import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import _ from 'lodash';
import GraphChart from './graphChart';
import { GlobalConst } from '../../../shared/appConfig/globalConst';
import axios from 'axios';
import { getCloudIcon } from '../../../shared/helper/genHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


export default function CloudTreeModal({ showCloudTreeModal, setShowCloudTreeModal, setLoading, setShowResourceDetailsModal, advDashboardData }) {
    const [isReady, setIsReady] = useState(false);
    const [modalDimensions, setModalDimensions] = useState({ width: 0, height: 0 });
    const [apidata, setApiData] = useState({});

    const hideCloudTreeModal = () => {
        setShowCloudTreeModal(false);
    }

    const handleModalEntered = useCallback(() => {
        setIsReady(true);
    }, []);

    const updateModalDimensions = useCallback(_.debounce(() => {
        const modalBody = document.querySelector('.modal-body');
        if (modalBody) {
            setModalDimensions({
                width: modalBody.clientWidth,
                height: modalBody.clientHeight - 70
            });
        }
    }, 200), []);

    useEffect(() => {
        if (showCloudTreeModal) {
            window.addEventListener('resize', updateModalDimensions);
            return () => {
                window.removeEventListener('resize', updateModalDimensions);
            };
        }
    }, [showCloudTreeModal, updateModalDimensions]);

    useEffect(() => {
        if (isReady) {
            updateModalDimensions();
        }
    }, [isReady, updateModalDimensions]);

    useEffect(() => {
        getHierarchyData();
    }, []);

    const getHierarchyData = () => {
        setLoading(true);

        let postData = {
            integrationId: advDashboardData.integrationId,
            reportId: advDashboardData.reportId,
            targetSystem: advDashboardData.targetSystem,
        }

        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/hierarchy`, postData)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setApiData(op.data);
                }
            })
            .catch(e => { setApiData({}); })
            .finally(o => {
                setLoading(false);
            })

    }

    return (
        <Modal
            show={showCloudTreeModal}
            fullscreen={true}
            onHide={hideCloudTreeModal}
            onEntered={handleModalEntered}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className='d-flex align-items-start justify-content-start flex-row'>
                        <a className='me-3 mt-1' onClick={hideCloudTreeModal} href='#' style={{ fontSize: 22 }}><small ><FontAwesomeIcon icon={faArrowLeft} /></small></a>
                        <div className='me-3'>{!_.isEmpty(advDashboardData) ? <><img className='me-2' src={getCloudIcon('', advDashboardData.targetSystem)} style={{ width: 30 }} />{advDashboardData.projectId}</> : 'Cloud Tree'}</div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='mb-3'>
                    <section className="mb-5">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div style={{ height: `${modalDimensions.height}px`, width: '100%' }} className='d-flex align-items-center justify-content-center'>
                                    {!_.isEmpty(apidata) && <GraphChart apidata={apidata} setShowResourceDetailsModal={setShowResourceDetailsModal} advDashboardData={advDashboardData} />}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Modal.Body>
        </Modal>
    );
}