import axios from "axios";
import { GlobalConst } from "../../../shared/appConfig/globalConst";

import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Table, Tag, Switch, Input, Space, Button } from 'antd';
import _ from 'lodash';
import NoDataFound from '../../../shared/sharedComponents/noDataFound';
import { getCloudIcon, getFontColor, getStrokeColor } from '../../../shared/helper/genHelper';
import { WarningFilled, CheckCircleFilled, SearchOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import TabLoader from "../../../shared/sharedComponents/tabLoader";
import { updateSelectedK8sRecord } from "../../../shared/redux/reducers/kubernetesSecurityReducer";
import { useParams } from "react-router-dom";
import Highlighter from "react-highlight-words";

export default function RulesTab({ setShowResourceDetailsModal, dashboardData }) {

    const [showAllComponents, setShowAllComponents] = useState(false);
    const [data, setData] = useState({});
    const [loadingData, setLoadingData] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [filterDropdownVisible, setFilterDropdownVisible] = useState({});
    const dispatch = useDispatch();
    const { id, integrationId } = useParams();
    const searchInput = useRef(null);

    useEffect(() => {
        setLoadingData(true);
        const postData = { "reportId": id };
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/summaryreport/rules`, postData)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setData(op.data);
                }
                else {
                    setData({});
                }
            })
            .catch(e => { setData({}); })
            .finally(o => {
                setLoadingData(false);
            })
    }, []);


    const expandedRowRender = (record) => {

        const handleNameClick = (e, row) => {
            e.preventDefault();
            const activeRecordData = {
                "targetSystem": "k8s",
                "integrationId": integrationId,
                "reportId": id,
                "docType": row.docType,
                "name": row.name,
                "namespace": row.namespace
            };
            dispatch(updateSelectedK8sRecord(activeRecordData));
            setShowResourceDetailsModal(true);
        }

        const columns = [
            { title: 'Name', dataIndex: 'name', key: 'name', ellipsis: true, width: 250, render: (text, row) => { return (<a href='#' onClick={(e) => handleNameClick(e, row)}><img src={getCloudIcon(row.docType, "k8s")} className='me-2' style={{ width: 18 }} />{text}</a>) } },
            {
                title: 'Status',
                key: 'status',
                width: 100,
                render: (_, record) => (
                    <span>
                        {record.violated === 'Pass' ? (
                            <CheckCircleFilled style={{ color: 'green' }} />
                        ) : (
                            <WarningFilled style={{ color: getStrokeColor('Critical') }} />
                        )}
                        {' '}
                        {record.violated}
                    </span>
                ),
            },
            {
                title: 'Severity',
                dataIndex: 'severity',
                key: 'severity',
                render: (text, record) => (
                    <Tag
                        style={{
                            color: getFontColor(text),
                            borderRadius: 50,
                            fontSize: 12,
                            fontWeight: 600
                        }}
                        color={getStrokeColor(text)}
                        className={"mx-auto text-center text-uppercase"}
                    >
                        {text}
                    </Tag>
                ),
            },
        ];

        const myUpdatedData = (_.isEmpty(record) && _.isEmpty(record.policies)) ? [] : record.policies.map((row, index) => ({
            ...row,
            key: `cloud-adv-dashboard-expanded-${index}`
        }));

        const filteredData = showAllComponents ? myUpdatedData : myUpdatedData.filter(item => !_.isEmpty(item.violated) && item.violated.toLowerCase() === 'fail');

        const sortedData = _.orderBy(filteredData,
            [item => {
                switch (item.severity.toLowerCase()) {
                    case 'critical': return 1;
                    case 'high': return 2;
                    case 'medium': return 3;
                    case 'low': return 4;
                    default: return 5;
                }
            }],
            ['asc']
        );

        return (
            <>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="card custom-card">
                            <div className="card-body">
                                <h5 className="mb-2">Resource Details</h5>
                                <Table
                                    size='small'
                                    columns={columns}
                                    dataSource={sortedData}
                                    pagination={(!_.isEmpty(sortedData) && sortedData.length > 10) ? {
                                        showTotal: (total, range) => {
                                            return (
                                                <span className="text-white">
                                                    {`${range[0]}-${range[1]} of ${total && total.format()} issues`}
                                                </span>
                                            );
                                        },
                                        defaultPageSize: 10,
                                        showSizeChanger: true,
                                        position: ["bottom", "left"],
                                        className: "searchPagePagination px-0",
                                        pageSizeOptions: [10, 20, 25],
                                    } : false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card custom-card">
                            <div className="card-body">
                                <h5 className="mb-3">Additional Details</h5>
                                <p><strong>Additional description: </strong>{record.description}</p>
                                <p><strong>Impact: </strong>{record.impact}</p>
                                <p className="mb-0"><strong>References: </strong></p>
                                <ul>
                                    {!_.isEmpty(record.references) && record.references.map((el, index) => (
                                        <li key={`ref-${record.name}-${index}`}>
                                            <a target='_blank' href={el} rel="noopener noreferrer">{el}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    };

    const getColumnSearchProps = (dataIndex, columnName) => ({
        // filterDropdownOpen: openState,
        // filterDropdownVisible: searchStates.column1,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${columnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        className="d-flex align-items-center justify-content-center"
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            if (clearFilters) {

                                clearFilters();
                                setSearchText('');
                            }
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchedColumn(dataIndex);
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        // filterDropdownVisible: filterDropdownVisible.column1,
        filterDropdownVisible: filterDropdownVisible[dataIndex],
        onFilterDropdownVisibleChange: (visible) => handleSearchClick(dataIndex, visible),
        render: (text) =>
            searchedColumn === dataIndex && !_.isEmpty(searchText)
                ? getHighlighted(text)
                : <>
                    {text}
                </>,
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        // visible = true;
        // console.log("Visible", visible);
        setTimeout(() => searchInput.current?.select(), 100);
        // setOpenState(true);
    };

    const handleSearchClick = (selectedColumn, visible) => {
        let allVisible = _.mapValues(filterDropdownVisible, function (o) { return false; });
        setFilterDropdownVisible({ ...allVisible, [selectedColumn]: visible });
    };

    const getHighlighted = (text) => {
        return (
            <Highlighter
                highlightStyle={{
                    backgroundColor: "#ffc069",
                    padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
            />
        );
    };

    const dataTableColumns = [
        {
            title: "Sr No", key: "srNo", width: 60,
            render: (val, record, index) => index + 1
        },
        {
            title: 'Name',
            dataIndex: 'message',
            key: 'message',
            ellipsis: true,
            ...getColumnSearchProps('message', 'Name'),
        },
        {
            title: 'Framework',
            dataIndex: '',
            key: '',
            width: 110,
            render: (text, record) => (<>{record.ID.frameworkName}</>),
        },
        { title: 'Rule No', dataIndex: '', key: '', ellipsis: true, align: 'left', width: 90, render: (text, record) => (<>{record.ID.ruleNo}</>) },
        {
            title: 'Severity',
            dataIndex: 'severity',
            align: 'center',
            filters: [
                {
                    text: 'critical',
                    value: 'critical',
                },
                {
                    text: 'high',
                    value: 'high',
                },
                {
                    text: 'medium',
                    value: 'medium',
                },
                {
                    text: 'low',
                    value: 'low',
                },
                {
                    text: 'na',
                    value: 'na',
                },
            ],
            onFilter: (value, row) => {
                let returnStatus = false;
                if (value) {
                    if (!_.isEmpty(row.severity)) {
                        returnStatus = _.includes(row.severity, value);
                    }
                    else {
                        returnStatus = false;
                    }
                }
                return returnStatus;
            },
            width: 110,
            render: (text, record) => {
                return (<Tag
                    style={{
                        color: getFontColor(text),
                        borderRadius: 50,
                        fontSize: 12,
                        fontWeight: 600
                    }}
                    color={getStrokeColor(text)}
                    className={"mx-auto text-center text-uppercase"}
                >
                    {text}
                </Tag>)
            }
        },
        {
            title: 'Fail Count',
            dataIndex: 'failCount',
            align: 'center',
            width: 110,
            sorter: (a, b) => {
                const aFailCount = a.failCount || 0;
                const bFailCount = b.failCount || 0;
                return aFailCount - bFailCount;
            },
            sortDirections: ["descend"],
            render: (text, record) => {
                return (<>{text}</>);
            }
        },
        {
            title: '',
        }
    ];

    const onChangeShowAllComponents = (checked) => {
        setShowAllComponents(checked);
    }

    let myUpdatedData = [];
    if (!_.isEmpty(data)) {
        myUpdatedData = data.map((row, index) => ({
            ...row,
            key: `cloud-adv-dashboard-${index}`
        }));
    }

    const filteredAndSortedData = useMemo(() => {

        if (!_.isEmpty(myUpdatedData)) {
            // Filter out items with failCount of 0
            const filteredData = !showAllComponents ? myUpdatedData.filter(item => item.failCount > 0) : myUpdatedData;
            const sortedData = _.orderBy(filteredData,
                [item => {
                    switch (item.severity.toLowerCase()) {
                        case 'critical': return 1;
                        case 'high': return 2;
                        case 'medium': return 3;
                        case 'low': return 4;
                        default: return 5;
                    }
                }, 'failCount'],
                ['asc', 'desc']
            );

            const finalData = sortedData.map((row, index) => ({
                ...row,
                sno: index + 1,
            }));

            return finalData;
        }

    }, [myUpdatedData, showAllComponents]);


    if (loadingData) {
        return (<div style={{ height: 200 }}><TabLoader /></div>);
    }
    else {
        return (
            <div>
                <div className='d-flex align-items-center justify-content-end mb-4 me-3 mt-2' style={{ position: 'absolute', right: 0, top: '-56px' }}>
                    <Switch onChange={onChangeShowAllComponents} checked={showAllComponents} className='me-2' />Show All
                </div>
                <Table
                    size='small'
                    className='custom-table mb-0 fixedScrollCustomTable table-hover component-view-table'
                    dataSource={filteredAndSortedData}
                    columns={dataTableColumns}
                    locale={{
                        emptyText: <NoDataFound title={'No Data'} />
                    }}
                    expandable={{
                        expandedRowRender,
                        rowExpandable: (record) => record.policies && record.policies.length > 0,
                    }}
                    loading={loadingData}
                    pagination={(!_.isEmpty(filteredAndSortedData) && filteredAndSortedData.length > 10) ? {
                        showTotal: (total, range) => {
                            return (
                                <span className="text-white">
                                    {`${range[0]}-${range[1]} of ${total && total.format()} issues`}
                                </span>
                            );
                        },
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        position: ["bottom", "left"],
                        className: "searchPagePagination px-0",
                        pageSizeOptions: [10, 20, 25],
                    } : false}
                />
            </div>
        );
    }

}