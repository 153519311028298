const getConfig = () => {
    // Use runtime config if available, otherwise fall back to process.env
    if (window.__RUNTIME_CONFIG__) {
      return {
        PUBLIC_URL: window.__RUNTIME_CONFIG__.PUBLIC_URL,
        REACT_APP_APP_URL: window.__RUNTIME_CONFIG__.REACT_APP_APP_URL,
        REACT_APP_REDIRECT_URL: window.__RUNTIME_CONFIG__.REACT_APP_REDIRECT_URL,
        REACT_APP_API_URL: window.__RUNTIME_CONFIG__.REACT_APP_API_URL,
        REACT_APP_AUTH_API_URL: window.__RUNTIME_CONFIG__.REACT_APP_AUTH_API_URL,
        REACT_APP_VERSION: window.__RUNTIME_CONFIG__.REACT_APP_VERSION,
        REACT_APP_AUTH_APP_URL: window.__RUNTIME_CONFIG__.REACT_APP_AUTH_APP_URL,
        REACT_APP_CLIENT_SECRET: window.__RUNTIME_CONFIG__.REACT_APP_CLIENT_SECRET,
        REACT_APP_CLIENT_ID: window.__RUNTIME_CONFIG__.REACT_APP_CLIENT_ID
      };
    }
  
    // Fallback to build-time environment variables
    return {
      PUBLIC_URL: process.env.PUBLIC_URL,
      REACT_APP_APP_URL: process.env.REACT_APP_APP_URL,
      REACT_APP_REDIRECT_URL: process.env.REACT_APP_REDIRECT_URL,
      REACT_APP_API_URL: process.env.REACT_APP_API_URL,
      REACT_APP_AUTH_API_URL: process.env.REACT_APP_AUTH_API_URL,
      REACT_APP_VERSION: process.env.REACT_APP_VERSION,
      REACT_APP_AUTH_APP_URL: process.env.REACT_APP_AUTH_APP_URL,
      REACT_APP_CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
      REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID
    };
  };

  export const config = getConfig();