import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from 'lodash';

const integrationReducer = createSlice({
    name: 'integrationReducer',
    initialState: {
        allIntegratedSystems: {}
    },
    reducers: {
        setAllIntegratedSystems: (state, action) => {
            state.allIntegratedSystems = action.payload;
        },
    }
})

export const { setAllIntegratedSystems } = integrationReducer.actions;

export default integrationReducer.reducer;