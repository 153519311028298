import _ from 'lodash';

const ConvertResourceGraphData = (apiData) => {
  const transformNode = (node) => ({
    id: node.id || node.name,
    name: node.name,
    category: node.docType,
    vulnerability: node.vulnerability
  });

  const nodes = [transformNode(apiData)];
  const links = [];
  const categories = [];

  const addChildrenNodes = (parent) => {
    if (parent.children) {
      parent.children.forEach((child) => {
        const childNode = transformNode(child);
        nodes.push(childNode);
        links.push({
          source: parent.id || parent.name,
          target: childNode.id
        });
        
        if (!categories.some(cat => cat.name === child.docType)) {
          categories.push({ name: child.docType });
        }

        addChildrenNodes(child);
      });
    }
  };

  addChildrenNodes(apiData);

  return {
    nodes,
    links,
    categories
  };
};

export default ConvertResourceGraphData;