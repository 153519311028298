import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import _ from 'lodash';
import * as echarts from 'echarts';
import TabLoader from "../../shared/sharedComponents/tabLoader";
import NoDataFound from "../../shared/sharedComponents/noDataFound";


let colorPalette = [
    '#e0e6b7',
    '#f1a236',
    '#d67043',
    '#da4a47',
    '#58d9f9',
    '#05c091',
    '#ff8a45',
    '#8d48e3',
    '#dd79ff'
];

let contrastColor = '#B9B8CE', backgroundColor = '#100C2A';

let axisCommon = function () {
    return {
        axisLine: {
            lineStyle: {
                color: contrastColor
            }
        },
        splitLine: {
            lineStyle: {
                color: '#484753'
            }
        },
        splitArea: {
            areaStyle: {
                color: ['rgba(255,255,255,0.02)', 'rgba(255,255,255,0.05)']
            }
        },
        minorSplitLine: {
            lineStyle: {
                color: '#20203B'
            }
        }
    };
};

let theme = {
    darkMode: true,

    color: colorPalette,
    backgroundColor: 'transparent',
    axisPointer: {
        lineStyle: {
            color: '#817f91'
        },
        crossStyle: {
            color: '#817f91'
        },
        label: {
            color: '#fff'
        }
    },
    legend: {
        textStyle: {
            color: contrastColor
        }
    },
    textStyle: {
        color: contrastColor
    },
    title: {
        textStyle: {
            color: '#EEF1FA'
        },
        subtextStyle: {
            color: '#B9B8CE'
        }
    },
    toolbox: {
        iconStyle: {
            borderColor: contrastColor
        }
    },
    dataZoom: {
        borderColor: '#71708A',
        textStyle: {
            color: contrastColor
        },
        brushStyle: {
            color: 'rgba(135,163,206,0.3)'
        },
        handleStyle: {
            color: '#353450',
            borderColor: '#C5CBE3'
        },
        moveHandleStyle: {
            color: '#B0B6C3',
            opacity: 0.3
        },
        fillerColor: 'rgba(135,163,206,0.2)',
        emphasis: {
            handleStyle: {
                borderColor: '#91B7F2',
                color: '#4D587D'
            },
            moveHandleStyle: {
                color: '#636D9A',
                opacity: 0.7
            }
        },
        dataBackground: {
            lineStyle: {
                color: '#71708A',
                width: 1
            },
            areaStyle: {
                color: '#71708A'
            }
        },
        selectedDataBackground: {
            lineStyle: {
                color: '#87A3CE'
            },
            areaStyle: {
                color: '#87A3CE'
            }
        }
    },
    visualMap: {
        textStyle: {
            color: contrastColor
        }
    },
    timeline: {
        lineStyle: {
            color: contrastColor
        },
        label: {
            color: contrastColor
        },
        controlStyle: {
            color: contrastColor,
            borderColor: contrastColor
        }
    },
    calendar: {
        itemStyle: {
            color: backgroundColor
        },
        dayLabel: {
            color: contrastColor
        },
        monthLabel: {
            color: contrastColor
        },
        yearLabel: {
            color: contrastColor
        }
    },
    timeAxis: axisCommon(),
    logAxis: axisCommon(),
    valueAxis: axisCommon(),
    categoryAxis: axisCommon(),

    line: {
        symbol: 'circle'
    },
    graph: {
        color: colorPalette
    },
    gauge: {
        title: {
            color: contrastColor
        }
    },
    candlestick: {
        itemStyle: {
            color: '#FD1050',
            color0: '#0CF49B',
            borderColor: '#FD1050',
            borderColor0: '#0CF49B'
        }
    }
};

echarts.registerTheme('my_theme', theme);


export default function TrendGraph({hasCloudData, trendData, loadingData }) {

    const [options, setOptions] = useState({
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
            data: ['Total Vulnerabilities', 'Critical', 'High', 'Medium', 'Low']
        },
        // toolbox: {
        //     feature: {
        //         saveAsImage: {}
        //     }
        // },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: []
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: []
    });


    useEffect(() => {
        let tempXAxis = [], total = [], critical = [], high = [], medium = [], low = [];
        if (!_.isEmpty(trendData)) {
            trendData.forEach((row) => {
                tempXAxis.push(row.scanDate);
                total.push(row.totalCount);
                critical.push(row.totalCriticalCount);
                high.push(row.totalHighCount);
                medium.push(row.totalMediumCount);
                low.push(row.totalLowCount);
            })
        }

        let seriesData = [
            {
                name: 'Low',
                type: 'line',
                stack: 'Total',
                areaStyle: {},
                emphasis: {
                    focus: 'series'
                },
                data: low
            },
            {
                name: 'Medium',
                type: 'line',
                stack: 'Total',
                areaStyle: {},
                emphasis: {
                    focus: 'series'
                },
                data: medium
            },
            {
                name: 'High',
                type: 'line',
                stack: 'Total',
                areaStyle: {},
                emphasis: {
                    focus: 'series'
                },
                data: high
            },
            {
                name: 'Critical',
                type: 'line',
                stack: 'Total',
                areaStyle: {},
                emphasis: {
                    focus: 'series'
                },
                data: critical
            },
            {
                name: 'Total Vulnerabilities',
                type: 'line',
                stack: 'Total',
                label: {
                    show: true,
                    position: 'top'
                },
                areaStyle: {},
                emphasis: {
                    focus: 'series'
                },
                data: total
            }
        ];

        setOptions({ ...options, xAxis: { ...options.xAxis, data: tempXAxis }, series: seriesData });
    }, [trendData])

    if (_.isEmpty(trendData)) {
        if (loadingData === true) {
            return (<div className="d-flex align-items-center justify-content-center h-100 position-relative"><TabLoader /></div>);
        }
        else {
            return (<NoDataFound title={'No Trend Data Found'} desc={hasCloudData ? `It seems there haven't been any recent scans to generate trend data. Please initiate a scan to start tracking your cloud security trends over time.` : 'We could not find any trend data'} />);
        }
    }
    else {
        return (
            <>
                <ReactEcharts
                    option={options}
                    theme={"my_theme"}
                />
            </>
        );
    }
}
