import React from "react";
import { FloatButton } from 'antd';
import { LinkedinOutlined, YoutubeOutlined, TwitterOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import moment from "moment";

export default function Footer(props) {

    return (
        <footer className="bg-header-dark text-white">
            
            <div className="sotcox-copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            {/* © 2023 All rights reserved. By NurseryBuy.com. */}
                            {/* <p className="mb-0">&copy; <span id="copyright-date">{moment().format('YYYY')}</span> All Rights Reserved. By sec1.com </p> */}
                            {/* <p className="mb-0">Sec1 &copy; <span id="copyright-date">{moment().format('YYYY')}</span>  - Cybersecurity AI Fortified </p> */}

                            <div className="text-dark order-2 order-md-1 text-muted">
                                Copyright &copy; {moment().format('YYYY')} <a href="https://sec1.io" target="_blank" className="text-gray-800 text-hover-primary">Sec1 - Cybersecurity AI Fortified</a>. All rights reserved
                                </div>
                            {/* Sec1 © 2023 - Unleashing the Future of Cyber Resilience */}
                        </div>
                        {/* <div className="col-md-4"> */}
                        {/* <div className="sotcox-backtotop"> */}
                        {/* <button data-sotcox-backtotop>
                                        <FontAwesomeIcon icon={faAngleUp} className="me-1" />  Go On Top
                                    </button> */}

                        {/* </div> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>
            {/* <FloatButton.BackTop /> */}
        </footer>
    );
}