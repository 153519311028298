import React, { useEffect, useState } from "react";
import { PiTrademarkRegistered } from "react-icons/pi";
import gcpIcon from '../../../assets/img/scopy-tech-icons/gcp.svg';
import awsIcon from '../../../assets/img/scopy-tech-icons/aws.svg';
import kubernatesIcon from '../../../assets/img/kubernetes.svg';
import Modal from 'react-bootstrap/Modal';
import { Tooltip, Tabs } from 'antd';
import axios from "axios";
import { GlobalConst } from "../../../shared/appConfig/globalConst";
import _ from 'lodash';
import NoIntegrationsConfigured from "./noIntegrationsConfigured";
import GCPTab from "./gcpTab";
import { useDispatch, useSelector } from "react-redux";
import { setIScanModalState } from "../../../shared/redux/reducers/cloudSecurityReducer";
import AWSTab from "./awsTab";

export default function IScanModal({ loading, setLoading }) {

    const cloudSecurityReducer = useSelector(state => state.cloudSecurityReducer);
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState('1');
    const [foundIntegrations, setFoundIntegrations] = useState({});
    const keysToCheck = ['gcp', 'aws', 'kubernetes'];
    const [items, setItems] = useState([]);

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${GlobalConst.API_URL}/auth/integrate/user-integrations`)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    let integrations = [];
                    keysToCheck.forEach(key => {
                        if (_.has(op.data, key)) {
                            integrations.push(op.data[key]);
                        }
                    });
                    setFoundIntegrations(integrations);
                }
            })
            .catch(e => console.log("Exception: ", e))
            .finally(o => { setLoading(false) })

    }, []);

    useEffect(() => {

        if (!_.isEmpty(foundIntegrations)) {
            let tabContent = [];
            foundIntegrations.map(integration => {
                if (integration && integration.targetSystem === 'gcp') {
                    tabContent.push({
                        key: '1',
                        label: (
                            <Tooltip title="Google Cloud Platform">
                                <span>
                                    <img src={gcpIcon} style={{ width: 20, marginRight: 8 }} alt="GCP" />
                                    GCP
                                </span>
                            </Tooltip>
                        ),
                        children: <GCPTab integration={integration} setLoading={setLoading} loading={loading} />,
                    })
                }
                else if (integration && integration.targetSystem === 'aws') {
                    tabContent.push({
                        key: '2',
                        label: (
                            <Tooltip title="Amazon Web Services">
                                <span>
                                    <img src={awsIcon} style={{ width: 20, marginRight: 8 }} alt="AWS" />
                                    AWS
                                </span>
                            </Tooltip>
                        ),
                        children: <AWSTab integration={integration} setLoading={setLoading} loading={loading}/>,
                    },)
                }
                else if (integration && integration.targetSystem === 'kubernetes') {
                    tabContent.push({
                        key: '3',
                        label: (
                            <Tooltip title="Kubernetes">
                                <span>
                                    <img src={kubernatesIcon} style={{ width: 20, marginRight: 8 }} alt="Kubernetes" />
                                    Kubernetes
                                </span>
                            </Tooltip>
                        ),
                        children: 'Kubernetes Tab Content',
                    })
                }
            });
            setItems(tabContent);
        }
    }, [foundIntegrations])

    const onChange = (key) => {
        setActiveTab(key);
    };

    const closeIScanModal = () => {
        dispatch(setIScanModalState(false))
    }

    return (
        <Modal show={cloudSecurityReducer.iScanModalState} onHide={closeIScanModal} size="lg" backdrop={'static'} >
            <Modal.Header closeButton>
                <div className="mb-0">
                    <h5 className="mb-0 text-white" style={{ fontWeight: 600 }}>
                        iScan <small className="mb-0 ms-2 text-muted" style={{ fontSize: '65%' }}>AI Powered Scans <PiTrademarkRegistered /></small>
                    </h5>
                </div>
            </Modal.Header>
            <>
                {
                    !_.isEmpty(foundIntegrations) ? <>
                        <Modal.Body>
                            <Tabs activeKey={activeTab} items={items} onChange={onChange} />
                        </Modal.Body>
                    </> : <Modal.Body>{loading ? 'Fetching Integrated List...' : <NoIntegrationsConfigured />}</Modal.Body>
                }
            </>
        </Modal>
    );
}