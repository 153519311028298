import React, { useCallback, useRef, useState } from 'react';
import { Button, Input, Space, Switch, Table, Tag, Tooltip } from 'antd';
import _ from 'lodash';
import NoDataFound from '../../../shared/sharedComponents/noDataFound';
import { getCloudIcon, getFontColor, getStrokeColor } from '../../../shared/helper/genHelper';
import { useDispatch } from 'react-redux';
import { AiOutlineDashboard } from "react-icons/ai";
import Loader from '../../welcomeScreen/loader';
import FullLoader from '../../../shared/sharedComponents/loader';
import VulDataTable from '../resourceDetailsModal/detailsModalTabs/vulDataTable';
import axios from 'axios';
import { GlobalConst } from '../../../shared/appConfig/globalConst';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { debounce } from 'lodash';
import TabLoader from "../../../shared/sharedComponents/tabLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const VulnerablePods = ({ data, handleDetailsClick }) => {
    const [podsReport, setPodsReport] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState("");
    const [searchText, setSearchText] = useState("");
    const [showByNamespace, setShowByNamespace] = useState(false);
    const searchInput = useRef(null);

    const expandedRowRender = (record) => {
        if (loadingData && expandedRowKeys === record.scanReportId) {
            return (<div style={{ height: 200 }}><TabLoader /></div>);
        }
        return !_.isEmpty(podsReport) && !_.isEmpty(podsReport[record.scanReportId]) && (<VulDataTable
            searchResults={podsReport[record.scanReportId]}
            dataSet={podsReport[record.scanReportId].scanResult}
        />)
    }

    const namespaceExpandedRowRender = (record) => {
        const myUpdatedData = record.group.map((row, index) => ({
            ...row,
            key: `k8s-namespace-group-dashboard-${index}`
        }));

        const expandedColumns = dataTableColumns.filter((column) => column.dataIndex !== "namespace")
        return (<Table
            size='small'
            className='custom-table mb-0 fixedScrollCustomTable table-hover component-view-table'
            dataSource={myUpdatedData}
            columns={expandedColumns}
            expandable={{
                expandedRowRender: expandedRowRender,
                onExpand: handleNamespaceExpand,
                rowExpandable: (record) => !_.isEmpty(record.scanReportId) && record.totalCve > 0,
                // expandedRowKeys,
            }}
            locale={{
                emptyText: <NoDataFound title={'No Data'} />
            }}
            pagination={(!_.isEmpty(myUpdatedData) && myUpdatedData.length > 10) ? {
                showTotal: (total, range) => {
                    return (
                        <span className="text-white">
                            {`${range[0]}-${range[1]} of ${total && total.format()} issues`}
                        </span>
                    );
                },
                defaultPageSize: 10,
                showSizeChanger: true,
                position: ["bottom", "left"],
                className: "searchPagePagination px-0",
                pageSizeOptions: [10, 20, 25],
            } : false}
        />)
    }

    const getReportDetails = (scanReportId) => {
        setLoadingData(true);
        axios
            .get(
                `${GlobalConst.API_URL}/auth/foss/report/${scanReportId}`
            )
            .then((op) => {
                setLoadingData(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setPodsReport({
                        ...podsReport,
                        [scanReportId]: op.data.scannerResponseEntity,
                    });
                }
            })
            .catch((e) => {
                setLoadingData(false);
                console.log("Exception: ", e);
            });
    }

    const handleExpand = (expanded, record) => {
        if (showByNamespace) return
        if (expanded && !podsReport[record.scanReportId]) {
            getReportDetails(record.scanReportId);
        }
        setExpandedRowKeys(expanded ? record.scanReportId : "");
    }

    const handleNamespaceExpand = (expanded, record) => {
        if (expanded && !podsReport[record.scanReportId]) {
            getReportDetails(record.scanReportId);
        }
        setExpandedRowKeys(expanded ? record.scanReportId : "");
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex, columnName, si, handleDetailsClick, isNameSpace = false) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={si}
                    placeholder={`Search ${columnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        className="d-flex align-items-center justify-content-center"
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => debouncedReset(clearFilters, confirm, dataIndex)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
            // style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => si.current?.select(), 100);
            }
        },
        render: (text, row) =>
            searchedColumn === dataIndex && !_.isEmpty(searchText)
                ? getHighlighted(text)
                : (dataIndex === "containerName" ? <>
                    <Tooltip title={"pod"}><img src={getCloudIcon("pod", "k8s")} className='me-2' style={{ width: 18 }} /></Tooltip>
                    <a
                        className={`me-3 details-hover-animate`}
                        target="_blank"
                        onClick={() => handleDetailsClick(row)}
                    >
                        {text}
                    </a>
                    {!_.isEmpty(row.scanStatus) && row.scanStatus.toLowerCase() === "failed" && <span>
                        <Tooltip title={`Scan status:${row.scanStatus}`}>
                            <span>
                                <FontAwesomeIcon
                                    icon={faTriangleExclamation}
                                    className="ms-2 text-danger"
                                />
                            </span>
                        </Tooltip>
                    </span>}</> : (<>{isNameSpace && <Tooltip title={"namespace"}><img src={getCloudIcon("namespace", "k8s")} className='me-2' style={{ width: 18 }} /></Tooltip>}{text}</>))
        ,
    });

    const getHighlighted = (text) => {
        return (
            <Highlighter
                highlightStyle={{
                    backgroundColor: "#ffc069",
                    padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
            />
        );
    };

    const onChangeShowByNamespace = (checked) => {
        setShowByNamespace(checked);
    }

    const debouncedReset = useCallback(
        debounce((clearFilters, confirm, dataIndex) => {
            if (clearFilters) {
                clearFilters();
                setSearchText("");
            }
            confirm({ closeDropdown: false });
            setSearchedColumn(dataIndex);
        }, 250),
        []
    );

    const dataTableColumns = [
        {
            title: 'Container Name',
            dataIndex: 'containerName',
            key: 'containerName',
            width: 180,
            ellipsis: true,
            ...getColumnSearchProps("containerName", "Container Name", searchInput, handleDetailsClick),
        },
        {
            title: 'Pod Name',
            dataIndex: 'podName',
            key: 'podName',
            width: 180,
            ...getColumnSearchProps("podName", "Pod Name", searchInput),
        },
        {
            title: 'Namespace',
            dataIndex: 'namespace',
            key: 'namespace',
            width: 180,
            ...getColumnSearchProps("namespace", "Namespace", searchInput),
        },
        {
            title: 'Total',
            dataIndex: 'totalCve',
            key: 'totalCve',
            width: 95,
            sorter: (a, b) => {
                const aTotal = a.totalCve || 0;
                const bTotal = b.totalCve || 0;
                return aTotal - bTotal;
            },
            sortDirections: ["descend"],
            align: 'center',
            render: (text, record) => {
                if (record.scanStatus.toLowerCase() === 'initiated' || record.scanStatus.toLowerCase() === 'scanning') {
                    // return (<div className='d-flex align-items-center justify-content-center'><Loader /></div>);
                    return (
                        <div className="d-flex align-items-center justify-content-center">-</div>
                    );
                } else {
                    if (text) {
                        return (<>{text}</>);
                    } else {
                        return <>0</>
                    }
                }
            }
        },
        {
            title: 'Critical',
            dataIndex: 'critical',
            key: 'criticalCve',
            width: 105,
            sorter: (a, b) => {
                const aCritical = a.critical || 0;
                const bCritical = b.critical || 0;
                return aCritical - bCritical
            },
            sortDirections: ["descend"],
            align: 'center',
            render: (text, record) => {
                if (record.scanStatus.toLowerCase() === 'initiated' || record.scanStatus.toLowerCase() === 'scanning') {
                    return (
                        <div className="d-flex align-items-center justify-content-center">
                            <Loader />
                        </div>
                    );
                }
                else {
                    if (text) {
                        return (
                            <Tag
                                style={{
                                    color: getFontColor("CRITICAL"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14
                                }}
                                color={getStrokeColor("CRITICAL")}
                                className={"mx-auto text-center"}
                            >
                                {text}
                            </Tag>
                        )
                    } else {
                        return (
                            <Tag
                                style={{
                                    color: getFontColor("CRITICAL"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14
                                }}
                                color={getStrokeColor("CRITICAL")}
                                className={"mx-auto text-center"}
                            >
                                0
                            </Tag>
                        )
                    }
                }
            }
        },
        {
            title: 'High',
            dataIndex: 'high',
            key: 'highCve',
            width: 85,
            sorter: (a, b) => {
                const aHigh = a.high || 0;
                const bHigh = b.high || 0;
                return aHigh - bHigh
            },
            sortDirections: ["descend"],
            align: 'center',
            render: (text, record) => {
                if (record.scanStatus.toLowerCase() === 'initiated' || record.scanStatus.toLowerCase() === 'scanning') {
                    return (
                        <div className="d-flex align-items-center justify-content-center">
                            <Loader />
                        </div>
                    );
                }
                else {
                    if (text) {
                        return (
                            <Tag
                                style={{
                                    color: getFontColor("HIGH"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14
                                }}
                                color={getStrokeColor("HIGH")}
                                className={"mx-auto text-center"}
                            >
                                {text}
                            </Tag>
                        )
                    } else {
                        return (
                            <Tag
                                style={{
                                    color: getFontColor("HIGH"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14
                                }}
                                color={getStrokeColor("HIGH")}
                                className={"mx-auto text-center"}
                            >
                                0
                            </Tag>
                        )
                    }
                }
            }
        },
        {
            title: 'Medium',
            dataIndex: 'medium',
            key: 'mediumCve',
            width: 105,
            sorter: (a, b) => {
                const aMedium = a.medium || 0;
                const bMedium = b.medium || 0;
                return aMedium - bMedium
            },
            sortDirections: ["descend"],
            align: 'center',
            render: (text, record) => {
                if (record.scanStatus.toLowerCase() === 'initiated' || record.scanStatus.toLowerCase() === 'scanning') {
                    return (
                        <div className="d-flex align-items-center justify-content-center">
                            <Loader />
                        </div>
                    );
                } else {
                    if (text) {
                        return (
                            <Tag
                                style={{
                                    color: getFontColor("MEDIUM"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14
                                }}
                                color={getStrokeColor("MEDIUM")}
                                className={"mx-auto text-center"}
                            >
                                {text}
                            </Tag>
                        )
                    } else {
                        return (
                            <Tag
                                style={{
                                    color: getFontColor("MEDIUM"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14
                                }}
                                color={getStrokeColor("MEDIUM")}
                                className={"mx-auto text-center"}
                            >
                                0
                            </Tag>
                        )
                    }
                }
            }
        },
        {
            title: 'Low',
            dataIndex: 'low',
            key: 'lowCve',
            width: 95,
            sorter: (a, b) => {
                const aLow = a.low || 0;
                const bLow = b.low || 0;
                return aLow - bLow
            },
            sortDirections: ["descend"],
            align: 'center',
            render: (text, record) => {
                if (record.scanStatus.toLowerCase() === 'initiated' || record.scanStatus.toLowerCase() === 'scanning') {
                    return (
                        <div className="d-flex align-items-center justify-content-center">
                            <Loader />
                        </div>
                    );
                } else {
                    if (text) {
                        return (
                            <Tag
                                style={{
                                    color: getFontColor("LOW"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14
                                }}
                                color={getStrokeColor("LOW")}
                                className={"mx-auto text-center"}
                            >
                                {text}
                            </Tag>
                        )
                    } else {
                        return (
                            <Tag
                                style={{
                                    color: getFontColor("LOW"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14
                                }}
                                color={getStrokeColor("LOW")}
                                className={"mx-auto text-center"}
                            >
                                0
                            </Tag>
                        )
                    }
                }
            }
        },
        {
            title: 'NA',
            dataIndex: 'na',
            key: 'na',
            width: 95,
            sorter: (a, b) => {
                const aNa = a.na || 0;
                const bNa = b.na || 0;
                return aNa - bNa
            },
            sortDirections: ["descend"],
            align: 'center',
            render: (text, record) => {
                if (record.scanStatus.toLowerCase() === 'initiated' || record.scanStatus.toLowerCase() === 'scanning') {
                    return (
                        <div className="d-flex align-items-center justify-content-center">
                            <Loader />
                        </div>
                    );
                } else {
                    if (text) {
                        return (
                            <Tag
                                style={{
                                    color: getFontColor("NA"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14
                                }}
                                color={getStrokeColor("NA")}
                                className={"mx-auto text-center"}
                            >
                                {text}
                            </Tag>
                        )
                    } else {
                        return (
                            <Tag
                                style={{
                                    color: getFontColor("NA"),
                                    borderRadius: 50,
                                    width: 40,
                                    fontSize: 14
                                }}
                                color={getStrokeColor("NA")}
                                className={"mx-auto text-center"}
                            >
                                0
                            </Tag>
                        )
                    }
                }
            }
        },
        {
            title: "Action",
            key: "",
            fixed: "right",
            width: 100,
            render: (_, record) => {
                return <div className="d-flex align-items-center justify-content-start">
                    <a
                        className={`me-3 details-hover-animate`}
                        target="_blank"
                        onClick={() => handleDetailsClick(record)}
                    >
                        <Tooltip title="Details">
                            <AiOutlineDashboard style={{ fontSize: 20 }} />
                        </Tooltip>
                    </a>
                </div>
            }
        }
    ];

    const namespaceDataTableColumns = [
        // {
        //     title: 'Container Name',
        //     dataIndex: 'containerName',
        //     key: 'containerName',
        //     width: 180,
        //     ellipsis: true,
        //     ...getColumnSearchProps("containerName", "Container Name", searchInput, handleDetailsClick),
        // },
        // {
        //     title: 'Pod Name',
        //     dataIndex: 'podName',
        //     key: 'podName',
        //     width: 180,
        //     ...getColumnSearchProps("podName", "Pod Name", searchInput),
        // },
        {
            title: 'Namespace',
            dataIndex: 'name',
            key: 'namespace',
            width: 180,
            ...getColumnSearchProps("namespace", "Namespace", searchInput, null,true),
        },
        {
            title: 'Total',
            dataIndex: 'totalCve',
            key: 'totalCve',
            width: 95,
            sorter: (a, b) => {
                const aTotal = a.totalCve || 0;
                const bTotal = b.totalCve || 0;
                return aTotal - bTotal;
            },
            sortDirections: ["descend"],
            align: 'center',
            render: (text, record) => {

                if (text) {
                    return (<>{text}</>);
                } else {
                    return <>0</>
                }
            }
        },
        {
            title: 'Critical',
            dataIndex: 'critical',
            key: 'criticalCve',
            width: 105,
            sorter: (a, b) => {
                const aCritical = a.critical || 0;
                const bCritical = b.critical || 0;
                return aCritical - bCritical
            },
            sortDirections: ["descend"],
            align: 'center',
            render: (text, record) => {

                if (text) {
                    return (
                        <Tag
                            style={{
                                color: getFontColor("CRITICAL"),
                                borderRadius: 50,
                                width: 40,
                                fontSize: 14
                            }}
                            color={getStrokeColor("CRITICAL")}
                            className={"mx-auto text-center"}
                        >
                            {text}
                        </Tag>
                    )
                } else {
                    return (
                        <Tag
                            style={{
                                color: getFontColor("CRITICAL"),
                                borderRadius: 50,
                                width: 40,
                                fontSize: 14
                            }}
                            color={getStrokeColor("CRITICAL")}
                            className={"mx-auto text-center"}
                        >
                            0
                        </Tag>
                    )
                }
            }
        },
        {
            title: 'High',
            dataIndex: 'high',
            key: 'highCve',
            width: 85,
            sorter: (a, b) => {
                const aHigh = a.high || 0;
                const bHigh = b.high || 0;
                return aHigh - bHigh
            },
            sortDirections: ["descend"],
            align: 'center',
            render: (text, record) => {

                if (text) {
                    return (
                        <Tag
                            style={{
                                color: getFontColor("HIGH"),
                                borderRadius: 50,
                                width: 40,
                                fontSize: 14
                            }}
                            color={getStrokeColor("HIGH")}
                            className={"mx-auto text-center"}
                        >
                            {text}
                        </Tag>
                    )
                } else {
                    return (
                        <Tag
                            style={{
                                color: getFontColor("HIGH"),
                                borderRadius: 50,
                                width: 40,
                                fontSize: 14
                            }}
                            color={getStrokeColor("HIGH")}
                            className={"mx-auto text-center"}
                        >
                            0
                        </Tag>
                    )
                }
            }
        },
        {
            title: 'Medium',
            dataIndex: 'medium',
            key: 'mediumCve',
            width: 105,
            sorter: (a, b) => {
                const aMedium = a.medium || 0;
                const bMedium = b.medium || 0;
                return aMedium - bMedium
            },
            sortDirections: ["descend"],
            align: 'center',
            render: (text, record) => {

                if (text) {
                    return (
                        <Tag
                            style={{
                                color: getFontColor("MEDIUM"),
                                borderRadius: 50,
                                width: 40,
                                fontSize: 14
                            }}
                            color={getStrokeColor("MEDIUM")}
                            className={"mx-auto text-center"}
                        >
                            {text}
                        </Tag>
                    )
                } else {
                    return (
                        <Tag
                            style={{
                                color: getFontColor("MEDIUM"),
                                borderRadius: 50,
                                width: 40,
                                fontSize: 14
                            }}
                            color={getStrokeColor("MEDIUM")}
                            className={"mx-auto text-center"}
                        >
                            0
                        </Tag>
                    )
                }
            }
        },
        {
            title: 'Low',
            dataIndex: 'low',
            key: 'lowCve',
            width: 95,
            sorter: (a, b) => {
                const aLow = a.low || 0;
                const bLow = b.low || 0;
                return aLow - bLow
            },
            sortDirections: ["descend"],
            align: 'center',
            render: (text, record) => {

                if (text) {
                    return (
                        <Tag
                            style={{
                                color: getFontColor("LOW"),
                                borderRadius: 50,
                                width: 40,
                                fontSize: 14
                            }}
                            color={getStrokeColor("LOW")}
                            className={"mx-auto text-center"}
                        >
                            {text}
                        </Tag>
                    )
                } else {
                    return (
                        <Tag
                            style={{
                                color: getFontColor("LOW"),
                                borderRadius: 50,
                                width: 40,
                                fontSize: 14
                            }}
                            color={getStrokeColor("LOW")}
                            className={"mx-auto text-center"}
                        >
                            0
                        </Tag>
                    )
                }
            }
        },
        {
            title: 'NA',
            dataIndex: 'na',
            key: 'na',
            width: 95,
            sorter: (a, b) => {
                const aNa = a.na || 0;
                const bNa = b.na || 0;
                return aNa - bNa
            },
            sortDirections: ["descend"],
            align: 'center',
            render: (text, record) => {

                if (text) {
                    return (
                        <Tag
                            style={{
                                color: getFontColor("NA"),
                                borderRadius: 50,
                                width: 40,
                                fontSize: 14
                            }}
                            color={getStrokeColor("NA")}
                            className={"mx-auto text-center"}
                        >
                            {text}
                        </Tag>
                    )
                } else {
                    return (
                        <Tag
                            style={{
                                color: getFontColor("NA"),
                                borderRadius: 50,
                                width: 40,
                                fontSize: 14
                            }}
                            color={getStrokeColor("NA")}
                            className={"mx-auto text-center"}
                        >
                            0
                        </Tag>
                    )
                }
            }
        },
        // {
        //     title: "Action",
        //     key: "",
        //     fixed: "right",
        //     width: 100,
        //     render: (_, record) => {
        //         return <div className="d-flex align-items-center justify-content-start">
        //             <a
        //                 className={`me-3 details-hover-animate`}
        //                 target="_blank"
        //                 onClick={() => handleDetailsClick(record)}
        //             >
        //                 <Tooltip title="Details">
        //                     <AiOutlineDashboard style={{ fontSize: 20 }} />
        //                 </Tooltip>
        //             </a>
        //         </div>
        //     }
        // }
    ];

    let groupedNamespaceReports = []
    if (showByNamespace) {
        // const nameSpaceList = _.uniq(_.map(data.reports), "namespace")
        _.forEach(data.reports, reportItem => {
            const { namespace } = reportItem
            const isNamespacePresent = groupedNamespaceReports.some(({ name }) => namespace === name)
            if (!isNamespacePresent) {
                const result = {
                    name: namespace,
                    group: [reportItem],
                    totalCve: reportItem.totalCve || 0,
                    critical: reportItem.critical || 0,
                    high: reportItem.high || 0,
                    medium: reportItem.medium || 0,
                    low: reportItem.low || 0,
                    na: reportItem.na || 0,
                }
                groupedNamespaceReports.push(result)
            } else {
                groupedNamespaceReports = groupedNamespaceReports.map((namespaceGroupingData) => namespace === namespaceGroupingData.name ? {
                    ...namespaceGroupingData,
                    group: [...namespaceGroupingData.group, reportItem],
                    totalCve: reportItem.totalCve ? namespaceGroupingData.totalCve + reportItem.totalCve : namespaceGroupingData.totalCve,
                    critical: reportItem.critical ? namespaceGroupingData.critical + reportItem.critical : namespaceGroupingData.critical,
                    high: reportItem.high ? namespaceGroupingData.high + reportItem.high : namespaceGroupingData.high,
                    medium: reportItem.medium ? namespaceGroupingData.medium + reportItem.medium : namespaceGroupingData.medium,
                    low: reportItem.low ? namespaceGroupingData.low + reportItem.low : namespaceGroupingData.low,
                    na: reportItem.na ? namespaceGroupingData.na + reportItem.na : namespaceGroupingData.na,
                } : namespaceGroupingData)
            }
        })
    }

    const records = showByNamespace ? groupedNamespaceReports : data.reports
    const myUpdatedData = records.map((row, index) => ({
        ...row,
        key: `k8s-adv-dashboard-${index}`
    }));

    return (
        <div>
            <div className='d-flex align-items-center justify-content-end mb-4 me-3 mt-2' style={{ position: 'absolute', right: 0, top: '-56px' }}>
                <Switch onChange={onChangeShowByNamespace} checked={showByNamespace} className='me-2' />Show By Namespace
            </div>
            <Table
                size='small'
                className='custom-table mb-0 fixedScrollCustomTable table-hover component-view-table'
                dataSource={myUpdatedData}
                columns={showByNamespace ? namespaceDataTableColumns : dataTableColumns}
                expandable={{
                    expandedRowRender: showByNamespace ? namespaceExpandedRowRender : expandedRowRender,
                    onExpand: handleExpand,
                    rowExpandable: (record) => !showByNamespace ? !_.isEmpty(record.scanReportId) && record.totalCve > 0 : !_.isEmpty(record.group),
                    // expandedRowKeys,
                }}
                locale={{
                    emptyText: <NoDataFound title={'No Data'} />
                }}
                pagination={(!_.isEmpty(myUpdatedData) && myUpdatedData.length > 10) ? {
                    showTotal: (total, range) => {
                        return (
                            <span className="text-white">
                                {`${range[0]}-${range[1]} of ${total && total.format()} issues`}
                            </span>
                        );
                    },
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    position: ["bottom", "left"],
                    className: "searchPagePagination px-0",
                    pageSizeOptions: [10, 20, 25],
                } : false}
            />
        </div>
    );
};

export default VulnerablePods;