import React, { useEffect, useState } from "react";

import { Checkbox, Table, Tooltip } from "antd";
import _ from "lodash";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { GoShield } from "react-icons/go";
import ComponentVulTable from "./componentVulTable";
import ContainerMetaList from "./containerMetaList";
import awsCi from "../../assets/img/scopy-tech-icons/aws.svg";
import jfrog from "../../assets/img/scopy-tech-icons/jfrog.svg";
import ibm from "../../assets/img/scopy-tech-icons/ibm.svg";
import azureRepos from "../../assets/img/azure-repos.svg";
import googleCloud from "../../assets/img/scopy-tech-icons/gcp.svg";
import { faDocker } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

export default function LayerDataTable({ dataSet }) {
  const [selectedLayer, setLayerId] = useState("all");
  const [layerVulnerabilities, setLayerVulnerabilities] = useState({});
  const severityOrder = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW', 'NA'];


  useEffect(() => {
    if (
      !_.isEmpty(dataSet) &&
      !_.isEmpty(dataSet.containerMetaInfo) &&
      !_.isEmpty(dataSet.containerMetaInfo.layers)
    ) {
      const newLayerVulnerabilities = {};
      for (const { layerId } of dataSet.containerMetaInfo.layers) {
        if (_.has(dataSet.containerLayerCveMetadataMap, layerId)) {
          let packages =
            dataSet.containerLayerCveMetadataMap[layerId].vulnerablePackageList;
            let layerHighestSeverity = 'NA';

          for (const vulPackage of packages) {
            const vulPackageDetails = dataSet.scanResult[vulPackage];
            if (
              !_.isEmpty(vulPackageDetails) &&
              !_.isEmpty(vulPackageDetails.cves)
            ) {
              const packageHighestSeverity = getHighestSeverity(vulPackageDetails.cves);

              // Update layer highest severity if the package has a higher severity
              if (severityOrder.indexOf(packageHighestSeverity) < severityOrder.indexOf(layerHighestSeverity)) {
                layerHighestSeverity = packageHighestSeverity;
              }

              // If we've found a CRITICAL vulnerability, we can stop checking this layer
              if (layerHighestSeverity === 'CRITICAL') break;
            }
          }
          newLayerVulnerabilities[layerId] = layerHighestSeverity;
        }
      }
      setLayerVulnerabilities(newLayerVulnerabilities);
    }
  }, [dataSet]);

  const getSelectedPackage = () => {
    let scanResult = {};
    if (!_.isEmpty(selectedLayer)) {
      if (selectedLayer === "all") {
        scanResult = dataSet.scanResult;
      } else {
        if (_.has(dataSet.containerLayerCveMetadataMap, selectedLayer)) {
          let packages =
            dataSet.containerLayerCveMetadataMap[selectedLayer]
              .vulnerablePackageList;
          _.forIn(dataSet.scanResult, (value, key) => {
            if (packages.includes(key)) {
              if (!_.isEmpty(value) && !_.isEmpty(value.cves)) {
                scanResult[key] = value;
              }
            }
          });
        }
      }
    }
    return scanResult;
  };

  const getRegistryAssetIcon = (registryType) => {
    const card = {
      alt: "title",
    };
    switch (registryType) {
      case "ibmcr":
        return (
          <img
            src={ibm}
            alt={"IBM"}
            className="img-fluid"
            style={{ width: 22 }}
          />
        );
      case "jfrog":
        return (
          <img
            src={jfrog}
            alt={"Jfrog"}
            className="img-fluid"
            style={{ width: 22 }}
          />
        );
      case "awsecr":
        return (
          <img
            src={awsCi}
            alt={"AWS"}
            className="img-fluid"
            style={{ width: 22 }}
          />
        );
      case "azurecr":
        return (
          <img
            src={azureRepos}
            alt={"Azure"}
            className="img-fluid"
            style={{ width: 22 }}
          />
        );
      case "dockerhub":
        return (
          <FontAwesomeIcon
            title="docker"
            icon={faDocker}
            style={{ width: 22 }}
          />
        );
      case "gcr":
        return (
          <img
            src={googleCloud}
            alt={"Google"}
            className="img-fluid"
            style={{ width: 22 }}
          />
        );
      default:
        return (
          <Tooltip title="Unable to identify the artifact">
            <span>
              <FontAwesomeIcon icon={faCircleQuestion} />
            </span>
          </Tooltip>
        );
    }
  };

  const getHighestSeverity = (cveDetails) => {
    if (!_.isEmpty(cveDetails)) {
      for (const severity of severityOrder) {
        if (cveDetails.some(cve => (cve.cvssV3BaseSeverity || cve.cvssV2BaseSeverity) === severity)) {
          return severity;
        }
      }
    }
    return 'NA';
  };

  const packagesInfo = getSelectedPackage();

  const imageMetaColumns = [
    {
      title: "",
      key: "checkbox",
      width: 30,
      render: (text, record) => (
        <Checkbox
          checked={selectedLayer === "all"}
          onChange={() => setLayerId("all")}
        />
      ),
    },
    {
      dataIndex: "",
      title: "name",
      render: (text, row) => (
        <span className="text-truncate me-2">
          <span className="pe-2">{getRegistryAssetIcon(row.registryType)}</span>
          {row.scanFileLocation}
        </span>
      ),
      ellipsis: true,
    },
  ];

  return (
    <div className="d-flex flex-column">
      <>
        <div className="pb-4">
          <div className="pb-2">Container Image</div>
          <Table
            showHeader={false}
            columns={imageMetaColumns}
            dataSource={[dataSet]}
            size="small"
            className="table table-striped custom-table mb-0"
            onRow={(row) => ({
              onClick: () => {
                setLayerId("all");
              },
              style: {
                color: "#adb5bd ",
                backgroundColor:
                  selectedLayer === "all" ? "rgb(37, 43, 54)" : "transparent",
                cursor: "pointer",
              },
            })}
            pagination={false}
          />
        </div>
        {!_.isEmpty(dataSet.containerMetaInfo) ? (
          !_.isEmpty(dataSet.containerMetaInfo.layers) ? (
            <div className="row">
              <div
                className={`col-sm-4`}
                style={{ borderRight: "1px solid #2b313e" }}
              >
                <div>
                  <ContainerMetaList
                    layers={dataSet.containerMetaInfo.layers}
                    selectedLayer={selectedLayer}
                    setLayerId={setLayerId}
                    layerPackageMap={dataSet.containerLayerCveMetadataMap}
                    layerVulnerabilities={layerVulnerabilities}
                  />
                </div>
              </div>
              <div className="col-sm-8">
                {!_.isEmpty(packagesInfo) && (
                  <ComponentVulTable dataSet={packagesInfo} />
                )}
                {selectedLayer !== null && _.isEmpty(packagesInfo) && (
                  <NoDataFound
                    title={"No Vulnerability Data Found"}
                    desc={"There were no vulnerabilities found for this layer"}
                  />
                )}
              </div>
            </div>
          ) : (
            <NoDataFound
              title={"No Layers Found"}
              desc={"There were no layers found for the image"}
            />
          )
        ) : (
          <NoDataFound
            title={"No Layers Found"}
            desc={"There were no layers found for the image"}
          />
        )}
      </>
    </div>
  );
}
