import { config } from '../../config';

// console.log("Config here ", config, config.REACT_APP_API_URL);

export const GlobalConst = {
    SCAN_REPO_LIMIT: 5,
    BASE_URL: `${config.PUBLIC_URL}`,
    APP_URL: `${config.REACT_APP_APP_URL}`,
    REDIRECT_URL: `${config.REACT_APP_REDIRECT_URL}`,
    API_URL: `${config.REACT_APP_API_URL}`,
    AUTH_API_URL: `${config.REACT_APP_AUTH_API_URL}`,
    AUTH_APP_URL: `${config.REACT_APP_AUTH_APP_URL}`,
    CLIENT_SECRET: `${config.REACT_APP_CLIENT_SECRET}`,
    CLIENT_ID: `${config.REACT_APP_CLIENT_ID}`,
    CHART_COLORS: ["#2caffe", "#544fc5", "#fa4b42", "#00e272", "#fe6a35", "#9fb6db", "#d568fb", "#2ee0ca", "#feb56a", "#91e8e1", "#f0a134", "#f0a134", "#da4a47", "#d67043", "#f1a236", "#e0e6b7"],
    CHART_GRADIENT_COLORS: ['#126293', "#1a1678", "#912924", "#028745", "#973b1a", "#3f5270", "#813b99", "#1c9182", "#a55d15", "#488b85", "#a76f21", "red", "#7b0002", "#964a00", "#cc7103", "#448300"],
    LINK_COLORS: ["#91e8e1", "#2caffe", "#544fc5", "#544fc5", "#544fc5", "#544fc5", "#544fc5"],
    LINK_GRADIENT_COLORS: ["#2caffe", "#544fc5", "#f60000", "#f6800d", "#FFFF00", "#85ff00", '#999999'],
    GIT_REPO_LIST_PAGE_COUNT: 20,
    CRITICAL_COLOR: 'rgba(218,74,71, 1)',
    HIGH_COLOR: 'rgba(214, 112, 67, 1)',
    MEDIUM_COLOR: 'rgba(241, 162, 54, 1)',
    LOW_COLOR: 'rgba(224, 230, 183, 1)',
    NA_COLOR: 'rgba(145, 145, 145, 1)',
    POLLING_LIMIT: 5000,
    // DAST_POLLING_LIMIT: 120000
    DAST_POLLING_LIMIT: 5000,
    API_POLLING_LIMIT: 5000,
    AI_CHART_COLORS: ["#da4a47", "#d67043", "#f1a236", "#16af16"],
    AI_CHART_GRADIENT_COLORS: ["#7b0002", "#964a00", "#cc7103", "#098709"],
    EMAIL_REGEX: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    RESTRICTED_EMAIL_DOMAINS: ["gmail", "yahoo", "outlook", "aol", "icloud", "gmx", "mail", "zoho", "protonmail", "yandex", "hotmail", "live", "qq", "163", "126", "sina", "naver", "daum", "nate", "hanmail", "rediffmail", "indiatimes", "mail", "list", "bk", "rambler", "inbox"],
    PRODUCT_KEYS :{
        SCA: 'scopy',
        SAST:'prosast',
        DAST:'dast',
        API:'api',
        CLOUD_SECURITY:'cloudsecurity',
        CONTEK:'contek',
        THREAT_VISION: 'threatvision',
        KUBERNETES: 'k8ssecurity'
    },
    DATE_KEYS_METADATA: ['created', 'updated', 'lockedTime', 'creationTimestamp', 'lastStartTimestamp', 'lastStopTimestamp'],
    EXCLUDE_METADATA_KEYS_INCLUDING_KEYWORD: ['key', 'Cert'],
    AUTO_FIX_ECOSYSTEMS_TO_CHECK: ['maven', 'pypi', 'npm','go'],
    EXCLUDED_GRAPH_CLICK_KEYS: ['gke'],
    EXCLUDED_CLOUD_SCAN_STATUS: ['inventory_scan'],
    CLOUD_SECURITY_STATE_GREEN_VALUES: ['running', 'runnable', 'enabled'],
    CLOUD_SECURITY_STATE_RED_VALUES: ['terminated', 'stopped']
}