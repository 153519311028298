import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import VulnerabilityTab from './vulnerabilityTab';

export default function VulnerabilityRemediationTab({ selectedRemediation }) {
    const [items, setItems] = useState([]);
    useEffect(() => {
        if (!_.isEmpty(selectedRemediation) && selectedRemediation.vulnGroup && !_.isEmpty(selectedRemediation.vulnGroup.vulns)) {
            let vulnerabilities = [];
            selectedRemediation.vulnGroup.vulns.forEach((row, index) => {
                vulnerabilities.push({
                    key: `${index + 1}`,
                    label: <b>{row.title} (<span className='text-danger'>L: {row.line_number}</span>) </b>,
                    children: <VulnerabilityTab selectedVulnerability={row} showTitle={false} />,
                });
            });
            setItems(vulnerabilities);
        }
    }, [selectedRemediation]);

    return (
        <div className='my-1'>
            <Collapse accordion items={items} />
        </div>
    );
}