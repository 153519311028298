import React, { useEffect } from 'react';
import _ from 'lodash';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import CliTabs from './cliTabs';
import SBOMTabs from './sbomTabs';
import CISOTabs from './cisoTabs';


export default function Downloads() {

    useEffect(() => {
        async function isSessionActive() {
            let ls = await localStorage.getItem("__t");
            if (_.isEmpty(ls)) {
                // history.push("/");
                // dispatch(setLoginModalViewStatus(true));
                window.location.href = GlobalConst.BASE_URL + '/login';
            }
        }
        isSessionActive();
    }, []);

    useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);


    return (
        <section className="mx-3">
            <div className="mb-3">
                <div className="">
                    <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-0 text-white d-flex align-items-center justify-content-start">
                        <FontAwesomeIcon icon={faDownload} className="me-2" />
                        Downloads
                    </h2>
                </div>
            </div>


            <section className='p-4 px-0'>

                <div className='mb-4' id="cli">
                    <h5 className='text-white mb-1'>CLI</h5>
                    <CliTabs />
                </div>

                <div className='mb-4' id="sbomScanner">
                    <h5 className='text-white mb-1'>SBOM Scanner</h5>
                    <SBOMTabs />
                </div>

                <div className='mb-4' id="cisoConsole">
                    <h5 className='text-white mb-1'>CISO Console</h5>
                    <CISOTabs />
                </div>




                {/* <div className='mb-4'>

                    <h4 className='text-white fw-normal other-header pb-2 mb-4'>Container Scanners</h4>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <div className='card custom-card text-center p-4'>
                                <img src={linux} alt={"Linux CLI"} className='mb-3' style={{ width: 120, margin: '0 auto' }} />
                                <h4 className='text-white text-center fw-normal mb-2'>Linux CLI</h4>
                                <div className='mt-2 text-center'>
                                    <a className='btn btn-primary' href='https://storage.googleapis.com/digitalassets-sec1/sec1-k8s-vuln-scanner-linux.tar.gz'><DownloadOutlined className="me-2" />Download</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-2'>
                            <div className='card custom-card text-center p-4'>
                                <img src={macos} alt={"macOs CLI"} className='mb-3' style={{ width: 120, margin: '0 auto' }} />
                                <h4 className='text-white fw-normal mb-2'>macOS CLI</h4>
                                <div className='mt-2 text-center'>
                                    <a className='btn btn-primary' href='https://storage.googleapis.com/digitalassets-sec1/sec1-k8s-vuln-scanner-macos.tar.gz'><DownloadOutlined className="me-2" />Download</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}







                {/* <div className='mb-4'>

                    <h4 className='text-white fw-normal other-header pb-2 mb-4'>CLI</h4>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <div className='card custom-card text-center p-4'>
                                <img src={linux} alt={"Linux CLI"} className='mb-3' style={{ width: 120, margin: '0 auto' }} />
                                <h4 className='text-white text-center fw-normal mb-2'>Linux CLI</h4>
                                <div className='mt-2 text-center'>
                                    <a className='btn btn-primary' href='https://storage.googleapis.com/digitalassets-sec1/sec1-k8s-vuln-scanner-linux.tar.gz'><DownloadOutlined className="me-2" />Download</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-2'>
                            <div className='card custom-card text-center p-4'>
                                <img src={macos} alt={"macOs CLI"} className='mb-3' style={{ width: 120, margin: '0 auto' }} />
                                <h4 className='text-white fw-normal mb-2'>macOS CLI</h4>
                                <div className='mt-2 text-center'>
                                    <a className='btn btn-primary' href='https://storage.googleapis.com/digitalassets-sec1/sec1-k8s-vuln-scanner-macos.tar.gz'><DownloadOutlined className="me-2" />Download</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-3'>

                        </div>
                        <div className='col-sm-3'></div>
                    </div>
                </div> */}





                {/* <div className='mb-4'>

                    <h4 className='text-white fw-normal other-header pb-2 mb-4'>SBOM Scanners</h4>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <div className='card custom-card text-center p-4'>
                                <img src={linux} alt={"Linux CLI"} className='mb-3' style={{ width: 120, margin: '0 auto' }} />
                                <h4 className='text-white text-center fw-normal mb-2'>Linux CLI</h4>
                                <div className='mt-2 text-center'>
                                    <a className='btn btn-primary' href='https://storage.googleapis.com/digitalassets-sec1/sec1-k8s-vuln-scanner-linux.tar.gz'><DownloadOutlined className="me-2" />Download</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-2'>
                            <div className='card custom-card text-center p-4'>
                                <img src={macos} alt={"macOs CLI"} className='mb-3' style={{ width: 120, margin: '0 auto' }} />
                                <h4 className='text-white fw-normal mb-2'>macOS CLI</h4>
                                <div className='mt-2 text-center'>
                                    <a className='btn btn-primary' href='https://storage.googleapis.com/digitalassets-sec1/sec1-k8s-vuln-scanner-macos.tar.gz'><DownloadOutlined className="me-2" />Download</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-3'>

                        </div>
                        <div className='col-sm-3'></div>
                    </div>
                </div> */}


















                {/* <div className='mb-4'>

                    <h4 className='text-white fw-normal other-header pb-2 mb-4'>CISO Console</h4>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <div className='card custom-card text-center p-4'>
                                <img src={linux} alt={"Linux CLI"} className='mb-3' style={{ width: 120, margin: '0 auto' }} />
                                <h4 className='text-white text-center fw-normal mb-2'>Linux CLI</h4>
                                <div className='mt-2 text-center'>
                                    <a className='btn btn-primary' href='https://storage.googleapis.com/digitalassets-sec1/sec1-k8s-vuln-scanner-linux.tar.gz'><DownloadOutlined className="me-2" />Download</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-2'>
                            <div className='card custom-card text-center p-4'>
                                <img src={macos} alt={"macOs CLI"} className='mb-3' style={{ width: 120, margin: '0 auto' }} />
                                <h4 className='text-white fw-normal mb-2'>macOS CLI</h4>
                                <div className='mt-2 text-center'>
                                    <a className='btn btn-primary' href='https://storage.googleapis.com/digitalassets-sec1/sec1-k8s-vuln-scanner-macos.tar.gz'><DownloadOutlined className="me-2" />Download</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-3'>

                        </div>
                        <div className='col-sm-3'></div>
                    </div>
                </div> */}

















            </section>
        </section>
    );
}