import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'antd';
import { Formik, Form } from 'formik';
import { OAuth2OptionsHelper } from "./oauth2OptionsHelper";

import { HeadersOptionsHelper } from "./headersOptionsHelper";
import { handleDastFormSubmit } from '../../helper/apiHelper';
// const { darkAlgorithm } = theme;




const RescanConfigModal = ({ details, configType, visible, onClose }) => {
    const [headers, setHeaders] = useState([{ name: '', value: '' }]);
    const formikRef = useRef(null);

    const convertHeadersToArray = (headers) => {
        return Object.keys(headers).map(key => ({
            name: key,
            value: headers[key]
        }));
    };

    useEffect(() => {
        if (configType === 'HEADERS' && details.headers) {
            const convertedHeaders = convertHeadersToArray(details.headers);
            setHeaders(convertedHeaders);
            details.headers = convertedHeaders; // Update details object
            // console.log("useEffect headers: ", convertedHeaders);
        }
    }, [configType, details]);
    console.log("details : ", details, configType, visible);
    if (!details) return null;



    const getInitialValues = () => {
        if (configType === 'OAUTH2') {
            return {
                url: details.url,
                authUrl: details.authUrl,
                realmName: details.realmName,
                clientId: details.clientId,
                clientSecret: details.clientSecret,
                userId: details.userId,
                password: details.password,
                scanType: details.scanType,
                scanAuthType: configType,
                configid: details.configId,
            }
        } else if (configType === 'HEADERS') {
            return {
                url: details.url,
                scanType: details.scanType,
                scanAuthType: configType,
                configId: details.configId,
                headers: headers,
                schemaFile: null,
                fileName: details.fileName,
                fileId: details.fileId
            }
        }
    }

    const handleFormSubmit = async (values, setSubmitting) => {
        // console.log("submit data : ", values, headers)
        if (headers) {
            values.headers = headers;
        }
        try {
            const response = handleDastFormSubmit(values, 'config')
            // console.log('API response:', response);
            onClose();
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setSubmitting(false);
        }
    };

    const getConfigViewBasedOnType = (setFieldValue, setTouched, values) => {
        if (configType === "OAUTH2") {
            return <OAuth2OptionsHelper values={values} setFieldValue={setFieldValue} setTouched={setTouched} />;
        } else if (configType === "HEADERS") {
            return <HeadersOptionsHelper headers={headers} setHeaders={setHeaders} values={values} updateHeaders={setHeaders} />;
        } else {
            return <>No Config Found</>;
        }
    }

    // console.log("details inside : ", details, configType, visible);
    return (
        <>
            {
                visible === true &&
                <Modal
                    title={details.url ? `${configType} Configuration - ${details.url}` : `${configType} Configuration`}
                    open={visible}
                    onCancel={onClose}
                    footer={null}
                    width={800}
                >

                    <Formik
                        initialValues={getInitialValues()}
                        onSubmit={(values, { setSubmitting }) => {
                            handleFormSubmit(values, setSubmitting);
                        }}
                        innerRef={formikRef}
                    >

                        {({ isSubmitting, setFieldValue, setTouched, values }) => (
                            <Form>
                                {details && details.scanType == 'api' &&
                                    <>
                                        <div className="d-flex align-items-center justify-content-start w-100 gap-3">
                                            <div>Uploaded API Schema: {details.fileName}</div>
                                            <div>Upload API Schema:</div>
                                            <div>
                                                <input
                                                    id="schemaFile"
                                                    name="schemaFile"
                                                    type="file"
                                                    accept=".json"
                                                    className="input input-rounded orm-control"
                                                    onChange={(event) => {
                                                        setFieldValue("schemaFile", event.currentTarget.files[0]);
                                                    }}
                                                    style={{ padding: '10px 20px' }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                {getConfigViewBasedOnType(setFieldValue, setTouched, values)}

                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                    <button type="button" className="btn btn-secondary" onClick={onClose} style={{ marginRight: '10px' }}>
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                        Save
                                    </button>
                                </div>
                            </Form>
                        )}

                    </Formik>

                </Modal>
            }
        </>
    );
};

export default RescanConfigModal;