import React, { useState } from "react";
import ReactEcharts from "echarts-for-react";
import * as echarts from 'echarts';

// Theme configuration
let colorPalette = [
    '#FF4560',  // Critical - Red
    '#FEB019',  // High - Orange
    '#00E396',  // Medium - Green
    '#008FFB',  // Low - Blue
];

let contrastColor = '#B9B8CE';
let backgroundColor = '#100C2A';

// Theme definition
let theme = {
    darkMode: true,
    color: colorPalette,
    backgroundColor: 'transparent',
    textStyle: {
        color: contrastColor
    },
    title: {
        textStyle: {
            color: '#EEF1FA'
        },
        subtextStyle: {
            color: '#B9B8CE'
        }
    },
    gauge: {
        title: {
            color: contrastColor
        }
    }
};

// Register the theme
echarts.registerTheme('risk_theme', theme);

export default function RiskGaugeChart() {
    // Dummy data - value between 0 and 1
    // 0-0.25: Low Risk
    // 0.26-0.5: Medium Risk
    // 0.51-0.75: High Risk
    // 0.76-1: Critical Risk
    const [options] = useState({
        series: [
            {
                type: 'gauge',
                startAngle: 180,
                endAngle: 0,
                center: ['50%', '95%'],
                radius: '180%',
                min: 0,
                max: 1,
                splitNumber: 4,
                axisLine: {
                    lineStyle: {
                        width: 5,
                        color: [
                            [0.25, '#e0e6b7'],  // Low Risk - Blue
                            [0.5, '#f1a236'],   // Medium Risk - Green
                            [0.75, '#d67043'],  // High Risk - Orange
                            [1, '#da4a47']      // Critical Risk - Red
                        ]
                    }
                },
                // pointer: {
                //     icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                //     length: '12%',
                //     width: 15,
                //     offsetCenter: [0, '-60%'],
                //     itemStyle: {
                //         color: '#fff'
                //     }
                // },
                pointer: {
                    itemStyle: {
                      color: 'auto',
                      opacity: 0.6
                    }
                  },
                axisTick: {
                    length: 3,
                    distance: 1,
                    lineStyle: {
                        color: 'auto',
                        width: 1
                    }
                },
                splitLine: {
                    length: 8,
                    distance: 1,
                    lineStyle: {
                        color: 'auto',
                        width: 1
                    }
                },
                axisLabel: {
                    color: '#fff',
                    fontSize: 8,
                    distance: -50,
                    rotate: 'tangential',
                    formatter: function (value) {
                        if (value === 0.875) {
                            return 'Critical';
                        } else if (value === 0.625) {
                            return 'High';
                        } else if (value === 0.375) {
                            return 'Medium';
                        } else if (value === 0.125) {
                            return 'Low';
                        }
                        return '';
                    }
                },
                title: {
                    offsetCenter: [0, '-10%'],
                    fontSize: 8,
                    color: '#fff'
                },
                detail: {
                    fontSize: 14,
                    offsetCenter: [0, '-35%'],
                    valueAnimation: true,
                    formatter: function (value) {
                        // Convert value to risk level
                        if (value <= 0.25) return 'Low Risk';
                        if (value <= 0.5) return 'Medium Risk';
                        if (value <= 0.75) return 'High Risk';
                        return 'Critical Risk';
                    },
                    color: 'inherit'
                },
                data: [
                    {
                        value: 0.7, // This is your risk value (0.7 = 70% = High Risk)
                        name: ''
                    }
                ]
            }
        ]
    });

    return (
        <div className="w-full bg-gray-900 rounded-lg">
            <ReactEcharts
                option={options}
                theme="risk_theme"
                style={{ 
                    height: '80px',
                    width: '100%'
                }}
                opts={{ renderer: 'svg' }}
            />
            {/* Legend */}
            {/* <div className="flex justify-center gap-4 mt-4">
                <div className="flex items-center">
                    <div className="w-3 h-3 rounded-full bg-[#FF4560] mr-2"></div>
                    <span className="text-white text-sm">Critical (76-100%)</span>
                </div>
                <div className="flex items-center">
                    <div className="w-3 h-3 rounded-full bg-[#FEB019] mr-2"></div>
                    <span className="text-white text-sm">High (51-75%)</span>
                </div>
                <div className="flex items-center">
                    <div className="w-3 h-3 rounded-full bg-[#00E396] mr-2"></div>
                    <span className="text-white text-sm">Medium (26-50%)</span>
                </div>
                <div className="flex items-center">
                    <div className="w-3 h-3 rounded-full bg-[#008FFB] mr-2"></div>
                    <span className="text-white text-sm">Low (0-25%)</span>
                </div>
            </div> */}
        </div>
    );
}