import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faP,
  faPen,
  faPlusSquare,
  faTrowelBricks,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setAllIntegratedSystems } from "../../shared/redux/reducers/integrationReducer";

// images
import github from "../../assets/img/github-white.svg";
import gitlab from "../../assets/img/gitlab.svg";
import azureRepos from "../../assets/img/azure-repos.svg";
import kubernetes from "../../assets/img/kubernetes.svg";
import googleCloud from "../../assets/img/scopy-tech-icons/gcp.svg";
import cli from "../../assets/img/scopy-tech-icons/cli.svg";
import jenkins from "../../assets/img/scopy-tech-icons/jenkins.svg";
import teamcity from "../../assets/img/scopy-tech-icons/teamcity.svg";
import azurePipelines from "../../assets/img/scopy-tech-icons/azure-pipelines.svg";
import circleCi from "../../assets/img/scopy-tech-icons/circleci.svg";
import githubActions from "../../assets/img/gitHubActions.svg";
import awsCi from "../../assets/img/scopy-tech-icons/aws.svg";
import jfrog from "../../assets/img/scopy-tech-icons/jfrog.svg";
import ibm from "../../assets/img/scopy-tech-icons/ibm.svg";
import bitbucket from "../../assets/img/bitbucket.svg";

import Loader from "../../shared/sharedComponents/loader";
import SettingsPage from "../settingsPage";
import { faDocker } from "@fortawesome/free-brands-svg-icons";
import { GlobalConst } from "../../shared/appConfig/globalConst";

import oracle from "../../assets/img/techIcons/oracle.png";

export default function IntegrationsListPage() {
  const history = useHistory();
  // const reduxState = useSelector(state => state);
  const dispatch = useDispatch();
  const [integratedSystems, setIntegratedSystems] = useState({});

  const [showLoader, setShowLoader] = useState(false);


  useEffect(() => {
    setShowLoader(true);
    axios
      .get(`${GlobalConst.API_URL}/auth/integrate/user-integrations`)
      .then(op => {
        setShowLoader(false);
        // console.log("Output", op);
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          setIntegratedSystems(op.data);
          dispatch(setAllIntegratedSystems(op.data));
        }
      })
      .catch(e => {
        setShowLoader(false);
        console.log("Exception:", e);
      })
  }, [])

  const handleAzureClick = (e) => {
    e.preventDefault();
    history.push('/integrations-list/azure');
  }

  const handleGCPClick = (e) => {
    e.preventDefault();
    if (!_.isEmpty(getIntegratedState('gcp'))) {
      history.push(`/integrations-list/gcp/${getIntegratedState('gcp').integrationId}`);
      return;
    }
    history.push('/integrations-list/gcp');
  }

  const handleAWSClick = (e) => {
    e.preventDefault();
    if (!_.isEmpty(getIntegratedState('aws'))) {
      history.push(`/integrations-list/aws/${getIntegratedState('aws').integrationId}`);
      return;
    }
    history.push('/integrations-list/aws');
  }

  const handleTeamsClick = (e) => {
    e.preventDefault();
    history.push("/integrations-list/notifications/microsoft-teams-notification-settings");
  }

  const handleSlackClick = (e) => {
    e.preventDefault();
    history.push("/integrations-list/notifications/slack-notification-settings");
  }

  const handleEmailClick = (e) => {
    e.preventDefault();
    history.push("/integrations-list/notifications/email-notification-settings");
  }

  const handleGithubClick = (e) => {
    e.preventDefault();
    history.push("/integrations-list/github");
  }

  const handleBitbucketClick = (e) => {
    e.preventDefault();
    history.push("/integrations-list/bitbucket");
  }

  const handleAddKubernetesClick = (e) => {
    e.preventDefault();
    history.push('/integrations-list/k8s');
  }

  const getIntegratedState = (keyToCheck) => {
    if (_.has(integratedSystems, keyToCheck)) {
      const value = integratedSystems[keyToCheck];
      if (value.isIntegrated) {
        return value;
      }
      else {
        return false;
      }
    } else {
      return false;
    }
  }



  // const generateContainerCardList = (cardsData) => {
  //     return cardsData.map((card, index) => (
  //         <div className="col-sm-2" key={index}>
  //             <div
  //                 className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card position-relative"
  //                 onClick={() => onRegistryClick(card.path)}
  //             >
  //                 {(!_.isEmpty(integratedSystems) && getIntegratedState(card.integrationKey)) && <button onClick={() => onRegistryClick(card.path)}
  //                     type="button" className="btn btn-dark btn-sm" style={{ position: 'absolute', top: 2, right: 2 }}>
  //                     <FontAwesomeIcon icon={faGear} />
  //                 </button>}
  //                 <div className="source-control-image-container">
  //                     {card.name !== 'Docker' ? <img src={card.image} alt={card.alt} className="img-fluid" /> : card.image}
  //                 </div>
  //                 <h5 className="mb-2 pt-3">{card.title}</h5>
  //             </div>
  //         </div>
  //     ));
  // };



  // const handleGCPClick = (e) => {
  //   e.preventDefault();
  //   history.push("/integrations-list/gcp");
  // };

  // const handleTeamsClick = (e) => {
  //   e.preventDefault();
  //   history.push(
  //     "/integrations-list/notifications/microsoft-teams-notification-settings"
  //   );
  // };

  // const handleSlackClick = (e) => {
  //   e.preventDefault();
  //   history.push(
  //     "/integrations-list/notifications/slack-notification-settings"
  //   );
  // };

  // const handleEmailClick = (e) => {
  //   e.preventDefault();
  //   history.push(
  //     "/integrations-list/notifications/email-notification-settings"
  //   );
  // };

  // const handleGithubClick = (e) => {
  //   e.preventDefault();
  //   history.push("/integrations-list/github");
  // };

  // const getIntegratedState = (keyToCheck) => {
  //   if (_.has(integratedSystems, keyToCheck)) {
  //     const value = integratedSystems[keyToCheck];
  //     if (value.isIntegrated) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // };

  const onRegistryClick = (path, registryType) => {
    if (
      !_.isEmpty(integratedSystems) &&
      !_.isEmpty(integratedSystems[registryType]) &&
      !_.isEmpty(integratedSystems[registryType].integrationId)
    ) {
      history.push(`/integrations-list/${path}/${integratedSystems[registryType].integrationId}`);
    } else {
      history.push(`/integrations-list/${path}`);
    }
  };

  const generateContainerCardList = (cardsData) => {
    return cardsData.map((card, index) => (
      <div className="col-sm-2" key={index}>
        <div
          className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card position-relative"
          onClick={() => onRegistryClick(card.path, card.integrationKey)}
        >
          {!_.isEmpty(integratedSystems) &&
            getIntegratedState(card.integrationKey) && (
              <button
                onClick={() => onRegistryClick(card.path, card.integrationKey)}
                type="button"
                className="btn btn-dark btn-sm"
                style={{ position: "absolute", top: 2, right: 2 }}
              >
                <FontAwesomeIcon icon={faGear} />
              </button>
            )}
          <div className="source-control-image-container">
            {card.name !== "Docker" ? (
              <img src={card.image} alt={card.alt} className="img-fluid" />
            ) : (
              card.image
            )}
          </div>
          <h5 className="mb-2 pt-3">{card.title}</h5>
        </div>
      </div>
    ));
  };

  const cardsData = [
    {
      name: "Docker",
      image: <FontAwesomeIcon icon={faDocker} style={{ fontSize: 20 }} />,
      alt: "Docker",
      title: "Docker",
      path: "docker-registry",
      integrationKey: "dockerhub",
    },
    {
      name: "Azure Container Registry",
      image: azureRepos,
      alt: "Azure Container Registry",
      title: "Azure Container Registry",
      path: "azure-registry",
      integrationKey: "azurecr",
    },
    {
      name: "AWS",
      image: awsCi,
      alt: "AWS",
      title: "AWS Container Registry",
      path: "aws-registry",
      integrationKey: "awsecr",
    },
    {
      name: "Google Artifact Registry",
      image: googleCloud,
      alt: "Google Artifact Registry",
      title: "Google Artifact Registry",
      path: "google-registry",
      integrationKey: "gcr",
    },
    {
      name: "JFrog",
      image: jfrog,
      alt: "JFrog",
      title: "JFrog",
      path: "jfrog-registry",
      integrationKey: "jfrog",
    },
    {
      name: "IBM Container Registry",
      image: ibm,
      alt: "IBM Container Registry",
      title: "IBM Container Registry",
      path: "ibm-registry",
      integrationKey: "ibmcr",
    },
    {
      name: "Oracle Container Registry",
      image: oracle,
      alt: "Oracle Container Registry",
      title: "Oracle Container Registry",
      path: "oracle-registry",
      integrationKey: "ocir",
    },
  ];

  return (
    <section className="mx-3">
      <section>
        <div className="mb-5">
          <div className="">
            <h2
              style={{ lineHeight: 1 }}
              className="sotcox-title mb-0 text-white d-flex align-items-center justify-content-start"
            >
              <FontAwesomeIcon icon={faTrowelBricks} className="me-2" />
              Integrations
            </h2>
          </div>
        </div>
        <div className={"mb-4"}>
          <div className="">
            <div className="card-body">
              <h3
                className="title pb-2 mb-3 text-white fw-normal"
                style={{ borderBottom: "1px solid #2b313e" }}
              >
                Source Control
              </h3>
              {/* <Row> */}
              <div className="row">
                <div className="col-sm-2">
                  <div
                    className={`card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center position-relative source-control-card`}
                    onClick={handleAzureClick}
                  >
                    {/* {(!_.isEmpty(integratedSystems) && getIntegratedState('azure-scm')) && <span className="badge text-bg-success" style={{ position: 'absolute', top: 2, left: 2 }}>Configured</span>} */}
                    {!_.isEmpty(integratedSystems) &&
                      getIntegratedState("azure-scm") && (
                        <button
                          onClick={handleAzureClick}
                          type="button"
                          className="btn btn-dark btn-sm"
                          style={{ position: "absolute", top: 2, right: 2 }}
                        >
                          <FontAwesomeIcon icon={faGear} />
                        </button>
                      )}
                    <div className="source-control-image-container">
                      <img
                        src={azureRepos}
                        alt="Azure Repos"
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="mb-2 pt-3">Azure Repos</h5>
                  </div>
                </div>
                <div className="col-sm-2" onClick={handleGithubClick}>
                  <div className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card position-relative">
                    {/* {(!_.isEmpty(integratedSystems) && getIntegratedState('github')) && <span className="badge text-bg-success" style={{ position: 'absolute', top: 2, left: 2 }}>Configured</span>} */}
                    {!_.isEmpty(integratedSystems) &&
                      getIntegratedState("github") && (
                        <button
                          onClick={handleGithubClick}
                          type="button"
                          className="btn btn-dark btn-sm"
                          style={{ position: "absolute", top: 2, right: 2 }}
                        >
                          <FontAwesomeIcon icon={faGear} />
                        </button>
                      )}
                    <div className="source-control-image-container">
                      <img src={github} alt="GitHub" className="img-fluid" />
                    </div>
                    <h5 className="mb-2 pt-3">GitHub</h5>
                  </div>
                </div>
                <div className="col-sm-2" onClick={handleBitbucketClick}>
                  <div className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card position-relative">
                    {!_.isEmpty(integratedSystems) &&
                      getIntegratedState("bitbucket") && (
                        <button
                          onClick={handleBitbucketClick}
                          type="button"
                          className="btn btn-dark btn-sm"
                          style={{ position: "absolute", top: 2, right: 2 }}
                        >
                          <FontAwesomeIcon icon={faGear} />
                        </button>
                      )}
                    <div className="source-control-image-container">
                      <img src={bitbucket} alt="Bitbucket" className="img-fluid" />
                    </div>
                    <h5 className="mb-2 pt-3">Bitbucket</h5>
                  </div>
                </div>

                {/* <div className="card custom-card p-2 py-4 me-3 text-center d-flex align-items-center justify-content-center source-control-card disabled">
                                    <div className="p-2 mb-3 source-control-image-container" >
                                        <img src={githubBlack} alt="GitHub Enterprise" className="img-fluid" />
                                    </div>
                                    <h5>GitHub Enterprise</h5>
                                </div> */}
                {/* <div className="card custom-card p-2 py-4 me-3 text-center d-flex align-items-center justify-content-center source-control-card disabled">
                                    <div className="p-2 mb-3 source-control-image-container" >
                                        <img src={gitlab} alt="GitLab" className="img-fluid" />
                                    </div>
                                    <h5>GitLab</h5>
                                </div>
                                
                                <div className="card custom-card p-2 py-4 me-3 text-center d-flex align-items-center justify-content-center source-control-card disabled">
                                    <div className="p-2 mb-3 source-control-image-container" >
                                        <img src={bitbucketCloud} alt="Bitbucket Cloud App" className="img-fluid" />
                                    </div>
                                    <h5>Bitbucket Cloud App</h5>
                                </div>
                                <div className="card custom-card p-2 py-4 me-3 text-center d-flex align-items-center justify-content-center source-control-card disabled">
                                    <div className="p-2 mb-3 source-control-image-container" >
                                        <img src={bitbucketCloud} alt="Bitbucket Cloud" className="img-fluid" />
                                    </div>
                                    <h5>Bitbucket Cloud</h5>
                                </div> */}
              </div>
              {/* </Row> */}
            </div>
          </div>
        </div>
        <div className={"mb-4"}>
          <div className="">
            <div className="card-body">
              <h3
                className="title pb-2 mb-3 text-white fw-normal"
                style={{ borderBottom: "1px solid #2b313e" }}
              >
                Cloud
              </h3>
              <div className="row">
                <div className="col-sm-2">
                  <div
                    className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card"
                    onClick={handleGCPClick}
                  >
                    {!_.isEmpty(integratedSystems) &&
                      getIntegratedState("gcp") && (
                        <button
                          onClick={handleGCPClick}
                          type="button"
                          className="btn btn-dark btn-sm"
                          style={{ position: "absolute", top: 2, right: 2 }}
                        >
                          <FontAwesomeIcon icon={faGear} />
                        </button>
                      )}
                    <div className="source-control-image-container">
                      <img
                        src={googleCloud}
                        alt="Google Cloud Platform"
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="mb-2 pt-3">Google Cloud Platform</h5>
                  </div>
                </div>

                <div className="col-sm-2">
                  <div
                    className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card"
                    onClick={handleAWSClick}
                  >
                    {!_.isEmpty(integratedSystems) &&
                      getIntegratedState("aws") && (
                        <button
                          onClick={handleAWSClick}
                          type="button"
                          className="btn btn-dark btn-sm"
                          style={{ position: "absolute", top: 2, right: 2 }}
                        >
                          <FontAwesomeIcon icon={faGear} />
                        </button>
                      )}
                    <div className="source-control-image-container">
                      <img
                        src={awsCi}
                        alt="AWS Cloud"
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="mb-2 pt-3">AWS Cloud</h5>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div
                    className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card"
                    onClick={handleAddKubernetesClick}
                  >
                    {!_.isEmpty(integratedSystems) &&
                      getIntegratedState("k8s") && (
                        <button
                          onClick={handleAddKubernetesClick}
                          type="button"
                          className="btn btn-dark btn-sm"
                          style={{ position: "absolute", top: 2, right: 2 }}
                        >
                          <FontAwesomeIcon icon={faGear} />
                        </button>
                      )}
                    <div className="source-control-image-container">
                      <img
                        src={kubernetes}
                        alt="Kubernetes"
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="mb-2 pt-3">Kubernetes</h5>
                  </div>
                </div>
                {/* <div className="col-sm-2">
                  <div
                    className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card"
                    onClick={handleAzureClick}
                  >
                    <div className="source-control-image-container">
                      <img
                        src={kubernetes}
                        alt="Kubernetes"
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="mb-2 pt-3">Kubernetes</h5>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className={"mb-4"}>
          <div className="">
            <div className="card-body">
              <h3
                className="title pb-2 mb-3 text-white fw-normal"
                style={{ borderBottom: "1px solid #2b313e" }}
              >
                Continuous integration
              </h3>
              <div className="row">
                <div className="col-sm-2">
                  <div
                    className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card"
                    onClick={() => {
                      window
                        .open(
                          "https://docs.sec1.io/user-docs/integrate-with-sec1/cli-integrations/commands",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    <div className="source-control-image-container">
                      <img
                        src={cli}
                        alt="CLI"
                        className="img-fluid"
                        style={{ width: 55 }}
                      />
                    </div>
                    <h5 className="mb-2 pt-3">CLI</h5>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div
                    className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card"
                    onClick={() => {
                      window
                        .open(
                          "https://docs.sec1.io/user-docs/integrate-with-sec1/ci-cd-integrations/jenkins-plugin-integration",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    <div className="source-control-image-container">
                      <img src={jenkins} alt="Jenkins" className="img-fluid" />
                    </div>
                    <h5 className="mb-2 pt-3">Jenkins</h5>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div
                    className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card"
                    onClick={() => {
                      window
                        .open(
                          "https://docs.sec1.io/user-docs/integrate-with-sec1/ci-cd-integrations/teamcity-plugin-integration",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    <div className="source-control-image-container">
                      <img
                        src={teamcity}
                        alt="TeamCity"
                        className="img-fluid"
                        style={{ width: 62 }}
                      />
                    </div>
                    <h5 className="mb-2 pt-3">TeamCity</h5>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div
                    className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card"
                    onClick={() => {
                      window
                        .open(
                          "https://docs.sec1.io/user-docs/integrate-with-sec1/ci-cd-integrations/azure-devops-extension",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    <div className="source-control-image-container">
                      <img
                        src={azurePipelines}
                        alt="Azure Pipelines"
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="mb-2 pt-3">Azure Pipelines</h5>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div
                    className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card"
                    onClick={() => {
                      window
                        .open(
                          "https://docs.sec1.io/user-docs/integrate-with-sec1/ci-cd-integrations/circleci-orb-integration",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    <div className="source-control-image-container">
                      <img
                        src={circleCi}
                        alt="CircleCI"
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="mb-2 pt-3">CircleCI</h5>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div
                    className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card"
                    onClick={() => {
                      window
                        .open(
                          "https://docs.sec1.io/user-docs/integrate-with-sec1/ci-cd-integrations/gitlab-cicd-integration",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    <div className="source-control-image-container">
                      <img
                        src={gitlab}
                        alt="GitLab CI/CD"
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="mb-2 pt-3">GitLab CI/CD</h5>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div
                    className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card"
                    onClick={() => {
                      window
                        .open(
                          "https://docs.sec1.io/user-docs/integrate-with-sec1/ci-cd-integrations/github-actions-integration",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    <div className="source-control-image-container">
                      <img
                        src={githubActions}
                        alt="GitHub Actions"
                        className="img-fluid"
                        style={{ width: 55 }}
                      />
                    </div>
                    <h5 className="mb-2 pt-3">GitHub Actions</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"mb-4"}>
          <div className="">
            <div className="card-body">
              <h3
                className="title pb-2 mb-3 text-white fw-normal"
                style={{ borderBottom: "1px solid #2b313e" }}
              >
                Container Registry
              </h3>
              <div className="row">{generateContainerCardList(cardsData)}</div>
            </div>
          </div>
        </div>

        <div className={"mb-4"}>
          <div className="">
            <div className="card-body">
              <h3
                className="title pb-2 mb-3 text-white fw-normal"
                style={{ borderBottom: "1px solid #2b313e" }}
              >
                Sec1 API Token
              </h3>
              <div className="col-sm-8">
                <SettingsPage />
              </div>
            </div>
          </div>
        </div>
      </section>
      {showLoader && <Loader />}
    </section>
  );
}
