import ContainerRegistrySettings from "./containerRegistry";

const DockerRegistrySettings = () => (
  <ContainerRegistrySettings
    registryName="Docker Container Registry"
    targetSystem="dockerhub"
    fields={[
      { name: "url", label: "Registry url", type: "text" },
      { name: "userId", label: "User ID", type: "text" },
      { name: "token", label: "Token", type: "password" },
    ]}
    registryTokenLink="https://docs.docker.com/security/for-developers/access-tokens/"
  />
);

const AWSRegistrySettings = () => (
  <ContainerRegistrySettings
    registryName="AWS"
    targetSystem="awsecr"
    fields={[
      { name: "url", label: "Registry url", type: "text" },
      { name: "aws_access_key_id", label: "Access Key", type: "text" },
      { name: "aws_secret_access_key", label: "Secret Key", type: "password" },
    ]}
    registryTokenLink="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_access-keys.html"
    patHelpTitle='Creating an Access Key and Secret'
    patHelpText='To create an access key and secret, follow the below link:'
    patHelpBtnText='Creating an Access Key and Secret'
  />
);

const JFrogRegistrySettings = () => (
  <ContainerRegistrySettings
    registryName="JFrog Registry"
    targetSystem="jfrog"
    fields={[
      { name: "url", label: "Registry url", type: "text" },
      { name: "userId", label: "User ID", type: "text" },
      { name: "token", label: "Token", type: "password" },
    ]}
    registryTokenLink="https://jfrog.com/help/r/how-to-generate-an-access-token-video/artifactory-creating-access-tokens-in-artifactory"
  />
);

const AzureRegistrySettings = () => (
  <ContainerRegistrySettings
    registryName="Azure Registry"
    targetSystem="azurecr"
    fields={[
      { name: "url", label: "Registry url", type: "text" },
      { name: "userId", label: "Service principal ID", type: "text" },
      { name: "token", label: "Service principal password", type: "password" },
    ]}
    registryTokenLink="https://learn.microsoft.com/en-us/azure/container-registry/container-registry-auth-service-principal"
    patHelpTitle='Creating a Service Principal'
    patHelpText='To create a service principal, follow the below link:'
    patHelpBtnText='Create a Service Principal'
  />
);

const IBMRegistrySettings = () => (
  <ContainerRegistrySettings
    registryName="IBM Registry"
    targetSystem="ibmcr"
    fields={[
      { name: "url", label: "Registry url", type: "text" },
      { name: "accountId", label: "Account Id", type: "text" },
      { name: "token", label: "Token", type: "password" },
    ]}
    registryTokenLink="https://cloud.ibm.com/docs/Registry?topic=Registry-registry_access"
  />
);

const GoogleCloudRegistrySettings = () => (
  <ContainerRegistrySettings
    registryName="Google Cloud Registry"
    targetSystem="gcr"
    fields={[
      { name: "url", label: "Registry url", type: "text" },
      { name: "token", label: "Token", type: "file" }]}
    registryTokenLink="https://cloud.google.com/artifact-registry/docs/docker/authentication#token"
  />
);

const OracleRegistrySettings = () => (
  <ContainerRegistrySettings
    registryName="Oracle Container Registry"
    targetSystem="ocir"
    fields={[
      { name: "url", label: "Registry url", type: "text" },
      { name: "userId", label: "User ID", type: "text" },
      { name: "token", label: "Token", type: "password" }]}
    registryTokenLink="https://docs.oracle.com/en-us/iaas/Content/Registry/Tasks/registrygettingauthtoken.htm"
  />
);

export {
  GoogleCloudRegistrySettings,
  DockerRegistrySettings,
  AzureRegistrySettings,
  JFrogRegistrySettings,
  AWSRegistrySettings,
  IBMRegistrySettings,
  OracleRegistrySettings
};
