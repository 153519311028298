import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';

const sastReducer = createSlice({
    name: 'sastReducer',
    initialState: {
        iScanModalState: false,
        sastScanTriggered: false,
        sastDashTableData: [],
        sastTriggeredScans: []
    },
    reducers: {
        setIScanModalState: (state, action) => {
            state.iScanModalState = action.payload;
        },
        setSastScanTriggeredState: (state, action) => {
            state.sastScanTriggered = action.payload;
        },
        setSastDashboardTableData: (state, action) => {
            state.sastDashTableData = action.payload;
        },
        updateSastTriggeredScans: (state, action) => {
            state.sastTriggeredScans = action.payload;
        },
        addUpdateSastDashboardTableData: (state, action) => {

            // This function is added only to populate data in the table when a new scan is initiated and nothing else.

            let clonedSastTableData = _.cloneDeep(state.sastDashTableData);

            const presentList = _.filter(action.payload, (item) => {
                return _.some(clonedSastTableData, { assetUrl: item.location });
            });

            const nonPresentList = _.filter(action.payload, (item) => {
                return !_.some(clonedSastTableData, { assetUrl: item.location });
            });

            // Incoming record URL
            // {
            //     "critical": 1,
            //     "high": 0,
            //     "medium": 1,
            //     "low": 8,
            //     "scanStatus": "COMPLETED",
            //     "reportId": "65910f15-76c8-428b-9ff1-4212b671b30d",
            //     "scanDateTime": "2024-07-05T12:52:21.272Z",
            //     "assetUrl": "https://github.com/ScaleSec/vulnado",
            //     "total": 10,
            //     "assetType": "github",
            //     "source": ""
            // }

            if (!_.isEmpty(presentList)) {
                // These are the present records that need to be modified and add a loader to it so that we can show loading state.
                clonedSastTableData = clonedSastTableData.map((record) => {
                    const presentRecord = _.find(presentList, { location: record.assetUrl });
                    if (presentRecord) {
                        // Modify the record as needed, for example, adding a loading state
                        return {
                            ...record,
                            scanStatus: "INITIATED",
                            reportId: presentRecord.uuid
                        };
                    }
                    return record;
                });
            }
            let newRecordsData = [];
            if (!_.isEmpty(nonPresentList)) {
                // These are the records which are newly added into the system show it on top of all the records.
                newRecordsData = nonPresentList.map((record) => {
                    return {
                        critical: 0,
                        high: 0,
                        medium: 0,
                        low: 0,
                        scanStatus: "INITIATED",
                        scanDateTime: "-",
                        assetUrl: record.location,
                        total: 0,
                        assetType: record.assetType,
                        source: "",
                        reportId: record.uuid
                    };
                });
            }

            // Filter records with status 'INITIATED' or 'SCANNING'
            const initiatedOrScanning = _.filter(clonedSastTableData, record => record.scanStatus.toLowerCase() === 'initiated' || record.scanStatus.toLowerCase() === 'scanning');

            // Filter records with other statuses
            const otherStatuses = _.filter(clonedSastTableData, record => record.scanStatus.toLowerCase() !== 'initiated' && record.scanStatus.toLowerCase() !== 'scanning');

            // Concatenate the filtered arrays to place 'INITIATED' or 'SCANNING' records first
            const sortedRecords = [...initiatedOrScanning, ...otherStatuses];
            const dashboardRecords = [...newRecordsData, ...sortedRecords];

            state.sastDashTableData = dashboardRecords;

            // const sastScans = sortedRecords.map((el)=>{() el.reportId});
            const sastScans = _.chain(dashboardRecords)
                .filter(record => record.scanStatus.toLowerCase() === 'scanning' || record.scanStatus.toLowerCase() === 'initiated')
                .map('reportId')
                .value();
            // console.log("Sast Scans", sastScans);

            state.sastTriggeredScans = sastScans;


            // dispatch



            // console.log("I am the sast dashboard table data", JSON.stringify(state.sastDashTableData));
        }
    }
})

export const { setIScanModalState, setSastScanTriggeredState, setSastDashboardTableData, addUpdateSastDashboardTableData, updateSastTriggeredScans } = sastReducer.actions;

export default sastReducer.reducer;