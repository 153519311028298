import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button } from 'antd';
import * as Yup from 'yup';
import _ from 'lodash';
import { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CiLink } from "react-icons/ci";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";

import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { handleDastFormSubmit } from '../../shared/helper/apiHelper';
import { Divider } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Scheduler from './scheduler';
import { HeadersOptionsHelper } from '../../shared/sharedComponents/dastSharedComponents/headersOptionsHelper';

dayjs.extend(customParseFormat);

export default function EditConfigPage({ fromModal, reportData, handleEditConfigClose }) {
    const formikRef = useRef(null);

    const [showClientSecret, setShowClientSecret] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [headers, setHeaders] = useState([{ name: '', value: '' }]);
    const [schedulerList, setSchedulerList] = useState([]);


    useEffect(() => {
        if (reportData.schedulers && reportData.schedulers.length > 0) {
            const convertedSchedulers = reportData.schedulers.map(scheduler => {
                // Create time string in hh:mm A format
                const timeString = `${scheduler.hour}:${scheduler.minute.toString().padStart(2, '0')} ${scheduler.period}`;
                return {
                    id: Date.now() + Math.random(),
                    time: dayjs(timeString, 'h:mm A'),
                    timezone: scheduler.timezone
                };
            });
            setSchedulerList(convertedSchedulers);
        }
    }, [reportData.schedulers]);

    useEffect(() => {
        if (reportData.headers && !_.isEmpty(reportData.headers)) {
            let headersJson = [];
            _.map(reportData.headers, (val, key) => {
                headersJson.push({
                    name: key,
                    value: val
                });
            });
            setHeaders(headersJson);
        }
    }, [reportData.headers])



    const handleFormSubmit = (values, setSubmitting) => {

        setSubmitting(true);
        values.scanType = 'api';
        values.configId = reportData.configId;
        if (!_.isEmpty(headers)) {
            values.headers = headers;
        }

        let response = handleDastFormSubmit(values, 'config', schedulerList);
        if (response) {
            if (fromModal) {
                handleEditConfigClose(false);
                alert('Settings saved successfully');
            }
            else {
                window.location.reload();
            }
        }
        else {
            // setSubmitting(false);
            // show the error here.
        }
    }

    const validationSchema = Yup.object().shape({
        // Global fields - always validated
        url: Yup.string()
            .required('API URL is required')
            .url('Please enter a valid URL')
            .matches(/^https?:\/\//, 'Please enter a valid HTTP or HTTPS URL'),

        // Authentication type is optional
        scanAuthType: Yup.string()
            .oneOf(['NO-AUTH', 'OAUTH2', 'HEADERS', 'APIKEY'], 'Invalid authentication type')
            .nullable(),

        // Headers fields - only validated when scanAuthType is 'HEADERS'
        fileId: Yup.string().when('scanAuthType', {
            is: 'HEADERS',
            then: () => Yup.string()
                .required('File ID is required'),
            otherwise: () => Yup.string().nullable(),
        }),

        fileName: Yup.string().when('scanAuthType', {
            is: 'HEADERS',
            then: () => Yup.string()
                .required('File Name is required'),
            otherwise: () => Yup.string().nullable(),
        }),

        // OAuth2 fields - only validated when scanAuthType is 'OAUTH2'
        authUrl: Yup.string().when('scanAuthType', {
            is: 'OAUTH2',
            then: () => Yup.string()
                .required('Auth URL is required')
                .url('Please enter a valid Auth URL'),
            otherwise: () => Yup.string().nullable(),
        }),

        realmName: Yup.string().when('scanAuthType', {
            is: 'OAUTH2',
            then: () => Yup.string()
                .required('Realm name is required'),
            otherwise: () => Yup.string().nullable(),
        }),

        clientId: Yup.string().when('scanAuthType', {
            is: 'OAUTH2',
            then: () => Yup.string()
                .required('Client ID is required'),
            otherwise: () => Yup.string().nullable(),
        }),

        clientSecret: Yup.string().when('scanAuthType', {
            is: 'OAUTH2',
            then: () => Yup.string()
                .required('Client Secret is required'),
            otherwise: () => Yup.string().nullable(),
        }),

        userId: Yup.string().when('scanAuthType', {
            is: 'OAUTH2',
            then: () => Yup.string()
                .required('User ID is required'),
            otherwise: () => Yup.string().nullable(),
        }),

        password: Yup.string().when('scanAuthType', {
            is: 'OAUTH2',
            then: () => Yup.string()
                .required('Password is required'),
            otherwise: () => Yup.string().nullable(),
        }),

        // API Key field - only validated when scanAuthType is 'APIKEY'
        apiKey: Yup.string().when('scanAuthType', {
            is: 'APIKEY',
            then: () => Yup.string()
                .required('API Key is required'),
            otherwise: () => Yup.string().nullable(),
        }),

        // Optional global fields
        triggerNow: Yup.boolean().nullable()
    });
    validationSchema.strict().noUnknown();

    return (
        <><h5 className='mb-3'>API Scan</h5>
            <section className="card custom-card">
                <div className="card-body">

                    <div>
                        <Formik
                            initialValues={{
                                url: reportData.url,
                                authUrl: (!_.isEmpty(reportData) && !_.isEmpty(reportData.oauth2)) ? reportData.oauth2.authUrl : '',
                                realmName: (!_.isEmpty(reportData) && !_.isEmpty(reportData.oauth2)) ? reportData.oauth2.realmName : '',
                                clientId: (!_.isEmpty(reportData) && !_.isEmpty(reportData.oauth2)) ? reportData.oauth2.clientId : '',
                                clientSecret: (!_.isEmpty(reportData) && !_.isEmpty(reportData.oauth2)) ? reportData.oauth2.clientSecret : '',
                                scanAuthType: reportData.authType,
                                userId: (!_.isEmpty(reportData) && !_.isEmpty(reportData.oauth2)) ? reportData.oauth2.userId : '',
                                password: (!_.isEmpty(reportData) && !_.isEmpty(reportData.oauth2)) ? reportData.oauth2.password : '',
                                schemaFile: null,
                                triggerNow: false,
                                apiKey: reportData.apiKey,
                                fileId: reportData.fileId,
                                fileName: !_.isEmpty(reportData.fileName) ? reportData.fileName : ''
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                handleFormSubmit(values, setSubmitting);
                            }}
                            innerRef={formikRef}
                        >
                            {
                                ({ isSubmitting, setFieldValue, setTouched, values }) => {

                                    return (
                                        <Form>
                                            <div className="mb-3">
                                                <label htmlFor='url' className="form-label">API URL</label>
                                                <div className="input-group me-3">
                                                    <span className="input-group-text"><CiLink /></span>
                                                    <Field
                                                        id="url"
                                                        name="url"
                                                        type="text"
                                                        className="form-control"
                                                        aria-describedby="Website URL"
                                                        placeholder={`Enter API Server URL to trigger API scan for e.g. https://api.example.com`}
                                                        onPaste={(e) => {
                                                            e.preventDefault();
                                                            const pastedText = e.clipboardData.getData('text');
                                                            let inputValue = pastedText.trim();
                                                            if (inputValue && !inputValue.startsWith('http://') && !inputValue.startsWith('https://')) {
                                                                inputValue = `https://${inputValue}`;
                                                            }
                                                            setFieldValue('url', inputValue);
                                                        }}
                                                    />
                                                </div>
                                                <ErrorMessage name="url">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor='schemaFile' className="form-label">Upload API Schema:</label>
                                                <div>
                                                    <input
                                                        id="schemaFile"
                                                        name="schemaFile"
                                                        type="file"
                                                        accept=".json"
                                                        className="form-control"
                                                        onChange={(event) => {
                                                            setFieldValue("schemaFile", event.currentTarget.files[0]);
                                                        }}
                                                        style={{ padding: '6px 12px' }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="mb-4">
                                                <div className="mb-3">
                                                    <label htmlFor='scanAuthType' className="form-label">Authentication Type</label>
                                                    <div className="input-group mb-3">
                                                        <Field
                                                            id="scanAuthType"
                                                            as="select"
                                                            name="scanAuthType"
                                                            className="form-select"
                                                        >
                                                            <option value="NO-AUTH">Select Authentication Type</option>
                                                            <option value="OAUTH2">OAuth2</option>
                                                            <option value="HEADERS">Headers</option>
                                                            <option value="APIKEY">API Key</option>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="scanAuthType">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                </div>

                                                {values.scanAuthType === 'OAUTH2' && (
                                                    <>
                                                        <div className="mb-3">
                                                            <label htmlFor='authUrl' className="form-label">Auth URL</label>
                                                            <Field
                                                                id="authUrl"
                                                                name="authUrl"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Auth URL"
                                                            />
                                                            <ErrorMessage name="authUrl">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>

                                                        <div className="mb-3">
                                                            <label htmlFor='realmName' className="form-label">Realm Name</label>
                                                            <Field
                                                                id="realmName"
                                                                name="realmName"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Realm Name"
                                                            />
                                                            <ErrorMessage name="realmName">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>

                                                        <div className="mb-3">
                                                            <label htmlFor='clientId' className="form-label">Client ID</label>
                                                            <Field
                                                                id="clientId"
                                                                name="clientId"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Client ID"
                                                            />
                                                            <ErrorMessage name="clientId">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>

                                                        <div className="mb-3">
                                                            <label htmlFor='clientSecret' className="form-label">Client Secret</label>
                                                            <div className="input-group me-3">
                                                                <Field
                                                                    id="clientSecret"
                                                                    name="clientSecret"
                                                                    type={showClientSecret ? 'text' : 'password'}
                                                                    className="form-control"
                                                                    placeholder="Enter Client Secret"
                                                                />
                                                                <span className="input-group-text" onClick={(e) => setShowClientSecret(!showClientSecret)}>
                                                                    {showClientSecret ? <FaRegEye /> : <FaRegEyeSlash />}
                                                                </span>
                                                            </div>
                                                            <ErrorMessage name="clientSecret">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>


                                                        <div className="mb-3">
                                                            <label htmlFor='userId' className="form-label">User Id</label>
                                                            <Field
                                                                id="userId"
                                                                name="userId"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter User Id"
                                                            />
                                                            <ErrorMessage name="userId">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>

                                                        <div className="mb-3">
                                                            <label htmlFor='password' className="form-label">Password</label>
                                                            <div className="input-group me-3">
                                                                <Field
                                                                    id="password"
                                                                    name="password"
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    className="form-control"
                                                                    placeholder="Enter Password"
                                                                />
                                                                <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                                                                    {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                                                                </span>
                                                            </div>
                                                            <ErrorMessage name="password">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>
                                                    </>
                                                )}
                                                {values.scanAuthType === 'HEADERS' && (
                                                    <>
                                                        <div className="mb-3">
                                                            <label htmlFor='fileId' className="form-label">File Id</label>
                                                            <Field
                                                                id="fileId"
                                                                name="fileId"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter File Id"
                                                            />
                                                            <ErrorMessage name="fileId">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>

                                                        <div className="mb-3">
                                                            <label htmlFor='fileName' className="form-label">File Name</label>
                                                            <Field
                                                                id="fileName"
                                                                name="fileName"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter File Name"
                                                            />
                                                            <ErrorMessage name="fileName">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                        </div>

                                                        <HeadersOptionsHelper
                                                            headers={headers}
                                                            setHeaders={(newHeaders) => {
                                                                setHeaders(newHeaders);
                                                                // setFieldValue('headers', newHeaders);
                                                            }}
                                                            values={values}
                                                        />
                                                        <ErrorMessage name="headers">
                                                            {msg => (
                                                                <div className="form-text text-danger mt-1">
                                                                    <small>
                                                                        <FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />
                                                                        {msg}
                                                                    </small>
                                                                </div>
                                                            )}
                                                        </ErrorMessage>

                                                    </>
                                                )}
                                                {values.scanAuthType === 'APIKEY' && (
                                                    <div className="mb-3">
                                                        <label htmlFor='apiKey' className="form-label">API Key</label>
                                                        <Field
                                                            id="apiKey"
                                                            name="apiKey"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter API Key"
                                                        />
                                                        <ErrorMessage name="apiKey">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                    </div>
                                                )}
                                            </div>
                                            <Divider />
                                            <Scheduler schedulerList={schedulerList} setSchedulerList={setSchedulerList} />
                                            <div>
                                                <Button htmlType="submit" type="primary" size={'large'}
                                                    loading={isSubmitting}
                                                    disabled={isSubmitting}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </Form>
                                    )
                                }}
                        </Formik>
                    </div>
                </div>
            </section>
        </>
    );
}