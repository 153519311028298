import React, { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, notification, Skeleton, Alert, Checkbox, Select } from "antd";
import axios from "axios";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Switch } from "antd";
import { useHistory } from "react-router-dom";
import { setLoginModalViewStatus } from "../../shared/redux/reducers/authReducer";
import { setAllUserRepoList } from "../../shared/redux/reducers/scannerReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faSliders } from "@fortawesome/free-solid-svg-icons";
import { GlobalConst } from "../../shared/appConfig/globalConst";

export default function UserPrefrences() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [api, contextHolder] = notification.useNotification();
  const reduxState = useSelector((state) => state);
  const authState = useSelector((state) => state.authReducer);


  const [loadingPrefrences, setLoadingPrefrences] = useState(false);

  const [branchCheckedState, setBranchCheckedState] = useState(false);
  const [masterCheckedState, setMasterCheckedState] = useState(false);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [branchList, setBranchList] = useState([{ value: '', label: 'Select Branch' }]);
  const [selectedBranch, setSelectedBranch] = useState('');

  const [selectedPrefrenceMap, setSelectedPrefrenceMap] = useState(null);
  const [savingSettings, setSavingSettings] = useState(false);
  const [settingItems, setSettingItems] = useState([{ label: <span>Global Settings</span>, key: "global-settings", value: "default", owner: "default" }]);
  const [userPrefrences, setUserPrefrences] = useState(null);
  const [errorFetchingPrefrences, setErrorFetchingPrefrences] = useState(false);
  const [prFolderName, setPrFolderName] = useState('');
  const [selectedSetting, setSelectedSetting] = useState({
    label: <span>Global Settings</span>,
    key: "global-settings",
    value: "default",
    owner: "default"
  });
  const [scanSettings, setScanSettings] = useState({
    includeDevDependencies: false,
    scanOnlyDirectDependencies: false,
    triggerScanCommit: false,
    useLockFiles: false,
    enableVulTracing: false
  });
  const [notificationSettings, setNotificationSettings] = useState({
    enableWeeklyNotification: false,
    subscribeToProductUpdate: false,
    enableAIRemediation: false
  });

  useEffect(() => {
    async function isSessionActive() {
      let ls = await localStorage.getItem("__t");
      if (_.isEmpty(ls)) {
        history.push("/");
        // dispatch(setLoginModalViewStatus(true));
        window.location.href = GlobalConst.BASE_URL + '/login';
      }
    }
    isSessionActive();
  }, []);

  useEffect(() => {
    // console.log("Redux State", reduxState)
  }, [reduxState]);

  useEffect(() => {
    // When the user reaches here for the first time load the result and then start the scanning of the loaded results.

    if (
      !_.isEmpty(reduxState.authReducer.token) &&
      _.isEmpty(reduxState.scannerReducer.allUserRepoList)
    ) {
      // console.log("inside the if function");
      // if (reduxState.authReducer.userDetails.isFirstTimeLogin) {
      getRepoList(); // journey when user is logged in for the first time in the syatem.
      // }
      // else {
      // getDashboardData(true); // journey when the user is logged in for the next subsequent times.
      // }
    } else {
      // console.log("IO am here", reduxState.scannerReducer.allUserRepoList);
      if (!_.isEmpty(reduxState.scannerReducer.allUserRepoList)) {
        // console.log("inside the populate if here")
        populateSettingsItems(reduxState.scannerReducer.allUserRepoList);
      }
      // Show the login popup here.
      // console.log("triggered else::");
      // dispatch(setLoginModalViewStatus(true));
    }
  }, [reduxState.authReducer.token]);

  useEffect(() => {
    // console.log("Auth State:", authState);
    let userId =
      !_.isEmpty(authState) &&
        !_.isEmpty(authState.userDetails) &&
        !_.isEmpty(authState.userDetails.email)
        ? authState.userDetails.email
        : "";

    if (!_.isEmpty(userId)) {
      getPrefrences(userId);
    }
  }, [authState]);

  const getPrefrences = (userId, appName, reportId, severity) => {
    const postObj = {
      appName: appName,
      userId: userId,
      reportId: reportId,
      severity: severity,
    };
    setLoadingPrefrences(true);
    axios
      .post(
        `${GlobalConst.API_URL}/auth/foss/user/get-preference`,
        postObj
      )
      .then((op) => {
        // console.log("Output: ", op);
        setLoadingPrefrences(false);
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          setUserPrefrences(op.data);
          // setErrorFetchingPrefrences(true);
        }
      })
      .catch((e) => {
        console.log("Exception: ", e);
        setLoadingPrefrences(false);
        setErrorFetchingPrefrences(true);
      });
  };

  useEffect(() => {
    // console.log("User Pref", userPrefrences);
    // console.log("selected Setting", selectedSetting);
    if (
      !_.isEmpty(userPrefrences) 
      // && !_.isEmpty(userPrefrences.userPreferenceMap) &&
      // !_.isEmpty(selectedSetting)
    ) {
      updateInputValues(userPrefrences)
      // console.log("first if")
      // console.log("Selected Setting", selectedSetting);
      // console.log("User Prefrences", userPrefrences);
      // if (_.has(userPrefrences.userPreferenceMap, selectedSetting.value)) {
      //   // console.log("second if");
      //   // Key exists, retrieve the object
      //   let value = _.get(
      //     userPrefrences.userPreferenceMap,
      //     selectedSetting.value
      //   );
      //   // console.log("Value", selectedSetting);
      //   // now set all the values of the settings
      //   // console.log(`Key exists. Value: `, value);
      //   if (!_.isEmpty(value)) {
      //     updateInputValues(value);
      //   }
      // } else {
      //   // console.log(
      //   //   "Key does not exist. so loading the default settings",
      //   //   userPrefrences.userPreferenceMap.default
      //   // );
      //   updateInputValues(userPrefrences.userPreferenceMap.global);
      //   // load the default setting here
      // }
    }
  }, [userPrefrences]);


  const getBranches = (repoName, owner) => {
    setLoadingBranches(true);
    setSelectedBranch('');
    setBranchList([{ value: '', label: 'Select Branch' }]);
    const reqBody = {
      "urlType": reduxState.authReducer.loggedInFrom,
      "repoName": repoName,
      "owner": owner
    }
    axios
      .post(`${GlobalConst.API_URL}/auth/foss/get-branch-list`, reqBody)
      .then(op => {
        setLoadingBranches(false);
        if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.data)) {
          let data = [{ value: '', label: 'Select Branch' }];
          let opData = _.remove(op.data.data, obj => !['main', 'master'].includes(obj.name.toLowerCase()));
          _.forEach(opData, (elem, key) => {
            data.push(
              {
                value: elem.name,
                label: elem.name,
              }
            )
          })
          setBranchList(data);
        }
        else {
          // show user the error message that there is no repo list.
          setBranchList([]);
        }
      })
      .catch(e => {
        console.log("Exception: ", e);
        setBranchList([]);
        setLoadingBranches(false);
      });
  }

  const updateInputValues = (value) => {
    // console.log("value", value, selectedSetting);
    setSelectedPrefrenceMap(value);


    // console.log("Updated input values::", value);
    let [owner, reponame] = selectedSetting.value.split('/');
    // if (selectedSetting.value != 'default') {
    //   getBranches(reponame, selectedSetting.owner);
    // }

    let isChecked = false;
    if (value["main.scan.frequency"] === "daily" || value["master.scan.frequency"] === "daily") {
      isChecked = true;
    }
    setMasterCheckedState(isChecked);

    setPrFolderName(value['pull.request.target.folder.name']);


    //     // / Function to check if the key contains ".scan.frequency"
    //     const containsHelloWorld = key => key.includes('.scan.frequency');

    //     // Use _.pickBy to filter keys based on the condition
    //     const result = _.pickBy(value, (val, key) => containsHelloWorld(key));

    //     console.log(result);


    // // Function to check if the key contains 'abc' or 'pqr'
    // const containsAbcOrPqr = key => key.includes('main.scan.frequency') || key.includes('master.scan.frequency');

    // // Use _.pickBy with _.negate to filter out keys based on the condition
    // const result2 = _.pickBy(result, _.negate((value, key) => containsAbcOrPqr(key)));

    // console.log("result2", result2);





    // const value = userPrefrences.userPreferenceMap.default;
    setScanSettings({
      includeDevDependencies: value["include.dev.dependencies"] === "true",
      scanOnlyDirectDependencies: value["scan.only.direct.dependencies"] === "true",
      triggerScanCommit: value["trigger.scan.on.commit"] === "true",
      useLockFiles: value["use.lock.files"] === "true",
      enableVulTracing: value["run.traceability"] === "true",
    });
    setNotificationSettings({
      enableWeeklyNotification: value["enable.weekly.notifications"] === "true",
      subscribeToProductUpdate: value["subscribe.to.product.update"] === "true",
      enableAIRemediation: value["enable.ai.remediation"] === "true",
    });
  };

  const openErrorNotification = () => {
    api.error({
      message: "Error",
      description:
        "An error occurred. Please check your request and try again. If the issue persists, contact support.",
      // className: "custom-class",
      // style: {
      //   width: 600,
      // },
    });
  };

  const openSuccessNotification = () => {
    api.success({
      message: "Success",
      description: "Settings saved successfully.",
      // className: "custom-class",
      // style: {
      //   width: 600,
      // },
    });
  };

  const getRepoList = () => {
    // user will reach here when is logs in for the first time this below api will get all the repo list of the user.
    const requestBody = { urlType: "github" };
    axios
      .post(`${GlobalConst.API_URL}/auth/foss/get-repo-list`, requestBody)
      .then((op) => {
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          // once you get all the repo result from the backend start processing the data.
          dispatch(setAllUserRepoList(op.data.data)); // Store the data in the reduxStore for future use.
          populateSettingsItems(op.data.data);
          // localStorage.setItem("fl", 'false');
        }
      })
      .catch((e) => {
        console.log("Exception: ", e);
      });
  };

  const populateSettingsItems = (dataItems) => {
    // console.log("Data Items", dataItems);

    let items = [
      {
        label: <span>Global Settings</span>,
        key: "global-settings",
        value: "default",
      },
    ];
    if (!_.isEmpty(dataItems)) {
      dataItems.forEach((element) => {
        items.push({
          label: (
            <span>
              {element.owner}/{element.name}
            </span>
          ),
          key: `${element.owner}-${element.name}`,
          value: `${element.owner}/${element.name}`,
          owner: `${element.owner}`
        });
      });
    }
    setSettingItems(items);
  };

  const handleTriggerMenuItemClick = ({ key }) => {
    const selectedMenu = _.find(settingItems, { key: key });
    setSelectedSetting(selectedMenu);
    getPrefrences(authState.userDetails.email, selectedMenu.value);
  };

  const handleScanSettingOptionChange = (checked, key) => {
    // console.log("setting change", checked, key);
    setScanSettings({
      ...scanSettings,
      [key]: checked,
    });
  };

  const handleNotificationSettingOptionChange = (checked, key) => {
    // console.log("setting change", checked, key);
    setNotificationSettings({
      ...notificationSettings,
      [key]: checked,
    });
  };

  const handleMasterCheckBoxChange = (e) => {
    // console.log("Checked", e.target.checked);
    setMasterCheckedState(e.target.checked);
  }

  const handleBranchCheckedStateChange = (e) => {
    setBranchCheckedState(e.target.checked);
  }

  const handleUpdateSettingClick = () => {
    // console.log("I am clicked");

    // setSelectedPrefrenceMap(value);
    // // const value = userPrefrences.userPreferenceMap.default;
    // setScanSettings({
    //   includeDevDependencies: value["include.dev.dependencies"] === "true",
    //   scanOnlyDirectDependencies:
    //     value["scan.only.direct.dependencies"] === "true",
    //   triggerScanCommit: value["trigger.scan.on.commit"] === "true",
    //   useLockFiles: value["use.lock.files"] === "true",
    // });
    // setNotificationSettings({
    //   enableWeeklyNotification: value["enable.weekly.notifications"] === "true",
    //   subscribeToProductUpdate: value["subscribe.to.product.update"] === "true",
    // });


    const requestBody = {
      // userId: userPrefrences.userId,
      userPreferenceMap: {
        ...selectedPrefrenceMap,
        ["main.scan.frequency"]: masterCheckedState ? "daily" : "null",
        ["master.scan.frequency"]: masterCheckedState ? "daily" : "null",
        ["include.dev.dependencies"]:
          scanSettings.includeDevDependencies.toString(),
        ["scan.only.direct.dependencies"]:
          scanSettings.scanOnlyDirectDependencies.toString(),
        ["trigger.scan.on.commit"]: scanSettings.triggerScanCommit.toString(),
        ["use.lock.files"]: scanSettings.useLockFiles.toString(),
        ["enable.weekly.notifications"]:
          notificationSettings.enableWeeklyNotification.toString(),
        ["subscribe.to.product.update"]:
          notificationSettings.subscribeToProductUpdate.toString(),
        ['pull.request.target.folder.name']: prFolderName,
        ['enable.ai.remediation']: notificationSettings.enableAIRemediation.toString(),
        ["run.traceability"]: scanSettings.enableVulTracing.toString(),
      },
      application: 'global',
    };

    if (!_.isEmpty(selectedBranch) && branchCheckedState === true) {
      requestBody.userPreferenceMap[`${selectedBranch.label}.scan.frequency`] = "daily";
    }
      
    if(!_.isEmpty(authState) &&
        !_.isEmpty(authState.userDetails) &&
        !_.isEmpty(authState.userDetails.email))
        requestBody.userId= authState.userDetails.email

    // console.log("I am the return object::", requestBody);

    // return;
    setSavingSettings(true);
    axios
      .post(
        `${GlobalConst.API_URL}/auth/foss/user/save-preference`,
        requestBody
      )
      .then((op) => {
        // console.log("I am here ::", op);
        setSavingSettings(false);
        openSuccessNotification();
        // if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
        //   // once you get all the repo result from the backend start processing the data.
        //   dispatch(setAllUserRepoList(op.data.data)); // Store the data in the reduxStore for future use.
        //   populateSettingsItems(op.data.data);
        //   // localStorage.setItem("fl", 'false');
        // }
      })
      .catch((e) => {
        console.log("Exception: ", e);
        setSavingSettings(false);
        openErrorNotification();
      });
  };

  const getHeader = (showDropdown) => {
    return (
      <section>
        <div className="mb-20">
          <div className="d-flex align-items-center justify-content-start">
            <h2
              style={{ lineHeight: 1 }}
              className="sotcox-title mb-0 text-white me-3 d-flex align-items-center justify-content-center"
            >
              <FontAwesomeIcon icon={faSliders} className="me-2" />
              Configure
            </h2>
            {/* {showDropdown &&
              <div>
                <Dropdown
                  menu={{
                    items: settingItems,
                    onClick: handleTriggerMenuItemClick,
                  }}
                  overlayClassName={"settings-dropdown"}
                  trigger={["click"]}
                >
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    <Space>
                      {!_.isEmpty(selectedSetting) && selectedSetting.label}
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            } */}
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className="mx-3 col-sm-7">
      {getHeader(errorFetchingPrefrences ? false : true)}
      {errorFetchingPrefrences ?
        <Alert
          message="We're sorry!"
          description="There was an issue processing your request. Please try again later."
          type="error"
          className="my-4"
        />
        :
        <>
          <section>
            <div className={""}>
              <div className="blog_widget widget card custom-card">
                <h3
                  className="title pb-2 mb-4 text-white fw-normal"
                  style={{ borderBottom: "1px solid #cfdaed" }}
                >
                  Branches
                </h3>

                {
                  loadingPrefrences ?
                    <div>
                      <Skeleton active paragraph={{ rows: 2 }} />
                    </div>

                    :
                    <div>
                      <h5 className="text-white mb-3">Branch Scan Filters</h5>
                      <p>
                        Set the branches you would like to have scanned and optionally
                        flag those that should be rescanned daily; other branches will
                        be ignored.
                      </p>
                      <p>
                        This can include wildcards, such as feature/*, fix/*,
                        orrefs/pulls/*/merge and are case insensitive. Use * to scan
                        all branches.
                      </p>
                      <p>
                        We suggest scanning your default branch as well as any active
                        release branches.
                      </p>
                      <div className="row">
                        <div className="col-sm-6">
                          <table className="table table-dark table-hover table-sm custom-table modified-bootstrap-table">
                            <thead>
                              <tr>
                                <th>Branch Name</th>
                                <th>Daily Rescan</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>main, master</td>
                                <td><Checkbox checked={masterCheckedState} onChange={handleMasterCheckBoxChange}></Checkbox></td>
                              </tr>
                              {/* {selectedSetting.value != 'default' && <tr>
                                <td>
                                  <Select
                                    // defaultValue="lucy"
                                    value={selectedBranch}
                                    onChange={(val, opt) => { setSelectedBranch(opt) }}
                                    style={{
                                      width: '100%',
                                    }}
                                    loading={loadingBranches}
                                    options={branchList}
                                  />
                                </td>
                                <td><Checkbox checked={branchCheckedState} disabled={_.isEmpty(selectedBranch)} onChange={handleBranchCheckedStateChange}></Checkbox></td>
                              </tr>} */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <hr />

                      <div>
                        <h5 className="text-white mb-3">Auto Fix PR Folder</h5>
                        <p>
                          Provide folder name where you would like to raise PR (Pull Requests) for Auto Fixes.
                        </p>
                        <p>
                          This configuration will help streamline the process and ensure that all Auto Fixes are properly managed and organized.
                        </p>
                        <div className="flex-fill me-3 col-sm-6">
                          <div className="mb-3">
                            <label htmlFor="prFolderName" className="form-label mb-1 fw-semibold">PR Folder Name</label>
                            <input type="text" autoComplete="off" className="form-control" id="prFolderName" aria-describedby="PR Folder Name" placeholder="Enter PR Folder Name"
                              value={prFolderName}
                              onChange={(e) => { setPrFolderName(e.target.value) }}
                            />
                          </div>
                        </div>



                      </div>





                    </div>
                }

              </div>
            </div>
          </section>
          <section>
            <div className={""}>
              <div className="blog_widget widget card custom-card">
                <h3
                  className="title pb-2 mb-4 text-white fw-normal"
                  style={{ borderBottom: "1px solid #cfdaed" }}
                >
                  Scan Settings
                </h3>
                {
                  loadingPrefrences ?
                    <div>
                      <Skeleton active paragraph={{ rows: 2 }} />
                      <hr />
                      <Skeleton active paragraph={{ rows: 2 }} />
                      <hr />
                      <Skeleton active paragraph={{ rows: 2 }} />
                    </div>

                    :
                    <div>
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="me-4">
                          <h5 className="text-white mb-1 fw-light">
                            Include dev dependencies
                          </h5>
                          <small className="text-muted mb-0 ">
                            Enhance development with essential tools. Toggle to include
                            dev dependencies for a comprehensive coding experience.
                          </small>
                        </div>

                        <div>
                          <Switch
                            checked={scanSettings.includeDevDependencies}
                            onChange={(checked) =>
                              handleScanSettingOptionChange(
                                checked,
                                "includeDevDependencies"
                              )
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="me-4">
                          <h5 className="text-white mb-1 fw-light">
                            Scan only direct dependencies
                          </h5>
                          <small className="text-muted mb-0 ">
                            Focus your scans on direct dependencies. Enable this option
                            for targeted vulnerability assessments, streamlining your
                            security measures to the essentials.
                          </small>
                        </div>

                        <div>
                          <Switch
                            checked={scanSettings.scanOnlyDirectDependencies}
                            onChange={(checked) =>
                              handleScanSettingOptionChange(
                                checked,
                                "scanOnlyDirectDependencies"
                              )
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="me-4">
                          <h5 className="text-white mb-1 fw-light">
                            Trigger scan commit
                          </h5>
                          <small className="text-muted mb-0 ">
                            Initiate scans with each commit. Activate this option for
                            real-time security checks, ensuring your codebase stays
                            resilient with every update.
                          </small>
                        </div>

                        <div>
                          <Switch
                            checked={scanSettings.triggerScanCommit}
                            onChange={(checked) =>
                              handleScanSettingOptionChange(
                                checked,
                                "triggerScanCommit"
                              )
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="me-4">
                          <h5 className="text-white mb-1 fw-light">Use lock files</h5>
                          <small className="text-muted mb-0 ">
                            Activate this setting to fortify your website's defenses.
                            "Use Lock Files" leverages a security best practice by
                            employing lock files to scan for vulnerabilities. Lock files
                            ensure precise identification of library versions, creating
                            a secure foundation for vulnerability assessments.
                          </small>
                        </div>

                        <div>
                          <Switch
                            checked={scanSettings.useLockFiles}
                            onChange={(checked) =>
                              handleScanSettingOptionChange(checked, "useLockFiles")
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="me-4">
                          <h5 className="text-white mb-1 fw-light">Enable Vulnerability Tracing</h5>
                          <small className="text-muted mb-0 ">
                          Activate this setting to trace and report vulnerabilities only in the packages directly used in your code. This feature filters out vulnerabilities from unused or indirectly included packages, ensuring your focus remains on the security issues that matter most to your project.
                          </small>
                        </div>

                        <div>
                          <Switch
                          disabled={true}
                            checked={scanSettings.enableVulTracing}
                            onChange={(checked) =>
                              handleScanSettingOptionChange(checked, "enableVulTracing")
                            }
                          />
                        </div>
                      </div>
                    </div>
                }


              </div>
            </div>
          </section>
          {/* <section>
            <div className={""}>
              <div className="blog_widget widget card custom-card">
                <h3
                  className="title pb-2 mb-4 text-white fw-normal"
                  style={{ borderBottom: "1px solid #cfdaed" }}
                >
                  Notification Settings
                </h3>

                {
                  loadingPrefrences ?
                    <div>
                      <Skeleton active paragraph={{ rows: 2 }} />
                      <hr />
                      <Skeleton active paragraph={{ rows: 2 }} />
                    </div>

                    :
                    <div>
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="me-4">
                          <h5 className="text-white mb-1 fw-light">
                            Enable weekly notification
                          </h5>
                          <small className="text-muted mb-0">
                            Enable this feature to receive weekly notifications! Stay
                            updated on the latest news, exclusive offers, and important
                            updates directly to your device. Choose convenience and
                            never miss out on what matters most. Manage your
                            notification preferences easily. Stay connected, stay
                            informed.
                          </small>
                        </div>

                        <div>
                          <Switch
                            checked={notificationSettings.enableWeeklyNotification}
                            onChange={(checked) =>
                              handleNotificationSettingOptionChange(
                                checked,
                                "enableWeeklyNotification"
                              )
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="me-4">
                          <h5 className="text-white mb-1 fw-light">
                            Subscribe to product update
                          </h5>
                          <small className="text-muted mb-0">
                            Stay ahead in the cybersecurity realm! Subscribe to receive
                            timely updates on our product's latest features, security
                            enhancements, and industry insights. Be the first to know
                            about advancements that keep your digital defenses sharp.
                            Knowledge is power.
                          </small>
                        </div>

                        <div>
                          <Switch
                            checked={notificationSettings.subscribeToProductUpdate}
                            onChange={(checked) =>
                              handleNotificationSettingOptionChange(
                                checked,
                                "subscribeToProductUpdate"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                }

              </div>
            </div>
          </section> */}
          <section>
            <div className={""}>
              <div className="blog_widget widget card custom-card">
                <h3
                  className="title pb-2 mb-4 text-white fw-normal"
                  style={{ borderBottom: "1px solid #cfdaed" }}
                >
                  SAST Settings
                </h3>

                {
                  loadingPrefrences ?
                    <div>
                      <Skeleton active paragraph={{ rows: 2 }} />
                      <hr />
                      <Skeleton active paragraph={{ rows: 2 }} />
                    </div>

                    :
                    <div>
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="me-4">
                          <h5 className="text-white mb-1 fw-light">
                            Enable AI generated remediation
                          </h5>
                          <small className="text-muted mb-0">
                            Enable this feature to receive weekly notifications! Stay
                            updated on the latest news, exclusive offers, and important
                            updates directly to your device. Choose convenience and
                            never miss out on what matters most. Manage your
                            notification preferences easily. Stay connected, stay
                            informed.
                          </small>
                        </div>

                        <div>
                          <Switch
                            checked={notificationSettings.enableAIRemediation}
                            disabled
                            onChange={(checked) =>
                              handleNotificationSettingOptionChange(
                                checked,
                                "enableAIRemediation"
                              )
                            }
                          />
                        </div>
                      </div>
                      {/* <hr /> */}
                      {/* <div className="d-flex align-items-start justify-content-between">
                        <div className="me-4">
                          <h5 className="text-white mb-1 fw-light">
                            Subscribe to product update
                          </h5>
                          <small className="text-muted mb-0">
                            Stay ahead in the cybersecurity realm! Subscribe to receive
                            timely updates on our product's latest features, security
                            enhancements, and industry insights. Be the first to know
                            about advancements that keep your digital defenses sharp.
                            Knowledge is power.
                          </small>
                        </div>

                        <div>
                          <Switch
                            checked={notificationSettings.subscribeToProductUpdate}
                            onChange={(checked) =>
                              handleNotificationSettingOptionChange(
                                checked,
                                "subscribeToProductUpdate"
                              )
                            }
                          />
                        </div>
                      </div> */}
                    </div>
                }

              </div>
            </div>
          </section>
          <section>
            <Button
              onClick={handleUpdateSettingClick}
              type="primary"
              size="large"
              disabled={loadingPrefrences}
              loading={savingSettings}
            >
              <FontAwesomeIcon icon={faFloppyDisk} className="me-2" />
              Update Settings
            </Button>
          </section>
        </>
      }
      {contextHolder}
    </section>
  );
}
