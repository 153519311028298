import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { Tag, Tooltip } from "antd";

import Loader from "../../shared/sharedComponents/loader";
// import ReviewFixVulDataTable from "./reviewFixVulDataTable";
import {
    getStrokeColor,
    getFontColor,
    getSeverityInitial,
} from "../../shared/helper/genHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faChevronLeft,
    faCircleExclamation,
    faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { setLoginModalViewStatus } from "../../shared/redux/reducers/authReducer";
import { useDispatch } from "react-redux";
import ReviewAutofixDataTable from './reviewAutofixDataTable';
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { faGitAlt, faGithub } from "@fortawesome/free-brands-svg-icons";


import {
    faAngleRight,
    faArrowRight,
    faBoxOpen,
    faCircleQuestion,
    faFile,
    faFileCode,
    faFileZipper,
    faTimesCircle,
    faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
    faDocker,
    faGit,
    faGithubAlt,
} from "@fortawesome/free-brands-svg-icons";


import { getAssetIcon } from "../../shared/helper/getAssetImagesHelper";


export default function ApplyAutofix() {
    const { id } = useParams();
    const history = useHistory();




    const [loading, setLoading] = useState(false);
    const [scannedReport, setScannedReport] = useState(null);

    const [selectedCVE, setSelectedCVE] = useState([]);
    const [metaData, setMetaData] = useState({});

    const [isFixDone, setIsFixDone] = useState(false);


    const [viewCanFixVul, setViewCanFixVul] = useState(false);
    const [viewCantFixVul, setViewCantFixVul] = useState(false);
    const [applyingAutofix, setApplyingAutofix] = useState(false);
    const [recommendationsData, setRecommendationsData] = useState(null);

    // const [downloadingFile, setDownloadingFile] = useState(false);
    const [fileDownloaded, setFileDownloaded] = useState(false);

    const [createPullRequestResponse, setCreatePullRequestResponse] = useState({
        // "pullRequestUrl": "https://github.com/padwalkar/spring-boot-test/pull/2",
        // "message": "Pull request created successfully.",
        // "pullRequestBranch": "sec1-fix-a0d3f6b7-aa88-448c-bdbc-faf3c0cb435a"
        // "pullRequestUrl": null,
        // "message": "Failed creating pull request.",
        // "pullRequestBranch": null
    });


    useEffect(() => {
        async function isSessionActive() {
            let ls = await localStorage.getItem("__t");
            if (_.isEmpty(ls)) {
                history.push("/");
                window.location.href = GlobalConst.BASE_URL + '/login';
            }
        }
        isSessionActive();
    }, []);

    useEffect(() => {
        setLoading(true);
        if (id) {
            axios
                .get(`${GlobalConst.API_URL}/auth/foss/report/${id}`)
                .then((op) => {
                    setLoading(false);
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        setScannedReport(op.data);
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    console.log("Exception: ", e);
                });
        }
    }, [id]);


    const getScanFileLocation = (report) => {

        if (!_.isEmpty(report.scannerResponseEntity.scanFileLocation)) {
            return report.scannerResponseEntity.scanFileLocation
        }
        else {
            if (!_.isEmpty(report.scannerResponseEntity.scanResult[0].scanFileLocation)) {
                return report.scannerResponseEntity.scanResult[0].scanFileLocation;
            }
            else {
                return null;
            }
        }
    }

    useEffect(() => {
        if (!_.isEmpty(scannedReport) && !_.isEmpty(scannedReport.scannerResponseEntity)) {
            let postData = {};
            if (scannedReport.scannerResponseEntity.assetSubtype === 'archive' && scannedReport.scannerResponseEntity.assetType === 'file') {
                postData = {
                    "reportId": id,
                    "fixAll": true,
                    "fixTypes": [],
                    "cveList": [],
                    "scanFileLocation": getScanFileLocation(scannedReport),
                    "urlType": 'file',
                    "repoUrl": '',
                    "branch": ''
                };
            }
            else {
                postData = {
                    "reportId": id,
                    "fixAll": true,
                    "fixTypes": [],
                    "cveList": [],
                    "scanFileLocation": getScanFileLocation(scannedReport),
                    "urlType": scannedReport.scannerResponseEntity.assetSubtype,
                    "repoUrl": scannedReport.location,
                    "branch": (!_.isEmpty(scannedReport.scannerResponseEntity.branch)) ? scannedReport.scannerResponseEntity.branch : 'main'
                };
            }
            getRecommendations(postData);
        }
    }, [scannedReport]);

    const getRecommendations = (postData) => {
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/get-remediation`, postData)
            .then(op => {

                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setRecommendationsData(op.data);
                    generateMetaData(op.data);
                }
                else {
                    setRecommendationsData(null);
                    setMetaData({});
                }
                setLoading(false);
            })
            .catch(e => {
                console.log("Exception:", e);
                setLoading(false);
            });
    }

    const generateMetaData = (remediationData) => {


        setMetaData({
            availableCVERemediation: remediationData.oldTotalCve - remediationData.newTotalCve,
            noCVERemediation: remediationData.newTotalCve,
            totalVul: remediationData.oldTotalCve,
            criticalCount: remediationData.oldCriticalFinding - remediationData.newCriticalFinding,
            highCount: remediationData.oldHighFinding - remediationData.newHighFinding,
            mediumCount: remediationData.oldMediumFinding - remediationData.newMediumFinding,
            lowCount: remediationData.oldLowFinding - remediationData.newLowFinding
        })
    }


    const handleCancelClick = () => {
        history.push(`/dashboard-scan-details/${id}`);
    };

    const handleApplyAutofixClick = () => {
        // console.log("Handle Apply Autofix Click");
        setApplyingAutofix(true);

        if (!_.isEmpty(recommendationsData) && !_.isEmpty(recommendationsData.remediationFileName)) {

            // Code for file download.

            let postData = { "reportId": id, "scanFileLocation": recommendationsData.remediationFileName };
            setLoading(true);
            // setDownloadingFile(true);
            setFileDownloaded(false);
            axios
                .post(`${GlobalConst.API_URL}/auth/foss/download-remediation-file`, postData)
                .then((op) => {
                    setApplyingAutofix(false);
                    // setDownloadingFile(false);
                    setFileDownloaded(true);
                    setIsFixDone(true);
                    // console.log("op==>", op);
                    setLoading(false);



                    const blob = new Blob([op.data], { type: 'application/xml' });

                    // Create a link element
                    const link = document.createElement('a');
                
                    // Set the download attribute with the filename
                    link.download = recommendationsData.remediationFileName;
                
                    // Create a URL for the Blob and set it as the href attribute
                    link.href = window.URL.createObjectURL(blob);
                
                    // Append the link to the body
                    document.body.appendChild(link);
                
                    // Programmatically click the link to trigger the download
                    link.click();
                
                    // Remove the link from the document
                    document.body.removeChild(link);


                    // if (!_.isEmpty(op) && !_.isEmpty(op.data)) {

                    //     // setCreatePullRequestResponse(op.data);
                    //     // setSuggestedRemediation(op.data);
                    //     // console.log("Response data :: from the create pull request", op.data);
                    // }
                })
                .catch((e) => {
                    setLoading(false);
                    setApplyingAutofix(false);
                    // setDownloadingFile(false);
                    setFileDownloaded(false);
                    console.log("Exception: ", e);
                });


        }
        else {


            let postData = {};


            // console.log("scanned report", scannedReport);
            postData = {
                branch: (!_.isEmpty(scannedReport.scannerResponseEntity.branch)) ? scannedReport.scannerResponseEntity.branch : 'main',
                repoUrl: scannedReport.location,
                urlType: scannedReport.scannerResponseEntity.assetSubtype,
                remediationPackage: {},
            };

            if (!_.isEmpty(recommendationsData) && !_.isEmpty(recommendationsData.remediationDetails)) {
                // console.log("Selected CVE::", selectedCVE)
                const filteredArray = _.filter(
                    recommendationsData.remediationDetails,
                    (obj) => obj.upgradeToVersion != "No remediation found"
                );


                // console.log("Filtered Array:", filteredArray);

                let resultObject = {};

                //   requestObject = requestObject[getScanFileLocation(scannedReport)];

                resultObject[getScanFileLocation(scannedReport)] = recommendationsData.remediationDetails.map((el) => {
                    return ({
                        packageName: el.packageName,
                        currentVersion: el.currentVersion,
                        upgradeToVersion: el.upgradeToVersion,
                        ecosystem: el.ecosystem,
                        cveList: el.cveList,
                        parent: el.parent
                    });
                }


                );

                if (!_.isEmpty(resultObject)) {
                    postData.remediationPackage = resultObject;
                }
            }

            // console.log("Post Data:", postData);

            // return;


            setLoading(true);
            axios
                .post(`${GlobalConst.API_URL}/auth/foss/create-pull-request`, postData)
                .then((op) => {
                    setApplyingAutofix(false);
                    // console.log("op==>", op);
                    setLoading(false);
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        setIsFixDone(true);
                        setCreatePullRequestResponse(op.data);
                        // setSuggestedRemediation(op.data);
                        // console.log("Response data :: from the create pull request", op.data);
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    setApplyingAutofix(false);
                    console.log("Exception: ", e);
                });

        }

    }



    function isValidHttpUrl(string) {
        let url;
        try {
            url = new URL(string);
        } catch (e) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }



    const getReportName = (location) => {
        // console.log("Location", searchResults);
        // console.log("Location", location, isValidHttpUrl(location))
        // let name = "";
        if (isValidHttpUrl(location)) {
            var pathname = new URL(location).pathname;
            pathname = pathname.substring(1);
            if (scannedReport.scannerResponseEntity.assetSubtype === 'azure-scm') {
                location = `${location}?version=GB${scannedReport.scannerResponseEntity.branch}`;
            }

            // console.log("path name:", pathname);
            // name = pathname;
            return <Tooltip title={location}>{decodeURIComponent(pathname)}</Tooltip>;
        } else {
            // name = location;
            return <Tooltip title={location}>{location}</Tooltip>;
        }
        // return name;
    }


    // const getAssetIcon = (row) => {
    //     // console.log("Row", row.assetSubtype );

    //     // cli,gitaction,aws-ci,gcp-ci,jenkins,circleci,bamboo,azure-ci
    //     // if(){

    //     // }

    //     switch (row.assetSubtype) {
    //         case "github":
    //             return (
    //                 <FontAwesomeIcon
    //                     title={row.assetSubtype}
    //                     icon={faGithub}
    //                     className="me-1"
    //                 />
    //             );
    //         case "archive":
    //             return (
    //                 <FontAwesomeIcon
    //                     title={row.assetSubtype}
    //                     icon={faFileZipper}
    //                     className="me-1"
    //                 />

    //             );
    //         case "sbom":
    //             return (
    //                 <FontAwesomeIcon
    //                     title={row.assetSubtype}
    //                     icon={faFileCode}
    //                     className="me-1"
    //                 />
    //             );
    //         case "container":
    //             return (
    //                 <FontAwesomeIcon
    //                     title={row.assetSubtype}
    //                     icon={faDocker}
    //                     className="me-1"
    //                 />
    //             );
    //         case "docker":
    //             return (
    //                 <FontAwesomeIcon
    //                     title={row.assetSubtype}
    //                     icon={faDocker}
    //                     className="me-1"
    //                 />
    //             );
    //         case "azure-scm":
    //             return (
    //                 <img src={azureScm} title={row.assetSubtype} className="me-1" style={{ width: 13 }} />
    //             );
    //         default:
    //             return (
    //                 <Tooltip title="Unable to identify the artifact">
    //                     <span>
    //                         <FontAwesomeIcon icon={faCircleQuestion} className="me-1" />
    //                     </span>
    //                 </Tooltip>
    //             );
    //     }
    // };



    return (
        <section className="mx-3">
            {isFixDone ? (
                <>
                    <div className="p-4 ">
                        <div className="row justify-content-center">
                            <div className="col-md-6 card custom-card shadow rounded">
                                {(!_.isEmpty(createPullRequestResponse) &&
                                    createPullRequestResponse.message ===
                                    "Pull request created successfully." || fileDownloaded === true) ? (
                                    <div className="text-center p-4" role="alert">
                                        <FontAwesomeIcon
                                            icon={faCircleCheck}
                                            style={{ fontSize: 55 }}
                                            className="mb-3 text-success"
                                        />
                                        <h2 className="alert-heading">Success!</h2>
                                        <p className="mb-4">
                                            {fileDownloaded ? <>We have successfully applied autofix on the selected CVE's and the fixed file has been downloaded successfully.</> : <>We have successfully applied autofix on the selected CVE's
                                                and have created a pull request for the same.</>}

                                        </p>
                                        <div className="d-flex align-items-center justify-content-center">
                                            {fileDownloaded === false && <a
                                                href={createPullRequestResponse.pullRequestUrl}
                                                className="btn btn-primary"
                                                target="_blank"
                                            >
                                                View pull request
                                            </a>}
                                            <a
                                                href={"/sca-dashboard"}
                                                className="btn btn-outline-primary ms-3"
                                            >
                                                Goto dashboard
                                            </a>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="text-center p-4" role="alert">
                                        <FontAwesomeIcon
                                            icon={faCircleExclamation}
                                            style={{ fontSize: 55 }}
                                            className="mb-3 text-danger"
                                        />
                                        <h2 className="alert-heading">
                                            Failed while applying autofix!
                                        </h2>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="text-white mb-2 d-flex align-items-center justify-content-start">
                        <h2
                            className="mb-0 text-white d-flex align-items-center justify-content-start"
                            style={{ minWidth: 200 }}
                        >Autofix Vulnerabilities</h2>
                    </div>
                    <div>
                        {!_.isEmpty(scannedReport) &&
                            !_.isEmpty(scannedReport.scannerResponseEntity) && (
                                <div className="mb-2 align-content-end row">
                                    <div className="col-sm-8">
                                        <div className="bg-transparent mb-1">
                                            <div className="bg-transparent text-white ps-0 mb-0 p-0">
                                                <span>


                                                    {/* <div>
                                                        {!_.isEmpty(searchResults) && (
                                                            <span style={{ fontSize: 18 }} className="d-flex align-items-center justify-content-start">
                                                                <span className="text-truncate me-2"><span className="me-1">{getAssetIcon(searchResults)}</span>{(!_.isEmpty(reportDetails) && !_.isEmpty(reportDetails.location)) && getReportName(reportDetails.location)}</span>
                                                            </span>
                                                        )} 
                                                    </div> */}



                                                    <a
                                                        href={scannedReport.scannerResponseEntity.assetUrl}
                                                        target="_blank"
                                                        className="me-2"
                                                    >
                                                        {/* {scannedReport.scannerResponseEntity.appName} */}
                                                        <span className="text-truncate"><span>{getAssetIcon(scannedReport.scannerResponseEntity.assetSubtype)}</span>{(!_.isEmpty(scannedReport) && !_.isEmpty(scannedReport.location)) && getReportName(scannedReport.location)}</span>
                                                    </a>
                                                    {(!_.isEmpty(scannedReport.scannerResponseEntity) && !_.isEmpty(scannedReport.scannerResponseEntity.branch)) && <small className="me-3">&lt;{scannedReport.scannerResponseEntity.branch}&gt;</small>}
                                                    <small className="text-secondary">
                                                        <i>
                                                            (Last Scan: {moment.utc(scannedReport.scannerResponseEntity.scanDate).local().fromNow()})
                                                        </i>
                                                    </small>
                                                </span>
                                            </div>
                                        </div>
                                        {(!_.isEmpty(metaData) && !_.isEmpty(recommendationsData) && !_.isEmpty(recommendationsData.remediationDetails)) && (
                                            <>
                                                <div className="row mb-2">
                                                    <div className="d-flex align-items-center justify-content-start flex-row col-sm-9">
                                                        <div
                                                            className={`p-2 px-3 text-white btn btn-success position-relative text-center `}
                                                            // onClick={() => {
                                                            //     setViewCanFixVul(!viewCanFixVul ? true : false);
                                                            // }}
                                                            style={{
                                                                borderRadius: "15px 0 0 15px",
                                                                width: `50%`,
                                                                cursor: "default",
                                                            }}
                                                        >
                                                            <strong style={{ fontSize: 18 }}>
                                                                {metaData.availableCVERemediation}
                                                            </strong>{" "}Vulnerabilities
                                                            <br />
                                                            can be autofixed
                                                            {viewCanFixVul && (
                                                                <FontAwesomeIcon
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: 5,
                                                                        right: 5,
                                                                    }}
                                                                    icon={faCircleCheck}
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`p-2 px-3 text-white btn btn-danger position-relative text-center `}
                                                            // onClick={() => {
                                                            //     setViewCantFixVul(
                                                            //         !viewCantFixVul ? true : false
                                                            //     );
                                                            // }}
                                                            style={{
                                                                borderRadius: "0 15px 15px 0",
                                                                cursor: "default",
                                                                borderLeft: "1px solid #212121",
                                                                width: `50%`,
                                                            }}
                                                        >
                                                            <strong style={{ fontSize: 18 }}>
                                                                {metaData.noCVERemediation}
                                                            </strong>{" "}Vulnerabilities
                                                            <br />
                                                            cannot be autofixed
                                                            {viewCantFixVul && (
                                                                <FontAwesomeIcon
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: 5,
                                                                        right: 5,
                                                                    }}
                                                                    icon={faCircleCheck}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-start flex-row">
                                                    <span className="me-2">
                                                        XIL can autofix{" "}
                                                        <strong>{metaData.availableCVERemediation}</strong>{" "}
                                                        out of <strong>{metaData.totalVul}</strong> CVEs (
                                                        <b>{metaData.criticalCount}</b>C,{" "}
                                                        <b>{metaData.highCount}</b>H,{" "}
                                                        <b>{metaData.mediumCount}</b>M,{" "}
                                                        <b>{metaData.lowCount}</b>L),
                                                    </span>
                                                    <span>
                                                        no autofix available for {metaData.noCVERemediation}{" "}
                                                        CVEs
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {!_.isEmpty(recommendationsData) && !_.isEmpty(recommendationsData.remediationDetails) && <div className="d-flex align-items-end justify-content-end col-sm-4">
                                        <button
                                            onClick={handleApplyAutofixClick}
                                            className="btn btn-primary mt-3 me-2"
                                            disabled={metaData.availableCVERemediation === 0}
                                        >
                                            <FontAwesomeIcon className="me-2" icon={faWandMagicSparkles} />Apply autofix for{" "}
                                            <strong style={{ fontSize: 18 }}>
                                                {metaData.availableCVERemediation}
                                            </strong>{" "}
                                            vulnerabilities
                                        </button>
                                    </div>}
                                </div>
                            )}
                        <div className="card custom-card">
                            <div className="card-body">
                                <ReviewAutofixDataTable
                                    dataSet={recommendationsData}
                                    loadingData={loading}
                                    scannedReport={scannedReport}
                                    getScanFileLocation={getScanFileLocation}
                                />
                            </div>
                        </div>
                    </div>
                    {loading ? <Loader /> : ""}
                </>
            )}
        </section>
    );
}