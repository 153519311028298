import cmIcon from '../../assets/img/k8sIcons/cm.svg';
import deployIcon from '../../assets/img/k8sIcons/deploy.svg';
import dsIcon from '../../assets/img/k8sIcons/ds.svg';
import epIcon from '../../assets/img/k8sIcons/ep.svg';
import nsIcon from '../../assets/img/k8sIcons/ns.svg';
import podIcon from '../../assets/img/k8sIcons/pod.svg';
import rsIcon from '../../assets/img/k8sIcons/rs.svg';
import saIcon from '../../assets/img/k8sIcons/sa.svg';
import secretIcon from '../../assets/img/k8sIcons/secret.svg';
import svcIcon from '../../assets/img/k8sIcons/svc.svg';
import volIcon from '../../assets/img/k8sIcons/vol.svg';
import clusterIcon from '../../assets/img/k8sIcons/cluster.svg';
import computeEngine from '../../assets/img/gcpIcons/compute_engine.svg';
import VPC from '../../assets/img/gcpIcons/virtual_private_cloud.svg';
import cloudIAM from '../../assets/img/gcpIcons/identity_and_access_management.svg';
import configurationManagement from '../../assets/img/gcpIcons/configuration_management.svg';
import cloudStorage from '../../assets/img/gcpIcons/cloud_storage.svg';
import cloudLogging from '../../assets/img/gcpIcons/cloud_logging.svg';
import jobIcon from '../../assets/img/k8sIcons/job.svg';
import cronJobIcon from '../../assets/img/k8sIcons/cronjob.svg';
import ingIcon from '../../assets/img/k8sIcons/ing.svg';
import stsIcon from '../../assets/img/k8sIcons/sts.svg';
import pvcIcon from '../../assets/img/k8sIcons/pvc.svg';
import netpolIcon from '../../assets/img/k8sIcons/netpol.svg';
import kubernetes from "../../assets/img/kubernetes.svg";
import analyticsHub from '../../assets/img/gcpIcons/analytics_hub.svg';
import crbIcon from '../../assets/img/k8sIcons/crb.svg';
import cRoleIcon from '../../assets/img/k8sIcons/c-role.svg';
import rbIcon from '../../assets/img/k8sIcons/rb.svg';
import roleIcon from '../../assets/img/k8sIcons/role.svg';
import pvIcon from '../../assets/img/k8sIcons/pv.svg';
import crdIcon from '../../assets/img/k8sIcons/crd.svg';
import nodeIcon from '../../assets/img/k8sIcons/node.svg';
import defaultIcon from '../../assets/img/k8sIcons/ds.svg';

export function getK8sIcon(docType) {
    const iconType = docType ? docType.toLowerCase() : '';

    switch (iconType) {
        case 'namespace':
            return nsIcon;
        case 'servicetype':
        case 'service':
            return svcIcon;
        case 'clusterip':
        case 'serviceport':
        case 'endpoint':
            return epIcon;
        case 'volume':
        case 'volumemount':
            return volIcon;
        case 'pod':
            return podIcon;
        case 'configmap':
            return cmIcon;
        case 'serviceaccount':
            return saIcon;
        case 'deployment':
            return deployIcon;
        case 'replicaset':
            return rsIcon;
        case 'daemonset':
            return dsIcon;
        case 'secret':
            return secretIcon;
        case 'cluster':
        case 'root':
            return kubernetes;
        case 'services':
            return svcIcon;
        case 'iam':
            return cloudIAM;
        case 'clusterresources':
            return crdIcon;
        case 'statefulset':
            return stsIcon;
        case 'job':
            return jobIcon;
        case 'cronjob':
            return cronJobIcon;
        case 'ingress':
            return ingIcon;
        case 'networkpolicy':
            return netpolIcon;
        case 'persistentvolumeclaim':
            return pvcIcon;
        case 'container':
            return podIcon;


        //new
        case 'node':
            case 'nodes':
            return nodeIcon;
        case 'persistentvolume':
            return pvIcon;
        case 'role':
            return roleIcon
        case 'rolebinding':
            return rbIcon
        case 'clusterrole':
            return cRoleIcon
        case 'clusterrolebinding':
            return crbIcon
        case 'nodes':
            return defaultIcon;
        case 'clusterroles':
            return cRoleIcon;
        case 'clusterrolebindings':
            return crbIcon;
        case 'persistentvolumes':
            return pvIcon;


        //new
        case 'compute':
            return computeEngine;
        case 'rbac':
            return analyticsHub;
        case 'configuration':
            return configurationManagement;
        case 'storage':
            return cloudStorage;
        case 'workloads':
            return cloudLogging;
        case 'networking':
            return VPC;
        case 'other':
            return defaultIcon;

        default:
            return defaultIcon;
    }
}