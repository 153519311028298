import React, { useEffect, useState } from "react";
import { Result, Button } from 'antd';
import _ from 'lodash';
import axios from "axios";
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Typography, Space } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";


import FixBySeverityTable from './fixBySeverityDataTable';
import ReviewFixVulDataTable from './reviewFixVulDataTable';
import { GlobalConst } from "../../shared/appConfig/globalConst";


export default function AutoFixVulnerabilityModal({ id, searchResults, selectedFileRecord, loadedResults, setOpenFixVulnerability, suggestedRemediation, setSuggestedRemediation }) {

    const [selectedFixOptions, setSelectedFixOptions] = useState('fixBySeverity');
    const [selectedSeverity, setSelectedSeverity] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const [loading, setLoading] = useState(false);
    const [showReview, setShowReview] = useState(true);
    const [selectedModule, setSelectedModule] = useState('');

    const handleCancelClick = () => {
        setOpenFixVulnerability(false);
    }

    useEffect(() => {
        if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.scanResult)) {
            if (searchResults.scanResult.length > 0) {
                setSelectedModule(searchResults.scanResult[0].applicationLocation);
            }
        }
    }, [searchResults]);

    // useEffect(() => {
    //     console.log("We are the selected Rows here::", selectedRows);
    // }, [selectedRows]);

    const handleGetRemediationClick = () => {

        setLoading(true);
        let postData = {};
        if (selectedFixOptions === "fixBySeverity") {
            let sev = [];
            selectedSeverity.forEach((el) => {
                let sevLi = '';
                if (el === 'critical') {
                    sevLi = 'C';
                }
                else if (el === 'high') {
                    sevLi = 'H';
                }
                else if (el === 'medium') {
                    sevLi = 'M';
                }
                else {
                    sevLi = 'L';
                }
                sev.push(sevLi);
            });

            postData = {
                "reportId": id,
                "scanFileLocation": '',
                // "fixAll": false,
                // "fixTypes": sev,
                "cveList": []
            }
        }

        // console.log("Searched Result:", searchResults);
        // console.log("selected File Record", selectedFileRecord);

        let filteredSingleModule = _.filter(selectedFileRecord, function (o) {
            return o.applicationLocation == selectedModule;
        })

        // let cveList = [];
        if (!_.isEmpty(filteredSingleModule)) {
            postData.scanFileLocation = filteredSingleModule[0].scanFileLocation;

            //     if (!_.isEmpty(filteredSingleModule[0].scanResult)) {

            //         _.forIn(filteredSingleModule[0].scanResult, (value, key) => {


            //             if (!_.isEmpty(value) && !_.isEmpty(value.cves)) {

            //                 value.cves.forEach((el, index) => {
            //                     if (!_.isEmpty(selectedSeverity)) {
            //                         let severity = (el.cvssV3BaseSeverity) ? el.cvssV3BaseSeverity : el.cvssV2BaseSeverity;
            //                         if (_.includes(selectedSeverity, severity.toLowerCase())) {
            //                             cveList.push(el.cveId);
            //                         }

            //                     }
            //                     else {
            //                         cveList.push(el.cveId);
            //                     }

            //                 });


            //                 // console.log("Selected Filtered Single Module", filteredSingleModule, selectedSeverity);


            //             }

            //         });





            //     }

        }

        if (!_.isEmpty(selectedRows)) {
            postData.cveList = selectedRows.map(item => item.cveId);
        }

        // console.log("Selected Row::", selectedRows);
        // console.log("CVE List:", cveList);
        // console.log("Post Data:", postData);

        setLoading(false);

        // return;
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/get-remediation`, postData)
            .then((op) => {
                // console.log("op==>", op);
                setLoading(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setSuggestedRemediation(op.data);
                    // console.log("Response data ::", op.data);
                    setShowReview(true);
                }
            })
            .catch(e => {
                console.log("Exception: ", e);
            })
    }

    const handleApplyAutofixClick = () => {


        let scanResult = searchResults.scanResult;
        // console.log("The main scan result passed from parent", scanResult);

        let postData = {};

        postData = {
            "branch": searchResults.branch,
            "repoUrl": searchResults.assetUrl,
            "urlType": searchResults.assetSubtype,
            "remediationPackage": {}
        };



        if (!_.isEmpty(suggestedRemediation)) {
            let newDataSet = [], foundModule = null, cveList = [];
            let selectedModuleData = suggestedRemediation;

            // console.log("suggested remediation", selectedModuleData);

            _.forEach(selectedModuleData, (parentVal, parentKey) => {
                if (!_.isEmpty(parentVal)) {

                    // console.log("I am the parentVal", parentKey);
                    foundModule = _.find(scanResult, { applicationLocation: parentKey });
                    // console.log("I am the find module here::", foundModule);




                    newDataSet = [];
                    _.forEach(parentVal, (childVal, childKey) => {


                        if (!_.isEmpty(childVal)) {
                            // console.log("Child Key::", childVal);
                            const targetValues = _.get(foundModule.scanResult, `${childKey}`, null);
                            // console.log("Found Module==?", foundModule);

                            cveList = [];
                            childVal.forEach((element, elementKey) => {
                                if (element.bestVersion) {
                                    if (!_.isEmpty(foundModule.scanResult)) {
                                        
                                        if (!_.isEmpty(targetValues) && !_.isEmpty(targetValues.cves)) {
                                            // console.log("I am the cves::", targetValues.cves);
                                            _.forEach(targetValues.cves, (cveValue, cveKey) => {
                                                cveList.push(cveValue.cveId);
                                            })
                                        }
                                    }





                                    newDataSet.push({
                                        "packageName": element.packageName,
                                        "currentVersion": element.currentVersion,
                                        "upgradeToVersion": element.upgradeToVersion,
                                        "ecosystem": element.ecosystem,
                                        "cveList": (!_.isEmpty(cveList)) ? _.uniq(cveList) : cveList
                                    })
                                }
                            })

                            //   }
                            if (!_.isEmpty(newDataSet)) {
                                postData.remediationPackage[foundModule.scanFileLocation] = newDataSet;
                            }

                        }

                    });
                }
            })

            // postData.remediationPackage = newDataSet;
        }


        // console.log("The new dataset is ::", postData);

        // return;


        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/create-pull-request`, postData)
            .then((op) => {
                // console.log("op==>", op);
                setLoading(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    // setSuggestedRemediation(op.data);
                    // console.log("Response data :: from the create pull request", op.data);

                    setShowReview(true);


                }
            })
            .catch(e => {
                setLoading(false);
                console.log("Exception: ", e);
            })
    }

    const getFixType = (value) => {
        switch (value) {
            case 'fixAll':
                return 'Fix all';
            case 'fixBySeverity':
                return 'Fix by severity';
            case 'fixByCve':
                return 'Fix by CVE';
            default:
                return '';

        }
    }

    const handleSeverityChange = (e) => {
        if (e.target.checked && !_.includes(selectedSeverity, e.target.value)) {
            setSelectedSeverity([...selectedSeverity, e.target.value]);
        }
        else {
            setSelectedSeverity([..._.without(selectedSeverity, e.target.value)]);
        }
    }

    const handleBackClick = () => {
        setShowReview(false);
        setSuggestedRemediation({});
    }

    const getModuleMenuItems = () => {
        let returnMenu = [];

        if (!_.isEmpty(suggestedRemediation)) {

            _.forEach(suggestedRemediation, (value, key) => {
                returnMenu.push({
                    key: key,
                    label: key
                });
            });
        }

        return returnMenu;
    }


    const getFileLocation = (file) => {
        let fileSplit = file.split('/');
        return `${fileSplit[fileSplit.length - 2]}/${fileSplit.pop()}`
    }

    const moduleMenuItems = () => {
        let returnMenu = [];
        if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.scanResult)) {

            _.forEach(searchResults.scanResult, (value, key) => {
                if (!_.isEmpty(value.applicationLocation)) {
                    returnMenu.push({
                        key: value.applicationLocation,
                        label: value.applicationLocation
                    });
                }
            });
        }
        return returnMenu;
    }

    return (
        <>
            {(!showReview) ? <div className="mb-2">Select Module: <span className="text-capitalize">
                <Dropdown
                    menu={{
                        items: moduleMenuItems(),
                        selectable: true,
                        defaultSelectedKeys: [selectedModule],
                        onSelect: ({ key }) => {
                            setSelectedModule(key);
                            setSelectedRows([]);
                            setSelectedSeverity([]);
                            setShowReview(false);
                        }
                    }}
                    trigger={['click']}
                >
                    <Typography.Link>
                        <Space>
                            {(!_.isEmpty(selectedModule) ? selectedModule : '')}
                            <DownOutlined />
                        </Space>
                    </Typography.Link>
                </Dropdown>
            </span>
            </div>
                : <></>
            }


            {
                (showReview) ?
                    <section className="my-4">
                        <h5 className="mb-4 d-flex align-items-center justify-content-start">
                            {/* <span className="btn btn-outline-primary btn-sm me-2" onClick={handleBackClick}><FontAwesomeIcon icon={faCircleChevronLeft} className="me-1" /> Back</span> */}
                            Review Your Changes
                        </h5>
                        {/* <div className="mb-4 row">
                            <span><b>Fix Type: </b> {getFixType(selectedFixOptions)}</span>
                        </div> */}
                        {/* <div className="mb-4 row">
                            <span><b>Selected Severity Level: </b> <span className="text-capitalize">{selectedSeverity.join(', ')}</span></span>
                        </div> */}
                        {/* <div className="mb-4 row">
                            <span><b>Module: </b>
                                <span>
                                    <Space>
                                        {(!_.isEmpty(selectedModule) ? selectedModule : '')}
                                    </Space>
                                </span>
                            </span>
                        </div> */}



                        <div className="mb-3">
                            <ReviewFixVulDataTable dataSet={suggestedRemediation} selectedModule={selectedModule} />
                        </div>
                        <footer className="mb-3">
                            <div className="d-flex align-items-center justify-content-end">
                                <Button onClick={handleApplyAutofixClick} type="primary" loading={loading}>Apply Autofix</Button>
                                <Button onClick={handleCancelClick} className="ms-2">Cancel</Button>
                            </div>
                        </footer>




                    </section>
                    :
                    <section className="my-4">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" disabled={!_.isEmpty(suggestedRemediation)} checked={selectedFixOptions === 'fixAll'} onChange={(e) => { setSelectedFixOptions('fixAll') }} id="fixAll" value="fixAll" />
                            <label className="form-check-label" htmlFor="fixAll">Fix all</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" disabled={!_.isEmpty(suggestedRemediation)} checked={selectedFixOptions === 'fixBySeverity'} onChange={(e) => { setSelectedFixOptions('fixBySeverity') }} id="fixBySeverity" value="fixBySeverity" />
                            <label className="form-check-label" htmlFor="fixBySeverity">Fix by severity</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" disabled={!_.isEmpty(suggestedRemediation)} checked={selectedFixOptions === 'fixByCve'} onChange={(e) => { setSelectedFixOptions('fixByCve') }} id="fixByCve" value="fixByCve" />
                            <label className="form-check-label" htmlFor="fixByCve">Fix by CVE</label>
                        </div>

                        <hr />



                        <div>


                            <p className="mb-2">Choose severity level to apply autofix:</p>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" onChange={(e) => handleSeverityChange(e)} checked={_.includes(selectedSeverity, 'critical')} type="checkbox" value="critical" id={'chkCritical'} />
                                <label className="form-check-label" htmlFor="chkCritical">Critical</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" onChange={(e) => handleSeverityChange(e)} checked={_.includes(selectedSeverity, 'high')} type="checkbox" value="high" id={'chkHigh'} />
                                <label className="form-check-label" htmlFor="chkHigh">High</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" onChange={(e) => handleSeverityChange(e)} checked={_.includes(selectedSeverity, 'medium')} type="checkbox" value="medium" id={'chkMedium'} />
                                <label className="form-check-label" htmlFor="chkMedium">Medium</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" onChange={(e) => handleSeverityChange(e)} checked={_.includes(selectedSeverity, 'low')} type="checkbox" value="low" id={'chkLow'} />
                                <label className="form-check-label" htmlFor="chkLow">Low</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            {(selectedFixOptions === 'fixBySeverity') &&
                                <>
                                    {(searchResults && Object.keys(searchResults).length > 0) ?
                                        <>
                                            {selectedFileRecord && <div className="d-flex flex-column">
                                                <div className="row">
                                                    <div className={`col-sm-12`}>
                                                        <FixBySeverityTable
                                                            searchResults={searchResults}
                                                            dataSet={selectedFileRecord}
                                                            selectedModule={selectedModule}
                                                            // filter={selectedFilter}
                                                            // handleSeverityFilterChange={handleSeverityFilterChange}
                                                            // selectedFile={selectedFile}
                                                            selectedSeverity={selectedSeverity}
                                                            setSelectedRows={setSelectedRows}
                                                            selectedRows={selectedRows}
                                                            setSelectedSeverity={setSelectedSeverity}
                                                        />
                                                    </div>
                                                </div>
                                            </div>}
                                        </>
                                        :
                                        <>
                                            {loadedResults && <Result
                                                status="warning"
                                                title={<span style={{ fontSize: 20 }}>No data found</span>}
                                            />}
                                        </>
                                    }
                                </>
                            }
                        </div>
                        <footer className="mb-3">
                            <div className="d-flex align-items-center justify-content-end">
                                <Button onClick={handleGetRemediationClick} type="primary" disabled={(_.isEmpty(selectedSeverity))} loading={loading}>Get Remediation</Button>
                                <Button onClick={handleCancelClick} className="ms-2">Cancel</Button>
                            </div>
                        </footer>

                    </section>
            }
        </>

    );
}