import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCode, faFileZipper, faXmark } from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash';
import axios from 'axios';
import {
    setScanModalViewState, setScanInProgress, setScanGuidResponse,
    getDashboardDataState, setFirstTrigger, setScannedRecords, setCurrentDashboardPageNumber,
    setAllUserRepoList,
    setSanningFromDrag,
    setUserListPageNumber
} from '../../redux/reducers/scannerReducer';
import { Input, Modal, Tooltip, Divider } from 'antd';
import { faRegistered } from "@fortawesome/free-regular-svg-icons";

import SelectRepository from "./selectRepository";
import { faBitbucket, faGithub, faGitlab } from "@fortawesome/free-brands-svg-icons";
import { getIntegratedState, removeGitSuffix } from '../../helper/genHelper';
import { message, Upload } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { GlobalConst } from "../../appConfig/globalConst";
import NoDataFound from "../noDataFound";


export default function ScanModal() {

    const dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { Search } = Input;
    const scanModalState = useSelector((state) => state.scannerReducer.scanModalViewStatus);
    const [searchVal, setSearchVal] = useState('');
    const [showMore, setShowMore] = useState(false);
    const [selectedRepo, setSelectedRepo] = useState([]);
    const [loadingRepos, setLoadingRepos] = useState(false);
    const [loadingTags, setLoadingTags] = useState(false);
    const [unscannedRepoState, setUnscannedRepoState] = useState(true);
    const [branchList, setBranchList] = useState([]);
    const [loadingBranches, setLoadingBranches] = useState(false);
    const [activeCollapse, setActiveCollapse] = useState();
    const [uploadedFile, setUploadedFile] = useState(null);
    const [activeTab, setActiveTab] = useState(false);
    const [integratedSystems, setIntegratedSystems] = useState({});


    const { Dragger } = Upload;
    const props = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        className: 'custom-dragger-upload',
        customRequest: (p1, p2, p3) => {
        },
        onChange(info) {
            setSearchVal('');
            setUploadedFile(info);
            message.info(`${info.file.name} file uploaded successfully, scanning in progress.`);
            let presentRecord = _.find(reduxState.scannerReducer.scannedRecords, function (o) { return removeGitSuffix(o.url) === removeGitSuffix(info.file.name); });
            if (!_.isEmpty(presentRecord)) {
                dispatch(setCurrentDashboardPageNumber(1)); // change the page number on the dashboard.
                var clonedRecords = _.cloneDeep(reduxState.scannerReducer.scannedRecords);
                presentRecord = {
                    ...presentRecord,
                    rowStatus: 'INITIATED'
                }
                clonedRecords = _.remove(clonedRecords, (o) => o.key !== presentRecord.key);
                clonedRecords.unshift(presentRecord); // Add the object to the beginning of the array
                let newData = [];
                if (!_.isEmpty(clonedRecords)) {
                    clonedRecords.forEach((el, ind) => {
                        newData.push({
                            ...el,
                            key: `dataRow-${ind}`,
                            serialNumber: ind + 1
                        })
                    })
                }
                dispatch(setScannedRecords(newData));
            }
            else {
                let newRecord = {
                    key: `dataRow-new`,
                    serialNumber: 1,
                    name: decodeURIComponent(info.file.name),
                    owner: '',
                    org: '',
                    total: 0,
                    critical: 0,
                    high: 0,
                    medium: 0,
                    low: 0,
                    na: 0,
                    branch: '',
                    type: 'github',
                    url: info.file.name,
                    assetType: 'file',
                    subAssetType: 'sbom',
                    reportId: '',
                    scanDate: '',
                    scanningRow: false,
                    ecosystem: [],
                    rowStatus: 'INITIATED',
                    source: null
                };
                let updatedScanRecords = [{ ...newRecord }, ...reduxState.scannerReducer.scannedRecords];
                let newData = [];

                if (!_.isEmpty(updatedScanRecords)) {
                    updatedScanRecords.forEach((el, ind) => {
                        newData.push({
                            ...el,
                            key: `dataRow-${ind}`,
                            serialNumber: ind + 1
                        })
                    })
                }
                dispatch(setScannedRecords(newData)); // Update the redux state array to load the first record on top.
            }

            let data = new FormData();
            // console.log("I am the file obj", info.file.originFileObj);
            data.append('file', info.file.originFileObj);

            let config = {
                method: 'post',
                // maxBodyLength: Infinity,
                url: `${GlobalConst.API_URL}/auth/foss/scan/file`,
                headers: { 'content-type': 'multipart/form-data' },
                data: data
            };
            // console.log("Configuration::", config);
            // setIsScanningFromDrag(true);
            dispatch(setSanningFromDrag(true));
            dispatch(setScanModalViewState(false));
            axios.request(config)
                .then((response) => {
                    // console.log("I am the response here::", response.data);
                    if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
                        // Add the file to the scannerList here.
                        // update the uuid in the table.
                        dispatch(setScanGuidResponse(response.data));
                        // message.success(`${info.file.name} file scanning complete.`);
                    }
                    // setIsScanningFromDrag(false);
                    // console.log(JSON.stringify(response.data));
                })
                .catch((e) => {
                    console.log("Exception", e);
                    // setIsScanningFromDrag(false);
                    dispatch(setSanningFromDrag(false));
                    message.error(`${info.file.name} is not a valid file.`);
                });
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        },
    };

    useEffect(() => {
        getUserIntegrations()
        // Now the api will be fired here.
        if (_.isEmpty(reduxState.scannerReducer.allUserRepoList)) {
            getRepositories();
        }
    }, []);

    const getRepositories = (urlType, targetOrg) => {
        setLoadingRepos(true);
        dispatch(setUserListPageNumber(0));
        let reqBody = {
            "urlType": urlType,
            "page": reduxState.scannerReducer.userListPageNumber,
            "recordsPerPage": GlobalConst.GIT_REPO_LIST_PAGE_COUNT
        }
        if (!_.isEmpty(targetOrg)) {
            reqBody = {
                ...reqBody,
                "owner": targetOrg,
            }
        }
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/get-repo-list`, reqBody)
            .then(op => {
                setLoadingRepos(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.data)) {
                    dispatch(setAllUserRepoList(op.data.data));
                }
                else {
                    dispatch(setAllUserRepoList([]));
                }
            })
            .catch(e => {
                console.log("Exception: ", e.response);
                setLoadingRepos(false);
                dispatch(setAllUserRepoList([]));
            });
    }

    const getTags = (repoName, owner) => {
        setLoadingTags(true);
        const reqBody = {
            "urlType": reduxState.authReducer.loggedInFrom,
            "owner": owner,
            "repoName": repoName,
        }
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/get-tag-list`, reqBody)
            .then(op => {
                setLoadingTags(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {

                }
                else {
                    // show user the error message that there is no repo list.
                }
            })
            .catch(e => {
                console.log("Exception: ", e);
                setLoadingTags(false);
            });
    }

    const handleInputChange = (e) => {
        setSearchVal(e.target.value);
        setUploadedFile(null);
    }

    const handleCancelCLick = () => {
        dispatch(setScanModalViewState(false));
    }


    function isValidHttpUrl(string) {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    const scanApplicationFromText = () => {
        let isDemoUser = localStorage.getItem('isDemoUser');
        if (isDemoUser && isDemoUser === 'true') {
            alert("Scan has been disabled for this account");
        }
        else {
            dispatch(setScanInProgress(true));
            dispatch(setScanModalViewState(false));

            if (!_.isEmpty(selectedRepo)) {
                // console.log("I am the scan repo selected repos", selectedRepo);
                let requestList = [];
                let presentRepos = [];

                selectedRepo.forEach((el) => {
                    requestList.push({
                        location: el.url,
                        urlType: activeTab !== '6' ? "github" : 'bitbucket'
                    });
                    // check if the record is present in the scan list. If the record is present then 
                    const presentRecord = _.find(reduxState.scannerReducer.scannedRecords, function (o) { return removeGitSuffix(o.url) === removeGitSuffix(el.url); });
                    if (!_.isEmpty(presentRecord)) {
                        presentRepos.push(presentRecord);
                    }
                });

                // console.log("Present Repos:", presentRepos);
                if (!_.isEmpty(presentRepos)) {
                    dispatch(setCurrentDashboardPageNumber(1));
                    // There are present repos so we need to shift things in scanned records
                    let clonedPresentRecords = [];
                    presentRepos.forEach((presentRecord) => {
                        clonedPresentRecords.push({ ...presentRecord, rowStatus: 'INITIATED' });
                    });
                    // console.log("Cloned Present records with status changed", clonedPresentRecords);

                    var clonedRecords = _.cloneDeep(reduxState.scannerReducer.scannedRecords);

                    const comparator = (record1, record2) => record1.key === record2.key;

                    // Remove matching records from the 'records' array
                    _.pullAllWith(clonedRecords, clonedPresentRecords, comparator);

                    // now that we have removed the existing records from the array join the array with new records.
                    let newMergedRecords = [...clonedPresentRecords, ...clonedRecords];

                    // now change their indexes

                    let newData = [];

                    newMergedRecords.forEach((el, ind) => {
                        newData.push({
                            ...el,
                            key: `dataRow-${ind}`,
                            serialNumber: ind + 1
                        })
                    })

                    // console.log("newData::", newData);
                    dispatch(setScannedRecords(newData));
                    triggerScan(requestList);

                }
                else {
                    // when there are no present repos in the scanned list so we will directly add them in the scanned list and trigger the scans.
                    let newRecords = [];
                    selectedRepo.forEach((el) => {
                        let name = '';
                        if (isValidHttpUrl(el.url)) {
                            var pathname = new URL(el.url).pathname;
                            pathname = pathname.substring(1);
                            name = pathname;
                        }
                        else {
                            name = el.url;
                        }
                        newRecords.push({
                            key: `dataRow-new`,
                            serialNumber: 1,
                            name: decodeURIComponent(name),
                            owner: '',
                            org: '',
                            total: 0,
                            critical: 0,
                            high: 0,
                            medium: 0,
                            low: 0,
                            na: 0,
                            type: activeTab !== '6' ? "github" : 'bitbucket',
                            url: el.url,
                            branch: '',
                            assetType: 'scm',
                            subAssetType: activeTab !== '6' ? "github" : 'bitbucket',
                            reportId: '',
                            scanDate: '',
                            scanningRow: false,
                            ecosystem: [],
                            rowStatus: 'INITIATED',
                            source: null
                        })
                    });

                    let updatedScanRecords = [...newRecords, ...reduxState.scannerReducer.scannedRecords];
                    let newData = [];

                    if (!_.isEmpty(updatedScanRecords)) {
                        updatedScanRecords.forEach((el, ind) => {
                            newData.push({
                                ...el,
                                key: `dataRow-${ind}`,
                                serialNumber: ind + 1
                            })
                        })
                    }
                    dispatch(setScannedRecords(newData)); // Update the redux state array to load the first record on top.
                    triggerScan(requestList);
                }
                return;
            }

            if (!_.isEmpty(searchVal)) {
                // first push the new record in the list
                // 1_ Go through the records and get the current record on top of the array
                let name = '';
                if (isValidHttpUrl(searchVal)) {
                    var pathname = new URL(searchVal).pathname;
                    pathname = pathname.substring(1);
                    name = pathname;
                }
                else {
                    name = searchVal;
                }
                // Before inserting the new record check if there is a same repo with the same url link.
                let presentRecord = _.find(reduxState.scannerReducer.scannedRecords, function (o) { return removeGitSuffix(o.url) === removeGitSuffix(searchVal); });

                if (!_.isEmpty(presentRecord)) {
                    // when there is a record found so we need to move the record to the first place and trigger the scan
                    dispatch(setCurrentDashboardPageNumber(1)); // change the page number on the dashboard.
                    var clonedRecords = _.cloneDeep(reduxState.scannerReducer.scannedRecords);
                    // get the record on top of the array...

                    presentRecord = {
                        ...presentRecord,
                        rowStatus: 'INITIATED'
                    }
                    clonedRecords = _.remove(clonedRecords, (o) => o.key !== presentRecord.key);
                    clonedRecords.unshift(presentRecord); // Add the object to the beginning of the array
                    // reset the key of the array by modifying the keys.
                    let newData = [];

                    if (!_.isEmpty(clonedRecords)) {
                        clonedRecords.forEach((el, ind) => {
                            newData.push({
                                ...el,
                                key: `dataRow-${ind}`,
                                serialNumber: ind + 1
                            })
                        })
                    }

                    dispatch(setScannedRecords(newData));
                    let requestList = [];
                    requestList.push({
                        location: presentRecord.url,
                        urlType: activeTab !== '6' ? "github" : 'bitbucket'
                    });
                    triggerScan(requestList);
                }
                else {
                    // When there is no record found we need to add the new record to the scan records and then trigger the scan.
                    let newRecord = {
                        key: `dataRow-new`,
                        serialNumber: 1,
                        name: decodeURIComponent(name),
                        owner: '',
                        org: '',
                        total: 0,
                        critical: 0,
                        high: 0,
                        medium: 0,
                        low: 0,
                        na: 0,
                        type: 'github',
                        branch: '',
                        url: searchVal,
                        assetType: 'scm',
                        subAssetType: 'github',
                        reportId: '',
                        scanDate: '',
                        scanningRow: false,
                        ecosystem: [],
                        rowStatus: 'INITIATED',
                        source: null
                    };

                    let updatedScanRecords = [{ ...newRecord }, ...reduxState.scannerReducer.scannedRecords];
                    let newData = [];

                    if (!_.isEmpty(updatedScanRecords)) {
                        updatedScanRecords.forEach((el, ind) => {
                            newData.push({
                                ...el,
                                key: `dataRow-${ind}`,
                                serialNumber: ind + 1
                            })
                        })
                    }
                    dispatch(setScannedRecords(newData)); // Update the redux state array to load the first record on top.
                    let requestList = [];
                    requestList.push({
                        location: searchVal,
                        urlType: activeTab !== '6' ? "github" : 'bitbucket'
                    });
                    triggerScan(requestList);
                    return;
                }
            }
            if (!_.isEmpty(uploadedFile)) {
                let data = new FormData();
                data.append('file', uploadedFile.file.originFileObj);


                let config = {
                    method: 'post',
                    url: `${GlobalConst.API_URL}/auth/foss/scan/file`,
                    headers: { 'content-type': 'multipart/form-data' },
                    data: data
                };
                axios.request(config)
                    .then((response) => {
                        if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
                            dispatch(getDashboardDataState(true));
                            message.success(`${uploadedFile.file.name} file scanning complete.`);
                        }
                    })
                    .catch((e) => {
                        console.log("Exception", e);
                        message.error(`${uploadedFile.file.name} is not a valid file.`);
                    });
            }

        }
    }


    const triggerScan = (requestList) => {
        let requestBody = {
            "urlType": activeTab !== '6' ? "github" : 'bitbucket',
            "scanRequestList": requestList
        };
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/ascan?enableSbom=true`, requestBody)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    dispatch(setScanGuidResponse(op.data));
                    dispatch(setFirstTrigger(true));
                }
            })
            .catch(e => { console.log("Exception: ", e) });
    }

    const getUserIntegrations = () => {
        axios
            .get(`${GlobalConst.API_URL}/auth/integrate/user-integrations`)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setIntegratedSystems(op.data);
                }

            })
            .catch(e => {
                console.log("Exception:", e);
            })
    }

    const handleBitbucketClick = (e) => {
        e.preventDefault();
        if (!_.isEmpty(integratedSystems) && !_.isEmpty(integratedSystems['bitbucket']))
            getRepositories('bitbucket', integratedSystems['bitbucket'].targetorg)
        setActiveTab('6')
    }

    const handleGithubClick = (e) => {
        e.preventDefault();
        setActiveTab('1')
        getRepositories('github')
    }

    return (
        <Modal
            title=""
            open={scanModalState}
            footer={null}
            onCancel={handleCancelCLick}
            closeIcon={<FontAwesomeIcon className="fs-5 text-white" icon={faXmark} />}
            width={850}
            maskClosable={false}
            className="scan-modal"
        >
            <section className="m-1">
                <div className="">
                    <div className="text-white">
                        <div className="mb-3">
                            <h5 className="mb-0 text-white" style={{ fontWeight: 600 }}>iScan <small style={{ fontSize: '65%' }} className="mb-0 ms-2 text-muted">AI Powered Scans <FontAwesomeIcon icon={faRegistered} style={{ fontSize: 10 }} /></small></h5>
                            <div className="d-flex align-items-center justify-content-start"></div>
                        </div>
                        <div className="my-3 mb-1">
                            <div className="me-3 mb-2">Select asset to scan</div>
                            <div className="d-flex align-items-center justify-content-start">
                                <Tooltip title="Scan Github Repo">
                                    <div style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7 }} className={`p-2 rounded ${activeTab === '1' ? 'active-other-link' : 'border'} other-links`} onClick={handleGithubClick}>
                                        <p className="mb-0"><FontAwesomeIcon icon={faGithub} className="icon" style={{ fontSize: 20 }} /></p>
                                        <p className="mb-0 text-center" style={{ width: 70 }}>Github</p>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Scan by uploading file">
                                    <div className={`${activeTab === '3' ? 'active-other-link' : 'border'} p-2 rounded other-links`} style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7 }} onClick={(e) => { e.preventDefault(); setActiveTab('3') }}>
                                        <p className="mb-0"><FontAwesomeIcon icon={faFileCode} style={{ fontSize: 20 }} /></p>
                                        <p className="mb-0 text-center" style={{ width: 70 }}>SBOM</p>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Scan a binary package">
                                    <div className={`${activeTab === '4' ? 'active-other-link' : 'border'} p-2 rounded other-links`} style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7 }} onClick={(e) => { e.preventDefault(); setActiveTab('4') }}>
                                        <p className="mb-0"><FontAwesomeIcon icon={faFileZipper} style={{ fontSize: 20 }} /></p>
                                        <p className="mb-0 text-center" style={{ width: 70 }}>Binary</p>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Scan Bitbucket Repo">
                                    <div style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7 }} className={`p-2 rounded ${activeTab === '6' ? 'active-other-link' : 'border'} other-links`} onClick={handleBitbucketClick}
                                    >
                                        <p className="mb-0"><FontAwesomeIcon icon={faBitbucket} className="icon" style={{ fontSize: 20 }} /></p>
                                        <p className="mb-0 text-center" style={{ width: 70 }}>Bitbucket</p>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Scan Gitlab Repo">
                                    <div style={{ cursor: 'pointer', marginRight: 12, opacity: 0.4 }} className={`p-2 rounded ${activeTab === '7' ? 'active-other-link' : 'border'} other-links disabled pe-none`}
                                    >
                                        <p className="mb-0"><FontAwesomeIcon icon={faGitlab} className="icon" style={{ fontSize: 20 }} /></p>
                                        <p className="mb-0 text-center" style={{ width: 70 }}>GitLab</p>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <div>
                            {(activeTab === '1' || activeTab === '6') &&
                                <div className="mt-3">
                                    <>
                                        {getIntegratedState(
                                            integratedSystems,
                                            activeTab !== '6' ? 'github' : 'bitbucket'
                                        ) ? <SelectRepository
                                            loadingRepos={loadingRepos}
                                            selectedRepo={selectedRepo}
                                            setSelectedRepo={setSelectedRepo}
                                            unscannedRepoState={unscannedRepoState}
                                            setUnscannedRepoState={setUnscannedRepoState}
                                            activeTab={activeTab}
                                            urlType={activeTab !== '6' ? 'github' : 'bitbucket'}
                                            integratedSystems={integratedSystems}
                                        /> : <NoDataFound
                                            title={`We could not found integrations for the scm`}
                                            desc={
                                                <a
                                                    href={`/integrations-list/${activeTab !== '6' ? 'github' : 'bitbucket'}`}
                                                    target="_blank"
                                                >
                                                    Please click here to configure
                                                </a>
                                            }
                                            customInlineStyle={{
                                                image: {
                                                    width: 54,
                                                },
                                                title: {
                                                    fontSize: 14,
                                                },
                                                description: {
                                                    fontSize: 12,
                                                },
                                            }}
                                        />}
                                    </>
                                </div>
                            }
                            {activeTab === '2' && <div><h2 style={{ color: '#adb5bd' }}>Docker Tab</h2></div>}
                            {activeTab === '3' && <div>
                                <div className="my-3">
                                    <Dragger {...props} className="p-0 uploader-custom">
                                        <p className="ant-upload-text mb-0  " style={{ color: '#adb5bd', fontSize: 14 }}>Upload a SBOM file</p>
                                    </Dragger>
                                </div>
                            </div>}
                            {activeTab === '4' && <div><h2 style={{ color: '#adb5bd' }}>Binary Tab</h2></div>}
                            {activeTab === '5' && <div><h2 style={{ color: '#adb5bd' }}>More Integration</h2></div>}
                            <div className="sotcox-osvs-form sotcox-input-field">
                                <form action="#" onSubmit={scanApplicationFromText}>
                                    {activeTab && (
                                        <>
                                            <Divider className="divider-custom my-1" orientation="center"><small>or</small></Divider>
                                            <div className="">
                                                <div>
                                                    <Input
                                                        placeholder="enter an artifact URL to scan for e.g. https://github.com/owner/repo"
                                                        allowClear
                                                        size="large"
                                                        value={searchVal}
                                                        onChange={handleInputChange}
                                                        className="iscan-search"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="text-center ">
                                        <button className={`sotcox-btn mt-3`} type="submit">Scan {!_.isEmpty(selectedRepo) && `${selectedRepo.length} repo${(selectedRepo.length > 1) ? 's' : ''}`}<span></span></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Modal>
    );
}