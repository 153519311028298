import React from 'react';
import { isEmpty } from 'lodash';
import { getStrokeColor } from '../../shared/helper/genHelper';

const SeverityIndicator = ({ severityData }) => {

    // console.log("severityCount".severityData)

  const getDisplaySeverity = (data) => {
    if (isEmpty(data)) return '';
    
    const { critical, high, medium, low } = data;
    
    if (critical > 0) return 'critical';
    if (high > 0) return 'high';
    if (medium > 0) return 'medium';
    if (low > 0) return 'low';
    return '';
  };

//   const getStrokeColor = (severity) => {
//     const colorMap = {
//       critical: '#FFE0E0',
//       high: '#FFF3CD',
//       medium: '#FFF8E6',
//       low: '#E8F5E9',
//       NA: '#F5F5F5'
//     };
//     return colorMap[severity] || colorMap.NA;
//   };

  const severity = getDisplaySeverity(severityData);

  return (
    <div 
      className="p-0 px-2 text-center capitalize"
      style={{ 
        backgroundColor: getStrokeColor(severity),
        borderRadius: 50,
        fontSize: 10,
        color: '#000',
        width: 52
      }}
    >
      {severity}
    </div>
  );
};

export default SeverityIndicator;

// Usage example:
// const Example = () => {
// //   const severityData = {
// //     critical: 1,
// //     medium: 12,
// //     high: 23,
// //     low: 23
// //   };

//   return <SeverityIndicator severityData={severityData} />;
// };