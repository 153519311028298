import { Checkbox, Table, Tooltip } from "antd";
import _ from "lodash";
import { BsShieldFillExclamation } from "react-icons/bs";
import { IoShieldCheckmark } from "react-icons/io5";
import { getStrokeColor } from "../../shared/helper/genHelper";

export default function ContainerMetaList({
  layers,
  selectedLayer,
  setLayerId,
  layerPackageMap,
  layerVulnerabilities,
}) {
  const shouldShowCheckbox = (record) =>
    !_.isEmpty(layerPackageMap) &&
    !_.isEmpty(record.layerId) &&
    _.has(layerPackageMap, record.layerId) &&
    !_.isEmpty(layerPackageMap[record.layerId].vulnerablePackageList);

  const columns = [
    {
      title: "",
      key: "checkbox",
      width: 30,
      render: (text, record) =>
        shouldShowCheckbox(record) ? (
          <Checkbox
            checked={selectedLayer === record.layerId}
            onChange={() => setLayerId(record.layerId)}
          />
        ) : null,
    },
    {
      title: "Id",
      dataIndex: "id",
      width: 40,
    },
    {
      title: "Command",
      dataIndex: "command",
      ellipsis: true,
    },
    {
      title: "Is Vulnerable Packages",
      dataIndex: "",
      fixed: "right",
      width: 30,

      render: (text, row) => {
        return !_.isEmpty(layerVulnerabilities) && !_.isEmpty(layerVulnerabilities[row.layerId]) ? <Tooltip title={layerVulnerabilities[row.layerId]}>
          {layerVulnerabilities[row.layerId] === "LOW" ? (
            <IoShieldCheckmark
              style={{
                fontSize: 20,
                color: getStrokeColor(layerVulnerabilities[row.layerId]),
              }}
            />
          ) : (
            <BsShieldFillExclamation
              style={{
                fontSize: 20,
                color: getStrokeColor(layerVulnerabilities[row.layerId]),
              }}
            />
          )}
        </Tooltip> : null;
      },
    },
  ];

  return (
    <>
      <div style={{ overflow: "hidden", borderRadius: 8 }}>
        <div className="pb-2">Layers</div>
        <Table
          showHeader={false}
          columns={columns}
          dataSource={layers}
          size="small"
          className="table table-striped custom-table mb-0"
          onRow={(row) => ({
            onClick: (event) => {
              if (
                event.target.tagName.toLowerCase() !== "input" &&
                shouldShowCheckbox(row)
              ) {
                setLayerId(row.layerId);
              }
            },
            style: {
              color: "#adb5bd ",
              backgroundColor:
                selectedLayer === row.layerId
                  ? "rgb(37, 43, 54)"
                  : "transparent",
              cursor: shouldShowCheckbox(row) ? "pointer" : "default",
              pointerEvents: !row.layerId ? "none" : "auto",
            },
          })}
          pagination={{
            showTotal: (total, range) => {
              return (
                <span className="text-white">
                  {range[0]}-{range[1]} of {total && total.format()} layers
                </span>
              );
            },
            defaultPageSize: 20,
            showSizeChanger: true,
            position: ["bottom", "left"],
            className: "searchPagePagination px-0",
            pageSizeOptions: [20, 30, 40, 50],
          }}
        />
      </div>
    </>
  );
}
