import { createSlice } from "@reduxjs/toolkit";

const contSecurityReducer = createSlice({
  name: "contSecurityReducer",
  initialState: {
    iScanModalState: false,
    scannedContainers: [],
    scanGuidResponse: [], // contains all the list of current scans which are triggered.
    noScannedRecords: false,
    currentDashboardPageNumber: 1,
  },
  reducers: {
    setContainerScanModalState: (state, action) => {
      state.iScanModalState = action.payload;
    },
    setScannedContainers: (state, action) => {
      state.scannedContainers = action.payload;
    },
    setScanGuidResponse: (state, action) => {
      // console.log("Acr", action.payload)
      state.scanGuidResponse = action.payload;
    },
    setNoScannedRecords: (state, action) => {
      state.noScannedRecords = action.payload;
    },
    setConScanPage: (state, action) => {
      state.currentDashboardPageNumber = action.payload;
    },
    updateConScannedRecord: (state, action) => {
      const itemIndex = state.scannedContainers.findIndex(item => action.payload.reportId === item.reportId);
      if (itemIndex !== -1) {
          state.scannedContainers[itemIndex] = { ...state.scannedContainers[itemIndex], ...action.payload };
      }
    },
    addContainerGuid: (state, action) => {
      state.scanGuidResponse = [...state.scanGuidResponse, ...action.payload];
    },
  },
});

export const { setContainerScanModalState, setScannedContainers, setScanGuidResponse, setNoScannedRecords, setConScanPage, updateConScannedRecord, addContainerGuid } = contSecurityReducer.actions;
export default contSecurityReducer.reducer;
