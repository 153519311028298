import _ from 'lodash';
import gcpIcon from '../../assets/img/scopy-tech-icons/gcp.svg';
import computeEngine from '../../assets/img/gcpIcons/compute_engine.svg';
import persistantDisk from '../../assets/img/gcpIcons/persistent_disk.svg';
import VPC from '../../assets/img/gcpIcons/virtual_private_cloud.svg';
import cloudIAM from '../../assets/img/gcpIcons/identity_and_access_management.svg';
import cloudSql from '../../assets/img/gcpIcons/cloud_sql.svg';
import analyticsHub from '../../assets/img/gcpIcons/analytics_hub.svg';
import cloudMonitoring from '../../assets/img/gcpIcons/cloud_monitoring.svg';
import cloudStorage from '../../assets/img/gcpIcons/cloud_storage.svg';
import bigQuery from '../../assets/img/gcpIcons/bigquery.svg';
import cloudNetwork from '../../assets/img/gcpIcons/cloud_network.svg';
import cloudFirewallRules from '../../assets/img/gcpIcons/cloud_firewall_rules.svg';
import cloudLogging from '../../assets/img/gcpIcons/cloud_logging.svg';
import bucket from '../../assets/img/gcpIcons/bucket-icon.svg';
import ipAddress from '../../assets/img/gcpIcons/cloud_external_ip_addresses.svg';
import certificate from '../../assets/img/gcpIcons/certificate_authority_service.svg';
import configurationManagement from '../../assets/img/gcpIcons/configuration_management.svg';
import cloudDNS from '../../assets/img/gcpIcons/cloud_dns.svg';
import gke from '../../assets/img/gcpIcons/google_kubernetes_engine.svg';


export function getGCPIcon(iconName) {

    let icon = (!_.isEmpty(iconName)) ? iconName.toLowerCase() : '';

    switch (icon) {
        case 'vm':
            return computeEngine;
        case 'compute':
            return computeEngine;
        case 'disk':
            return persistantDisk;
        case 'network':
            return VPC;
        case 'vpc_network':
            return VPC;
        case 'subnet':
            return cloudNetwork;
        case 'firewall':
            return cloudFirewallRules;
        case 'iampolicy':
            return cloudIAM;
        case 'iam':
            return cloudIAM;
        case 'role':
            return cloudIAM;
        case 'service_account':
            return cloudIAM;
        case 'logging':
            return cloudLogging;
        case 'serviceaccount':
            return cloudIAM;
        case 'database':
            return cloudSql;
        case 'sql':
            return cloudSql;
        case 'analytics':
            return analyticsHub;
        case 'logging_monitoring':
            return cloudMonitoring;
        case 'monitoring':
            return cloudMonitoring;
        case 'gcp_storage':
            return cloudStorage;
        case 'storage':
            return cloudStorage;
        case 'bigquery':
            return bigQuery;
        case 'website':
            return bucket;
        case 'ip_address':
            return ipAddress;
        case 'ssl_cert':
            return certificate;
        case 'network_interface':
            return cloudNetwork;
        case 'shielded_instance_config':
            return configurationManagement;
        case 'confidential_instance_config':
            return configurationManagement;
        case 'dns':
            return cloudDNS;
        case 'gke':
            return gke;
        default:
            return gcpIcon;
    }

}