import React from 'react';
// import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import { Tooltip } from 'antd';

import { useD3 } from '../../shared/hooks/useD3';
import { GlobalConst } from '../../shared/appConfig/globalConst';

function createSVG({
    nodes, // an iterable of node objects (typically [{id}, …])
    links // an iterable of link objects (typically [{source, target}, …])
}, {
    nodeId = d => d.id, // given d in nodes, returns a unique identifier (string)
    nodeLevel = d => d.level,
    severity = d => d.severity,
    nodeGroup, // given d in nodes, returns an (ordinal) value for color
    nodeGroups, // an array of ordinal values representing the node groups
    nodeTitle, // given d in nodes, a title string
    nodeFill = "currentColor", // node stroke fill (if not using a group color encoding)
    nodeStroke = "#ffffff", // node stroke color
    nodeStrokeWidth = 0, // node stroke width, in pixels
    nodeStrokeOpacity = 1, // node stroke opacity
    nodeRadius = 5, // node radius, in pixels
    nodeStrength,
    linkSource = ({ source }) => source, // given d in links, returns a node identifier string
    linkTarget = ({ target }) => target, // given d in links, returns a node identifier string
    linkType = ({ linkTyp }) => linkTyp,
    nodeType = d => d.type,
    linkStroke = "#aaa", // link stroke color
    linkStrokeOpacity = 0.7, // link stroke opacity
    linkStrokeWidth = 4, // given d in links, returns a stroke width in pixels
    linkStrokeLinecap = "round", // link stroke linecap
    linkStrength = 0.1,
    colors = d3.schemeTableau10, // an array of color strings, for the node groups
    width = 1250, // outer width, in pixels
    height = 1330, // outer height, in pixels
    invalidation // when this promise resolves, stop the simulation
} = {}, svg) {

    // Compute values.
    const N = d3.map(nodes, nodeId).map(intern);
    const S = d3.map(nodes, severity).map(intern);
    const NT = d3.map(nodes, nodeType).map(intern);
    const LVL = d3.map(nodes, nodeLevel).map(intern);
    const LS = d3.map(links, linkSource).map(intern);
    const LT = d3.map(links, linkTarget).map(intern);
    const LinkType = d3.map(links, linkType).map(intern);
    if (nodeTitle === undefined) nodeTitle = (_, i) => N[i];
    const T = nodeTitle == null ? null : d3.map(nodes, nodeTitle);
    const G = nodeGroup == null ? null : d3.map(nodes, nodeGroup).map(intern);
    const W = typeof linkStrokeWidth !== "function" ? null : d3.map(links, linkStrokeWidth);

    // console.log("I am the n", LVL)
    // let newHeight = 1330;

    // Replace the input nodes and links with mutable objects for the simulation.
    nodes = d3.map(nodes, (_, i) => ({ id: N[i], severity: S[i], type: NT[i] }));
    links = d3.map(links, (_, i) => ({ source: LS[i], target: LT[i], type: LinkType[i] }));

    // console.log("We are the nodes::", nodes);
    // console.log("We are the links ------>", links)

    // Compute default domains.
    if (G && nodeGroups === undefined) nodeGroups = d3.sort(G);
    // console.log("Colors::", colors);
    colors = [...GlobalConst.CHART_COLORS];

    // Construct the scales.
    const color = nodeGroup == null ? null : d3.scaleOrdinal(nodeGroups, colors);
    const radius = d3.map(nodes, (_, i) => ({ radius: (LVL[i] !== 1) ? ((LVL[i] * 2) + nodeRadius) : nodeRadius + 2 }));
    // console.log("Radius::", radius)

    // Construct the forces.
    const forceNode = d3.forceManyBody();

    // let distance = 600;
    // console.log("Length", N.length);
    switch (true) {
        case N.length > 400:
            // distance = 600;
            // nodeStrength = -20;
            break;
        case N.length > 200:
            // distance = 150;
            nodeStrength = -100;
            break;
        case N.length > 100:
            // distance = 200;
            nodeStrength = -150;
            break;
        case N.length > 50:
            // distance = 350;
            nodeStrength = -220;
            break;
        default:
            // distance = 600;
            nodeStrength = -300;
            break;
    }

    // .force("charge", d3.forceManyBody().strength(-400))

    const getIndex = (val) => {
        if (val === 'module') {
            return 0;
        }
        if (val === 'cveNode') {
            return 2
        }
        else {
            return 1
        }
    }

    // console.log("Node strength::", nodeStrength);
    // console.log("Link strength::", linkStrength);

    // var xcenter = [1,100,150];

    const forceLink = d3.forceLink(links).id(({ index: i }) => N[i]).distance(5);
    if (nodeStrength !== undefined) forceNode.strength(nodeStrength);
    if (linkStrength !== undefined) forceLink.strength(linkStrength);

    const simulation = d3.forceSimulation(nodes)
        .force("link", forceLink)
        .force("charge", forceNode)
        // .force("x", d3.forceX().x(function(d){return xcenter[getIndex(d.type)]}))
        .force("x", d3.forceX())
        // .force("x", d3.forceX(function(d){
        // 	if(d.group === 0){
        // 		return width/3
        // 	} else if (d.group === 1){
        // 		return 2*width/3
        // 	} else {
        // 		return width/2 
        // 	}
        // }))
        // .force("y", d3.forceY().y(function(d){console.log("ddd:::",d);return 0;}))
        .force("y", d3.forceY())
        .force("collide", d3.forceCollide(function (d) {
            return radius[d.index].radius + 5;
        }))
        .on("tick", ticked);


    simulation.alphaTarget(1);

    // simulation.force("x", d3.forceX(width / 2).strength(0.1));
    // simulation.force("y", d3.forceY(height / 2).strength(0.1));

    // const duration = 3000;

    // // Schedule a function to stop the simulation after the specified duration
    // setTimeout(() => {
    //     simulation.stop();
    // }, duration);


    // .force("y", d3.forceY(height / 2));
    // .force("collide", d3.forceCollide(0).strength(0).radius(function (d) { return d.radius; }).iterations(10));

    // const svg = d3.create("svg")
    svg
        .attr("width", width)
        .attr('shape-rendering', 'geometricPrecision')
        .attr("height", height)
        .attr("viewBox", [-width / 2, -height / 2, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");



    svg.selectAll("*")
        .filter(function () {
            return !this.classList.contains('filter-css');
        })
        .remove();

    const defs = svg.append('defs');
    // Create Defs for emosed view
    GlobalConst.CHART_COLORS.forEach((mapColor, index) => {
        // Append all the gradient colors defs here
        // if (index === 11) {
        //     const linearGradient = defs.append('linearGradient')
        //         .attr('id', `gradient${index}`)
        //         // .attr('gradientUnits', 'userSpaceOnUse')
        //         .attr('inkscapeCollect', 'always')
        //         .attr('y2', '100%')
        //         .attr('x2', '0')
        //         .attr('y1', '0')
        //         .attr('x1', '0');
        //     linearGradient.append('stop')
        //         .attr('style', `stop-color:${mapColor}`)
        //         .attr('offset', '30%');
        //     linearGradient.append('stop')
        //         .attr('style', `stop-color:${GlobalConst.CHART_GRADIENT_COLORS[index]}`)
        //         .attr('offset', '100%');

        // }
        // else {
        const linearGradient = defs.append('linearGradient')
            .attr('id', `gradient${index}`)
            // .attr('gradientUnits', 'userSpaceOnUse')
            .attr('inkscapeCollect', 'always')
            .attr('y2', '100%')
            .attr('x2', '0')
            .attr('y1', '0')
            .attr('x1', '0');
        linearGradient.append('stop')
            .attr('style', `stop-color:${mapColor}`)
            .attr('offset', '30%');
        linearGradient.append('stop')
            .attr('style', `stop-color:${GlobalConst.CHART_GRADIENT_COLORS[index]}`)
            .attr('offset', '100%');

        // }



    });

    GlobalConst.LINK_COLORS.forEach((mapColor, index) => {
        const linearGradient = defs.append('linearGradient')
            .attr('id', `lineGradient${index}`)
            .attr('gradientUnits', 'userSpaceOnUse')
            .attr('inkscapeCollect', 'always')
            .attr('y2', '100%')
            .attr('x2', '0')
            .attr('y1', '0')
            .attr('x1', '0');
        linearGradient.append('stop')
            .attr('style', `stop-color:${mapColor}`)
            .attr('offset', '30%');
        linearGradient.append('stop')
            .attr('style', `stop-color:${GlobalConst.LINK_GRADIENT_COLORS[index]}`)
            .attr('offset', '100%');

    });


    const combinedNodes = svg.append("g");

    const parentLinkContainer = combinedNodes.append('g')
        .selectAll("g")
        .data(links)
        .enter()
        .append('g');


    const link = parentLinkContainer.append("line")
        // .data(links)
        // .attr("stroke", linkStroke)
        .attr('stroke', (d) => {
            switch (d.type) {
                case 'moduletocomponent':
                    // lineGradient
                    // return '#544fc5';
                    return '#2caffe'
                case 'componenttocve':
                    // return '#E6E6FA';
                    return '#544fc5';
                default:
                    return '#544fc5';
            }
            // if (d.type === 'cveNode') { return '#544fc5' } else { return '#544fc5' }
        })
        .attr("stroke-opacity", (d) => {
            switch (d.type) {
                case 'moduletocomponent':
                    return 1;
                case 'componenttocve':
                    return 0.9;
                default:
                    return 0.4;
            }
        })
        // strokeDasharray="5, 5"
        // .attr('strokeDasharray', (d) => { if (d.type === 'cwe') { return '5, 5' } else { return '0, 0' } })
        .attr("stroke-width", (d) => {
            switch (d.type) {
                case 'moduletocomponent':
                    return 1.5;
                case 'componenttocve':
                    // return 0.7;
                    return 1.5;
                default:
                    return 1;
            }
            // if (d.type === 'cveNode') { return 1; } else { return 3; } 
        }) // typeof linkStrokeWidth !== "function" ? linkStrokeWidth : null)
        .attr("stroke-linecap", linkStrokeLinecap)
        .attr('shape-rendering', 'geometricPrecision');
    // .selectAll("line")

    // .join("line");



    // console.log("Width is::", W);

    if (W) link.attr("stroke-width", ({ index: i }) => { return W[i] }).attr('stroke', (d) => { return 'url(#gradient1)' });




    const textAndNodes = combinedNodes.append('g')
        .selectAll("g")
        .data(nodes)
        .enter()
        .append('g')
        .attr('class', 'text-and-nodes')
        .attr('filter', 'none')
        .attr('opacity', 1)
        .call(drag(simulation));

    const circle = textAndNodes.append("path")
        // .attr("style", (d) => { return `fill:url(#gradient${d.index})`})
        .attr("sodipodi:type", "arc")
        .attr("d", (d) => {
            // console.log("D::", radius);
            let newRadius = radius[d.index].radius;
            // if(newRadius===7){
            //     newRadius = 2;
            // }
            return `M 0, 0 m -${newRadius}, 0 a ${newRadius},${newRadius} 0 1,0 ${newRadius * 2},0 a ${newRadius},${newRadius} 0 1,0 -${newRadius * 2},0`;
        });
    // .attr('stroke-width', 2)
    // .attr('stroke', (d) => {

    // console.log("D is ::", d);
    // if (d.severity != 'CRITICAL') { return '#7a1e00' }
    // if (d.severity === 'HIGH') { return '#ae3b01' }
    // });



    const text = textAndNodes.append("text")
        .text(function (d) {
            // console.log("d value:", d);
            switch (d.type) {
                case 'module':
                    return 'M';
                case 'component':
                    return 'C';
                default:
                    return '';
            }
            // if (LVL[d.index] != 1) { return `${getData(d.id).substring(0, 8)}...`; } 

        })
        .attr('y', 4)
        .attr('dx', 0)
        .attr('dy', 0)
        .attr('fill', '#ffffff')
        .attr('text-anchor', 'middle')
        .attr('font-size', 14)
        .attr("style", "cursor: default; pointer-events: all;");
        
    text.append("title").text(({ index: i }) => T[i]).attr("style", "cursor: default; pointer-events: all;");
    // .style("cursor", "pointer")
    // .style("pointer-events", "all");

    // if (G) circle.attr("fill", ({ index: i }) => color(G[i]));
    if (G) circle.attr("style", ({ index: i, severity: s, type: t }) => {
        // console.log("t", t);
        // if (s === 'CRITICAL') {
        //     return `fill:url(#gradient${G[i]})`;
        // }
        // else if (s === 'HIGH') {
        //     return `fill:url(#gradient${G[i]})`;
        // }
        // else {
        //     return `fill:url(#gradient10)`;
        // }

        // if(s===''){
        // console.log("Type:: g---->", G[i])

        // }
        if (t === 'cveNode') {
            if (s === 'CRITICAL') {
                return `fill:url(#gradient12);cursor: default; pointer-events: all;`;
            }
            if (s === 'HIGH') {
                return `fill:url(#gradient13);cursor: default; pointer-events: all;`;
            }
            if (s === 'MEDIUM') {
                return `fill:url(#gradient14);cursor: default; pointer-events: all;`;
            }
            if (s === 'LOW') {
                return `fill:url(#gradient15);cursor: default; pointer-events: all;`;
            }
            // if (t === 'cveNode') {
            return `fill:url(#gradient10);cursor: default; pointer-events: all;`;
            // }
        }
        return `fill:url(#gradient${G[i]});cursor: default; pointer-events: all;`;


    });
    // circle.attr("style", (d) => { if(d.severity==='CRITICAL'){return 'fill:url(#f0a134)'} if(d.severity==='HIGH'){return 'fill:url(#f0a134)'}});
    if (T) circle.append("title").text(({ index: i }) => T[i]).attr("style", "cursor: default; pointer-events: all;");


    // textAndNodes.on('mouseover', function (d) {
    //     // console.log("I am the d here::", d)
    //     link.style('stroke-width', function (l) {
    //         // console.log("I am the l ::", l)
    //         if (d === l.source || d === l.target)
    //             return 7;
    //         else
    //             return 5;
    //     });
    // });

    // Set the stroke width back to normal when mouse leaves the node.
    textAndNodes.on('mouseout', function () {
        link.style('stroke-width', 2);
    });


    // Handle invalidation.
    if (invalidation != null) invalidation.then(() => simulation.stop());

    function intern(value) {
        return value !== null && typeof value === "object" ? value.valueOf() : value;
    }

    // function ticked() {
    //     link
    //         .attr("x1", d => d.source.x)
    //         .attr("y1", d => d.source.y)
    //         .attr("x2", d => d.target.x)
    //         .attr("y2", d => d.target.y);
    //     // parentLinkContainer
    //     //     .attr("x1", d => d.source.x)
    //     //     .attr("y1", d => d.source.y)
    //     //     .attr("x2", d => d.target.x)
    //     //     .attr("y2", d => d.target.y);
    //     // node
    //     //     .attr("cx", d => d.x)
    //     //     .attr("cy", d => d.y);

    //     // Translate the groups
    //     textAndNodes.attr("transform", function (d) {
    //         return 'translate(' + [d.x, d.y] + ')';
    //     });
    // }

    let tickCount = 0;
    const maxTickCount = 300; // Maximum number of iterations

    function ticked() {
        link
            .attr("x1", d => d.source.x)
            .attr("y1", d => d.source.y)
            .attr("x2", d => d.target.x)
            .attr("y2", d => d.target.y);

        textAndNodes.attr("transform", d => `translate(${d.x},${d.y})`);


        tickCount++;
        // Fix node positions after simulation stabilizes
        if (tickCount >= maxTickCount) {
            // if (simulation.alpha() < 0.01) {
            nodes.forEach(d => {
                d.fx = d.x;
                d.fy = d.y;
            });
            // }
        }
    }

   

    function drag(simulation) {
        function dragstarted(event) {
            // console.log("Drag started")
            if (!event.active) simulation.alphaTarget(0.3).restart();
            event.subject.fx = event.subject.x;
            event.subject.fy = event.subject.y;
        }

        function dragged(event) {
            event.subject.fx = event.x;
            event.subject.fy = event.y;
        }

        function dragended(event) {
            // console.log("Drag end");
            if (!event.active) simulation.alphaTarget(0.08);
            event.subject.fx = null;
            event.subject.fy = null;
        }

        return d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);
    }

    const zoom = d3.zoom()
        .scaleExtent([0.1, 10]) // set min and max zoom levels
        .on("zoom", (event) => {
            combinedNodes.attr("transform", event.transform);
        });
    svg.call(zoom).call(drag(simulation));


    // const duration = 300000;
    // const alphaTarget = 0.001; // Threshold alpha value for stopping

    // // Gradually decrease alpha until it reaches the threshold
    // const stopGradually = () => {
    //     if (simulation.alpha() > alphaTarget) {
    //         // Decrease alpha gradually
    //         simulation.alphaTarget(alphaTarget).restart();
    //         // Schedule the next step
    //         setTimeout(stopGradually, duration / 100);
    //     } else {
    //         // Once alpha is below the threshold, stop the simulation
    //         simulation.stop();
    //     }
    // };

    // // Start the gradual stopping process
    // stopGradually();

}


const getData = (value) => {
    if (value) {
        return value.split('/').pop().split('@')[0];
    }
    else {
        return value;
    }
}



export default function ConnectedGraph({ data, options }) {
    const ref = useD3((svg) => {
        createSVG(data, options, svg);
    }, [data]);
    return (
        <>
            {data ?
                <svg
                    ref={ref}
                    style={{
                        height: 700,
                        width: "100%",
                        marginRight: "0px",
                        marginLeft: "0px",
                    }}
                >
                </svg>
                : ''}


        </>
    );
}