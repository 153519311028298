import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, notification, Tag } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCheck,
  faExclamationCircle,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import _ from "lodash";
import Loader from "../../shared/sharedComponents/loader";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import CustomResultView from "../../shared/sharedComponents/customResultView";
import { GlobalConst } from "../../shared/appConfig/globalConst";

const ContainerRegistrySettings = ({
  registryName,
  targetSystem,
  fields,
  apiEndpoint = `${GlobalConst.API_URL}/auth/integrate/user-pat`,
  registryTokenLink,
  patHelpTitle = "Creating a personal access token",
  patHelpText = "To create a personal access token, follow the below link:",
  patHelpBtnText = "Create a personal access token",
}) => {
  const [registrySettings, setRegistrySettings] = useState(
    fields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
  );
  const [apiFired, setApiFired] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState({});
  const [confirmOpenDeleteModal, setConfirmOpenDeleteModal] = useState(false);
  const [organization, setOrganization] = useState("");
  const [result, setResult] = useState({});

  const reduxState = useSelector((state) => state);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (
      !_.isEmpty(reduxState) &&
      !_.isEmpty(reduxState.authReducer) &&
      !_.isEmpty(reduxState.authReducer.userDetails) &&
      !_.isEmpty(reduxState.authReducer.userDetails.email)
    ) {
      getUserPat();
    }
  }, [reduxState]);

  const getUserPat = () => {
    setShowLoader(true);
    let data = {
      userEmail: reduxState.authReducer.userDetails.email,
      targetSystem: targetSystem,
    };
    axios
      .post(`${GlobalConst.API_URL}/auth/integrate/get-user-pat`, data)
      .then((op) => {
        setShowLoader(false);
        if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.data)) {
          setError(false);
          setOrganization(op.data.data.org);
          const decodedAccessToken = atob(op.data.data.accessToken);
          const decodedAccTokenObj = JSON.parse(decodedAccessToken);
          setRegistrySettings(decodedAccTokenObj);
        } else {
          setError(true);
        }
      })
      .catch((e) => {
        setError(true);
        setShowLoader(false);
        console.log("Exception:", e);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(e.currentTarget.files);

    if (!_.isEmpty(e.currentTarget) && !_.isEmpty(e.currentTarget.files)) {
      const file = e.currentTarget.files[0];
      console.log(file);
      fileToBase64(file)
        .then((base64String) => {
          console.log("File converted to base64", base64String);
          setRegistrySettings((prevSettings) => ({
            ...prevSettings,
            [name]: base64String,
          }));
        })
        .catch((error) => {
          console.error("Error converting file to base64:", error);
          setRegistrySettings((prevSettings) => ({
            ...prevSettings,
            [name]: null, // Set to null if there's an error
          }));
        });
    } else {
      setRegistrySettings((prevSettings) => ({
        ...prevSettings,
        [name]: value,
      }));
    }
  };

  // Function to convert file to base64
  const fileToBase64 = (file) => {
    console.log("fileToBase64");
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (error) => {
        reject(new Error("Failed to read file: " + error));
      };

      reader.readAsDataURL(file);
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setApiFired(true);
    setShowLoader(true);
    let postData = {
      targetSystem,
      userEmail: reduxState.authReducer.userDetails.email,
      accessToken: btoa(
        JSON.stringify({
          ...registrySettings,
        })
      ),
    };

    const data = new FormData();

    if (id) {
      postData = {
        ...postData,
        integrationId: id,
      };
    }
    data.append("request", JSON.stringify(postData));

    const config = {
      method: "post",
      url: apiEndpoint,
      headers: { "content-type": "multipart/form-data" },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setShowLoader(false);
        setApiFired(false);
        if (
          !_.isEmpty(response) &&
          !_.isEmpty(response.data) &&
          response.data.data.connected === true &&
          response.data.data.saved === true
        ) {
          setError(false);
          setResult({
            show: true,
            isAlert: false,
            message: `Successfully saved ${registryName} settings`,
            description: `Your ${registryName} settings have been saved successfully.`,
          });
        } else if (
          !_.isEmpty(response) &&
          !_.isEmpty(response.data) &&
          response.data.data.connected === false &&
          response.data.data.saved === false
        ) {
          setError(true);
          setResult({
            show: true,
            isAlert: true,
            message: "Invalid Credentials",
            description:
              "The provided credentials are invalid. Please verify and try again. Your credentials were not saved.",
          });
        } else {
          setError(true);
          setResult({
            show: true,
            isAlert: true,
            message: "Undefined Error",
            description:
              "An error occurred while attempting to save Container registry credentials. Please try again later.",
          });
        }
      })
      .catch((error) => {
        setApiFired(false);
        if (error.response && error.response.data)
          setError(error.response.data);
        else setError(true);
        setShowLoader(false);
        setResult({
          show: true,
          isAlert: true,
          message: "Error",
          description: `An error occurred while attempting to save the ${registryName} settings: ${error.message}`,
        });
      });
  };

  const handleConfirmModalClose = () => {
    setConfirmOpenDeleteModal(false);
  };

  const handleRemoveRepoClick = () => {
    setShowLoader(true);
    let data = {
      userEmail: reduxState.authReducer.userDetails.email,
      targetSystem: targetSystem,
      targetOrg: organization,
    };
    axios
      .delete(`${GlobalConst.API_URL}/auth/integrate/user-pat`, { data })
      .then((op) => {
        setShowLoader(false);
        history.push("/integrations-list");
        setResult({
          show: true,
          isAlert: false,
          message: "Integration Deleted Successfully",
          description: `The ${registryName} integration has been successfully deleted.`,
        });
      })
      .catch((e) => {
        setShowLoader(false);
        console.log("Exception:", e);
        setResult({
          show: true,
          isAlert: true,
          message: "Undefined Error",
          description:
            "An undefined error occurred while attempting to save the data.",
        });
      });
  };

  const isFormValid = useMemo(() => {
    return !Object.values(registrySettings).some((value) => _.isEmpty(value));
  }, [registrySettings]);

  return (
    <section className="mx-3">
      <section>
        <div className="mb-4">
          <div className="">
            <div className="mb-3">
              <Link to="/integrations-list">
                <FontAwesomeIcon className="me-2" icon={faArrowLeft} />
                Back to integrations
              </Link>
            </div>
            <h2
              style={{ lineHeight: 1 }}
              className="sotcox-title mb-2 text-white d-flex align-items-center justify-content-start"
            >
              {registryName}
            </h2>
          </div>
        </div>
        <div className={"mb-5"}>
          <div className="card custom-card">
            <div className="card-body">
              <h3
                className="title pb-2 mb-4 text-white fw-normal d-flex align-items-center justify-content-start"
                style={{ borderBottom: "1px solid #cfdaed" }}
              >
                {registryName} Settings
                {!_.isEmpty(error) || error ? (
                  <Tag className="ms-3" color="error">
                    <FontAwesomeIcon
                      className="me-2"
                      icon={faExclamationCircle}
                    />
                    Not Connected
                  </Tag>
                ) : (
                  <Tag className="ms-3" color="rgb(25,135,84)">
                    <FontAwesomeIcon className="me-2" icon={faCheck} />
                    Connected
                  </Tag>
                )}
              </h3>
              <p className="mb-3">
                Provide your account credentials below to establish a connection
                between Sec1 and your {registryName}.
              </p>
              {!_.isEmpty(result) && result.show && (
                <div className="col-sm-5">
                  <CustomResultView
                    show={result.show}
                    isAlert={result.isAlert}
                    resultPrimaryText={result.message}
                    // resultSecondaryText={result.description}
                  />
                </div>
              )}

              <section className="row">
                <div className="col-sm-5">
                  <form onSubmit={handleFormSubmit}>
                    {fields.map((field) =>
                      field.type === "file" ? (
                        <div key={field.name} className=" w-100 gap-3 mb-3">
                          <div className="mb-1">Service Account JSON File:</div>
                          <div>
                            <input
                              id={field.name}
                              name={field.name}
                              type="file"
                              accept=".json"
                              className="form-control"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="mb-3" key={field.name}>
                          <label
                            htmlFor={field.name}
                            className="form-label mb-1 fw-semibold"
                          >
                            {field.label}
                          </label>
                          <input
                            disabled={apiFired}
                            type={field.type || "text"}
                            name={field.name}
                            value={registrySettings[field.name]}
                            onChange={handleInputChange}
                            className="form-control"
                            id={field.name}
                            placeholder={`Enter ${field.label}`}
                          />
                        </div>
                      )
                    )}
                    <div className="d-flex align-items-center justify-content-start mb-3">
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={!isFormValid || apiFired}
                      >
                        <FontAwesomeIcon icon={faFloppyDisk} className="me-2" />{" "}
                        Save
                      </Button>
                    </div>
                  </form>
                </div>
                <div className="col-sm-4">
                  <div className="alert alert-light">
                    <h4 className="mb-3">{`${patHelpTitle}`}</h4>
                    <p>{`${patHelpText}`}</p>
                    <p>
                      <a
                        className="btn btn-outline-info btn-sm"
                        target="_blank"
                        href={registryTokenLink}
                      >{`${patHelpBtnText}`}</a>
                    </p>
                  </div>
                </div>
              </section>
              <hr />
            </div>
          </div>
        </div>
        {error === false && (
          <div className="mb-5">
            <div className="card custom-card border-danger-subtle">
              <div className="card-body">
                <h3
                  className="title pb-2 mb-4 text-white fw-normal d-flex align-items-center justify-content-start"
                  style={{ borderBottom: "1px solid #cfdaed" }}
                >
                  Danger Zone
                </h3>
                <div className="row">
                  <div className="col-sm-5">
                    <h6>{`Remove ${registryName} Integration`}</h6>
                    <p className="mb-0">{`Removing this integration will delete the stored registry credentials from the Sec1.`}</p>
                  </div>
                  <div className="col-sm-4">
                    <button
                      className="btn btn-outline-danger"
                      onClick={(e) => {
                        e.preventDefault();
                        setConfirmOpenDeleteModal(true);
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      {showLoader && <Loader />}
      {confirmOpenDeleteModal && (
        <Modal
          show={confirmOpenDeleteModal}
          onHide={handleConfirmModalClose}
          size="lg"
          backdrop={"static"}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="border-0" closeButton>
            <Modal.Title>
              Confirm Deletion of <span>{registryName}</span>Integration
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Once you delete the <span>{registryName}</span> integration,{" "}
            <span className="text-danger-emphasis">
              the action cannot be undone
            </span>
            . Please proceed with caution and ensure your decision is final.
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-dark" onClick={handleConfirmModalClose}>
              Close
            </button>
            <button className="btn btn-danger" onClick={handleRemoveRepoClick}>
              Remove
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </section>
  );
};

export default ContainerRegistrySettings;
