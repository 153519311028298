import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';

const threatVisionReducer = createSlice({
    name: 'threatVisionReducer',
    initialState: {
        iScanModalState: false,
        assetsThreatScanRecords: null, 
        allUserRepoList: [], // list of all the github repos fetched from the user account of github portal.
        userListPageNumber: 0,
        scanInProgress: false,
        pendingAssetScans: [],
        assetScanDependencyReport: {},
        overallRiskCategory: ''
    },
    reducers: {
        setIScanModalState: (state, action) => {
            state.iScanModalState = action.payload;
        },
        setAssetsThreatScanRecords: (state, action) => {
            // Filter records with status 'INITIATED' or 'SCANNING'
            const initiatedOrScanning = _.filter(action.payload, record => record.status.toLowerCase() === 'initiated' || record.status.toLowerCase() === 'scanning');

            // Filter records with other statuses
            const otherStatuses = _.filter(action.payload, record => record.status.toLowerCase() !== 'initiated' && record.status.toLowerCase() !== 'scanning');

            // Concatenate the filtered arrays to place 'INITIATED' or 'SCANNING' records first
            const sortedRecords = [...initiatedOrScanning, ...otherStatuses];
            state.assetsThreatScanRecords = _.map(sortedRecords, summaryInfo => ({
                ...summaryInfo,
                rowStatus: summaryInfo.status
            }))
        },
        setAllUserRepoList: (state, action) => {
            state.allUserRepoList = action.payload;
        },
        setUserListPageNumber: (state, action) => {
            state.userListPageNumber = action.payload;
        },
        setPendingAssetScans: (state,action) => {
            state.pendingAssetScans = action.payload
        },
        setAssetScanDependencyReport: (state,action) => {
            state.assetScanDependencyReport = action.payload
        },
        setOverallRiskState: (state, action) => {
            state.overallRiskCategory = action.payload;
        },
    }
})

export const { setIScanModalState, setAllUserRepoList, setAssetsThreatScanRecords, setUserListPageNumber, setPendingAssetScans, setAssetScanDependencyReport, setOverallRiskState } = threatVisionReducer.actions;

export default threatVisionReducer.reducer;