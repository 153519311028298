import { message, Tag, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import ScanModal from "./scanModal";
import {
  setAssetsThreatScanRecords,
  setIScanModalState,
  setOverallRiskState,
  setPendingAssetScans,
} from "../../shared/redux/reducers/threatVisionReducer";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import Loader from "../../shared/sharedComponents/loader";
import GridAssetsView from "./gridAssetsView";
import SummaryTable from "./summaryTable";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { capitalizeFirstLetter, getAIAssetScanStrokeColor, getFontColor, getPlanTypeFromProductKey, getProductSubscription, getStrokeColor } from "../../shared/helper/genHelper";
import { TbGridScan } from "react-icons/tb";

export const DashboardSummary = () => {
  const [loadingDashboardData, setLoadingDashboardData] = useState(false);
  const threatVisionReducer = useSelector((state) => state.threatVisionReducer);
  const subscribedProductsReducer = useSelector(state => state.productsReducer);

  const dispatch = useDispatch();
  const intervalId = useRef(null);

  useEffect(() => {
    getDashboardSummary();
  }, []);

  useEffect(() => {
    if (
      threatVisionReducer &&
      !_.isEmpty(threatVisionReducer.pendingAssetScans)
    ) {
      // Clear previous interval if it exists
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }

      // Set a new interval for polling
      intervalId.current = setInterval(() => {
        poll(threatVisionReducer.pendingAssetScans);
      }, 5000);
    }
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [threatVisionReducer.pendingAssetScans]);

  const poll = (reportIds) => {
    // console.log("Inside poll stats", reportIds);
    axios
      .post(`${GlobalConst.API_URL}/auth/sca-threat-api/reports/status`, {
        reportId: reportIds,
      })
      .then((response) => {
        if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
          const updatedAssetsSummaries =
            threatVisionReducer.assetsThreatScanRecords.map((record) => {
              const isPresent = response.data.find((statusRecord) => {
                return record.reportId === statusRecord.reportId;
              });
              return isPresent ? isPresent : record;
            });
          dispatch(setAssetsThreatScanRecords(updatedAssetsSummaries));
          const scanningOrInitiated = _.chain(updatedAssetsSummaries)
            .filter(
              (record) =>
                record.status.toLowerCase() === "scanning" ||
                record.status.toLowerCase() === "initiated"
            )
            .map("reportId")
            .value();
          console.log(scanningOrInitiated);
          if (_.isEmpty(scanningOrInitiated)) {
            dispatch(setPendingAssetScans([]));
          } else {
            dispatch(setPendingAssetScans(scanningOrInitiated));
          }
        }
      })
      .catch((error) => {
        message.error("Error retrieving threat predictor scan status, please try again later");
        console.error("API Request Failed:", error);
      });
  };

  const handleiScanClick = () => {
      dispatch(setIScanModalState(true));
  };

  const getDashboardSummary = () => {
    setLoadingDashboardData(true);
    axios
      .get(`${GlobalConst.API_URL}/auth/sca-threat-api/dashboard/summary`)
      .then((op) => {
        setLoadingDashboardData(false);
        if (
          !_.isEmpty(op) &&
          !_.isEmpty(op.data) &&
          !_.isEmpty(op.data.documents) &&
          !_.isEmpty(op.data.overallRiskCategory)
        ) {
          dispatch(setOverallRiskState(op.data.overallRiskCategory));
          dispatch(setAssetsThreatScanRecords(op.data.documents));
          const scanningOrInitiated = _.chain(op.data.documents)
            .filter(
              (record) =>
                record.status.toLowerCase() === "scanning" ||
                record.status.toLowerCase() === "initiated"
            )
            .map("reportId")
            .value();
          if (_.isEmpty(scanningOrInitiated)) {
            dispatch(setPendingAssetScans([]));
          } else {
            dispatch(setPendingAssetScans(scanningOrInitiated));
          }
        }
      })
      .catch((e) => {
        setLoadingDashboardData(false);
        console.log("error", e);
        message.error("Error fetching threat predictor report summaries, please try again later");
      });
  };

  return (
    <>
      <section className="mx-3">
        <div className="d-flex align-items-start justify-content-between mb-2">
          <h2 className="text-white">
            Threat Vision{" "}
            <Tooltip title="Scan a repository">
              <button
                className="btn btn-warning py-1 ms-3"
                onClick={handleiScanClick}
              >
                <TbGridScan className={'me-2'} />
                AI Scan
              </button>
            </Tooltip>
          </h2>

          {!_.isEmpty(subscribedProductsReducer.subscribedProducts.activePlans) &&
            !_.isEmpty(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.THREAT_VISION))
            && <div><span className="product-plan-text">Plan - <span className={`${getPlanTypeFromProductKey(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.THREAT_VISION).sProductKey) === 'free' ? 'free-plan' : 'non-free-plan'}`}>{capitalizeFirstLetter(getPlanTypeFromProductKey(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.THREAT_VISION).sProductKey))}</span></span></div>
          }
        </div>
        {!_.isEmpty(threatVisionReducer.overallRiskCategory) && <div className="d-flex align-items-center gap-3">
          <h4 className="text-white mb-0">Overall Risk</h4>
          <Tag
            style={{
              color: getFontColor("CRITICAL"),
              borderRadius: 50,
              width: 100,
              fontSize: 14,
            }}
            color={getAIAssetScanStrokeColor(threatVisionReducer.overallRiskCategory.toUpperCase())}
            className={
              "d-flex align-items-center justify-content-center flex-column p-2"
            }
          >
            <p className="text-white mb-0" style={{ fontSize: 14, lineHeight: 1, fontWeight: 'bold' }}>{threatVisionReducer.overallRiskCategory.toUpperCase()}</p>
          </Tag>
        </div>}
        {!_.isEmpty(threatVisionReducer.assetsThreatScanRecords) && (
          <>
            <div className="row align-items-center">
              <div
                className="
              col-sm-7"
              >
                <div style={{ marginTop: "0.2rem", marginBottom: "1rem" }}>
                  <GridAssetsView />
                </div>
              </div>
              {!_.isEmpty(threatVisionReducer.overallRiskCategory) && <div
                className="
              col-sm-5"
              >
                <div className="row ">

                  <div className="w-75 mx-auto">
                    <Tag
                      style={{
                        color: getFontColor("CRITICAL"),
                        borderRadius: 50,
                      }}
                      color={getAIAssetScanStrokeColor(threatVisionReducer.overallRiskCategory.toUpperCase())}
                      className={
                        "d-flex align-items-center justify-content-center flex-column px-3 mb-3"
                      }
                    >
                      <h5 className="text-white mb-0 py-2">{`AI Prediction:${threatVisionReducer.overallRiskCategory.toLowerCase() === 'low' ? 'No Major' : threatVisionReducer.overallRiskCategory.toLowerCase() === 'medium' ? 'Medium' : 'Major'}`} Threat Detected</h5>
                    </Tag>
                    <Tag
                      style={{
                        color: getFontColor("CRITICAL"),
                        borderRadius: 50,
                      }}
                      color={getAIAssetScanStrokeColor(threatVisionReducer.overallRiskCategory.toUpperCase())}
                      className={
                        "d-flex align-items-center justify-content-center flex-column px-3"
                      }
                    >
                      <h5 className="text-white mb-0 py-2">{`System Stability:${threatVisionReducer.overallRiskCategory.toLowerCase() === 'low' ? 'Fairly Stable' : threatVisionReducer.overallRiskCategory.toLowerCase() === 'medium' || threatVisionReducer.overallRiskCategory.toLowerCase() === 'high' ? 'Medium' : 'Low'}`} </h5>
                    </Tag>
                  </div>

                </div>
              </div>}
            </div>

            <section>
              <SummaryTable getDashboardSummary={getDashboardSummary} />
            </section>
          </>
        )}
        {_.isEmpty(threatVisionReducer.assetsThreatScanRecords) && threatVisionReducer.assetsThreatScanRecords !== null && (
          <div className="text-center mx-4 mt-4">
            <>
              <p className="text-white mb-4">
                <>
                  It looks like you haven't scanned any applications yet. Begin
                  by scanning your first application to get started.
                </>
              </p>
              <Tooltip title="Scan a repository">
                <button
                  className="btn btn-warning py-1 ms-3"
                  onClick={handleiScanClick}
                >
                  AI Scan
                </button>
              </Tooltip>
            </>
          </div>
        )}
      </section>
      {threatVisionReducer.iScanModalState && <ScanModal />}
      {loadingDashboardData && <Loader />}
    </>
  );
};
