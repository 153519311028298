import React, { useCallback, useEffect, useRef, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
// import { monokai } from '@uiw/codemirror-theme-monokai';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import { javascript } from '@codemirror/lang-javascript';
import { lineNumbers } from '@codemirror/view';
// import { defaultKeymap } from '@codemirror/commands';
import { gutter, GutterMarker, EditorView } from "@codemirror/view";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation, faClose, faInfoCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
// import { createRoot } from 'react-dom/client';  // Import createRoot from react-dom/client
import { Button, Space, Tag, Tooltip } from 'antd';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Drawer } from 'antd';
// import { markdown, markdownLanguage } from '@codemirror/lang-markdown';
// import { languages } from '@codemirror/language-data';
import { Tabs } from 'antd';
import VulnerabilityTab from './vulnerabilityTab';

import CodeMirrorMerge from 'react-codemirror-merge';
// import { EditorView } from 'codemirror';
// import { EditorState } from '@codemirror/state';
import RemediationTab from './remediationTab';
import VulnerabilityRemediationTab from './vulnerabilityRemediationTab';
import { getFontColor, getStrokeColor } from '../../../shared/helper/genHelper';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'



const emptyMarker = class extends GutterMarker {
    constructor(vulData, handleIconLineNumberClick, view, line) {
        super();
        this.vulData = vulData;
        this.handleIconLineNumberClick = handleIconLineNumberClick;
        this.view = view;
        this.line = line;
        this.domRef = React.createRef();
        // this.state = { isTooltipVisible: false };
        // this.showTooltip = this.showTooltip.bind(this);
        // this.hideTooltip = this.hideTooltip.bind(this);
    }

    // showTooltip() {
    //     this.setState({ isTooltipVisible: true });
    // }

    // hideTooltip() {
    //     this.setState({ isTooltipVisible: false });
    // }

    toDOM() {
        if (!this.domRef.current) {
            this.domRef.current = document.createElement('div');
        }
        // const content = (
        //     <Tooltip
        //         open={this.state.isTooltipVisible}
        //         // open={this.isTooltipVisible}
        //         // onOpenChange={this.setIsTooltipVisible}
        //         // trigger={'hover'}
        //         destroyTooltipOnHide={true}
        //         mouseLeaveDelay={0.01}
        //         title={<span className='d-flex align-items-center justify-content-center'><FontAwesomeIcon className='me-2 text-danger' icon={faInfoCircle} />{this.vulData}</span>}
        //     >
        //         <FontAwesomeIcon
        //             icon={faTriangleExclamation}
        //             className='text-danger'
        //             style={{ cursor: 'pointer' }}
        //             onMouseEnter={this.showTooltip}
        //             onMouseLeave={this.hideTooltip}
        //         />
        //     </Tooltip>
        // );

        let isTooltipVisible = false;

        const updateTooltip = (visible) => {
            isTooltipVisible = visible;
            ReactDOM.render(createContent(), this.domRef.current);
        };

        const createContent = () => {
            const { title, isFalsePositive,markerBackground } = this.vulData;
            let tooltipTitle = title
            if (isFalsePositive)
                tooltipTitle = "False Positive:" + title

            return <Tooltip
                open={isTooltipVisible}
                trigger={[]}
                destroyTooltipOnHide={true}
                mouseLeaveDelay={0.01}
                title={<span className='d-flex align-items-center justify-content-center'>
                    <FontAwesomeIcon style={{color: markerBackground}} className={'me-2 ' + (isFalsePositive ? 'text-warning' : '')} 
                    icon={!isFalsePositive ? faTriangleExclamation : faInfoCircle}                                        />
                    {tooltipTitle}
                </span>}
            >
                <FontAwesomeIcon
                    icon={!isFalsePositive ? faTriangleExclamation : faInfoCircle}
                    className={(isFalsePositive ? 'text-warning' : '')}
                    style={{ cursor: 'pointer' , color: markerBackground}}
                    onMouseEnter={() => updateTooltip(true)}
                    onMouseLeave={() => updateTooltip(false)}
                    onClick={() => { updateTooltip(false); this.handleIconLineNumberClick(this.view, this.line) }}
                />
            </Tooltip>
        }
        ReactDOM.render(createContent(), this.domRef.current);
        return this.domRef.current;
    }

    destroy() {
        if (this.domRef.current) {
            ReactDOM.unmountComponentAtNode(this.domRef.current);
        }
    }
};

const remediationMarker = class extends GutterMarker {
    toDOM() {
        const div = document.createElement('div');
        const content = (<FontAwesomeIcon icon={faCircleCheck} className='text-success' style={{ cursor: 'pointer' }} />);
        ReactDOM.render(content, div);
        return div;
    }
};

export default function CodeMirrorComp({ oldFile, newFile, fileData, remediation, vulDetailsModalOpen, setVulDetailsModalOpen, remediationDetailsModalOpen, setRemediationDetailsModalOpen, onUpdateFileVulnerability, selectedData }) {

    const [vulnerabilityList, setVulnerabilityList] = useState([]);

    // const [iconHovered, setIconHovered] = useState(false);
    const [defaultSelectedTab, setDefaultSelectedTab] = useState('1');

    const [selectedVulnerability, setSelectedVulnerability] = useState({});
    const [selectedRemediation, setSelectedRemediation] = useState({});
    const [defaultSeletedRemediationTab, setDefaultSelectedRemediationTab] = useState('2');
    const MySwal = withReactContent(Swal)
    let fileViewRef = useRef(null)   

    const Original = CodeMirrorMerge.Original;
    const Modified = CodeMirrorMerge.Modified;

    // const handleLineNumberClick = (view, lineNumber, event) => {
    //     const line = view.state.doc.lineAt(lineNumber.from);
    //     const actualLineNumber = line.number;
    //     showModal();
    // };

    const handleIconLineNumberClick = (view, lineNumber) => {
        const line = view.state.doc.lineAt(lineNumber.from);
        const actualLineNumber = line.number;
        let vulList = vulnerabilityList.vulnerabilities;
        const isPresent = _.find(vulList, { line_number: actualLineNumber });
        setSelectedVulnerability(isPresent);
        // console.log("Selected vul in the left side original file", isPresent);
        setDefaultSelectedTab('1')
        if (!_.isEmpty(isPresent)) {
            showModal();
        }
    };

    const handleSuccessIconLineNumberClick = (view, lineNumber, event) => {
        const line = view.state.doc.lineAt(lineNumber.from);
        const actualLineNumber = line.number;
        let vulList = remediation.remediation;
        const isPresent = _.find(vulList, { fixedLineNumber: actualLineNumber });
        setSelectedRemediation(isPresent);
        setDefaultSelectedRemediationTab('2')
        if (!_.isEmpty(isPresent)) {
            showRemediationDetailsModalOpen();
        }
    };

    const customLineNumbers = lineNumbers({
        domEventHandlers: {
            mousedown: (view, lineNumber, event) => {
                // handleLineNumberClick(view, lineNumber, event);
                // return true;
            },
        },
    });

    const remediateLineNumbers = lineNumbers({
        domEventHandlers: {
            mousedown: (view, lineNumber, event) => {
                // handleLineNumberClick(view, lineNumber, event);
                // return true;
            },
        },
    });

    useEffect(() => {
        if (!_.isEmpty(fileData.vulnerabilities)) {
            setVulnerabilityList(fileData.vulnerabilities[0]);
        }
    }, [fileData]);

    const emptyLineGutter = gutter({
        lineMarker(view, line) {
            const ln = view.state.doc.lineAt(line.from);
            let vulList = vulnerabilityList.vulnerabilities;
            const isPresent = _.find(vulList, { line_number: ln.number });
            return isPresent ? new emptyMarker({
                title: isPresent.title,
                isFalsePositive: isPresent.false_positive,
                markerBackground: getStrokeColor(isPresent.severity.toUpperCase())
            }, handleIconLineNumberClick, view, line) : null
        },
        initialSpacer: (view, line) => new emptyMarker("", handleIconLineNumberClick, view, line)
    });

    const remediationLineGutter = gutter({
        lineMarker(view, line) {
            const ln = view.state.doc.lineAt(line.from);
            let vulList = remediation.remediation;
            const isPresent = _.find(vulList, { fixedLineNumber: ln.number });
            return isPresent ? new remediationMarker(isPresent.title) : null
        },
        initialSpacer: () => new remediationMarker(""),
        domEventHandlers: {
            mousedown: (view, lineNumber, event) => {
                handleSuccessIconLineNumberClick(view, lineNumber, event);
                // return true;
            },
        },
    });

    const showModal = () => {
        setVulDetailsModalOpen(true);
    };
    const handleCancel = () => {
        setVulDetailsModalOpen(false);
    };
    const showRemediationDetailsModalOpen = () => {
        setRemediationDetailsModalOpen(true);
    }
    const handleRemediationDetailsModalClose = () => {
        setRemediationDetailsModalOpen(false);
    }

    const handleUpdateVulnerability = () => {
        MySwal.fire({
            text: "The vulnerability will be marked as false positive, Do you want to confirm",
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                confirmButton: "btn fw-bold btn-primary hover-elevate-up",
                cancelButton: "btn fw-bold btn-secondary hover-elevate-up",
                htmlContainer: "text-white",
                // popup: "bg-header-dark"
            },
            background: "#1f242e"
        }).then(function (result) {
            if (result.value) {
                onUpdateFileVulnerability(selectedVulnerability)
                setVulDetailsModalOpen(false)

            }
            else if (result.dismiss === 'cancel') {
            }
        });
    }

    const handleFileViewCreated = useCallback((view, state) => {
        fileViewRef = { view, state }
                if(!_.isEmpty(fileViewRef) && !_.isEmpty(selectedData)){
                    const {lineNumber} = selectedData
            if (isNaN(lineNumber)) {
                return;
            }
            const doc = fileViewRef.state.doc;
            const lineCount = doc.lines;
            const targetLine = Math.max(1, Math.min(lineNumber, lineCount));
            const lineStart = doc.line(targetLine);
            fileViewRef.view.dispatch({
                effects: EditorView.scrollIntoView(lineStart.from, { y: 'center' })
            });

            // Removed support to optionally, set the cursor to the start of the line due to bug in opening vulnerabilites details
            // fileViewRef.view.dispatch({
            //     selection: { anchor: lineStart.from }
            // });
        }
    }, []);

    const items = [
        {
            key: '1',
            label: 'Vulnerability',
            children: <VulnerabilityTab selectedVulnerability={selectedVulnerability} showTitle={true}
            />,
        }
    ];
    const remediationItems = [
        {
            key: '1',
            label: 'Vulnerability',
            children: <VulnerabilityRemediationTab selectedRemediation={selectedRemediation}
            />,
        },
        {
            key: '2',
            label: 'Remediation',
            children: <RemediationTab remediation={remediation} selectedRemediation={selectedRemediation} />,
        }
    ];

    return (
        <div className='code-container'>

            <div className='p-2' style={{ overflow: 'auto', overflowX: 'hidden', height: '100%', border: '1px solid #3d3d3d', borderRadius: 10 }}>
                {_.isEmpty(newFile) ? <CodeMirror
                    value={oldFile}
                    theme={vscodeDark}
                    // theme={'dark'}
                    editable={false}
                    readOnly={true}
                    // extensions={[markdown({ base: markdownLanguage, codeLanguages: languages }), customLineNumbers, emptyLineGutter]}
                    extensions={[javascript(), customLineNumbers, emptyLineGutter]}
                    onCreateEditor={handleFileViewCreated}
                /> :  !_.isEmpty(remediation) ?
                <CodeMirrorMerge theme={vscodeDark}>
                <Original
                    value={oldFile}
                    editable={false}
                    readOnly={true}
                    extensions={[javascript(), customLineNumbers, emptyLineGutter]}
                />
                <Modified
                    value={newFile}
                    editable={false}
                    readOnly={true}
                    // extensions={[EditorView.editable.of(false), EditorState.readOnly.of(true)]}
                    extensions={[javascript(), remediateLineNumbers, remediationLineGutter]}
                />
            </CodeMirrorMerge> : null}
            </div>
            {(!_.isEmpty(selectedVulnerability) && vulDetailsModalOpen) && <Drawer
                title={selectedVulnerability.title}
                placement={'bottom'}
                closable={true}
                open={vulDetailsModalOpen}
                onClose={handleCancel}
                getContainer={false}
                headerStyle={{ display: 'none' }}
            >
                <Space className='position-absolute end-0 mt-1 me-4' style={{ zIndex: 10001 }}>
                    {!selectedVulnerability.false_positive ? <Button
                        onClick={handleUpdateVulnerability}
                        type="primary"
                        size="medium"
                    >
                        Mark as false positive
                    </Button> :
                        <Tag style={{ color: getFontColor('CRITICAL'), fontWeight: 600, textTransform: 'capitalize' }} className='bg-warning'>
                            False positive
                        </Tag>}
                    <span className='closeIcon-drawer position-relative end-0 top-0' onClick={handleCancel}><FontAwesomeIcon icon={faClose} className='' /></span>
                </Space>
                <Tabs defaultActiveKey={defaultSelectedTab} items={items} />
            </Drawer>}

            {(!_.isEmpty(selectedRemediation) && remediationDetailsModalOpen) && <Drawer
                title={selectedRemediation.title}
                placement={'bottom'}
                closable={true}
                open={remediationDetailsModalOpen}
                onClose={handleRemediationDetailsModalClose}
                getContainer={false}
                headerStyle={{ display: 'none' }}
            >
                <span className='closeIcon-drawer' onClick={handleRemediationDetailsModalClose}><FontAwesomeIcon icon={faClose} className='' /></span>
                <Tabs defaultActiveKey={defaultSeletedRemediationTab} items={remediationItems} />
            </Drawer>}

        </div>
    );
}