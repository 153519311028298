import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table } from "antd";
import { useState } from "react";
import NoDataFound from "../../shared/sharedComponents/noDataFound";

const UrlDiscoveries = ({
    discoveries, 
    configId,
    startScan
}) => {
    const [urlDiscoveryPage,setUrlDiscoveryPage] = useState(1) 

    const data = discoveries.map((url, index) => ({
        key: index,
        url: url,
    }))
    const columns = [
        {
            title: <strong style={{ textAlign: 'left' }}>Sno</strong>,
            dataIndex: "serialNumber",
            width: 60,
            render: (val, row, index) => ({
                props: {
                    style: { textAlign: 'center' }
                },
                children: (urlDiscoveryPage - 1) * 10 + index + 1
            }),
          },
        {
            title: 'Discovered Assets',
            dataIndex: 'url',
            key: 'url',
            render: text => text
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Popconfirm
                    title="Are you sure you want to scan this URL?"
                    onConfirm={() => startScan(record.url,configId)}
                    okText="Yes"
                    cancelText="No"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                >
                    <Button type="primary" size="small">
                        Scan
                    </Button>
                </Popconfirm>
            ),
        },
    ];

    return <Table
            columns={columns}
            className='custom-table mb-0'
            dataSource={data}
            pagination={{
                showTotal: (total, range) => {
                    return (
                        <span className="text-white">
                            {range[0]}-{range[1]} of {total && total.format()}{" "}
                            resources
                        </span>
                    );
                },
                defaultPageSize: 10,
                showSizeChanger: true,
                position: ["bottom", "left"],
                className: "searchPagePagination px-0",
                pageSizeOptions: [10, 20, 25],
                onChange: (current) =>  {
                    setUrlDiscoveryPage(current);
                }
            }}
            locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
        />
};

export default UrlDiscoveries