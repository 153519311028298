import ComponentExpandedTable from "./componentExpandedTable";
import { getFontColor, getStrokeColor } from "../../../../shared/helper/genHelper";
import { Button, Input, Space, Table, Tag, Tooltip } from "antd";
import Highlighter from "react-highlight-words";
import { getArtifactImage } from "../../../../shared/helper/getArtifactImageHelper";
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import NoDataFound from "../../../../shared/sharedComponents/noDataFound";

const expandedRowRender = (props) => {
  return <ComponentExpandedTable {...props} />;
};

export default function ComponentVulTable({ dataSet }) {
  const [data, setData] = useState([]);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [filterDropdownVisible, setFilterDropdownVisible] = useState({});
  const [totalCount, setTotalCount] = useState({
    CRITICAL: 0,
    HIGH: 0,
    MEDIUM: 0,
    LOW: 0,
    NA: 0,
  });

  const searchInput = useRef(null);

  useEffect(() => {
    if (!_.isEmpty(dataSet)) {
      let newData = [];
      let newCve = [],
        count = {};

      _.forIn(dataSet, (value, key) => {
        newCve = value.cves;

        if (!_.isEmpty(newCve)) {
          count = getCount({ cveDetails: [...newCve] });
          newData.push({
            key: `${key}`,
            ecosystem: getPackageDetails(key, "getEcosystem"),
            packageName: getPackageDetails(
              key,
              "packageName",
              dataSet.scanType
            ),
            vulnerableVersion: getPackageDetails(key, "getVersion"),
            recomendedFix:
              !_.isEmpty(value) &&
              !_.isEmpty(value.stableRecommendedVersionList)
                ? value.stableRecommendedVersionList
                : value.recommendedVersionList,
            cveDetails: [...newCve],
            introducedCveDetails: value.introducedCveDetails,
            critical: count.CRITICAL,
            medium: count.MEDIUM,
            high: count.HIGH,
            low: count.LOW,
            na: count.NA,
            total:
              count.CRITICAL + count.MEDIUM + count.HIGH + count.LOW + count.NA,
            colSpan: 0,
            traceabilityDetails: value.traceabilityDetails,
          });
        }
      });

      // let gfg = _.sortBy(newData, [function (o) { return o.critical; }]);
      let sortedData = [];

      if (!_.isEmpty(newData)) {
        sortedData = _.orderBy(
          newData,
          ["critical", "high", "medium", "low", "na"],
          ["desc", "desc", "desc", "desc", "desc"]
        );
      }

      // console.log("I am the newData here:::", sortedData);

      setData(sortedData);
    }
  }, [dataSet]);

  const getCount = (rowData) => {
    let count = { CRITICAL: 0, HIGH: 0, MEDIUM: 0, LOW: 0, NA: 0 };
    if (!_.isEmpty(rowData) && !_.isEmpty(rowData.cveDetails)) {
      rowData.cveDetails.forEach((elem) => {
        if (!_.isEmpty(elem)) {
          if (!_.isEmpty(elem.cvssV3BaseSeverity)) {
            count[elem.cvssV3BaseSeverity] = count[elem.cvssV3BaseSeverity] + 1;
          } else {
            if (!_.isEmpty(elem.cvssV2BaseSeverity)) {
              count[elem.cvssV2BaseSeverity] =
                count[elem.cvssV2BaseSeverity] + 1;
            } else {
              count["NA"] = count["NA"] + 1;
            }
          }
        }
      });
    }
    return count;
  };

  const getModuleName = (module) => {
    if (module) {
      const splitText = module.split("/");
      // console.log(splitText);
      return splitText
        ? `${splitText[splitText.length - 2]}/${splitText.pop()}`
        : "";
    } else {
      return "";
    }
  };

  const getPackageDetails = (val, type, artifact) => {
    if (val) {
      let splitedPkg = val.split(":");
      switch (type) {
        case "artifact":
          let sText = splitedPkg[1].split("/");
          return sText[2];
        case "group":
          let splitedText = splitedPkg[1].split("/");
          return splitedText[1];
        case "getEcosystem":
          let ecosystem = splitedPkg[1].split("/");
          return ecosystem[0];
        case "packageName":
          return splitedPkg[1];
        case "getVersion":
          let version = splitedPkg[1].split("@");
          return version[1];
        default:
          return splitedPkg[2];
      }
    } else {
      return "";
    }
  };

  const getColumnSearchProps = (dataIndex, columnName) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${columnName}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            className="d-flex align-items-center justify-content-center"
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                clearFilters();
                setSearchText("");
              }
              //  && handleReset(clearFilters);
              confirm({
                closeDropdown: false,
              });
              // console.log("I am he dataIndex", dataIndex);
              // setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    filterDropdownVisible: filterDropdownVisible[dataIndex],
    onFilterDropdownVisibleChange: (visible) =>
      handleSearchClick(dataIndex, visible),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, row) =>
      searchedColumn === dataIndex && !_.isEmpty(searchText)
        ? highlightText(searchedColumn, text, row)
        : getColumnText(dataIndex, text, row),
  });

  const highlightText = (searchedColumn, text, row) => {
    if (searchedColumn === "packageName") {
      let [artifact, componentRaw] = text.split(/\/(.*)/s);
      let component = "";
      let imageTitle = "";
      if (artifact === "maven") {
        component = _.replace(componentRaw, "/", ":");
        imageTitle = "java";
      } else {
        component = componentRaw;
        imageTitle = artifact;
      }
      return (
        <span>
          <img
            src={getArtifactImage(artifact)}
            style={{ width: 25 }}
            className="me-1"
            alt={artifact}
            title={imageTitle}
          />{" "}
          <span title={component}>{getHighlighted(component)}</span>
        </span>
      );
    } else {
      return getHighlighted(text);
    }
  };

  const getHighlighted = (text) => {
    return (
      <Highlighter
        highlightStyle={{
          backgroundColor: "#ffc069",
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    );
  };

  const getColumnText = (searchedColumn, text, row) => {
    if (searchedColumn === "packageName") {
      let [artifact, componentRaw] = text.split(/\/(.*)/s);
      let component = "";
      let imageTitle = "";
      if (artifact === "maven") {
        component = _.replace(componentRaw, "/", ":");
        imageTitle = "java";
      } else {
        component = componentRaw;
        imageTitle = artifact;
      }
      return (
        <span>
          <img
            src={getArtifactImage(artifact)}
            style={{ width: 25 }}
            className="me-1"
            title={imageTitle}
            alt={artifact}
          />{" "}
          <span title={component}>{component}</span>
        </span>
      );
    } else {
      return text;
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleSearchClick = (selectedColumn, visible) => {
    let allVisible = _.mapValues(filterDropdownVisible, function (o) {
      return false;
    });
    setFilterDropdownVisible({ ...allVisible, [selectedColumn]: visible });
  };

  const expandIcon = ({ expanded, onExpand, record }) => {
    if (record.colSpan) {
      return false;
    }
    return expanded ? (
      <button
        type="button"
        onClick={(e) => onExpand(record, e)}
        className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
        aria-label="Collapse row"
        aria-expanded="true"
      ></button>
    ) : (
      <button
        onClick={(e) => onExpand(record, e)}
        type="button"
        className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
        aria-label="Expand row"
        aria-expanded="false"
      ></button>
    );
  };

  const componentColumns = [
    {
      title: "Vulnerable component",
      dataIndex: "packageName",
      key: "packageName",
      sorter: (a, b) => a.packageName.localeCompare(b.packageName),
      ellipsis: true,
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps("packageName", "vulnerable component"),
    },
    {
      title: "Total",
      dataIndex: "total",
      width: 75,
      sorter: (a, b) => a.total - b.total,
      sortDirections: ["descend"],
      align: (val, row) => {
        return row.colSpan ? "left" : "center";
      },
      render: (val, row) => {
        if (row.colSpan === 9) {
          return "This component has no known vulnerabilities";
        }

        return val ? (
          <Tag
            style={{
              color: "#adb5bd",
              borderRadius: 50,
              width: 40,
              border: "none",
              boxShadow: "none",
              background: "transparent",
              fontSize: 14,
            }}
            color={getStrokeColor("TOTAL")}
            className={"me-1 text-center"}
          >
            {val}
          </Tag>
        ) : (
          ""
        );
      },
    },
    {
      title: "Critical",
      dataIndex: "critical",
      width: 75,
      sorter: (a, b) => a.critical - b.critical,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) =>
        val ? (
          <Tag
            style={{
              color: getFontColor("CRITICAL"),
              borderRadius: 50,
              width: 40,
            }}
            color={getStrokeColor("CRITICAL")}
            className={"me-1 text-center"}
          >
            {val}
          </Tag>
        ) : (
          ""
        ),
    },
    {
      title: "High",
      dataIndex: "high",
      width: 65,
      sorter: (a, b) => a.high - b.high,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) =>
        val ? (
          <Tag
            style={{ color: getFontColor("HIGH"), borderRadius: 50, width: 40 }}
            color={getStrokeColor("HIGH")}
            className={"me-1 text-center"}
          >
            {val}
          </Tag>
        ) : (
          ""
        ),
    },
    {
      title: "Medium",
      dataIndex: "medium",
      width: 85,
      sorter: (a, b) => a.medium - b.medium,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) =>
        val ? (
          <Tag
            style={{
              color: getFontColor("MEDIUM"),
              borderRadius: 50,
              width: 40,
            }}
            color={getStrokeColor("MEDIUM")}
            className={"me-1 text-center"}
          >
            {val}
          </Tag>
        ) : (
          ""
        ),
    },
    {
      title: "Low",
      dataIndex: "low",
      width: 65,
      sorter: (a, b) => a.low - b.low,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) =>
        val ? (
          <Tag
            style={{ color: getFontColor("LOW"), borderRadius: 50, width: 40 }}
            color={getStrokeColor("LOW")}
            className={"me-1 text-center"}
          >
            {val}
          </Tag>
        ) : (
          ""
        ),
    },
    {
      title: "NA",
      dataIndex: "na",
      width: 65,
      sorter: (a, b) => a.na - b.na,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) =>
        val ? (
          <Tag
            style={{ color: getFontColor("NA"), borderRadius: 50, width: 40 }}
            color={getStrokeColor("NA")}
            className={"me-1 text-center"}
          >
            {val}
          </Tag>
        ) : (
          ""
        ),
    },
  ];

  return (
    <Table
      size="small"
      dataSource={data}
      columns={componentColumns}
      pagination={{
        showTotal: (total, range) => {
          return (
            <span className="text-white">
              {range[0]}-{range[1]} of {total && total.format()}{" "}
              packages
            </span>
          );
        },
        defaultPageSize: 20,
        showSizeChanger: true,
        position: ["bottom", "left"],
        className: "searchPagePagination px-0",
        pageSizeOptions: [20,30,40,50],
      }}
      className="table table-striped custom-table mb-0"
      expandable={{
        expandedRowRender,
        expandIcon: expandIcon,
      }}
      locale={{ emptyText: <NoDataFound title={"No Data"} /> }}
    />
  );
}
