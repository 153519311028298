import { Tree } from 'antd';
import { faCaretDown, faCaretRight, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { getAssetName } from '../../../shared/helper/genHelper';

const { DirectoryTree } = Tree;

export default function FileExplorerSidebar({ reportDetails, handleFileClick, selectedData }) {
    const [fileExplorerData, setFileExplorerData] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);



    const sidebarRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(268);

    const startResizing = React.useCallback((mouseDownEvent) => {
        setIsResizing(true);
    }, []);

    const stopResizing = React.useCallback(() => {
        setIsResizing(false);
    }, []);

    const resize = React.useCallback(
        (mouseMoveEvent) => {
            if (isResizing) {
                setSidebarWidth(
                    mouseMoveEvent.clientX -
                    sidebarRef.current.getBoundingClientRect().left
                );
            }
        },
        [isResizing]
    );

    React.useEffect(() => {
        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResizing);
        return () => {
            window.removeEventListener("mousemove", resize);
            window.removeEventListener("mouseup", stopResizing);
        };
    }, [resize, stopResizing]);








    useEffect(() => {
        if (!_.isEmpty(reportDetails) && !_.isEmpty(reportDetails.scanReport.vulnerabilities_by_file)) {
            const filesAndFolders = _.map(reportDetails.scanReport.vulnerabilities_by_file, file => {
                return _.pick(file, ['fileName', 'fileId']);
            });

            let treeData = buildTree(filesAndFolders);
            //console.log("treeData : ",treeData);
            setFileExplorerData(treeData);

            if (selectedData && selectedData.fileId) {
                const selectedNode = findNodeByFileId(treeData, selectedData.fileId);
                // console.log("Tree data",treeData);
                // console.log("I am selected node", selectedNode)
                if (selectedNode) {
                    const parentKeys = getParentKeys(treeData, selectedNode.key);
                    // console.log("I am parent keys", parentKeys)
                    setSelectedKeys([selectedNode.key]);
                    setExpandedKeys(parentKeys);
                    handleFileClick(selectedData.fileId);
                }
            }
            else {
                // setSelectedKeys(['0-0']);
                setExpandedKeys(['0-0']);
            }
        }
    }, [reportDetails, selectedData]);

    const onSelect = (keys, info) => {
        if (!_.isEmpty(info)) {
            if (!_.isEmpty(info) && !_.isEmpty(info.node) && !_.isEmpty(info.node.fileId)) {
                handleFileClick(info.node.fileId);
            }

            setSelectedKeys([info.node.key]);
            setExpandedKeys(getParentKeys(fileExplorerData, info.node.key));
        }
    };

    const onExpand = (keys) => {
        setExpandedKeys(keys);
    };

    const buildTree = (paths) => {
        const root = { title: getAssetName(reportDetails.assetUrl), key: '0-0', children: [] };
        
        const addNode = (node, pathParts, path, fileId) => {
            const part = pathParts.shift();
            const isLeaf = pathParts.length === 0;
            let childNode = _.find(node.children, { title: part });
            
            if (!childNode) {
             //   console.log("childNode : ", childNode);
                childNode = {
                    title: part,
                    key: `${node.key}-${node.children.length}`,
                    isLeaf,
                    children: [],
                    fileId: (isLeaf) ? fileId : ''
                };
                node.children.push(childNode);
            }

            if (!isLeaf) {
            //    console.log("isLeaf : ", childNode);
                addNode(childNode, pathParts, path, fileId);
            } else {
                childNode.isLeaf = true;
            }
        };

        _.each(paths, (path) => {
            const pathParts = path.fileName.split('/');
        //    console.log("pathParts : ", pathParts, path)
            addNode(root, pathParts, path.fileName, path.fileId);
        });

        const sortTree = (node) => {
            node.children = _.sortBy(node.children, ['isLeaf', 'title']);
            _.each(node.children, sortTree);
        };

        sortTree(root);
       // console.log("root : ", root);
        return [root];
    };

    const findNodeByFileId = (treeData, fileId) => {
        let result = null;
        const searchTree = (nodes) => {
            for (let node of nodes) {
                if (node.fileId === fileId) {
                    result = node;
                    return;
                }
                if (node.children) {
                    searchTree(node.children);
                }
            }
        };
        searchTree(treeData);
        return result;
    };

    const getParentKeys = (treeData, key) => {
        let parentKeys = [];
        const searchTree = (nodes, parentKey = '') => {
            for (let node of nodes) {
                if (node.key === key) {
                    if (parentKey) {
                        parentKeys.push(parentKey);
                    }
                    return true;
                }
                if (node.children) {
                    if (searchTree(node.children, node.key)) {
                        parentKeys.push(node.key);
                        return true;
                    }
                }
            }
            return false;
        };
        searchTree(treeData);
        return parentKeys;
    };

    if (!_.isEmpty(fileExplorerData)) {
        return (
            // <div className="list-group list-group-flush my-2" style={{ overflow: 'auto', height: 'calc(100vh - 140px)' }}>


            <div
                ref={sidebarRef}
                className="app-sidebar"
                style={{ width: sidebarWidth, height: 'calc(100vh - 105px)' }}
                onMouseDown={(e) => e.preventDefault()}
            >
                {/* <div className='d-flex align-items-start justify-content-start flex-column w-100'> */}
                {/* <div className="sidebar-heading border-bottom px-1 py-2"> */}
                {/* <span className='toggle-sidebar p-2 d-flex align-items-center justify-content-center me-2' style={{ borderRadius: 5 }}><img src={hideSidebar} style={{ width: 15 }} /></span> */}
                {/* <h4 className='mb-0 w-100'>Files</h4>
                        </div> */}
                <div className="app-sidebar-content pe-3" style={{ overflow: 'auto' }} >
                    <h4 className='mb-2 w-100 pb-2' style={{ borderBottom: '1px solid #495057' }}>Files</h4>
                    <DirectoryTree
                        showLine={false}
                        multiple
                        autoExpandParent={true}
                        onSelect={onSelect}
                        onExpand={onExpand}
                        treeData={fileExplorerData}
                        switcherIcon={({ expanded }) =>
                            expanded ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretRight} />
                        }
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        className='file-explorer-tree'
                    />
                </div>
                <div className="app-sidebar-resizer d-flex align-items-center justify-content-center text-secondary" onMouseDown={startResizing} ><FontAwesomeIcon icon={faEllipsisV} style={{fontSize: 12}} /></div>
                {/* </div> */}
            </div>


            // </div>
        );
    } else {
        return (<div>No Data to load</div>);
    }
}
