import React from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';

const SecurityScanRadial = () => {
    const colors = [
        {
            fill: 'rgba(255, 72, 66, 0.8)',     // Red for High
            border: 'rgba(255, 72, 66, 1)'
        },
        {
            fill: 'rgba(255, 145, 73, 0.8)',    // Orange for Medium
            border: 'rgba(255, 145, 73, 1)'
        },
        {
            fill: 'rgba(45, 180, 107, 0.8)',    // Green for Low
            border: 'rgba(45, 180, 107, 1)'
        }
    ];

    const theme = {
        darkMode: true,
        backgroundColor: 'transparent',
        textStyle: {
            color: '#B9B8CE',
        }
    };
    echarts.registerTheme('security_theme', theme);

    // Aggregate data across all assets
    const totalHigh = 57;
    const totalMedium = 402; // 294 + 103 + 5
    const totalLow = 1229;   // 630 + 592 + 7
    const grandTotal = totalHigh + totalMedium + totalLow;

    const severityData = [
        {
            name: "High Severity",
            value: totalHigh,
            description: "High Risk Vulnerabilities"
        },
        {
            name: "Medium Severity",
            value: totalMedium,
            description: "Medium Risk Vulnerabilities"
        },
        {
            name: "Low Severity",
            value: totalLow,
            description: "Low Risk Vulnerabilities"
        }
    ];

    const option = {
        title: {
            text: grandTotal.toLocaleString(),
            subtext: 'Total Vulnerabilities',
            left: '50%',
            top: '42%',
            textAlign: 'center',
            textStyle: {
                color: '#EEF1FA',
                fontSize: 24,
                fontWeight: 'bold',
                align: 'center',
                verticalAlign: 'middle'
            },
            subtextStyle: {
                color: '#B9B8CE',
                fontSize: 14,
                align: 'center',
                padding: [8, 0, 0, 0],
                verticalAlign: 'middle'
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                const severity = severityData.find(s => s.name === params.name);
                return `${severity.name}<br/>${severity.description}<br/>Count: ${params.value}`;
            },
            backgroundColor: 'rgba(0,0,0,0.85)',
            borderColor: '#484753',
            textStyle: {
                color: '#B9B8CE'
            }
        },
        legend: {
            orient: 'vertical',
            right: '5%',
            top: '5%',
            align: 'right',
            itemGap: 15,
            textStyle: {
                color: '#B9B8CE',
                fontSize: 13,
                padding: [0, 0, 0, 8]
            },
            selectedMode: true,
            data: severityData.map(item => ({
                name: item.name,
                value: item.value
            }))
        },
        polar: {
            radius: ['40%', '95%'],
            center: ['50%', '50%']
        },
        radiusAxis: {
            max: Math.max(...severityData.map(item => item.value)) * 1.1,
            axisLine: {
                lineStyle: {
                    color: '#B9B8CE'
                }
            },
            splitLine: {
                lineStyle: {
                    color: '#484753'
                }
            },
            axisLabel: {
                show: true,
                margin: 10,
                color: '#B9B8CE'
            }
        },
        angleAxis: {
            type: 'category',
            data: severityData.map(item => item.name),
            startAngle: 75,
            axisLine: {
                lineStyle: {
                    color: '#B9B8CE'
                }
            },
            axisLabel: {
                color: '#B9B8CE',
                fontSize: 10,
                interval: 0,
                rotate: 30,
                margin: 20
            }
        },
        series: {
            type: 'bar',
            data: severityData.map((item, index) => ({
                value: item.value,
                itemStyle: {
                    color: colors[index].fill,
                    borderColor: colors[index].border,
                    borderWidth: 2
                }
            })),
            coordinateSystem: 'polar',
            label: {
                show: true,
                position: 'middle',
                formatter: '{c}',
                color: '#EEF1FA',
                fontSize: 12,
                distance: 10
            },
            itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
                borderColor: '#2b313e'
            },
            barWidth: '50%',
            emphasis: {
                scale: true,
                scaleSize: 5,
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    };

    return (
        <div className="w-full h-full rounded-lg" style={{ width: '100%', height: 292 }}>
            <ReactEcharts
                option={option}
                theme="security_theme"
                style={{ height: '100%', width: '100%', cursor: 'pointer' }}
                opts={{ renderer: 'svg' }}
            />
        </div>
    );
};

export default SecurityScanRadial;