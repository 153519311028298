import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import { Button, Alert } from "antd";
import axios from "axios";
import { notification } from 'antd';
import { GlobalConst } from "../../shared/appConfig/globalConst";
import Loader from "../../shared/sharedComponents/loader";
import _ from 'lodash';

export default () => {

    const { id } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Suppress ResizeObserver error
        if (!_.isEmpty(id)) {
            getGcpConfiguration(id);
        }

        const resizeObserverError = console.error;
        console.error = (...args) => {
            if (
                args.length > 1 &&
                typeof args[0] === 'string' &&
                args[0].startsWith('ResizeObserver loop')
            ) {
                // Do nothing, suppressing the ResizeObserver error
            } else {
                resizeObserverError.apply(console, args);
            }
        };

        return () => {
            console.error = resizeObserverError;
        };
    }, [id]);


    const formik = useFormik({
        initialValues: {
            projectId: '',
            serviceAccountFile: null,
        },
        validationSchema: Yup.object({
            projectId: Yup.string()
                .required('Project ID is required'),
            serviceAccountFile: Yup.mixed()
                .required('Service account file is required')
                .test('fileType', 'Invalid file type. Must be a JSON file.', (value) => {
                    if (!value) return false;
                    return value && value.type === "application/json";
                }),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const postBody = {
                "integration": "saas",
                "gcp": {
                    "projectId": values.projectId,
                }
            };

            let data = new FormData();
            data.append("config", JSON.stringify(postBody));
            data.append("service-account-file", values.serviceAccountFile);
            if (id) {
                data.append("integrationId", id);
            }

            let config = {
                method: "post",
                url: `${GlobalConst.API_URL}/auth/cloud-api/integration/gcp/save`,
                headers: { "content-type": "multipart/form-data" },
                data: data,
            };

            axios
                .request(config)
                .then(response => {
                    if (response.data && response.data.connected === true) {
                        let message = { title: '', desc: '' };
                        if (id) {
                            message = { title: 'Integration Updated', desc: "GCP integration updated successfully!" };
                        }
                        else {
                            message = { title: 'Integration Successfull', desc: "GCP integration successfully configured!" };
                        }
                        notification.success({
                            message: message.title,
                            description: message.desc,
                            duration: 5,
                        });
                        history.push(`/integrations-list/gcp/${response.data.integrationId}`);
                    } else {
                        notification.error({
                            message: 'Undefined Error',
                            description: "Integration was not successful. Please check your configuration.",
                            duration: 0,
                        });
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.data && error.response.data.message) {
                            notification.error({
                                message: 'Undefined Error',
                                description: error.response.data.message,
                                duration: 0,
                            });
                        }
                        else if (error.response.data && error.response.data.error) {
                            notification.error({
                                message: 'Undefined Error',
                                description: error.response.data.error,
                                duration: 0,
                            });
                        }
                        else {
                            notification.error({
                                message: 'Undefined Error',
                                description: "An error occurred while processing your request. Please try again.",
                                duration: 0,
                            });
                        }
                    } else if (error.request) {
                        notification.error({
                            message: 'Undefined Error',
                            description: "No response received from the server. Please try again later.",
                            duration: 0,
                        });
                    } else {
                        notification.error({
                            message: 'Undefined Error',
                            description: "An error occurred while sending the request. Please try again.",
                            duration: 0,
                        });
                    }
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });

    const getGcpConfiguration = (gcpIntegrationId) => {
        setLoading(true);
        const postData = {
            "integrationId": gcpIntegrationId,
            "targetSystem": "gcp"
        };
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/integration/cloud/fetch`, postData)
            .then(op => {
                if (op && op.data && !_.isEmpty(op.data.config.gcp)) {
                    formik.setValues({
                        projectId: op.data.config.gcp.projectId || '',
                        serviceAccountFile: null // We can't populate the file input, but we can clear it
                    });
                }
            })
            .catch(e => {
                notification.error({
                    message: 'Undefined Error',
                    description: "Failed to load GCP configuration. Please try again.",
                    duration: 0,
                });
            })
            .finally(op => {
                setLoading(false);
            });
    }


    return (
        <div className="col-sm-5">
            <form onSubmit={formik.handleSubmit}>
                <div className="flex-fill me-3">
                    <div className="mb-3">
                        <label htmlFor="projectId" className="form-label mb-1 fw-semibold">Project Id</label>
                        <input
                            type="text"
                            className={`form-control ${formik.touched.projectId && formik.errors.projectId ? 'is-invalid' : ''}`}
                            id="projectId"
                            {...formik.getFieldProps('projectId')}
                            placeholder="Enter Project Id"
                        />
                        {formik.touched.projectId && formik.errors.projectId ? (
                            <div className="invalid-feedback">{formik.errors.projectId}</div>
                        ) : null}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="serviceAccountFile" className="form-label">Service account file</label>
                        <input
                            className={`form-control ${formik.touched.serviceAccountFile && formik.errors.serviceAccountFile ? 'is-invalid' : ''}`}
                            type="file"
                            id="serviceAccountFile"
                            onChange={(event) => {
                                formik.setFieldValue("serviceAccountFile", event.currentTarget.files[0]);
                            }}
                        />
                        {formik.touched.serviceAccountFile && formik.errors.serviceAccountFile ? (
                            <div className="invalid-feedback">{formik.errors.serviceAccountFile}</div>
                        ) : null}
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-start">
                    <Button type="primary" htmlType="submit" loading={formik.isSubmitting} disabled={formik.isSubmitting}>
                        <FontAwesomeIcon icon={faFloppyDisk} className="me-1" />
                        {formik.isSubmitting ? 'Validating & Saving...' : 'Save'}
                    </Button>
                </div>
            </form>
            {formik.isSubmitting && <Loader />}
            {loading && <Loader />}
        </div>
    );
};