import { createSlice } from "@reduxjs/toolkit";

export const genReducer = createSlice({
    name: 'genReducer',
    initialState: {
        background: '#23395d',
        bodyColor: '#ffffff',
        headerType: 'normal'
    },
    reducers: {
        inverseTheme: (state) => {
            state.background = '#23395d';
            state.bodyColor = '#ffffff';
            state.headerType = 'navbar-inverse';
        },
        normalTheme: (state) => {
            state.background = '#ffffff';
            state.bodyColor = '#212121';
            state.headerType = 'normal';
        }
    }
})

export const { inverseTheme, normalTheme } = genReducer.actions;

export default genReducer.reducer;