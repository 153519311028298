import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { faArrowLeft, faTrowelBricks, faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import gcpIcon from '../../assets/img/scopy-tech-icons/gcp.svg';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { Tag } from "antd";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import OnPremise from "./onPremise";
import SAAS from "./saas";

export default function IntegrateGCPPage() {

    const { id } = useParams();

    return (
        <section className="mx-3">
            <section>
                <div className="mb-4">
                    <div className="">
                        <div className="mb-3">
                            <Link to="/integrations-list"><FontAwesomeIcon className="me-2" icon={faArrowLeft} />Back to integrations</Link>
                        </div>
                        <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-2 text-white d-flex align-items-center justify-content-start">
                            <FontAwesomeIcon icon={faTrowelBricks} className="me-2" />
                            Integrations
                        </h2>
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/integrations-list">Integrations</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Google Cloud Platform</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className={'mb-5'}>
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h3 className="title pb-2 mb-4 text-white fw-normal d-flex align-items-center justify-content-start" style={{ borderBottom: '1px solid #cfdaed' }}>
                                    <img src={gcpIcon} alt="Google Cloud Platform" style={{ width: 35 }} className="me-2" /> Google Cloud Platform
                                    <>
                                        {!_.isEmpty(id) ?
                                            <Tag className="ms-3" color="rgb(25,135,84)"><FontAwesomeIcon className="me-2" icon={faCheck} />Connected</Tag>
                                            :
                                            <Tag className="ms-3"><FontAwesomeIcon className="me-2" icon={faInfoCircle} />Not Connected</Tag>
                                        }
                                    </>
                                </h3>
                            </div>
                            <p className="mb-3">Provide your account credentials below to establish a connection between Sec1 and your GCP account.</p>
                            <div style={{ width: '100%' }}>
                                <Tabs
                                    defaultActiveKey="saas"
                                    id="cloud-tabs"
                                    className="mb-3"
                                >
                                    <Tab eventKey="saas" title="SAAS">
                                        <SAAS />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
}