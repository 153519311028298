import React, { useEffect, useState } from 'react';
import TidyTreeChart from './tidyTreeChart';
import _ from 'lodash';

export default function TidyTreeView({ dataSet }) {
    const cutOutIndex = 5;
    const [data, setData] = useState([]);

    useEffect(() => {
        // console.log("I am the dataset", dataSet);

        if (!_.isEmpty(dataSet)) {

            let newData = {
                name: (!_.isEmpty(dataSet.assetUrl)) ? dataSet.assetUrl.split('/').pop() : '',
                children: [],
                type: 'root'
            };

            let children = [];
            let hasRootCritical = false;
            if (!_.isEmpty(dataSet) && !_.isEmpty(dataSet.scanResult)) {
                
                // Loop through the dataset scan result
                dataSet.scanResult.forEach((root) => {
                    if (!_.isEmpty(root) && !_.isEmpty(root.scanResult)) {
                        let rootChildren = [], hasCve = false, hadModuleCritical=false;

                        // Loop through the packages
                        _.forIn(root.scanResult, (val, key) => {
                            let cveChildren = [], hasCritical=false;
                            if (!_.isEmpty(val) && !_.isEmpty(val.cves)) {
                                hasCve = true;
                                // if (count < cutOutIndex) {

                                let cveCount = 0;
                                val.cves.forEach((cve, index) => {
                                    if (index < cutOutIndex) {
                                        // console.log("CVE ::", cve);
                                        cveChildren.push({
                                            name: cve.cveId,
                                            children: [],
                                            type: 'cve',
                                            severity: (!_.isEmpty(cve) && cve.cvssV3BaseSeverity) ? cve.cvssV3BaseSeverity : cve.cvssV2BaseSeverity,
                                            hasCritical: doesHaveCritical(cve)
                                        })
                                    }
                                    cveCount = cveCount + 1;
                                    if(doesHaveCritical(cve)){
                                        hasCritical=doesHaveCritical(cve);
                                        hasRootCritical=doesHaveCritical(cve);
                                        hadModuleCritical = doesHaveCritical(cve);
                                    }
                                });

                                if (cveCount > cutOutIndex) {
                                    cveChildren.push({
                                        name: `...and ${cveCount - cutOutIndex} more`,
                                        type: 'cve'
                                    });
                                }

                                rootChildren.push({
                                    name: getPackageDetails(key, 'packageName'),
                                    children: [...cveChildren],
                                    type: 'component',
                                    hasCritical: hasCritical
                                });
                            }
                        });

                        if (hasCve) {
                            children.push({
                                name: getModuleName(root.applicationLocation),
                                children: [...rootChildren],
                                type: 'module',
                                hasCritical: hadModuleCritical
                            });
                        }
                    }
                });
            }

            if (!_.isEmpty(dataSet) && !_.isEmpty(dataSet.scanResult) && dataSet.scanResult.length <= 1) {
                // console.log("In if condition");
                newData = children[0];
            }
            else {
                // console.log("In Else condition");
                newData.children = [...children];
            }
            newData.hasCritical = hasRootCritical;
            // console.log("New Data::", newData);
            setData(newData);
        }

    }, [dataSet]);

    const doesHaveCritical = (cve) => {
        let type = (!_.isEmpty(cve) && cve.cvssV3BaseSeverity) ? cve.cvssV3BaseSeverity : cve.cvssV2BaseSeverity
        if(type && type ==='CRITICAL'){
            return true;
        }
        return false;

    }

    const getModuleName = (name) => {
        if (!_.isEmpty(name)) {
            return name.split('/').pop();
        }
    }

    const getPackageDetails = (val, type, artifact) => {
        if (val) {
            let splitedPkg = val.split(":");
            switch (type) {
                case "artifact":
                    let sText = splitedPkg[1].split('/');
                    return sText[2];
                case "group":
                    let splitedText = splitedPkg[1].split('/');
                    return splitedText[1];
                case 'getEcosystem':
                    let ecosystem = splitedPkg[1].split('/');
                    return ecosystem[0];
                case 'packageName':
                    if (artifact === "maven") {
                        let strSplit = splitedPkg[1].split('/');
                        return `${strSplit[0]}/${strSplit[1]}:${strSplit[2]}`;
                    }
                    else {
                        return splitedPkg[1].split('/').pop();
                    }
                case 'getVersion':
                    let version = splitedPkg[1].split('@')
                    return version[1];
                default:
                    return splitedPkg[2];
            }
        }
        else {
            return ('');
        }
    }

    return (
        <div className='d-flex align-items-center justify-content-center'>
            {!_.isEmpty(data) ? <> <TidyTreeChart
                data={data}
                options={{
                    label: d => d.name,
                    title: (d, n) => `${n.ancestors().reverse().map(d => d.data.name).join(".")}`, // hover text
                    // link: (d, n) => `https://github.com/prefuse/Flare/${n.children ? "tree" : "blob"}/master/flare/src/${n.ancestors().reverse().map(d => d.data.name).join("/")}${n.children ? "" : ".as"}`,
                    width: 1152
                }} /> </> :
                <section className='card custom-card p-2 px-3 text-left mb-2' style={{ background: '#1e464d', border: '1px solid #2c6a74' }}>
                    <p className='mb-0'>No trace available for selected repository</p>
                </section>}
        </div>
    );
}
