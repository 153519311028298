import _ from 'lodash';
import npm from '../../assets/img/techIcons/npm-icon.png';
import pypi from '../../assets/img/techIcons/python-icon.png';
// import gradle from '../../assets/img/techIcons/gradle.png';
import java from '../../assets/img/techIcons/java.png';
import linuxDebian from '../../assets/img/techIcons/debian.png';
import linuxAlpine from '../../assets/img/techIcons/alpine.png';
import goLang from '../../assets/img/techIcons/go-icon.png';
import generic from '../../assets/img/techIcons/generic.svg';
import wordpress from '../../assets/img/techIcons/wordpress-logo.png';
import defaultLang from '../../assets/img/techIcons/generic.png';
import linuxUbuntu from '../../assets/img/techIcons/ubuntu.png';

export const getArtifactImage = (artifact) => {
    // console.log("ARTIFACT::", artifact);
    switch (artifact) {
        case 'maven':
            return java;
        case 'npm':
            return npm;
        case 'pypi':
            return pypi;
        case 'gradle':
            return java;
        case 'linux-alpine':
            return linuxAlpine;
        case 'linux-debian':
            return linuxDebian;
        case 'go':
            return goLang;
        case 'generic':
            return generic;
        case 'wordpress':
            return wordpress;
            case 'linux-ubuntu':
                return linuxUbuntu
        default:
            return defaultLang;
    }
}



// { key: 'Maven', value: 'maven' },
//     { key: 'npm', value: 'npm' },
//     { key: 'PyPi', value: 'pypi' },
//     { key: 'NuGet', value: 'nuget' },
//     { key: 'RubyGems', value: 'rubygems' },
//     { key: 'Conan', value: 'conan' },
//     { key: 'Cargo', value: 'cargo' },
//     { key: 'Go', value: 'go' },
//     { key: 'Composer', value: 'composer' },
//     { key: 'Hex', value: 'hex' },
//     { key: 'Red Hat', value: 'linux-redhat' },
//     { key: 'Ubuntu', value: 'linux-ubuntu' },
//     { key: 'Debian', value: 'linux-debian' },
//     { key: 'Amazon Linux', value: 'linux-amazon' },
//     { key: 'Alpine Linux', value: 'linux-alpine' },
//     { key: 'Microsoft', value: 'microsoft' },
//     { key: 'CentOS', value: 'linux-centos' },
//     { key: 'SUSE', value: 'linux-suse' },
//     { key: 'Alma Linux', value: 'linux-alma' },
//     { key: 'Open SUSE', value: 'linux-opensuse' },
//     { key: 'Arch Linux', value: 'linux-arch-linux' },
//     { key: 'Oracle Linux', value: 'linux-oracle' },
//     { key: 'Rocky Linux', value: 'linux-rocky' },
//     { key: 'Android', value: 'android' },
//     { key: 'rust', value: 'rust' },
//     { key: 'Others', value: 'others' },
//     { key: 'Mozilla', value: 'mozilla'},
//     { key: 'All Ecosystems', value: 'all'},


    

//     { key: "Apple", value: "apple" },
//     { key: "Google", value: "google" },
//     { key: "IBM", value: "ibm" },
//     { key: "Adobe", value: "adobe" },
//     { key: "Oracle", value: "oracle" },
//     { key: "Jenkins", value: "jenkins" },
//     { key: "SAP", value: "sap" },
//     { key: "Qualcomm", value: "qualcomm" },
//     { key: "Huawei", value: "huawei" },
//     { key: "Siemens", value: "siemens" },
//     { key: "Samsung", value: "samsung" },
//     { key: "Dell", value: "dell" },
//     { key: "ASUS", value: "asus" },
//     { key: "VMware", value: "vmware" },
//     { key: "Atlassian", value: "atlassian" },
//     { key: "Foxit", value: "foxit" },
//     { key: "Juniper Networks", value: "juniper-networks" },
//     { key: "Cisco", value: "cisco" },
//     { key: "TIBCO", value: "tibco" },




