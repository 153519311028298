import React, { useEffect, useState, useRef } from "react";

import { SearchOutlined } from '@ant-design/icons';
import { Tag, Space, Alert, Table, Button, Input } from 'antd';
import Highlighter from 'react-highlight-words';
import _ from 'lodash';

import { getStrokeColor, getFontColor } from '../../shared/helper/genHelper';
import { getArtifactImage } from '../../shared/helper/getArtifactImageHelper';
import NoDataFound from "../../shared/sharedComponents/noDataFound";




export default function FixBySeverityTable({ dataSet, searchResults, filter, selectedSeverity, selectedModule, setSelectedSeverity, setSelectedRows, selectedRows }) {

  const [data, setData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterDropdownVisible, setFilterDropdownVisible] = useState({});
  const [selectedTableRowKeys, setSelectedTableRowKeys] = useState([]);
  const searchInput = useRef(null);


  const getFileLocation = (file) => {
    let fileSplit = file.split('/');
    return `${fileSplit[fileSplit.length - 2]}/${fileSplit.pop()}`
  }

  useEffect(() => {

    if (!_.isEmpty(dataSet)) {
      // console.log("I am the selected Severity ::", selectedSeverity);
      // console.log("I am the dataset");

      let newDataSet = [];
      dataSet.forEach((superRow, ind) => {
        // console.log(superRow.applicationLocation , " === " , selectedModule , '<-->' , superRow.applicationLocation === selectedModule);
        if (!_.isEmpty(superRow) && superRow.applicationLocation === selectedModule && !_.isEmpty(superRow.scanResult)) {
          _.forIn(superRow.scanResult, (value, key) => {
            if (!_.isEmpty(value) && !_.isEmpty(value.cves)) {
              value.cves.forEach((el, index) => {
                newDataSet.push({
                  key: `${ind}-${key}-${index}-vultable-${el.cveId}`,
                  vulnerability: (!_.isEmpty(el) && !_.isEmpty(el.cveTypes)) ? el.cveTypes[0].cveType : 'Misc',
                  scanType: superRow.scanType,
                  pkg: key,
                  module: getFileLocation(superRow.scanFileLocation),
                  fileLocation: (superRow.scanFileLocation) ? superRow.scanFileLocation : '',
                  cve: el,
                  cveId: el.cveId,
                  severity: (el.cvssV3BaseSeverity) ? el.cvssV3BaseSeverity : el.cvssV2BaseSeverity,
                  severityScore: (el.cvssV3BaseScore) ? el.cvssV3BaseScore : (el.cvssV2BaseScore) ? el.cvssV2BaseScore : 0,
                  cveCountDetails: value.cveCountDetails,
                  recommendedVersionList: value.recommendedVersionList,
                  stableRecommendedVersionList: value.stableRecommendedVersionList,
                  vulnerableComponent: getPackageDetails(key, 'packageName')
                });
              })
            }
          })
        }
      });

      let groupedItems = { 'CRITICAL': [], 'HIGH': [], 'MEDIUM': [], 'LOW': [] };
      groupedItems = _(newDataSet)
        .orderBy('severityScore', ['desc'])
        .groupBy('severity')
        .value();

      let sortedNewData = [];
      // let selectedRowKeys = [];

      if (!_.isEmpty(groupedItems)) {
        _.forIn(groupedItems, (val, key) => {
          sortedNewData = [...sortedNewData, ...val];
        })
      }



      if (!_.isEmpty(sortedNewData)) {
        sortedNewData = sortedNewData.map((el, index) => ({ ...el, "count": index + 1 }));
      }

      // console.log("New sorted dataL", sortedNewData);

      sortedNewData = _.uniqBy(sortedNewData, obj => obj.cveId);

      // console.log("Sorted New", sortedNewData);

      setData([...sortedNewData]);
    }
  }, [dataSet, filter, selectedSeverity, selectedModule]);

  useEffect(() => {

    if (!_.isEmpty(selectedRows)) {
      // _.forEach(selectedRows, (value, key)=>{
      //   console.log("I am value, key:", value, key);
      //   selectedTableRowKeys()
      // })
      let val = _.filter(selectedRows, ['key']);


      // console.log("I am the sleetec val", val);
    }

  }, [selectedRows]);

  // const getSeverity = (severityArray) => {
  //   if (!_.isEmpty(severityArray)) {
  //     _.forEach(severityArray, function (val) {
  //       console.log("val=>", val);
  //     });
  //   }
  // }

  // useEffect(() => {
  //   console.log("I am the selected Rows here::", JSON.stringify(selectedRows));
  // }, [selectedRows])


  const getPackageDetails = (val, type, artifact) => {
    if (val) {
      let splitedPkg = val.split(":");
      switch (type) {
        case "artifact":
          let sText = splitedPkg[1].split('/');
          return sText[2];
        case "group":
          let splitedText = splitedPkg[1].split('/');
          return splitedText[1];
        case 'getEcosystem':
          let ecosystem = splitedPkg[1].split('/');
          return ecosystem[0];
        case 'packageName':
          return splitedPkg[1];
        case 'getVersion':
          let version = splitedPkg[1].split('@')
          return version[1];
        default:
          return splitedPkg[2];
      }
    }
    else {
      return ('');
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setTimeout(() => searchInput.current?.select(), 100);
  };

  const getColumnSearchProps = (dataIndex, columnName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{ padding: 8 }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${columnName}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            className="d-flex align-items-center justify-content-center"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                clearFilters();
                setSearchText('');
              }
              confirm({
                closeDropdown: false,
              });
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    filterDropdownVisible: filterDropdownVisible[dataIndex],
    onFilterDropdownVisibleChange: (visible) => handleSearchClick(dataIndex, visible),
    render: (text, row) =>
      (searchedColumn === dataIndex && !_.isEmpty(searchText)) ? (
        highlightText(searchedColumn, text, row)
      ) : (
        getColumnText(dataIndex, text, row)
      ),
  });

  const handleSearchClick = (selectedColumn, visible) => {
    let allVisible = _.mapValues(filterDropdownVisible, function (o) { return false; });
    setFilterDropdownVisible({ ...allVisible, [selectedColumn]: visible });
  };

  const getColumnText = (searchedColumn, text, row) => {
    if (searchedColumn === 'cveId') {
      return <a href={`https://galaxyguard.sec1.io/cve/${text}/?s=${text}`} target={'_blank'} rel="noreferrer">{text}</a>
    }
    else if (searchedColumn === 'module') {
      let href = '';
      if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.assetUrl)) {

        if (!_.isEmpty(searchResults.assetType) && searchResults.assetType === 'file') {
          return ((text) && <span title={row.fileLocation}>{row.fileLocation}</span>);
        }
        else {
          let hrefPart = row.fileLocation.split(/\//g);
          if (hrefPart.length >= 3) {
            href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(2).join('/');
          }
          else {
            href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(1).join('/');
          }
          return ((text) && <a href={href} target="_blank"><span title={row.fileLocation}>{text}</span></a>);
        }
      }
    }
    else {
      return text;
    }
  }

  const highlightText = (searchedColumn, text, row) => {
    if (searchedColumn === 'cveId') {
      return <a href={`https://galaxyguard.sec1.io/cve/${text}/?s=${text}`} target={'_blank'} rel="noreferrer">{getHighlighted(text)}</a>;
    }
    else if (searchedColumn === 'module') {
      const highlightText = (text) ? text : '';
      let href = '';
      if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.assetUrl)) {

        if (!_.isEmpty(searchResults.assetType) && searchResults.assetType === 'file') {
          return ((text) && <span title={row.fileLocation}>{row.fileLocation}</span>);
        }
        else {
          let hrefPart = row.fileLocation.split(/\//g);
          if (hrefPart.length >= 3) {
            href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(2).join('/');
          }
          else {
            href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(1).join('/');
          }
          return ((highlightText) ? <a href={href} target="_blank"><span title={row.fileLocation}>{getHighlighted(highlightText)}</span></a> : '');
        }
      }
    }
    else {
      return getHighlighted(text);
    }
  }

  const getHighlighted = (text) => {
    return <Highlighter
      highlightStyle={{
        backgroundColor: '#ffc069',
        padding: 0,
      }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
    />;
  }


  const compareCVE = (a, b) => {
    const regex = /CVE-(\d{4})-(\d+)/;
    const aMatch = a.match(regex);
    const bMatch = b.match(regex);
    const aYear = parseInt(aMatch[1]);
    const aNumber = parseInt(aMatch[2]);
    const bYear = parseInt(bMatch[1]);
    const bNumber = parseInt(bMatch[2]);

    if (aYear !== bYear) {
      return aYear - bYear;
    }

    return aNumber - bNumber;
  }

  const columns = [
    {
      title: 'CVE ID',
      dataIndex: 'cveId',
      ...getColumnSearchProps('cveId', 'cve id'),
      sorter: (a, b) => { return compareCVE(a.cveId, b.cveId) },
      sortDirections: ['ascend', 'descend'],
      // width: '16%',
    },
    // {
    //   title: 'Vulnerable component',
    //   dataIndex: 'vulnerableComponent',
    //   ...getColumnSearchProps('vulnerableComponent', 'vulnerable component'),
    //   ellipsis: true,
    //   render: (text, row) => {
    //     let [artifact, componentRaw] = text.split(/\/(.*)/s);
    //     let component = '';
    //     let imgTitle = '';
    //     if (artifact === 'maven') {
    //       component = _.replace(componentRaw, '/', ':');
    //       imgTitle = 'java';
    //     }
    //     else {
    //       component = componentRaw;
    //       imgTitle = artifact;
    //     }

    //     return <span ><img src={getArtifactImage(artifact)} style={{ width: 25 }} className="me-1" title={imgTitle} /> <span title={component}>{component}</span></span>;
    //   }
    // },
    {
      title: 'Severity',
      render: (index, row) => {
        if (!_.isEmpty(row) && !_.isEmpty(row.cve)) {
          return (
            <Tag style={{ color: getFontColor(row.severity), fontWeight: 600 }} color={getStrokeColor(row.severity)}>
              {row.severity} {row.severityScore}
            </Tag>
          )
        }
        else {
          return '-';
        }
      },
      // width: '15%',
      sorter: (a, b) => a.severityScore - b.severityScore,
      sortDirections: ['ascend', 'descend'],
    },
    // {
    //   title: 'Module name',
    //   dataIndex: 'module',
    //   ...getColumnSearchProps('module', 'module name'),
    //   ellipsis: true,
    //   sorter: (a, b) => a.module.localeCompare(b.module),
    //   sortDirections: ['ascend', 'descend'],
    // },

    // {
    //   title: '',
    //   dataIndex: ''
    // }
  ];

  const rowSelection = {
    type: 'checkbox',
    columnTitle: <></>,
    selectedRowKeys: selectedTableRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log("Selected Row::", selectedRowKeys, selectedRows);
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      // setSelectedTableRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);


      const uniqueSeverities = _.uniq(_.map(selectedRows, 'severity'));
      // console.log("Unique Severities::", uniqueSeverities);


      let severityLevels = _.intersection(selectedSeverity, uniqueSeverities);
      severityLevels = _.union(severityLevels, uniqueSeverities);
      severityLevels = _.map(severityLevels, severity => severity.toLowerCase());
      setSelectedSeverity(severityLevels);

      // console.log('severity levels::', severityLevels);
      // if(!_.isEmpty(selectedRows)){
      //   _.forEach(selectedRows, (value, key)=>{
      //     console.log("Value:", value);
      //     console.log("Key::", key);
      //   })
      // }


    }
  };

  // const handleClickButton = () => {
  //   setSelectedTableRowKeys(["0-pkg:npm/ini@1.3.5-0-vultable-CVE-2020-7788", "0-pkg:npm/y18n@3.2.1-0-vultable-CVE-2020-7774", "0-pkg:npm/y18n@4.0.0-0-vultable-CVE-2020-7774", "0-pkg:npm/minimist@1.2.5-0-vultable-CVE-2021-44906", "0-pkg:npm/loader-utils@1.4.0-0-vultable-CVE-2022-37601", "0-pkg:npm/loader-utils@0.2.17-0-vultable-CVE-2022-37601"]);
  // }

  return (
    <div>
      {
        (!_.isEmpty(data)) ?
          <div style={{ overflow: 'hidden', borderRadius: 8 }}>
            {/* <button onClick={handleClickButton}>Click Button</button> */}
            {/* {console.log("I am the road keys here::", selectedTableRowKeys)} */}
            <div className="row">
              <div className="col-sm-6">
                <Table
                  columns={columns}
                  dataSource={data}
                  sticky={{ offsetHeader: 0 }}
                  size="small"
                  className="table table-striped table-sm mb-0"
                  rowSelection={rowSelection}
                  pagination={{
                    showTotal: (total, range) => {
                      return <span>{range[0]}-{range[1]} of {total && total.format()} vulnerabilities</span>;
                    },
                    defaultPageSize: 5,
                    showSizeChanger: true,
                    position: ["bottom", "left"],
                    // className: "searchPagePagination",
                    pageSizeOptions: [10, 20, 25, 50]
                  }}
                  locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
                />
              </div>
            </div>
          </div>
          :
          <Alert
            message="Nothing to fix"
            description="This repository is not having vulnerabilities."
            type="info"
            showIcon
            className="mt-2"
          />
      }
    </div>
  );
}