import React, { useEffect, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';

const SCASASTCombinedBar = () => {
    const chartRef = useRef(null);

    // Define colors with transparency
    const colors = [
        { fill: 'rgba(218, 74, 71, 0.8)', border: '#da4a47' },    // Critical
        { fill: 'rgba(214, 112, 67, 0.8)', border: '#d67043' },   // High
        { fill: 'rgba(241, 162, 54, 0.8)', border: '#f1a236' },   // Medium
        { fill: 'rgba(224, 230, 183, 0.8)', border: '#e0e6b7' },  // Low
    ];

    const rawData = [
        [45, 35, 42, 48, 39, 44, 38],   // Low
        [83, 78, 82, 75, 81, 77, 85],   // Medium
        [43, 52, 61, 58, 48, 55, 63],   // High
        [23, 32, 45, 63, 52, 38, 45],   // Critical
    ];

    const totalData = rawData[0].map((_, colIndex) =>
        rawData.reduce((sum, row) => sum + row[colIndex], 0)
    );

    const theme = {
        darkMode: true,
        backgroundColor: 'transparent',
        textStyle: {
            color: '#B9B8CE',
        }
    };
    echarts.registerTheme('reference_theme', theme);

    const getOption = () => {
        const grid = {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        };

        return {
            title: {
                text: '',
                left: 'left',
                top: 0,
                textStyle: {
                    color: '#B9B8CE',
                    fontSize: 16,
                    fontWeight: 500
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: function (params) {
                    const total = params.reduce((sum, item) => sum + item.value, 0);
                    let result = `<div style="font-weight:bold;margin-bottom:5px">${params[0].axisValue}</div>`;
                    result += `<div style="margin-bottom:5px">Total: ${total}</div>`;
                    [...params].reverse().forEach(param => {
                        const markerSpan = `<span style="display:inline-block;margin-right:4px;border-radius:50%;width:10px;height:10px;background-color:${colors[3 - params.indexOf(param)].border};"></span>`;
                        result += `${markerSpan}${param.seriesName}: ${param.value}<br/>`;
                    });
                    return result;
                }
            },
            legend: {
                data: ['Critical', 'High', 'Medium', 'Low'].reverse(),
                top: 0,
                left: 'right',
                textStyle: {
                    color: '#B9B8CE'
                },
                selectedMode: true
            },
            grid: grid,
            xAxis: {
                type: 'category',
                data: ['2018', '2019', '2020', '2021', '2022', '2023', '2024'],
                axisLine: {
                    lineStyle: {
                        color: '#484753'
                    }
                },
                axisLabel: {
                    color: '#B9B8CE'
                }
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    lineStyle: {
                        color: '#484753'
                    }
                },
                axisLabel: {
                    color: '#B9B8CE'
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgba(72, 71, 83, 0.3)'
                    }
                }
            },
            series: ['Low', 'Medium', 'High', 'Critical'].map((name, index) => ({
                name,
                type: 'bar',
                stack: 'total',
                barWidth: '60%',
                itemStyle: {
                    color: colors[3 - index].fill,
                    borderColor: colors[3 - index].border,
                    borderWidth: 1,
                    // Only round the top corners for the topmost visible item
                    borderRadius: [
                        index === 3 ? 6 : 0,  // top-left
                        index === 3 ? 6 : 0,  // top-right
                        0,  // bottom-right
                        0   // bottom-left
                    ]
                },
                // emphasis: {
                //     itemStyle: {
                //         color: colors[3 - index].fill,
                //         borderColor: colors[3 - index].border,
                //         borderWidth: 2,
                //         borderRadius: [
                //             index === 3 ? 6 : 0,
                //             index === 3 ? 6 : 0,
                //             0,
                //             0
                //         ]
                //     }
                // },
                emphasis: {
                    focus: 'series'
                },
                label: {
                    show: true,
                    position: 'inside',
                    formatter: (params) => {
                        return params.value;
                    },
                    color: '#fff',
                    fontSize: 11
                },
                data: rawData[index].map((value, dataIndex) => ({
                    value,
                    itemStyle: {
                        color: colors[3 - index].fill,
                        borderColor: colors[3 - index].border,
                        borderWidth: 1,
                        // Apply rounded corners only to the topmost visible bar in each stack
                        borderRadius: [
                            // Check if this is the highest non-zero value in the stack
                            isTopStack(index, dataIndex) ? 6 : 0,
                            isTopStack(index, dataIndex) ? 6 : 0,
                            0,
                            0
                        ]
                    }
                }))
            }))
        };
    };

    // Helper function to determine if this is the topmost non-zero value in the stack
    const isTopStack = (seriesIndex, dataIndex) => {
        for (let i = seriesIndex + 1; i < rawData.length; i++) {
            if (rawData[i][dataIndex] > 0) return false;
        }
        return true;
    };

    return (
        <div className="w-full h-full rounded-lg" style={{ width: '100%', height: 300 }}>
            <ReactEcharts
                ref={chartRef}
                option={getOption()}
                theme="reference_theme"
                style={{ height: '100%', width: '100%', cursor: 'pointer' }}
                opts={{ renderer: 'svg' }}
            />
        </div>
    );
};

export default SCASASTCombinedBar;