import { faInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NetworkPolicy() {
    return (<div><span>
        <FontAwesomeIcon style={{
            color: "#438eff"
        }} title="info" icon={faInfoCircle} className="me-2" />Deploy our kubernetes node agent in your cluster to collect network telemetry and automatically generate network policies for your cluster.</span>
        <div className="mt-2"><strong>Coming Soon...</strong></div>
    </div >)
}