import _ from 'lodash';

const convertData = (inputData, includeDeepChildren = false, expandNodeIds = []) => {
  // Function to recursively extract unique docTypes
  const extractDocTypes = (node, docTypes = new Set()) => {
    if (node.docType) {
      docTypes.add(node.docType);
    }
    _.forEach(node.children, child => extractDocTypes(child, docTypes));
    return docTypes;
  };

  // Extract unique docTypes and create categories
  const uniqueDocTypes = extractDocTypes(inputData);
  const categories = Array.from(uniqueDocTypes).map((docType, index) => ({
    name: _.startCase(docType),
    keyword: {},
    base: docType
  }));

  // Create a map for quick category index lookup
  const categoryMap = _.reduce(categories, (acc, category, index) => {
    acc[category.base] = index;
    return acc;
  }, {});

  const getCategoryIndex = (docType) => {
    return categoryMap[docType] || 0; // Default to first category if not found
  };

  const nodes = [];
  const links = [];
  const nodeMap = new Map();

  // Helper function to check if a node is expanded or a child of an expanded node
  const isNodeExpanded = (nodeId, parentId) => {
    return expandNodeIds.includes(nodeId) || expandNodeIds.includes(parentId);
  };

  const getSeverityColor = (vulObject) => {
    const severityCounts = {
      ...vulObject,
    };
    const sortedSeverity = _.fromPairs(
      _.sortBy(
        _.toPairs(severityCounts),
        ([key]) => ['critical', 'high', 'medium', 'low'].indexOf(key)
      )
    );
    const severityOrder = ['critical', 'high', 'medium', 'low'];
    const firstNonZeroSeverity = severityOrder.find(severity => sortedSeverity[severity] > 0);
    return firstNonZeroSeverity;
  }

  const processNode = (node, parentId = null, depth = 0) => {
    if (!node.id) {
      console.warn('Node without id encountered:', node);
      return;
    }

    // console.log("I am the node: ", node);
    let nodeIndex;
    if (!nodeMap.has(node.id)) {
      nodeIndex = nodes.length;
      nodes.push({
        name: node.name || node.id,
        value: 1,
        category: getCategoryIndex(node.docType),
        id: node.id,
        docType: node.docType,
        uniqId: node.id,
        parentId: parentId,
        hasChildren: Array.isArray(node.children) && node.children.length > 0,
        vulnerability: node.vulnerability,
        opacity: isNodeExpanded(node.id, parentId) ? 1 : 0.09
      });
      nodeMap.set(node.id, nodeIndex);
    } else {
      nodeIndex = nodeMap.get(node.id);
      // Update opacity for existing nodes
      nodes[nodeIndex].opacity = isNodeExpanded(node.id, parentId) ? 1 : 0.09;
    }

    if (parentId !== null && nodeMap.has(parentId)) {
      links.push({
        source: nodeMap.get(parentId),
        target: nodeIndex,
        vulnerability: getSeverityColor(node.vulnerability),
        opacity: isNodeExpanded(node.id, parentId) ? 1 : 0.09
      });
    }

    // Process children if:
    // 1. It's the first level (depth === 0), or
    // 2. includeDeepChildren is true, or
    // 3. This node's ID is in the expandNodeIds array
    if (depth === 0 || includeDeepChildren || expandNodeIds.includes(node.id)) {
      _.forEach(node.children, child => processNode(child, node.id, depth + 1));
    }
  };

  processNode(inputData);



  return {
    type: "force",
    categories: categories,
    nodes: nodes,
    links: links
  };
};

export default convertData;