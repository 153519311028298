import React, { useEffect, useState, useRef } from 'react';
import { Table, Tag, Dropdown, Space, Menu, Typography, Popover, Button, Checkbox, Alert, message } from 'antd';
import { SearchOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import Highlighter from 'react-highlight-words';
import { CaretDownOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus, faSquareMinus } from '@fortawesome/free-regular-svg-icons';
import { faCaretRight, faCaretDown, faFilter, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { getStrokeColor, getFontColor } from '../../../../shared/helper/genHelper';
import { ExpandIcon } from 'antd/lib/table/interface';
import _ from 'lodash';
import { Switch } from 'antd';
import { getArtifactImage } from '../../../../shared/helper/getArtifactImageHelper';
import ComponentExpandedTable from './componentExpandedTable';
import NoDataFound from '../../../../shared/sharedComponents/noDataFound';

const expandedRowRender = (props) => {

  // console.log("Props:", props);

  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');

  // const compareCVE = (a, b) => {
  //   const regex = /CVE-(\d{4})-(\d+)/;
  //   const aMatch = a.match(regex);
  //   const bMatch = b.match(regex);
  //   const aYear = parseInt(aMatch[1]);
  //   const aNumber = parseInt(aMatch[2]);
  //   const bYear = parseInt(bMatch[1]);
  //   const bNumber = parseInt(bMatch[2]);
  //   if (aYear !== bYear) {
  //     return aYear - bYear;
  //   }
  //   return aNumber - bNumber;
  // }




  // const getColumnSearchProps = (dataIndex, columnName) => ({
  //   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close, visible }) => (
  //     <div
  //       style={{
  //         padding: 8,
  //       }}
  //       onKeyDown={(e) => e.stopPropagation()}
  //     >
  //       <Input
  //         ref={searchInput}
  //         placeholder={`Search ${columnName}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, visible)}
  //         style={{
  //           marginBottom: 8,
  //           display: 'block',
  //         }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex, visible)}
  //           icon={<SearchOutlined />}
  //           className="d-flex align-items-center justify-content-center"
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => {
  //             if (clearFilters) {

  //               clearFilters();
  //               setSearchText('');
  //             }
  //             confirm({
  //               closeDropdown: false,
  //             });
  //             setSearchedColumn(dataIndex);
  //           }}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Reset
  //         </Button>
  //         <Button
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             close();
  //           }}
  //         >
  //           close
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined
  //       style={{
  //         color: filtered ? '#1890ff' : undefined,
  //       }}
  //     />
  //   ),
  //   onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownOpenChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => searchInput.current?.select(), 100);
  //     }
  //   },
  //   render: (text, row) =>
  //     (searchedColumn === dataIndex && !_.isEmpty(searchText)) ? (
  //       highlightText(searchedColumn, text, row)
  //     ) : (
  //       getColumnText(dataIndex, text, row)
  //     ),
  // });


  // const columns = [
  //   // {
  //   //   title: 'S. No.',
  //   //   dataIndex: 'serialNo',
  //   //   key: 'serialNo',
  //   //   width: '8%',
  //   //   // sorter: (a, b) => a.serialNo - b.serialNo,
  //   //   // sortDirections: ['descend'],
  //   // },
  //   {
  //     title: 'Vulnerability',
  //     dataIndex: 'vulType',
  //     key: 'vulType',
  //     sorter: (a, b) => a.module.localeCompare(b.module),
  //     sortDirections: ['ascend', 'descend'],
  //     // width: '20%',
  //     ellipsis: true,
  //     render: (text, row) => {
  //       return (!_.isEmpty(text) ? text : 'Unknown')
  //     }
  //   },
  //   {
  //     title: 'Severity',
  //     dataIndex: 'severity',
  //     key: 'severity',
  //     render: (val, row) => <Tag style={{ color: getFontColor(row.severity), fontWeight: 600 }} color={getStrokeColor(row.severity)}>{row.score} {row.severity}</Tag>,
  //     width: '13%'
  //   },
  //   {
  //     title: 'CVE ID',
  //     dataIndex: 'cveId',
  //     key: 'cveId',
  //     render: (val) => <a href={`/cve/${val}/?s=${val}`} target={'_blank'} rel="noreferrer">{val}</a>,
  //     sorter: (a, b) => { return compareCVE(a.cveId, b.cveId) },
  //     sortDirections: ['ascend', 'descend'],
  //     ...getColumnSearchProps('cveId', 'cve id'),
  //     width: '15%'
  //   },
  //   // {
  //   //   title: ' ',
  //   //   dataIndex: ' '
  //   // }
  // ];
  // const data = [];
  // if (!_.isEmpty(props) && !_.isEmpty(props.cveDetails)) {
  //   props.cveDetails.forEach((elem, index) => {
  //     data.push({
  //       key: `${index}-${elem.cveId}`,
  //       serialNo: index + 1,
  //       cveId: elem.cveId,
  //       score: (elem.cvssV3BaseSeverity) ? elem.cvssV3BaseScore : elem.cvssV2BaseScore,
  //       severity: (elem.cvssV3BaseSeverity) ? elem.cvssV3BaseSeverity : elem.cvssV2BaseSeverity,
  //       vulType: (!_.isEmpty(elem.cveTypes)) ? elem.cveTypes[0].cveType : 'Unknown'
  //     })
  //   })
  // }

  // const getPaginationStatus = (dataState) => {
  //   // console.log("Dataset::", dataState);
  //   if (!_.isEmpty(dataState)) {
  //     // console.log("Dataset is not empty")
  //     if (dataState.length > 10) {
  //       // console.log("Dataset length is high")
  //       return {
  //         showTotal: (total, range) => <span className='text-white'>{range[0]}-{range[1]} of {total && total.format()} vulnerabilities</span>,
  //         position: ["bottom", "left"],
  //         className: "searchPagePagination extendedPagePagination ms-4",
  //         showSizeChanger: true,
  //         defaultPageSize: 10,
  //         pageSizeOptions: [5, 10, 20, 50],
  //       };
  //     }
  //     else {
  //       // console.log("Returning false from the length value")
  //       return false;
  //     }

  //   }
  //   else {
  //     return false;
  //   }
  // }

  // return <Table
  //   size="middle"
  //   // className="table-striped-rows"
  //   className='component-view-table table table-striped custom-table mb-0'
  //   columns={columns}
  //   dataSource={data}
  //   pagination={getPaginationStatus(data)}
  // />;
  return <ComponentExpandedTable {...props} />;
};

const GetRecFixRow = ({ val, row, getCount }) => {

  // console.log("Row is :", row, val);

  const [selectedVersion, setSelectedVersion] = useState((!_.isEmpty(val) && !_.isEmpty(val[0])) ? val[0] : '');
  let count = getCount(row);

  const getDropdownMenuItems = (stableVersionList) => {
    let menuItems = [];
    if (!_.isEmpty(stableVersionList)) {
      stableVersionList.forEach((elem, index) => {
        menuItems.push({
          key: `${elem}`,
          label: `${elem}`
        })
      })
    }
    return menuItems;
  }

  const getCountStatement = (count) => {
    if (!_.isEmpty(count)) {
      let c = getIntroduceCount();
      return (<small className='text-muted d-block'>
        (this will fix {count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW} vulnerabilit{(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW) > 1 ? 'ies' : 'y'}
        {(c) ? ` and will introduce ${c} vulnerabilit${c > 1 ? 'ies' : 'y'}` : ''})
      </small>);
    }
  }

  const getIntroduceCount = () => {
    if (!_.isEmpty(row) && !_.isEmpty(row.introducedCveDetails)) {
      let objectVal = row.introducedCveDetails[selectedVersion];
      if (!_.isEmpty(objectVal) && !_.isEmpty(objectVal.cveCountDetails)) {
        let c = 0;
        _.forIn(objectVal.cveCountDetails, (val, key) => c = c + val)
        return c;
      }
      else {
        return 0;
      }
    }
    else {
      return 0;
    }
  }

  const handleDropdownMenuClick = ({ key }) => {
    if (!_.isEmpty(key) && selectedVersion !== key) {
      setSelectedVersion(key);
    }
  };

  if (_.isEmpty(val)) {
    return (<><span className='text-danger'>No fix available</span></>);
  }

  if (!_.isEmpty(val) && val.length > 1) {
    return (<>
      <Dropdown overlay={<Menu selectable defaultSelectedKeys={[selectedVersion]} onClick={handleDropdownMenuClick} items={getDropdownMenuItems(val)} className={'versionMenuList scroll-style'} />} trigger={['click']}>
        <Typography.Link>
          <Space>
            <span style={{ display: 'flex', maxWidth: '12rem', color: '#adb5bd', alignItems: 'center' }}>
              <span className="text-truncate" style={{ color: '#adb5bd', fontSize: 14, fontFamily: 'Open Sans' }}>{selectedVersion}</span>
              <CaretDownOutlined className="ms-1" />
            </span>
          </Space>
        </Typography.Link>
      </Dropdown>{!_.isEmpty(val) && getCountStatement(count)}</>);
  }
  else {
    return <span>{val[0]} {!_.isEmpty(val) && getCountStatement(count)}</span>;
  }
}


const ComponentDataTable = ({ dataSet, searchResults, filter, selectedFile, handleSeverityFilterChange }) => {

  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState({ CRITICAL: 0, HIGH: 0, MEDIUM: 0, LOW: 0, NA: 0 });
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const [filterDropdownVisible, setFilterDropdownVisible] = useState({});

  const [showAllComponents, setShowAllComponents] = useState(false);

  const onChangeShowAllComponents = (checked) => {
    setShowAllComponents(checked);
    if (checked) {
      infoComponentsAdded();
    }
    else {
      infoComponentsRemoved();
    }
  }

  const searchInput = useRef(null);

  const infoComponentsAdded = () => {
    messageApi.info('All components without vulnerabilities added to the table');
  };

  const infoComponentsRemoved = () => {
    messageApi.info('Components without vulnerabilities removed from the table');
  };

  const getPackageDetails = (val, type, artifact) => {
    if (val) {
      let splitedPkg = val.split(":");
      switch (type) {
        case "artifact":
          let sText = splitedPkg[1].split('/');
          return sText[2];
        case "group":
          let splitedText = splitedPkg[1].split('/');
          return splitedText[1];
        case 'getEcosystem':
          let ecosystem = splitedPkg[1].split('/');
          return ecosystem[0];
        case 'packageName':
          // if (artifact === "maven") {
          //   let strSplit = splitedPkg[1].split('/');
          //   return `${strSplit[0]}/${strSplit[1]}:${strSplit[2]}`;
          // }
          // else {
          //   // return splitedPkg[1];
          //   // console.log(splitedPkg[1].split(/\/(.*)/s));
          //   let splitTxt = splitedPkg[1].split(/\/(.*)/s)
          //   return splitTxt[1];
          // }
          return splitedPkg[1];
        case 'getVersion':
          let version = splitedPkg[1].split('@')
          return version[1];
        default:
          return splitedPkg[2];
      }
    }
    else {
      return ('');
    }
  }

  useEffect(() => {
    if (!_.isEmpty(dataSet)) {
      // console.log("Here in the showAllComponents", showAllComponents);
      // console.log("Dataset::", dataSet);
      let newData = [];
      let dataSetDuplicate = [...dataSet];
      let newCve = [], count = {};
      // if (selectedFile !== 'all') {
      //   dataSetDuplicate = [dataSet[selectedFile]];
      // }
      // let inc = 0;
      // console.log("Filter::", filter);
      dataSetDuplicate.forEach((application, index) => {
        if (!_.isEmpty(application) && !_.isEmpty(application.scanResult)) {
          newCve = [];

          setTotalCount({ CRITICAL: 0, HIGH: 0, MEDIUM: 0, LOW: 0, NA: 0, ...application.cveCountDetails });

          // console.log("Application count details:::", JSON.stringify(application.cveCountDetails));

          _.forIn(application.scanResult, (value, key) => {
            // Code for cve Filter based on the selected severity
            if (!_.isEmpty(filter)) {
              newCve = _.filter(value.cves, (o) => (o.cvssV3BaseSeverity) ? _.includes(filter, o.cvssV3BaseSeverity) : _.includes(filter, o.cvssV2BaseSeverity));

            }
            else {
              newCve = value.cves;
            }

            // console.log("I am the values cves:: =>", value.cves);


            // console.log("New CVE::", newCve);

            if (!_.isEmpty(newCve)) {
              count = getCount({ cveDetails: [...newCve] });
              // console.log("I am new cve::", JSON.stringify(count));
              newData.push({
                key: `${index}-${key}`,
                ecosystem: getPackageDetails(key, 'getEcosystem'),
                packageName: getPackageDetails(key, 'packageName', application.scanType),
                vulnerableVersion: getPackageDetails(key, 'getVersion'),
                recomendedFix: (!_.isEmpty(value) && !_.isEmpty(value.stableRecommendedVersionList)) ? value.stableRecommendedVersionList : value.recommendedVersionList,
                cveDetails: [...newCve],
                introducedCveDetails: value.introducedCveDetails,
                scanFileLocation: (application.scanFileLocation) ? getModuleName(application.scanFileLocation) : '',
                fileLocation: (application.scanFileLocation) ? application.scanFileLocation : '',
                critical: count.CRITICAL,
                medium: count.MEDIUM,
                high: count.HIGH,
                low: count.LOW,
                na: count.NA,
                total: (count.CRITICAL + count.MEDIUM + count.HIGH + count.LOW + count.NA),
                colSpan: 0,
                traceabilityDetails: value.traceabilityDetails
              });
            }
            else {
              if (showAllComponents) {
                // console.log("Emptycve");
                newData.push({
                  key: `${index}-${key}`,
                  ecosystem: getPackageDetails(key, 'getEcosystem'),
                  packageName: getPackageDetails(key, 'packageName', application.scanType),
                  vulnerableVersion: getPackageDetails(key, 'getVersion'),
                  recomendedFix: '-',
                  cveDetails: [],
                  introducedCveDetails: {},
                  scanFileLocation: (application.scanFileLocation) ? getModuleName(application.scanFileLocation) : '',
                  fileLocation: (application.scanFileLocation) ? application.scanFileLocation : '',
                  critical: 0,
                  medium: 0,
                  high: 0,
                  low: 0,
                  na: 0,
                  total: 0,
                  colSpan: 9
                });
              }
            }
          })
        }
      });



      // let gfg = _.sortBy(newData, [function (o) { return o.critical; }]);
      let sortedData = [];

      if (!_.isEmpty(newData)) {
        sortedData = _.orderBy(newData, ['critical', 'high', 'medium', 'low', 'na'], ['desc', 'desc', 'desc', 'desc', 'desc']);
      }

      // console.log("I am the newData here:::", sortedData);



      setData(sortedData);
    }
  }, [dataSet, selectedFile, filter, showAllComponents]);

  const getModuleName = (module) => {
    if (module) {
      const splitText = module.split('/');
      // console.log(splitText);
      return splitText ? `${splitText[splitText.length - 2]}/${splitText.pop()}` : '';
    }
    else {
      return '';
    }
  }

  const getCount = (rowData) => {
    // console.log("I am new data:", rowData);
    let count = { CRITICAL: 0, HIGH: 0, MEDIUM: 0, LOW: 0, NA: 0 };
    if (!_.isEmpty(rowData) && !_.isEmpty(rowData.cveDetails)) {
      rowData.cveDetails.forEach((elem) => {
        if (!_.isEmpty(elem)) {
          if (!_.isEmpty(elem.cvssV3BaseSeverity)) {
            count[elem.cvssV3BaseSeverity] = count[elem.cvssV3BaseSeverity] + 1;
          }
          else {
            if (!_.isEmpty(elem.cvssV2BaseSeverity)) {
              count[elem.cvssV2BaseSeverity] = count[elem.cvssV2BaseSeverity] + 1;
            }
            else {
              count['NA'] = count["NA"] + 1;
            }
          }
        }
      });
    }
    return count;
  }



  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const getColumnSearchProps = (dataIndex, columnName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${columnName}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            className="d-flex align-items-center justify-content-center"
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {

                clearFilters();
                setSearchText('');
              }
              //  && handleReset(clearFilters);
              confirm({
                closeDropdown: false,
              });
              // console.log("I am he dataIndex", dataIndex);
              // setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    filterDropdownVisible: filterDropdownVisible[dataIndex],
    onFilterDropdownVisibleChange: (visible) => handleSearchClick(dataIndex, visible),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, row) => (searchedColumn === dataIndex && !_.isEmpty(searchText)) ? highlightText(searchedColumn, text, row) : getColumnText(dataIndex, text, row)
  });


  const handleSearchClick = (selectedColumn, visible) => {
    let allVisible = _.mapValues(filterDropdownVisible, function (o) { return false; });
    setFilterDropdownVisible({ ...allVisible, [selectedColumn]: visible });
  };


  const getColumnText = (searchedColumn, text, row) => {
    // console.log("I am the text::", text, searchedColumn, row);
    if (searchedColumn === 'packageName') {
      // console.log("here::")
      // let returnData = [], count = getCount(row);
      // if (!_.isEmpty(row) && !_.isEmpty(row.cveDetails)) {
      //   _.forIn(count, (value, key) => {
      //     if (value) {
      //       returnData.push(<span key={key} className={'me-1'}>{value}{key[0]}</span>);
      //     }
      //   })
      // }

      let [artifact, componentRaw] = text.split(/\/(.*)/s);
      let component = '';
      let imageTitle = '';
      if (artifact === 'maven') {
        component = _.replace(componentRaw, '/', ':');
        imageTitle = 'java';
      }
      else {
        component = componentRaw;
        imageTitle = artifact;
      }
      return <span><img src={getArtifactImage(artifact)} style={{ width: 25 }} className="me-1" title={imageTitle} alt={artifact} /> <span title={component}>{component}</span></span>;

      // return (
      //   <>
      //     <span title={text}>{text}
      //       {/* <small className='text-muted d-block'>(has {(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW)} vulnerabilit{(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW) > 1 ? 'ies' : 'y'})</small> */}
      //     </span>
      //     {/* <small className='text-muted d-block'> */}
      //     {/* ({(!_.isEmpty(returnData) ? returnData : <span className='mx-1'>0</span>)} */}
      //     {/* vulnerabilit{(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW) > 1 ? 'ies' : 'y'}  */}
      //     {/* ) */}
      //     {/* </small> */}
      //   </>
      // );


    }
    else if (searchedColumn === 'scanFileLocation') {
      // console.log("Inside the if")
      // console.
      // console.log("Scan file location::", text, row);
      let href = '';
      // if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.assetUrl)) {
      //   let [project, filePath] = row.fileLocation.split(/\/(.*)/s);
      //   href = searchResults.assetUrl + '/tree/' + searchResults.branch + '/' + filePath;
      // }
      if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.assetUrl)) {

        if (!_.isEmpty(searchResults.assetType) && searchResults.assetType === 'file') {
          return ((text) && <span title={row.fileLocation}>{row.fileLocation}</span>);
        }
        else {
          let hrefPart = row.fileLocation.split(/\//g);
          if (hrefPart.length >= 3) {
            href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(2).join('/');
          }
          else {
            href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(1).join('/');
          }
          return <a href={href} target="_blank"><span title={row.fileLocation}>{text}</span></a>;
        }


      }

      // return <span title={row.fileLocation}>{text}</span>;
    }
    else {
      return text;
    }


  }

  const highlightText = (searchedColumn, text, row) => {
    // console.log("here", searchedColumn, text, row);
    if (searchedColumn === 'packageName') {


      // let returnData = [], count = getCount(row);
      // if (!_.isEmpty(row) && !_.isEmpty(row.cveDetails)) {
      //   _.forIn(count, (value, key) => {
      //     if (value) {
      //       returnData.push(<span key={key} className={'me-1'}>{value}{key[0]}</span>);
      //     }
      //   })
      // }


      let [artifact, componentRaw] = text.split(/\/(.*)/s);
      let component = '';
      let imageTitle = '';
      if (artifact === 'maven') {
        component = _.replace(componentRaw, '/', ':');
        imageTitle = 'java';
      }
      else {
        component = componentRaw;
        imageTitle = artifact;
      }
      return <span ><img src={getArtifactImage(artifact)} style={{ width: 25 }} className="me-1" alt={artifact} title={imageTitle} /> <span title={component}>{getHighlighted(component)}</span></span>;





      // return (
      //   <>
      //     <span title={text}>{getHighlighted(text)} <small className='text-muted d-block'>(has {(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW)} vulnerabilit{(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW) > 1 ? 'ies' : 'y'})</small></span>
      //     {/* <small className='text-muted d-block'> */}
      //     {/* ({(!_.isEmpty(returnData) ? returnData : <span className='mx-1'>0</span>)} */}
      //     {/* vulnerabilit{(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW) > 1 ? 'ies' : 'y'}  */}
      //     {/* ) */}
      //     {/* </small> */}
      //   </>
      // );
    }
    else if (searchedColumn === 'scanFileLocation') {
      // console.log("Inside the if")
      // console.
      let href = '';
      // if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.assetUrl)) {
      //   let [project, filePath] = row.fileLocation.split(/\/(.*)/s);
      //   href = searchResults.assetUrl + '/tree/' + searchResults.branch + '/' + filePath;
      // }
      if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.assetUrl)) {

        if (!_.isEmpty(searchResults.assetType) && searchResults.assetType === 'file') {
          return ((text) && <span title={row.fileLocation}>{row.fileLocation}</span>);
        }
        else {
          let hrefPart = row.fileLocation.split(/\//g);
          if (hrefPart.length >= 3) {
            href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(2).join('/');
          }
          else {
            href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(1).join('/');
          }
          return <a href={href} target="_blank"><span title={row.fileLocation}>{getHighlighted(text)}</span></a>;
        }


      }

      // return <span title={row.fileLocation}>{getHighlighted(text)}</span>;
    }
    else {
      return getHighlighted(text);
    }

  }

  const getHighlighted = (text) => {
    // console.log("I am text::", text)
    return <Highlighter
      highlightStyle={{
        backgroundColor: '#ffc069',
        padding: 0,
      }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
    />;
  }


  const columns = [
    {
      // title: (val, row) => {
      //   return (<span className='d-flex align-items-center justify-content-between w-100'>
      //     <span>Vulnerable component</span>
      //     <Popover
      //       placement="bottom"
      //       content={<div>
      //         <ul className="list-unstyled mb-0">
      //           <li><Checkbox checked={_.includes(filter, 'CRITICAL')} onChange={(e) => handleSeverityFilterChange(e, 'CRITICAL')}><span>critical ({totalCount.CRITICAL})</span></Checkbox></li>
      //           <li><Checkbox checked={_.includes(filter, 'HIGH')} onChange={(e) => handleSeverityFilterChange(e, 'HIGH')}><span>high ({totalCount.HIGH})</span></Checkbox></li>
      //           <li><Checkbox checked={_.includes(filter, 'MEDIUM')} onChange={(e) => handleSeverityFilterChange(e, 'MEDIUM')}><span>medium ({totalCount.MEDIUM})</span></Checkbox></li>
      //           <li><Checkbox checked={_.includes(filter, 'LOW')} onChange={(e) => handleSeverityFilterChange(e, 'LOW')}><span>low ({totalCount.LOW})</span></Checkbox></li>
      //         </ul>
      //       </div>}
      //       title=""
      //       trigger="click"
      //     >
      //       <Button className={`me-2 ${(_.isEmpty(filter)) ? 'text-black-50' : ''}`} type={(_.isEmpty(filter)) ? 'text' : 'link'} size="small"><FontAwesomeIcon style={{ fontSize: '0.8rem' }} icon={faFilter} /></Button>
      //     </Popover>
      //   </span>)
      // },
      title: 'Vulnerable component',
      dataIndex: 'packageName',
      key: 'packageName',
      sorter: (a, b) => a.packageName.localeCompare(b.packageName),
      ellipsis: true,
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('packageName', 'vulnerable component'),
      // render: (val, row) => {
      //   let returnData = [], count = getCount(row);
      //   if (!_.isEmpty(row) && !_.isEmpty(row.cveDetails)) {
      //     _.forIn(count, (value, key) => {
      //       if (value) {
      //         returnData.push(<span key={key} className={'me-1'}>{value}{key[0]}</span>);
      //       }
      //     })
      //   }
      //   return (
      //     <>
      //       <span>{val} <small className='text-muted d-block'>(has {(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW)} vulnerabilit{(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW) > 1 ? 'ies' : 'y'})</small></span>
      //       {/* <small className='text-muted d-block'> */}
      //       {/* ({(!_.isEmpty(returnData) ? returnData : <span className='mx-1'>0</span>)} */}
      //       {/* vulnerabilit{(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW) > 1 ? 'ies' : 'y'}  */}
      //       {/* ) */}
      //       {/* </small> */}
      //     </>
      //   );
      // },
      // width: '18%',

    },
    // {
    //   title: 'Vulnerability count',
    //   children: [
    {
      title: 'Total',
      dataIndex: 'total',
      width: 75,
      sorter: (a, b) => a.total - b.total,
      sortDirections: ['descend'],
      align: (val, row) => { return row.colSpan ? 'left' : 'center' },
      render: (val, row) => {
        if (row.colSpan === 9) {
          return 'This component has no known vulnerabilities'
        }

        return val ?
          <Tag style={{ color: '#adb5bd', borderRadius: 50, width: 40, border: 'none', boxShadow: 'none', background: 'transparent', fontSize: 14 }} color={getStrokeColor('TOTAL')} className={'me-1 text-center'}>{val}</Tag>
          : ''
      },
      // onCell: (_, row) => ({ colSpan: (_.colSpan === 9) ? 0 : '' })
      onCell: (_, row) => { return ({ colSpan: (_.colSpan === 9) ? 7 : '' }) }
    },
    {
      title: 'Critical',
      dataIndex: 'critical',
      width: 75,
      // defaultSortOrder: 'descend',
      sorter: (a, b) => a.critical - b.critical,
      sortDirections: ['descend'],
      align: 'center',
      render: (val, row) => val ? <Tag style={{ color: getFontColor('CRITICAL'), borderRadius: 50, width: 40 }} color={getStrokeColor('CRITICAL')} className={'me-1 text-center'}>{val}</Tag> : '',
      onCell: (_, row) => ({ colSpan: (_.colSpan === 9) ? 0 : '' })
    },
    {
      title: 'High',
      dataIndex: 'high',
      width: 65,
      sorter: (a, b) => a.high - b.high,
      sortDirections: ['descend'],
      align: 'center',
      render: (val, row) => val ? <Tag style={{ color: getFontColor('HIGH'), borderRadius: 50, width: 40 }} color={getStrokeColor('HIGH')} className={'me-1 text-center'}>{val}</Tag> : '',
      onCell: (_, row) => ({ colSpan: (_.colSpan === 9) ? 0 : '' })
    },
    {
      title: 'Medium',
      dataIndex: 'medium',
      width: 85,
      sorter: (a, b) => a.medium - b.medium,
      sortDirections: ['descend'],
      align: 'center',
      render: (val, row) => val ? <Tag style={{ color: getFontColor('MEDIUM'), borderRadius: 50, width: 40 }} color={getStrokeColor('MEDIUM')} className={'me-1 text-center'}>{val}</Tag> : '',
      onCell: (_, row) => ({ colSpan: (_.colSpan === 9) ? 0 : '' })
    },
    {
      title: 'Low',
      dataIndex: 'low',
      width: 65,
      sorter: (a, b) => a.low - b.low,
      sortDirections: ['descend'],
      align: 'center',
      render: (val, row) => val ? <Tag style={{ color: getFontColor('LOW'), borderRadius: 50, width: 40 }} color={getStrokeColor('LOW')} className={'me-1 text-center'}>{val}</Tag> : '',
      onCell: (_, row) => ({ colSpan: (_.colSpan === 9) ? 0 : '' })
    },
    {
      title: 'NA',
      dataIndex: 'na',
      width: 65,
      sorter: (a, b) => a.na - b.na,
      sortDirections: ['descend'],
      align: 'center',
      render: (val, row) => val ? <Tag style={{ color: getFontColor('NA'), borderRadius: 50, width: 40 }} color={getStrokeColor('NA')} className={'me-1 text-center'}>{val}</Tag> : '',
      onCell: (_, row) => ({ colSpan: (_.colSpan === 9) ? 0 : '' })
    },

    // ],
    // dataIndex: 'recomendedFix',
    // key: 'recomendedFix',
    //   render: (val, row) => {
    //     let returnData = [], count = getCount(row);
    //     if (!_.isEmpty(row) && !_.isEmpty(row.cveDetails)) {
    //       _.forIn(count, (value, key) => {
    //         if (value) {
    //           returnData.push(<Tag style={{ color: getFontColor(key), borderRadius: 50, width: 50 }} color={getStrokeColor(key)} key={key} className={'me-1 text-center'}>{value} {key[0]}</Tag>);
    //         }
    //         else {
    //           returnData.push(<Tag style={{ color: 'transparent', borderRadius: 50, width: 53, border: 0 }} key={key} className={'me-1 text-center'}></Tag>)
    //         }
    //       })
    //     }
    //     return (
    //       <>

    //         <small className='text-muted d-block'>
    //           {(!_.isEmpty(returnData) ? returnData : <span className='mx-1'>0</span>)}
    //           {/* vulnerabilit{(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW) > 1 ? 'ies' : 'y'} */}

    //         </small>
    //       </>
    //     );
    //   },
    //   width: '20%',
    //   ellipsis: true,
    // },
    {
      title: 'Recommended fix',
      dataIndex: 'recomendedFix',
      key: 'recomendedFix',
      width: '18%',
      onCell: (_, row) => ({ colSpan: (_.colSpan === 9) ? 0 : '' }),
      render: (val, row) => {
        return <GetRecFixRow val={val} row={row} getCount={getCount} />
      },
    },
    {
      title: 'Module name',
      dataIndex: 'scanFileLocation',
      key: 'scanFileLocation',
      ...getColumnSearchProps('scanFileLocation', 'module name'),
      sorter: (a, b) => a.scanFileLocation.localeCompare(b.scanFileLocation),
      sortDirections: ['ascend', 'descend'],
      // render:(val, row)=>{
      //   return <span title={row.fileLocation}>{val}</span>
      // },
      // render: (val, row) => {
      //   return val
      // },
      // width: '25%',
      ellipsis: true,
      // onCell: (_, row) => ({ colSpan: (_.colSpan === 9) ? 0 : '' })
    },
    // {
    //   title: '',
    //   dataIndex: '',
    //   // onCell: (_, row) => ({ colSpan: (_.colSpan === 9) ? 0 : '' })
    // }
    {
      title: 'Is Traceable',
      dataIndex: 'traceabilityDetails',
      key: 'traceabilityDetails',
      align: "center",
      render: (traceabilityDetails, row) => {
        return (
          !_.isEmpty(traceabilityDetails) ?
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="text-success"
            />
            : <FontAwesomeIcon
              icon={faCircleXmark}
              className="text-danger"
            />
        );
      },
    }
  ];

  const expandIcon = ({ expanded, onExpand, record }) => {
    if (record.colSpan) {
      return false;
    }
    return expanded ? <button type="button" onClick={(e) => onExpand(record, e)} className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded" aria-label="Collapse row" aria-expanded="true"></button> : <button onClick={(e) => onExpand(record, e)} type="button" className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed" aria-label="Expand row" aria-expanded="false"></button>
  };


  return (
    <div className=''>
      {contextHolder}
      <div className='d-flex align-items-center justify-content-end mb-4 me-3 mt-2' style={{ position: 'absolute', right: 0, top: '-56px' }}>
        <Switch onChange={onChangeShowAllComponents} checked={showAllComponents} className='me-2' />Show all components
      </div>
      <Table
        className='component-view-table table table-striped custom-table mb-0'
        columns={columns}
        // sticky={{ offsetHeader: 0 }}
        expandable={{
          expandedRowRender,
          expandIcon: expandIcon
          // expandIcon: ({ expanded, onExpand, record }) => <FontAwesomeIcon icon={(expanded) ? faSquareMinus : faSquarePlus} style={{ cursor: 'pointer' }} onClick={e => onExpand(record, e)} />
        }}
        dataSource={data}
        // size="small"
        pagination={{
          showTotal: (total, range) => {
            return <span className='text-white'>{range[0]}-{range[1]} of {total && total.format()} components</span>;
          },
          // position: ["bottom", "left"],
          // className: "searchPagePagination",
          // pageSizeOptions: [25, 50, 100, 200],

          defaultPageSize: 20,
          showSizeChanger: true,
          position: ["bottom", "left"],
          className: "searchPagePagination",
          pageSizeOptions: [10, 20, 25, 50, 100, 200]
        }}
        locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
      />
    </div>
  );
};
export default ComponentDataTable;