import React, { useEffect, useState } from "react";
import { faCheck, faMoneyCheckDollar, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch, message } from 'antd';
import _ from 'lodash';


import FreePlanList from "./freePlanList";
import StartupPlanList from "./startupPlanList";
import StandardPlanList from "./standardPlanList";
import EnterprisePlanList from "./enterprisePlanList";
import axios from "axios";
import Loader from "../../shared/sharedComponents/loader";

import { useSelector } from "react-redux";
import { GlobalConst } from "../../shared/appConfig/globalConst";

export default function PlanAndPricingPage() {

    const reduxState = useSelector(state => state.authReducer.userDetails);
    const [planType, setPlanType] = useState('monthly');
    const [selectedProducts, setSelectedProducts] = useState(['scopy']);
    const [pricesList, setPricesList] = useState({});
    const [loadingPrices, setLoadingPrices] = useState(false);

    useEffect(() => {
        if (!_.isEmpty(reduxState) && !_.isEmpty(reduxState.email)) {
            getSubscriptionPlan(reduxState.email);
        }

    }, [reduxState])

    useEffect(() => {









        setLoadingPrices(true);
        axios
            .get(`${GlobalConst.API_URL}/foss/payment/get-product-prices`)
            .then(op => {
                // console.log("Op", op);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.data)) {
                    setPricesList(op.data.data);
                }
                else {
                    message.error({
                        content: (
                            <div className="d-flex align-items-center">
                                The price list is currently unavailable. Please check back later.
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                        onClose: () => message.destroy(),
                    });
                }
            })
            .catch(error => {
                if (error.response) {
                    message.error({
                        content: (
                            <div className="d-flex align-items-center">
                                We encountered an issue retrieving the price list. Please try again later.
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                        onClose: () => message.destroy(),
                    });
                } else {
                    message.error({
                        content: (
                            <div className="d-flex align-items-center">
                                A network error occurred. Please verify your internet connection and try again.
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                        onClose: () => message.destroy(),
                    });
                }
            })
            .finally(() => {
                setLoadingPrices(false);
            });







    }, []);


    const getSubscriptionPlan = (email) => {

        setLoadingPrices(true);
        const postData = { "email": email };
        axios
            .post(`${GlobalConst.API_URL}/foss/user/subscriptions/get`, postData)
            .then(op => {
                // console.log("Op", op);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.data)) {
                    // setPricesList(op.data.data);
                }
                else {
                    message.error({
                        content: (
                            <div className="d-flex align-items-center">
                                We were unable to fetch the current subscriptions. Please check back later.
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                        onClose: () => message.destroy(),
                    });
                }
            })
            .catch(error => {
                if (error.response) {
                    message.error({
                        content: (
                            <div className="d-flex align-items-center">
                                We encountered an issue retrieving current subscription. Please try again later.
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                        onClose: () => message.destroy(),
                    });
                } else {
                    message.error({
                        content: (
                            <div className="d-flex align-items-center">
                                A network error occurred. Please verify your internet connection and try again.
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                        onClose: () => message.destroy(),
                    });
                }
            })
            .finally(() => {
                setLoadingPrices(false);
            });
    }

    const handleSwitchChange = (checked) => {
        // Update the planType state based on the switch status
        const newPlanType = checked ? 'yearly' : 'monthly';
        setPlanType(newPlanType);
    };

    const getPricesByPlan = (planType) => {
        const filteredPrices = _.pickBy(pricesList, (value, key) => {
            return key.startsWith(`${planType}_`);
        });
        return filteredPrices;
    }


    return (
        <section className="mx-3">
            <div className="mb-4">
                <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-2 text-white d-flex align-items-center justify-content-start">
                    <FontAwesomeIcon icon={faMoneyCheckDollar} className="me-3" /> Plans and Pricing
                </h2>
            </div>

            <div className="mt-4 mb-3 w-100">
                <div className="d-flex align-items-center justify-content-between align-self-stretch w-100">
                    <div>
                        <h3 className="fw-semibold fs-22">Plans for Your Business</h3>
                        <p className="text-muted fs-15 mb-0">Simple Pricing with No Hidden Fees and Advanced Features for Your Business</p>
                    </div>
                    <div className="d-flex align-items-end justify-content-end">
                        <span onClick={(e) => { setPlanType('monthly') }} className={`me-2 ${planType === 'monthly' ? 'text-white' : 'text-secondary'}`} style={{ cursor: 'pointer' }}>Monthly</span>
                        <Switch
                            className="always-on-switch"
                            checked={planType === 'yearly'}
                            onChange={handleSwitchChange}
                        />
                        <span onClick={(e) => { setPlanType('yearly') }} className={`ms-2 ${planType === 'yearly' ? 'text-white' : 'text-secondary'}`} style={{ cursor: 'pointer' }}>Annually - Save 10%</span>
                    </div>
                </div>
            </div>
            {!_.isEmpty(pricesList) &&
                <div className="row">
                    <div className="col-xxl-3 col-lg-6">
                        <FreePlanList planType={planType} />
                    </div>

                    <div className="col-xxl-3 col-lg-6">
                        <StartupPlanList planType={planType} setSelectedProducts={setSelectedProducts} selectedProducts={selectedProducts} prices={getPricesByPlan('startup')} />
                    </div>

                    <div className="col-xxl-3 col-lg-6">
                        <StandardPlanList planType={planType} setSelectedProducts={setSelectedProducts} selectedProducts={selectedProducts} prices={getPricesByPlan('standard')} />
                    </div>

                    <div className="col-xxl-3 col-lg-6">
                        <EnterprisePlanList planType={planType} />
                    </div>
                </div>
            }

            {loadingPrices && <Loader />}
        </section>
    );
}