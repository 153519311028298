import { Divider, Button } from 'antd';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CiLink } from "react-icons/ci";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { handleDastFormSubmit } from '../../shared/helper/apiHelper';
import dayjs from 'dayjs';
import Scheduler from '../dastDashboard/scheduler';


export default function UsernamePasswordFields({ handleEditConfigClose, reportData }) {

    const formikRef = useRef(null);
    const ssPlaceholderUrl = 'https://example-secured.com/';
    const pwsPlaceholderUrl = 'https://example.com/';
    const [showPasswordUname, setShowPasswordUname] = useState(false);
    const [schedulerList, setSchedulerList] = useState([]);

    useEffect(() => {
        if (reportData.schedulers && reportData.schedulers.length > 0) {
            const convertedSchedulers = reportData.schedulers.map(scheduler => {
                // Create time string in hh:mm A format
                const timeString = `${scheduler.hour}:${scheduler.minute.toString().padStart(2, '0')} ${scheduler.period}`;

                return {
                    id: Date.now() + Math.random(),
                    time: dayjs(timeString, 'h:mm A'),
                    timezone: scheduler.timezone
                };
            });
            setSchedulerList(convertedSchedulers);
        }
    }, [reportData.schedulers]);


    const handleFormSubmit = (values, setSubmitting) => {

        setSubmitting(true);
        values.scanType = 'website';
        values.configId = reportData.configId;

        let response = handleDastFormSubmit( values, 'config', schedulerList);
        if (response) {
            alert('Settings saved successfully');
            setSubmitting(false);
            handleEditConfigClose();
        }
        else {
            setSubmitting(false);
        }
    }

    const validationSchema = Yup.object().shape({
        url: Yup.string()
            .required('Website URL is required')
            .url('Please enter a valid URL')
            .test('is-valid-protocol', 'Please enter a valid HTTP or HTTPS URL', function (value) {
                const { isSecureSite } = this.parent;
                if (isSecureSite) {
                    return value && value.startsWith('https://');
                }
                return value && (value.startsWith('http://') || value.startsWith('https://'));
            }),

        isSecureSite: Yup.boolean(),

        scanAuthType: Yup.string().when('isSecureSite', {
            is: true,
            then: () => Yup.string()
                .required('Authentication type is required')
                .oneOf(['NO-AUTH', 'OAUTH2', 'USERNAME-PASSWORD'], 'Invalid authentication type')
        }),


        // Username-Password fields
        authenticatorName: Yup.string().when(['isSecureSite', 'scanAuthType'], {
            is: (isSecureSite, scanAuthType) => isSecureSite && scanAuthType === 'USERNAME-PASSWORD',
            then: () => Yup.string()
                .required('Authenticator name is required')
                .min(2, 'Authenticator name must be at least 2 characters')
        }),

        userIdUname: Yup.string().when(['isSecureSite', 'scanAuthType'], {
            is: (isSecureSite, scanAuthType) => isSecureSite && scanAuthType === 'USERNAME-PASSWORD',
            then: () => Yup.string()
                .required('User ID is required')
                .min(3, 'User ID must be at least 3 characters')
        }),

        passwordUname: Yup.string().when(['isSecureSite', 'scanAuthType'], {
            is: (isSecureSite, scanAuthType) => isSecureSite && scanAuthType === 'USERNAME-PASSWORD',
            then: () => Yup.string()
                .required('Password is required')
                .min(8, 'Password must be at least 8 characters')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
                    'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
                )
        })
    });



    return (
        <>
            <Formik
                initialValues={{
                    url: reportData.url,
                    scanAuthType: reportData.authType,
                    isSecureSite: true,
                    triggerNow: true,
                    authenticatorName: reportData.usernamePassword.authenticatorName,
                    userIdUname: reportData.usernamePassword.userId,
                    passwordUname: reportData.usernamePassword.password
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    handleFormSubmit(values, setSubmitting);
                }}
                innerRef={formikRef}
            >
                {
                    ({ isSubmitting, values }) => {

                        return (
                            <Form>
                                <div className="mb-3">
                                    <div className="input-group me-3">
                                        <span className="input-group-text"><CiLink /></span>
                                        <Field
                                            name="url"
                                            type="text"
                                            className="input input-rounded form-control"
                                            aria-describedby="Website URL"
                                            placeholder={`Enter a website URL to trigger DAST scan for e.g. ${values.isSecureSite ? ssPlaceholderUrl : pwsPlaceholderUrl}`}
                                            disabled={true}
                                        />
                                    </div>
                                    <ErrorMessage name="url">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                </div>

                                <div className="mb-3">
                                    <label className="form-check">
                                        <Field
                                            type="checkbox"
                                            name="isSecureSite"
                                            className="form-check-input me-2"
                                            disabled={true}
                                        />
                                        This is a secure site?
                                    </label>
                                </div>

                                {values.isSecureSite && (
                                    <div className="secure-site-fields mb-4">
                                        <div className="mb-3">
                                            <label htmlFor='scanAuthType' className="form-label">Authentication Type</label>
                                            <div className="input-group mb-3">
                                                <Field
                                                    id="scanAuthType"
                                                    as="select"
                                                    name="scanAuthType"
                                                    className="form-select"
                                                    disabled={true}
                                                >
                                                    <option value="NO-AUTH">Select Authentication Type</option>
                                                    <option value="OAUTH2">OAUTH2</option>
                                                    <option value="USERNAME-PASSWORD">Username Password</option>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="scanAuthType">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                        </div>

                                        {values.scanAuthType === 'USERNAME-PASSWORD' && (
                                            <>
                                                <div className="mb-3">
                                                    <Field
                                                        id="authenticatorName"
                                                        name="authenticatorName"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Authenticator Name (Provided by Sec1)"
                                                    />
                                                    <ErrorMessage name="authenticatorName">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                </div>

                                                <div className="mb-3">
                                                    <Field
                                                        id="userIdUname"
                                                        name="userIdUname"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter User Id"
                                                    />
                                                    <ErrorMessage name="userIdUname">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                </div>

                                                <div className="mb-3">
                                                    <div className="input-group me-3">
                                                        <Field
                                                            id="passwordUname"
                                                            name="passwordUname"
                                                            type={showPasswordUname ? 'text' : 'password'}
                                                            className="form-control"
                                                            placeholder="Enter Password"
                                                        />
                                                        <span className="input-group-text" onClick={() => setShowPasswordUname(!showPasswordUname)}>
                                                            {showPasswordUname ? <FaRegEye /> : <FaRegEyeSlash />}
                                                        </span>
                                                    </div>
                                                    <ErrorMessage name="passwordUname">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                                <Divider />
                                <Scheduler schedulerList={schedulerList} setSchedulerList={setSchedulerList} />
                                <div className="mb-4">
                                    <label className="form-check">
                                        <Field
                                            type="checkbox"
                                            name="triggerNow"
                                            className="form-check-input me-2"
                                        />
                                        Trigger scan now
                                    </label>
                                </div>
                                <div className="">
                                    <Button htmlType="submit" type="primary" size={'large'}
                                        loading={isSubmitting}
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        )
                    }
                }
            </Formik>
        </>
    );
}