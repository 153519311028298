import _ from 'lodash';
import { Table, Tag } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import NoDataFound from '../../shared/sharedComponents/noDataFound';

const Version = ({ data: vulnerabilities }) => {
  const typeLink = `https://cwe.mitre.org/data/definitions/`;

  const getTypeLink = (cveId) => {
    let url = "#";
    if (!_.isEmpty(cveId)) {
      url = cveId.split("-");
      return `${typeLink}${url[1]}.html`;
    }
    return url;
  };

  const getCVSScore = (elem) => {
    if (!_.isEmpty(elem)) {
      if (
        (!_.isEmpty(elem.cvssV3BaseSeverity) &&
          elem.cvssV3BaseSeverity !== "null") ||
        (!_.isEmpty(elem.cvssV2BaseSeverity) &&
          elem.cvssV2BaseSeverity !== "null")
      ) {
        return (
          <Tag
            style={{ borderRadius: 4, color: '#212121' }}
            color={getStrokeColor(
              elem.cvssV3BaseSeverity
                ? elem.cvssV3BaseSeverity
                : elem.cvssV2BaseSeverity
            )}
          >
            {elem.cvssV3BaseSeverity
              ? `${(elem.cvssV3BaseScore) ? elem.cvssV3BaseScore : ''} ${getSeverity(
                elem.cvssV3BaseSeverity
              )}`
              : `${(elem.cvssV2BaseScore) ? elem.cvssV2BaseScore : ''} ${getSeverity(
                elem.cvssV2BaseSeverity
              )}`}
          </Tag>
        );
      }
    }
    return <span style={{ borderRadius: 4, background: 'transparent', border: 'none' }} className="p-0">Not yet assigned</span>;
  };

  const getStrokeColor = (severity) => {
    if (severity === "HIGH") {
      return "#d67043";
    } else if (severity === "CRITICAL") {
      return "#da4a47";
    } else if (severity === "MEDIUM") {
      return "#f1a236";
    } else if (severity === "MODERATE") {
      return "#f1a236";
    } else if (severity === "LOW") {
      return "#e0e6b7";
    }
  };

  const getSeverity = (type) => {
    if (type === "MODERATE") {
      return "MEDIUM";
    }
    return type;
  };

  const columns = [
    {
      title: "CVE ID",
      dataIndex: "cveId",
      key: "cveId",
      width: "13%",
      render: (row) =>
        <a
          className="link-primary"
          href={`https://www.cve.org/CVERecord?id=${row}`}
          // onClick={() => {
          //   window.location = `/cve/${row}/${id==="all" ? '' : encodeURIComponent(id)}`;
          // }}
          target='_blank'
        >{row}</a>
    },
    {
      title: "Vulnerability type",
      dataIndex: "vulType",
      key: "vulType",
      width: "20%",
      ellipsis: true,
      render: (row) => {
        return <span className="text-truncate d-block" style={{ width: 300 }}>
          {!_.isEmpty(row) ? (
            <a
              className="link-primary"
              href={getTypeLink(!_.isEmpty(row[0]) ? row[0].cweId : null)}
              target={"_blank"}
              rel="noreferrer"
            >
              {row[0].cveType}
            </a>
          ) : (
            "Misc"
          )}
        </span>
      },
    },
    {
      title: "Severity",
      dataIndex: "cvssScore",
      key: "cvssScore",
      width: '12%',
      render: (row) => getCVSScore(row)
    },
  ];

  const data = [];
  if (!_.isEmpty(vulnerabilities)) {
    vulnerabilities.forEach((el, index) => {
      data.push({
        key: index,
        cveId: el.cveId,
        vulType: !_.isEmpty(el.cveTypes) ? el.cveTypes : [],
        cvssScore: {
          cvssV3BaseSeverity: !_.isEmpty(el.cvssV3BaseSeverity)
            ? el.cvssV3BaseSeverity
            : "",
          cvssV3BaseScore: !_.isEmpty(el.cvssV3BaseScore)
            ? el.cvssV3BaseScore
            : "",
          cvssV2BaseScore: !_.isEmpty(el.cvssV2BaseScore)
            ? el.cvssV2BaseScore
            : "",
          cvssV2BaseSeverity: !_.isEmpty(el.cvssV2BaseSeverity)
            ? el.cvssV2BaseSeverity
            : "",
        },
      });
    });
  }
  return <Table
    columns={columns}
    dataSource={data}
    pagination={{
      showTotal: (total, range) => {
        return `${range[0]}-${range[1]} of ${total && total.format()} items`;
      },
      itemRender: (_, type, originalElement) => {
        if (type === "prev") {
          return (
            <a>
              <FontAwesomeIcon icon={faChevronLeft} />
            </a>
          );
        }
        if (type === "next") {
          return (
            <a>
              <FontAwesomeIcon icon={faChevronRight} />
            </a>
          );
        }
        return originalElement;
      },
      position: ["bottom", "left"],
      className: "searchPagePagination px-2",
      pageSize: 5,
      pageSizeOptions: [5, 10, 15, 20],
    }}
    size="small"
    locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
  />;
};

export default Version