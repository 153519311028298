import React from 'react';
import { Tabs } from 'antd';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks';
import CodeMirror from '@uiw/react-codemirror';
import { markdown, markdownLanguage } from '@codemirror/lang-markdown';
import { languages } from '@codemirror/language-data';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';

const LINK_COLOR = '#3b82f6';

const formatContent = (content) => {
    if (!content) return 'No data available';
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {
                line.split(urlRegex).map((part, partIndex) => {
                    if (part.match(urlRegex)) {
                        return (
                            <a
                                key={partIndex}
                                href={part}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: LINK_COLOR }}
                            >
                                {part}
                            </a>
                        );
                    }
                    return part;
                })
            }
        </React.Fragment>
    ));
};

const codeMirrorWrapperStyle = {
    height: '300px',
    overflow: 'auto',
    overflowX: 'scroll',
};

const codeMirrorStyle = {
    minWidth: '100%',
    display: 'inline-block',
};

export const ViewDetailsModal = ({ details, cweId, visible, onClose, reportId, modalData }) => {
    // console.log("Modal Data: ", modalData, details);
    // console.log("Details", JSON.stringify(details));

    const renderCodeMirror = (content) => (
        <div className='card card-custom p-1' style={{ background: '#1e1e1e' }}>
            <div style={codeMirrorWrapperStyle}>
                <div style={codeMirrorStyle}>
                    <CodeMirror
                        value={content}
                        extensions={[markdown({ base: markdownLanguage, codeLanguages: languages })]}
                        theme={vscodeDark}
                        editable={false}
                        readOnly={true}
                        basicSetup={{
                            lineNumbers: false,
                            foldGutter: false,
                        }}
                    />
                </div>
            </div>
        </div>
    );

    const renderReferenceLinks = (referenceText) => {
        if (!referenceText) return null;

        const links = referenceText.split('\n').filter(link => link.trim() !== '');

        return (
            <ul>
                {links.map((link, index) => (
                    <li key={index}>
                        <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: LINK_COLOR }}>
                            {link}
                        </a>
                    </li>
                ))}
            </ul>
        );
    };

    const tabItems = [
        {
            key: '1',
            label: 'Request Body',
            children: (!_.isEmpty(modalData) && !_.isEmpty(modalData.message) && !_.isEmpty(modalData.message.requestBody)) ? renderCodeMirror(modalData.message.requestBody) : <div className='alert alert-info px-2 py-1'>No data found</div>
        },
        {
            key: '2',
            label: 'Request Headers',
            children: (!_.isEmpty(modalData) && !_.isEmpty(modalData.message) && !_.isEmpty(modalData.message.requestHeader)) ? renderCodeMirror(modalData.message.requestHeader) : <div className='alert alert-info px-2 py-1'>No data found</div>
        },
        {
            key: '3',
            label: 'Response Body',
            children: (!_.isEmpty(modalData) && !_.isEmpty(modalData.message) && !_.isEmpty(modalData.message.responseBody)) ? renderCodeMirror(modalData.message.responseBody) : <div className='alert alert-info px-2 py-1'>No data found</div>
        },
        {
            key: '4',
            label: 'Response Headers',
            children: (!_.isEmpty(modalData) && !_.isEmpty(modalData.message) && !_.isEmpty(modalData.message.responseHeader)) ? renderCodeMirror(modalData.message.responseHeader) : <div className='alert alert-info px-2 py-1'>No data found</div>
        }
    ];

    return (
        <>
            {!_.isEmpty(modalData) && (
                <Modal show={visible} onHide={onClose} size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>{`${cweId} Details`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                        <div className='border-bottom'>
                            <div className='p-3'>
                                <h5 className='mb-0'>Headers</h5>
                                <Tabs defaultActiveKey="1" items={tabItems} style={{ color: '#e0e0e0' }} />
                            </div>
                        </div>
                        <div className='p-3 border-bottom'>
                            <h5 className='mb-2'>Solution</h5>
                            <div>
                                <ReactMarkdown
                                    children={details.solution}
                                    remarkPlugins={[remarkBreaks]}
                                />
                            </div>
                        </div>
                        <div className='p-3 mb-3'>
                            <h5 className='mb-2'>References</h5>
                            <div>
                                {renderReferenceLinks(details.reference)}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};