import Modal from 'react-bootstrap/Modal';
import EditConfigPage from './editConfigPage';

export default function EditConfig({ showEditConfig, handleEditConfigClose, reportData }) {

    return (
        <>
            <Modal show={showEditConfig} onHide={handleEditConfigClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title as={'h5'}>Config for - {reportData.url}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditConfigPage fromModal={true} reportData={reportData} handleEditConfigClose={handleEditConfigClose} />
                </Modal.Body>
            </Modal>
        </>
    );
}