import React, { useEffect, useState } from 'react';
import ConnectedGraph from './connectedGraph';
import { getStrokeColor, getFontColor } from '../../shared/helper/genHelper';
import _ from 'lodash';
import { Switch } from 'antd';
import LabelConnected from './labelConnected';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faFilter } from '@fortawesome/free-solid-svg-icons';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import Dropdown from 'react-bootstrap/Dropdown';
import { Checkbox } from 'antd'
// import data1 from './data1.json';

export default function LinkedGraph({ dataSet, searchResults }) {

    const [data, setData] = useState([]);
    const [hideLabel, setHideLabel] = useState(false);
    const [filter, setFilter] = useState([]);

    const [showAllComponents, setShowAllComponents] = useState(false);

    useEffect(() => {
        // console.log("I am filter", filter)
        let newData = { nodes: [], links: [] };

        if (!_.isEmpty(dataSet)) {
            // console.log("Dataset", dataSet)
            let cweList = searchResults.cweCveCountMetadataList;
            // Module Loop
            dataSet.forEach((el, index) => {
                if (el.totalCve > 0) {


                    if (!_.isEmpty(el) && !_.isEmpty(el.scanResult)) {

                        // Packages loop (Components)
                        _.forIn(el.scanResult, (val, key) => {

                            if (!_.isEmpty(val) && !_.isEmpty(val.cves)) {
                                // cve - vulnerability loop
                                // console.log("I am the cve length::", val.cves.length);
                                val.cves.forEach((cve, ind) => {
                                    // if (!_.isEmpty(cve.cveTypes)) {

                                    //     cve.cveTypes.forEach((el) => {
                                    //         let cwe = el.cweId;
                                    //         if (!_.isEmpty(el.cveType)) {
                                    //             cwe = el.cveType;
                                    //         }
                                    //         newData.nodes.push({
                                    //             id: cwe,
                                    //             group: 3,
                                    //             level: 1,
                                    //             type: 'cwe'
                                    //         });
                                    //         newData.links.push({
                                    //             source: `${index}-${key}`,
                                    //             target: cwe,
                                    //             value: 3,
                                    //             type: 'cwe'
                                    //         });
                                    //         newData.links.push({
                                    //             source: cwe,
                                    //             target: cve.cveId,
                                    //             value: 3,
                                    //             type: 'cwe'
                                    //         });

                                    //     })

                                    // }
                                    // else {
                                    // newData.nodes.push({
                                    //     id: `${index}-${key}-misc`,
                                    //     group: 3,
                                    //     level: 1,
                                    //     type: 'cwe'
                                    // });
                                    // newData.links.push({
                                    //     source: `${index}-${key}-misc`,
                                    //     target: cve.cveId,
                                    //     value: 3,
                                    //     type: 'cwe'
                                    // });
                                    // newData.links.push({
                                    //     source: `${index}-${key}`,
                                    //     target: `${index}-${key}-misc`,
                                    //     value: 2,
                                    //     type: 'cwe'
                                    // });
                                    // }

                                    // newData.nodes.push({
                                    //     id: cve.cveId,
                                    //     group: 3,
                                    //     level: 1
                                    // });


                                    // if (!_.isEmpty(cve.cveTypes)) {
                                    //     // let cweListFound = {};
                                    //     cve.cveTypes.forEach((elem) => {
                                    //         let cwe = elem.cweId;
                                    //         console.log("cwe", cweList);
                                    //         let cweListFound = _.find(cweList, (eleme) => eleme.cweId === cwe);
                                    //         console.log("Found::", cweListFound);
                                    //         if (!_.isEmpty(cweListFound) && !_.isEmpty(cweListFound.cveIdList)) {
                                    //             cweListFound.cveIdList.forEach((elemen) => {
                                    //                 newData.links.push({
                                    //                     source: elemen,
                                    //                     target: cve.cveId,
                                    //                     value: 3,
                                    //                     type: 'cwe'
                                    //                 });
                                    //             })
                                    //         }

                                    //     });



                                    // }



                                    // if(index<=40){


                                    if (!_.isEmpty(filter) && !_.isEmpty(cve)) {
                                        let severity = (!_.isEmpty(cve) && cve.cvssV3BaseSeverity) ? cve.cvssV3BaseSeverity : cve.cvssV2BaseSeverity;
                                        if (_.includes(filter, severity)) {
                                            newData.links.push({
                                                source: `${index}-${key}`,
                                                // target: cve.cveId,
                                                target: `${index}-${key}-${cve.cveId}`,
                                                value: 3,
                                                type: 'cveNode',
                                                linkTyp: 'componenttocve'
                                            });
                                            newData.nodes.push({
                                                // id: (cve.cveId) ? cve.cveId : '',
                                                id: (cve.cveId) ? `${index}-${key}-${cve.cveId}` : '',
                                                group: 2,
                                                level: 1,
                                                type: 'cveNode',
                                                severity: severity,
                                            });
                                        }
                                    }
                                    else {
                                        newData.links.push({
                                            source: `${index}-${key}`,
                                            // target: cve.cveId,
                                            target: `${index}-${key}-${cve.cveId}`,
                                            value: 3,
                                            type: 'cveNode',
                                            linkTyp: 'componenttocve'
                                        });
                                        newData.nodes.push({
                                            // id: (cve.cveId) ? cve.cveId : '',
                                            id: (cve.cveId) ? `${index}-${key}-${cve.cveId}` : '',
                                            group: 2,
                                            level: 1,
                                            type: 'cveNode',
                                            severity: (!_.isEmpty(cve) && cve.cvssV3BaseSeverity) ? cve.cvssV3BaseSeverity : cve.cvssV2BaseSeverity,
                                        });
                                    }


                                    // }









                                })


                                if (showAllComponents === false) {


                                    newData.nodes.push({
                                        id: `${index}-${key}`,
                                        group: 1,
                                        level: 2,
                                        type: 'component',
                                        severity: ''
                                    });
                                    newData.links.push({
                                        source: (el.applicationLocation) ? el.applicationLocation.split('/').pop() : '',
                                        target: `${index}-${key}`,
                                        value: 2,
                                        type: 'cve',
                                        linkTyp: 'moduletocomponent'
                                    });
                                }

                            }
                            if (showAllComponents === true) {


                                newData.nodes.push({
                                    id: `${index}-${key}`,
                                    group: 1,
                                    level: 2,
                                    type: 'component',
                                    severity: ''
                                });
                                newData.links.push({
                                    source: (el.applicationLocation) ? el.applicationLocation.split('/').pop() : '',
                                    target: `${index}-${key}`,
                                    value: 2,
                                    type: 'cve',
                                    linkTyp: 'moduletocomponent'
                                });
                            }


                            // newData.links.push({
                            //     source: `${index}-${key}`,
                            //     target: `${index}-${key}-misc`,
                            //     value: 2
                            // });
                        })

                    }

                    newData.nodes.push({
                        id: (el.applicationLocation) ? el.applicationLocation.split('/').pop() : '',
                        group: 0,
                        level: 3,
                        type: 'module',
                        severity: ''
                    });
                    // newData.links.push({
                    //     source: el.applicationLocation.split('/').pop(),
                    //     target: `module.git`,
                    //     value: 2,
                    //     type: 'cve',
                    //     linkTyp: 'moduletocomponent'
                    // });
                }
            });
            // newData.nodes.push({
            //     id:  `module.git`,
            //     group: 0,
            //     level: 4,
            //     type: 'master',
            //     severity: ''
            // });

            const uniqueSet = new Set(newData.nodes.map(JSON.stringify)); // map to JSON string to compare objects
            const uniqueList = Array.from(uniqueSet).map(JSON.parse);

            // console.log("Linkes", JSON.stringify(newData['links']));


            let newnewData = [];

            newData.links.forEach((el) => {
                newnewData.push([el.source, el.target])
            })

            // console.log("data::", JSON.stringify(newnewData))

            // let newArray = _.uniq(newData.nodes, 'id');

            // console.log("Unique ", uniqueList)

            newData['nodes'] = uniqueList;

            // console.log("New Daa::", JSON.stringify(newData))

            if (!_.isEmpty(newData)) {
                // console.log("New Data::", JSON.stringify(newData))
                setData(newData)
            }
            else {
                setData({ nodes: [], links: [] })
            }
        }



    }, [dataSet, searchResults, filter, showAllComponents]);


    const handleLabelHide = (checked) => {
        // console.log(`switch to ${checked}`);
        setHideLabel(checked);
    };

    const onFilterChange = (e) => {
        // console.log(`checked = ${e.target.checked}`, e.target.value);
        if (e.target.checked) {
            setFilter(prev => [...prev, e.target.value]);
        }
        else {
            // console.log("I am here::")
            setFilter(prev => _.filter(prev, (el) => el !== e.target.value));
        }
    };

    const handleDropDownClick = (e) => {
        // console.log("I am clicked")
        e.preventDefault();
    }

    const onChange = (checked) => {
        // console.log(`switch to ${checked}`);
        setShowAllComponents(checked);
    };




    return (
        <div className=''>
            <div className='d-flex align-items-center justify-content-start p-2 pb-0'>
                {/* <button className='btn btn-outline-secondary text-white btn-sm'><FontAwesomeIcon className='me-2' icon={faFilter} />Filter</button> */}
                <Dropdown autoClose={'outside'}>
                    <Dropdown.Toggle variant="outline-secondary" className='text-white' size='sm'>
                        <FontAwesomeIcon className='me-2' icon={faFilter} />Filter
                    </Dropdown.Toggle>

                    <Dropdown.Menu>


                        <ul className="list-group m-0">
                            <li className="list-group-item p-2 legend-linkGraph border-0"><Checkbox className='me-2' checked={_.includes(filter, 'CRITICAL')} onClick={onFilterChange} value={'CRITICAL'}>Critical</Checkbox></li>
                            <li className="list-group-item p-2 legend-linkGraph border-0"><Checkbox className='me-2' checked={_.includes(filter, 'HIGH')} onClick={onFilterChange} value={'HIGH'}>High</Checkbox></li>
                            <li className="list-group-item p-2 legend-linkGraph border-0"><Checkbox className='me-2' checked={_.includes(filter, 'MEDIUM')} onClick={onFilterChange} value={'MEDIUM'}>Medium</Checkbox></li>
                            <li className="list-group-item p-2 legend-linkGraph border-0"><Checkbox className='me-2' checked={_.includes(filter, 'LOW')} onClick={onFilterChange} value={'LOW'}>Low</Checkbox></li>
                        </ul>



                        {/* <Dropdown.Item onClick={handleDropDownClick} as={'div'}><Checkbox className='me-2' checked={_.includes(filter, 'CRITICAL')} onClick={onFilterChange} value={'CRITICAL'}>Critical</Checkbox></Dropdown.Item>
                        <Dropdown.Item onClick={handleDropDownClick} as={'div'}><Checkbox className='me-2' checked={_.includes(filter, 'HIGH')} onClick={onFilterChange} value={'HIGH'}>High</Checkbox></Dropdown.Item>
                        <Dropdown.Item onClick={handleDropDownClick} as={'div'}><Checkbox className='me-2' checked={_.includes(filter, 'MEDIUM')} onClick={onFilterChange} value={'MEDIUM'}>Medium</Checkbox></Dropdown.Item>
                        <Dropdown.Item onClick={handleDropDownClick} as={'div'}><Checkbox className='me-2' checked={_.includes(filter, 'LOW')} onClick={onFilterChange} value={'LOW'}>Low</Checkbox></Dropdown.Item> */}
                    </Dropdown.Menu>
                </Dropdown>
                <Switch className='ms-3 me-1' checked={showAllComponents} onChange={onChange} /> show all components
            </div>
            <div className='d-flex align-items-center justify-content-end' style={{ position: 'relative' }}>
                {/* <Switch checked={hideLabel} onChange={handleLabelHide} className='me-2' /> {hideLabel ? 'Hide' : 'Show'} Label */}
                <div className='d-flex text-white' style={{ position: 'absolute', top: 0, right: 0 }}>
                    <ul className="list-group bg-transparent mt-3 me-3">
                        <li className="list-group-item bg-transparent text-white p-2 legend-linkGraph border-0"><span className='d-flex align-items-center justify-content-start' ><span className='d-flex align-items-center justify-content-center  me-2' ><FontAwesomeIcon icon={faCircle} style={{ color: GlobalConst.CHART_COLORS[0], position: 'absolute', zIndex: 0, fontSize: 20 }} /><span style={{ zIndex: 1 }} className='text-white'>M</span></span> Module </span></li>
                        <li className="list-group-item bg-transparent text-white p-2 legend-linkGraph border-0"><span className='d-flex align-items-center justify-content-start' ><span className='d-flex align-items-center justify-content-center  me-2' ><FontAwesomeIcon icon={faCircle} style={{ color: GlobalConst.CHART_COLORS[1], position: 'absolute', zIndex: 0, fontSize: 20 }} /><span style={{ zIndex: 1 }} className='text-white'>C</span></span> Component </span></li>
                        <li className="list-group-item bg-transparent text-white p-2 legend-linkGraph border-0"><span className='d-flex align-items-center justify-content-start' ><span className='d-flex align-items-center justify-content-center  me-2' ><FontAwesomeIcon icon={faCircle} style={{ color: GlobalConst.CHART_COLORS[12], zIndex: 0 }} /></span> Critical </span></li>
                        <li className="list-group-item bg-transparent text-white p-2 legend-linkGraph border-0"><span className='d-flex align-items-center justify-content-start' ><span className='d-flex align-items-center justify-content-center  me-2' ><FontAwesomeIcon icon={faCircle} style={{ color: GlobalConst.CHART_COLORS[13], zIndex: 0 }} /></span> High </span></li>
                        <li className="list-group-item bg-transparent text-white p-2 legend-linkGraph border-0"><span className='d-flex align-items-center justify-content-start' ><span className='d-flex align-items-center justify-content-center  me-2' ><FontAwesomeIcon icon={faCircle} style={{ color: GlobalConst.CHART_COLORS[14], zIndex: 0 }} /></span> Medium </span></li>
                        <li className="list-group-item bg-transparent text-white p-2 legend-linkGraph border-0"><span className='d-flex align-items-center justify-content-start' ><span className='d-flex align-items-center justify-content-center  me-2' ><FontAwesomeIcon icon={faCircle} style={{ color: GlobalConst.CHART_COLORS[15], zIndex: 0 }} /></span> Low </span></li>
                    </ul>

                </div>
            </div>
            {!_.isEmpty(data) ? <> <ConnectedGraph
                data={data}
                options={{
                    nodeId: d => d.id,
                    nodeGroup: d => d.group,
                    nodeLevel: d => d.level,
                    nodeTitle: d => `${d.id}`,
                    width: 1152,
                    height: 800,
                    // nodeStrength: -160,
                    // linkStrength: -120
                    // invalidation // a promise to stop the simulation when the cell is re-run
                }}
                hideLabel={hideLabel}
            /></> : ''}
            {/* <div className='mt-120'></div> */}
            {/* {!_.isEmpty(data) && 
                <LabelConnected data={data}
                    options={{
                        nodeId: d => d.id,
                        nodeGroup: d => d.group,
                        nodeLevel: d => d.level,
                        nodeTitle: d => `${d.id} (${d.group})`,
                        width: 1152,
                        height: 800,
                        // nodeStrength: -160,
                        // linkStrength: -120
                        // invalidation // a promise to stop the simulation when the cell is re-run
                    }}
                    hideLabel={hideLabel}
                />
            } */}
        </div>
    );
}
