import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Button, message } from 'antd';
import { useHistory } from "react-router-dom";
import _ from 'lodash';
import axios from "axios";
import moment from 'moment';


import sec1Logo from '../../../assets/img/sec1-logo-svg-orange.svg';
import loginBack from '../../../assets/img/login-background.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleExclamation, faClose, faRotateRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { GlobalConst } from "../../../shared/appConfig/globalConst";


export default function ForgotPasswordPage() {

    const history = useHistory();
    const [email, setEmail] = useState('');
    const [apiFired, setApiFired] = useState(false);
    const [changePassFired, setChangePassFired] = useState(false);

    const [otpReceived, setOtpReceived] = useState(false);
    // const [messageCount, setMessageCount] = useState(0);
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [resendDisabled, setResendDisabled] = useState(false);
    const [timer, setTimer] = useState(120);


    useEffect(() => {
        let intervalId;
        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else {
            setResendDisabled(false); // Enable resend button when timer reaches 0
        }

        return () => clearInterval(intervalId); // Clean up interval
    }, [timer]);

    const handleLoginClick = (e) => {
        history.push("/login");
    }

    const handleResendClick = (e) => {
        // setResendDisabled(true);
        // setTimer(120);
        requestOTP();
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (_.isEmpty(email)) {
            message.error('Please enter your email');
            return;
        }
        requestOTP();

    }

    const requestOTP = () => {
        setApiFired(true);
        const data = { "email": email, "otp_type": "forgot-password" };

        axios
            .post(`${GlobalConst.API_URL}/foss/user/generate-otp`, data)
            .then(op => {
                setApiFired(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && _.has(op.data, 'errorMessage') && !_.isEmpty(op.data.errorMessage)) {
                    message.error({
                        content: (
                            <div className="d-flex align-items-center">
                                {op.data.errorMessage}
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                        onClose: () => message.destroy(),
                    });
                }
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && _.has(op.data, 'isSuccess') && op.data.isSuccess === true) {
                    setOtpReceived(true);
                    setResendDisabled(true);
                    setTimer(120);
                }

            })
            .catch(e => {
                setApiFired(false);
                console.log("Exception: ", e);
                message.error({
                    content: (
                        <div className="d-flex align-items-center">
                            Undefined error occurred
                        </div>
                    ),
                    icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                    onClose: () => message.destroy(),
                });
            })
    }

    const handleChangePassword = (e) => {
        e.preventDefault();

        if (_.isEmpty(otp)) {
            message.error('Please enter OTP received.');
            return;
        }

        if (_.isEmpty(newPassword) || _.isEmpty(confirmPassword)) {
            message.error('Please enter both New Password and Confirm Password');
            return;
        }

        if (!_.isEqual(newPassword, confirmPassword)) {
            message.error('New Password and Confirm Password do not match');
            return;
        }

        let data = { "email": email, "password": newPassword, "verifyPassword": confirmPassword, "otp": otp };
        setChangePassFired(true);

        axios
            .post(`${GlobalConst.API_URL}/foss/user/update-password`, data)
            .then(op => {
                setChangePassFired(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && _.has(op.data, 'errorMessage') && !_.isEmpty(op.data.errorMessage)) {
                    message.error({
                        content: (
                            <div className="d-flex align-items-center">
                                {op.data.errorMessage}
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                        onClose: () => message.destroy(),
                    });
                }

                if (!_.isEmpty(op) && !_.isEmpty(op.data) && _.has(op.data, 'isSuccess') && op.data.isSuccess === true) {
                    message.success({
                        content: (
                            <div className="d-flex align-items-center">
                                Password changed, Please login to continue
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-success" icon={faCheckCircle} />,
                        onClose: () => message.destroy(),
                    });
                    history.push("/login");
                }


            })
            .catch(e => {
                setChangePassFired(false);
                console.log("Exception: ", e);
                message.error({
                    content: (
                        <div className="d-flex align-items-center">
                            Undefined error occurred
                        </div>
                    ),
                    icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                    onClose: () => message.destroy(),
                });
            })

    }

    const formattedTime = moment.utc(timer * 1000).format('mm:ss');

    return (
        <section>
            <Helmet bodyAttributes={{ class: 'login-back' }}>
                <title>Forgot Password | Scopy - Sec1.io</title>
            </Helmet>

            <section className="mb-8 vh-100 d-flex align-items-center justify-content-center bg-customDark">
                <div className="container">
                    <div className="row mt-lg-n10 mt-md-n11 mt-n10">
                        <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
                            <div className="card z-index-0 custom-card login-card mb-0" style={{ backgroundImage: `url(${loginBack})` }}>

                                <div className="card-body">
                                    <div className="justify-content-center">
                                        <div className="text-center mx-auto mt-4">
                                            <div className="login-logo-container">
                                                <img src={sec1Logo} alt="Sec1" />
                                            </div>
                                        </div>

                                        {
                                            otpReceived ?
                                                <div>
                                                    <h1 className="text-white mt-4 welcome-text mb-3">Create new password</h1>
                                                    <p>An OTP has been sent to your email <strong>{email}</strong> for password reset. Please check your inbox.</p>
                                                    <form onSubmit={handleChangePassword}>
                                                        <div className="mb-0">
                                                            <label htmlFor="txtotp" className="form-label" style={{ fontSize: 13 }}>OTP</label>
                                                            <input type="text" value={otp} onChange={(e) => { setOtp(e.target.value) }} className="form-control mb-2" id="txtotp" placeholder="Enter OTP" />
                                                            <div className="d-flex align-items-center justify-content-end">
                                                                <Button className="pe-0" type="link" onClick={handleResendClick} disabled={resendDisabled}><FontAwesomeIcon className="me-2" icon={faRotateRight} /> Resend OTP {(timer > 0) && <span className="ms-1">({formattedTime})</span>}</Button>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="txtnewpassword" className="form-label" style={{ fontSize: 13 }}>New Password</label>
                                                            <input type="password" value={newPassword} onChange={(e) => { setNewPassword(e.target.value) }} className="form-control" id="txtnewpassword" placeholder="Enter Password" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="txtconfirmpassword" className="form-label" style={{ fontSize: 13 }}>Confirm Password</label>
                                                            <input type="password" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} className="form-control" id="txtconfirmpassword" placeholder="Re-enter Password" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Button type="default" htmlType={'submit'} className="btn btn-primary-gradient w-100" loading={changePassFired}>Change Password</Button>
                                                        </div>
                                                    </form>
                                                    <div className="text-center mt-4" style={{ fontSize: 13 }}>Remember Password? <a href="#" onClick={handleLoginClick}>Login</a></div>
                                                </div>

                                                :

                                                <div>
                                                    <h1 className="text-white mt-4 welcome-text mb-3">Forgot your password?</h1>
                                                    <p className="text-lead text-white welcome-subtext mb-2">Please enter the email address associated with your account.</p>

                                                    <form onSubmit={handleFormSubmit}>
                                                        <div className="mb-3">
                                                            <input type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} className="form-control" id="txtEmail" placeholder="Enter Email Address" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Button type="default" htmlType={'submit'} className="btn btn-primary-gradient w-100" loading={apiFired}>Submit</Button>
                                                        </div>
                                                    </form>
                                                    <div className="text-center mt-4" style={{ fontSize: 13 }}>Remember Password? <a href="#" onClick={handleLoginClick}>Login</a></div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
}