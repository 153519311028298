import _ from 'lodash';
import aws from '../../assets/img/scopy-tech-icons/aws.svg';
import iam from '../../assets/img/awsIcons/Identity and Access Management.svg';
import config from '../../assets/img/awsIcons/Config.svg';
import s3 from '../../assets/img/awsIcons/Simple Storage Service.svg';
import ec2 from '../../assets/img/awsIcons/EC2.svg';
import vpc from '../../assets/img/awsIcons/Virtual Private Cloud.svg';
import ebs from '../../assets/img/awsIcons/Elastic Block Store.svg';
import kms from '../../assets/img/awsIcons/Key Management Service.svg';
import efs from '../../assets/img/awsIcons/EFS.svg';
import managment from '../../assets/img/awsIcons/Management Console.svg';
import networking from '../../assets/img/awsIcons/networking.svg';
import storage from '../../assets/img/awsIcons/storage.svg';
import security from '../../assets/img/awsIcons/Security Hub.svg';
import cloudFormation from '../../assets/img/awsIcons/CloudFormation.svg';
import role from '../../assets/img/awsIcons/role.svg';
import managementAndGovernance from '../../assets/img/awsIcons/management_and_governance.svg';
import user from '../../assets/img/awsIcons/user.svg';
import networkACL from '../../assets/img/awsIcons/network-acl.svg';
import subnet from '../../assets/img/awsIcons/subnet.svg';
import inboudOutbound from '../../assets/img/awsIcons/inbound-outbound-rules.svg';
import securityGroup from '../../assets/img/awsIcons/security-group.svg';
import networkInterface from '../../assets/img/awsIcons/network-interface.svg';

export function getAWSIcon(iconName) {

    let icon = (!_.isEmpty(iconName)) ? iconName.toLowerCase() : '';

    switch (icon) {

        case 'securitygroup':
            return iam;
        case 'iam':
            return iam;
        case 'regioniam':
            return iam;
        case 'aws_iam_user':
            return user;
        case 'config':
            return config;
        case 'aws_config':
            return config;
        case 's3':
            return s3;
        case 'ec2':
            return ec2;
        case 'aws_vpc':
            return vpc;
        case 'vpc':
            return vpc;
        case 'ebs':
            return ebs;
        case 'kms':
            return kms;
        case 'aws_kms':
            return kms;
        case 'efs':
            return efs;
        case 'management':
            return managementAndGovernance;
        case 'networking':
            return networking;
        case 'storage':
            return storage;
        case 'block_device':
            return storage;
        case 'security':
            return security;
        case 'cloudformation':
            return cloudFormation;
        case 'role':
            return role;
        case 'aws_network_acl':
            return networkACL;
        case 'networkacl':
            return networkACL;
        case 'subnet':
            return subnet;
        case 'inbound_rule':
            return inboudOutbound;
        case 'outbound_rule':
            return inboudOutbound;
        case 'aws_security_group':
            return securityGroup;
        case 'security_group':
            return securityGroup;
        case 'network_interface':
            return networkInterface;
        case 'user':
            return user;
        default:
            return aws;
    }

}