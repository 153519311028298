import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Alert, Space, Tag } from 'antd';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import Loader from '../../shared/sharedComponents/loader';
import { getFontColor, getStrokeColor } from '../../shared/helper/genHelper';
import _ from 'lodash';
import FileDirectory from './fileDirectory';
import NoDataFound from '../../shared/sharedComponents/noDataFound';

export default function ComplianceStandardTabView({ reportDetails, userPrefrences }) {

    const [showFileExplorerModal, setShowFileExplorerModal] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [selectedData, setSelectedData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${GlobalConst.API_URL}/auth/sast/asset/report/compliance-view`, {
                    reportId: reportDetails.reportId
                });
                if (response.data && response.data.categories && response.data.categories.length > 0) {
                    setData(response.data.categories);
                } else {
                    setError("No data available");
                }
            } catch (error) {
                setError(error.response?.data?.message || error.message || "An error occurred while fetching data");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [reportDetails.reportId]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Critical',
            dataIndex: 'critical',
            key: 'critical',
            align: "center",
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("CRITICAL"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("CRITICAL")}
                        className={"mx-auto text-center"}
                    >
                        {row.critical}
                    </Tag>
                </div>);
            }
        },
        {
            title: 'High',
            dataIndex: 'high',
            key: 'high',
            align: "center",
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("HIGH"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("HIGH")}
                        className={"mx-auto text-center"}
                    >
                        {row.high}
                    </Tag>
                </div>);
            }
        },
        {
            title: 'Medium',
            dataIndex: 'medium',
            key: 'medium',
            align: "center",
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("MEDIUM"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("MEDIUM")}
                        className={"mx-auto text-center"}
                    >
                        {row.medium}
                    </Tag>
                </div>);
            }
        },
        {
            title: 'Low',
            dataIndex: 'low',
            key: 'low',
            align: "center",
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("LOW"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("LOW")}
                        className={"mx-auto text-center"}
                    >
                        {row.low}
                    </Tag>
                </div>);
            }
        },
    ];

    const expandedRowRender = (record) => {
        const vulnerabilityColumns = [
            {
                title: 'File Name',
                dataIndex: 'fileName'
            },
            {
                title: 'Severity',
                dataIndex: 'severity',
                render: (index, row) => {
                    if (!_.isEmpty(row) && !_.isEmpty(row.severity)) {
                        return (
                            <Tag style={{ color: getFontColor(row.severity.toUpperCase()), fontWeight: 600, textTransform: 'capitalize' }} color={getStrokeColor(row.severity.toUpperCase())}>
                                {row.severity}
                            </Tag>);
                    }
                    else {
                        return '-';
                    }
                },
            },
            {
                title: 'Line Number',
                dataIndex: 'lineNumber',
                align: 'center'
            },
            {
                title: 'Title',
                dataIndex: 'title',
                render: (text) => <span title={text}>{text}</span>
            },
            {
                title: 'Action',
                render: (col, row) => {
                    return (
                        <Space size="middle">
                            <a href='#'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowFileExplorerModal(true);
                                    setSelectedData({ fileId: row.fileId, lineNumber: row.lineNumber });
                                }}
                            >View Detail</a>
                        </Space>
                    );
                },
            },
        ];

        return (
            <Table
                columns={vulnerabilityColumns}
                dataSource={record.vulnerabilityDetails.map((detail, index) => ({
                    ...detail,
                    id: index,
                    key: `${index}-${Math.random().toString(36).substr(2, 9)}`
                }))}
                pagination={false}
                rowKey="id"
                locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
            />
        );
    };

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return (
            <Alert
                message="Error"
                description={error}
                type="error"
                showIcon
            />
        );
    }

    return (
        <div>
            {data.length > 0 ? (
                <Table
                    size='small'
                    columns={columns}
                    dataSource={data}
                    className='custom-table mb-0'
                    rowKey="name"
                    expandable={{
                        expandedRowRender,
                        rowExpandable: (record) => record.vulnerabilityDetails && record.vulnerabilityDetails.length > 0,
                    }}
                    locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
                />
            ) : (
                <Alert
                    message="No Data"
                    description="No OWASP Top 10 data is available at the moment."
                    type="info"
                    showIcon
                />
            )}
            <FileDirectory setShowFileExplorerModal={setShowFileExplorerModal} showFileExplorerModal={showFileExplorerModal} reportDetails={reportDetails} selectedData={selectedData} userPrefrences={userPrefrences} />
        </div>
    );
}