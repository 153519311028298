import React, { useEffect, useState } from 'react';
import { Card, Tooltip, Tag, Divider } from 'antd';
import _ from 'lodash';
import { GlobalConst } from '../../../../shared/appConfig/globalConst';
import { faCheck, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VulnerabilityLegend = ({ reportDetails, onSelectFilter, selectedFilter }) => {

    const [legendCounts, setLegendCounts] = useState({
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
        kev: 0,
        zeroday: 0
    })

    useEffect(() => {
        if (!_.isEmpty(reportDetails) &&
            !_.isEmpty(reportDetails.scannerResponseEntity) &&
            !_.isEmpty(reportDetails.scannerResponseEntity.scanResult)) {
            const { CRITICAL: critical, HIGH: high, MEDIUM: medium, LOW: low } = reportDetails.scannerResponseEntity.scanResult[0].cveCountDetails
            let kev = 0
            let zeroday = 0

            _.forIn(reportDetails.scannerResponseEntity.scanResult[0].scanResult, (value, key) => {
                const kveCount = value.cves.reduce((count, cve) => {
                    if (cve.kev) {
                        return count + 1
                    }
                    return count
                }, 0)
                const zerodayCount = value.cves.reduce((count, cve) => {
                    if (cve.zeroDay) {
                        return count + 1
                    }
                    return count
                }, 0)
                kev += kveCount
                zeroday += zerodayCount
            })

            setLegendCounts({
                critical,
                high,
                medium,
                low,
                kev,
                zeroday
            })
        }
    }, [reportDetails])

    const vulnerabilityTypes = [
        {
            vulFilter: 'CRITICAL',
            name: 'CRITICAL',
            color: GlobalConst.CHART_COLORS[12],
            count: legendCounts.critical
        },
        {
            vulFilter: 'HIGH',
            name: 'HIGH',
            color: GlobalConst.CHART_COLORS[13],
            count: legendCounts.high
        },
        {
            vulFilter: 'MEDIUM',
            name: 'MEDIUM',
            color: GlobalConst.CHART_COLORS[14],
            count: legendCounts.medium
        },
        {
            vulFilter: 'LOW',
            name: 'LOW',
            color: GlobalConst.CHART_COLORS[15],
            count: legendCounts.low
        },
        {
            vulFilter: 'kev',
            name: 'Kev',
            color: GlobalConst.CHART_COLORS[12],
            count: legendCounts.kev
        },
        {
            vulFilter: 'zeroday',
            name: 'Zeroday',
            color: GlobalConst.CHART_COLORS[12],
            count: legendCounts.zeroday
        },
    ];

    return (
        <Card
            size="small"
            className="bg-dark bg-opacity-75 border-0"
        >
            <div className="d-flex flex-column">
                <div className="d-flex flex-column flex-wrap">
                    {vulnerabilityTypes.map(({ vulFilter, name, color, count }) => (
                        <button
                            className={`
                            d-flex align-items-center rounded btn btn-link p-1 py-0
                            ${selectedFilter.includes(vulFilter) ? '' : ''}
                          `}
                            onClick={() => onSelectFilter(vulFilter)} 
                            style={{ width: 115, textDecoration: "none" }}>
                            <div className='d-flex justify-content-between align-items-center w-100'>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <span style={{ width: 10 }} className='me-1'>
                                        {selectedFilter.includes(vulFilter) && (
                                            <FontAwesomeIcon style={{ fontSize: 12, color: "#a59b9b" }} icon={faCheck} />
                                        )}
                                    </span>
                                    <span>
                                        <small style={{ color: color }} className="ms-1">
                                            {_.startCase(name.toLowerCase())}
                                        </small>
                                    </span>
                                </div>
                                <div>
                                    <small style={{ color: color }} className="ms-2">{count}</small>
                                </div>

                            </div>
                            {/* <span style={{ width: 10 }} className="d-flex justify-content-center">
                                {selectedFilter.includes(vulFilter) && (
                                    <FontAwesomeIcon style={{fontSize: 12, color: "#a59b9b"}} icon={faCheck}/>
                                )}
                            </span>
                            <span className='d-flex justify-content-between align-items-center'>
                                <small style={{color: color}} className="ms-1">
                                    {_.startCase(name.toLowerCase())}
                                </small>
                                <small style={{color: color}}  className="ms-2">{count}</small>
                            </span> */}
                        </button>
                    ))}
                </div>
            </div>
        </Card>
    );
};

export default VulnerabilityLegend;