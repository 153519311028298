import React, { useEffect, useState, useRef } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Tooltip, Tag, Space, Table } from "antd";
import Highlighter from "react-highlight-words";

import _ from "lodash";
import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RiQrScan2Line } from "react-icons/ri";
import { AiOutlineDashboard } from "react-icons/ai";
import { MdAutoFixHigh } from "react-icons/md";
import { getStrokeColor, getFontColor } from "../../shared/helper/genHelper";
import Loader from "../welcomeScreen/loader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleQuestion,
  faFileCode,
  faFileZipper,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faDocker } from "@fortawesome/free-brands-svg-icons";

import awsCi from "../../assets/img/scopy-tech-icons/aws.svg";
import gcpCi from "../../assets/img/scopy-tech-icons/gcp.svg";
import azure from "../../assets/img/scopy-tech-icons/azure.svg";
import { getAssetIcon } from "../../shared/helper/getAssetImagesHelper";
import {
  setConScanPage,
  updateConScannedRecord,
  addContainerGuid,
} from "../../shared/redux/reducers/containerSecurityReducer";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import jfrog from "../../assets/img/scopy-tech-icons/jfrog.svg";
import ibm from "../../assets/img/scopy-tech-icons/ibm.svg";
import azureRepos from "../../assets/img/azure-repos.svg";
import googleCloud from "../../assets/img/scopy-tech-icons/gcp.svg";
import { GlobalConst } from "../../shared/appConfig/globalConst";

function ActionColumn({ columnText, row, index, reportId, scanRecord }) {
  // const history = useHistory();
  // console.log("Row: ", row);
  const [autofixBtnText, setAutofixBtnText] = useState({
    key: `1-${reportId}`,
    value: "1",
    label: "Fix all",
  });

  const items = [
    {
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/fix-vulnerabilities/${reportId}?type=1`}
        >
          Fix all
        </a>
      ),
      key: `1-${reportId}`,
      value: "1",
    },
    {
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/fix-vulnerabilities/${reportId}?type=2`}
        >
          Fix all critical
        </a>
      ),
      key: `2-${reportId}`,
      value: "2",
    },
    {
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/fix-vulnerabilities/${reportId}?type=3`}
        >
          Fix all critical and high
        </a>
      ),
      key: `3-${reportId}`,
      value: "3",
    },
  ];

  const handleMenuClick = (e) => {
    const selectedObject = _.find(items, { key: e.key });
    if (!_.isEmpty(selectedObject)) {
      setAutofixBtnText(selectedObject);
    } else {
      setAutofixBtnText({ key: `1-${reportId}`, value: "1", label: "Fix all" });
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-start">
      <a
        className={`me-3 details-hover-animate ${row.scanningRow ||
            row.rowStatus.toLowerCase() === "initiated" ||
            row.rowStatus.toLowerCase() === "scanning" ||
            row.assetType === "file"
            ? "disabled"
            : ""
          }`}
        href="#"
        onClick={(e) => {
          scanRecord(e, row);
        }}
      >
        {/* Rescan{" "} */}
        <Tooltip title={"Rescan"}>
          <RiQrScan2Line style={{ fontSize: 20 }} />
        </Tooltip>
      </a>
      <a
        className={`me-3 details-hover-animate ${row.scanningRow ||
            row.rowStatus.toLowerCase() === "failed" ||
            row.rowStatus.toLowerCase() === "initiated" ||
            row.rowStatus.toLowerCase() === "scanning"
            ? "disabled"
            : ""
          }`}
        href={`/container-security/${row.reportId}`}
        target="_blank"
      >
        {/* Adv Dashboard */}
        <Tooltip title="Advance Dashboard">
          <AiOutlineDashboard style={{ fontSize: 20 }} />
        </Tooltip>
      </a>
      {_.includes(row.ecosystem, "maven") && (
        <a
          className={`me-0 details-hover-animate ${row.scanningRow ||
              row.rowStatus.toLowerCase() === "failed" ||
              row.rowStatus.toLowerCase() === "initiated" ||
              row.rowStatus.toLowerCase() === "scanning"
              ? "disabled"
              : ""
            }`}
          href={`/fix-vulnerabilities/${reportId}`}
          target="_blank"
        >
          {/* Apply Auto Fix */}
          <Tooltip title="Apply Auto Fix">
            <MdAutoFixHigh style={{ fontSize: 20 }} />
          </Tooltip>
        </a>
      )}
    </div>
  );
}

export default function ConListScannedDataTable() {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);

  const [data, setData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [page, setPage] = useState(1);

  const searchInput = useRef(null);

  useEffect(() => {
    if (
      !_.isEmpty(reduxState.contSecurityReducer) &&
      !_.isEmpty(reduxState.contSecurityReducer.scannedContainers)
    ) {
      setData([...reduxState.contSecurityReducer.scannedContainers]);
    }
  }, [reduxState.contSecurityReducer.scannedContainers]);

  useEffect(() => {
    // console.log("I found that there is a change here");
    if (reduxState.contSecurityReducer.currentDashboardPageNumber) {
      setPage(reduxState.contSecurityReducer.currentDashboardPageNumber);
    }
  }, [reduxState.contSecurityReducer.currentDashboardPageNumber]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex, columnName, si) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={si}
          placeholder={`Search ${columnName}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            className="d-flex align-items-center justify-content-center"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                clearFilters();
                setSearchText("");
              }
              confirm({
                closeDropdown: false,
              });
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
      // style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => si.current?.select(), 100);
      }
    },
    render: (text, row) =>
      searchedColumn === dataIndex && !_.isEmpty(searchText)
        ? highlightText(searchedColumn, text, row)
        : getColumnText(dataIndex, text, row),
  });

  const getColumnText = (searchedColumn, text, row) => {
    // console.log("Row:", row)
    if (searchedColumn === "name") {
      // console.log("I am the row name::", row);
      if (row.rowStatus.toLowerCase() === "failed") {
        return (
          <span className="d-flex align-items-center">
            {!_.isEmpty(row) &&
              !_.isEmpty(row.url) &&
              isValidHttpUrl(row.url) ? (
              <a
                title={row.url}
                href={
                  row.subAssetType === "azure-scm"
                    ? `${row.url}?version=GB${row.branch}`
                    : row.url
                }
                target="_blank"
                style={{
                  color: "inherit",
                }}
              >
                {getAssetIcon(row.subAssetType)}
              </a>
            ) : (
              getAssetIcon(row.subAssetType)
            )}
            {row.assetType !== "file" ? (
              <>
                <a
                  title={row.url}
                  className="d-flex align-items-center"
                  style={{ overflow: "hidden" }}
                  href={`/container-security/${row.reportId}`}
                  target="_blank"
                >
                  <span
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {text}
                  </span>
                </a>{" "}
                <Tooltip title={row.errorMessage}>
                  <span>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="ms-2 text-danger"
                    />
                  </span>
                </Tooltip>
              </>
            ) : (
              <span title={row.url}>
                {!_.isEmpty(row) &&
                  !_.isEmpty(row.reportId) &&
                  row.type === "github" ? (
                  <a
                    href={`/container-security/${row.reportId}`}
                    target="_blank"
                  >
                    {text}
                  </a>
                ) : (
                  text
                )}{" "}
                <Tooltip title={row.errorMessage}>
                  <span>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="ms-2 text-danger"
                    />
                  </span>
                </Tooltip>
              </span>
            )}
          </span>
        );
      }
      return (
        <span>
          {/* <InboxOutlined className={'me-1'} /> <FontAwesomeIcon icon={faBoxOpen} />  */}
          {/* <img src={repo} style={{width: 16}} className="me-2" /> */}
          {!_.isEmpty(row) && !_.isEmpty(row.url) && isValidHttpUrl(row.url) ? (
            <a
              title={row.url}
              href={
                row.subAssetType === "azure-scm"
                  ? `${row.url}?version=GB${row.branch}`
                  : row.url
              }
              target="_blank"
              style={{
                color: "inherit",
              }}
            >
              {getRegistryAssetIcon(row.registryType)}
            </a>
          ) : (
            getRegistryAssetIcon(row.registryType)
          )}

          <span title={row.url} className="ps-2">
            {!_.isEmpty(row) ? getRowName(row, text) : text}
          </span>
        </span>
      );
      // return <h6>text</h6>;
    } else {
      return text;
    }
  };

  const isValidHttpUrl = (string) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };

  // const isUrl = (url) => {
  //   let name = "";
  //   if (isValidHttpUrl(url)) {
  //     var pathname = new URL(key).pathname;
  //     pathname = pathname.substring(1);
  //     name = pathname;
  //   } else {
  //     name = key;
  //   }
  // }

  const getRowName = (row, text) => {
    // if (row.assetType !== "file" && row.subAssetType !== 'archive') {
    return (
      <a href={`/container-security/${row.reportId}`} target="_blank">
        {text}
      </a>
    );
    // }
    // else {
    //   return text;
    // }
  };

  const highlightText = (searchedColumn, text, row) => {
    // console.log("Row:", row)

    if (searchedColumn === "name") {
      // return getHighlighted(text);
      if (row.rowStatus.toLowerCase() === "failed") {
        return (
          <span className="d-flex align-items-center">
            {!_.isEmpty(row) &&
              !_.isEmpty(row.url) &&
              isValidHttpUrl(row.url) ? (
              <a
                title={row.url}
                href={
                  row.subAssetType === "azure-scm"
                    ? `${row.url}?version=GB${row.branch}`
                    : row.url
                }
                target="_blank"
                style={{
                  color: "inherit",
                }}
              >
                {getAssetIcon(row.subAssetType)}
              </a>
            ) : (
              getAssetIcon(row.subAssetType)
            )}
            {row.assetType !== "file" ? (
              <>
                <a
                  title={row.url}
                  className="d-flex align-items-center"
                  style={{ overflow: "hidden" }}
                  href={`/container-security/${row.reportId}`}
                  target="_blank"
                >
                  <span
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {getHighlighted(text)}
                  </span>
                </a>{" "}
                <Tooltip title={row.errorMessage}>
                  <span>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="ms-2 text-danger"
                    />
                  </span>
                </Tooltip>
              </>
            ) : (
              <span title={row.url}>
                {!_.isEmpty(row) && row.type === "github" ? (
                  <a
                    href={`/container-security/${row.reportId}`}
                    target="_blank"
                  >
                    {text}
                  </a>
                ) : (
                  text
                )}{" "}
                <Tooltip title={row.errorMessage}>
                  <span>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="ms-2 text-danger"
                    />
                  </span>
                </Tooltip>
              </span>
            )}
          </span>
        );
      }
      return (
        <span>
          {/* <InboxOutlined className={'me-1'} />  */}
          {/* <img src={repo} style={{width: 16}} className="me-2" /> */}
          {!_.isEmpty(row) && !_.isEmpty(row.url) && isValidHttpUrl(row.url) ? (
            <a
              title={row.url}
              href={
                row.subAssetType === "azure-scm"
                  ? `${row.url}?version=GB${row.branch}`
                  : row.url
              }
              target="_blank"
              style={{
                color: "inherit",
              }}
            >
              {getAssetIcon(row.subAssetType)}
            </a>
          ) : (
            getAssetIcon(row.subAssetType)
          )}
          <span title={row.url}>
            {!_.isEmpty(row) && row.type === "github"
              ? getHighlightedRowName(row, text)
              : getHighlighted(text)}
          </span>
        </span>
      );

      // {getHighlighted(text)}</>;
    } else {
      return getHighlighted(text);
    }
  };

  const getHighlightedRowName = (row, text) => {
    if (row.assetType !== "file") {
      return (
        <a href={`/container-security/${row.reportId}`} target="_blank">
          {getHighlighted(text)}
        </a>
      );
    } else {
      return getHighlighted(text);
    }
  };

  const getHighlighted = (text) => {
    return (
      <Highlighter
        highlightStyle={{
          backgroundColor: "#ffc069",
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    );
  };

  const getGradientBack = (critical, high, medium, low) => {
    if (!critical && !high && !medium && !low) {
      return `linear-gradient(90deg, #da4a47 8%, #d67043 26% , #f1a236 58%, rgba(224,230,183,1) 92%)`;
    } else {
      let gradient = `linear-gradient(90deg,`;
      let myColorRow = [];

      if (critical) {
        myColorRow.push(`#da4a47`);
        gradient += `#da4a47 8%${high || medium || low ? "," : ""}`;
      }
      if (high) {
        myColorRow.push(`#d67043`);
        gradient += `#d67043 26%${medium || low ? "," : ""}`;
      }
      if (medium) {
        myColorRow.push(`#f1a236`);
        gradient += `#f1a236 58%${low ? "," : ""}`;
      }
      if (low) {
        myColorRow.push(`rgb(224,230,183)`);
        gradient += `rgb(224,230,183) 92%`;
      }

      gradient += `)`;

      if (myColorRow.length <= 1) {
        return myColorRow[0];
      }
      return gradient;
    }
  };

  const scanRecord = async (e, scanTriggeredRecord) => {
    // This will be triggered only when the user has clicked on re-scan link on the table.
    e.preventDefault();

    let isDemoUser = localStorage.getItem('isDemoUser');
    if (isDemoUser && isDemoUser === 'true') {
      alert("Scan has been disabled for this account");
    }
    else {
      // 1) Go through the records and get the current record on top of the array
      // 2) initiate the scan api
      // 3) get the response as guid and store it in the guid array
      // 4) poll against all the guids.

      if (!_.isEmpty(scanTriggeredRecord)) {
        let triggeredScanCopy = {
          ...scanTriggeredRecord,
          rowStatus: "INITIATED",
        };

        dispatch(updateConScannedRecord(triggeredScanCopy));

        // console.log("Updated records:", updatedScanRecords);
        // Modify the current triggered scan and make its status to INITIATED. once done update the redux state for scannedrecords.
        // return;
        // dispatch(setScannedRecords(updatedScanRecords)); // Update the redux state array to load.

        const requestBody = {
          urlType: scanTriggeredRecord.registryType,
          scanRequestList: [
            {
              location: scanTriggeredRecord.url,
              urlType: scanTriggeredRecord.registryType,
            },
          ],
        };
        // console.log("Started scan with Request body:", requestBody);
        // return;
        // 2) initiate the scan api

        // return;
        await axios
          .post(
            `${GlobalConst.API_URL}/auth/foss/ascan?enableSbom=true`,
            requestBody
          )
          .then(async (op) => {
            if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
              // 3) get the response as guid and store it in the guid array
              // get the guid array list from the redux and push the new guid onto the array so that we can poll against the guid added in the system.
              await updateGuids(op.data);
            }
          })
          .catch((e) => {
            console.log("Exception: ", e);
          });
      }
    }
  };

  const updateGuids = async (op) => {
    let guid = [];
    if (!_.isEmpty(op)) {
      op.forEach((el) => {
        if (!_.isEmpty(el)) {
          guid.push(el);
        }
      });
    }
    dispatch(addContainerGuid(guid));
  };

  const getRegistryAssetIcon = (registryType) => {
    const card = {
      alt: "title",
    };
    switch (registryType) {
      case "ibmcr":
        return (
          <Tooltip title="IBM">
            <img
              src={ibm}
              alt={"IBM"}
              className="img-fluid"
              style={{ width: 18 }}
            />
          </Tooltip>
        );
      case "jfrog":
        return (
          <Tooltip title="AWS">
            <img
              src={jfrog}
              alt={"Jfrog"}
              className="img-fluid"
              style={{ width: 18 }}
            />{" "}
          </Tooltip>
        );
      case "awsecr":
        return (
          <Tooltip title="AWS">
            <img
              src={awsCi}
              alt={"AWS"}
              className="img-fluid"
              style={{ width: 18 }}
            />{" "}
          </Tooltip>
        );
      case "azurecr":
        return (
          <Tooltip title="Azure">
            <img
              src={azureRepos}
              alt={"Azure"}
              className="img-fluid"
              style={{ width: 18 }}
            />{" "}
          </Tooltip>
        );
      case "dockerhub":
        return (
          <Tooltip title="Docker">
            <FontAwesomeIcon
              title="docker"
              icon={faDocker}
              style={{ width: 18 }}
            />{" "}
          </Tooltip>
        );
      case "gcr":
        return (
          <Tooltip title="Google">
            <img
              src={googleCloud}
              alt={"Google"}
              className="img-fluid"
              style={{ width: 18 }}
            />{" "}
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Unable to identify the artifact">
            <span>
              <FontAwesomeIcon icon={faCircleQuestion} />
            </span>
          </Tooltip>
        );
    }
  };

  const columns = [
    {
      title: "Sno",
      dataIndex: "serialNumber",
      width: 60,
      // sorter: (a, b) => a.serialNumber - b.serialNumber,
      // sortDirections: ['descend'],
      render: (val, row, index) => {
        return (page - 1) * 10 + index + 1;
      },
    },
    {
      title: "Artifact Name",
      dataIndex: "name",
      vAlign: "top",
      ...getColumnSearchProps("name", "name", searchInput),
      ellipsis: true,
    },
    {
      title: "Total",
      dataIndex: "total",
      width: 60,
      sorter: (a, b) => a.total - b.total,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) => {
        if (
          row.rowStatus.toLowerCase() === "initiated" ||
          row.rowStatus.toLowerCase() === "scanning"
        ) {
          // return (<div className='d-flex align-items-center justify-content-center'><Loader /></div>);
          return (
            <div className="d-flex align-items-center justify-content-center"></div>
          );
        }
        if (row.rowStatus.toLowerCase() === "failed") {
          return (
            <span
              style={{
                color: "#adb5bd",
                borderRadius: 50,
                width: 40,
                border: "none",
                boxShadow: "none",
                fontSize: 14,
              }}
              className={"me-0 text-center d-inline-block"}
            >
              -
            </span>
          );
        }
        return val ? (
          <span
            style={{
              color: "#adb5bd",
              borderRadius: 50,
              width: 40,
              border: "none",
              boxShadow: "none",
              fontSize: 14,
            }}
            className={"me-0 text-center d-inline-block"}
          >
            {row.total}
          </span>
        ) : (
          <span
            style={{
              color: "#adb5bd",
              borderRadius: 50,
              width: 40,
              border: "none",
              boxShadow: "none",
              fontSize: 14,
            }}
            className={"me-0 text-center d-inline-block"}
          >
            0
          </span>
        );
      },
    },
    {
      title: "Critical",
      dataIndex: "critical",
      width: 105,
      sorter: (a, b) => a.critical - b.critical,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) => {
        // console.log("I am the Row Status::", row.rowStatus);
        if (
          row.rowStatus.toLowerCase() === "initiated" ||
          row.rowStatus.toLowerCase() === "scanning"
        ) {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <Loader />
            </div>
          );
        }
        if (row.rowStatus.toLowerCase() === "failed") {
          return (
            <span
              style={{
                color: "#adb5bd",
                borderRadius: 50,
                width: 40,
                border: "none",
                boxShadow: "none",
                fontSize: 14,
              }}
              className={"me-0 text-center d-inline-block"}
            >
              -
            </span>
          );
        }
        if (val) {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("CRITICAL"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("CRITICAL")}
                className={"mx-auto text-center"}
              >
                {row.critical}
              </Tag>
            </div>
          );
        } else {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("CRITICAL"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("CRITICAL")}
                className={"mx-auto text-center"}
              >
                0
              </Tag>
            </div>
          );
        }
      },
    },
    {
      title: "High",
      dataIndex: "high",
      width: 85,
      sorter: (a, b) => a.high - b.high,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) => {
        if (
          row.rowStatus.toLowerCase() === "initiated" ||
          row.rowStatus.toLowerCase() === "scanning"
        ) {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <Loader />
            </div>
          );
        }
        if (row.rowStatus.toLowerCase() === "failed") {
          return (
            <span
              style={{
                color: "#adb5bd",
                borderRadius: 50,
                width: 40,
                border: "none",
                boxShadow: "none",
                fontSize: 14,
              }}
              className={"me-0 text-center d-inline-block"}
            >
              -
            </span>
          );
        }
        if (val) {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("HIGH"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("HIGH")}
                className={"mx-auto text-center"}
              >
                {row.high}
              </Tag>
            </div>
          );
        } else {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("HIGH"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("HIGH")}
                className={"mx-auto text-center"}
              >
                0
              </Tag>
            </div>
          );
        }
      },
    },
    {
      title: "Medium",
      dataIndex: "medium",
      width: 105,
      sorter: (a, b) => a.medium - b.medium,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) => {
        if (
          row.rowStatus.toLowerCase() === "initiated" ||
          row.rowStatus.toLowerCase() === "scanning"
        ) {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <Loader />
            </div>
          );
        }
        if (row.rowStatus.toLowerCase() === "failed") {
          return (
            <span
              style={{
                color: "#adb5bd",
                borderRadius: 50,
                width: 40,
                border: "none",
                boxShadow: "none",
                fontSize: 14,
              }}
              className={"me-0 text-center d-inline-block"}
            >
              -
            </span>
          );
        }
        if (val) {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("MEDIUM"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("MEDIUM")}
                className={"mx-auto text-center"}
              >
                {row.medium}
              </Tag>
            </div>
          );
        } else {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("MEDIUM"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("MEDIUM")}
                className={"mx-auto text-center"}
              >
                0
              </Tag>
            </div>
          );
        }
      },
    },
    {
      title: "Low",
      dataIndex: "low",
      width: 95,
      sorter: (a, b) => a.low - b.low,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) => {
        if (
          row.rowStatus.toLowerCase() === "initiated" ||
          row.rowStatus.toLowerCase() === "scanning"
        ) {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <Loader />
            </div>
          );
        }
        if (row.rowStatus.toLowerCase() === "failed") {
          return (
            <span
              style={{
                color: "#adb5bd",
                borderRadius: 50,
                width: 40,
                border: "none",
                boxShadow: "none",
                fontSize: 14,
              }}
              className={"me-0 text-center d-inline-block"}
            >
              -
            </span>
          );
        }
        if (val) {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("LOW"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("LOW")}
                className={"mx-auto text-center"}
              >
                {row.low}
              </Tag>
            </div>
          );
        } else {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("LOW"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("LOW")}
                className={"mx-auto text-center"}
              >
                {0}
              </Tag>
            </div>
          );
        }
      },
    },

    {
      title: "NA",
      dataIndex: "na",
      width: 95,
      sorter: (a, b) => a.na - b.na,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) => {
        if (
          row.rowStatus.toLowerCase() === "initiated" ||
          row.rowStatus.toLowerCase() === "scanning"
        ) {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <Loader />
            </div>
          );
        }
        if (row.rowStatus.toLowerCase() === "failed") {
          return (
            <span
              style={{
                color: "#adb5bd",
                borderRadius: 50,
                width: 40,
                border: "none",
                boxShadow: "none",
                fontSize: 14,
              }}
              className={"me-0 text-center d-inline-block"}
            >
              -
            </span>
          );
        }
        if (val) {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("NA"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("NA")}
                className={"mx-auto text-center"}
              >
                {row.na}
              </Tag>
            </div>
          );
        } else {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("NA"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("NA")}
                className={"mx-auto text-center"}
              >
                {0}
              </Tag>
            </div>
          );
        }
      },
    },

    {
      title: "Last Scan",
      dataIndex: "",
      width: 150,
      sorter: (a, b) => moment(a.scanDate).unix() - moment(b.scanDate).unix(),
      sortDirections: ["ascend", "descend"],
      render: (val, row) => {
        if (
          row.rowStatus.toLowerCase() === "initiated" ||
          row.rowStatus.toLowerCase() === "scanning"
        ) {
          // return (<div className='d-flex align-items-center justify-content-start ps-2'><Loader /></div>);
          return (
            <div className="d-flex align-items-center justify-content-start ps-2"></div>
          );
        }
        return moment.utc(row.scanDate).local().fromNow();
      },
      ellipsis: true,
    },
    {
      title: "Actions",
      dataIndex: "",
      width: 100,
      render: (val, row, index) => {
        return (
          <ActionColumn
            columnText={val}
            row={row}
            index={index}
            reportId={row.reportId}
            scanRecord={scanRecord}
          />
        );
      },
      fixed: "right",
    },
  ];

  return (
    <div className="mt-3">
      {!_.isEmpty(reduxState.contSecurityReducer.scannedContainers) && (
        <div style={{ overflow: "auto" }}>
          <Table
            size={"small"}
            columns={columns}
            dataSource={reduxState.contSecurityReducer.scannedContainers}
            // sticky={{ offsetHeader: 0 }}
            // rowSelection={{}}

            className="custom-table mb-0 fixedScrollCustomTable"
            // size="small"
            pagination={{
              showTotal: (total, range) => {
                return (
                  <span className="text-white">
                    {range[0]}-{range[1]} of {total && total.format()} Images
                  </span>
                );
              },
              current: page,
              onChange(current) {
                // setPage(current);
                dispatch(setConScanPage(current));
              },
              // hideOnSinglePage: true,
              defaultPageSize: 10,
              showSizeChanger: true,
              position: ["bottom", "left"],
              className: "searchPagePagination px-0",
              pageSizeOptions: [10, 20, 25],
            }}
            locale={{ emptyText: <NoDataFound title={"No Data"} /> }}
          />
        </div>
      )}
    </div>
  );
}
