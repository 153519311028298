
import { Tag, Space, Table } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getFontColor, getStrokeColor } from '../../shared/helper/genHelper';
// import NoDataFound from './noDataFound';
import { ViewDetailsModal } from '../../shared/sharedComponents/dastSharedComponents/viewDetailsModalHelper';
import Loader from '../../shared/sharedComponents/loader';
import axios from 'axios';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import NoDataFound from '../../shared/sharedComponents/noDataFound';

const expandedRowRender = (props, setIsModalVisible, setSelectedData, getDetails) => {
    let subData = [];
    // console.log("Props", props);
    const columns = [
        {
            title: 'CWE ID',
            dataIndex: 'cweId',
            key: 'cweId',
            width: '10%',
        },
        {
            title: 'Vulnerability Title',
            key: 'vulTitle',
            dataIndex: 'vulTitle',
            width: '30%',
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
            align: 'center',
            width: '12%',
            render: (index, row) => {
                if (!_.isEmpty(row) && !_.isEmpty(row.severity)) {
                    return (
                        <Tag style={{ color: getFontColor(row.severity.toUpperCase()), fontWeight: 600, textTransform: 'capitalize' }} color={getStrokeColor(row.severity.toUpperCase())}>
                            {row.severity}
                        </Tag>);
                }
                else {
                    return '-';
                }
            },
        },
        {
            title: 'View Details',
            dataIndex: 'details',
            key: 'details',
            align: 'center',
            width: '12%',
            render: (col, row) => {
                return (
                    <Space size="middle">
                        <a href='#'
                            onClick={(e) => {
                                e.preventDefault();
                                // console.log("I am tjhe row", row);
                                getDetails(row.details.dbMessageId);
                                // setIsModalVisible(true);
                                // console.log("I am the row", row);
                                setSelectedData(row);
                            }}
                        >View Detail</a>
                    </Space>
                );
            },
        },
    ];

    if (!_.isEmpty(props) && !_.isEmpty(props.vulnerabilities)) {
        let newData = [];

        props.vulnerabilities.forEach((row, i) => {
            // console.log("Row", row)
            newData.push({
                key: i.toString(),
                cweId: `CWE-${row.id}`,
                vulTitle: row.title,
                severity: row.severity,
                urlId: props.url,
                details: row
            })
        });
        // console.log("newData", newData);
        subData = newData;
    }
    else {
        subData = [];
    }
    return <Table columns={columns} dataSource={subData} pagination={false} locale={{ emptyText: <NoDataFound title={'No Data'} /> }} />;
};

export default function DastFileTabView({ reportDetails, userPrefrences, reportId }) {

    const [data, setData] = useState([]);

    const [selectedData, setSelectedData] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [modalData, setModalData] = useState(false);




    const columns = [
        {
            title: <><span className='me-3'>Path</span> </>,
            dataIndex: 'url',
            key: 'url',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'center'
        },
        {
            title: 'Critical',
            dataIndex: 'critical',
            key: 'critical',
            align: 'center',
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("CRITICAL"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("CRITICAL")}
                        className={"mx-auto text-center"}
                    >
                        {row.critical}
                    </Tag>
                </div>);
            }
        },
        {
            title: 'High',
            dataIndex: 'high',
            key: 'high',
            align: 'center',
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("HIGH"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("HIGH")}
                        className={"mx-auto text-center"}
                    >
                        {row.high}
                    </Tag>
                </div>);
            }
        },
        {
            title: 'Medium',
            dataIndex: 'medium',
            key: 'medium',
            align: 'center',
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("MEDIUM"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("MEDIUM")}
                        className={"mx-auto text-center"}
                    >
                        {row.medium}
                    </Tag>
                </div>);
            }
        },
        {
            title: 'Low',
            dataIndex: 'low',
            key: 'low',
            align: 'center',
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("LOW"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("LOW")}
                        className={"mx-auto text-center"}
                    >
                        {row.low}
                    </Tag>
                </div>);
            }
        }
    ];

    useEffect(() => {
        if (!_.isEmpty(reportDetails) && !_.isEmpty(reportDetails.fileSummary) && !_.isEmpty(reportDetails.fileSummary.fileDetail)) {
            let newData = [];
            const fileDetails = reportDetails.fileSummary.fileDetail;
            {
                Object.entries(fileDetails).map(([key, value]) => (
                    newData.push({
                        key: key,
                        url: value.url,
                        total: value.count,
                        critical: value.vulnerabilityCounts.critical,
                        high: value.vulnerabilityCounts.high,
                        medium: value.vulnerabilityCounts.medium,
                        low: value.vulnerabilityCounts.low,
                        vulnerabilities: value.occurrences,
                        urlId: value.url,
                        cweId: value.cweId
                    })
                ))
            };
            // console.log("reportDetails", reportDetails);
            setData(newData);
        }
        else {
            setData([]);
        }
    }, [reportDetails])

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setSelectedData(null);
    };

    const getDetails = (dbMessageId) => {

        setShowLoader(true);
        const postBody = {
            "dbMessageId": dbMessageId,
            "reportId": reportId
        };
        axios
            .post(`${GlobalConst.API_URL}/auth/dast/message`, postBody)
            .then(op => {
                // console.log("Output: ", op);
                setShowLoader(false);
                setIsModalVisible(true);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setModalData(op.data);
                }

            })
            .catch(e => {
                setShowLoader(false);
                console.log("Exception: ", e);
            })
    }

    if (_.isEmpty(data)) {
        return (<NoDataFound title={"No Data Available"} desc={'There are currently no urls to display. Please initiate a scan.'} />);
    }
    else {
        return (
            <section className="my-2">
                <div>
                    <div className='d-flex align-items-center justify-content-start mb-0'></div>
                    <Table
                        className='custom-table'
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record) => expandedRowRender(record, setIsModalVisible, setSelectedData, getDetails),
                        }}
                        dataSource={data}
                        size="small"
                        locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
                    />
                    {isModalVisible && <ViewDetailsModal
                        details={selectedData ? selectedData.details : undefined}
                        cweId={selectedData ? selectedData.cweId : undefined}
                        visible={isModalVisible}
                        onClose={handleCloseModal}
                        reportId={reportId}
                        modalData={modalData}
                    />}
                </div>
                {showLoader && <Loader />}
            </section>

        )
    }
}