import React, { useEffect, useState, useRef } from 'react';
import { Table, Tag, Dropdown, Space, Menu, Typography, Popover, Button, Checkbox, Input } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus, faSquareMinus } from '@fortawesome/free-regular-svg-icons';
import { faCaretRight, faCaretDown, faFilter } from '@fortawesome/free-solid-svg-icons';
import { SearchOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { getStrokeColor, getFontColor } from '../../shared/helper/genHelper';
import { getArtifactImage } from '../../shared/helper/getArtifactImageHelper';
import ComponentExpandedTable from './componentExpandedTable';
import _ from 'lodash';
import NoDataFound from '../../shared/sharedComponents/noDataFound';

const expandedRowRender = (props) => {


    // const compareCVE = (a, b) => {
    //     const regex = /CVE-(\d{4})-(\d+)/;
    //     const aMatch = a.match(regex);
    //     const bMatch = b.match(regex);
    //     const aYear = parseInt(aMatch[1]);
    //     const aNumber = parseInt(aMatch[2]);
    //     const bYear = parseInt(bMatch[1]);
    //     const bNumber = parseInt(bMatch[2]);
    //     if (aYear !== bYear) {
    //         return aYear - bYear;
    //     }
    //     return aNumber - bNumber;
    // }

    // const columns = [
    //     // {
    //     //     title: 'S. No.',
    //     //     dataIndex: 'serialNo',
    //     //     key: 'serialNo',
    //     //     width: '7%'
    //     // },
    //     {
    //         title: 'Vulnerability',
    //         dataIndex: 'vulType',
    //         key: 'vulType',
    //         width: '30%',
    //         ellipsis: true,
    //     },
    //     {
    //         title: 'Severity',
    //         dataIndex: 'severity',
    //         key: 'severity',
    //         render: (val, row) => <Tag style={{ color: '#212121' }} color={getStrokeColor(row.severity)}>{row.score} {row.severity}</Tag>,
    //         width: '13%'
    //     },
    //     {
    //         title: 'CVE ID',
    //         dataIndex: 'cveId',
    //         key: 'cveId',
    //         render: (val) => <a href={`/cve/${val}/?s=${val}`} target={'_blank'} rel="noreferrer">{val}</a>,
    //         sorter: (a, b) => { return compareCVE(a.cveId, b.cveId) },
    //         sortDirections: ['ascend', 'descend'],
    //         width: '15%'
    //     },
    //     {
    //         title: '',
    //     }
    // ];
    // const data = [];
    // if (!_.isEmpty(props) && !_.isEmpty(props.cveDetails)) {
    //     props.cveDetails.forEach((elem, index) => {
    //         data.push({
    //             key: `${index}-${elem.cveId}`,
    //             serialNo: index + 1,
    //             cveId: elem.cveId,
    //             score: (elem.cvssV3BaseSeverity) ? elem.cvssV3BaseScore : elem.cvssV2BaseScore,
    //             severity: (elem.cvssV3BaseSeverity) ? elem.cvssV3BaseSeverity : elem.cvssV2BaseSeverity,
    //             vulType: (!_.isEmpty(elem.cveTypes)) ? elem.cveTypes[0].cveType : 'Unknown'
    //         })
    //     })
    // }

    // const getPaginationStatus = (dataState) => {
    //     if (!_.isEmpty(dataState)) {
    //         if (dataState.length > 10) {
    //             return {
    //                 showTotal: (total, range) => <span className='text-white'>{range[0]}-{range[1]} of {total && total.format()} items</span>,
    //                 position: ["bottom", "left"],
    //                 className: "searchPagePagination extendedPagePagination",
    //                 defaultPageSize: 5,
    //                 pageSizeOptions: [5, 10, 20, 50],
    //             };
    //         }
    //         else {
    //             return false;
    //         }
    //     }
    //     else {
    //         return false;
    //     }
    // }

    // return <Table
    //     size="middle"
    //     // className="table-striped-rows"
    //     className='component-view-table table table-striped custom-table mb-0'
    //     columns={columns}
    //     dataSource={data}
    //     pagination={getPaginationStatus(data)}
    // />;
    return <ComponentExpandedTable {...props} />;
};

const GetRecFixRow = ({ val, row, getCount }) => {

    const [selectedVersion, setSelectedVersion] = useState(val[0]);
    let count = getCount(row);

    const getDropdownMenuItems = (stableVersionList) => {
        let menuItems = [];
        if (!_.isEmpty(stableVersionList)) {
            stableVersionList.forEach((elem, index) => {
                menuItems.push({
                    key: `${elem}`,
                    label: `${elem}`
                })
            })
        }
        return menuItems;
    }

    const getCountStatement = (count) => {
        if (!_.isEmpty(count)) {
            let c = getIntroduceCount();
            return (<small className='text-muted d-block'>
                (this will fix {count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW} vulnerabilit{(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW) > 1 ? 'ies' : 'y'}
                {(c) ? ` and will introduce ${c} vulnerabilit${c > 1 ? 'ies' : 'y'}` : ''})
            </small>);
        }
    }

    const getIntroduceCount = () => {
        if (!_.isEmpty(row) && !_.isEmpty(row.introducedCveDetails)) {
            let objectVal = row.introducedCveDetails[selectedVersion];
            if (!_.isEmpty(objectVal) && !_.isEmpty(objectVal.cveCountDetails)) {
                let c = 0;
                _.forIn(objectVal.cveCountDetails, (val, key) => c = c + val)
                return c;
            }
            else {
                return 0;
            }
        }
        else {
            return 0;
        }
    }

    const handleDropdownMenuClick = ({ key }) => {
        if (!_.isEmpty(key) && selectedVersion !== key) {
            setSelectedVersion(key);
        }
    };

    if (_.isEmpty(val)) {
        return <span className='text-danger'>No fix available</span>
    }

    if (!_.isEmpty(val) && val.length > 1) {
        return (<>
            <Dropdown overlay={<Menu selectable defaultSelectedKeys={[selectedVersion]} onClick={handleDropdownMenuClick} items={getDropdownMenuItems(val)} className={'versionMenuList scroll-style'} />} trigger={['click']}>
                <Typography.Link>
                    <Space>
                        <span className='text-white' style={{ display: 'flex', maxWidth: '12rem', alignItems: 'center' }}>
                            <span className="text-truncate text-white" style={{ fontSize: 14, fontFamily: 'Open Sans' }}>{selectedVersion}</span>
                            <CaretDownOutlined className="ms-1" />
                        </span>
                    </Space>
                </Typography.Link>
            </Dropdown>{getCountStatement(count)}</>);
    }
    else {
        return <span>{val[0]} {getCountStatement(count)}</span>;
    }
}


const DataTable = ({ dataSet, filter, selectedFile, handleSeverityFilterChange }) => {

    const [data, setData] = useState([]);
    const [selectedApplication, setSelectedApplication] = useState('');
    const [totalCount, setTotalCount] = useState({ CRITICAL: 0, HIGH: 0, MEDIUM: 0, LOW: 0, NA: 0 });

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef(null);

    const getPackageDetails = (val, type, artifact) => {
        if (val) {
            let splitedPkg = val.split(":");
            switch (type) {
                case "artifact":
                    let sText = splitedPkg[1].split('/');
                    return sText[2];
                case "group":
                    let splitedText = splitedPkg[1].split('/');
                    return splitedText[1];
                case 'getEcosystem':
                    let ecosystem = splitedPkg[1].split('/');
                    return ecosystem[0];
                case 'packageName':
                    // if (artifact === "maven") {
                    //     let strSplit = splitedPkg[1].split('/');
                    //     return `${strSplit[0]}/${strSplit[1]}:${strSplit[2]}`;
                    // }
                    // else {
                    //     return splitedPkg[1].split('/').pop();
                    // }
                    return splitedPkg[1];
                case 'getVersion':
                    let version = splitedPkg[1].split('@')
                    return version[1];
                default:
                    return splitedPkg[2];
            }
        }
        else {
            return ('');
        }
    }

    useEffect(() => {
        if (!_.isEmpty(dataSet)) {
            let newData = [];
            let dataSetDuplicate = [...dataSet];
            let newCve = [], count = {};

            // console.log("I am the dataset::", dataSet);

            // if (selectedFile !== 'all') {
            //     dataSetDuplicate = [...dataSet];
            //     // console.log(dataSetDuplicate[0].applicationLocation, dataSetDuplicate.length)


            // }
            // if (!_.isEmpty(dataSetDuplicate) && dataSetDuplicate.length >= 1 && dataSetDuplicate[0].applicationLocation) {
            //     setSelectedApplication(dataSetDuplicate[0].applicationLocation);
            // }
            // console.log("I am dast euplicatio", dataSetDuplicate)
            dataSetDuplicate.forEach((application, index) => {
                if (!_.isEmpty(application) && !_.isEmpty(application.scanResult)) {
                    newCve = [];
                    setTotalCount({ CRITICAL: 0, HIGH: 0, MEDIUM: 0, LOW: 0, NA: 0, ...application.cveCountDetails });
                    _.forIn(application.scanResult, (value, key) => {
                        // Code for cve Filter based on the selected severity
                        if (!_.isEmpty(filter)) {
                            newCve = _.filter(value.cves, (o) => (o.cvssV3BaseSeverity) ? _.includes(filter, o.cvssV3BaseSeverity) : _.includes(filter, o.cvssV2BaseSeverity))
                        }
                        else {
                            newCve = value.cves;
                        }
                        if (!_.isEmpty(newCve)) {
                            count = getCount({ cveDetails: [...newCve] });
                            newData.push({
                                key: `${index}-${key}`,
                                ecosystem: getPackageDetails(key, 'getEcosystem'),
                                packageName: getPackageDetails(key, 'packageName', application.scanType),
                                vulnerableVersion: getPackageDetails(key, 'getVersion'),
                                recomendedFix: (!_.isEmpty(value) && !_.isEmpty(value.stableRecommendedVersionList)) ? value.stableRecommendedVersionList : value.recommendedVersionList,
                                cveDetails: [...newCve],
                                introducedCveDetails: value.introducedCveDetails,
                                // key: `${index}-${key}`,
                                // ecosystem: getPackageDetails(key, 'getEcosystem'),
                                // packageName: getPackageDetails(key, 'packageName', application.scanType),
                                // vulnerableVersion: getPackageDetails(key, 'getVersion'),
                                // recomendedFix: (!_.isEmpty(value) && !_.isEmpty(value.stableRecommendedVersionList)) ? value.stableRecommendedVersionList : value.recommendedVersionList,
                                // cveDetails: [...newCve],
                                // introducedCveDetails: value.introducedCveDetails,
                                scanFileLocation: application.applicationLocation,
                                critical: count.CRITICAL,
                                medium: count.MEDIUM,
                                high: count.HIGH,
                                low: count.LOW,
                                total: (count.CRITICAL + count.MEDIUM + count.HIGH + count.LOW),
                                na: count.NA








                            });
                        }
                    })
                }
            });


            const newOptions = _.orderBy(newData, ['critical', 'high', 'medium', 'low', 'na'], ['desc', 'desc', 'desc', 'desc', 'desc']);
            setData(newOptions);
        }
    }, [dataSet, selectedFile, filter]);

    const getCount = (rowData) => {
        let count = { CRITICAL: 0, HIGH: 0, MEDIUM: 0, LOW: 0, NA: 0 };
        if (!_.isEmpty(rowData) && !_.isEmpty(rowData.cveDetails)) {
            rowData.cveDetails.forEach((elem) => {
                if (!_.isEmpty(elem)) {
                    if (!_.isEmpty(elem.cvssV3BaseSeverity)) {
                        count[elem.cvssV3BaseSeverity] = count[elem.cvssV3BaseSeverity] + 1;
                    }
                    else {
                        if (!_.isEmpty(elem.cvssV2BaseSeverity)) {
                            count[elem.cvssV2BaseSeverity] = count[elem.cvssV2BaseSeverity] + 1;
                        }
                        else{
                            count["NA"] = count["NA"] + 1;
                        }
                    }
                }
            });
        }
        return count;
    }


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };


    const getColumnSearchProps = (dataIndex, columnName) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close, visible }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${columnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, visible)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex, visible)}
                        icon={<SearchOutlined />}
                        className="d-flex align-items-center justify-content-center"
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            if (clearFilters) {

                                clearFilters();
                                setSearchText('');
                            }
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchedColumn(dataIndex);
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, row) =>
            (searchedColumn === dataIndex && !_.isEmpty(searchText)) ? (
                highlightText(searchedColumn, text, row)
            ) : (
                getColumnText(dataIndex, text, row)
            ),
    });

    const getColumnText = (searchedColumn, text, row) => {
        if (searchedColumn === 'packageName') {

            if (!_.isEmpty(text)) {
                let [artifact, componentRaw] = text.split(/\/(.*)/s);
                let component = '';
                let imgTitle = '';
                if (artifact === 'maven') {
                    component = _.replace(componentRaw, '/', ':');
                    imgTitle = 'java'
                }
                else {
                    component = componentRaw;
                    imgTitle = artifact;
                }

                return <span ><img src={getArtifactImage(artifact)} style={{ width: 25 }} className="me-1" title={imgTitle} /> <span title={component}>{component}</span></span>;
            }
            else {
                return 'Unknown';
            }
            // return (!_.isEmpty(text) ? text : 'Unknown')
        }
        else {
            return text;
        }
    }

    const highlightText = (searchedColumn, text, row) => {
        if (searchedColumn === 'packageName') {
            
            if (!_.isEmpty(text)) {
                let [artifact, componentRaw] = text.split(/\/(.*)/s);
                let component = '';
                let imgTitle = '';
                if (artifact === 'maven') {
                    component = _.replace(componentRaw, '/', ':');
                    imgTitle = 'java'
                }
                else {
                    component = componentRaw;
                    imgTitle = artifact;
                }

                return <span ><img src={getArtifactImage(artifact)} style={{ width: 25 }} className="me-1" title={imgTitle} /> <span title={component}>{getHighlighted(component)}</span></span>;
            }
            else {
                return 'Unknown';
            }
            // return (!_.isEmpty(text) ? getHighlighted(text) : 'Unknown')
        }
        else {
            return getHighlighted(text);
        }

    }

    const getHighlighted = (text) => {
        return <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
        />;
    }


    const columns = [
        // {
        //     title: 'Module name',
        //     dataIndex: 'scanFileLocation',
        //     key: 'scanFileLocation',
        //     // ...getColumnSearchProps('scanFileLocation', 'module name'),
        //     sorter: (a, b) => a.scanFileLocation.localeCompare(b.scanFileLocation),
        //     sortDirections: ['ascend', 'descend'],
        //     // render: (val, row) => {
        //     //   return val
        //     // },
        //     // width: '25%',
        //     ellipsis: true,
        // },
        {
            title: 'Vulnerable component',
            dataIndex: 'packageName',
            key: 'packageName',
            ...getColumnSearchProps('packageName', 'vulnerability'),
            sorter: (a, b) => a.packageName.localeCompare(b.packageName),
            sortDirections: ['ascend', 'descend'],
            // width: '20%',
            ellipsis: true,
            // render: (val, row) => {
            //     // let returnData = [], count = getCount(row);
            //     // if (!_.isEmpty(row) && !_.isEmpty(row.cveDetails)) {
            //     //     _.forIn(count, (value, key) => {
            //     //         if (value) {
            //     //             returnData.push(<span key={key} className={'mx-1'}>{value} {key.toLowerCase()}</span>);
            //     //         }
            //     //     })
            //     // }
            //     // return (
            //     //     <>
            //     //         <span>{val}
            //     //             {/* <small className='text-muted'>
            //     //                 (has {(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW)} vulnerabilit{(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW) > 1 ? 'ies' : 'y'})
            //     //             </small> */}
            //     //         </span>
            //     //         {/* <small className='text-muted d-block'>
            //     //             (this has{(!_.isEmpty(returnData) ? returnData : <span className='mx-1'>0</span>)}vulnerabilit{(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW) > 1 ? 'ies' : 'y'})
            //     //         </small> */}
            //     //     </>
            //     // );

            // }
        },
        {
            title: 'Total',
            dataIndex: 'total',
            width: 75,
            sorter: (a, b) => a.total - b.total,
            sortDirections: ['descend'],
            align: 'center',
            render: (val, row) => val ? <Tag style={{ color: '#adb5bd', borderRadius: 50, width: 40, border: 'none', boxShadow: 'none', background: 'transparent', fontSize: 14 }} color={getStrokeColor('TOTAL')} className={'me-1 text-center'}>{val}</Tag> : ''
        },
        {
            title: 'Critical',
            dataIndex: 'critical',
            width: 75,
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.critical - b.critical,
            sortDirections: ['descend'],
            align: 'center',
            render: (val, row) => val ? <Tag style={{ color: getFontColor('CRITICAL'), borderRadius: 50, width: 40 }} color={getStrokeColor('CRITICAL')} className={'me-1 text-center'}>{val}</Tag> : ''
        },
        {
            title: 'High',
            dataIndex: 'high',
            width: 65,
            sorter: (a, b) => a.high - b.high,
            sortDirections: ['descend'],
            align: 'center',
            render: (val, row) => val ? <Tag style={{ color: getFontColor('HIGH'), borderRadius: 50, width: 40 }} color={getStrokeColor('HIGH')} className={'me-1 text-center'}>{val}</Tag> : ''
        },
        {
            title: 'Medium',
            dataIndex: 'medium',
            width: 85,
            sorter: (a, b) => a.medium - b.medium,
            sortDirections: ['descend'],
            align: 'center',
            render: (val, row) => val ? <Tag style={{ color: getFontColor('MEDIUM'), borderRadius: 50, width: 40 }} color={getStrokeColor('MEDIUM')} className={'me-1 text-center'}>{val}</Tag> : ''
        },
        {
            title: 'Low',
            dataIndex: 'low',
            width: 65,
            sorter: (a, b) => a.low - b.low,
            sortDirections: ['descend'],
            align: 'center',
            render: (val, row) => val ? <Tag style={{ color: getFontColor('LOW'), borderRadius: 50, width: 40 }} color={getStrokeColor('LOW')} className={'me-1 text-center'}>{val}</Tag> : ''
        },
        {
            title: 'NA',
            dataIndex: 'na',
            width: 65,
            sorter: (a, b) => a.na - b.na,
            sortDirections: ['descend'],
            align: 'center',
            render: (val, row) => val ? <Tag style={{ color: getFontColor('NA'), borderRadius: 50, width: 40 }} color={getStrokeColor('NA')} className={'me-1 text-center'}>{val}</Tag> : ''
        },
        {
            title: 'Recommended fix',
            dataIndex: 'recomendedFix',
            key: 'recomendedFix',
            render: (val, row) => {
                return <GetRecFixRow val={val} row={row} getCount={getCount} />
            }
        },
        // {
        //     title: '',
        //     dataIndex: ''
        // }
    ];
    return (
        <>
            {/* <div className='mb-3'>
                <p><b>Selected application:</b> {selectedApplication}</p>
                {console.log("here::", totalCount)}
                <p>This application has a total of {totalCount.CRITICAL + totalCount.HIGH + totalCount.MEDIUM + totalCount.LOW} vulnerabilities</p>
            </div> */}
            <Table
                className='scanDetailsTable table-striped-rows-new '
                columns={columns}
                // sticky={{ offsetHeader: 0 }}
                expandable={{
                    expandedRowRender,
                    // expandIcon: ({ expanded, onExpand, record }) => <FontAwesomeIcon icon={(expanded) ? faSquareMinus : faSquarePlus} style={{ cursor: 'pointer' }} onClick={e => onExpand(record, e)} />
                }}
                dataSource={data}
                size="small"
                pagination={{
                    showTotal: (total, range) => {
                        return <span className='text-white'>{range[0]}-{range[1]} of {total && total.format()} components</span>;
                    },
                    showSizeChanger: true,
                    position: ["bottom", "left"],
                    className: "searchPagePagination extendedPagePagination",
                    pageSizeOptions: [25, 50, 100, 200]
                }}
                locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
            />
        </>
    );
};
export default DataTable;