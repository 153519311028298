
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faFileCode, faFileZipper } from "@fortawesome/free-solid-svg-icons";
import { faBitbucket, faDocker, faGithub } from "@fortawesome/free-brands-svg-icons";
import azureScm from '../../assets/img/scopy-tech-icons/azure-scm.svg';
import aws from '../../assets/img/scopy-tech-icons/aws.svg';
import gcp from '../../assets/img/scopy-tech-icons/gcp.svg';
import azure from '../../assets/img/scopy-tech-icons/azure.svg';
import oracle from '../../assets/img/techIcons/oracle.png';
import { Tooltip } from "antd";

export function getAssetIcon(assetName) {
    switch (assetName) {
        case "github":
            return (
                <FontAwesomeIcon
                    title={assetName}
                    icon={faGithub}
                    className="me-2"
                />
            );
        case "bitbucket":
            return (
                <FontAwesomeIcon
                    title={assetName}
                    icon={faBitbucket}
                    className="me-2"
                />
            );
        case "archive":
            return (
                <FontAwesomeIcon
                    title={assetName}
                    icon={faFileZipper}
                    className="me-2"
                />

            );
        case "file-cli":
            return (
                <FontAwesomeIcon
                    title={assetName}
                    icon={faFileCode}
                    className="me-2"
                />
            );
        case "sbom":
            return (
                <FontAwesomeIcon
                    title={assetName}
                    icon={faFileCode}
                    className="me-2"
                />
            );
        case "container":
            return (
                <FontAwesomeIcon
                    title={assetName}
                    icon={faDocker}
                    className="me-2"
                />
            );
        case "docker":
            return (
                <FontAwesomeIcon
                    title={assetName}
                    icon={faDocker}
                    className="me-2"
                />
            );
        case "azure-scm":
            return (
                <img src={azureScm} title={assetName} className="me-2" style={{ width: 13 }} />
            );
        case "aws":
            return (
                <img src={aws} title={assetName} className="me-2" style={{ width: 18 }} />
            );
        case "google":
            return (
                <img src={gcp} title={assetName} className="me-2" style={{ width: 18 }} />
            );
        case "gcp":
            return (
                <img src={gcp} title={assetName} className="me-2" style={{ width: 18 }} />
            );
        case "azure":
            return (
                <img src={azure} title={assetName} className="me-2" style={{ width: 18 }} />
            );
        case "oracle":
            return (
                <img src={oracle} title={assetName} className="me-2" style={{ width: 18 }} />
            );

        default:
            return (
                <Tooltip title="Unable to identify the artifact">
                    <span>
                        <FontAwesomeIcon icon={faCircleQuestion} className="me-2" />
                    </span>
                </Tooltip>
            );
    }
};


export function getTooltipName(assetName) {

    switch (assetName) {
        case 'aws':
            return 'AWS Cloud';
        case 'google':
            return 'Google Cloud';
        case 'gcp':
            return 'Google Cloud Platform';
        default:
            return 'N/A';
    }
}