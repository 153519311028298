import React, { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';

const AssetVulnerabilityChart = () => {
    const theme = {
        darkMode: true,
        backgroundColor: 'transparent',
        textStyle: {
            color: '#B9B8CE',
        }
    };
    echarts.registerTheme('security_theme', theme);

    // Predefined set of visually distinct colors
    const colorPalette = [
        'rgba(255, 99, 132, 0.8)',   // Red
        'rgba(54, 162, 235, 0.8)',   // Blue
        'rgba(255, 206, 86, 0.8)',   // Yellow
        'rgba(75, 192, 192, 0.8)',   // Teal
        'rgba(153, 102, 255, 0.8)',  // Purple
        'rgba(255, 159, 64, 0.8)',   // Orange
        'rgba(76, 175, 80, 0.8)',    // Green
        'rgba(233, 30, 99, 0.8)',    // Pink
        'rgba(3, 169, 244, 0.8)',    // Light Blue
        'rgba(255, 152, 0, 0.8)'     // Deep Orange
    ];

    // Function to shuffle array
    const shuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    // Memoize the shuffled colors so they don't change on every render
    const randomColors = useMemo(() => shuffleArray(colorPalette), []);

    const formatDomain = (url) => {
        try {
            const domain = new URL(url).hostname;
            return domain;
        } catch {
            return url;
        }
    };

    const validData = [
        {
            assetUrl: "testfire.net",
            total: 924
        },
        {
            assetUrl: "sec1.io",
            total: 752
        },
        {
            assetUrl: "example.com",
            total: 12
        }
    ].filter(item => item.total > 0);

    const option = {
        tooltip: {
            trigger: 'item',
            formatter: (params) => {
                return `${params.name}<br/>Vulnerabilities: ${params.value} (${params.percent}%)`;
            },
            backgroundColor: 'rgba(0,0,0,0.85)',
            borderColor: '#484753',
            textStyle: {
                color: '#B9B8CE'
            }
        },
        legend: {
            orient: 'horizontal',
            right: '0',
            top: 'top',
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
                color: '#B9B8CE',
                fontSize: 11
            }
        },
        series: [
            {
                name: 'Asset Vulnerabilities',
                type: 'pie',
                radius: ['15%', '80%'],
                center: ['42%', '50%'],
                roseType: 'area',
                itemStyle: {
                    borderRadius: 4,
                    borderColor: '#2b313e',
                    borderWidth: 2
                },
                label: {
                    show: true,
                    position: 'outer',
                    fontSize: 10,
                    color: '#EEF1FA',
                    formatter: '{b}\n{c}',
                    overflow: 'break'
                },
                labelLine: {
                    length: 8,
                    length2: 12,
                    maxSurfaceAngle: 80
                },
                emphasis: {
                    label: {
                        show: true,
                        fontWeight: 'bold',
                        fontSize: 12
                    },
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                data: validData.map((item, index) => ({
                    value: item.total,
                    name: formatDomain(item.assetUrl),
                    itemStyle: {
                        color: randomColors[index],
                        borderColor: randomColors[index].replace('0.8', '1') // Slightly darker border
                    }
                }))
            }
        ]
    };

    return (
        <div className="w-full h-full rounded-lg" style={{ width: '100%', height: 353 }}>
            <ReactEcharts
                option={option}
                theme="security_theme"
                style={{ height: '100%', width: '100%', cursor: 'pointer' }}
                opts={{ renderer: 'svg' }}
            />
        </div>
    );
};

export default AssetVulnerabilityChart;