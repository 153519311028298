import moment from "moment";
import { getFontColor, getAIAssetScanStrokeColor } from "../../shared/helper/genHelper";
import { Table, Tag } from "antd";
import _ from "lodash";
import Loader from "../welcomeScreen/loader";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAssetIcon } from "../../shared/helper/getAssetImagesHelper";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import { RiQrScan2Line } from "react-icons/ri";
import { AiOutlineDashboard } from "react-icons/ai";
import { GlobalConst } from "../../shared/appConfig/globalConst";

function ActionColumn({ row, rescan, onClickAdvDashboard }) {
  return (
    <div className="d-flex align-items-center justify-content-start">
      <a
        className={`me-3 details-hover-animate ${row.rowStatus.toLowerCase() === "initiated" ||
          row.rowStatus.toLowerCase() === "scanning" ||
          row.assetType === "sbom"
          ? "disabled"
          : ""
          }`}
        href="#"
        onClick={(e) => {
          rescan(e, row);
        }}
      >
        <RiQrScan2Line style={{ fontSize: 20 }} />
      </a>
      <a
        className={`me-3 details-hover-animate ${row.rowStatus.toLowerCase() === "failed" ||
          row.rowStatus.toLowerCase() === "initiated" ||
          row.rowStatus.toLowerCase() === "scanning"
          ? "disabled"
          : ""
          }`}
        onClick={onClickAdvDashboard}
      >
        <AiOutlineDashboard style={{ fontSize: 20 }} />
      </a>
    </div>
  );
}

export default function SummaryTable({ getDashboardSummary }) {
  const [page, setPage] = useState(1)
  const threatVisionReducer = useSelector((state) => state.threatVisionReducer);
  const history = useHistory();

  const getColumnText = (searchedColumn, text, row, onRowNameClick) => {
    // console.log("Row:", row)
    if (searchedColumn === "name") {
      return (
        <span>
          <span title={text}>
            {!_.isEmpty(row) && !_.isEmpty(row.assetUrl) && isValidHttpUrl(row.assetUrl) && row.assetType !== 'sbom'
              ? <a
                title={row.assetUrl}
                href={row.assetUrl}
                target="_blank"
                style={{
                  color: "inherit"
                }}
              >
                {getAssetIcon(row.assetType)}
              </a>
              : getAssetIcon(row.assetType)}
            {!_.isEmpty(row) ? getRowName(row, text, onRowNameClick) : text}
          </span>
        </span>
      );
    } else {
      return text;
    }
  };

  const getRowName = (row, text, onRowNameClick) => {
    return (
      <a className={`details-hover-animate ${row.rowStatus.toLowerCase() === "failed" ||
        row.rowStatus.toLowerCase() === "initiated" ||
        row.rowStatus.toLowerCase() === "scanning"
        ? "disabled"
        : ""
        }`} onClick={onRowNameClick}>
        {getReportName(text)}
      </a>
    );
  };

  const isValidHttpUrl = (string) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };

  const scanRecord = (e, assetRecord) => {
    e.preventDefault();

    if (!_.isEmpty(assetRecord)) {
      const { assetType, assetUrl } = assetRecord;
      let requestBody = {
        repoType: assetType,
        repoURL: assetUrl,
      };
      axios
        .post(
          `${GlobalConst.API_URL}/auth/sca-threat-api/repo/predict`,
          requestBody
        )
        .then((response) => {
          if (
            !_.isEmpty(response) &&
            !_.isEmpty(response.data))
            getDashboardSummary()
        })
        .catch((e) => {
          console.log("Exception", e);
        });
    }
  };

  const getReportName = (location) => {
    if (isValidHttpUrl(location)) {
      var pathname = new URL(location).pathname;
      pathname = pathname.substring(1);
      return !_.isEmpty(pathname) ? decodeURIComponent(pathname) : location
    } else return location
  };

  const navigateToDependency = (assetReportId) => () => {
    history.push(`/ai-threat-vision/${assetReportId}`);
  }

  const columns = [
    {
      title: "Sno",
      dataIndex: "serialNumber",
      width: 60,
      render: (val, row, index) => {
        return (page - 1) * 10 + index + 1;
      }
    },
    {
      title: "Artifact Name",
      dataIndex: "assetUrl",
      ellipsis: true,
      render: (text, row) => getColumnText("name", text, row, navigateToDependency(row.reportId)),
    },
    {
      title: "Risk Score",
      dataIndex: "riskScore",
      sorter: (a, b) => a.riskScore - b.riskScore,
      sortDirections: ["descend"],
      align: "center",
      width: 150,
      render: (val, row) => {
        return row.rowStatus.toLowerCase() === "initiated" ||
          row.rowStatus.toLowerCase() === "scanning" ? (
          <div className="d-flex align-items-center justify-content-center">
            {" "}
            <Loader />
          </div>
        ) : row.rowStatus.toLowerCase() === "failed" ? (
          <span
            style={{
              color: "#adb5bd",
              borderRadius: 50,
              width: 40,
              border: "none",
              boxShadow: "none",
              fontSize: 14,
            }}
            className={"me-0 text-center d-inline-block"}
          >
            -
          </span>
        ) : val ? (
          <div className="d-flex align-items-center justify-content-center flex-column">
            <Tag
              style={{
                color: getFontColor(row.riskCategory.toUpperCase()),
                fontSize: 14,
                borderRadius: 50,
              }}
              color={getAIAssetScanStrokeColor(row.riskCategory.toUpperCase())}
              className={"mx-auto text-center"}
            >
              {Math.round(val)}
            </Tag>
          </div>
        ) : (
          <span
            style={{
              color: "#adb5bd",
              borderRadius: 50,
              width: 40,
              border: "none",
              boxShadow: "none",
              fontSize: 14,
            }}
            className={"me-0 text-center d-inline-block"}
          >
            -
          </span>
        )
      },
    },
    {
      title: "Severity",
      dataIndex: "riskCategory",
      align: "center",
      width: 150,
      filters: [
        {
          text: 'critical',
          value: 'CRITICAL',
        },
        {
          text: 'high',
          value: 'HIGH',
        },
        {
          text: 'medium',
          value: 'MEDIUM',
        },
        {
          text: 'low',
          value: 'LOW',
        },
        {
          text: 'na',
          value: 'NA',
        },
      ],
      // sorter: (a, b) => a.severity.localeCompare(b.severity),
      // sortDirections: ['ascend', 'descend'],
      // filteredValue: filteredInfo.name || null,
      onFilter: (value, row) => {
        // console.log("Here::", value);
        let returnStatus = false;
        if (value) {
          if (!_.isEmpty(row.riskCategory)) {
            returnStatus = _.includes(row.riskCategory.toLowerCase(), value.toLowerCase());
          }
          else {
            returnStatus = false;
          }
        }
        return returnStatus;
      },
      render: (val, row) => {
        return row.rowStatus.toLowerCase() === "initiated" ||
          row.rowStatus.toLowerCase() === "scanning" ? (
          <div className="d-flex align-items-center justify-content-center">
            {" "}
            <Loader />
          </div>
        ) : row.rowStatus.toLowerCase() === "failed" ? (
          <span
            style={{
              color: "#adb5bd",
              borderRadius: 50,
              width: 40,
              border: "none",
              boxShadow: "none",
              fontSize: 14,
            }}
            className={"me-0 text-center d-inline-block"}
          >
            -
          </span>
        ) : (
          <Tag style={{ color: getFontColor(val.toUpperCase()), fontWeight: 600 }} color={getAIAssetScanStrokeColor(val.toUpperCase())}>
          {val.toUpperCase()}
        </Tag>
        );
      },
    },
    {
      title: "Last Scan",
      dataIndex: "",
      width: 150,
      render: (val, row) => {
        return row.rowStatus.toLowerCase() === "initiated" ||
          row.rowStatus.toLowerCase() === "scanning" ? (
          <div className="d-flex align-items-center justify-content-start ps-2"></div>
        ) : (
          moment.utc(row.scanDateTime).local().fromNow()
        );
      },
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "",
      width: 100,
      render: (val, row, index) => {
        // console.log("Row::", row)
        return <ActionColumn row={row} rescan={scanRecord} onClickAdvDashboard={navigateToDependency(row.reportId)} />;
      },
      fixed: "right"
    },
  ];

  return (
    <div className="card custom-card">
      <div className="card-body py-0">
        {!_.isEmpty(threatVisionReducer.assetsThreatScanRecords) && (
          <div>
            <div style={{ overflow: "auto" }}>
              <Table
                size={"large"}
                columns={columns}
                dataSource={threatVisionReducer.assetsThreatScanRecords}
                className="custom-table mb-0"
                pagination={{
                  current: page,
                  onChange(current) {
                    setPage(current);
                  },
                  showTotal: (total, range) => {
                    return (
                      <span className="text-white">
                        {range[0]}-{range[1]} of {total && total.format()}{" "}
                        scanned assets
                      </span>
                    );
                  },
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  position: ["bottom", "left"],
                  className: "searchPagePagination px-0",
                  pageSizeOptions: [10, 20, 25],
                }}
                locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
