import React, { useEffect, useState } from 'react';
import TidyTreeChart from './tidyTreeChart'
import _ from 'lodash';

export default function TidyTreeView({ dataSet, onSelectedPackage }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(dataSet)) {
      const processedData = processData(dataSet);
      setData(processedData);
    }
  }, [dataSet]);

  const processData = (node, parent) => {
    const result = {
      ...node,
      name: `${node.name}`,
      type: 'package',
      children: [],
    };

    if (node.children && node.children.length > 0) {
      result.children = node.children.map(child => processData(child));
    }

    return result;
  };

  return (
    <div className='d-flex align-items-center justify-content-center'>
      {!_.isEmpty(data) ? (
        // <TidyTreeChart
        //   data={data}
        //   options={{
        //     label: d => d.name,
        //     title: (d, n) => `${n.ancestors().reverse().map(d => d.data.name).join(".")}`,
        //     width: 1152,
        //     height: 800, // Increased height to accommodate larger trees
        //   }}
        // />
        <TidyTreeChart
          data={data}
          options={{
            label: d => d.name,
            title: (d, n) => `${n.ancestors().reverse().map(d => d.data.name).join(".")}`,
            // width: 1152,
            // height: 800, // Increased height to accommodate larger trees
          }}
          triggerNodeClick={onSelectedPackage}
        />
      ) : (
        <section className='card custom-card p-2 px-3 text-left mb-2' style={{ background: '#1e464d', border: '1px solid #2c6a74' }}>
          <p className='mb-0'>No dependency data available for the selected project</p>
        </section>
      )}
    </div>
  );
}