import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import _ from 'lodash';

// thunk to extract the user details from token and update the state.
export const updateToken = createAsyncThunk(
    'authReducer/updateToken',
    async (token) => {
        var decodedToken = jwt_decode(token);
        return decodedToken;
    }
);


const authReducer = createSlice({
    name: 'authReducer',
    initialState: {
        userDetails: { name: '', preferred_username: '', email: '', isFirstTimeLogin: false},
        token: '',
        loggedInFrom: '',
        loginModalViewStatus: false
    },
    reducers: {
        resetAuthState: (state, action) => {
            state.token = '';
            state.userDetails = { name: '', preferred_username: '', email: '', isFirstTimeLogin: false }
        },
        setLoggedInFrom: (state, action) => {
            localStorage.setItem("lf", action.payload);
            state.loggedInFrom = action.payload;
        },
        setIsFirstLogin: (state, action) => {
            // localStorage.setItem("fl", action.payload.isFirstLogin ? 'true' : 'false');
            // console.log("I am the state::",action.payload.isFirstLogin );
            state.userDetails.isFirstTimeLogin = action.payload.isFirstLogin;
        },
        setLoginModalViewStatus: (state, action) => {
            state.loginModalViewStatus = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateToken.fulfilled, (state, action) => {
            state.userDetails = {
                name: action.payload.name,
                preferred_username: action.payload.preferred_username,
                email: action.payload.email
            };
            state.token = action.meta.arg;
            const lf = localStorage.getItem('lf');
            // const fl = localStorage.getItem('fl');
            state.loggedInFrom = lf;
            // state.userDetails.isFirstTimeLogin = fl;
        })
    }
})

export const { resetAuthState, setIsFirstLogin, setLoginModalViewStatus, setLoggedInFrom } = authReducer.actions;

export default authReducer.reducer;