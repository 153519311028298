import React from "react";
import './style.css';

export default function Loader() {
    return (
        // <div className='demo'>
        //     <div className='loader-circle'>
        //         <div className='inner'></div>
        //     </div>
        //     <div className='loader-circle'>
        //         <div className='inner'></div>
        //     </div>
        //     <div className='loader-circle'>
        //         <div className='inner'></div>
        //     </div>
        //     <div className='loader-circle'>
        //         <div className='inner'></div>
        //     </div>
        //     <div className='loader-circle'>
        //         <div className='inner'></div>
        //     </div>
        // </div>
        <div className="loader">
            <div className="anim-circle circle-1"></div>
        </div>

    );
}