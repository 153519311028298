import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

export default function StackedGraph({ data }) {

    const barColors = ["#8561f9", "#2dcb73", "#438eff"];
    const [newData, setNewData] = useState([]);
    const [hoverIndex, setHoverIndex] = useState(0);

    useEffect(() => {
        let nd = [];
        let nm = '';
        _.forIn(data, (val, key) => {
            nm = key.split('/').pop();
            nd.push({ name: nm, val: val });
        });
        setNewData(nd);

    }, [data]);

    const handleMouseEnter = (index) => {
        setHoverIndex(index);
    }

    return (
        <ResponsiveContainer>
            <BarChart
                width={600}
                height={200}
                data={newData}
                margin={{
                    top: 20,
                    right: 30,
                    left: 150,
                    bottom: 5,
                }}
                barGap={5}
                layout={'vertical'}
            >
                <XAxis type="number" dataKey="val" />
                <YAxis type="category" dataKey="name" />
                <Tooltip
                    cursor={{ fill: 'rgb(43, 49, 62, 0.39)' }}
                    content={({ active, payload, label }) => {
                        if (active && payload && payload.length) {
                            // Extract the data for the hovered bar
                            const barData = payload[0].payload;
                            const fill = barColors[hoverIndex];

                            return (
                                <div className="custom-tooltip-recharts ">
                                    <p className="label mb-0"><small>{label}</small></p>
                                    <p className="value mb-0" style={{ fontSize: 12 }}><b><FontAwesomeIcon className='me-2' icon={faCircle} style={{ color: fill, fontSize: 8 }} />Vulnerabilities:</b> {barData.val}</p>
                                </div>
                            );
                        }
                        return null;
                    }} />
                <Bar
                    dataKey="val"
                    barSize={20}
                    radius={[0, 10, 10, 0]}
                >
                    {
                        newData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={barColors[index]} onMouseEnter={() => handleMouseEnter(index)} />
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}