import React, { useEffect, useState } from "react";
import { Alert, Tooltip, Radio, Button } from 'antd';
import axios from "axios";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import _ from 'lodash';
import InlineLoader from '../../shared/sharedComponents/inlineLoader';
import { useSelector, useDispatch } from "react-redux";
import SelectRepositoryDataTable from "./selectRepositoryDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { setAllUserRepoList, setUserListPageNumber } from "../../shared/redux/reducers/threatVisionReducer";
import { removeGitSuffix } from "../../shared/helper/genHelper";


export default function SelectRepository({ loadingRepos, selectedRepo, setSelectedRepo }) {

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [loadingReposList, setLoadingReposList] = useState(false);
    const [disableLoadMore, setDisableLoadMore] = useState(false);

    const reduxState = useSelector(state => state);

    useEffect(() => {
        // console.log(":Called here", reduxState);
        if (!_.isEmpty(reduxState.threatVisionReducer.allUserRepoList) && reduxState.threatVisionReducer.allUserRepoList.length > 0) {
            setData(reduxState.threatVisionReducer.allUserRepoList);
        }
    }, [reduxState.threatVisionReducer.allUserRepoList]);

    const handleRepoItemClick = (e, element) => {
        e.preventDefault();

        // check if the current element is not present in the existing list if present then remove it else add it
        if (!_.isEmpty(selectedRepo)) {

            let isPresent = false;
            selectedRepo.forEach((el, ind) => {
                if (el.url === element.url) {
                    isPresent = true;
                }
            })
            if (isPresent) {
                // since the element is present now remove the element from list
                let filter = [];
                filter = _.filter(selectedRepo, (o) => { return o.url !== element.url });
                setSelectedRepo([...filter]);
            }
            else {
                setSelectedRepo([...selectedRepo,element]);
            }
        }
        else {
            setSelectedRepo([element]);
        }
    }

    const getRepoCheckStatus = (repo) => {

        if (!_.isEmpty(selectedRepo)) {
            let isPresent = false;
            selectedRepo.forEach((el) => {
                if (el.url === repo.url) {
                    isPresent = true;
                }
            })
            return isPresent;
        }
        else {
            return false;
        }

    }

    const handleLoadMoreClick = () => {
        // console.log("I am load more click here");

        const page = reduxState.threatVisionReducer.userListPageNumber + 1;

        dispatch(setUserListPageNumber(page));

        setLoadingReposList(true);
        const reqBody = {
            "urlType": reduxState.authReducer.loggedInFrom,
            "owner": reduxState.authReducer.userDetails.email,
            "page": page,
            "recordsPerPage": GlobalConst.GIT_REPO_LIST_PAGE_COUNT
        }
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/get-repo-list`, reqBody)
            .then(op => {
                setLoadingReposList(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.data)) {
                    // setRepositoryList(op.data.data);
                    // setRepositoryListCopy(op.data.data);
                    dispatch(setAllUserRepoList([...op.data.data, ...reduxState.threatVisionReducer.allUserRepoList]));
                    if (op.data.data.length < 20) {
                        setDisableLoadMore(true);
                    }
                }
                else {
                    // show user the error message that there is no repo list.
                    // setRepositoryList([]);
                    // setRepositoryListCopy([]);
                    // dispatch(setAllUserRepoList([]));
                    setDisableLoadMore(true);
                }
            })
            .catch(e => {
                console.log("Exception: ", e.response);
                setLoadingReposList(false);
                // setRepositoryList([]);
                // setRepositoryListCopy([]);
                // dispatch(setAllUserRepoList([]));
            });



    }

    if (loadingRepos) {
        return (
            <div className='card custom-card p-2 px-3 text-left mb-2' style={{ background: '#1e464d', border: '1px solid #2c6a74' }}>
                <div className="d-flex align-items-start justify-content-start text-white"><InlineLoader /> <span className="ms-2">Loading repositories from github ...</span></div>
            </div>
        );
    }
    else if (_.isEmpty(reduxState.threatVisionReducer.allUserRepoList)) {
        return (
            <Alert message="No Repositories found in your account" type="info" showIcon />
        );
    }
    else {
        return (

            <div className="py-2 rounded" >
                <div className="row">
                    <div className="col-sm-12 scroll-style">
                        <div className="row">
                            <div className="col-sm-8">
                                <div style={{ maxHeight: 450, overflow: "hidden", overflowY: 'scroll' }}>
                                    <SelectRepositoryDataTable
                                        dataSet={data}
                                        handleRepoItemClick={handleRepoItemClick}
                                        selectedRepo={selectedRepo}
                                        getRepoCheckStatus={getRepoCheckStatus}
                                    />
                                    <div className="w-100 text-center my-3">
                                        <Button type="primary" disabled={disableLoadMore} loading={loadingReposList} onClick={() => handleLoadMoreClick()}>Load More <FontAwesomeIcon className="ms-2" icon={faArrowDown} /></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}