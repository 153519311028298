import React, { useEffect, useState, useRef } from "react";
import { Space, Table } from 'antd';
import { faSquare, faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import Highlighter from 'react-highlight-words';
import _ from 'lodash';
import moment from 'moment';
import { Tooltip } from 'antd';
import { randomNumberLess100 } from '../../helper/genHelper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
// import { faDocker, faGithub } from "@fortawesome/free-brands-svg-icons";
import { getAssetIcon } from "../../helper/getAssetImagesHelper";
import NoDataFound from "../noDataFound";

export default function SelectRepositoryDataTable({ dataSet, selectedFilter, handleRepoItemClick, selectedRepo, getRepoCheckStatus }) {

    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);


    useEffect(() => {
        // console.log("newDataset", dataSet)
        let newData = [];
        if (!_.isEmpty(dataSet)) {
            let newDataset = _.orderBy(dataSet, [(obj) => new Date(obj.scanDate)], ['desc']);
            newDataset.forEach((el, index) => {
                newData.push({
                    sNo: index + 1,
                    key: `${index}-select-repo`,
                    dataKey: el.url,
                    repository: `${el.owner}/${el.name}`,
                    allData: el,
                    scanDate: el.scanDate,
                    pipScan: randomNumberLess100()
                })
            });
        }
        setData(newData);
    }, [dataSet]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    // const handleReset = (clearFilters) => {
    //     clearFilters();
    //     setSearchText('');
    // };

    const getColumnText = (searchedColumn, text, row) => {
        return text;
    }

    const highlightText = (searchedColumn, text, row) => {

        if (searchedColumn === 'name') {
            // return getHighlighted(text);
            if (row.rowStatus.toLowerCase() === 'failed') {
                return <>{getAssetIcon(row.subAssetType)}<a href={row.url} target="_blank" rel="noreferrer">{getHighlighted(text)}</a> <Tooltip title="Scan failed, please contact administrator"><span><FontAwesomeIcon icon={faTimesCircle} className="me-2 text-danger" /></span></Tooltip></>;
            }
            return <>
                {/* <InboxOutlined className={'me-1'} />  */}
                {/* <img src={repo} style={{width: 16}} className="me-2" /> */}
                {getAssetIcon(row.subAssetType)}
                {(!_.isEmpty(row) && !_.isEmpty(row.url) && row.type === 'github') ? <a href={row.url} target="_blank" rel="noreferrer">{getHighlighted(text)}</a> : getHighlighted(text)}</>;

            // {getHighlighted(text)}</>;
        }
        else {
            return getHighlighted(text);
        }
    }

    const getHighlighted = (text) => {
        return <Highlighter
            highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
        />;
    }

    const getColumnSearchProps = (dataIndex, columnName) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{ padding: 8 }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${columnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        className="d-flex align-items-center justify-content-center"
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            if (clearFilters) {
                                clearFilters();
                                setSearchText('');
                            }
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchedColumn(dataIndex);
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, row) =>
            (searchedColumn === dataIndex && !_.isEmpty(searchText)) ? (
                highlightText(searchedColumn, text, row)
            ) : (
                getColumnText(dataIndex, text, row)
            ),
    });


    // const getAssetIcon = (row) => {
    //     switch (row.subAssetType) {
    //         case 'github':
    //             return <FontAwesomeIcon icon={faGithub} className="me-2" />;
    //         case 'archive':
    //             return <FontAwesomeIcon icon={faFileZipper} className="me-2" />;
    //         case 'sbom':
    //             return <FontAwesomeIcon icon={faFileCode} className="me-2" />;
    //         case 'docker':
    //             return <FontAwesomeIcon icon={faDocker} className="me-2" />;
    //         default:
    //             return <Tooltip title="Unable to identify the artifact"><span><FontAwesomeIcon icon={faCircleQuestion} className="me-2" /></span></Tooltip>;
    //     }
    // }


    const columns = [
        {
            title: 'Sno',
            dataIndex: 'sNo',
            width: '10%'
        },
        {
            title: 'Repository',
            dataIndex: 'repository',
            key: 'repository',
            ellipsis: true,
            width: '50%',
            sorter: (a, b) => a.repository.localeCompare(b.repository),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('repository', 'repository'),
            render: (key, row) => {
                return (
                    <>
                        {/* <span className="me-2">
                            {
                                row.allData.isPrivate ?
                                    <Tooltip title="Private">
                                        <small className="text-muted theme-border rounded p-1 text-center" alt={'Private'} style={{ width: '25px' }}>
                                            <FontAwesomeIcon className="m-auto" icon={faLock} />
                                        </small>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Public">
                                        <small className="text-muted theme-border rounded p-1  text-center" alt={'Public'} style={{ width: '25px' }}>
                                            <FontAwesomeIcon className="m-auto" icon={faLockOpen} />
                                        </small>
                                    </Tooltip>
                            }
                        </span> */}
                        <span title={key}><a href={row.dataKey} target="_blank" rel="noreferrer">{key}</a></span>
                    </>
                );
            }
        },
        (selectedFilter === "scanned") ? {
            title: 'Last Scan',
            dataIndex: 'scanDate',
            ellipsis: true,
            sorter: (a, b) => moment(a.scanDate).unix() - moment(b.scanDate).unix(),
            sortDirections: ['ascend', 'descend'],
            render: (key, row) => {

                return (!_.isEmpty(key)) ? moment.utc(key).local().fromNow() : <span className="text-danger">not scanned</span>
            }
        } : {
            title: 'Peep scan vulnerabilities',
            dataIndex: 'pipScan',
            align: 'center',
            ellipsis: true,
            // sorter: (a, b) => moment(a.scanDate).unix() - moment(b.scanDate).unix(),
            // sortDirections: ['ascend', 'descend'],
            render: (key, row) => {
                return <span>{key}</span>
            }
        },
        {
            title: 'Select to scan',
            align: 'center',
            dataIndex: 'dataKey',
            render: (key, row) => {
                return (
                    <span className="list-group-item border-0" style={{ 'cursor': 'pointer' }} onClick={(e) => handleRepoItemClick(e, row.allData)}>
                        <span className="d-flex align-items-center justify-content-center">
                            {/* {!_.isEmpty(selectedRepo) && <span style={{ width: '25px' }}>
                        {(selectedRepo.url === element.url) && <span className="me-2"><FontAwesomeIcon style={{ color: '#2dcb73' }} icon={faCheck} /></span>}
                    </span>} */}
                            <span>
                                {(!_.isEmpty(selectedRepo) && getRepoCheckStatus(row.allData)) ? <span className="me-2"><FontAwesomeIcon icon={faSquareCheck} /></span> : <span className="me-2"><FontAwesomeIcon icon={faSquare} /></span>}
                            </span>
                        </span>
                    </span >
                );
            },
            width: '16%'
        }
    ];

    return <Table
        size={'small'}
        columns={columns}
        dataSource={data}
        className="table table-striped custom-table mb-0 table-no-select-all sticky-custom-table-header"
        pagination={false}
        locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
    // sticky={true}
    // pagination={{
    //     showTotal: (total, range) => {
    //         return <span className="text-white">{range[0]}-{range[1]} of {total && total.format()} repositories</span>;
    //     },
    //     defaultPageSize: 10,
    //     showSizeChanger: true,
    //     position: ["bottom", "left"],
    //     className: "searchPagePagination px-0 mb-0",
    //     pageSizeOptions: [10, 15, 20, 25]
    // }}
    />;
}