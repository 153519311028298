// import axios from 'axios';
// import jwt_decode from "jwt-decode";
// import moment from 'moment';
// import qs from 'qs';
// import _ from 'lodash';
// import { GlobalConst } from '../appConfig/globalConst';
// let instance = axios.create();

// // import { useDispatch } from 'react-redux';
// // import { resetAuthState } from '../redux/reducers/authReducer';

// axios.interceptors.request.use(
//     async (config) => {
//         const token = await localStorage.getItem('__t');
//         if (token) {
//             //check if the token has expired
//             var decodedToken = jwt_decode(token);
//             // console.log("I am the token expiry", checkTokenExpiry(decodedToken.exp));
//             // console.log("I am decoded token", decodedToken);

//             if (checkTokenExpiry(decodedToken.exp)) {
//                 console.log("Token expired need to get new token")
//                 let newToken = await getNewToken();
//                 config.headers['Authorization'] = `Bearer ${newToken}`;
//             }
//             else {
//                 config.headers['Authorization'] = `Bearer ${token}`;
//             }

//         }
//         // config.headers['Content-Type'] = 'application/json';
//         return config;
//     },
//     error => {
//         Promise.reject(error)
//     }
// )

// const checkTokenExpiry = (expiryValue) => {
//     let a = moment(expiryValue * 1000), b = moment();
//     if (a.diff(b, "minutes") <= 0) {
//         return true;
//     }
//     else {
//         return false;
//     }
// }

// const getNewToken = async () => {
//     // let dispatch = useDispatch();
//     // "client_secret": GlobalConst.CLIENT_SECRET,
//     // console.log("I am in the new token ");
//     const payload = qs.stringify({
//         "grant_type": "refresh_token", // refresh_token
//         "client_id": "cve-buster-apisix-prod",
//         "refresh_token": localStorage.getItem("__r")
//     });
//     const headers = { headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8', "Authorization": `Basic ${btoa(`cve-buster-apisix-prod:${GlobalConst.CLIENT_SECRET}`)}` } };
//     return instance.post(`${GlobalConst.AUTH_API_URL}/realms/cve-buster-prod/protocol/openid-connect/token`, payload, headers)
//         .then(async (op) => {
//             if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
//                 // console.log("I am the op::", op)
//                 let tokenUpdated = false;
//                 if (op.data.access_token) {
//                     tokenUpdated = await localStorage.setItem("__t", op.data.access_token);
//                 }
//                 if (op.data.refresh_token) {
//                     await localStorage.setItem("__r", op.data.refresh_token);
//                 }
//                 return op.data.access_token;
//                 // dispatch(updateToken(op.data.access_token));
//                 // setLoginModalStatus(false);
//             }
//         })
//         .catch(e => {

//             // if (!_.isEmpty(e) && !_.isEmpty(e.response) && !_.isEmpty(e.response.status) && e.response.status == 400) {
//             // console.log("I am the catch")
//             // dispatch(resetAuthState());
//             // localStorage.clear();
//             localStorage.removeItem('__t');
//             localStorage.removeItem('__r');
//             window.location.reload();
//             // }
//             console.log("exception: ", e.response);
//         });
// }


// src/shared/api/axiosSetup.js

import axios from 'axios';
import jwtDecode from "jwt-decode";
import moment from 'moment';
import qs from 'qs';
import _ from 'lodash';
import { GlobalConst } from '../appConfig/globalConst';
import { deleteCookie } from './genHelper';

let instance = axios.create();
let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => refreshSubscribers.push(cb);

const onTokenRefreshed = (token) => {
    refreshSubscribers.map(cb => cb(token));
    refreshSubscribers = [];
};

const checkTokenExpiry = (expiryValue) => {
    let a = moment(expiryValue * 1000), b = moment();
    return a.diff(b, "minutes") <= 0;
};

const getNewToken = async () => {
    const payload = qs.stringify({
        "grant_type": "refresh_token",
        "client_id": GlobalConst.CLIENT_ID,
        "refresh_token": localStorage.getItem("__r")
    });
    const headers = {
        headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
            "Authorization": `Basic ${btoa(`${GlobalConst.CLIENT_ID}:${GlobalConst.CLIENT_SECRET}`)}`
        }
    };
    try {
        const op = await instance.post(`${GlobalConst.AUTH_API_URL}/realms/cve-buster-prod/protocol/openid-connect/token`, payload, headers);
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
            localStorage.setItem("__t", op.data.access_token);
            localStorage.setItem("__r", op.data.refresh_token);
            return op.data.access_token;
        }
    } catch (e) {
        console.error("Token refresh failed:", e);
        if (!_.isEmpty(e) && !_.isEmpty(e.response) && e.response.code === 'ERR_NETWORK') {
            return false;
        } else {
            localStorage.removeItem('__t');
            localStorage.removeItem('__r');
            deleteCookie();
            const redirectUrl = encodeURIComponent(GlobalConst.APP_URL);
            window.location.href = GlobalConst.AUTH_APP_URL + `/login?redirect_url=${redirectUrl}`;
            // Instead of reloading, consider redirecting to a login page
            // window.location.href = '/login';
            throw e; // Propagate the error
        }
    }
};

export function setupAxios() {
    axios.interceptors.request.use(
        async (config) => {
            // console.log("I am initialised");
            const token = await localStorage.getItem('__t');
            if (token) {
                const decodedToken = jwtDecode(token);
                if (checkTokenExpiry(decodedToken.exp)) {
                    if (!isRefreshing) {
                        isRefreshing = true;
                        getNewToken().then(newToken => {
                            isRefreshing = false;
                            onTokenRefreshed(newToken);
                        }).catch(err => {
                            console.error("Error refreshing token:", err);
                            // Handle refresh failure (e.g., redirect to login)
                        });
                    }
                    
                    return new Promise((resolve) => {
                        subscribeTokenRefresh(token => {
                            config.headers['Authorization'] = `Bearer ${token}`;
                            resolve(config);
                        });
                    });
                } else {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
            }
            return config;
        },
        error => Promise.reject(error)
    );
}

export default instance;


