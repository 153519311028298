import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './shared/redux/store';
import { setupAxios } from './shared/helper/axiosInterceptor';

import { checkAuthToken } from './shared/redux/checkAuth';

setupAxios();

// const root = ReactDOM.render(document.getElementById('root'));

const renderApp = async () => {
  await checkAuthToken();


  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

renderApp();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
