import React, { useEffect, useState, useRef } from 'react';
import { Table, Tag, Dropdown, Space, Menu, Typography, Popover, Button, Checkbox, Alert, message } from 'antd';
import { SearchOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import Highlighter from 'react-highlight-words';
import { CaretDownOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus, faSquareMinus } from '@fortawesome/free-regular-svg-icons';
import { faCaretRight, faCaretDown, faFilter } from '@fortawesome/free-solid-svg-icons';
import { getStrokeColor, getFontColor } from '../../../../shared/helper/genHelper';
import { ExpandIcon } from 'antd/lib/table/interface';
import _ from 'lodash';
import { Switch } from 'antd';
import { getArtifactImage } from '../../../../shared/helper/getArtifactImageHelper';
import NoDataFound from '../../../../shared/sharedComponents/noDataFound';

export default function ComponentExpandedTable(props) {


    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [filterDropdownVisible, setFilterDropdownVisible] = useState({});

    const searchInput = useRef(null);

    useEffect(() => {
        if (!_.isEmpty(props) && !_.isEmpty(props.cveDetails)) {
            // console.log("Props::", props);
            let NewData = [];
            props.cveDetails.forEach((elem, index) => {
                // console.log("Element::", elem);
                NewData.push({
                    key: `${index}-${elem.cveId}`,
                    serialNo: index + 1,
                    cveId: elem.cveId,
                    score: (elem.cvssV3BaseScore) ? elem.cvssV3BaseScore : (elem.cvssV2BaseScore) ? elem.cvssV2BaseScore : 0,
                    severity: (elem.cvssV3BaseSeverity) ? elem.cvssV3BaseSeverity : (elem.cvssV2BaseSeverity) ? elem.cvssV2BaseSeverity : 'NA',
                    vulType: (!_.isEmpty(elem.cveTypes)) ? elem.cveTypes[0].cveType : 'Misc'
                })
            });

            NewData = _(NewData)
                .orderBy([
                    elem => {
                        switch (elem.severity) {
                            case 'CRITICAL':
                                return 0;
                            case 'HIGH':
                                return 1;
                            case 'MEDIUM':
                                return 2;
                            case 'LOW':
                                return 3;
                            case 'NA':
                                return 4;
                            default:
                                return 5; // Place any other severity types at the end
                        }
                    },
                    elem => -elem.score // Sort score in descending order
                ])
                .value();



            setData(NewData);
        }
    }, [props.cveDetails])

    const compareCVE = (a, b) => {
        const regex = /CVE-(\d{4})-(\d+)/;
        const aMatch = a.match(regex);
        const bMatch = b.match(regex);
        const aYear = parseInt(aMatch[1]);
        const aNumber = parseInt(aMatch[2]);
        const bYear = parseInt(bMatch[1]);
        const bNumber = parseInt(bMatch[2]);
        if (aYear !== bYear) {
            return aYear - bYear;
        }
        return aNumber - bNumber;
    }



    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };


    const getColumnSearchProps = (dataIndex, columnName) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close, visible }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${columnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, visible)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex, visible)}
                        icon={<SearchOutlined />}
                        className="d-flex align-items-center justify-content-center"
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            if (clearFilters) {

                                clearFilters();
                                setSearchText('');
                            }
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchedColumn(dataIndex);
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, row) =>
            (searchedColumn === dataIndex && !_.isEmpty(searchText)) ? (
                highlightText(searchedColumn, text, row)
            ) : (
                getColumnText(dataIndex, text, row)
            ),
        filterDropdownVisible: filterDropdownVisible[dataIndex],
        onFilterDropdownVisibleChange: (visible) => handleSearchClick(dataIndex, visible),
    });


    const handleSearchClick = (selectedColumn, visible) => {
        let allVisible = _.mapValues(filterDropdownVisible, function (o) { return false; });
        setFilterDropdownVisible({ ...allVisible, [selectedColumn]: visible });
    };

    const getColumnText = (searchedColumn, text, row) => {
        if (searchedColumn === 'vulType') {
            return (!_.isEmpty(text) ? text : 'Unknown')
        }
        else {
            return text;
        }
    }

    const highlightText = (searchedColumn, text, row) => {
        if (searchedColumn === 'vulType') {
            return (!_.isEmpty(text) ? getHighlighted(text) : 'Unknown')
        }
        else {
            return getHighlighted(text);
        }

    }

    const getHighlighted = (text) => {
        return <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
        />;
    }


    const columns = [
        // {
        //   title: 'S. No.',
        //   dataIndex: 'serialNo',
        //   key: 'serialNo',
        //   width: '8%',
        //   // sorter: (a, b) => a.serialNo - b.serialNo,
        //   // sortDirections: ['descend'],
        // },
        {
            title: 'Vulnerability',
            dataIndex: 'vulType',
            key: 'vulType',
            sorter: (a, b) => a.vulType.localeCompare(b.vulType),
            sortDirections: ['ascend', 'descend'],
            width: '30%',
            ...getColumnSearchProps('vulType', 'vulnerability'),
            ellipsis: true,
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
            render: (val, row) => {
                // console.log(val);
                if (val === 'NA') {
                    return (<Tag style={{ color: getFontColor(row.severity.toUpperCase()), fontWeight: 600 }} color={getStrokeColor(row.severity.toUpperCase())}>{row.severity.toUpperCase()}</Tag>)
                }
                return (<Tag style={{ color: getFontColor(row.severity.toUpperCase()), fontWeight: 600 }} color={getStrokeColor(row.severity.toUpperCase())}>{row.score} {row.severity.toUpperCase()}</Tag>)
            },
            width: '13%',
            sorter: (a, b) => a.score - b.score,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'CVE ID',
            dataIndex: 'cveId',
            key: 'cveId',
            sorter: (a, b) => { return compareCVE(a.cveId, b.cveId) },
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('cveId', 'cve id'),
            width: '15%',
            render: (val) => <a href={`https://galaxyguard.sec1.io/cve/${val}/?s=${val}`} target={'_blank'} rel="noreferrer">{val}</a>,
        },
        {
            title: ' '
        }
    ];

    // const data = [];


    const getPaginationStatus = (dataState) => {
        if (!_.isEmpty(dataState)) {
            if (dataState.length > 10) {
                return {
                    showTotal: (total, range) => <span className='text-white'>{range[0]}-{range[1]} of {total && total.format()} vulnerabilities</span>,
                    position: ["bottom", "left"],
                    className: "searchPagePagination extendedPagePagination ms-4 mt-4",
                    showSizeChanger: true,
                    defaultPageSize: 10,
                    pageSizeOptions: [5, 10, 20, 50],
                };
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    return <Table
        size="middle"
        className='table table-striped mb-0 nested-table'
        columns={columns}
        dataSource={data}
        pagination={getPaginationStatus(data)}
        locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
    />;
}