import React from "react";
import { Tabs } from 'antd';
import linux from '../../assets/img/linux-cli.svg';
import macos from '../../assets/img/macos-cli.svg';
import { DownloadOutlined } from '@ant-design/icons';

export default function CliTabs() {

    return (
        <Tabs
            defaultActiveKey="1"
            items={[
                {
                    label: 'Mac',
                    key: '1',
                    children: <div className='row'>
                        <div className='col-sm-2'>
                            <div className='card custom-card text-center p-4'>
                                <img src={macos} alt={"macOS CLI"} className='mb-3' style={{ width: 120, margin: '0 auto' }} />
                                <h4 className='text-white text-center fw-normal mb-2'>macOS CLI</h4>
                                <div className='mt-2 text-center'>
                                    <a className='btn btn-primary' href='https://digitalassets.sec1.io/sec1-cli-macos'><DownloadOutlined className="me-2" />Download</a>
                                </div>
                            </div>
                        </div>
                    </div>,
                },
                {
                    label: 'Linux',
                    key: '2',
                    children: <div className='row'>
                        <div className='col-sm-2'>
                            <div className='card custom-card text-center p-4'>
                                <img src={linux} alt={"Linux CLI"} className='mb-3' style={{ width: 120, margin: '0 auto' }} />
                                <h4 className='text-white text-center fw-normal mb-2'>Linux CLI</h4>
                                <div className='mt-2 text-center'>
                                    <a className='btn btn-primary' href='https://digitalassets.sec1.io/sec1-cli-linux'><DownloadOutlined className="me-2" />Download</a>
                                </div>
                            </div>
                        </div>
                    </div>,
                },
                // {
                //     label: 'Windows',
                //     key: '3',
                //     children: <div className='row'>
                //         <div className='col-sm-2'>
                //             <div className='card custom-card text-center p-4'>
                //                 <img src={microsoft} alt={"Windows CLI"} className='mb-3' style={{ width: 120, margin: '0 auto' }} />
                //                 <h4 className='text-white text-center fw-normal mb-2'>Windows CLI</h4>
                //                 <div className='mt-2 text-center'>
                //                     <a className='btn btn-primary' href='https://digitalassets.sec1.io/sec1-cli-win.exe'><DownloadOutlined className="me-2" />Download</a>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>,
                // },
            ]}
        />
    );
}