import { faTrowelBricks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

export default function NoIntegrationsConfigured() {

    const history = useHistory();

    const handleGotoIntegrationClick = () => {
        history.push('/integrations-list');
    }

    return (
        <div className="text-center d-flex align-items-center justify-content-center flex-column my-4">
            <button className="btn btn-primary mb-3" onClick={handleGotoIntegrationClick}><FontAwesomeIcon icon={faTrowelBricks} /> Goto Integrations</button>
            <p>You haven't configured any cloud integrations. Please navigate to the Integration Tab to complete the setup.</p>
        </div>
    );
}