import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import axios from "axios";
import { Table, Tag, Switch, Popover, Tabs, Tooltip, Input, Space, Button } from 'antd';
import _ from 'lodash';
import { GlobalConst } from "../../../shared/appConfig/globalConst";
import NoDataFound from '../../../shared/sharedComponents/noDataFound';
import { getCloudIcon, getFontColor, getStrokeColor } from '../../../shared/helper/genHelper';
import { WarningFilled, CheckCircleFilled, SearchOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import TabLoader from "../../../shared/sharedComponents/tabLoader";
import { useParams } from "react-router-dom";
import VulDataTable from '../resourceDetailsModal/detailsModalTabs/vulDataTable';
import { getAssetIcon } from '../../../shared/helper/getAssetImagesHelper';
import { updateSelectedK8sRecord } from '../../../shared/redux/reducers/kubernetesSecurityReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Highlighter from 'react-highlight-words';
import { debounce } from 'lodash'

const { TabPane } = Tabs;

const MessagePopover = ({ record }) => {
    const [visible, setVisible] = useState(false);

    const handleOpenChange = (newVisible) => {
        setVisible(newVisible);
    };

    const hide = () => {
        setVisible(false);
    };
    return (<Popover
        overlayClassName="message-popover"
        open={visible}
        placement='rightTop'
        content={
            <div style={{ width: 600, height: 400, overflow: 'hidden', overflowX: 'auto' }}>
                <p className='mb-2'><strong>Description:</strong> {record.description}</p>
                {record.impact && <p className='mb-2'><strong>Impact:</strong> {record.impact}</p>}
                <div className='mb-1'>
                    <strong>References</strong>
                    {record.references && <ul>
                        {record.references.map((el, index) => (
                            <li key={`ref-${record.name}-${index}`}>
                                <a target='_blank' href={el} rel="noopener noreferrer">{el}</a>
                            </li>
                        ))}
                    </ul>}
                </div>
            </div>
        }
        title={<div className='d-flex justify-content-between'>Details<span className='ms-auto'><FontAwesomeIcon icon={faClose} onClick={hide} /></span></div>}
        trigger="click"
        onOpenChange={handleOpenChange}
    >
        <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
    </Popover>)
}

export default function AssetViewTab({ setShowResourceDetailsModal }) {
    const [showAllComponents, setShowAllComponents] = useState(false);
    const [data, setData] = useState({});
    const [loadingData, setLoadingData] = useState(false);
    const [expandedContainerVulnerabilities, setExpandedContainerVulnerabilities] = useState({});
    const [loadingContainerVulData, setLoadingContainerVulData] = useState(false)
    const [searchedColumn, setSearchedColumn] = useState("");
    const [searchText, setSearchText] = useState("");
    const [expandedRow, setExpandedRow] = useState("");
    const dispatch = useDispatch();
    const { id, integrationId } = useParams();
    const searchInput = useRef(null);

    useEffect(() => {
        fetchAssetData();
    }, []);

    const fetchAssetData = async () => {
        setLoadingData(true);
        try {
            const postData = {
                "integrationId": integrationId,
                "reportId": id
            };
            const response = await axios.post(`${GlobalConst.API_URL}/auth/cloud-api/k8s/summary/dashboard/resource`, postData);
            if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
                setData(response.data);
            } else {
                setData({});
            }
        } catch (error) {
            console.error("Error fetching asset data:", error);
            setData({});
        } finally {
            setLoadingData(false);
        }
    };

    const fetchContainerVulnerabilities = async (scanReportId) => {
        setLoadingContainerVulData(scanReportId)
        try {
            const response = await axios.get(`${GlobalConst.API_URL}/auth/foss/report/${scanReportId}`);
            setLoadingContainerVulData("")
            if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
                setExpandedContainerVulnerabilities(prevState => ({
                    ...prevState,
                    [scanReportId]: response.data.scannerResponseEntity
                }));
            }
        } catch (error) {
            setLoadingContainerVulData("")
            console.error("Error fetching asset vulnerabilities:", error);
        }
    };

    const handleNameClick = (e, row) => {
        e.preventDefault();
        const activeRecordData = {
            "targetSystem": "k8s",
            "integrationId": integrationId,
            "reportId": id,
            "docType": row.docType,
            "name": row.name,
            "namespace": row.namespace
        };
        dispatch(updateSelectedK8sRecord(activeRecordData));
        setShowResourceDetailsModal(true);
    }

    const securityPostureColumns = [
        {
            title: "Sr No", key: "srNo", width: 60,
            render: (val, record, index) => index + 1
        },
        {
            title: 'Message', dataIndex: 'message', key: 'message', ellipsis: true, render: (text, record) => <>
                <MessagePopover record={record} />
                <span>{text}</span></>
        },
        { title: 'Framework', dataIndex: 'frameworkName', key: 'frameworkName', ellipsis: true, width: 110 },
        { title: 'Rule No', dataIndex: 'ruleNo', key: 'ruleNo', ellipsis: true, width: 90, align: 'left' },
        {
            title: 'Status',
            key: 'status',
            width: 100,
            render: (_, record) => (
                <span>
                    {record.violated === 'Pass' ? (
                        <CheckCircleFilled style={{ color: 'green' }} />
                    ) : (
                        <WarningFilled style={{ color: getStrokeColor('Critical') }} />
                    )}
                    {' '}
                    {record.violated}
                </span>
            ),
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
            width: 110,
            render: (text) => (
                <Tag
                    style={{
                        color: getFontColor(text),
                        borderRadius: 50,
                        fontSize: 12,
                        fontWeight: 600
                    }}
                    color={getStrokeColor(text)}
                    className="mx-auto text-center text-uppercase"
                >
                    {text}
                </Tag>
            ),
        },
        // {
        //     title: 'Action',
        //     render: (_, record) => (
        //         <Popover
        //             content={
        //                 <div style={{ width: 600, overflow: 'hidden', overflowX: 'scroll' }}>
        //                     <p className='mb-2'><strong>Description:</strong> {record.description}</p>
        //                     {record.impact && <p className='mb-2'><strong>Impact:</strong> {record.impact}</p>}
        //                     <div className='mb-1'>
        //                         <strong>References</strong>
        //                         {record.references && <ul>
        //                             {record.references.map((el, index) => (
        //                                 <li key={`ref-${record.name}-${index}`}>
        //                                     <a target='_blank' href={el} rel="noopener noreferrer">{el}</a>
        //                                 </li>
        //                             ))}
        //                         </ul>}
        //                     </div>
        //                 </div>
        //             }
        //             title="Details"
        //             trigger="click"
        //         >
        //             <a href="#" onClick={(e) => e.preventDefault()}>Details</a>
        //         </Popover>
        //     )
        // }
    ];

    // const expandedRowRender = (record) => {
    //     const hasMultipleContainers = record.containers && record.containers.length > 1;

    //     // if (hasMultipleContainers) {
    //     //     return (
    //     //         <Table
    //     //             size="small"
    //     //             columns={[
    //     //                 { title: 'Container Name', dataIndex: 'name', key: 'name' },
    //     //                 { title: 'Image', dataIndex: 'image', key: 'image' },
    //     //             ]}
    //     //             dataSource={record.containers}
    //     //             expandable={{
    //     //                 expandedRowRender: (containerRecord) => (
    //     //                     <Table
    //     //                         size="small"
    //     //                         columns={vulnerabilityColumns}
    //     //                         dataSource={containerRecord.vulnerabilities || []}
    //     //                         pagination={false}
    //     //                     />
    //     //                 ),
    //     //             }}
    //     //             pagination={false}
    //     //         />
    //     //     );
    //     // } else {
    //     // For single container or no container, show vulnerabilities directly
    //     if (loadingContainerVulData) {
    //         return (<div style={{ height: 200 }}><TabLoader /></div>);
    //     }
    //     const vulnerabilities = expandedContainerVulnerabilities[record.containers[0].scanReportId] || [];
    //     return (<VulDataTable
    //         searchResults={vulnerabilities}
    //         dataSet={vulnerabilities.scanResult}
    //     />);
    //     // }
    // };

    const expandedRowRender = (record) => {
        const hasMultipleContainers = !_.isEmpty(record.containers) && record.containers.length > 1;
        const myUpdatedData = (!_.isEmpty(record) && !_.isEmpty(record.policies))
            ? record.policies.map((row, index) => ({
                ...row,
                key: `k8s-adv-dashboard-expanded-${index}`
            }))
            : [];
        const filteredData = showAllComponents ? myUpdatedData : myUpdatedData.filter(item => item.violated.toLowerCase() === 'fail');

        // Sort the expanded data based on severity
        const sortedData = _.orderBy(filteredData,
            [item => {
                switch (item.severity.toLowerCase()) {
                    case 'critical': return 1;
                    case 'high': return 2;
                    case 'medium': return 3;
                    case 'low': return 4;
                    default: return 5;
                }
            }],
            ['asc']
        );
        let vulnerabilities = null
        if (!_.isEmpty(expandedContainerVulnerabilities) && !_.isEmpty(record.containers))
            vulnerabilities = expandedContainerVulnerabilities[record.containers[0].scanReportId]
        if (loadingContainerVulData  && !_.isEmpty(record.containers) && loadingContainerVulData === record.containers[0].scanReportId)
            return (<div style={{ height: 200 }}><TabLoader /></div>)

        return (<div style={{ paddingLeft: "40px" }}>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Vulnerability View" key="1">
                    {hasMultipleContainers ? <Table
                        size="small"
                        columns={[
                            { title: 'Container Name', dataIndex: 'containerName', key: 'name' },
                        ]}
                        dataSource={record.containers}
                        expandable={{
                            expandedRowRender: (containerRecord) => {
                                const vulnerabilities = expandedContainerVulnerabilities[containerRecord.scanReportId] || [];
                                return <VulDataTable
                                    searchResults={vulnerabilities}
                                    dataSet={vulnerabilities.scanResult}
                                />
                            },
                            onExpand: (expanded, record) => {
                                if (expanded) {
                                    fetchContainerVulnerabilities(record.scanReportId);
                                }
                            },
                        }}
                        pagination={false}
                    /> : (!_.isEmpty(vulnerabilities) ? <VulDataTable
                        searchResults={vulnerabilities}
                        dataSet={vulnerabilities.scanResult}
                    /> : <NoDataFound title={'No Data'} />)}
                </TabPane>
                <TabPane tab="Security Posture View" key="2">
                    <Table
                        size="small"
                        columns={securityPostureColumns}
                        dataSource={sortedData}
                        pagination={(!_.isEmpty(sortedData) && sortedData.length > 10) ? {
                            showTotal: (total, range) => {
                                return (
                                    <span className="text-white">
                                        {`${range[0]}-${range[1]} of ${total && total.format()} issues`}
                                    </span>
                                );
                            },
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            position: ["bottom", "left"],
                            className: "searchPagePagination px-0",
                            pageSizeOptions: [10, 20, 25],
                        } : false}
                    />
                </TabPane>
            </Tabs>
        </div>
        );
    };

    const getColumnSearchProps = (dataIndex, columnName, si, handleDetailsClick) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={si}
                    placeholder={`Search ${columnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        className="d-flex align-items-center justify-content-center"
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => debouncedReset(clearFilters, confirm, dataIndex)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
            // style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => si.current?.select(), 100);
            }
        },
        render: (text, record) => searchedColumn === dataIndex && !_.isEmpty(searchText)
            ? getHighlighted(text)
            : (<><Tooltip title={record.docType}><img src={getCloudIcon(record.docType, "k8s")} className='me-2' style={{ width: 18 }} /></Tooltip><a href='#' onClick={(e) => handleNameClick(e, record)}>{text}</a></>),
    });

    const getHighlighted = (text) => {
        return (
            <Highlighter
                highlightStyle={{
                    backgroundColor: "#ffc069",
                    padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
            />
        );
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const debouncedReset = useCallback(
        debounce((clearFilters, confirm, dataIndex) => {
            if (clearFilters) {
                clearFilters();
                setSearchText("");
            }
            confirm({ closeDropdown: false });
            setSearchedColumn(dataIndex);
        }, 250),
        []
    );

    const dataTableColumns = [
        {
            title: 'Name',
            dataIndex: 'displayName',
            key: 'displayName',
            ellipsis: true,
            ...getColumnSearchProps("displayName", "Name", searchInput),
        },
        {
            title: 'Total',
            key: 'total',
            align: 'center',
            width: 95,
            sorter: (a, b) => {
                const aTotal = a.criticalCve + a.highCve + a.mediumCve + a.lowCve || 0;
                const bTotal = b.criticalCve + b.highCve + b.mediumCve + b.lowCve || 0;
                return aTotal - bTotal;
            },
            sortDirections: ["descend"],
            render: (text, record) => {
                const total = record.criticalCve + record.highCve + record.mediumCve + record.lowCve;
                return (<>{total}</>);
            }
        },
        {
            title: 'Critical',
            dataIndex: 'criticalCve',
            key: 'critical',
            align: 'center',
            width: 100,
            sorter: (a, b) => {
                const aCritical = a.criticalCve || 0;
                const bCritical = b.criticalCve || 0;
                return aCritical - bCritical
            },
            sortDirections: ["descend"],
            render: (text, record) => (
                <Tag
                    style={{
                        color: getFontColor("CRITICAL"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14
                    }}
                    color={getStrokeColor("CRITICAL")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        },
        {
            title: 'High',
            dataIndex: 'highCve',
            key: 'high',
            align: 'center',
            width: 100,
            sorter: (a, b) => {
                const aHigh = a.highCve || 0;
                const bHigh = b.highCve || 0;
                return aHigh - bHigh
            },
            sortDirections: ["descend"],
            render: (text, record) => (
                <Tag
                    style={{
                        color: getFontColor("HIGH"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14
                    }}
                    color={getStrokeColor("HIGH")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        },
        {
            title: 'Medium',
            dataIndex: 'mediumCve',
            key: 'medium',
            align: 'center',
            width: 100,
            sorter: (a, b) => {
                const aMedium = a.mediumCve || 0;
                const bMedium = b.mediumCve || 0;
                return aMedium - bMedium
            },
            sortDirections: ["descend"],
            render: (text, record) => (
                <Tag
                    style={{
                        color: getFontColor("MEDIUM"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14
                    }}
                    color={getStrokeColor("MEDIUM")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        },
        {
            title: 'Low',
            dataIndex: 'lowCve',
            key: 'low',
            align: 'center',
            width: 100,
            width: 95,
            sorter: (a, b) => {
                const aLow = a.lowCve || 0;
                const bLow = b.lowCve || 0;
                return aLow - bLow
            },
            sortDirections: ["descend"],
            render: (text, record) => (
                <Tag
                    style={{
                        color: getFontColor("LOW"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14
                    }}
                    color={getStrokeColor("LOW")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        },
        {
            title: ''
        }
    ];
    const onChangeShowAllComponents = (checked) => {
        setShowAllComponents(checked);
    };

    const filteredAndSortedData = useMemo(() => {
        if (!_.isEmpty(data.assetSummary)) {
            let filtered = showAllComponents ? data.assetSummary : data.assetSummary.filter(item =>
                item.criticalCve > 0 || item.highCve > 0 || item.mediumCve > 0 || item.lowCve > 0
            );

            return _.orderBy(filtered,
                ['criticalCve', 'highCve', 'mediumCve', 'lowCve'],
                ['desc', 'desc', 'desc', 'desc']
            ).map((row, index) => ({
                ...row,
                key: `k8s-adv-dashboard-${index}`
            }));
        }
        return [];
    }, [data.assetSummary, showAllComponents]);

    if (loadingData) {
        return (<div style={{ height: 200 }}><TabLoader /></div>);
    }

    return (
        <div>
            <div className='d-flex align-items-center justify-content-end mb-4 me-3 mt-2' style={{ position: 'absolute', right: 0, top: '-56px' }}>
                <Switch onChange={onChangeShowAllComponents} checked={showAllComponents} className='me-2' />Show All
            </div>
            <Table
                size='small'
                className='custom-table mb-0 fixedScrollCustomTable table-hover component-view-table'
                dataSource={filteredAndSortedData}
                columns={dataTableColumns}
                locale={{
                    emptyText: <NoDataFound title={'No Data'} />
                }}
                expandable={{
                    expandedRowRender,
                    rowExpandable: (record) => !_.isEmpty(record.policies) || !_.isEmpty(record.containers),
                    onExpand: (expanded, record) => {
                        if(!_.isEmpty(record.containers)){
                            const isVulnerabilityFetched = !_.isEmpty(expandedContainerVulnerabilities) ? Object.keys(expandedContainerVulnerabilities).some((reportId) => reportId === record.containers[0].scanReportId) : false
                            if (expanded && !isVulnerabilityFetched) {
                                fetchContainerVulnerabilities(record.containers[0].scanReportId);
                            }
                        }
                        
                    },
                }}
                pagination={(!_.isEmpty(filteredAndSortedData) && filteredAndSortedData.length > 10) ? {
                    showTotal: (total, range) => (
                        <span className="text-white">
                            {`${range[0]}-${range[1]} of ${total && total.format()} issues`}
                        </span>
                    ),
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    position: ["bottom", "left"],
                    className: "searchPagePagination px-0",
                    pageSizeOptions: [10, 20, 25],
                } : false}
            />
        </div>
    );
}