import React from 'react';
import * as d3 from 'd3';
import { hierarchy } from 'd3';
import { useD3 } from '../../shared/hooks/useD3';
import { getStrokeColor, getFontColor } from '../../shared/helper/genHelper';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import _ from 'lodash';
// import randomColor from 'randomcolor';


function createSVG(data, { // data is either tabular (array of objects) or hierarchy (nested objects)
    path, // as an alternative to id and parentId, returns an array identifier, imputing internal nodes
    id = Array.isArray(data) ? d => d.id : null, // if tabular data, given a d in data, returns a unique identifier (string)
    parentId = Array.isArray(data) ? d => d.parentId : null, // if tabular data, given a node d, returns its parent’s identifier
    children, // if hierarchical data, given a d in data, returns its children
    tree = d3.tree, // layout algorithm (typically d3.tree or d3.cluster)
    sort, // how to sort nodes prior to layout (e.g., (a, b) => d3.descending(a.height, b.height))
    label, // given a node d, returns the display name
    title, // given a node d, returns its hover text
    link, // given a node d, its link (if any)
    linkTarget = "_blank", // the target attribute for links (if any)
    width = 640, // outer width, in pixels
    height, // outer height, in pixels
    color = t => d3.interpolateRdBu(1 - t),
    r = 3.65, // radius of nodes
    padding = 0.8, // horizontal padding for first and last column
    fill = "#999", // fill for nodes
    fillOpacity, // fill opacity for nodes
    stroke = "#555", // stroke for links
    strokeWidth = 2, // stroke width for links
    strokeOpacity = 0.9,//0.4, // stroke opacity for links
    strokeLinejoin, // stroke line join for links
    strokeLinecap, // stroke line cap for links
    halo = "#fff", // color of label halo 
    haloWidth = 2, // padding around the labels
    curve = d3.curveBumpX, // curve for the link
} = {}, svg) {

    // If id and parentId options are specified, or the path option, use d3.stratify
    // to convert tabular data to a hierarchy; otherwise we assume that the data is
    // specified as an object {children} with nested objects (a.k.a. the “flare.json”
    // format), and use d3.hierarchy.
    const root = path != null ? d3.stratify().path(path)(data)
        : id != null || parentId != null ? d3.stratify().id(id).parentId(parentId)(data)
            : d3.hierarchy(data, children);

    // console.log("I am the root here:::", root)

    // Sort the nodes.
    if (sort != null) root.sort(sort);

    // Compute labels and titles.
    const descendants = root.descendants();
    const L = label == null ? null : descendants.map(d => label(d.data, d));

    // Compute the layout.
    const dx = 15;
    const dy = width / (root.height + padding);
    tree().nodeSize([dx, dy])(root);

    // Center the tree.
    let x0 = Infinity;
    let x1 = -x0;
    root.each(d => {
        if (d.x > x1) x1 = d.x;
        if (d.x < x0) x0 = d.x;
    });

    // Compute the default height.
    if (height === undefined) height = x1 - x0 + dx * 2;

    // Use the required curve
    if (typeof curve !== "function") throw new Error(`Unsupported curve`);

    // const svg = d3.create("svg")
    svg
        .attr('shape-rendering', 'geometricPrecision')
        .attr("viewBox", [-dy * padding / 2, x0 - dx, width, height])
        .attr("width", width)
        .attr("height", height)
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
        // .attr("font-family", "sans-serif")
        .attr("font-size", 10);
    svg.selectAll("*").remove();



    const defs = svg.append('defs');
    // Create Defs for emosed view

    var sg = defs.append("linearGradient")
        .attr("id", "simple-gradient") //unique id for reference
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "100%")
        .attr("y2", "0")
        .attr("spreadMethod", "reflect");

    var sco = ["#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874"];

    //Append the colors evenly along the gradient
    sg.selectAll(".stop")
        .data(sco)
        .enter().append("stop")
        .attr("offset", function (d, i) { return i / (sco.length - 1); })
        .attr("stop-color", function (d) { return d; });

    sg.append("animate")
        .attr("attributeName", "x1")
        .attr("values", "0%;200%") //let x1 run to 200% instead of 100%
        .attr("dur", "12s")
        .attr("repeatCount", "indefinite");

    sg.append("animate")
        .attr("attributeName", "x2")
        .attr("values", "100%;300%") //let x2 run to 300% instead of 200%
        .attr("dur", "12s")
        .attr("repeatCount", "indefinite");





    var lg = defs.append("linearGradient")
        .attr("id", "animate-gradient") //unique id for reference
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "100%")
        .attr("y2", "0")
        .attr("spreadMethod", "reflect");

    // //A color palette that is 4 colors
    // //(the last 3 colors are the reverse of the start)
    // // #1ff2ec
    var co = ["#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#ed3608", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874"];
    // // var co = ["#ed3608", "#ed3608", "#ed3608", "#ed3608", "#ed3608"];
    // // var co = ["#af1c1a", "#af1c1a", "#af1c1a", "#af1c1a", "#af1c1a", "#af1c1a", "#af1c1a", "#af1c1a", "#ffb5b4", "#af1c1a", "#af1c1a", "#af1c1a", "#af1c1a", "#af1c1a", "#af1c1a", "#af1c1a", "#af1c1a"];
    // // var co = ["#005874", "#005874", "#ed3608", "#005874", "#005874", "#005874", "#005874", "#ed3608", "#005874", "#005874", "#005874", "#005874", "#ed3608", "#005874", "#005874"];

    // //Append the colors evenly along the gradient
    lg.selectAll(".stop")
        .data(co)
        .enter()
        .append("stop")
        .attr("offset", function (d, i) { return i / (co.length - 1); })
        .attr("stop-color", function (d) { return d; });

    lg.append("animate")
        .attr("attributeName", "x1")
        .attr("values", "0%;200%") //let x1 run to 200% instead of 100%
        .attr("dur", "12s")
        .attr("repeatCount", "indefinite");

    lg.append("animate")
        .attr("attributeName", "x2")
        .attr("values", "100%;300%") //let x2 run to 300% instead of 200%
        .attr("dur", "12s")
        .attr("repeatCount", "indefinite");

    // var sunSpeed = 0.5;
    // var gradTl = gsap.timeline({ repeat: -1, repeatDelay: 2, delay: 1 });

    // // gradTl.staggerTo(".class", 1, { attr: { x1: '0%;200%' }, opacity: 0, ease: 'none' }, 0.2);
    // gradTl.staggerTo("#animate-gradient stop", sunSpeed, { stopColor: '#005874' }, 0.2);
    // gradTl.staggerTo("#animate-gradient stop", sunSpeed, { stopColor: '#005874' }, 0.2);
    // gradTl.staggerTo("#animate-gradient stop", sunSpeed, { stopColor: '#ed3608' }, 0.2);
    // gradTl.staggerTo("#animate-gradient stop", sunSpeed, { stopColor: '#005874' }, 0.2);
    // gradTl.staggerTo("#animate-gradient stop", sunSpeed, { stopColor: '#005874' }, 0.2);





































    var lg1 = defs.append("linearGradient")
        .attr("id", "critical-gradient") //unique id for reference
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "100%")
        .attr("y2", "0%")
        .attr("spreadMethod", "reflect");
    // var co1 = ["#812422", "#812422", "#812422", "#812422", "#812422", "#812422", "#812422", "#812422", "#ed3608", "#812422", "#812422", "#812422", "#812422", "#812422", "#812422", "#812422", "#812422"];
    var co1 = ["#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#ed3608", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874"];
    lg1.selectAll(".stop")
        .data(co1)
        .enter().append("stop")
        // .attr("offset", function (d, i) { return i / (co.length - 1); })
        .attr("offset", function (d, i) { return i / (co1.length - 1); })
        .attr("stop-color", function (d) { return d; });

    lg1.append("animate")
        .attr("attributeName", "x1")
        .attr("values", "0%;200%") //let x1 run to 200% instead of 100%
        .attr("dur", "12s")
        .attr("repeatCount", "indefinite");

    lg1.append("animate")

        .attr("attributeName", "x2")
        .attr("values", "100%;300%") //let x2 run to 300% instead of 200%
        .attr("dur", "12s")
        .attr("repeatCount", "indefinite");















    var dropShadowFilter = defs.append('svg:filter')
        .attr('id', 'drop-shadow')
        .attr('filterUnits', "userSpaceOnUse")
        .attr('width', '250%')
        .attr('height', '250%');
    dropShadowFilter.append('svg:feGaussianBlur')
        .attr('in', 'SourceGraphic')
        .attr('stdDeviation', 5)
        .attr('result', 'blur-out');
    dropShadowFilter.append('svg:feColorMatrix')
        .attr('in', 'blur-out')
        .attr('type', 'hueRotate')
        .attr('values', 180)
        .attr('result', 'color-out');
    dropShadowFilter.append('svg:feOffset')
        .attr('in', 'color-out')
        .attr('dx', 0)
        .attr('dy', 0)
        .attr('result', 'the-shadow');
    dropShadowFilter.append('svg:feBlend')
        .attr('in', 'SourceGraphic')
        .attr('in2', 'the-shadow')
        .attr('mode', 'normal');




    svg.append("g")
        .attr("fill", "none")

        // .attr("stroke-opacity", strokeOpacity)
        // .attr("stroke-linecap", strokeLinecap)
        // .attr("stroke-linejoin", strokeLinejoin)
        // .attr("stroke-width", strokeWidth)
        .selectAll("path")
        .data(root.links())
        .join("path")
        .attr("d", d3.link(curve)
            .x(d => d.y)
            .y(d => d.x))
        .attr("class", (d, index) => {
            if (d.source.data.type === 'root' && d.target.data.type === 'module') {
                if (d.source.data.hasCritical === true && d.target.data.hasCritical === true) {
                    return 'critical'
                }
                else {
                    return 'simple'
                }
            }
            if (d.source.data.type === 'module' && d.target.data.type === 'component') {
                if (d.source.data.hasCritical === true && d.target.data.hasCritical === true) {
                    return 'critical'
                }
                else {
                    return 'simple'
                }
            }
            if (d.source.data.type === 'component' && d.target.data.type === 'cve') {
                if (d.target.data.severity === 'CRITICAL') {
                    return 'critical'
                }
                // else if (d.target.data.severity === 'HIGH') {
                //     return 'url(#high-gradient)'
                // }
                // else if (d.target.data.severity === 'MEDIUM') {
                //     return 'url(#medium-gradient)'
                // }
                // else if (d.target.data.severity === 'LOW') {
                //     return 'url(#low-gradient)'
                // }
                else {
                    return 'simple'
                }
            }
        })
        .attr("stroke", (d, index) => {
            if (d.source.data.type === 'root' && d.target.data.type === 'module') {
                if (d.source.data.hasCritical === true && d.target.data.hasCritical === true) {
                    return 'url(#animate-gradient)'
                }
                else {
                    return 'url(#simple-gradient)'
                }
            }
            if (d.source.data.type === 'module' && d.target.data.type === 'component') {
                if (d.source.data.hasCritical === true && d.target.data.hasCritical === true) {
                    return 'url(#animate-gradient)'
                }
                else {
                    return 'url(#simple-gradient)'
                }
            }
            if (d.source.data.type === 'component' && d.target.data.type === 'cve') {
                if (d.target.data.severity === 'CRITICAL') {
                    return 'url(#animate-gradient)'
                }
                // else if (d.target.data.severity === 'HIGH') {
                //     return 'url(#high-gradient)'
                // }
                // else if (d.target.data.severity === 'MEDIUM') {
                //     return 'url(#medium-gradient)'
                // }
                // else if (d.target.data.severity === 'LOW') {
                //     return 'url(#low-gradient)'
                // }
                else {
                    return 'url(#simple-gradient)'
                }
            }
            return GlobalConst.CHART_COLORS[1];
        })
        .attr("stroke-width", strokeWidth)
        .attr("stroke-opacity", strokeOpacity)
        .attr("stroke-linecap", strokeLinecap)
        .attr("stroke-linejoin", strokeLinejoin)
        .attr("stroke-dasharray", function () {
            const length = this.getTotalLength();
            return `${length} ${length}`;
        })
        .attr("stroke-dashoffset", function () {
            const length = this.getTotalLength();
            return length;
        })
        .transition()
        .duration(1500)
        .delay(linkObj => linkObj.source.depth * 1500)
        .attr("stroke-dashoffset", 0);

    // .attr("stroke", (d, i) => { console.log("here this ::", color(d3.easeQuad(i / ((1 << 6) - 1)))); return color(d3.easeQuad(i / ((1 << 6) - 1)))});



    // let pathLines = document.getElementsByClassName("critical");
    // console.log("Path Lines:", pathLines);

    // _.forEach(pathLines, (el) => {
    //     console.log("Element:", el);
    // gsap.to(".critical", {
    //     strokeDashoffset: 1,
    //     // strokeDasharray: '10 10',
    //     repeat: -1,
    //     ease: "none",
    //     duration: 1
    //   });
    // })
    // TweenLite.defaultEase = Linear.easeNone;










    const node = svg.append("g")
        .selectAll("a")
        .data(root.descendants())
        .join("a")
        // .attr("xlinkHref", link == null ? null : d => link(d.data, d))
        // .attr("target", link == null ? null : linkTarget)
        .attr("transform", d => `translate(${d.y},${d.x})`);

    node.append("circle")
        .attr("fill", d => {
            // console.log("D::", d);
            if (d.data.type === 'root') {
                return GlobalConst.CHART_COLORS[9];
            }
            if (d.data.type === 'module') {
                return GlobalConst.CHART_COLORS[0];
            }
            if (d.data.type === 'component') {
                return GlobalConst.CHART_COLORS[1];
            }
            if (d.data.type === 'cve') {
                let color = getStrokeColor(d.data.severity);
                return (color) ? color : fill;
            }
            return d.children ? stroke : fill;
        })
        .attr("r", r)
        // .attr('cx', (d) => { console.log("In the d::", d) })
        // .attr('cy', r+2)
        .attr('filter', 'url(#drop-shadow)')
        .attr("opacity", 0)
        .transition()
        .duration(1500)
        .delay(node => node.depth * 1500)
        .attr("opacity", 1);

    if (title != null) node.append("title")
        .text(d => title(d.data, d));

    if (L) node.append("text")
        .attr("dy", d => d.children ? "1.5em" : "0.3em")
        .attr("dx", d => d.children ? 0 : 10)
        .attr("text-anchor", d => d.children ? "middle" : "start")
        .attr("paint-order", "stroke")
        .attr('fill', '#ffffff')
        .attr("stroke", '#212529')
        .attr("stroke-width", 5)
        .attr("stroke-opacity", 0.7)
        // .attr("stroke", halo)
        // .attr("stroke-width", 5)
        .text((d, i) => L[i])
        .attr("opacity", 0)
        .transition()
        .duration(1500)
        .delay(node => node.depth * 1500)
        .attr("opacity", 1);

    // const root = hierarchy(data);
    // console.log("descendents", root.descendants());
    // console.log("links", root.links());

    // const allCritical = document.getElementsByClassName('critical');
    // _.forEach(allCritical, (el) => {
    //     el.setAttribute("stroke", "url(#animate-gradient)");
    // });

    // setInterval(() => {
    //     // const allCritical = document.getElementsByClassName('critical');
    //     if (!_.isEmpty(allCritical)) {

    //         _.forEach(allCritical, (el) => {
    //             el.setAttribute("stroke", "#ed3608");
    //         });
    //         // console.log("Made the stroke red");
    //         setTimeout(() => {
    //             _.forEach(allCritical, (el) => {
    //                 el.setAttribute("stroke", "url(#animate-gradient)");
    //             });
    //         }, 2000);

    //     }
    //     // console.log("We are critical path", allCritical);
    // }, 7000);








    return svg.node();
}


export default function TidyTreeChart({ data, options }) {
    const ref = useD3((svg) => {
        createSVG(data, options, svg);

    }, [data]);

    // console.log("I am the data here:", JSON.stringify(data))

    return (
        <>
            {data ?
                <svg
                    ref={ref}
                    style={{
                        height: 500,
                        width: "100%",
                        marginRight: "0px",
                        marginLeft: "0px",
                    }}
                ></svg>
                : ''}
        </>
    );
}