import React, { useEffect, useState, useMemo, useRef } from "react";
import ReactEcharts from "echarts-for-react";
import _ from 'lodash';
import convertData from './convertData';
import { useDispatch } from "react-redux";
import { GlobalConst } from "../../../shared/appConfig/globalConst";
import { getCloudIcon, getStrokeColor } from "../../../shared/helper/genHelper";
import './style.css';
import { updateSelectedK8sRecord } from "../../../shared/redux/reducers/kubernetesSecurityReducer";
import { useParams } from "react-router-dom";

export default function GraphChart({ apidata, setShowResourceDetailsModal }) {
  const dispatch = useDispatch();
  const chartRef = useRef(null);

  const initialData = useMemo(() => convertData(apidata, false, []), [apidata]);
  const [graphData, setGraphData] = useState(initialData);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [graphCenter, setGraphCenter] = useState(['50%', '50%']);
  const [clickedNodeId, setClickedNodeId] = useState(null);
  const { id, integrationId } = useParams();
  useEffect(() => {
    setGraphData(convertData(apidata, false, expandedNodes));
  }, [expandedNodes, apidata]);

  // useEffect(() => {
  //   if (chartRef.current) {
  //     const echartsInstance = chartRef.current.getEchartsInstance();
  //     echartsInstance.on('finished', logNodePositions);
  //   }

  //   return () => {
  //     if (chartRef.current) {
  //       const echartsInstance = chartRef.current.getEchartsInstance();
  //       echartsInstance.off('finished', logNodePositions);
  //     }
  //   };
  // }, [graphData]);

  useEffect(() => {
    if (clickedNodeId !== null) {
      setTimeout(() => {
        centerViewOnNode(clickedNodeId);
      }, 100);
    }
  }, [clickedNodeId]);

  const getNodePosition = (dataIndex) => {
    if (chartRef.current) {
      const echartsInstance = chartRef.current.getEchartsInstance();
      const series = echartsInstance.getModel().getSeries()[0];
      const layout = series.preservedPoints || series.graph.data;

      if (layout && layout[dataIndex]) {
        return {
          x: layout[dataIndex][0],
          y: layout[dataIndex][1]
        };
      }
    }
    return null;
  };

  // const logNodePositions = () => {
  //   if (chartRef.current) {
  //     const echartsInstance = chartRef.current.getEchartsInstance();
  //     const series = echartsInstance.getModel().getSeries()[0];
  //     const data = series.getData();
  //     const layout = series.preservedPoints || series.graph.data;

  //     console.log("Node Positions:");
  //     data.each((idx) => {
  //       const item = data.getItemModel(idx);
  //       const name = item.get('name');
  //       const id = item.get('id');
  //       const docType = item.get('docType');
  //       const position = layout[idx];

  //       console.log(`Node ${idx} (${name}):`, {
  //         x: position ? position[0] : undefined,
  //         y: position ? position[1] : undefined,
  //         id: id,
  //         docType: docType
  //       });
  //     });
  //   }
  // };

  const centerViewOnNode = (nodeId) => {
    if (chartRef.current) {
      const echartsInstance = chartRef.current.getEchartsInstance();
      const nodePosition = getNodePosition(nodeId);

      if (nodePosition) {
        const { x, y } = nodePosition;

        // Get the current zoom level
        const zoom = echartsInstance.getOption().series[0].zoom;

        // Set the center of the graph to the node's position
        echartsInstance.setOption({
          series: [{
            center: [x, y],
            zoom: zoom  // Maintain the current zoom level
          }]
        });

        // console.log("Centering node:", nodeId, "Position:", x, y);
      }
    }
  };

  const handleNodeClick = (params) => {
    if (params.dataType === 'node') {
      const clickedNode = params.data;
      let newExpandedNodes = _.cloneDeep(expandedNodes);
      if (_.find(newExpandedNodes, (node) => node === clickedNode.uniqId)) {
        newExpandedNodes = _.filter(newExpandedNodes, (node) => node != clickedNode.uniqId);
      }
      else {
        newExpandedNodes.push(clickedNode.uniqId);
      }
      setExpandedNodes(newExpandedNodes);
      if (clickedNode.hasChildren === false) {
        // here is the node clicked logic to open the node details modal
        if (_.includes(GlobalConst.EXCLUDED_GRAPH_CLICK_KEYS, clickedNode.docType.toLowerCase())) { return false; }
        // hideResourceDetailsModal();
        const resourceInfo = {
          "targetSystem": "k8s",
          "integrationId": integrationId,
          "reportId": id,
          "docType": clickedNode.docType,
          "name": clickedNode.name,
          "namespace": clickedNode.namespace
        }
        dispatch(updateSelectedK8sRecord(resourceInfo))
        setShowResourceDetailsModal(true);
      }
      // const position = getNodePosition(params.dataIndex);
      // console.log("Clicked Node Position:", position);
      // console.log("I'll be moving to position:", params.event.offsetX, params.event.offsetY);
      setTimeout(() => {
        setClickedNodeId(params.dataIndex);

      }, 100);

    }
  };

  // const moveToPosition = (x, y) => {
  //   if (chartRef.current) {
  //     const echartsInstance = chartRef.current.getEchartsInstance();
  //     const option = echartsInstance.getOption();

  //     if (option.series && option.series[0]) {
  //       // option.series[0].center = [x, y];
  //       // option.series[0].zoom = 1;  // Reset zoom level
  //       const containerWidth = echartsInstance.getWidth();
  //       const containerHeight = echartsInstance.getHeight();
  //       option.series[0].center = [`${(x / containerWidth) * 100}%`, `${(y / containerHeight) * 100}%`];

  //       // Remove left, top, right, bottom properties
  //       option.series[0].left = null;
  //       option.series[0].top = null;
  //       option.series[0].right = null;
  //       option.series[0].bottom = null;
  //     }

  //     setTimeout(() => {
  //       console.log("I am moved to the new location", option);
  //       echartsInstance.setOption(option, {
  //         animation: true,
  //         animationDuration: 1000
  //       });
  //     }, 3000)

  //   }
  // };

  const options = useMemo(() => ({
    title: false,
    legend: false,
    // toolbox: {
    //   feature: {
    //     dataZoom: {
    //       yAxisIndex: 'none'
    //     },
    //     restore: {},
    //     saveAsImage: {}
    //   }
    // },
    // tooltip: {
    //   trigger: 'item',
    //   formatter: function (params) {
    //     if (params.dataType === 'node') {
    //       const { name, docType, vulnerability } = params.data;
    //       const severityList = Object.entries(vulnerability)
    //         .map(([severity, count]) => `
    //           <div style="display: flex; align-items: center; margin-bottom: 5px;">
    //             <div style="width: 10px; height: 10px; border-radius: 50%; background-color: ${getStrokeColor(severity.toUpperCase())}; margin-right: 5px;"></div>
    //             <span>${severity.charAt(0).toUpperCase() + severity.slice(1)}: ${count}</span>
    //           </div>
    //         `)
    //         .join('');

    //       return `
    //         <div style="padding: 8px;padding-bottom: 3px">
    //           <div>
    //             ${severityList}
    //           </div>
    //         </div>
    //       `;
    //     }
    //   },
    //   backgroundColor: 'rgba(50, 50, 50, 0.9)',
    //   borderColor: '#333',
    //   borderWidth: 1,
    //   textStyle: {
    //     color: '#fff'
    //   }
    // },
    series: [
      {
        type: 'graph',
        layout: 'force',
        animation: false,
        label: {
          show: true,
          position: 'right',
          formatter: '{b}',
          fontSize: 12,
          fontWeight: 'normal',
          color: '#ffffff',
          textBorderColor: 'transparent',
          textBorderWidth: 0,
          textShadowColor: 'transparent',
          textShadowBlur: 0,
          textShadowOffsetX: 0,
          textShadowOffsetY: 0,
          // formatter: (params) => { // this code is working
          //   console.log("Params", params);
          //   if (params.data.category > 0) {
          //     if (!_.includes(expandedNodes, params.data.uniqId)) {
          //       const arrow = params.data.hasChildren ? `▶` : '';
          //       return `${params.name} ${arrow}`;
          //     } else {
          //       return `${params.name}`;
          //     }
          //   }
          //   else {
          //     return `${params.name}`;
          //   }
          // }
          // formatter: (params) => { //this code is working
          //   const severityCounts = {
          //     ...params.data.vulnerability,
          //   };
          //   const sortedSeverity = _.fromPairs(
          //     _.sortBy(
          //       _.toPairs(severityCounts),
          //       ([key]) => ['critical', 'high', 'medium', 'low'].indexOf(key)
          //     )
          //   );
          //   const severityLine = _.map(sortedSeverity, (count, severity) => {
          //     return `{${severity}|${count}}`;
          //   }).join(', ');
          //   const arrow = params.data.hasChildren && !_.includes(expandedNodes, params.data.uniqId) ? ' ▶' : '';
          //   return [
          //     `{name|${params.name}${arrow}}`,
          //     `{lineBreak|}`,
          //     `(${severityLine})`
          //   ].join('\n');
          // },
          // rich: {
          //   name: {
          //     color: '#ffffff',
          //     fontSize: 12,
          //     fontWeight: 'normal',
          //   },
          //   lineBreak: {
          //     height: 5,  // Adjust this value to increase/decrease space between lines
          //   },
          //   critical: { color: getStrokeColor('CRITICAL') }, // Red for Critical
          //   high: { color: getStrokeColor('HIGH')  }, // Orange for Important
          //   medium: { color: getStrokeColor('MEDIUM')  }, // Yellow for Medium
          //   low: { color: getStrokeColor('LOW')  }, // Green for Low
          // }
          formatter: (params) => {
            const severityCounts = {
              ...params.data.vulnerability,
            };
            const sortedSeverity = _.fromPairs(
              _.sortBy(
                _.toPairs(severityCounts),
                ([key]) => ['critical', 'high', 'medium', 'low', 'na'].indexOf(key)
              )
            );
            let total = 0;
            const severityCircles = _.map(sortedSeverity, (count, severity) => {
              if (count > 0) {
                total = total + count;
                return `{${severity}|${count}}`;
              }
            }).join(' ');

            let circleText = '';
            if (severityCircles) {
              circleText = severityCircles;
            }

            const severityOrder = ['critical', 'high', 'medium', 'low'];
            const firstNonZeroSeverity = severityOrder.find(severity => sortedSeverity[severity] > 0);

            let totalText = total ? `{${firstNonZeroSeverity}|${total}}` : ``;
            // console.log("severityCircles_array", severityCircles_array);
            // let totalText = total ? `{critical|${total}}` : ``;
            // ▶

            const arrow = params.data.hasChildren && !_.includes(expandedNodes, params.data.uniqId) && params.data.parentId !== null ? ` {expander|▶}` : '';
            return [
              `{name|${params.data.displayName}} ${arrow}`,
              `{lineBreak|}`,
              // `{critical|${total}}`,
              totalText
              // circleText
            ].join('\n');
          },
          rich: {
            name: {
              color: '#ffffff',
              fontSize: 12,
              fontWeight: 'normal',
            },
            lineBreak: {
              height: 5,
            },
            critical: {
              // backgroundColor: `linear-gradient(90deg, ${getStrokeColor('CRITICAL')} 0%, ${getStrokeColor('HIGH')}) 35%, ${getStrokeColor('MEDIUM')} 61%, ${getStrokeColor('LOW')} 100%)`,
              // backgroundColor: `linear-gradient(90deg, ${getStrokeColor('CRITICAL')} 0%, ${getStrokeColor('HIGH')}) 35%, ${getStrokeColor('MEDIUM')} 61%, ${getStrokeColor('LOW')} 100%)`,
              backgroundColor: getStrokeColor('CRITICAL'),
              color: '#212121',
              borderRadius: 10,
              padding: [3, 3, 3, 3],
              fontSize: 10,
              lineHeight: 11,
            },
            high: {
              backgroundColor: getStrokeColor('HIGH'),
              color: '#212121',
              borderRadius: 10,
              padding: [3, 3, 3, 3],
              fontSize: 10,
              lineHeight: 11,
            },
            medium: {
              backgroundColor: getStrokeColor('MEDIUM'),
              color: '#212121',
              borderRadius: 10,
              padding: [3, 3, 3, 3],
              fontSize: 10,
              lineHeight: 11,
            },
            low: {
              backgroundColor: getStrokeColor('LOW'),
              color: '#212121',
              borderRadius: 10,
              padding: [3, 3, 3, 3],
              fontSize: 10,
              lineHeight: 11,
            },
            expander: {
              color: '#0e6ecf'
            }
            // tick: {
            //   backgroundColor: 'green',
            //   color: '#fff',
            //   borderRadius: 10,
            //   padding: [3, 3, 3, 3],
            //   fontSize: 12,
            //   lineHeight: 11,
            // }
          }
        },
        draggable: true,
        data: graphData.nodes.filter(node => !node.hidden).map(function (node, idx) {
          return {
            ...node,
            id: idx,
            symbol: 'image',
            symbolSize: [15, 15], // Adjust size as needed
            symbolOffset: [0, 0],
            itemStyle: {
              color: node.collapsed ? '#999' : undefined,
              cursor: 'pointer',
            },
            symbol: `image://${getCloudIcon(node.docType, "k8s") || getCloudIcon('')}`, // Use a placeholder if no image is provided
            emphasis: {
              itemStyle: {
                cursor: 'pointer'
              }
            }
          };
        }),
        categories: graphData.categories,
        labelLayout: {
          hideOverlap: true
        },
        force: {
          repulsion: 90,
          edgeLength: 30,
          layoutAnimation: false,
          initLayout: 'none'
        },
        edges: graphData.links.filter(link => !link.hidden),
        roam: true,
        lineStyle: {
          color: 'source',
          curveness: 0.3
        },
        focusNodeAdjacency: false,
        zoom: 5,
        center: graphCenter,
        // emphasis: {
        //   focus: 'adjacency',
        //   lineStyle: {
        //     width: 5
        //   }
        // }
      }
    ]
  }), [graphData, graphCenter, expandedNodes]);

  const onEvents = {
    'click': handleNodeClick,
  };

  return (
    <ReactEcharts
      ref={chartRef}
      option={options}
      style={{ height: '100%', width: '100%', cursor: 'pointer' }}
      opts={{ renderer: 'svg' }}
      onEvents={onEvents}
      className="echarts-graph-container"
    />
  );
}