import React, { useState, useEffect } from "react";
import _ from 'lodash';
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, InputNumber, Select, Tooltip} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { faCheck, faGears, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import moment from 'moment';
import { GlobalConst } from "../../shared/appConfig/globalConst";
// import { setLoginModalViewStatus } from "../../shared/redux/reducers/authReducer";

export default function SettingsPage() {

    // const dispatch = useDispatch();
    const history = useHistory();
    const [apiKey, setAPIKey] = useState('');
    const [expiry, setExpiry] = useState('');
    const [triggeredGenKey, setTriggeredGenKey] = useState(false);
    const [loadingKey, setLoadingKey] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [apiKeyType, setAPIKeyType] = useState('password');
    const [copiedText, setCopiedText] = useState(false);
    const [expiryValue, setExpiryValue] = useState(30);
    const [isCustom, setIsCustom] = useState(false);


    // useEffect(() => {
    //     async function isSessionActive() {
    //         let ls = await localStorage.getItem("__t");
    //         if (_.isEmpty(ls)) {
    //             history.push("/");
    //             window.location.href = GlobalConst.BASE_URL + '/login';
    //             // dispatch(setLoginModalViewStatus(true));
    //         }
    //     }
    //     isSessionActive();
    // }, []);


    useEffect(() => {
        setLoadingKey(true);
        axios.get(`${GlobalConst.API_URL}/auth/foss/get-api-key`)
            .then(op => {
                setLoadingKey(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.api)) {
                    setAPIKey(op.data.api.apiKey);
                    setExpiry(op.data.api.expiry);
                    setShowAlert(false);
                }
                else {
                    setShowAlert(true);
                }
            })
            .catch(e => {
                setLoadingKey(false);
                console.log("Exception: ", e)
            });
    }, []);

    useEffect(() => {

        if (copiedText) {
            setTimeout(() => {
                setCopiedText(false);
            }, 2000)
        }
    }, [copiedText]);

    const handleGenerateAPIKeyClick = () => {
        let postBody = {
            expiryDays: expiryValue,
            type:'api'
        };
        setTriggeredGenKey(true);
        axios.post(`${GlobalConst.API_URL}/auth/foss/generate-key`, postBody)
            .then(op => {
                setTriggeredGenKey(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setAPIKey(op.data.apiKey);
                    setExpiry(op.data.expiry);
                    setShowAlert(false);
                }
            })
            .catch(e => {
                setTriggeredGenKey(false);
                console.log("Exception:", e);
            });
    }

    const handleKeyTypeClick = () => {
        setAPIKeyType((apiKeyType === 'text') ? 'password' : 'text')
    }

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(apiKey);
        setCopiedText(true);
    }

    const keyExpired = (expiryDate) => {
        // console.log("Expiry Date:", expiryDate);
        const currentDate = moment();
        const expiry = moment(expiryDate);
        // console.log("ExpiryLL", expiry);
        if (expiry.isBefore(currentDate)) {
            // console.log("true::");
            return true;
        } else {
            // console.log("::false::");
            return false;
        }
    }

    const handleExpiryFilterChange = (value) => {
        setIsCustom(value === 'custom');
        setExpiryValue(value === 'custom' ? 1 : value);
    };

    const handleExpiryInputChange = (value) => {
        setExpiryValue(value);
    }

    return (
        <section>
            {/* <section> */}
            {/* <div className="mb-20">
                    <div className="">
                        <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-0 text-white d-flex align-items-center justify-content-start">
                            <FontAwesomeIcon icon={faGears} className="me-2" />
                            Settings
                        </h2>
                    </div>
                </div> */}
            <div className={'mb-5'}>
                <div className="blog_widget widget widget_categories card custom-card">
                    <h3 className="title pb-2 mb-4 text-white fw-normal" style={{ borderBottom: '1px solid #cfdaed' }}>API key <small className="text-muted" style={{ fontSize: '55%' }}>(use this API key to interact with Sec1 API)</small></h3>
                    <Form.Group className="mb-3" controlId="formPlaintextPassword">
                        {showAlert && <div className="alert alert-info p-2 d-inline-block">
                            <small className="d-flex align-items-start justify-content-center flex-row"><FontAwesomeIcon icon={faInfoCircle} className="mt-1 me-2 fa-2x" />You need to click the "Generate API key" button to generate a new API key since you haven't generated one yet.</small>
                        </div>}
                        {!_.isEmpty(expiry) && keyExpired(expiry) && <div className="alert alert-danger p-2 d-inline-block">
                                This key has expired. Please click on the 'Regenerate' button to generate a new key.
                            </div>}
                            <div className="row align-items-center mb-3"> 
                                <div className="col-sm-7">
                                    <div className="position-relative flex-grow-1">
                                        <Form.Control
                                            type={apiKeyType}
                                            placeholder={loadingKey ? "Loading..." : "API key"}
                                            className={`${keyExpired(expiry) ? '' : 'api-key-input'}  ${loadingKey ? 'text-spinner-active' : ''}`}
                                            data-bs-theme="dark"
                                            readOnly
                                            value={apiKey}
                                            disabled={keyExpired(expiry)}
                                        />
                                        {loadingKey && <Spin
                                            className="bootstrap-textbox-spinner"
                                            indicator={
                                                <LoadingOutlined style={{ fontSize: 24 }} spin />
                                            }
                                        />}
                                        {!_.isEmpty(apiKey) && !keyExpired(expiry) && <div className="api-key-other-controls">
                                            {apiKeyType === 'text' && <Tooltip title="Hide Key"><EyeOutlined className="me-2 api-key-control-icon active" onClick={handleKeyTypeClick} style={{ cursor: 'pointer' }} /></Tooltip>}
                                            {apiKeyType === 'password' && <Tooltip title="Show Key" ><EyeInvisibleOutlined className="me-2 api-key-control-icon" onClick={handleKeyTypeClick} style={{ cursor: 'pointer' }} /></Tooltip>}
                                            <Tooltip title={copiedText ? "Copied" : "Copy to clipboard"}>{copiedText ? <FontAwesomeIcon className="api-key-control-icon" icon={faCheck} style={{ cursor: 'pointer' }} /> : <FontAwesomeIcon className="api-key-control-icon" icon={faCopy} style={{ cursor: 'pointer' }} onClick={handleCopyToClipboard} />}</Tooltip>
                                        </div>}
                                    </div>
                                </div>
                        <div className="col-sm-5">{!_.isEmpty(expiry) && <Form.Text className={`${keyExpired(expiry) ? 'text-danger' : 'text-muted'} `}>
                            (<strong>API Key Expiry:</strong> {moment(expiry).format("MMM Do YYYY, HH:mm")})
                        </Form.Text>}</div>
                            
                            </div>
                        <div className="row">
                            <div className="col-sm-7">
                                <Form.Group className="row mb-3">
                                    <div className="col-sm-6">
                                    <Form.Label className="text-white">Expiry options</Form.Label>
                                    <div>
                                    <Select
                                        value={isCustom ? 'custom' : expiryValue}
                                        onChange={handleExpiryFilterChange}
                                        options={[
                                            {
                                            value: 30,
                                            label: '30 days',
                                            },
                                            {
                                            value: 60,
                                            label: '60 days',
                                            },
                                            {
                                            value: 90,
                                            label: '90 days',
                                            },
                                            {
                                            value: 'custom',
                                            label: 'Select expiry days',
                                            },
                                        ]}
                                        className="w-100"
                                    />
                                    </div>
                                    </div>
                                    {isCustom && (<div className="col-sm-6">
                                        <Form.Label className="text-white">Enter expiry days</Form.Label>
                                        <div >
                                            <InputNumber
                                                min={1}
                                                max={365}
                                                value={expiryValue}
                                                onChange={handleExpiryInputChange}
                                                className="w-100"
                                            />
                                            <Form.Text className="text-muted d-block">
                                            max limit 365 days
                                            </Form.Text>
                                            {(expiryValue < 1 || expiryValue > 365) && (
                                            <Form.Text className="text-danger">
                                                Value should be between 1 and 365 days
                                            </Form.Text>
                                            )}
                                        </div>
                                    </div>
                            )}
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-7">
                                <div className="d-flex align-items-center justify-content-end mt-4">
                                    <Button onClick={handleGenerateAPIKeyClick} type="primary" disabled={loadingKey || !expiryValue || expiryValue === ''} loading={triggeredGenKey}>
                                        {(!_.isEmpty(apiKey)) ? 'Regenerate API key' : 'Generate API key'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </div>
            </div>
            {/* </section> */}



            {/* <div className="mb-20">
                    <div className="">
                        <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-0 text-white">
                            Global Settings
                        </h2>
                        <p style={{ fontSize: 14 }} className="text-muted mb-0"><small>Configuration settings for scanning a GitHub repository</small></p>
                    </div>
                </div> */}


            {/* <div className={'mb-5 '}>

                    <div className="blog_widget widget widget_categories card custom-card ">
                        <h3 className="title pb-2 mb-4 text-white fw-normal" style={{ borderBottom: '1px solid #cfdaed' }}>One time setup</h3>



                        <div className="row">
                            <div className="col-sm-6">
                                <div className="settings-box mt-2">
                                    <h5 className="title mb-4 text-white fw-normal"><SecurityScanOutlined className="me-1" /> Scan settings</h5>

                                    <div className="mb-3">
                                        <p className="mb-0">Should I trigger automatic scans on newly added repositories? <Switch className="ms-2" defaultChecked onChange={onChange} /></p>
                                        <div></div>
                                    </div>
                                    <div>
                                        <p className="mb-0">Schedule automatic scans?

                                            <span className="ms-3"><Switch checked={autoScans} onChange={(state) => setAutoScans(state)} /></span>
                                            <span className="ms-2">every <Select
                                                className="ms-2"
                                                defaultValue="1d"
                                                style={{
                                                    width: 120,
                                                }}
                                                disabled={!autoScans}
                                                onChange={(val) => console.log(val)}
                                                options={[
                                                    {
                                                        value: '4h',
                                                        label: '4 Hours',
                                                    },
                                                    {
                                                        value: '1d',
                                                        label: '1 day',
                                                    },
                                                    {
                                                        value: '3d',
                                                        label: '3 days',
                                                    },
                                                    {
                                                        value: '7d',
                                                        label: '7 days',
                                                    },
                                                ]}
                                            /></span>
                                        </p>
                                        <div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="settings-box mt-2">
                                    <h5 className="title mb-4 text-white fw-normal"><FontAwesomeIcon icon={faBell} className="me-1" /> Notification settings</h5>

                                    <div className="mb-3">
                                        <p className="mb-0">Notify me when new vulnerability is introduced in my repository</p>
                                        <div><Switch defaultChecked onChange={onChange} /> Yes</div>
                                    </div>
                                    <div className="mb-3">
                                        <p className="mb-0">Subscribe for weekly reports</p>
                                        <div><Switch defaultChecked onChange={onChange} /> Yes</div>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>





                </div> */}





        </section>
    );
}