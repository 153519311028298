import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck, faExclamationCircle, faInfoCircle, faTrowelBricks } from "@fortawesome/free-solid-svg-icons";
import github from '../../assets/img/github-white.svg';
import { faFile, faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import { Button, Tag } from "antd";
import _ from 'lodash';
import axios from "axios";
import { useHistory } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { Breadcrumb } from 'antd';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAllIntegratedSystems } from "../../shared/redux/reducers/integrationReducer";
import { getIntegratedState } from "../../shared/helper/genHelper";
import Loader from "../../shared/sharedComponents/loader";
import { Alert, notification } from 'antd';
import Modal from 'react-bootstrap/Modal';
import { GlobalConst } from "../../shared/appConfig/globalConst";

export default function IntegrateGithubPage() {

    const reduxState = useSelector(state => state);
    const dispatch = useDispatch();
    // const [api, contextHolder] = notification.useNotification();

    // const inputRef = useRef(null);
    const history = useHistory();

    const [accessToken, setAccessToken] = useState('');
    const [apiFired, setApiFired] = useState(false);

    const [error, setError] = useState({});
    // const [testedSuccess, setTestedSuccess] = useState(false);
    // const [testingToken, setTestingToken] = useState(false);
    const [isIntegrated, setIsIntegrated] = useState(false);
    const [integratedSystems, setIntegratedSystems] = useState({});

    const [showLoader, setShowLoader] = useState(false);

    const [confirmOpenDeleteModal, setConfirmOpenDeleteModal] = useState(false);

    useEffect(() => {
        // check for the status of the userdetails email.
        if (!_.isEmpty(reduxState) && !_.isEmpty(reduxState.authReducer) && !_.isEmpty(reduxState.authReducer.userDetails) && !_.isEmpty(reduxState.authReducer.userDetails.email)) {

            getUserIntegrations();
        }
    }, [reduxState]);



    const getUserIntegrations = () => {

        setShowLoader(true);
        axios
            .get(`${GlobalConst.API_URL}/auth/integrate/user-integrations`)
            .then(op => {
                // console.log("Output:", op);
                setShowLoader(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    // dispatch(setAllIntegratedSystems(op.data));
                    setIntegratedSystems(op.data);
                    if (_.has(op.data, 'github')) {
                        getUserPat();
                    }
                }

            })
            .catch(e => { setShowLoader(false); console.log("Exception:", e); })
    }


    const handleRemoveRepoClick = () => {

        setShowLoader(true);
        let data = {
            "userEmail": reduxState.authReducer.userDetails.email,
            "targetSystem": "github",
        }
        // console.log("Data::", data);
        axios
            .delete(`${GlobalConst.API_URL}/auth/integrate/user-pat`, { data })
            .then(op => {
                // console.log("Output:", op);
                setShowLoader(false);
                history.push('/integrations-list');
                notification.success({
                    message: 'Integration Deleted Successfully',
                    description: "The Github Repositories integration has been successfully deleted.",
                    duration: 5,
                });

            })
            .catch(e => {
                setShowLoader(false);
                console.log("Exception:", e);
                notification.error({
                    message: 'Undefined Error',
                    description: "An undefined error occurred while attempting to save the data.",
                    duration: 0,
                });
            })
    }


    const getUserPat = () => {

        // console.log("i am the targetOrg::", targetOrg);

        setShowLoader(true);
        let data = {
            "userEmail": reduxState.authReducer.userDetails.email,
            "targetSystem": "github",
        }
        axios
            .post(`${GlobalConst.API_URL}/auth/integrate/get-user-pat`, data)
            .then(op => {
                // console.log("Output:", op);
                setShowLoader(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.data)) {
                    setAccessToken(op.data.data.accessToken);
                    verifyData(op.data.data.org, op.data.data.accessToken);

                }

            })
            .catch(e => { setShowLoader(false); console.log("Exception:", e); })
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        // console.log("Handle Test Click here");
        setApiFired(true);

        handleFormSave();
    }

    const verifyData = (org, accessT) => {
        setShowLoader(true);

        const postBody = {
            urlType: "github",
            accessToken: accessT
        };

        axios
            .post(`${GlobalConst.API_URL}/auth/foss/verify`, postBody)
            .then(op => {
                setShowLoader(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && op.data.isValid) {
                    setError(false);
                }
                else {
                    setError(true);
                }
            })
            .catch(e => {
                setShowLoader(false);
                setError(e.response.data);
            })
    }


    const handleFormSave = () => {

        let postData = {
            "accessToken": accessToken,
            "targetSystem": "github",
            "userEmail": reduxState.authReducer.userDetails.email
        };

        if(getIntegratedState(integratedSystems, 'github')){
            postData = {
                ...postData,
                integrationId: integratedSystems['github'].integrationId
            }
        }
        let data = new FormData();
        data.append("request", JSON.stringify(postData));

        let config = {
            method: "post",
            url: `${GlobalConst.API_URL}/auth/integrate/user-pat`,
            headers: { "content-type": "multipart/form-data" },
            data: data,
        };

        axios
            .request(config)
            .then(op => {
                setApiFired(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && op.data.data.connected === true && op.data.data.saved === true) {
                    setError(false);
                    notification.success({
                        message: 'Successfully Verified and Saved',
                        description: "Your Github repository credentials have been verified and saved successfully.",
                        duration: 5,
                    });
                    getUserIntegrations();
                }
                else if (!_.isEmpty(op) && !_.isEmpty(op.data) && op.data.data.connected === false && op.data.data.saved === false) {
                    setError(true);
                    notification.error({
                        message: 'Invalid Credentials',
                        description: "The provided credentials are invalid. Please verify and try again. Your credentials were not saved.",
                        duration: 0,
                    });
                }
                else {
                    setError(true);
                    notification.error({
                        message: 'Undefined Error',
                        description: "An error occurred while attempting to save Github repository credentials. Please try again later.",
                        duration: 0,
                    });
                }
            })
            .catch(e => {
                setApiFired(false);
                setError(e.response.data);
                // console.log("Exception:", e);
                if (!_.isEmpty(e.response) && !_.isEmpty(e.response.data) && e.response.data.data.connected === false && e.response.data.data.saved === false) {
                    notification.error({
                        message: 'Invalid Credentials',
                        description: "The provided credentials are invalid. Please verify and try again. Your credentials were not saved.",
                        duration: 0,
                    });
                }
                else {
                    notification.error({
                        message: 'Undefined Error',
                        description: "An undefined error occurred while attempting to save the data.",
                        duration: 0,
                    });
                }

            })
    }

    const handleAccessTokenChange = (e) => {
        setAccessToken(e.target.value);
    }

    const handleConfirmModalClose = () => {
        setConfirmOpenDeleteModal(false);
    }

    return (

        <section className="mx-3">
            <section>
                {/* <div className="mb-4">
                    <div className="">
                        <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-2 text-white d-flex align-items-center justify-content-start">
                            <FontAwesomeIcon icon={faTrowelBricks} className="me-2" />
                            Integrations
                        </h2>
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/integrations-list">Integrations</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Azure Repos</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div> */}
                <div className="mb-4">
                    <div className="">
                        <div className="mb-3">
                            <Link to="/integrations-list"><FontAwesomeIcon className="me-2" icon={faArrowLeft} />Back to integrations</Link>
                        </div>
                        <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-2 text-white d-flex align-items-center justify-content-start">
                            <img src={github} className="me-2" style={{ width: 30 }} alt={'Github Repositories'} />
                            Github Repositories
                        </h2>
                        <p className="text-secondary">Streamline your integration with Github Repositories and effortlessly scan container images. Get started with your personal access tokens.</p>
                    </div>
                </div>



                <div className={'mb-5'}>
                    <div className="card custom-card">
                        <div className="card-body">
                            <h3 className="title pb-2 mb-4 text-white fw-normal d-flex align-items-center justify-content-start" style={{ borderBottom: '1px solid #cfdaed' }}>
                                {/*<img src={azureRepos} alt="Azure Repos" style={{ width: 35 }} />*/} Github Repos Settings
                                {!_.isEmpty(error) || error ? <Tag className="ms-3" color="error"><FontAwesomeIcon className="me-2" icon={faExclamationCircle} />Not Connected</Tag> : <>{getIntegratedState(integratedSystems, 'github') ? <Tag className="ms-3" color="rgb(25,135,84)"><FontAwesomeIcon className="me-2" icon={faCheck} />Connected</Tag> : <Tag className="ms-3"><FontAwesomeIcon className="me-2" icon={faInfoCircle} />Not Connected</Tag>}</>}
                            </h3>
                            <p className="mb-3">Provide your account credentials below to establish a connection between Sec1 and your Github Repositories account.</p>

                            <section className="row">
                                <div className="col-sm-5">
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="d-flex align-items-end justify-content-between flex-row">
                                            <div className="flex-fill me-3">
                                                <div className="mb-3">
                                                    <label htmlFor="txtAccessToken" className="form-label mb-1 fw-semibold">Personal access token</label>
                                                    <input disabled={apiFired} type="password" value={accessToken} onChange={handleAccessTokenChange} autoComplete="off" className="form-control" id="txtAccessToken" aria-describedby="AccessToken" placeholder="e.g. yy6uc5uoyhwjbjvuydfg7juk25e8e5wbobhddgxpee2ehp4ec66q" />
                                                </div>
                                            </div>
                                            {/* <div className="">
                                                <div className="mb-3">
                                                    <Button type="default" htmlType="button" disabled={_.isEmpty(accessToken) || testedSuccess} loading={testingToken} onClick={handleTestClick}>
                                                        <FontAwesomeIcon icon={faPlay} className={`me-2 ${testedSuccess ? 'text-success' : ''}`} /> Test Token
                                                    </Button>
                                                </div>
                                            </div> */}
                                        </div>

                                        {/* {!_.isEmpty(error) && <div className="d-flex align-items-center justify-content-start mb-3"><Alert
                                            message={error.errorMessage}
                                            type="error"
                                            closable
                                        /></div>} */}
                                        <div className="d-flex align-items-center justify-content-start mb-3">
                                            <Button type="primary" htmlType="submit" disabled={_.isEmpty(accessToken)} loading={apiFired}>
                                                <FontAwesomeIcon icon={faFloppyDisk} className="me-2" /> Save
                                            </Button>

                                        </div>

                                    </form>

                                </div>
                                <div className="col-sm-4">
                                    <div className="alert alert-light">
                                        <h4 className="mb-3">Creating a personal access token</h4>
                                        <p>To create a personal access token, follow the below link:</p>
                                        <p><a className="btn btn-outline-info btn-sm" target="_blank" href="https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens">Create a personal access token</a></p>
                                        {/* <p className="mb-1">From this page:</p>
                                        <ol>
                                            <li>Click <strong>New Token</strong>.</li>
                                            <li>Enter a <strong>Name</strong> <i>e.g. Sec1</i></li>
                                            <li><i>Important:</i> Personal access tokens with Azure Repos expire. The longest expiration that can be set is 1 year. To set this select <strong>Custom defined</strong> from the dropdown, and set the date to be the last day of the last month you can select. We will prompt you to renew your token when it expires.</li>
                                            <li>Scopes. Ensure <strong>Custom defined</strong> is selected and under Code select <code>Rend & write</code>.</li>
                                        </ol>
                                        <p>Click <strong>Create</strong> and copy and paste your newly created personal access token into the Personal access token field.</p> */}
                                    </div>
                                </div>
                            </section>

                            <hr />
                            <p className="text-secondary"><small>Refer to <a href={'https://docs.sec1.io/user-docs/2-integration-with-sec1/6-ci-cd-integrations/2-github-actions-integration'} target="_blank">our documentation</a> for further details regarding our integration with Github Repos. </small></p>
                        </div>
                    </div>
                </div>
                {getIntegratedState(integratedSystems, 'github') && <div className="mb-5">
                    <div className="card custom-card border-danger-subtle">
                        <div className="card-body">
                            <h3 className="title pb-2 mb-4 text-white fw-normal d-flex align-items-center justify-content-start" style={{ borderBottom: '1px solid #cfdaed' }}>
                                Danger Zone
                            </h3>
                            <div className="row">
                                <div className="col-sm-5">
                                    <h6>Remove Github Repositories Integration</h6>
                                    <p className="mb-0">Removing this integration will revoke your credentials and deactivate all Github Repositories projects associated with your account from Sec1.</p>
                                </div>
                                <div className="col-sm-4">
                                    <button className="btn btn-outline-danger" onClick={(e) => { e.preventDefault(); setConfirmOpenDeleteModal(true); }}>Remove</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </section>
            {showLoader && <Loader />}
            {confirmOpenDeleteModal && <Modal show={confirmOpenDeleteModal} onHide={handleConfirmModalClose} size="lg" backdrop={'static'} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title>Confirm Deletion of Github Repositories Integration</Modal.Title>
                </Modal.Header>
                <Modal.Body>Once you delete the Github Repositories integration, <span className="text-danger-emphasis">the action cannot be undone</span>. Please proceed with caution and ensure your decision is final.</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-dark" onClick={handleConfirmModalClose}>
                        Close
                    </button>
                    <button className="btn btn-danger" onClick={handleRemoveRepoClick}>
                        Remove
                    </button>
                </Modal.Footer>
            </Modal>}
        </section>
    );
}