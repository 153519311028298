import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTrowelBricks, faCheck, faInfoCircle, faEdit, faPlusCircle, faGear } from "@fortawesome/free-solid-svg-icons";
import kubernetes from "../../assets/img/kubernetes.svg";
import _ from 'lodash';
import { Link } from "react-router-dom";
import { Button, Popconfirm, Select, Space, Tag, Tooltip } from "antd";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import OnPremise from "./onPremise";
import Loader from "../../shared/sharedComponents/loader";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import axios from "axios";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import * as Yup from "yup";
import { useFormik } from "formik";
import oracle from "../../assets/img/techIcons/oracle.png";
import awsCi from "../../assets/img/scopy-tech-icons/aws.svg";
import googleCloud from "../../assets/img/scopy-tech-icons/gcp.svg";
import azure from "../../assets/img/scopy-tech-icons/azure.svg";
import { useParams, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import CustomResultView from "../../shared/sharedComponents/customResultView";

export default function KubernetesSaas() {
    const [loading, setLoading] = useState(false);
    const [confirmOpenDeleteModal, setConfirmOpenDeleteModal] = useState(false);
    const [isValidIntegration, setIsValidIntegration] = useState('');
    const [result, setResult] = useState({});
    const { id } = useParams()

    useEffect(() => {
        if (id) {
            getK8sConfiguration()
        }
    }, []);

    const getK8sConfiguration = () => {
        setLoading(true);
        const postData = {
            "integrationId": id,
            "targetSystem": "k8s"
        };
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/integration/cloud/fetch`, postData)
            .then(op => {
                if (op && op.data && !_.isEmpty(op.data.config) && !_.isEmpty(op.data.config.k8s)) {
                    setIsValidIntegration(op.data.connected)
                    formik.setValues({
                        "projectId": op.data.config.k8s.projectId || '',
                        "serviceAccountFile": null, // We can't populate the file input, but we can clear it
                        "provider": op.data.config.k8s.provider || '',
                        "host": op.data.config.k8s.host || '',
                        "clusterName": op.data.config.k8s.clusterName || '',
                        "namespace": op.data.config.k8s.namespace,
                        "region": op.data.config.k8s.region
                    });
                }
            })
            .catch(e => {
                if (e && e.response) {
                    const op = e.response
                    if (op && op.data && !_.isEmpty(op.data.config) && !_.isEmpty(op.data.config.k8s)) {
                        setIsValidIntegration(op.data.connected)
                        formik.setValues({
                            "projectId": op.data.config.k8s.projectId || '',
                            "serviceAccountFile": null, // We can't populate the file input, but we can clear it
                            "provider": op.data.config.k8s.provider || '',
                            "host": op.data.config.k8s.host || '',
                            "clusterName": op.data.config.k8s.clusterName || '',
                            "namespace": op.data.config.k8s.namespace,
                            "region": op.data.config.k8s.region
                        });
                    } else {
                        setIsValidIntegration(false)
                        formik.setValues({
                            "serviceAccountFile": null,
                            "host": "",
                            "clusterName": "",
                            "projectId": "",
                            "namespace": "",
                            "region": "",
                            "provider": "gcp",
                        });
                    }
                } else {
                    setIsValidIntegration(false)
                    setResult({
                        show: true,
                        isAlert: true,
                        message: 'Undefined Error',
                        description: "Failed to load Kubernetes configuration. Please try again.",
                    });
                }
            })
            .finally(op => {
                setLoading(false);
            });
    }

    const formik = useFormik({
        initialValues: {
            "serviceAccountFile": null,
            "host": "",
            "clusterName": "",
            "projectId": "",
            "namespace": "",
            "region": "",
            "provider": "gcp",
        },
        validationSchema: Yup.object({
            clusterName: Yup.string()
                .required('Cluster name is required'),
            projectId: Yup.string()
                .required('Project ID is required'),
            // namespace: Yup.string()
            //     .required('Namespace is required'),
            region: Yup.string()
                .required('Region is required'),
            host: Yup.string()
                .required('Host is required'),
            projectId: Yup.string()
                .required('Project ID is required'),
            serviceAccountFile: Yup.mixed()
                .required('Service account file is required')
                .test('fileType', 'Invalid file type. Must be a JSON file.', (value) => {
                    if (!value) return false;
                    return value && value.type === "application/json";
                }),
            provider: Yup.string().required('Provider is required'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const postBody = {
                "integration": "saas",
                "k8s": {
                    "config": "service-account",
                    "projectId": values.projectId,
                    "provider": values.provider,
                    "host": values.host,
                    "clusterName": values.clusterName,
                    "projectId": values.projectId,
                    "region": values.region
                }
            };

            if (values.namespace) {
                postBody.k8s.namespace = values.namespace
            }

            let data = new FormData();
            data.append("config", JSON.stringify(postBody));
            data.append("service-account-file", values.serviceAccountFile);
            if (id) {
                data.append("integrationId", id);
            }
            let config = {
                method: "post",
                url: `${GlobalConst.API_URL}/auth/cloud-api/integration/k8s/save`,
                headers: { "content-type": "multipart/form-data" },
                data: data,
            };
            axios
                .request(config)
                .then(response => {
                    if (response.data && response.data.connected === true) {
                        let message = { title: '', desc: '' };
                        if (id) {
                            message = { title: 'Integration Updated', desc: "Kubernetes integration updated successfully!" };
                        }
                        else {
                            message = { title: 'Integration Successfull', desc: "Kubernetes integration successfully configured!" };
                        }
                        setResult({
                            show: true,
                            isAlert: false,
                            message: message.title,
                            description: message.desc,
                        });
                    } else if (
                        !_.isEmpty(response) &&
                        !_.isEmpty(response.data) &&
                        response.data.connected === false
                    ) {
                        setResult({
                            show: true,
                            isAlert: true,
                            message: "Invalid Credentials",
                            description:
                                "The provided credentials are invalid. Please verify and try again. Your credentials were not saved.",
                        });
                    } else {
                        setResult({
                            show: true,
                            isAlert: true,
                            message: 'Undefined Error',
                            description:
                                "Integration was not successful. Please check your configuration.",
                        });
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.data && error.response.data.message) {
                            setResult({
                                show: true,
                                isAlert: true,
                                message: 'Undefined Error',
                                description:
                                    error.response.data.message,
                            });
                        }
                        else if (error.response.data && error.response.data.error) {
                            setResult({
                                show: true,
                                isAlert: true,
                                message: 'Undefined Error',
                                description: error.response.data.error,
                            });
                        }
                        else {
                            setResult({
                                show: true,
                                isAlert: true,
                                message: 'Undefined Error',
                                description: "An error occurred while processing your request. Please try again.",
                            });
                        }
                    } else if (error.request) {
                        setResult({
                            show: true,
                            isAlert: true,
                            message: 'Undefined Error',
                            description: "No response received from the server. Please try again later.",
                        });
                    } else {
                        setResult({
                            show: true,
                            isAlert: true,
                            message: 'Undefined Error',
                            description: "An error occurred while sending the request. Please try again.",
                        });
                    }
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });

    const handleConfirmModalClose = () => {
        setConfirmOpenDeleteModal(false);
    };

    const providerOptions = [{
        label: 'GCP',
        value: 'gcp',
        icon: googleCloud
    },
    {
        label: 'AWS',
        value: 'aws',
        icon: awsCi
    },
    {
        label: 'Oracle',
        value: 'oracle',
        icon: oracle
    },
    {
        label: 'Azure',
        value: 'azure',
        icon: azure
    }]

    return (
        <section className="mx-3">
            <section>
                <div className="mb-4">
                    <div className="">
                        <div className="mb-3">
                            <Link to="/integrations-list"><FontAwesomeIcon className="me-2" icon={faArrowLeft} />Back to integrations</Link>
                        </div>
                        <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-2 text-white d-flex align-items-center justify-content-start">
                            <FontAwesomeIcon icon={faTrowelBricks} className="me-2" />
                            Integrations
                        </h2>
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/integrations-list">Integrations</Link></li>
                                    <li className="breadcrumb-item active"><Link to="/integrations-list/k8s">Kubernetes</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{!_.isEmpty(id) ? formik.values.clusterName : 'Add New Integration'}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className={'mb-5'}>
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h3 className="title pb-2 mb-4 text-white fw-normal d-flex align-items-center justify-content-start" style={{ borderBottom: '1px solid #cfdaed' }}>
                                    <img src={kubernetes} alt="Kubernetes" style={{ width: 35 }} className="me-2" /> Kubernetes
                                    {isValidIntegration ?
                                        <Tag className="ms-3" color="rgb(25,135,84)"><FontAwesomeIcon className="me-2" icon={faCheck} />Connected</Tag>
                                        :
                                        <Tag className="ms-3" color="error"><FontAwesomeIcon className="me-2" icon={faInfoCircle} />Not Connected</Tag>
                                    }
                                </h3>
                            </div>
                            <p className="mb-3">Provide your account credentials below to establish a connection between Sec1 and your Kubernetes.</p>
                            {!_.isEmpty(result) && result.show && (
                                <div className="col-sm-5">
                                    <CustomResultView
                                        show={result.show}
                                        isAlert={result.isAlert}
                                        resultPrimaryText={result.message}
                                    />
                                </div>
                            )}
                            <div style={{ width: '100%' }}>
                                <Tabs
                                    defaultActiveKey="saas"
                                    id="cloud-tabs"
                                    className="mb-3"
                                >
                                    <Tab eventKey="saas" title="SAAS">
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="flex-fill me-3">
                                                <div className="mb-3">
                                                    <label className="form-label mb-1 fw-semibold">Provider</label>
                                                    <Select
                                                        className={`${formik.touched.provider && formik.errors.provider ? 'is-invalid' : ''}`}
                                                        value={formik.values.provider}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        placeholder="Select Provider"
                                                        onChange={(value) => formik.setFieldValue('provider', value)}
                                                        options={providerOptions}
                                                        optionRender={(option) => (
                                                            <Space>
                                                                <span role="img" aria-label={option.data.label}>

                                                                    <img src={option.data.icon} alt="GCP" style={{ width: 20 }} className="me-2" />
                                                                </span>
                                                                {option.data.label}
                                                            </Space>
                                                        )}
                                                    />
                                                    {formik.touched.provider && formik.errors.provider ? (
                                                        <div className="invalid-feedback">{formik.errors.provider}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="clusterName" className="form-label mb-1 fw-semibold">Cluster Name</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${formik.touched.clusterName && formik.errors.clusterName ? 'is-invalid' : ''}`}
                                                        id="clusterName"
                                                        {...formik.getFieldProps('clusterName')}
                                                        placeholder="Enter Cluster Name"
                                                    />
                                                    {formik.touched.clusterName && formik.errors.clusterName ? (
                                                        <div className="invalid-feedback">{formik.errors.clusterName}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="projectId" className="form-label mb-1 fw-semibold">Project Id</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${formik.touched.projectId && formik.errors.projectId ? 'is-invalid' : ''}`}
                                                        id="projectId"
                                                        {...formik.getFieldProps('projectId')}
                                                        placeholder="Enter Project Id"
                                                    />
                                                    {formik.touched.projectId && formik.errors.projectId ? (
                                                        <div className="invalid-feedback">{formik.errors.projectId}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="namespace" className="form-label mb-1 fw-semibold">{`Namespace (optional)`}</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${formik.touched.namespace && formik.errors.namespace ? 'is-invalid' : ''}`}
                                                        id="namespace"
                                                        {...formik.getFieldProps('namespace')}
                                                        placeholder={`Enter Namespace`}
                                                    />
                                                    {formik.touched.namespace && formik.errors.namespace ? (
                                                        <div className="invalid-feedback">{formik.errors.namespace}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="region" className="form-label mb-1 fw-semibold">Region</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${formik.touched.region && formik.errors.region ? 'is-invalid' : ''}`}
                                                        id="region"
                                                        {...formik.getFieldProps('region')}
                                                        placeholder="Enter Region"
                                                    />
                                                    {formik.touched.region && formik.errors.region ? (
                                                        <div className="invalid-feedback">{formik.errors.region}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="host" className="form-label mb-1 fw-semibold">Host</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${formik.touched.host && formik.errors.host ? 'is-invalid' : ''}`}
                                                        id="host"
                                                        {...formik.getFieldProps('host')}
                                                        placeholder="Enter Host"
                                                    />
                                                    {formik.touched.host && formik.errors.host ? (
                                                        <div className="invalid-feedback">{formik.errors.host}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="serviceAccountFile" className="form-label">Service account file</label>
                                                    <input
                                                        className={`form-control ${formik.touched.serviceAccountFile && formik.errors.serviceAccountFile ? 'is-invalid' : ''}`}
                                                        type="file"
                                                        id="serviceAccountFile"
                                                        onChange={(event) => {
                                                            formik.setFieldValue("serviceAccountFile", event.currentTarget.files[0]);
                                                        }}
                                                    />
                                                    {formik.touched.serviceAccountFile && formik.errors.serviceAccountFile ? (
                                                        <div className="invalid-feedback">{formik.errors.serviceAccountFile}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-start">
                                                <Button type="primary" htmlType="submit" loading={formik.isSubmitting} disabled={formik.isSubmitting}>
                                                    <FontAwesomeIcon icon={faFloppyDisk} className="me-1" />
                                                    {formik.isSubmitting ? 'Validating & Saving...' : 'Save'}
                                                </Button>
                                            </div>
                                        </form>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {isValidIntegration && (
                <div className="mb-5">
                    <div className="card custom-card border-danger-subtle">
                        <div className="card-body">
                            <h3
                                className="title pb-2 mb-4 text-white fw-normal d-flex align-items-center justify-content-start"
                                style={{ borderBottom: "1px solid #cfdaed" }}
                            >
                                Danger Zone
                            </h3>
                            <div className="row">
                                <div className="col-sm-5">
                                    <h6>{`Remove Cluster Integration`}</h6>
                                    <p className="mb-0">{`Removing this integration will delete the stored cluster credentials from the Sec1.`}</p>
                                </div>
                                <div className="col-sm-4">
                                    <button
                                        className="btn btn-outline-danger"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setConfirmOpenDeleteModal(true);
                                        }}
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {loading && <Loader />}
            {confirmOpenDeleteModal && (
                <Modal
                    show={confirmOpenDeleteModal}
                    onHide={handleConfirmModalClose}
                    size="lg"
                    backdrop={"static"}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="border-0" closeButton>
                        <Modal.Title>
                            Confirm Deletion of <span>{formik.values.clusterName}</span>Integration
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Once you delete the <span>{formik.values.clusterName}</span> integration,{" "}
                        <span className="text-danger-emphasis">
                            the action cannot be undone
                        </span>
                        . Please proceed with caution and ensure your decision is final.
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-dark" onClick={handleConfirmModalClose}>
                            Close
                        </button>
                        <button className="btn btn-danger">
                            Remove
                        </button>
                    </Modal.Footer>
                </Modal>
            )}
        </section>
    );
}