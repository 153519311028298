import React from "react";
import { useD3 } from "../../shared/hooks/useD3";
import * as d3 from "d3";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import _ from 'lodash';

function createSVG(
  data,
  {
    name = ([x]) => x,
    label = name,
    value = ([, y]) => y,
    group,
    title,
    link,
    linkTarget = "_blank",
    width = 1180,
    height = 400,
    basePadding = 15, // Base padding between rectangles
    margin = 1,
    marginTop = margin,
    marginRight = margin,
    marginBottom = margin,
    marginLeft = margin,
    groups,
    colors = d3.schemeTableau10,
    fill,
    fillOpacity = 0.7,
    stroke,
    strokeWidth,
    strokeOpacity,
    severity,
    status
  },
  svg,
  onGridClick
) {
  const L = label == null ? null : d3.map(data, label);
  const T = title === undefined ? L : title == null ? null : d3.map(data, title);
  const SV = d3.map(data, severity);
  const ST = d3.map(data,status)

  // Clear existing content
  svg.selectAll("*").remove();

  // Calculate grid layout
  const itemCount = data.length;
  const aspectRatio = width / height;

  // Function to calculate the optimal number of columns
  const calculateOptimalColumns = (count, aspectRatio) => {
    let bestCols = 1;
    let bestScore = Infinity;

    for (let cols = 1; cols <= count; cols++) {
      const rows = Math.ceil(count / cols);
      const gridRatio = (cols * aspectRatio) / rows;
      const score = Math.abs(gridRatio - aspectRatio);

      if (score < bestScore) {
        bestScore = score;
        bestCols = cols;
      }
    }

    return bestCols;
  };

  const cols = calculateOptimalColumns(itemCount, aspectRatio);
  const rows = Math.ceil(itemCount / cols);

 // Calculate rectangle size
 const maxWidth = (width - (cols + 1) * (basePadding - 15)) / cols;
 const maxHeight = (height - (rows + 1) * (basePadding - 15)) / rows;
 const rectSize = Math.min(maxWidth, maxHeight, 220); // Increased from 200 to 220
 const rectWidth = rectSize * 1.25; // Increased width by 10%
 const rectHeight = rectSize * 0.7; // Reduced height from 0.75 to 0.7
 const depth = 0; // Adjust the depth factor as needed

 // Adjust padding to account for 3D effect
 const paddingX = basePadding + depth;
 const paddingY = basePadding + depth;

 // Calculate total grid size
 const totalWidth = cols * rectWidth + (cols - 1) * paddingX + 2 * depth;
 const totalHeight = rows * rectHeight + (rows - 1) * paddingY + 2 * depth;

 // Center the grid in the SVG
 const startX = (width - totalWidth) / 2 + depth;
 const startY = (height - totalHeight) / 2 + depth;

  // Create grid positions
  const gridData = data.map((d, i) => ({
    ...d,
    width: rectWidth,
    height: rectHeight,
    x: startX + (i % cols) * (rectWidth + paddingX),
    y: startY + Math.floor(i / cols) * (rectHeight + paddingY),
  }));


  const defs = svg.append("defs");

  const mainGradientColors = [...GlobalConst.AI_CHART_COLORS,"gray"]
  const secondaryGradientColors = [...GlobalConst.AI_CHART_GRADIENT_COLORS,'gray']

  // Create Defs for 3D effect gradients
  mainGradientColors.forEach((mapColor, index) => {
    const gradientColor = secondaryGradientColors[index];

    // // Top face gradient
    // const topGradient = defs
    //   .append("linearGradient")
    //   .attr("id", `topGradient${index}`)
    //   .attr("x1", "0%")
    //   .attr("y1", "0%")
    //   .attr("x2", "100%")
    //   .attr("y2", "100%");

    // topGradient
    //   .append("stop")
    //   .attr("offset", "0%")
    //   .attr("style", `stop-color:${d3.rgb(mapColor).brighter(0.3)};stop-opacity:1`);

    // topGradient
    //   .append("stop")
    //   .attr("offset", "100%")
    //   .attr("style", `stop-color:${mapColor};stop-opacity:1`);

    // Front face gradient
    const frontGradient = defs
      .append("linearGradient")
      .attr("id", `frontGradient${index}`)
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "100%");

    frontGradient
      .append("stop")
      .attr("offset", "10%")
      .attr("style", `stop-color:${mapColor};stop-opacity:1`);

    frontGradient
      .append("stop")
      .attr("offset", "100%")
      .attr("style", `stop-color:${gradientColor};stop-opacity:1`);

    // // Right face gradient
    // const rightGradient = defs
    //   .append("linearGradient")
    //   .attr("id", `rightGradient${index}`)
    //   .attr("x1", "0%")
    //   .attr("y1", "0%")
    //   .attr("x2", "100%")
    //   .attr("y2", "100%");

    // rightGradient
    //   .append("stop")
    //   .attr("offset", "0%")
    //   .attr("style", `stop-color:${d3.rgb(mapColor).darker(0.3)};stop-opacity:1`);

    // rightGradient
    //   .append("stop")
    //   .attr("offset", "100%")
    //   .attr("style", `stop-color:${d3.rgb(gradientColor).darker(0.3)};stop-opacity:1`);

    // // Bottom face gradient
    // const bottomGradient = defs
    //   .append("linearGradient")
    //   .attr("id", `bottomGradient${index}`)
    //   .attr("x1", "0%")
    //   .attr("y1", "0%")
    //   .attr("x2", "100%")
    //   .attr("y2", "100%");

    // bottomGradient
    //   .append("stop")
    //   .attr("offset", "0%")
    //   .attr("style", `stop-color:${d3.rgb(mapColor).darker(0.5)};stop-opacity:1`);

    // bottomGradient
    //   .append("stop")
    //   .attr("offset", "100%")
    //   .attr("style", `stop-color:${d3.rgb(gradientColor).darker(0.5)};stop-opacity:1`);
  });

  svg.attr("width", width).attr("height", height);

  const leaf = svg
    .selectAll("g")
    .data(gridData)
    .join("g")
    .style("cursor", "pointer")
    .attr("transform", (d) => `translate(${d.x},${d.y})`)
    .on("click", (e, d) => {
      e.preventDefault();
      onGridClick(d.reportId);
    });

  // Function to get gradient based on severity
  const getGradient = (severity, face) => {
    const sev = severity.toUpperCase();
    let index;
    switch (sev) {
      case "CRITICAL": index = 0; break;
      case "HIGH": index = 1; break;
      case "MEDIUM": index = 2; break;
      case "LOW": index = 3; break;
      default: index = 4;
    }
    return `url(#${face}Gradient${index})`;
  };

  const cornerRadius = Math.min(10, Math.min(rectWidth, rectHeight) * 0.1); // Adjust as needed

 // Add 3D effect
 leaf.each(function(d, i) {
  const g = d3.select(this);
  const sev = SV[i];
  const status = ST[i]
// // Top face with rounded right edge
// g.append("path")
//   .attr("d", `
//     M0,0
//     h${rectWidth}
//     q${cornerRadius},${-cornerRadius} ${depth},${-depth}
//     h${-rectWidth + cornerRadius}
//     l${-depth},${depth}
//     Z
//   `)
//   .attr("fill", getGradient(sev, "top"))
//   .attr("opacity", 0.9);

// Front face with rounded corners
g.append("path")
  .attr("d", `
    M${cornerRadius},0
    h${rectWidth - 2 * cornerRadius}
    q${cornerRadius},0 ${cornerRadius},${cornerRadius}
    v${rectHeight - 2 * cornerRadius}
    q0,${cornerRadius} ${-cornerRadius},${cornerRadius}
    h${-rectWidth + 2 * cornerRadius}
    q${-cornerRadius},0 ${-cornerRadius},${-cornerRadius}
    v${-rectHeight + 2 * cornerRadius}
    q0,${-cornerRadius} ${cornerRadius},${-cornerRadius}
    Z
  `)
  .attr("fill", ()  => {
    if(status)
      return getGradient(sev, "front")
    return `url(#frontGradient4)`;
 })
  .attr("opacity", 0.9);

// // Right face with bottom rounded corner
// g.append("path")
//   .attr("d", `
//     M${rectWidth},0
//     l${depth},${-depth}
//     v${rectHeight - cornerRadius}
//     q0,${cornerRadius} ${-cornerRadius},${cornerRadius}
//     h${cornerRadius - depth}
//     l${-cornerRadius},${cornerRadius}
//     v${-rectHeight}
//     Z
//   `)
//   .attr("fill", getGradient(sev, "right"))
//   .attr("opacity", 0.9);

// // Left face (not visible, but kept for completeness)
// g.append("path")
//   .attr("d", `M0,0 l${depth},${-depth} v${rectHeight} l${-depth},${depth} Z`)
//   .attr("fill", getGradient(sev, "left"))
//   .attr("opacity", 0.1);

// // Bottom face with rounded corners on right and left edges
// g.append("path")
//   .attr("d", `
//     M0,${rectHeight - cornerRadius}
//     q0,${cornerRadius} ${cornerRadius},${cornerRadius}
//     h${rectWidth - 2 * cornerRadius}
//     q${cornerRadius},0 ${cornerRadius},${-cornerRadius}
//     l${depth},${-depth}
//     v${-cornerRadius}
//     q0,${cornerRadius} ${-cornerRadius},${cornerRadius}
//     h${-rectWidth + 2 * cornerRadius}
//     q${-cornerRadius},0 ${-cornerRadius},${-cornerRadius}
//     l${-depth},${depth}
//     Z
//   `)
//   .attr("fill", getGradient(sev, "bottom"))
//   .attr("opacity", 0.9);

});
  // // Add hover effect
  // leaf.on("mouseover", function() {
  //   d3.select(this).transition()
  //     .duration(200)
  //     .attr("transform", (d) => `translate(${d.x - 2},${d.y - 2}) scale(1.05)`);
  // })
  // .on("mouseout", function() {
  //   d3.select(this).transition()
  //     .duration(200)
  //     .attr("transform", (d) => `translate(${d.x},${d.y}) scale(1)`);
  // });

  if (T) leaf.append("title").text((d, i) => T[i]);

  if (L) {
    const uid = `O-${Math.random().toString(16).slice(2)}`;
  
    leaf
      .append("clipPath")
      .attr("id", (d) => `${uid}-clip-${d.key}`)
      .append("rect")
      .attr("width", rectWidth)
      .attr("height", rectHeight)
      .attr("rx", 5)
      .attr("ry", 5);
  
    const text = leaf
      .append("text")
      .attr("clip-path", (d) => `url(#${uid}-clip-${d.key})`)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("fill", "white")
      .attr("x", rectWidth / 2)
      .attr("y", rectHeight / 2)
      .style("font-size", "13px")
      .style("font-weight", "bold")
      .text((d, i) => L[i])
      .each(function() {
        const text = d3.select(this);
        const originalText = text.text();
        let textWidth = this.getComputedTextLength();
        
        if (textWidth > 80) {
          let truncatedText = originalText;
          while (textWidth > 80 && truncatedText.length > 0) {
            truncatedText = truncatedText.slice(0, -1);
            text.text(truncatedText + '...');
            textWidth = this.getComputedTextLength();
          }
        }
      });

    // text
    //   .selectAll("tspan")
    //   .data((d, i) => {
    //     let textLines = `${L[i]}`.split(/\n/g);
    //     return textLines;
    //   })
    //   .join("tspan")
    //   .attr("x", rectWidth / 2)
    //   .attr("dy", (d, i, nodes) => {
    //     const lineHeight = 1.1;
    //     return i === 0
    //       ? `${(-(nodes.length - 1) * lineHeight) / 2}em`
    //       : `${lineHeight}em`;
    //   })
    //   .text((d) => d)
    //   .each(function() {
    //     const tspan = d3.select(this);
    //     const text = tspan.text();
    //     let textWidth = this.getComputedTextLength();
        
    //     if (textWidth > 50) {
    //       let truncatedText = text;
    //       while (textWidth > 50 && truncatedText.length > 0) {
    //         truncatedText = truncatedText.slice(0, -1);
    //         tspan.text(truncatedText + '...');
    //         textWidth = this.getComputedTextLength();
    //       }
    //     }
    //   });
  
    leaf
      .append("text")
      .attr("clip-path", (d) => `url(#${uid}-clip-${d.key})`)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "baseline")
      .attr("fill", "white")
      .attr("x", rectWidth / 2)
      .attr("y", rectHeight - 10)
      .style("font-size", "11px")
      .style("font-weight", "normal")
      .attr("opacity", (d, i) => !ST[i] ? 1 : 0)
      .text("Scanning...");
  }

  // Add subtle pulsating animation
  function animatePulse(selection) {
    selection
      .transition()
      .duration(1500)
      .attr("opacity", 0.7)
      .transition()
      .duration(1500)
      .attr("opacity", 0.9)
      .on("end", function() {
        d3.select(this).call(animatePulse);
      });
  }

  // leaf.selectAll("rect, path").call(animatePulse);
}

export default function GridView({ data, options, onGridClick }) {
  const ref = useD3(
    (svg) => {
      createSVG(data, options, svg, onGridClick);
    },
    [data, options]
  );

  return (
    <svg
      ref={ref}
      style={{
        height: options.height || 800,
        width: "100%",
        marginRight: "0px",
        marginLeft: "0px",
      }}
    />
  );
}