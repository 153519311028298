import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Alert, Space, Tag } from "antd";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import Loader from "../../shared/sharedComponents/loader";
import { getFontColor, getStrokeColor } from "../../shared/helper/genHelper";
import _ from "lodash";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import VulDataTable from "./vulDataTable";

export default function OWASP10TabView({
  reportDetails,
  searchResults,
  dataSet,
}) {
  const [newData, setNewData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    let newData = [];
    reportDetails.forEach((el) => {
      newData.push({
        ...el,
        total: el.cveCount,
        critical: el.cveCountData.CRITICAL ? el.cveCountData.CRITICAL : 0,
        high: el.cveCountData.HIGH ? el.cveCountData.HIGH : 0,
        medium: el.cveCountData.MEDIUM ? el.cveCountData.MEDIUM : 0,
        low: el.cveCountData.LOW ? el.cveCountData.LOW : 0,
      });

      setNewData(newData);
    });
  }, [reportDetails]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Critical",
      dataIndex: "critical",
      key: "critical",
      align: "center",
      render: (val, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center flex-column">
            <Tag
              style={{
                color: getFontColor("CRITICAL"),
                borderRadius: 50,
                width: 40,
                fontSize: 14,
              }}
              color={getStrokeColor("CRITICAL")}
              className={"mx-auto text-center"}
            >
              {row.critical}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "High",
      dataIndex: "high",
      key: "high",
      align: "center",
      render: (val, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center flex-column">
            <Tag
              style={{
                color: getFontColor("HIGH"),
                borderRadius: 50,
                width: 40,
                fontSize: 14,
              }}
              color={getStrokeColor("HIGH")}
              className={"mx-auto text-center"}
            >
              {row.high}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Medium",
      dataIndex: "medium",
      key: "medium",
      align: "center",
      render: (val, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center flex-column">
            <Tag
              style={{
                color: getFontColor("MEDIUM"),
                borderRadius: 50,
                width: 40,
                fontSize: 14,
              }}
              color={getStrokeColor("MEDIUM")}
              className={"mx-auto text-center"}
            >
              {row.medium}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Low",
      dataIndex: "low",
      key: "low",
      align: "center",
      render: (val, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center flex-column">
            <Tag
              style={{
                color: getFontColor("LOW"),
                borderRadius: 50,
                width: 40,
                fontSize: 14,
              }}
              color={getStrokeColor("LOW")}
              className={"mx-auto text-center"}
            >
              {row.low}
            </Tag>
          </div>
        );
      },
    },
  ];

  const expandedRowRender = (record) => {
    return (
      <VulDataTable
        cveIdList={record.cveIdList}
        searchResults={searchResults}
        dataSet={dataSet}
      />
    );
  };

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />;
  }

  return (
    <div>
      {reportDetails.length > 0 ? (
        <Table
          size="small"
          columns={columns}
          dataSource={newData}
          className="custom-table mb-0"
          rowKey="name"
          expandable={{
            expandedRowRender,
            rowExpandable: (record) =>
              record.cveIdList && record.cveIdList.length > 0,
          }}
          locale={{ emptyText: <NoDataFound title={"No Data"} /> }}
        />
      ) : (
        <Alert
          message="No Data"
          description="No OWASP Top 10 data is available at the moment."
          type="info"
          showIcon
        />
      )}
    </div>
  );
}
