import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import _ from 'lodash';
import axios from 'axios';
import { GlobalConst } from '../../../shared/appConfig/globalConst';
import { getCloudIcon } from '../../../shared/helper/genHelper';
import SecurityPostureTab from './detailsModalTabs/securityPostureTab';
import WorkloadProtectionTab from './detailsModalTabs/workloadProtectionTab';
import { useSelector } from 'react-redux';
import StatsGraphMetaData from './statsGraphMetaData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ResourceGraph from './resourceGraph';
import { Tooltip } from 'antd';

export default function ResourceDetailsModal({ showResourceDetailsModal, setShowResourceDetailsModal, setLoading, targetSystem, advDashboardData }) {

    const cloudSecurityReducer = useSelector(state => state.cloudSecurityReducer);
    const [activeTabsKey, setActiveTabsKey] = useState('securityPosture');
    const [resourceData, setResourceData] = useState({});
    const [selectedResourceDetails, setSelectedResourceDetails] = useState({});

    useEffect(() => {

        if (!_.isEmpty(cloudSecurityReducer.activeSelectedRecord)) {
            getResourceData(cloudSecurityReducer.activeSelectedRecord);
            getHierarchyData(cloudSecurityReducer.activeSelectedRecord);
        }
    }, [cloudSecurityReducer.activeSelectedRecord]);


    const getResourceData = (activeSelectedRecord) => {
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/securityposture/resource`, activeSelectedRecord)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setResourceData(op.data);
                }
            })
            .catch(e => { setResourceData({}); })
            .finally(o => {
                setLoading(false);
            })
    }

    const getHierarchyData = (activeSelectedRecord) => {
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/resource/details`, activeSelectedRecord)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setSelectedResourceDetails(op.data);
                }
            })
            .catch(e => { setSelectedResourceDetails({}); })
            .finally(o => {
                setLoading(false);
            })
    }

    const hideResourceDetailsModal = () => {
        setShowResourceDetailsModal(false);
    }
    return (
        <Modal show={showResourceDetailsModal} fullscreen={true} onHide={hideResourceDetailsModal} style={{ zIndex: 1060 }}>
            <Modal.Header closeButton>
                <a className='me-3' onClick={hideResourceDetailsModal} href='#' style={{ fontSize: 22 }}><small ><FontAwesomeIcon icon={faArrowLeft} /></small></a>
                <Modal.Title>{!_.isEmpty(cloudSecurityReducer.activeSelectedRecord) ? <><span><Tooltip title={cloudSecurityReducer.activeSelectedRecord.docType}><img src={getCloudIcon(cloudSecurityReducer.activeSelectedRecord.docType, targetSystem)} className='me-2' style={{ width: 30 }} /></Tooltip></span>{cloudSecurityReducer.activeSelectedRecord.name}</> : 'Undefined'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
                {/* <input type='text' className='form-control form-control-sm'/> */}
                <div className='p-2 px-3 scroll--simple' style={{ height: 'calc(100vh - 56px)', overflowY: 'auto' }}>
                    {!_.isEmpty(selectedResourceDetails) && <div className='mb-2'>
                        <section>
                            <div className="card custom-card mb-0">
                                <div className="card-body" style={{ overflow: 'hidden', height: 380 }}>
                                    <div className='row'>
                                        <div className='col-sm-9' >
                                            <div style={{ width: '100%', height: '100%' }}>
                                                {!_.isEmpty(selectedResourceDetails) && <ResourceGraph apidata={selectedResourceDetails} advDashboardData={advDashboardData} />}
                                            </div>
                                        </div>
                                        {(!_.isEmpty(selectedResourceDetails) && !_.isEmpty(selectedResourceDetails.metadata)) &&
                                            <div className='col-sm-3'>
                                                <div className="card custom-card h-100 mb-0" style={{ background: '#37435b' }}>
                                                    <div className="card-body">
                                                        <h5 className='mb-3 border-bottom pb-1'>Statistics</h5>
                                                        <StatsGraphMetaData metadata={selectedResourceDetails.metadata} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>}
                    <div className='mb-2'>
                        <Tabs
                            className="mb-3 report-tabs"
                            activeKey={activeTabsKey}
                            onSelect={(k) => setActiveTabsKey(k)}
                            mountOnEnter={true}
                            unmountOnExit={true}
                        >
                            <Tab eventKey="securityPosture" title="Security Posture">
                                <section className="mb-5">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className='mb-0'>
                                                <SecurityPostureTab data={resourceData} />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Tab>
                            <Tab eventKey="workloadProtection" title="Workload Protection">
                                <WorkloadProtectionTab />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}