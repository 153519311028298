import React from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';

const TotalRepoNumberGraph = () => {
    const colors = [
        {
            fill: 'rgba(120, 90, 221, 0.8)',    // Blue with transparency
            border: 'rgba(120, 90, 221, 1)'                    // Solid blue
        },
        {
            fill: 'rgba(45, 180, 107, 0.8)',     // Critical red with transparency
            border: 'rgba(45, 180, 107, 1)'                    // Solid critical red
        }
    ];

    const theme = {
        darkMode: true,
        backgroundColor: 'transparent',
        textStyle: {
            color: '#B9B8CE',
        }
    };
    echarts.registerTheme('reference_theme', theme);

    const option = {
        title: {
            text: '1,175',
            subtext: 'Total Issues',
            left: '49%',
            top: '40%',
            textAlign: 'center',
            textStyle: {
                color: '#EEF1FA',
                fontSize: 24,
                fontWeight: 'bold',
                align: 'center'
            },
            subtextStyle: {
                color: '#B9B8CE',
                fontSize: 14,
                align: 'center'
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                const percent = ((params.value / 2787) * 100).toFixed(1);
                return `${params.name}<br/>${params.value} (${percent}%)`;
            },
            backgroundColor: 'rgba(0,0,0,0.85)',
            borderColor: '#484753',
            textStyle: {
                color: '#B9B8CE'
            }
        },
        legend: {
            orient: 'vertical',
            right: '5%',
            top: '5%',
            align: 'right',
            itemGap: 15,
            formatter: function (name) {
                const data = option.series[0].data;
                const item = data.find(item => item.name === name);
                return `${name} - ${item.value}`;
            },
            textStyle: {
                color: '#B9B8CE',
                fontSize: 13,
                padding: [0, 0, 0, 8]  // Add some padding to the left of text
            },
            selectedMode: true
        },
        series: [
            {
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderColor: '#2b313e',
                    borderWidth: 2,
                    borderRadius: 10
                },
                label: {
                    show: false
                },
                emphasis: {
                    scale: true,
                    scaleSize: 5,
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                labelLine: {
                    show: false
                },
                padAngle: 5,
                gapWidth: 4,
                data: [
                    { 
                        value: 602, 
                        name: 'Misconfigurations', 
                        itemStyle: { 
                            color: colors[0].fill,
                            borderColor: colors[0].border,
                            borderWidth: 2
                        }
                    },
                    { 
                        value: 573, 
                        name: 'Vulnerabilities', 
                        itemStyle: { 
                            color: colors[1].fill,
                            borderColor: colors[1].border,
                            borderWidth: 2
                        }
                    },
                ]
            }
        ]
    };

    return (
        <div className="w-full h-full rounded-lg" style={{ width: '100%', height: 250 }}>
            <ReactEcharts
                option={option}
                theme="reference_theme"
                style={{ height: '100%', width: '100%', cursor: 'pointer' }}
                opts={{ renderer: 'svg' }}
            />
        </div>
    );
};

export default TotalRepoNumberGraph;