import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { GlobalConst } from "../../appConfig/globalConst";

export default function DashboardSeverityIndicator({ loadingRows, severity, dashboardData }) {

    const [data, setData] = useState('-');

    useEffect(() => {
        setData(dashboardData);
    }, [dashboardData])

    const getFillColor = () => {
        switch (severity) {
            case 'Critical':
                return GlobalConst.CRITICAL_COLOR;
            case 'High':
                return GlobalConst.HIGH_COLOR;
            case 'Medium':
                return GlobalConst.MEDIUM_COLOR;
            case 'Low':
                return GlobalConst.LOW_COLOR;
            default:
                return GlobalConst.NA_COLOR;
        }
    }

    return (
        <svg width="120px" height="120px" viewBox="0 0 120 120">
            <g transform="translate(60 60)">
                {[...new Array(45)].map((ind, index) => (
                    <use
                        key={`linear-${severity}-${index}`}
                        xlinkHref="#lines"
                        transform={`rotate( ${index * 6})`}
                    />
                ))}
                <circle
                    cx="0"
                    cy="0"
                    r="59"
                    stroke={getFillColor()}
                    strokeWidth="2"
                    strokeDasharray="3"
                    fill="transparent"
                    className={loadingRows === true ? "scanning-animation" : ""}
                />
                <circle
                    cx="0"
                    cy="0"
                    r="53"
                    strokeWidth="0"
                    fill={getFillColor()}
                />
                <g x="0" y="0">
                    <text
                        x="0"
                        y="-5"
                        textAnchor="middle"
                        fill="#212121"
                        strokeWidth="0"
                        dy=".3em"
                        style={{ fontSize: 24, fontWeight: 600 }}
                    >{data ? data : "-"}</text>
                    <text
                        x="0"
                        y="10"
                        textAnchor="middle"
                        fill="#212121"
                        strokeWidth="0"
                        dy=".6em"
                        style={{ fontSize: 14 }}
                    >{severity}</text>
                </g>
            </g>
        </svg>
    );
}