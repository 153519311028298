import React, { useState, useMemo, useCallback } from 'react';
import { Table, Tag, Switch, Popover, Button } from 'antd';
import _ from 'lodash';
import NoDataFound from '../../../shared/sharedComponents/noDataFound';
import { getCloudIcon, getFontColor, getStrokeColor } from '../../../shared/helper/genHelper';
import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { FaCircleInfo } from "react-icons/fa6";
import { TiWarning } from "react-icons/ti";
import { MdOutlineError } from "react-icons/md";
import { updateActiveSelectedRecord } from '../../../shared/redux/reducers/cloudSecurityReducer';
import TableColumnSearch from '../../../shared/sharedComponents/antdTableComponents/tableColumnSearch';
import TableFilterDropdown from '../../../shared/sharedComponents/antdTableComponents/tableFilterDropDown';

const SecurityPostureTab = React.memo(({ data, setShowResourceDetailsModal, expandedRowKeys, onExpand }) => {

    const dispatch = useDispatch();
    const [showAllComponents, setShowAllComponents] = useState(false);
    const { getColumnSearchProps } = TableColumnSearch();

    const expandedRowRender = (record) => {

        const handleNameClick = (e, row) => {
            e.preventDefault();
            const activeRecordData = {
                "targetSystem": data.targetSystem,
                "integrationId": data.integrationId,
                "reportId": data.reportId,
                "docType": row.docType,
                "name": row.name
            };
            dispatch(updateActiveSelectedRecord(activeRecordData));
            setShowResourceDetailsModal(true);
        }

        const customAssetNameRender = (text, record, { highlightComponent }) => (
            <>
                <img src={getCloudIcon(record.docType, data.targetSystem)} className='me-2' style={{ width: 18 }} />
                <a href='#' onClick={(e) => handleNameClick(e, record)}>{highlightComponent || text}</a>
            </>
        );

        const customRender = (text, record, { highlightComponent }) => (
            <>
                {highlightComponent || text}
            </>
        );


        const columns = [
            // { title: 'Sno', dataIndex: 'sno', key: 'sno', ellipsis: true, width: 60 },
            {
                title: 'Asset Name', dataIndex: 'name', key: 'name', ellipsis: true, width: 250,
                ...getColumnSearchProps('name', customAssetNameRender, 'Asset Name')
            },
            {
                title: 'Message', dataIndex: 'message', key: 'message', ellipsis: true, render: (text, record) => {
                    return (
                        <>
                            <Popover
                                content={<div className='scroll--simple' style={{ width: 400, maxHeight: 400, overflow: 'hidden', overflowY: 'scroll' }}>
                                    <p className='mb-3'><strong>Description:</strong> {record.description}</p>
                                    {record.impact && <p className='mb-3'><strong>Impact:</strong> {record.impact}</p>}
                                    <div className='mb-1'>
                                        <strong>References</strong>
                                        {(_.has(record, 'references') && !_.isEmpty(record.references)) && <ul>
                                            {record.references.map((el, index) => (
                                                <li key={`ref-${record.name}-${index}`}>
                                                    <a target='_blank' href={el} rel="noopener noreferrer">{el}</a>
                                                </li>
                                            ))}
                                        </ul>}
                                    </div>
                                </div>}
                                title={
                                    <div className="d-flex align-items-center justify-content-between w-100 mb-3">
                                        <span style={{ fontSize: 16 }}><strong>Details</strong></span>
                                        <Button
                                            type="text"
                                            size="small"
                                            icon={<CloseOutlined />}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                const triggerElements = document.getElementsByClassName('ant-popover-open');
                                                if (triggerElements && triggerElements[0]) {
                                                    triggerElements[0].dispatchEvent(new MouseEvent('click', {
                                                        bubbles: true,
                                                        cancelable: true,
                                                        view: window
                                                    }));
                                                }
                                            }}
                                        />
                                    </div>
                                }
                                trigger="click"
                                destroyTooltipOnHide
                                rootClassName="ant-popover-closable"
                            >
                                <FaCircleInfo className='me-2 cursor-pointer' />
                            </Popover>
                            {text}
                        </>
                    );
                }
            },
            { title: 'Framework', dataIndex: 'frameworkName', key: 'frameworkName', ellipsis: true, width: 110, ...getColumnSearchProps('frameworkName', customRender, 'Framework') },
            { title: 'Rule No', dataIndex: 'ruleNo', key: 'ruleNo', ellipsis: true, width: 90, align: 'left', ...getColumnSearchProps('ruleNo', customRender, 'Rule Number') },
            {
                title: 'Status',
                key: 'status',
                width: 100,
                filters: [
                    { text: 'Pass', value: 'Pass' },
                    { text: 'Fail', value: 'Fail' },
                    { text: 'N/A', value: 'NA' }
                ],
                onFilter: (value, record) => record.violated.toLowerCase() === value.toLowerCase(),
                filterDropdown: (props) => <TableFilterDropdown {...props} />,
                render: (_, record) => (
                    <span>
                        {record.violated.toLowerCase() === 'pass' ? (
                            <CheckCircleFilled style={{ color: 'green' }} />
                        ) : record.violated.toLowerCase() === 'na' ? (
                            <TiWarning style={{ color: '#ffcc00' }} />
                        ) : (
                            <MdOutlineError style={{ color: getStrokeColor('Critical') }} />
                        )}
                        {' '}
                        {record.violated}
                    </span>
                ),
            },
            {
                title: 'Severity',
                dataIndex: 'severity',
                key: 'severity',
                width: 120,
                filters: [
                    { text: 'CRITICAL', value: 'CRITICAL' },
                    { text: 'HIGH', value: 'HIGH' },
                    { text: 'MEDIUM', value: 'MEDIUM' },
                    { text: 'LOW', value: 'LOW' }
                ],
                onFilter: (value, record) => record.severity.toLowerCase() === value.toLowerCase(),
                filterDropdown: (props) => <TableFilterDropdown {...props} />,
                render: (text, record) => (
                    <Tag
                        style={{
                            color: getFontColor(text),
                            borderRadius: 50,
                            fontSize: 12,
                            fontWeight: 600
                        }}
                        color={getStrokeColor(text)}
                        className={"mx-auto text-center text-uppercase"}
                    >
                        {text}
                    </Tag>
                ),
            }
        ];

        const myUpdatedData = (_.isEmpty(record) && _.isEmpty(record.policies)) ? [] : record.policies.map((row, index) => ({
            ...row,
            key: `cloud-adv-dashboard-expanded-${index}`
        }));

        const filteredData = showAllComponents ? myUpdatedData : myUpdatedData.filter(item => item.violated.toLowerCase() === 'fail');

        // Sort the expanded data based on severity
        const sortedData = _.orderBy(filteredData,
            [item => {
                switch (item.severity.toLowerCase()) {
                    case 'critical': return 1;
                    case 'high': return 2;
                    case 'medium': return 3;
                    case 'low': return 4;
                    default: return 5;
                }
            }],
            ['asc']
        );

        const sData = sortedData.map((row, index) => ({
            ...row,
            sno: index + 1,
        }));



        return (
            <Table
                size='small'
                columns={columns}
                dataSource={sData}
                pagination={{
                    showTotal: (total, range) => {
                        return (
                            <span className="text-white">
                                {`${range[0]}-${range[1]} of ${total && total.format()} issues`}
                            </span>
                        );
                    },
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    position: ["bottom", "left"],
                    className: "searchPagePagination px-0",
                    pageSizeOptions: [10, 20, 25],
                }}
            />
        );
    };

    const customDisplayNameRender = (text, record, { highlightComponent }) => (
        <><img src={getCloudIcon(record.docType, data.targetSystem)} className='me-2' style={{ width: 18 }} />{highlightComponent || text}</>
    );

    const dataTableColumns = [
        {
            title: 'Category',
            dataIndex: 'displayName',
            key: 'displayName',
            ...getColumnSearchProps('displayName', customDisplayNameRender, 'Category'),
        },
        {
            title: 'Total',
            key: 'total',
            dataIndex: 'total',
            align: 'center',
            sortDirections: ["ascend", "descend"],
            sorter: (a, b) => a.total - b.total,
            render: (text, record) => {
                return (<>{text}</>);
            }
        },
        {
            title: 'Critical',
            dataIndex: 'critical',
            key: 'critical',
            align: 'center',
            sortDirections: ["ascend", "descend"],
            sorter: (a, b) => a.critical - b.critical,
            render: (text, record) => (
                <Tag
                    style={{
                        color: getFontColor("CRITICAL"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14
                    }}
                    color={getStrokeColor("CRITICAL")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        },
        {
            title: 'High',
            dataIndex: 'high',
            key: 'high',
            align: 'center',
            sortDirections: ["ascend", "descend"],
            sorter: (a, b) => a.high - b.high,
            render: (text, record) => (
                <Tag
                    style={{
                        color: getFontColor("HIGH"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14
                    }}
                    color={getStrokeColor("HIGH")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        },
        {
            title: 'Medium',
            dataIndex: 'medium',
            key: 'medium',
            align: 'center',
            sortDirections: ["ascend", "descend"],
            sorter: (a, b) => a.medium - b.medium,
            render: (text, record) => (
                <Tag
                    style={{
                        color: getFontColor("MEDIUM"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14
                    }}
                    color={getStrokeColor("MEDIUM")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        },
        {
            title: 'Low',
            dataIndex: 'low',
            key: 'low',
            align: 'center',
            sortDirections: ["ascend", "descend"],
            sorter: (a, b) => a.low - b.low,
            render: (text, record) => (
                <Tag
                    style={{
                        color: getFontColor("LOW"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14
                    }}
                    color={getStrokeColor("LOW")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        }
    ];

    // const onChangeShowAllComponents = (checked) => {
    //     setShowAllComponents(checked);
    // }

    const myUpdatedData = data.resourceSummary.map((row, index) => {

        const keys = ['critical', 'high', 'medium', 'low'];
        const total = _.sum(_.map(keys, key => _.toNumber(_.get(row, key, 0))));
        return {
            ...row,
            sno: index + 1,
            total: total,
            key: `cloud-adv-dashboard-${index}`
        }
    });

    const filteredAndSortedData = useMemo(() => {
        let filtered = showAllComponents ? myUpdatedData : myUpdatedData.filter(item =>
            item.critical > 0 || item.high > 0 || item.medium > 0 || item.low > 0
        );

        // Sort the data based on severity counts
        return _.orderBy(filtered,
            ['critical', 'high', 'medium', 'low'],
            ['desc', 'desc', 'desc', 'desc']
        );
    }, [myUpdatedData, showAllComponents]);

    // const switchBox = React.useMemo(() => {
    //     return (<Switch onChange={onChangeShowAllComponents} checked={showAllComponents} className='me-2' />);
    // }, [showAllComponents]);

    // Memoize the callback to prevent unnecessary re-renders of Switch
    const onChangeShowAllComponents = useCallback((checked) => {
        setShowAllComponents(checked);
    }, []);

    const switchBox = useMemo(() => (
        <Switch
            onChange={onChangeShowAllComponents}
            checked={showAllComponents}
            className='me-2'
        />
    ), [onChangeShowAllComponents, showAllComponents]);

    return (
        <div>
            <div className='d-flex align-items-center justify-content-end mb-4 me-3 mt-2' style={{ position: 'absolute', right: 0, top: '-56px' }}>
                {switchBox}Show all
            </div>
            <Table
                size='small'
                className='custom-table mb-0 fixedScrollCustomTable table-hover component-view-table'
                dataSource={filteredAndSortedData}
                columns={dataTableColumns}
                locale={{
                    emptyText: <NoDataFound title={'No Data'} />
                }}
                expandable={{
                    expandedRowRender,
                    rowExpandable: (record) => record.policies && record.policies.length > 0,
                    expandedRowKeys,
                    onExpand,
                }}
                pagination={{
                    showTotal: (total, range) => {
                        return (
                            <span className="text-white">
                                {`${range[0]}-${range[1]} of ${total && total.format()} issues`}
                            </span>
                        );
                    },
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    position: ["bottom", "left"],
                    className: "searchPagePagination px-0",
                    pageSizeOptions: [10, 20, 25],
                }}
            />
        </div>
    );
});

export default SecurityPostureTab;