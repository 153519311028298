import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { message, InputNumber } from 'antd';
import Form from 'react-bootstrap/Form';
import { faCheck, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function StartupPlanList({ planType, selectedProducts, setSelectedProducts, prices }) {

    const [developers, setDevelopers] = useState(1);

    // useEffect(() => {
    //     console.log("Prices Standard:", prices)
    // }, [prices])

    const onChange = (event) => {
        setDevelopers(Number(event.target.value));
    };

    const handleCheckboxChange = (event, product) => {
        const isChecked = event.target.checked;

        if (selectedProducts.length === 1 && !isChecked && isProductSelected(product)) {
            showMessage();
            return;
        }

        if (selectedProducts.length === 2 && !isChecked && product === 'scopy' && isProductSelected('xil')) {
            showMessage();
            return;
        }

        let updatedSelectedProducts = [...selectedProducts];

        if (isChecked) {
            updatedSelectedProducts.push(product);
            if (product === 'xil' && !isProductSelected('scopy')) {
                updatedSelectedProducts.push('scopy');
            }
        } else {
            updatedSelectedProducts = updatedSelectedProducts.filter(p => p !== product);
            if (product === 'scopy') {
                updatedSelectedProducts = updatedSelectedProducts.filter(p => p !== 'xil');
            }
        }

        setSelectedProducts(updatedSelectedProducts);
    };

    const isProductSelected = (productName) => {
        return _.includes(selectedProducts, productName);
    };

    const showMessage = () => {
        message.error({
            content: (
                <div className="d-flex align-items-center">
                    At least one product should be selected.
                </div>
            ),
            icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
            onClose: () => message.destroy(),
        });
    };

    const getPrice = (planType) => {
        if (!_.isEmpty(prices) && !_.isEmpty(selectedProducts)) {
            // console.log("Prices", JSON.stringify(prices));
            // console.log("Selected products", JSON.stringify(selectedProducts));

            const filteredPrices = _.pickBy(prices, (value, key) => {
                const productName = key.split('_')[1];
                const pricePlanType = key.endsWith('_monthly') ? 'monthly' : 'yearly';
                return selectedProducts.includes(productName) && pricePlanType === planType;
            });

            const totalPrice = _.sumBy(_.values(filteredPrices), 'amount') * developers;

            return totalPrice;
        } else {
            return 0;
        }
    };


    const handleChangePlanClick = (e) => {
        e.preventDefault();
    }

    return (
        <div className="card pricing-box card-animate shadow-lg border-0 border-top border-2 border-secondary" style={{ borderRadius: 8 }}>
            <div className="card-body m-2 p-4">
                <div className="mb-0">
                    <div className="">
                        <h3 className="mb-0 fw-semibold">Startup</h3>
                    </div>
                    <p className="text-muted">Exclusive startup rates</p>
                </div>
                <hr />
                <h5 className="mb-2 fw-semibold">Products</h5>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item border-bottom-0"><input className="form-check-input me-1" type="checkbox" value="" id="scopyStartup" checked={isProductSelected('scopy')} onChange={(event) => handleCheckboxChange(event, 'scopy')} /> <label className="form-check-label" htmlFor="scopyStartup">Scopy - SCA</label></li>
                    <li className="list-group-item border-bottom-0"><input className="form-check-input me-1" type="checkbox" value="" id="xilStartup" checked={isProductSelected('xil')} onChange={(event) => handleCheckboxChange(event, 'xil')} /> <label className="form-check-label" htmlFor="xilStartup">XIL - Automated Fixes</label></li>
                    <li className="list-group-item border-bottom-0"><input className="form-check-input me-1" type="checkbox" value="" id="contekStartup" checked={isProductSelected('contek')} onChange={(event) => handleCheckboxChange(event, 'contek')} /> <label className="form-check-label" htmlFor="contekStartup">Contek - Container Scanning</label></li>
                    <li className="list-group-item border-bottom-0"><input className="form-check-input me-1" type="checkbox" value="" id="cisoStartup" checked={isProductSelected('cisoconsole')} onChange={(event) => handleCheckboxChange(event, 'cisoconsole')} /> <label className="form-check-label" htmlFor="cisoStartup">CISO Console</label></li>
                    <li className="list-group-item border-bottom-0"><input className="form-check-input me-1" type="checkbox" value="" id="sbomStartup" checked={isProductSelected('sbomscanner')} onChange={(event) => handleCheckboxChange(event, 'sbomscanner')} /> <label className="form-check-label" htmlFor="sbomStartup">SBOM Scanner</label></li>
                    <li className="list-group-item border-bottom-0"><input className="form-check-input me-1" type="checkbox" value="" id="cliStartup" checked={isProductSelected('cli')} onChange={(event) => handleCheckboxChange(event, 'cli')} /> <label className="form-check-label" htmlFor="cliStartup">CLI</label></li>
                    <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Galaxy Guard - Vulnerability Database</li>
                </ul>

                <hr />
                <div style={{ height: 350 }}>
                    <h5 className="mb-2 fw-semibold">Other Features</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Limited Scans (500 per {planType === 'monthly' ? 'month' : 'year'})</li>
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Support for SCM - GitHub, Azure, GitLab</li>
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Integration with CI tools - Jenkins, Azure DevOps, GitHub actions, GitLab CI/CD</li>
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Limited Team members - Max 10 developers</li>
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Team Dashboard</li>
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Limited Support</li>
                    </ul>
                </div>
                <hr />
                <div className="ms-auto text-center  d-flex align-items-center justify-content-end flex-column" style={{ height: 120 }}>
                    <div className="mb-1">
                        <Form.Label className="mb-0"><span className="me-2">{developers}</span>{developers === 1 ? 'Developer' : 'Developers'}</Form.Label>
                        <Form.Range value={developers} onChange={onChange} min={1} max={10}/>
                    </div>
                    <h2 className="mb-0">${getPrice(planType)} </h2>
                    <small className="fs-13 text-muted"> {developers === 1 ? 'per user' : `for ${developers} users`}/{planType === 'monthly' ? 'Month' : 'Year'}</small>
                </div>

                <div className="mt-3 pt-2">
                    <a href="#" onClick={handleChangePlanClick} className="btn btn-info w-100">Change Plan</a>
                </div>
            </div>
        </div>
    );
}