import React, { useEffect, useState, useRef } from "react";

import { SearchOutlined } from '@ant-design/icons';
import { Space, Alert, Table, Button, Input, Tag, Tooltip, Checkbox } from 'antd';
import Highlighter from 'react-highlight-words';
import _ from 'lodash';

// import { getStrokeColor, getFontColor } from '../../shared/helper/genHelper';
import { getStrokeColor, getFontColor, getSeverityInitial } from '../../shared/helper/genHelper';
import { getArtifactImage } from '../../shared/helper/getArtifactImageHelper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import NoDataFound from "../../shared/sharedComponents/noDataFound";

export default function ReviewAutofixDataTable({ dataSet, loadingData, scannedReport, getScanFileLocation }) {

    const [data, setData] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [filterDropdownVisible, setFilterDropdownVisible] = useState({});
    const searchInput = useRef(null);


    useEffect(() => {
        if (!_.isEmpty(dataSet) && !_.isEmpty(dataSet.remediationDetails)) {

            let newData = [];
            dataSet.remediationDetails.forEach((el, index) => {
                newData.push({
                    key: `remediation-data-${index}`,
                    packageName: el.purl,
                    upgradeToVersion: el.upgradeToVersion,
                    module: getScanFileLocation(scannedReport)
                });
            });

            setData(newData);
        }
    }, [dataSet]);


    const getPackageDetails = (val, type, artifact) => {
        if (val) {
            let splitedPkg = val.split(":");
            switch (type) {
                case "artifact":
                    let sText = splitedPkg[1].split('/');
                    return sText[2];
                case "group":
                    let splitedText = splitedPkg[1].split('/');
                    return splitedText[1];
                case 'getEcosystem':
                    let ecosystem = splitedPkg[1].split('/');
                    return ecosystem[0];
                case 'packageName':
                    return splitedPkg[1];
                case 'getVersion':
                    let version = splitedPkg[1].split('@')
                    return version[1];
                default:
                    return splitedPkg[2];
            }
        }
        else {
            return ('');
        }
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        setTimeout(() => searchInput.current?.select(), 100);
    };

    const getColumnSearchProps = (dataIndex, columnName) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{ padding: 8 }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${columnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        className="d-flex align-items-center justify-content-center"
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            if (clearFilters) {
                                clearFilters();
                                setSearchText('');
                            }
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchedColumn(dataIndex);
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button type="link" size="small"
                        onClick={() => {
                            close();
                        }}
                    >close</Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        filterDropdownOpen: filterDropdownVisible[dataIndex],
        onFilterDropdownOpenChange: (visible) => handleSearchClick(dataIndex, visible),
        render: (text, row) =>
            (searchedColumn === dataIndex && !_.isEmpty(searchText)) ? (
                highlightText(searchedColumn, text, row)
            ) : (
                getColumnText(dataIndex, text, row)
            ),
    });

    const handleSearchClick = (selectedColumn, visible) => {
        let allVisible = _.mapValues(filterDropdownVisible, function (o) { return false; });
        setFilterDropdownVisible({ ...allVisible, [selectedColumn]: visible });
    };

    const getColumnText = (searchedColumn, text, row) => {
        if (searchedColumn === 'packageName') {
            return <span className="d-flex align-items-center justify-content-start flex-row">
                <span title={text}>{getPackageDetails(text, 'packageName')}</span>
            </span>;
        }
        else if (searchedColumn === 'upgradeToVersion') {

            if (text === 'No remediation found') {
                return (<span className="text-danger">{text}</span>);
            }
            else {
                return (
                    <span className="d-flex align-items-center justify-content-start">
                        <span title={text}>{text}</span>
                    </span>
                );
            }
        }
        else if (searchedColumn === 'module') {
            return (<span title={text}>{text}</span>);
        }
        else {
            return text;
        }
    }

    const highlightText = (searchedColumn, text, row) => {

        if (searchedColumn === 'packageName') {
            return <span className="d-flex align-items-center justify-content-start flex-row">
                <span title={text}>{getHighlighted(getPackageDetails(text, 'packageName'))}</span>
            </span>;
        }
        else if (searchedColumn === 'upgradeToVersion') {

            if (text === 'No remediation found') {
                return (<span className="text-danger">{getHighlighted(text)}</span>);
            }
            else {
                return (
                    <span className="d-flex align-items-center justify-content-start">
                        <span title={text}>{getHighlighted(text)}</span>
                    </span>
                );
            }
        }
        else if (searchedColumn === 'module') {
            return (<span title={text}>{getHighlighted(text)}</span>);
        }
        else {
            return getHighlighted(text);
        }
    }

    const getHighlighted = (text) => {
        return <Highlighter
            highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
        />;
    }

    const columns = [
        {
            title: 'Vulnerable component',
            dataIndex: 'packageName',
            ...getColumnSearchProps('packageName', 'vulnerable component'),
        },
        {
            title: 'Recommended fix',
            dataIndex: 'upgradeToVersion',
            ...getColumnSearchProps('upgradeToVersion', 'recommended fix'),
        },
        {
            title: 'Module name',
            dataIndex: 'module',
            ellipsis: true,
            ...getColumnSearchProps('module', 'module name')
        }
    ];

    return (
        <div>
            {
                (!_.isEmpty(data)) ?
                    <div style={{ overflow: 'hidden', borderRadius: 8 }}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            sticky={{ offsetHeader: 0 }}
                            size="small"
                            className="table table-striped custom-table review-vul-table mb-0"
                            pagination={{
                                showTotal: (total, range) => {
                                    return <span className="text-white">{range[0]}-{range[1]} of {total && total.format()} components</span>;
                                },
                                defaultPageSize: 10,
                                showSizeChanger: true,
                                position: ["bottom", "left"],
                                pageSizeOptions: [10, 20, 25, 50],
                                className: 'bg-transparent'
                            }}
                            locale={{
                                emptyText: <NoDataFound title={'No Data'} />
                            }}
                        />
                    </div>
                    :
                    <>
                        {
                            (loadingData) ? <Alert
                                message="Loading..."
                                description="Please wait... while we load the recommeded remediation(s)"
                                type="warning"
                                showIcon
                                className="mt-2"
                            /> : <Alert
                                message="No remediation found"
                                description="We couldn't find any remediation on this repository."
                                type="info"
                                showIcon
                                className="mt-2"
                            />
                        }
                    </>

            }
        </div>
    );
}