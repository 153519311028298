import { useState, useEffect, useRef } from 'react';
import dashboardVideo from '../../assets/img/dast-video.MP4'
import aiSolution from '../../assets/img/ai-solutions.png';
import bug from '../../assets/img/bug.png';
import prioritize from '../../assets/img/prioritize.png';
import ScanPublicWebsite from './scanPublicWebsite';

export default function NewUserScreen() {

    const videoRef = useRef(null);
    const [isBtnClicked, setIsButtonClicked] = useState(false);


    useEffect(() => {
        const playVideo = () => {
            if (videoRef.current) {
                videoRef.current.play().catch(error => {
                    console.log("Video play failed:", error);
                });
            }
        };

        // Play video on component mount
        playVideo();

        // Add click event listener to play video after user interaction
        document.addEventListener('click', playVideo);

        // Cleanup
        return () => {
            document.removeEventListener('click', playVideo);
        };
    }, []);

    const handleGetStartedClick = () => {
        // console.log("Handle get started click");
        setIsButtonClicked(true);
    }

    return (
        <div className="me-4 mt-4 card custom-card p-4">
            <div className="card-body p-0">
                <div className={`row ${!isBtnClicked && 'align-items-center'} `}>
                    <div className="col-sm-6">
                        <h3 className='mb-3'>Surface and Remediate Critical Security Risks in Your Web Applications</h3>
                        <p className='mb-4'>Quickly identify, prioritize, and resolve vulnerabilities in your web applications to ensure robust security.</p>
                        <div className='mb-4'>
                            <ul className='list-unstyled'>
                                <li className='mb-2'>

                                    <div className="d-flex align-items-start">
                                        <div className="flex-shrink-0">
                                            <img src={bug} style={{ width: 25, marginTop: 6 }} />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <strong>Discover - </strong> Detect misconfigurations, security weaknesses, and vulnerabilities in web pages, input forms, authentication mechanisms, and data handling processes.
                                        </div>
                                    </div>
                                </li>

                                <li className='mb-2'>
                                    <div className="d-flex align-items-start">
                                        <div className="flex-shrink-0">
                                            <img src={prioritize} style={{ width: 25, marginTop: 6 }} />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <strong>Prioritize - </strong> Focus on the most critical vulnerabilities with risk-based insights, enabling efficient mitigation strategies.
                                        </div>
                                    </div>
                                </li>
                                <li className='mb-0'>
                                    <div className="d-flex align-items-start">
                                        <div className="flex-shrink-0">
                                            <img src={aiSolution} style={{ width: 25, marginTop: 6 }} />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <strong>AI-Powered Remediation - </strong> Accelerate your remediation process with AI-suggested solutions, providing actionable guidance to fix security flaws and enhance application resilience.
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <button className="btn btn-outline-info mb-4" style={{ fontSize: 16 }} onClick={handleGetStartedClick}>Get Started with Web Application Scanning</button>
                        <p className='mb-1'>Scan your web application in minutes and elevate your security posture with AI-powered insights and precision remediation strategies.</p>
                    </div>
                    <div className={`col-sm-6 ${!isBtnClicked && 'align-items-center justify-content-center text-center'}`}>
                        {
                            isBtnClicked ? <ScanPublicWebsite fromModal={false} /> :
                                <video
                                    ref={videoRef}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    onLoadedData={(e) => e.target.play()}
                                    onCanPlayThrough={(e) => e.target.play()}
                                    style={{ boxShadow: '0px 0px 20px rgba(0,0,0,0.6)', borderRadius: 10 }} className="w-100">
                                    <source src={dashboardVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}