

import React from 'react';
import { Navbar } from 'react-bootstrap';
// import { useHistory } from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleDown, faBell, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
// import { UserOutlined } from '@ant-design/icons';
// import { Avatar } from 'antd';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Popover from 'react-bootstrap/Popover';
// import axios from 'axios';
// import Login from '../../../sharedComponents/loginModal';
import ScanModal from '../../../sharedComponents/scanModal';
import _ from 'lodash';
import Offcanvas from 'react-bootstrap/Offcanvas';

// import { GlobalConst } from '../../../appConfig/globalConst';

// import { resetAuthState, setLoginModalViewStatus } from '../../../redux/reducers/authReducer';
// import { loaderState, setScanModalViewState } from '../../../redux/reducers/scannerReducer';

// import { getUserDisplayName, getUserDisplayEmail, getUserDisplayChar } from '../../../helper/genHelper';

// import qs from 'qs';
import SidebarLinks from '../../../sharedComponents/sidebarLinks';

export default function Header({ handleMobileSidebarClose, showMobileSidebar, handleMobileSidebarShow }) {

    // const history = useHistory();
    // const navigate = useNavigate();
    // const dispatch = useDispatch();
    // const inverseNavbar = useSelector((state) => state.genReducer.headerType);
    // const authState = useSelector((state) => state.authReducer);
    const scanModalState = useSelector((state) => state.scannerReducer.scanModalViewStatus);
    // const loginModalState = useSelector((state) => state.authReducer.loginModalViewStatus);

    // const [isLogin, setIsLogin] = useState(false);
    // const [userDetails, setUserDetails] = useState('');
    // const [loginModalStatus, setLoginModalStatus] = useState(false);
    // const [scanModalStatus, setScanModalStatus] = useState(false);

    // useEffect(() => {
    //     console.log("Scan Modal state", scanState);

    // }, [scanState])

    // useEffect(() => {

    // console.log("I am the path here::", location)

    // document.addEventListener("scroll", () => {
    //     setScroll(window.scrollY > 20);
    // })
    // if (location.pathname === '/') {
    //     setIverseNavbar(true);
    // }
    // console.log("I am inverseNavbar::", inverseNavbar)
    // }, [inverseNavbar]);



    // const setLoginModalStatus = (state) => {
    //     dispatch(setLoginModalViewStatus(state));
    // }

    // const handleLoginClick = (e) => {
    //     e.preventDefault();
    //     // setLoginModalStatus(true);
    //     // dispatch(setLoginModalViewStatus(true));
    //     history.push('/login');
    // }

    // const handleScanClick = () => {
    //     dispatch(setScanModalViewState(true));
    // }


    // const getAvatar = (size, addClass) => {
    //     if (!_.isEmpty(authState) && !_.isEmpty(authState.userDetails)) {
    //         return <Avatar style={{ backgroundColor: '#266FF2', color: '#ffffff' }} className={addClass} size={size ? size : 'default'}>
    //             <span className='text-white'>{getUserDisplayChar(authState.userDetails)}</span>
    //         </Avatar>;
    //     }
    //     else {
    //         return <Avatar icon={<UserOutlined />} />;
    //     }
    // }


    // const popover = (
    //     <Popover id="userloginPopover">
    //         <Popover.Body>
    //             <div className={'text-body border-bottom pb-3'}>
    //                 <div className='d-flex align-items-center justify-content-start'>

    //                     {getAvatar('large', 'me-2')}
    //                     <div className='ms-1 text-white'>
    //                         <p className='mb-0' style={{ lineHeight: 1 }}>Signed in as</p>
    //                         {(!_.isEmpty(getUserDisplayName(authState.userDetails))) && <p className='mb-0' style={{ fontWeight: 600 }}>{getUserDisplayName(authState.userDetails)}</p>}
    //                         <p className='mb-0 text-secondary' style={{ fontWeight: 300, fontSize: 11 }}>{getUserDisplayEmail(authState.userDetails)}</p>
    //                     </div>
    //                 </div>
    //             </div>
    //             <ul className='list-group list-group-flush'>
    //                 {/* <li className="list-group-item py-2 px-2 border-0 pt-3">
    //                     <a href={'#'}>
    //                         Your assets
    //                     </a>
    //                 </li>
    //                 <li className="list-group-item py-2 px-2 pb-3">
    //                     <a href={'#'}>
    //                         Settings
    //                     </a>
    //                 </li> */}
    //                 <li className="list-group-item pt-3 pb-1">
    //                     <a href={'#'} onClick={handleLogoutClick}>
    //                         <FontAwesomeIcon icon={faRightFromBracket} className={'me-2'} />
    //                         Sign out
    //                     </a>
    //                 </li>
    //             </ul>
    //         </Popover.Body>
    //     </Popover>
    // );

    return (
        <>
            <Navbar expand={'xl'} className="bg-header-dark text-white p-0">
                <div className="sotcox-logo-menu-wrapper w-100 px-2 mx-3">
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleMobileSidebarShow} />
                        </div>

                        <Navbar.Offcanvas
                            id={`basic-navbar-nav`}
                            aria-labelledby={`basic-navbar-nav`}
                            className="d-xl-none"
                            show={showMobileSidebar}
                            onHide={handleMobileSidebarClose}
                        >
                            <Offcanvas.Header className='align-items-end justify-content-end pb-0' closeButton></Offcanvas.Header>
                            <SidebarLinks handleMobileSidebarClose={handleMobileSidebarClose} />
                        </Navbar.Offcanvas>

                        {/* <div className="sotcox-menu-wrapper pt-0 pb-0">
                            <div className="sotcox-main-menu no-bullet pe-0">
                                <nav id="sotcox-navbar">
                                    <ul className='d-flex align-items-center justify-content-end'>
                                        {!_.isEmpty(authState.token) && <li style={{ marginRight: 24 }}>
                                            <a className='anchor-header-notification position-relative' href={'#'}>
                                                <FontAwesomeIcon icon={faBell} style={{ fontSize: 22 }} />
                                                <span className='position-absolute start-100 translate-middle badge rounded-pill bg-danger'>0</span>
                                            </a>
                                        </li>}
                                        <li>
                                            {
                                                !_.isEmpty(authState) && !_.isEmpty(authState.token) ?
                                                    <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popover}>
                                                        <a className='text-white' href={'#'}>{getAvatar()} <FontAwesomeIcon className="ms-1" icon={faAngleDown} /></a>
                                                    </OverlayTrigger>
                                                    :
                                                    <a href={'#'} onClick={handleLoginClick} className="d-flex align-items-center justify-content-center flex-row text-white">
                                                        <img src={loginIcon} style={{ width: 18 }} className='me-2 text-white' /> Login
                                                    </a>
                                            }
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div> */}
                    </div>
                </div>
            </Navbar>
            {/* {loginModalState && <Login loginModalStatus={loginModalState} setLoginModalStatus={setLoginModalStatus} />} */}
            {scanModalState && <ScanModal />}
            {/* {loggedOut && <>{console.log('loggedout')}<Login loginModalStatus={loginModalState} setLoginModalStatus={setLoginModalStatus} /></>} */}
        </>
    );
}