import React from 'react';
import * as d3 from 'd3';
import { useD3 } from '../../shared/hooks/useD3';
import { getStrokeColor, getFontColor } from '../../shared/helper/genHelper';
import { GlobalConst } from '../../shared/appConfig/globalConst';
// import randomColor from 'randomcolor';


function createSVG(data, { // data is either tabular (array of objects) or hierarchy (nested objects)
    path, // as an alternative to id and parentId, returns an array identifier, imputing internal nodes
    id = Array.isArray(data) ? d => d.id : null, // if tabular data, given a d in data, returns a unique identifier (string)
    parentId = Array.isArray(data) ? d => d.parentId : null, // if tabular data, given a node d, returns its parent’s identifier
    children, // if hierarchical data, given a d in data, returns its children
    tree = d3.tree, // layout algorithm (typically d3.tree or d3.cluster)
    sort, // how to sort nodes prior to layout (e.g., (a, b) => d3.descending(a.height, b.height))
    label, // given a node d, returns the display name
    title, // given a node d, returns its hover text
    link, // given a node d, its link (if any)
    linkTarget = "_blank", // the target attribute for links (if any)
    width = 640, // outer width, in pixels
    height, // outer height, in pixels
    color = t => d3.interpolateRdBu(1 - t),
    r = 3.65, // radius of nodes
    padding = 0.8, // horizontal padding for first and last column
    fill = "#999", // fill for nodes
    fillOpacity, // fill opacity for nodes
    stroke = "#555", // stroke for links
    strokeWidth = 2, // stroke width for links
    strokeOpacity = 0.9,//0.4, // stroke opacity for links
    strokeLinejoin, // stroke line join for links
    strokeLinecap, // stroke line cap for links
    halo = "#fff", // color of label halo 
    haloWidth = 2, // padding around the labels
    curve = d3.curveBumpX, // curve for the link
} = {}, svg) {

    // If id and parentId options are specified, or the path option, use d3.stratify
    // to convert tabular data to a hierarchy; otherwise we assume that the data is
    // specified as an object {children} with nested objects (a.k.a. the “flare.json”
    // format), and use d3.hierarchy.
    const root = path != null ? d3.stratify().path(path)(data)
        : id != null || parentId != null ? d3.stratify().id(id).parentId(parentId)(data)
            : d3.hierarchy(data, children);

    // console.log("I am the root here:::", root)

    // Sort the nodes.
    if (sort != null) root.sort(sort);

    // Compute labels and titles.
    const descendants = root.descendants();
    const L = label == null ? null : descendants.map(d => label(d.data, d));

    // Compute the layout.
    const dx = 15;
    const dy = width / (root.height + padding);
    tree().nodeSize([dx, dy])(root);

    // Center the tree.
    let x0 = Infinity;
    let x1 = -x0;
    root.each(d => {
        if (d.x > x1) x1 = d.x;
        if (d.x < x0) x0 = d.x;
    });

    // Compute the default height.
    if (height === undefined) height = x1 - x0 + dx * 2;

    // Use the required curve
    if (typeof curve !== "function") throw new Error(`Unsupported curve`);

    // const svg = d3.create("svg")
    svg
        .attr('shape-rendering', 'geometricPrecision')
        .attr("viewBox", [-dy * padding / 2, x0 - dx, width, height])
        .attr("width", width)
        .attr("height", height)
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
        // .attr("font-family", "sans-serif")
        .attr("font-size", 10);
    svg.selectAll("*").remove();



    const defs = svg.append('defs');
    // Create Defs for emosed view
    GlobalConst.LINK_COLORS.forEach((mapColor, index) => {

        const linearGradient = defs.append('linearGradient')
            .attr('id', `g${index}`)
            .attr('gradientUnits', 'userSpaceOnUse')
            .attr('spreadMethod', 'reflect')
            .attr('inkscapeCollect', 'always');
        if (index === 0) {
            linearGradient
                .attr('x1', '0')
                .attr('y1', '100%')
                .attr('x2', '18%')
                .attr('y2', '100%');
        }
        else if (index === 1) {
            linearGradient
                .attr('x1', '25%')
                .attr('y1', '100%')
                .attr('x2', '50%')
                .attr('y2', '100%');
        }
        else {
            linearGradient
                .attr('gradientUnits', 'userSpaceOnUse')
                .attr('x1', '55%')
                .attr('y1', '100%')
                .attr('x2', '25%')
                .attr('y2', '100%');
        }
        linearGradient.append('stop')
            .attr('style', `stop-color:${mapColor}`)
            .attr('offset', '0%');
        linearGradient.append('stop')
            .attr('style', `stop-color:${GlobalConst.LINK_GRADIENT_COLORS[index]}`)
            .attr('offset', '100%');
    });

    // <filter id='color-shadow2' color-interpolation-filters="sRGB">
    //     <feOffset dx="1" dy="1" />
    //     <feGaussianBlur stdDeviation=".85" />
    //     <feColorMatrix type="matrix" values="0 0 0 0 .2, 1 0 0 0 1, 1 0 0 0 .75, 0 0 0 1 0" result="shadow" />
    //     <feMerge>
    //         <feMergeNode in="shadow" />
    //         <feMergeNode in="SourceGraphic" />
    //     </feMerge>
    // </filter>


    // let shadow = defs.append('filter')
    //     .attr('id', 'circle-shadow')
    //     .attr('color-interpolation-filters', 'sRGB');

    // shadow.append('feOffset').attr('dx', 1).attr('dy', 1);
    // shadow.append('feGaussianBlur').attr('stdDeviation', .85);
    // shadow.append('feColorMatrix').attr('type', 'matrix').attr('result', 'shadow').attr('values', '0 0 0 0 .2, 1 0 0 0 1, 1 0 0 0 .75, 0 0 0 1 0');
    // let feMerge = shadow.append('feMerge');
    // feMerge.append('feMergeNode').attr('in', 'shadow');
    // feMerge.append('feMergeNode').attr('in', 'SourceGraphic');


    {/* <filter id="blur-out">
      <feDropShadow dx="0" dy="0" stdDeviation="4.5"
          flood-color="black"/>
      <feComposite operator="out" in2="SourceGraphic"/>
    </filter> */}

    // let shadow = defs.append('filter')
    //     .attr('id', 'blur-out');

    // shadow.append('feDropShadow').attr('dx', 0).attr('dy', 0).attr('flood-color', 'white');
    // shadow.append('feComposite').attr('operator', 'out').attr('in2', 'SourceGraphic');


    // <feFlood flood-color="#3D4574" flood-opacity="0.5" result="offsetColor" />
    var dropShadowFilter = defs.append('svg:filter')
        .attr('id', 'drop-shadow')
        .attr('filterUnits', "userSpaceOnUse")
        .attr('width', '250%')
        .attr('height', '250%');
    dropShadowFilter.append('svg:feGaussianBlur')
        .attr('in', 'SourceGraphic')
        .attr('stdDeviation', 5)
        .attr('result', 'blur-out');
    dropShadowFilter.append('svg:feColorMatrix')
        .attr('in', 'blur-out')
        .attr('type', 'hueRotate')
        .attr('values', 180)
        .attr('result', 'color-out');
    dropShadowFilter.append('svg:feOffset')
        .attr('in', 'color-out')
        .attr('dx', 0)
        .attr('dy', 0)
        .attr('result', 'the-shadow');
    dropShadowFilter.append('svg:feBlend')
        .attr('in', 'SourceGraphic')
        .attr('in2', 'the-shadow')
        .attr('mode', 'normal');




    svg.append("g")
        .attr("fill", "none")

        // .attr("stroke-opacity", strokeOpacity)
        // .attr("stroke-linecap", strokeLinecap)
        // .attr("stroke-linejoin", strokeLinejoin)
        // .attr("stroke-width", strokeWidth)
        .selectAll("path")
        .data(root.links())
        .join("path")
        .attr("d", d3.link(curve)
            .x(d => d.y)
            .y(d => d.x))
        .attr("stroke", (d) => {
            // console.log("I am d::", d.source.data.type); 
            if (d.source.data.type === 'root' && d.target.data.type === 'module') {
                // console.log("Here ")
                // return GlobalConst.CHART_COLORS[9];
                return 'url(#g0)';
            }
            if (d.source.data.type === 'module' && d.target.data.type === 'component') {
                // console.log("Here ", d)
                // return GlobalConst.CHART_COLORS[0];
                return 'url(#g1)'
            }
            if (d.source.data.type === 'component' && d.target.data.type === 'cve') {
                // console.log("CVE IS ::", d);
                if (d.target.data.severity === 'CRITICAL') {
                    return 'url(#g2)'
                }
                else if (d.target.data.severity === 'HIGH') {
                    return 'url(#g3)'
                }
                else if (d.target.data.severity === 'MEDIUM') {
                    return 'url(#g4)'
                }
                else if (d.target.data.severity === 'LOW') {
                    return 'url(#g5)'
                }
                else {
                    return 'url(#g6)'
                }

            }
            return GlobalConst.CHART_COLORS[1];
        } /*stroke*/)
        .attr("stroke-width", strokeWidth)
        .attr("stroke-opacity", strokeOpacity)
        .attr("stroke-linecap", strokeLinecap)
        .attr("stroke-linejoin", strokeLinejoin)

        // .attr("stroke", (d, i) => { console.log("here this ::", color(d3.easeQuad(i / ((1 << 6) - 1)))); return color(d3.easeQuad(i / ((1 << 6) - 1)))})
        ;

    const node = svg.append("g")
        .selectAll("a")
        .data(root.descendants())
        .join("a")
        // .attr("xlinkHref", link == null ? null : d => link(d.data, d))
        // .attr("target", link == null ? null : linkTarget)
        .attr("transform", d => `translate(${d.y},${d.x})`);

    node.append("circle")
        .attr("fill", d => {
            // console.log("D::", d);
            if (d.data.type === 'root') {
                return GlobalConst.CHART_COLORS[9];
            }
            if (d.data.type === 'module') {
                return GlobalConst.CHART_COLORS[0];
            }
            if (d.data.type === 'component') {
                return GlobalConst.CHART_COLORS[1];
            }
            if (d.data.type === 'cve') {
                let color = getStrokeColor(d.data.severity);
                return (color) ? color : fill;
            }
            return d.children ? stroke : fill;
        })
        .attr("r", r)
        // .attr('cx', (d) => { console.log("In the d::", d) })
        // .attr('cy', r+2)
        .attr('filter', 'url(#drop-shadow)');

    if (title != null) node.append("title")
        .text(d => title(d.data, d));

    if (L) node.append("text")
        .attr("dy", d => d.children ? "1.5em" : "0.3em")
        .attr("dx", d => d.children ? 0 : 10)
        .attr("text-anchor", d => d.children ? "middle" : "start")
        .attr("paint-order", "stroke")
        .attr('fill', '#ffffff')
        .attr("stroke", '#212529')
        .attr("stroke-width", 5)
        .attr("stroke-opacity", 0.7)
        // .attr("stroke", halo)
        // .attr("stroke-width", 5)
        .text((d, i) => L[i]);

    // return svg.node();
}


export default function TidyTreeChart({ data, options }) {
    const ref = useD3((svg) => {
        createSVG(data, options, svg);
    }, [data]);

    return (
        <>
            {data ?
                <svg
                    ref={ref}
                    style={{
                        height: 500,
                        width: "100%",
                        marginRight: "0px",
                        marginLeft: "0px",
                    }}
                ></svg>
                : ''}
        </>
    );
}