import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { GlobalConst } from '../appConfig/globalConst';

const NonProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  const location = useLocation();

  const isInternalRoute = location.pathname.startsWith('/');

  // useState(()=>{
  //   console.log("Location", location);
  // },[location])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          const redirectUrl = encodeURIComponent(GlobalConst.APP_URL);
          // console.log("I am the redirect url : ", redirectUrl);
          const loginUrl = `${GlobalConst.AUTH_APP_URL}/login?redirect_url=${redirectUrl}`;
          window.location.href = loginUrl;
          // return <Component {...props} />;
          return null;
        } else {
          if (isInternalRoute) {
            return <Redirect to="/dashboard" />;
          }
          else {
            // console.log("I am the base url here :", GlobalConst)
            const redirectUrl = encodeURIComponent(GlobalConst.APP_URL);
            // console.log("I am the redirect url : ", redirectUrl);
            const loginUrl = `${GlobalConst.AUTH_APP_URL}/login?redirect_url=${redirectUrl}`;
            window.location.href = loginUrl;
            return null;
          }
        }
      }}
    // render={(props) =>
    //   !isAuthenticated ? <Component {...props} /> : <>
    //     {/* {console.log("I am executed from here")} */}
    //     {/* <Redirect to="/sca-dashboard" /> */}
    //     {(
    //       isInternalRoute ? <Redirect to="/sca-dashboard" /> : <Component {...props} />
    //     )}

    //   </>
    // }
    />
  )
};

export default NonProtectedRoute;