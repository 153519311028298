import errorImage from '../../../assets/img/error-page.svg';

export default function ErrorComponent() {
    return (
        <div className="mx-3 w-100 h-100" >
            <div className="d-flex align-items-center justify-content-center flex-column" style={{ minHeight: "calc(-131px + 100vh)" }}>
                <img className='mb-2' src={errorImage} style={{ width: 150 }} />
                <h3>Something went wrong</h3>
                <p className='text-muted'>There were some issues loading the screen please contact, <a href="mailto:support@sec1.io">support@sec1.io</a></p>
            </div>
        </div>
    );
}