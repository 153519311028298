import React, { useEffect, useState } from "react";
// import Highcharts from 'highcharts';
// import 
// import HighchartsReact from 'highcharts-react-official';

// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
// import highcharts3d from 'highcharts/highcharts-3d'

import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts';
import highcharts3d from 'highcharts/highcharts-3d';
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";


import './highchartStyle.css';


export default function DonutHighChart() {

    const reduxState = useSelector(state => state);

    const [options, setOptions] = useState({
        chart: {
            backgroundColor: 'transparent',
            height: 300,
            type: 'pie',
            options3d: {
                enabled: true,
                alpha: 40
            }

        },
        legend: {
            align: 'center',
            itemStyle: { color: '#adb5bd' },
            itemHoverStyle: { color: '#ffffff' },
            // verticalAlign: 'middle',
            // layout: 'vertical',
        },
        title: {
            text: '',
            align: 'left'
        },
        plotOptions: {
            pie: {
                colors: [
                    '#438eff',
                    '#2dcb73',
                    '#4ab0c1',
                    '#ff6c6c',
                    '#8561f9',
                    '#f6b749',
                    '#FFF263',
                    '#6AF9C4'
                ],
                showInLegend: true,
                innerSize: 150,
                depth: 50,
                dataLabels: {
                    enabled: false,
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    textOutline: 'none',
                    color: '#ffffff',
                    style: {
                        fontSize: '0.8rem',
                        fontFamily: '"Arimo", sans-serif',
                        // fontWeight: 400
                    },
                    distance: -30
                }
            }
        },
        series: []
    });

    useEffect(() => {

        if (!_.isEmpty(reduxState.scannerReducer) && !_.isEmpty(reduxState.scannerReducer.scannedRecords)) {
            // console.log('--------------------------------');
            // console.log("got the list of scannedRecords:", reduxState.scannerReducer.scannedRecords);
            // console.log("loaded the data in data array of datatable");
            // setData([...reduxState.scannerReducer.scannedRecords]);
            // console.log("Now loaded here")
            // setLoadingData(false);
            // console.log('--------------------------------');

            // cli, gitaction, aws-ci, gcp-ci, jenkins, circleci, bamboo, azure-ci
            // azure - scm
            // let cli=0, gitaction=0, awsci=0, gcpci=0, jenkins=0, circleci=0, bamboo=0, azureci=0;
            let gitRepos = 0, sbom = 0, docker = 0, files = 0, container = 0, archive = 0, azurescm = 0;
            reduxState.scannerReducer.scannedRecords.forEach((el) => {
                if (!_.isEmpty(el) && !_.isEmpty(el.type)) {
                    switch (el.type) {
                        case 'sbom':
                            sbom = sbom + el.total;
                            break;
                        case 'github':
                            gitRepos = gitRepos + el.total;
                            break;
                        case 'docker':
                            docker = docker + el.total;
                            break;
                        case 'file':
                            files = files + el.total;
                            break;
                        case 'container':
                            container = container + el.total;
                            break;
                        case 'archive':
                            archive = archive + el.total;
                            break;
                        case 'azure-scm':
                            azurescm = azurescm + el.total;
                            break;
                        default:
                            break;
                    }
                }
            });


            let uploadData = [];

            if (gitRepos) {
                uploadData.push(['Git Repos', gitRepos]);
            }
            if (sbom) {
                uploadData.push(['SBOM', sbom]);
            }
            if (docker) {
                uploadData.push(['Docker Images', docker]);
            }
            if (files) {
                uploadData.push(['Files', files]);
            }
            if (container) {
                uploadData.push(['Container', container]);
            }
            if (archive) {
                uploadData.push(['Archive', archive]);
            }
            if (azurescm) {
                uploadData.push(['Azure SCM', azurescm]);
            }

            let data = [{
                name: 'Vulnerabilities',
                data: [...uploadData]
            }];
            setOptions({ ...options, series: data });

            // console.log("data", data)




        }
        else {
            // show an error message to the user.
            // setLoadingData(false);
            // console.log("Loadded here");
        }

    }, [reduxState.scannerReducer.scannedRecords]);




    // const high3d = highcharts3d(HighchartsReact.Highcharts);
    highcharts3d(Highcharts);
    // const options = 

    return (<div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>);

}