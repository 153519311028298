import React, { useEffect, useState } from "react";
import { PiTrademarkRegistered } from "react-icons/pi";
import gcpIcon from '../../../assets/img/scopy-tech-icons/gcp.svg';
import awsIcon from '../../../assets/img/scopy-tech-icons/aws.svg';
import Modal from 'react-bootstrap/Modal';
import { Tooltip, Tabs } from 'antd';
import axios from "axios";
import { GlobalConst } from "../../../shared/appConfig/globalConst";
import _ from 'lodash';
import NoIntegrationsConfigured from "../../cloudSecurityDashboard/iScanModal/noIntegrationsConfigured";
import K8sTab from "./k8sTab";
import { useDispatch, useSelector } from "react-redux";
import { setIScanModalState } from "../../../shared/redux/reducers/kubernetesSecurityReducer";

export default function IScanModal({ loading, setLoading }) {

    const kubernetesSecurityReducer = useSelector(state => state.kubernetesSecurityReducer);
    const dispatch = useDispatch();
    const [foundIntegrations, setFoundIntegrations] = useState({});

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${GlobalConst.API_URL}/auth/integrate/user-integrations`)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        if (_.has(op.data, "k8s")) {
                            setFoundIntegrations(op.data["k8s"]);
                        }
                }
            })
            .catch(e => console.log("Exception: ", e))
            .finally(o => { setLoading(false) })

    }, []);

    const closeIScanModal = () => {
        dispatch(setIScanModalState(false))
    }

    return (
        <Modal show={kubernetesSecurityReducer.iScanModalState} onHide={closeIScanModal} size="lg" backdrop={'static'} >
            <Modal.Header closeButton>
                <div className="mb-0">
                    <h5 className="mb-0 text-white" style={{ fontWeight: 600 }}>
                        iScan <small className="mb-0 ms-2 text-muted" style={{ fontSize: '65%' }}>AI Powered Scans <PiTrademarkRegistered /></small>
                    </h5>
                </div>
            </Modal.Header>
            <>
                {
                    !_.isEmpty(foundIntegrations) ? <>
                        <Modal.Body>
                            <K8sTab integration={foundIntegrations} setLoading={setLoading} loading={loading} />
                        </Modal.Body>
                    </> : <Modal.Body>{loading ? 'Fetching Integrated List...' : <NoIntegrationsConfigured />}</Modal.Body>
                }
            </>
        </Modal>
    );
}