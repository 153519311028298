import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';

const cloudSecurityReducer = createSlice({
    name: 'cloudSecurityReducer',
    initialState: {
        iScanModalState: false,
        cloudSecurityScanTriggered: false,
        cloudSecurityDashTableData: [],
        cloudSecurityTriggeredScans: [],
        activeSelectedRecord: {}
    },
    reducers: {
        setIScanModalState: (state, action) => {
            state.iScanModalState = action.payload;
        },
        setCloudSecurityScanTriggeredState: (state, action) => {
            state.cloudSecurityScanTriggered = action.payload;
        },
        setCloudSecurityDashboardTableData: (state, action) => {
            state.cloudSecurityDashTableData = action.payload;
        },
        updateCloudSecurityTriggeredScans: (state, action) => {
            state.cloudSecurityTriggeredScans = action.payload;
        },
        updateActiveSelectedRecord: (state, action) => {
            state.activeSelectedRecord = action.payload;
        },
        addUpdateCloudSecurityDashboardTableData: (state, action) => {

            // This function is added only to populate data in the table when a new scan is initiated and nothing else.

            let clonedCloudSecurityTableData = _.cloneDeep(state.cloudSecurityDashTableData);

            // console.log("clonedCloudSecurityTableData", JSON.stringify(clonedCloudSecurityTableData));

            const presentList = _.filter(action.payload, (item) => {
                return _.some(clonedCloudSecurityTableData, { integrationId: item.integrationId });
            });

            // console.log("presentList", JSON.stringify(presentList));

            const nonPresentList = _.filter(action.payload, (item) => {
                return !_.some(clonedCloudSecurityTableData, { integrationId: item.integrationId });
            });

            // console.log("nonPresentList", JSON.stringify(nonPresentList));

            if (!_.isEmpty(presentList)) {
                // These are the present records that need to be modified and add a loader to it so that we can show loading state.
                clonedCloudSecurityTableData = clonedCloudSecurityTableData.map((record) => {
                    const presentRecord = _.find(presentList, { integrationId: record.integrationId });
                    if (presentRecord) {
                        // Modify the record as needed, for example, adding a loading state
                        return {
                            ...record,
                            status: "INITIATED",
                            reportId: presentRecord.reportId
                        };
                    }
                    return record;
                });
            }
            // console.log("clonedCloudSecurityTableData", JSON.stringify(clonedCloudSecurityTableData));

            let newRecordsData = [];
            if (!_.isEmpty(nonPresentList)) {
                // These are the records which are newly added into the system show it on top of all the records.
                newRecordsData = nonPresentList.map((record) => {
                    return {
                        "integrationId": record.integrationId,
                        "integrationType": record.integrationType,
                        "projectId": record.projectId,
                        "reportId": record.reportId,
                        "createdAt": "-",
                        "updatedAt": "-",
                        "status": "INITIATED",
                        "critical": 0,
                        "high": 0,
                        "medium": 0,
                        "low": 0
                    };
                });
            }

            // console.log("newRecordsData", JSON.stringify(newRecordsData));

            // Filter records with status 'INITIATED' or 'SCANNING'
            const initiatedOrScanning = _.filter(clonedCloudSecurityTableData, record => record.status.toLowerCase() === 'initiated' || record.status.toLowerCase() === 'scanning');
            // console.log("initiatedOrScanning", JSON.stringify(initiatedOrScanning));
            // Filter records with other statuses
            const otherStatuses = _.filter(clonedCloudSecurityTableData, record => record.status.toLowerCase() !== 'initiated' && record.status.toLowerCase() !== 'scanning');
            // console.log("otherStatuses", JSON.stringify(otherStatuses));
            // Concatenate the filtered arrays to place 'INITIATED' or 'SCANNING' records first
            const sortedRecords = [...initiatedOrScanning, ...otherStatuses];
            const dashboardRecords = [...newRecordsData, ...sortedRecords];


            // console.log("I am the cloudSecurityDashTableData ", JSON.stringify(dashboardRecords))

            state.cloudSecurityDashTableData = dashboardRecords;

            // const cloudSecurityScans = sortedRecords.map((el)=>{() el.reportId});
            const cloudSecurityScans = _.chain(dashboardRecords)
                .filter(record => record.status.toLowerCase() === 'scanning' || record.status.toLowerCase() === 'initiated')
                .map('reportId')
                .value();
            // console.log("CloudSecurity Scans", JSON.stringify(cloudSecurityScans));

            state.cloudSecurityTriggeredScans = cloudSecurityScans;


            // dispatch



            // console.log("I am the cloudSecurity dashboard table data", JSON.stringify(state.cloudSecurityDashTableData));
        }
    }
})

export const { setIScanModalState, setCloudSecurityScanTriggeredState, setCloudSecurityDashboardTableData, addUpdateCloudSecurityDashboardTableData, updateCloudSecurityTriggeredScans, updateActiveSelectedRecord } = cloudSecurityReducer.actions;

export default cloudSecurityReducer.reducer;