import React, { useEffect, useState } from 'react';
import TidyTreeChart from './tidyTreeChart';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { Checkbox } from 'antd';

export default function TidyTreeView({ dataSet }) {
    const cutOutIndex = 5;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState([]);

    useEffect(() => {
        if (!_.isEmpty(dataSet)) {
            let newData = {
                name: dataSet.assetUrl.split('/').pop(),
                children: [],
                type: 'root'
            };
            let children = [];
            if (!_.isEmpty(dataSet) && !_.isEmpty(dataSet.scanResult)) {

                // Loop through the dataset scan result
                dataSet.scanResult.forEach((root) => {
                    if (!_.isEmpty(root) && !_.isEmpty(root.scanResult)) {
                        let rootChildren = [], hasCve = false;
                        if (!_.isEmpty(root) && !_.isEmpty(root.scanResult)) {

                            // Loop through the packages
                            _.forIn(root.scanResult, (val, key) => {
                                let cveChildren = [];
                                if (!_.isEmpty(val) && !_.isEmpty(val.cves)) {
                                    hasCve = true;
                                    // Loop through each CVE
                                    val.cves.forEach((el, index) => {
                                        let severity = (!_.isEmpty(el) && el.cvssV3BaseSeverity) ? el.cvssV3BaseSeverity : el.cvssV2BaseSeverity;
                                        if (!_.isEmpty(filter)) {
                                            if (_.includes(filter, severity)) {
                                                if (index < cutOutIndex) {
                                                    cveChildren.push({
                                                        name: el.cveId,
                                                        type: 'cve',
                                                        severity: (!_.isEmpty(el) && el.cvssV3BaseSeverity) ? el.cvssV3BaseSeverity : el.cvssV2BaseSeverity
                                                    });
                                                }
                                            }
                                        }
                                        else {
                                            if (index < cutOutIndex) {
                                                cveChildren.push({
                                                    name: el.cveId,
                                                    type: 'cve',
                                                    severity: (!_.isEmpty(el) && el.cvssV3BaseSeverity) ? el.cvssV3BaseSeverity : el.cvssV2BaseSeverity
                                                });
                                            }
                                        }


                                    });


                                    if (val.cves.length > cutOutIndex && !_.isEmpty(cveChildren)) {
                                        cveChildren.push({
                                            name: `...and ${val.cves.length - cutOutIndex} more`,
                                            type: 'cve'
                                        });

                                    }
                                    if (!_.isEmpty(cveChildren)) {
                                        rootChildren.push({
                                            name: getPackageDetails(key, 'packageName'),
                                            children: [...cveChildren],
                                            type: 'component'
                                        });
                                    }

                                }


                            });
                            if (hasCve) {
                                children.push({
                                    name: getModuleName(root.applicationLocation),
                                    children: [...rootChildren],
                                    type: 'module'
                                });
                            }


                        }




                    }


                });
            }

            if (!_.isEmpty(dataSet) && !_.isEmpty(dataSet.scanResult) && dataSet.scanResult.length <= 1) {
                newData = children[0];
            }
            else {
                newData.children = [...children];
            }

            // console.log("Here is new DATA::", newData);
            setData(newData);
        }

    }, [dataSet, filter]);

    const getModuleName = (name) => {
        if (!_.isEmpty(name)) {
            return name.split('/').pop();
        }
    }

    const getPackageDetails = (val, type, artifact) => {
        if (val) {
            let splitedPkg = val.split(":");
            switch (type) {
                case "artifact":
                    let sText = splitedPkg[1].split('/');
                    return sText[2];
                case "group":
                    let splitedText = splitedPkg[1].split('/');
                    return splitedText[1];
                case 'getEcosystem':
                    let ecosystem = splitedPkg[1].split('/');
                    return ecosystem[0];
                case 'packageName':
                    if (artifact === "maven") {
                        let strSplit = splitedPkg[1].split('/');
                        return `${strSplit[0]}/${strSplit[1]}:${strSplit[2]}`;
                    }
                    else {
                        return splitedPkg[1].split('/').pop();
                    }
                case 'getVersion':
                    let version = splitedPkg[1].split('@')
                    return version[1];
                default:
                    return splitedPkg[2];
            }
        }
        else {
            return ('');
        }
    }


    const onFilterChange = (e) => {
        // console.log(`checked = ${e.target.checked}`, e.target.value);
        if (e.target.checked) {
            setFilter(prev => [...prev, e.target.value]);
        }
        else {
            // console.log("I am here::")
            setFilter(prev => _.filter(prev, (el) => el !== e.target.value));
        }
    };


    return (
        <div>
            <Dropdown autoClose={'outside'}>
                <Dropdown.Toggle variant="outline-secondary" className='text-white' size='sm'>
                    <FontAwesomeIcon className='me-2' icon={faFilter} />Filter
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <ul className="list-group m-0">
                        <li className="list-group-item p-2 legend-linkGraph border-0"><Checkbox className='me-2' checked={_.includes(filter, 'CRITICAL')} onClick={onFilterChange} value={'CRITICAL'}>Critical</Checkbox></li>
                        <li className="list-group-item p-2 legend-linkGraph border-0"><Checkbox className='me-2' checked={_.includes(filter, 'HIGH')} onClick={onFilterChange} value={'HIGH'}>High</Checkbox></li>
                        <li className="list-group-item p-2 legend-linkGraph border-0"><Checkbox className='me-2' checked={_.includes(filter, 'MEDIUM')} onClick={onFilterChange} value={'MEDIUM'}>Medium</Checkbox></li>
                        <li className="list-group-item p-2 legend-linkGraph border-0"><Checkbox className='me-2' checked={_.includes(filter, 'LOW')} onClick={onFilterChange} value={'LOW'}>Low</Checkbox></li>
                    </ul>
                </Dropdown.Menu>
            </Dropdown>

            <div className='d-flex align-items-center justify-content-center'>
                {!_.isEmpty(data) ? <> <TidyTreeChart
                    data={data}
                    options={{
                        label: d => d.name,
                        title: (d, n) => `${n.ancestors().reverse().map(d => d.data.name).join(".")}`, // hover text
                        // link: (d, n) => `https://github.com/prefuse/Flare/${n.children ? "tree" : "blob"}/master/flare/src/${n.ancestors().reverse().map(d => d.data.name).join("/")}${n.children ? "" : ".as"}`,
                        width: 1152
                    }} /> </> : ''}
            </div>
        </div>
    );
}
