import React from 'react';
import { Button, Tag, Tooltip } from 'antd';
import { getStrokeColor, getFontColor } from '../../../shared/helper/genHelper';
import { FaToolbox } from "react-icons/fa";
import _ from "lodash";

const getSeverityCounts = (nodeData) => {
    if (!nodeData || !nodeData.cves || nodeData.cves.length === 0) return null;

    const counts = {
        CRITICAL: 0,
        HIGH: 0,
        MEDIUM: 0,
        LOW: 0
    };

    nodeData.cves.forEach(cve => {
        const severity = cve.cvssV3BaseSeverity
            ? cve.cvssV3BaseSeverity
            : cve.cvssV2BaseSeverity
                ? cve.cvssV2BaseSeverity
                : "NA";
        if (severity in counts) {
            counts[severity]++;
        }
    });

    return counts;
};

const VulnerabilityContent = ({ componentVulData, handleViewComponentDetails }) => {
    const packageInfo = componentVulData.data.packageDetails
    const recomendedFix = !_.isEmpty(packageInfo)
        ? (!_.isEmpty(packageInfo.stableRecommendedVersionList)
            ? packageInfo.stableRecommendedVersionList
            : !_.isEmpty(packageInfo.recommendedVersionList)
                ? packageInfo.recommendedVersionList
                : [])
        : []
    const counts = getSeverityCounts(packageInfo);

    return (<>
        <div className="d-flex align-items-center gap-2">
            <div className="d-flex align-items-center">
                {Object.entries(counts).map(([severity, count], index) => count > 0 && (
                    <Tooltip key={severity} title={`${count} ${severity}`}>
                        <Tag
                            color={getStrokeColor(severity)}
                            className="d-flex align-items-center justify-content-center"
                            style={{
                                color: getFontColor(severity),
                                cursor: 'default',
                                fontSize: 10,
                                borderRadius: '50%',
                                width: 18,
                                height: 18,
                            }}
                        >
                            {count}
                        </Tag>
                    </Tooltip>
                ))}
            </div>
            {/* {totalVulnerabilities > 0 && (
                <div className="d-flex align-items-center small">
                    <Tooltip title={canUpgrade ? 'Click to fix vulnerabilities' : 'Upgrade not available'}>
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                onUpgrade?.();
                            }}
                            disabled={!canUpgrade}
                            className={`btn btn-warning d-flex align-items-center py-1 px-2`}
                        >
                            <FontAwesomeIcon className='me-1' icon={faArrowCircleUp} />
                            <small>Fix ({totalVulnerabilities})</small>
                        </button>
                    </Tooltip>
                </div>
            )} */}
            <div className='d-flex align-items-center'>
                <Button
                    type="primary"
                    onClick={handleViewComponentDetails}
                    className="ms-auto fix-btn p-1"
                    style={{ height: "auto" }}>
                    <small style={{ fontSize: 12, lineHeight: "12px" }}>View Details</small>
                </Button>
            </div>
        </div>
        <span><FaToolbox /><small className='ps-2' style={{ fontSize: 12 }}>Upgrade to {!_.isEmpty(recomendedFix) && recomendedFix[0]}</small></span >
    </>

    );
};

export default VulnerabilityContent