import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import _ from 'lodash';
import axios from 'axios';
import { GlobalConst } from '../../../shared/appConfig/globalConst';
import VulDataTable from "./detailsModalTabs/vulDataTable"
import { useSelector } from 'react-redux';
import LayerDataTable from './detailsModalTabs/layerDataTable';
import ComponentDataTable from './detailsModalTabs/componentDataTable';
import StatsGraphMetaData from './detailsModalTabs/statsGraphMetaData';
import SecurityPostureTab from './detailsModalTabs/securityPostureTab';
import OWASP10TabView from './detailsModalTabs/OWASP10TabView';
import ResourceGraph from './resourceGraph';
import NoDataFound from '../../../shared/sharedComponents/noDataFound';
import { getCloudIcon } from '../../../shared/helper/genHelper';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ResourceDetailsModal({ showResourceDetailsModal, setShowResourceDetailsModal, setLoading }) {
    const [activeTabsKey, setActiveTabsKey] = useState('vulView');
    const [searchResults, setSearchResults] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [selectedFile, setSelectedFile] = useState("all");
    const [selectedResourceDetails, setSelectedResourceDetails] = useState({});
    const activeSelectedRecord = useSelector(state => state.kubernetesSecurityReducer.activeSelectedRecord)

    useEffect(() => {

        if (!_.isEmpty(activeSelectedRecord)) {
            getHierarchyData()
        }
    }, [activeSelectedRecord]);

    const getReportDetails = (scanReportId) => {
        setLoading(true);
        axios
            .get(
                `${GlobalConst.API_URL}/auth/foss/report/${scanReportId}`
            )
            .then((op) => {
                setLoading(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setSearchResults(op.data.scannerResponseEntity);
                }
            })
            .catch((e) => {
                setLoading(false);
                console.log("Exception: ", e);
            });
    }

    const getHierarchyData = () => {
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/k8s/resource/details`, activeSelectedRecord)
            .then(op => {
                setLoading(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    if (!_.isEmpty(op.data.contsec) && !_.isEmpty(op.data.contsec.reports)) {
                        const report = op.data.contsec.reports[0]
                        if (report.scanReportId) {
                            getReportDetails(report.scanReportId)
                        }
                    }
                    setSelectedResourceDetails(op.data);
                }
            })
            .catch(e => {
                setLoading(false);
                setSelectedResourceDetails({});
            })

    }

    const getFileLocation = (file) => {
        let fileSplit = file.split("/");
        return `${fileSplit[fileSplit.length - 2]}/${fileSplit.pop()}`;
    };

    const getComponentCount = () => {
        let count = 0;
        if (!_.isEmpty(searchResults)) {
            searchResults.scanResult.forEach((el, index) => {
                if (!_.isEmpty(el) && !_.isEmpty(el.scanResult)) {
                    _.forIn(el.scanResult, (val) => {
                        if (!_.isEmpty(val) && !_.isEmpty(val.cves)) {
                            count = count + 1;
                        }
                    });
                }
            });
        }
        return count;
    };

    const handleSeverityFilterChange = (e, val) => {
        if (e.target.checked) {
            setSelectedFilter([...selectedFilter, val]);
        } else {
            setSelectedFilter(_.filter(selectedFilter, (elm) => elm !== val));
        }
    };

    const hideResourceDetailsModal = () => {
        setShowResourceDetailsModal(false);
    }

    const getAvailableTabs = () => {
        const tabs = [];
        tabs.push('vulView');
        tabs.push('securityPosture');
        if (!_.isEmpty(searchResults)) {
            tabs.push('compView');
            if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.scanResult)) {
                tabs.push('layerView');
            }
            tabs.push('OWASP10View');
            if (!_.isEmpty(searchResults.zeroDayCveCountMetadata)) {
                tabs.push('zeroDayView');
            }
        }
        return tabs;
    }

    const availableTabs = getAvailableTabs()

    return (
        <Modal show={showResourceDetailsModal} fullscreen={true} onHide={hideResourceDetailsModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className='d-flex align-items-start justify-content-start flex-row'>
                        <a className='me-3 mt-1' onClick={hideResourceDetailsModal} href='#' style={{ fontSize: 22 }}><small ><FontAwesomeIcon icon={faArrowLeft} /></small></a>
                        {!_.isEmpty(selectedResourceDetails) && <span><img className='me-2' src={getCloudIcon(selectedResourceDetails.docType, "k8s")} style={{ width: 30 }} />{`${selectedResourceDetails.docType}:${selectedResourceDetails.name}`}</span>}                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
                <div className='p-2 px-3 scroll--simple' style={{ height: 'calc(100vh - 56px)', overflowY: 'auto' }}>

                    <div className='mb-2'>
                        <section>
                            <div className="card custom-card mb-0">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-sm-10' >
                                            <h5>Security Tree</h5>
                                            <div style={{ width: '100%', height: '100%' }}>
                                                {/* {!_.isEmpty(selectedResourceDetails) && <FlowGraph data={selectedResourceDetails} />} */}
                                                {!_.isEmpty(selectedResourceDetails) && <ResourceGraph apidata={selectedResourceDetails} />}
                                            </div>
                                        </div>
                                        {(!_.isEmpty(selectedResourceDetails) && !_.isEmpty(selectedResourceDetails.metadata)) &&
                                            <div className='col-sm-2'>
                                                <div className="card custom-card h-100 mb-0" style={{ background: '#37435b' }}>
                                                    <div className="card-body">
                                                        <h5 className='mb-3 border-bottom pb-1'>Statistics</h5>
                                                        <StatsGraphMetaData metadata={selectedResourceDetails.metadata} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className='mb-2'>
                        <Tabs
                            className="mb-3 report-tabs"
                            activeKey={activeTabsKey}
                            onSelect={(k) => setActiveTabsKey(k)}
                            mountOnEnter={true}
                            unmountOnExit={true}
                        >
                            <Tab
                                eventKey="vulView"
                                title={<span style={{ lineHeight: 1 }}>
                                    <span>
                                        Vulnerability View
                                        <small
                                            className="d-block text-muted"
                                            style={{ fontSize: 14 }}
                                        >
                                            {" "}
                                            total{" "}
                                            <span
                                                style={{
                                                    fontWeight: 700,
                                                    color: "#b3b3b3",
                                                }}
                                            >
                                                {!_.isEmpty(searchResults) &&
                                                    searchResults.totalCve
                                                    ? searchResults.totalCve
                                                    : "0"}
                                            </span>{" "}
                                        </small>
                                    </span>
                                </span>
                                }
                            >
                                {!_.isEmpty(searchResults) && !_.isEmpty(searchResults.scanResult) ? <section className="mb-5">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className='mb-4'>
                                                <VulDataTable
                                                    searchResults={searchResults}
                                                    dataSet={searchResults.scanResult}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section> : <NoDataFound
                                    title={"No Vulnerability Data Found"}
                                />}
                            </Tab>
                            {availableTabs.includes('compView') && (<Tab
                                eventKey="compView"
                                title={
                                    <span style={{ lineHeight: 1 }}>
                                        <span>Component View</span>
                                        <small
                                            className="d-block text-muted"
                                            style={{ fontSize: 14 }}
                                        >
                                            {" "}
                                            total{" "}
                                            <span
                                                style={{
                                                    fontWeight: 700,
                                                    color: "#b3b3b3",
                                                }}
                                            >
                                                {!_.isEmpty(searchResults) &&
                                                    searchResults.totalComponents
                                                    ? searchResults.totalComponents
                                                    : "0"}
                                            </span>{" "}
                                            (
                                            <span
                                                className="text-danger"
                                                style={{ fontWeight: 700 }}
                                            >
                                                {getComponentCount()}
                                            </span>{" "}
                                            with vul)
                                        </small>
                                    </span>
                                }
                            >
                                <section className="mb-5">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <ComponentDataTable
                                                    searchResults={searchResults}
                                                    dataSet={searchResults.scanResult}
                                                    filter={selectedFilter}
                                                    handleSeverityFilterChange={
                                                        handleSeverityFilterChange
                                                    }
                                                    selectedFile={selectedFile}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Tab>)}
                            {availableTabs.includes('layerView') && (<Tab
                                eventKey="layerView"
                                title={
                                    <span style={{ lineHeight: 1 }}>
                                        <span>
                                            Layer View
                                            <small
                                                className="d-block text-muted"
                                                style={{ fontSize: 14 }}
                                            >
                                                {" "}
                                                total{" "}
                                                <span
                                                    style={{
                                                        fontWeight: 700,
                                                        color: "#b3b3b3",
                                                    }}
                                                >
                                                    {!_.isEmpty(searchResults.scanResult[0].containerMetaInfo) &&
                                                        !_.isEmpty(searchResults.scanResult[0].containerMetaInfo.layers)
                                                        ? searchResults.scanResult[0].containerMetaInfo.layers.length
                                                        : "0"}
                                                </span>{" "}
                                            </small>
                                        </span>
                                    </span>
                                }
                            >
                                <section className="mb-5">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                {!_.isEmpty(searchResults) && !_.isEmpty(searchResults.scanResult) && (
                                                    <LayerDataTable
                                                        dataSet={searchResults.scanResult[0]}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Tab>)}
                            <Tab eventKey="securityPosture"
                                title={
                                    <span style={{ lineHeight: 1 }}>
                                        <span>
                                            Security Posture
                                            <small
                                                className="d-block text-muted"
                                                style={{ fontSize: 14 }}
                                            >
                                                {" "}
                                                total{" "}
                                                <span
                                                    style={{
                                                        fontWeight: 700,
                                                        color: "#b3b3b3",
                                                    }}
                                                >
                                                    {!_.isEmpty(selectedResourceDetails) &&
                                                        !_.isEmpty(selectedResourceDetails.cspm)
                                                        ? selectedResourceDetails.cspm.critical + selectedResourceDetails.cspm.high + selectedResourceDetails.cspm.low + selectedResourceDetails.cspm.medium
                                                        : "0"}
                                                </span>{" "}
                                            </small>
                                        </span>
                                    </span>
                                }>
                                {!_.isEmpty(selectedResourceDetails) && !_.isEmpty(selectedResourceDetails.cspm) ? <section className="mb-5">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <SecurityPostureTab data={selectedResourceDetails.cspm} />
                                            </div>
                                        </div>
                                    </div>
                                </section> : <NoDataFound
                                    title={"No Security Posture Data Found"}
                                />}
                            </Tab>
                            {availableTabs.includes('OWASP10View') && <Tab
                                eventKey="OWASP10View"
                                title={
                                    <span style={{ lineHeight: 1 }}>
                                        <span>
                                            OWASP Top 10
                                            <small
                                                className="d-block text-muted"
                                                style={{ fontSize: 14 }}
                                            >
                                                {" "}
                                                &nbsp;
                                            </small>
                                        </span>
                                    </span>
                                }
                            >
                                {!_.isEmpty(searchResults) &&
                                    !_.isEmpty(searchResults.owaspTop10CveCountMetadataList) &&
                                    !_.isEmpty(searchResults.scanResult) ? (
                                    <>
                                        <div className="d-flex flex-column">
                                            <div className="row">
                                                <div className={`col-sm-12`}>
                                                    <OWASP10TabView
                                                        searchResults={searchResults}
                                                        dataSet={searchResults.scanResult}
                                                        reportDetails={
                                                            searchResults.owaspTop10CveCountMetadataList
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : <NoDataFound
                                    title={"No OWASP Top 10 Data Found"}
                                />}
                            </Tab>}
                            {availableTabs.includes('zeroDayView') && <Tab
                                eventKey="zeroDayView"
                                title={
                                    <span style={{ lineHeight: 1 }}>
                                        <span>
                                            ZeroDay
                                            <small
                                                className="d-block text-muted"
                                                style={{ fontSize: 14 }}
                                            >
                                                {" "}
                                                total{" "}
                                                <span
                                                    style={{
                                                        fontWeight: 700,
                                                        color: "#b3b3b3",
                                                    }}
                                                >
                                                    {!_.isEmpty(searchResults
                                                        .zeroDayCveCountMetadata
                                                        .cveIdList)
                                                        ? searchResults
                                                            .zeroDayCveCountMetadata
                                                            .cveIdList.length
                                                        : "0"}
                                                </span>{" "}
                                            </small>
                                        </span>
                                    </span>
                                }
                            >
                                <>
                                    <div className="d-flex flex-column">
                                        <div className="row">
                                            <div className={`col-sm-12`}>
                                                <VulDataTable
                                                    cveIdList={
                                                        searchResults
                                                            .zeroDayCveCountMetadata
                                                            .cveIdList
                                                    }
                                                    searchResults={searchResults}
                                                    dataSet={searchResults.scanResult}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </Tab>}
                        </Tabs>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
}