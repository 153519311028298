import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';

const kubernetesSecurityReducer = createSlice({
    name: 'kubernetesSecurityReducer',
    initialState: {
        iScanModalState: false,
        kubernetesSecurityScanTriggered: false,
        kubernetesSecurityDashTableData: [],
        kubernetesSecurityTriggeredScans: [],
        activeSelectedRecord: {}
    },
    reducers: {
        setIScanModalState: (state, action) => {
            state.iScanModalState = action.payload;
        },
        setKubernetesSecurityScanTriggeredState: (state, action) => {
            state.kubernetesSecurityScanTriggered = action.payload;
        },
        setKubernetesSecurityDashboardTableData: (state, action) => {
            state.kubernetesSecurityDashTableData = action.payload;
        },
        updateKubernetesSecurityTriggeredScans: (state, action) => {
            state.kubernetesSecurityTriggeredScans = action.payload;
        },
        updateSelectedK8sRecord: (state, action) => {
            state.activeSelectedRecord = action.payload;
        },
        addUpdateKubernetesSecurityDashboardTableData: (state, action) => {
            let clonedKubernetesSecurityTableData = _.cloneDeep(state.kubernetesSecurityDashTableData);
            console.log("I am here", clonedKubernetesSecurityTableData)
            clonedKubernetesSecurityTableData = clonedKubernetesSecurityTableData.map((record) => {
                const presentRecord = action.payload.find(item => item.integrationId === record.integrationId);
                if (presentRecord) {
                    const updatedScanStatuses = {...record.scanStatuses};
                    Object.keys(updatedScanStatuses).forEach(key => {
                        updatedScanStatuses[key] = {
                            ...updatedScanStatuses[key],
                            status: "INITIATED"
                        };
                    }); 
                    return {
                        ...record,
                        scanStatuses: updatedScanStatuses,
                        overallStatus: presentRecord.status ? presentRecord.status : "",
                        reportId: presentRecord.reportId
                    };
                }
                return record;
            });
            console.log("I have updated data here", clonedKubernetesSecurityTableData)

            const initiatedOrScanning = clonedKubernetesSecurityTableData.filter(record => record.overallStatus.toLowerCase() === "initiated");
            const otherStatuses = clonedKubernetesSecurityTableData.filter(record => record.overallStatus.toLowerCase() !== "initiated");
            const sortedRecords = [...initiatedOrScanning, ...otherStatuses];
        
            state.kubernetesSecurityDashTableData = sortedRecords;
        
            const k8sSecurityScans = initiatedOrScanning.map(record => record.reportId);
        
            state.kubernetesSecurityTriggeredScans = k8sSecurityScans;
        }
    }
})

export const { setIScanModalState, setKubernetesSecurityScanTriggeredState, setKubernetesSecurityDashboardTableData, addUpdateKubernetesSecurityDashboardTableData, updateKubernetesSecurityTriggeredScans, updateSelectedK8sRecord } = kubernetesSecurityReducer.actions;

export default kubernetesSecurityReducer.reducer;