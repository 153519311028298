import React, { useMemo, useState } from 'react';
import { Table, Tag, Switch, Popover, Button } from 'antd';
import _ from 'lodash';
import { getFontColor, getStrokeColor } from '../../../../shared/helper/genHelper';
import { WarningFilled, CheckCircleFilled, CloseOutlined } from '@ant-design/icons';
import NoDataFound from '../../../../shared/sharedComponents/noDataFound';
import { FaCircleInfo } from "react-icons/fa6";
import TableFilterDropdown from '../../../../shared/sharedComponents/antdTableComponents/tableFilterDropDown';

const SecurityPostureTab = ({ data }) => {

    const [showAllComponents, setShowAllComponents] = useState(false);
    
    const dataTableColumns = [
        { title: 'Sno', dataIndex: 'sno', key: 'sno', ellipsis: true, width: 60 },
        {
            title: 'Message', dataIndex: 'message', key: 'message', ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <Popover
                            content={<div className='scroll--simple' style={{ width: 400, maxHeight: 400, overflow: 'hidden', overflowY: 'scroll' }}>
                                <p className='mb-3'><strong>Description:</strong> {record.description}</p>
                                {record.impact && <p className='mb-3'><strong>Impact:</strong> {record.impact}</p>}
                                <div className='mb-1'>
                                    <strong>References</strong>
                                    {(_.has(record, 'references') && !_.isEmpty(record.references)) && <ul>
                                        {record.references.map((el, index) => (
                                            <li key={`ref-${record.name}-${index}`}>
                                                <a target='_blank' href={el} rel="noopener noreferrer">{el}</a>
                                            </li>
                                        ))}
                                    </ul>}
                                </div>
                            </div>}
                            title={
                                <div className="d-flex align-items-center justify-content-between w-100 mb-3">
                                    <span style={{ fontSize: 16 }}><strong>Details</strong></span>
                                    <Button
                                        type="text"
                                        size="small"
                                        icon={<CloseOutlined />}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            const triggerElements = document.getElementsByClassName('ant-popover-open');
                                            if (triggerElements && triggerElements[0]) {
                                                triggerElements[0].dispatchEvent(new MouseEvent('click', {
                                                    bubbles: true,
                                                    cancelable: true,
                                                    view: window
                                                }));
                                            }
                                        }}
                                    />
                                </div>
                            }
                            trigger="click"
                            destroyTooltipOnHide
                            placement="right"
                            rootClassName="ant-popover-closable"
                        >
                            <FaCircleInfo className='me-2 cursor-pointer' />
                        </Popover>
                        {text}
                    </>
                )
            }
        },
        { title: 'Framework', dataIndex: 'frameworkName', key: 'frameworkName', ellipsis: true, width: 140 },
        { title: 'Rule No', dataIndex: 'ruleNo', key: 'ruleNo', ellipsis: true, width: 90, align: 'left' },
        {
            title: 'Status',
            key: 'status',
            width: 100,
            filters: [
                { text: 'Pass', value: 'Pass' },
                { text: 'Fail', value: 'Fail' },
                { text: 'N/A', value: 'NA' }
            ],
            onFilter: (value, record) => record.violated.toLowerCase() === value.toLowerCase(),
            filterDropdown: (props) => <TableFilterDropdown {...props} />,
            render: (_, record) => (
                <span>
                    {record.violated === 'Pass' ? (
                        <CheckCircleFilled style={{ color: 'green' }} />
                    ) : (
                        <WarningFilled style={{ color: getStrokeColor('Critical') }} />
                    )}
                    {' '}
                    {record.violated}
                </span>
            ),
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
            width: 120,
            filters: [
                { text: 'CRITICAL', value: 'CRITICAL' },
                { text: 'HIGH', value: 'HIGH' },
                { text: 'MEDIUM', value: 'MEDIUM' },
                { text: 'LOW', value: 'LOW' }
            ],
            onFilter: (value, record) => record.severity.toLowerCase() === value.toLowerCase(),
            filterDropdown: (props) => <TableFilterDropdown {...props} />,
            render: (text, record) => (
                <Tag
                    style={{
                        color: getFontColor(text),
                        borderRadius: 50,
                        fontSize: 12,
                        fontWeight: 600
                    }}
                    color={getStrokeColor(text)}
                    className={"mx-auto text-center text-uppercase"}
                >
                    {text}
                </Tag>
            ),
        }
    ];

    const onChangeShowAllComponents = (checked) => {
        setShowAllComponents(checked);
    }

    const myUpdatedData = useMemo(() => {


        return (_.isEmpty(data) && _.isEmpty(data.policies)) ? [] : data.policies.map((row, index) => {
            const keys = ['critical', 'high', 'medium', 'low'];
            const total = _.sum(_.map(keys, key => _.toNumber(_.get(row, key, 0))));

            return {
                ...row,
                total: total,
                key: `cloud-adv-dashboard-detail-${index}`
            }
        });
    }, [data]);

    const filteredData = showAllComponents ? myUpdatedData : myUpdatedData.filter(item => item.violated.toLowerCase() === 'fail');

    // Sort the expanded data based on severity
    const sortedData = _.orderBy(filteredData,
        [item => {
            switch (item.severity.toLowerCase()) {
                case 'critical': return 1;
                case 'high': return 2;
                case 'medium': return 3;
                case 'low': return 4;
                default: return 5;
            }
        }],
        ['asc']
    );


    // const thirdLevelExpandedRowRender = (record) => {
    //     return (
    //         <div className="card custom-card p-2 px-5 border-0">
    //             <p className='mb-2'><strong style={{ fontSize: 16 }}>Details</strong></p>
    //             <p className='mb-1'><strong>Description:</strong> {record.description}</p>
    //             {record.impact && <p className='mb-1'><strong>Impact:</strong> {record.impact}</p>}
    //             <div className='mb-1'>
    //                 <strong>References</strong>
    //                 <ul>
    //                     {record.references.map((el, index) => (
    //                         <li key={`ref-${record.name}-${index}`}>
    //                             <a target='_blank' href={el} rel="noopener noreferrer">{el}</a>
    //                         </li>
    //                     ))}
    //                 </ul>
    //             </div>
    //         </div>
    //     );
    // };

    const finalData = sortedData.map((row, index) => ({
        ...row,
        sno: index + 1,
    }));

    return (
        <div>
            <div className='d-flex align-items-center justify-content-end mb-4 me-3 mt-2' style={{ position: 'absolute', right: 0, top: '-56px' }}>
                <Switch onChange={onChangeShowAllComponents} checked={showAllComponents} className='me-2' />Show all
            </div>
            <Table
                size='small'
                className='custom-table mb-0 fixedScrollCustomTable table-hover component-view-table'
                dataSource={finalData}
                columns={dataTableColumns}
                locale={{
                    emptyText: <NoDataFound title={'No Data'} />
                }}
                // expandable={{
                //     expandedRowRender: thirdLevelExpandedRowRender,
                //     rowExpandable: (record) => true,
                // }}
                pagination={{
                    showTotal: (total, range) => {
                        return (
                            <span className="text-white">
                                {`${range[0]}-${range[1]} of ${total && total.format()} issues`}
                            </span>
                        );
                    },
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    position: ["bottom", "left"],
                    className: "searchPagePagination px-0",
                    pageSizeOptions: [10, 20, 25],
                }}
            />
        </div>
    );
};

export default SecurityPostureTab;