import React from 'react';
import { Button, Checkbox, Space } from 'antd';
import _ from 'lodash';

const TableFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filters,
    closeDropdown = true
}) => {
    const handleReset = () => {
        clearFilters();
        if (closeDropdown) {
            confirm();
        }
    };

    const handleConfirm = () => {
        confirm();
    };

    const handleCheckboxChange = (value, checked) => {
        const newKeys = checked
            ? [...selectedKeys, value]
            : selectedKeys.filter(k => k !== value);
        setSelectedKeys(newKeys);
    };

    return (
        <div style={{ padding: 8 }}>
            {filters?.map(({ text, value }) => (
                <div key={value} style={{ marginBottom: 8 }}>
                    <Checkbox
                        checked={selectedKeys.includes(value)}
                        onChange={(e) => handleCheckboxChange(value, e.target.checked)}
                    >
                        {text}
                    </Checkbox>
                </div>
            ))}
            <Space>
                <Button
                    onClick={handleReset}
                    type="primary"
                    disabled={_.isEmpty(selectedKeys)}
                    size="small"
                >
                    Reset
                </Button>
                <Button
                    type="primary"
                    onClick={handleConfirm}
                    size="small"
                >
                    OK
                </Button>
            </Space>
        </div>
    );
};

export default TableFilterDropdown;