import sec1Logo from '../../../assets/img/sec1-logo-svg-orange.svg'

export default function LoadingPageComponent() {
    return (
        <div className="mx-3 w-100 h-100" >
            <div className="d-flex align-items-center justify-content-center flex-column" style={{ minHeight: "calc(-131px + 100vh)" }}>
                <img className='mb-2' src={sec1Logo} style={{ width: 120 }} />
                <h3>Loaing Screen</h3>
                <p className='text-muted'>please wait while we load this screen, this might take few seconds, please be patient.</p>
            </div>
        </div>
    );
}