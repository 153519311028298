import React, { useCallback, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useD3 } from '../../../../shared/hooks/useD3';
import { getStrokeColor, getFontColor } from '../../../../shared/helper/genHelper';
import { GlobalConst } from '../../../../shared/appConfig/globalConst';
import { Popover } from 'antd';
import _ from "lodash";
import VulnerabilityContent from '../vulnerabilityInfo';
import { faWandMagicSparkles, faCircleCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactDOM from 'react-dom';

const getSeverityCounts = (nodeData) => {
    if (!nodeData || !nodeData.cves || nodeData.cves.length === 0) return null;

    const counts = {
        CRITICAL: 0,
        HIGH: 0,
        MEDIUM: 0,
        LOW: 0
    };

    nodeData.cves.forEach(cve => {
        const severity = cve.cvssV3BaseSeverity
            ? cve.cvssV3BaseSeverity
            : cve.cvssV2BaseSeverity
                ? cve.cvssV2BaseSeverity
                : "NA";
        if (severity in counts) {
            counts[severity]++;
        }
    });

    return counts;
};

// Helper function to check if a node matches any of the selected filters
const matchesSelectedFilters = (node, selectedSeverity) => {
    if (!node.data.packageDetails?.cves) return false;

    const hasKevFilter = selectedSeverity.includes("kev");
    const hasZerodayFilter = selectedSeverity.includes("zeroday");
    const regularSeverities = selectedSeverity.filter(s => !["kev", "zeroday"].includes(s));

    // Check KEV vulnerabilities
    if (hasKevFilter && node.data.packageDetails.cves.some(cve => cve.kev === true)) {
        return true;
    }

    // Check Zero Day vulnerabilities
    if (hasZerodayFilter && node.data.packageDetails.cves.some(cve => cve.zeroDay === true)) {
        return true;
    }

    // Check regular severity levels
    if (regularSeverities.length > 0 && regularSeverities.includes(node.data.severity)) {
        return true;
    }

    return false;
};

// Helper function to check if node should be visible
const shouldShowNode = (node, selectedSeverity) => {
    if (_.isEmpty(selectedSeverity)) return true; // Show all nodes if no severity is selected
    if (node.depth === 0) return true; // Always show root node

    // Check if node matches any of the selected filters
    const isExactMatch = matchesSelectedFilters(node, selectedSeverity);

    // Show node if it's a necessary parent of a matching node
    const isParentOfMatch = isNecessaryParent(node, selectedSeverity);

    // Node is visible if it either matches exactly or is a necessary parent
    return isExactMatch || isParentOfMatch;
};

// Update the shouldShowLink function to handle intermediate nodes
const shouldShowLink = (source, target, selectedSeverity) => {
    if (_.isEmpty(selectedSeverity)) return true;
    if (source.depth === 0) return true;

    // Show link if either node exactly matches or is a necessary intermediate
    const sourceVisible = shouldShowNode(source, selectedSeverity);
    const targetVisible = shouldShowNode(target, selectedSeverity);

    return sourceVisible && targetVisible;
};

// Helper function to check if node is a necessary parent of visible nodes
const isNecessaryParent = (node, selectedSeverity) => {
    if (node.depth === 0) return true; // Root is always necessary

    // Check if any descendants match the selected filters
    return node.descendants().some(descendant => {
        // Skip the current node
        if (descendant === node) return false;

        // Check if descendant matches any of the selected filters
        return matchesSelectedFilters(descendant, selectedSeverity);
    });
};

// Helper function to get node opacity
const getNodeOpacity = (node, selectedSeverity) => {
    if (!shouldShowNode(node, selectedSeverity)) return 0;
    if (node.depth === 0) return 1; // Root node always fully visible

    // Check if node is an exact match or just a necessary parent
    const isExactMatch = matchesSelectedFilters(node, selectedSeverity);
    return isExactMatch ? 1 : 0.7; // Exact matches fully visible, parents slightly transparent
};

// Helper function to check for exact matches
const isNodeExactMatch = (node, selectedSeverity) => {
    if (node.depth === 0 || _.isEmpty(selectedSeverity)) return true;
    if (!node.data.packageDetails?.cves) return false;

    return selectedSeverity.some(filter => {
        if (filter === 'kev') {
            return node.data.packageDetails.cves.some(cve => cve.kev === true);
        }
        if (filter === 'zeroday') {
            return node.data.packageDetails.cves.some(cve => cve.zeroDay === true);
        }
        return filter === node.data.severity;
    });
};

const createHexagonPath = (radius) => {
    const points = [];
    for (let i = 0; i < 6; i++) {
        const angle = (i * Math.PI) / 3;
        points.push([
            radius * Math.cos(angle),
            radius * Math.sin(angle)
        ]);
    }
    return `M ${points.map(p => p.join(',')).join(' L ')} Z`;
};

const createTrianglePath = (radius) => {
    const height = radius * 2;
    const width = height * Math.sin(Math.PI / 3);
    return `M 0,${-height / 2} L ${width / 2},${height / 2} L ${-width / 2},${height / 2} Z`;
};

const createSquarePathRelative = (radius) => {
    const size = radius * 2;

    // Create square using relative movements
    return `M ${-radius},${-radius} ` +  // Move to start
        `h ${size} ` +                // Right
        `v ${size} ` +                // Down
        `h ${-size} ` +               // Left
        'Z';                          // Close path
};

// Helper function to get link color based on node status
const getLinkColor = (target, selectedSeverity) => {
    const isVisibleAfterVulSearch = target.data.packageDetails?.cves?.some(v => v.showCve);

    if (target.depth === 0) return getStrokeColor(target.data.severity);

    const isExactMatch = isNodeExactMatch(target, selectedSeverity);
    if (!isExactMatch || !isVisibleAfterVulSearch) {
        return "#d4d4d4"; // Light gray for intermediate nodes
    }

    if (target.data.severity !== "NA") {
        return getStrokeColor(target.data.severity);
    }

    return "#868686"; // Default gray
};

function createSVG(
    data, {
        path,
        id = Array.isArray(data) ? d => d.id : null,
        parentId = Array.isArray(data) ? d => d.parentId : null,
        children,
        tree = d3.tree,
        sort,
        label,
        title,
        link,
        linkTarget = "_blank",
        width = 640,
        height,
        color = t => d3.interpolateRdBu(1 - t),
        r = 3.65,
        padding = 0.8,
        fill = "#999",
        fillOpacity,
        stroke = "#555",
        strokeWidth = 2,
        strokeOpacity = 0.9,
        strokeLinejoin,
        strokeLinecap,
        halo = "#fff",
        haloWidth = 2,
        curve = d3.curveBumpX,
    } = {},
    svg,
    onNodeHover,
    onNodeLeave,
    showIntermediateNodes,
    selectedSeverity,
    onNodeClick,
    onAutoFixClick) {

    console.log(onAutoFixClick)

    const root = path != null ? d3.stratify().path(path)(data)
        : id != null || parentId != null ? d3.stratify().id(id).parentId(parentId)(data)
            : d3.hierarchy(data, children);

    if (sort != null) root.sort(sort);

    const descendants = root.descendants();
    const L = label == null ? null : descendants.map(d => label(d.data, d));
    const dx = 36;  // Vertical spacing between nodes
    const dy = width / (root.height + padding);  // Horizontal spacing
    // Configure tree layout with consistent spacing and separation
    const treeLayout = tree()
        .nodeSize([dx, dy])
        .separation((a, b) => {
            return 1;  // Use consistent separation for all nodes
        });
    treeLayout(root);

    // Post-process node positions to ensure even vertical spacing
    const processNodePositions = (node, level = 0) => {
        if (!node) return;

        // For nodes with children, adjust their vertical position
        if (node.children && node.children.length > 0) {
            // Calculate the average position of children
            const childrenMean = d3.mean(node.children, d => d.x);

            // Set the node's position to maintain even spacing
            node.x = childrenMean;

            // Process all children
            node.children.forEach((child, i) => {
                processNodePositions(child, level + 1);
            });
        }
    };

    // Process positions starting from root
    processNodePositions(root);

    const getNodeRadius = (d) => {
        // Check if this is the root node
        if (d.depth === 0) {
            return 16; // Doubled size for root node
        }

        const count = d.data.nodeCount || 0;
        const minRadius = 8;
        if (count === 0) return minRadius;
        if (count < 10) return minRadius;
        if (count < 100) return minRadius + 1;
        return minRadius + 2;
    };

    // Additional pass to ensure minimum spacing between all nodes at same level
    const nodesByLevel = d3.group(descendants, d => d.depth);
    nodesByLevel.forEach(nodes => {
        nodes.sort((a, b) => a.x - b.x);
        for (let i = 1; i < nodes.length; i++) {
            const minSpacing = dx;
            const actualSpacing = nodes[i].x - nodes[i - 1].x;
            if (actualSpacing < minSpacing) {
                const shift = minSpacing - actualSpacing;
                nodes[i].x += shift;
                // Shift all subsequent nodes at this level
                for (let j = i + 1; j < nodes.length; j++) {
                    nodes[j].x += shift;
                }
            }
        }
    });

    let x0 = Infinity;
    let x1 = -x0;
    root.each(d => {
        if (d.x > x1) x1 = d.x;
        if (d.x < x0) x0 = d.x;
    });
    if (height === undefined) height = x1 - x0 + dx * 2;

    if (typeof curve !== "function") throw new Error(`Unsupported curve`);

    svg
        .attr('shape-rendering', 'geometricPrecision')
        .attr("viewBox", [-dy * padding / 2, x0 - dx, width, height])
        .attr("width", width)
        .attr("height", height + 80)
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
        .attr("font-size", 13);
    svg.selectAll("*").remove();

    const defs = svg.append('defs');

    // Create gradient definitions
    GlobalConst.LINK_COLORS.forEach((mapColor, index) => {
        const linearGradient = defs.append('linearGradient')
            .attr('id', `g${index}`)
            .attr('gradientUnits', 'userSpaceOnUse')
            .attr('spreadMethod', 'reflect')
            .attr('inkscapeCollect', 'always');

        if (index === 0) {
            linearGradient
                .attr('x1', '0')
                .attr('y1', '100%')
                .attr('x2', '18%')
                .attr('y2', '100%');
        } else if (index === 1) {
            linearGradient
                .attr('x1', '25%')
                .attr('y1', '100%')
                .attr('x2', '50%')
                .attr('y2', '100%');
        } else {
            linearGradient
                .attr('x1', '55%')
                .attr('y1', '100%')
                .attr('x2', '25%')
                .attr('y2', '100%');
        }

        linearGradient.append('stop')
            .attr('style', `stop-color:${mapColor}`)
            .attr('offset', '0%');
        linearGradient.append('stop')
            .attr('style', `stop-color:${GlobalConst.LINK_GRADIENT_COLORS[index]}`)
            .attr('offset', '100%');
    });

    // Add drop shadow filter
    var dropShadowFilter = defs.append('svg:filter')
        .attr('id', 'drop-shadow')
        .attr('filterUnits', "userSpaceOnUse")
        .attr('width', '250%')
        .attr('height', '250%');
    dropShadowFilter.append('svg:feGaussianBlur')
        .attr('in', 'SourceGraphic')
        .attr('stdDeviation', 5)
        .attr('result', 'blur-out');
    dropShadowFilter.append('svg:feColorMatrix')
        .attr('in', 'blur-out')
        .attr('type', 'hueRotate')
        .attr('values', 180)
        .attr('result', 'color-out');
    dropShadowFilter.append('svg:feOffset')
        .attr('in', 'color-out')
        .attr('dx', 0)
        .attr('dy', 0)
        .attr('result', 'the-shadow');
    dropShadowFilter.append('svg:feBlend')
        .attr('in', 'SourceGraphic')
        .attr('in2', 'the-shadow')
        .attr('mode', 'normal');

    // Update the pulse animation CSS in the defs section
    const style = defs.append('style')
        .text(`
            @keyframes pulse {
                0% {
                    transform: scale(1);
                    stroke-opacity: 0.5;
                }
                70% {
                    transform: scale(2.3);
                    stroke-opacity: 0;
                }
                100% {
                    transform: scale(1);
                    stroke-opacity: 0;
                }
            }
        `);

    svg.append("g")
        .attr("fill", "none")
        .selectAll("path")
        .data(root.links())
        .join("path")
        .attr("d", d3.link(curve)
            .x(d => d.y)
            .y(d => d.x))
        .attr("stroke", (d) => getLinkColor(d.target, selectedSeverity))
        .attr("stroke-width", (d) => {
            const isExactMatch = isNodeExactMatch(d.target, selectedSeverity);
            return isExactMatch ? strokeWidth : strokeWidth * 0.8; // Thinner lines for intermediate nodes
        })
        .attr("stroke-opacity", (d) => {
            const isExactMatch = isNodeExactMatch(d.target, selectedSeverity);
            return isExactMatch ? strokeOpacity : strokeOpacity * 0.7; // More transparent for intermediate nodes
        })
        .attr("stroke-linecap", strokeLinecap)
        .attr("stroke-linejoin", strokeLinejoin)
        .style("opacity", d => shouldShowLink(d.source, d.target, selectedSeverity) ? 1 : 0)
        .style("pointer-events", d => shouldShowLink(d.source, d.target, selectedSeverity) ? "auto" : "none");


    // Update the node styling to make intermediate nodes visually distinct
    const node = svg.append("g")
        .selectAll("g")
        .data(root.descendants())
        .join("g")
        .attr("transform", d => `translate(${d.y},${d.x})`)
        .attr("class", d => {
            let isExactMatch = true;
            if (d.depth !== 0 && !_.isEmpty(selectedSeverity)) {
                isExactMatch = false; // Start with false for non-root nodes when filters are active

                if (d.data.packageDetails?.cves) {
                    if (selectedSeverity.includes("kev")) {
                        const hasKevVuln = d.data.packageDetails.cves.some(cve => cve.kev === true);
                        if (hasKevVuln) {
                            isExactMatch = true;
                        }
                    }
                    if (selectedSeverity.includes("zeroday")) {
                        const hasZeroDayVuln = d.data.packageDetails.cves.some(cve => cve.zeroDay === true);
                        if (hasZeroDayVuln) {
                            isExactMatch = true;
                        }
                    }
                    if (!selectedSeverity.includes("kev") && !selectedSeverity.includes("zeroday")) {
                        isExactMatch = selectedSeverity.includes(d.data.severity);
                    }
                }
            }

            const isIntermediate = !isExactMatch && shouldShowNode(d, selectedSeverity);
            return `node-group ${d.data.severity === 'CRITICAL' && isExactMatch ? 'critical-node' : ''} ${isIntermediate ? 'intermediate-node' : ''}`;
        })
        .style("cursor", "pointer")
        .style("opacity", d => {
            if (!shouldShowNode(d, selectedSeverity)) return 0;
            if (d.depth === 0) return 1; // Root node always fully visible

            // Check if it's an exact match or intermediate node
            const isExactMatch = isNodeExactMatch(d, selectedSeverity);
            return isExactMatch ? 1 : 0.7; // Intermediate nodes slightly transparent
        })
        .style("pointer-events", d => shouldShowNode(d, selectedSeverity) ? "auto" : "none");


    // Also update the hover handling in createSVG function:
    const hoverGroup = node.append("g")
        .attr("class", "hover-group")
        .style("cursor", "pointer")
        .style("pointer-events", d => shouldShowNode(d, selectedSeverity) ? "auto" : "none")
        .on("mouseenter", (event, d) => {
            const isVisible = shouldShowNode(d, selectedSeverity);
            if (!isVisible || d.depth === 0 || _.isEmpty(d.data.packageDetails?.cves))  return;
            const nodeGroup = event.currentTarget.parentNode;
            const svgRect = svg.node().getBoundingClientRect();
            const nodeRect = nodeGroup.getBoundingClientRect();
            const containerRect = svg.node().parentNode.getBoundingClientRect();

            const rightX = nodeRect.right + 10;
            const centerY = nodeRect.top + (nodeRect.height / 2);

            onNodeHover({
                data: d.data,
                position: {
                    x: rightX - containerRect.left,
                    y: centerY - containerRect.top
                },
                nodeWidth: nodeRect.width,
            });
        })
        .on("mouseleave", (event, d) => {
            if (!shouldShowNode(d, selectedSeverity)) return;
            onNodeLeave(d);
        })
        .on("click", (event, d) => {
            if (d.depth !== 0) {
                onNodeClick(d.data)
            }
        });

    // Update the base circle style
    // hoverGroup.append("circle")
    //     .attr("class", "base")
    //     .attr("fill", d => {
    //         // // For intermediate nodes (parents), use a neutral color
    //         // if (!selectedSeverity.includes(d.data.severity) && d.depth !== 0) {
    //         //     return "#d4d4d4";  // Light gray for intermediate nodes
    //         // }
    //         let color = getStrokeColor(d.data.severity);
    //         return color || fill;
    //     })
    //     .attr("r", getNodeRadius)
    //     .style("stroke", d => {
    //         // // Add a subtle stroke to intermediate nodes
    //         // if (!selectedSeverity.includes(d.data.severity) && d.depth !== 0) {
    //         //     return "#868686";
    //         // }
    //         return "none";
    //     })
    //     .style("stroke-width", 1);

    const nodeGroup = hoverGroup.append("g");

    // nodeGroup.append("circle")
    //     .attr("class", "base")
    //     .attr("fill", d => {
    //         let color = getStrokeColor(d.data.severity);
    //         return color || fill;
    //     })
    //     .attr("r", getNodeRadius)
    //     .style("stroke", "none")
    //     .style("stroke-width", 1);

    nodeGroup.each(function (d) {
        const group = d3.select(this);
        const radius = getNodeRadius(d);
        const hasKev = d.data.packageDetails?.cves?.some(v => v.kev);
        const hasZeroDay = d.data.packageDetails?.cves?.some(v => v.zeroDay);
        const isTraceable = !_.isEmpty(d.data.packageDetails?.traceabilityDetails);
        const isExactMatch = d.depth !== 0 ? isNodeExactMatch(d, selectedSeverity) : true;
        const isVisibleAfterVulSearch = d.data.packageDetails?.cves?.some(v => v.showCve);

        if (!isExactMatch) {
            // For intermediate nodes, use neutral styling
            group.append("circle")
                .attr("class", "base")
                .attr("r", radius)
                .attr("fill", "#d4d4d4")  // Light gray for intermediate nodes
                .style("stroke", "#868686")
                .style("stroke-width", 1);
        }
        else if (hasKev && isVisibleAfterVulSearch) {
            // Hexagon for KEV vulnerabilities
            group.append("path")
                .attr("d", createHexagonPath(radius + 5))
                .attr("fill", d => {
                    let color = getStrokeColor(d.data.severity);
                    return color || fill;
                })
                .attr("class", "base");

            // Label group for KEV
            const labelGroup = group.append("g")
                .attr("transform", `translate(0, ${-radius})`);

            // Background rectangle for text
            labelGroup.append("rect")
                .attr("x", 3)  // Half of typical "KEV" text width
                .attr("y", -8)   // Text height adjustment
                .attr("width", 21)  // Typical "KEV" text width plus padding
                .attr("height", 10)  // Text height plus padding
                .attr("rx", 7)    // Rounded corners (half of height for pill shape)
                .attr("ry", 7)
                .attr("fill", "#ff0000")
            // .attr("stroke", "#ffffff")  // Add white border
            // .attr("stroke-width", "1")  // Border width
            // .style("stroke-opacity", "0.8"); 
            // .attr("fill", d => {
            //     let color = getStrokeColor(d.data.severity);
            //     return color || fill;
            // });

            // KEV text
            labelGroup.append("text")
                .attr("dx", 6)
                .attr("text-anchor", "start")
                // .attr("dx", "1")  // Vertical alignment within background
                .attr("font-size", "9px")
                .attr("font-weight", "bold")
                .attr("fill", "#000")
                .text("kev");

            if (hasZeroDay) {
                // Background rectangle for text
                labelGroup.append("rect")
                    .attr("x", 25)  // Half of typical "KEV" text width
                    .attr("y", -8)   // Text height adjustment
                    .attr("width", 45)  // Typical "KEV" text width plus padding
                    .attr("height", 10)  // Text height plus padding
                    .attr("rx", 7)    // Rounded corners (half of height for pill shape)
                    .attr("ry", 7)
                    .attr("fill", "#ff0000")

                // zeroday text
                labelGroup.append("text")
                    .attr("text-anchor", "start")
                    .attr("dx", "32")  // Vertical alignment within background
                    // .attr("dy", "17")
                    .attr("font-size", "8px")
                    .attr("font-weight", "bold")
                    .attr("fill", "#000")
                    .text("zeroday");
            }


        } else if (hasZeroDay && isVisibleAfterVulSearch) {
            // Triangle for Zero Day vulnerabilities
            group.append("path")
                .attr("d", createHexagonPath(radius + 5))
                .attr("fill", d => {
                    let color = getStrokeColor(d.data.severity);
                    return color || fill;
                })
                .attr("class", "base");

            // Label group for 0D
            const labelGroup = group.append("g")
                .attr("transform", `translate(0, ${-radius})`);

            // Background rectangle for text
            labelGroup.append("rect")
                .attr("x", 3)  // Half of typical "KEV" text width
                .attr("y", -8)   // Text height adjustment
                .attr("width", 40)  // Typical "KEV" text width plus padding
                .attr("height", 10)  // Text height plus padding
                .attr("rx", 7)    // Rounded corners (half of height for pill shape)
                .attr("ry", 7)
                .attr("fill", "#ff0000")
            // .attr("stroke", "#ffffff")  // Add white border
            // .attr("stroke-width", "1")  // Border width
            // .style("stroke-opacity", "0.8"); 
            // .attr("fill", d => {
            //     let color = getStrokeColor(d.data.severity);
            //     return color || fill;
            // });

            // KEV text
            labelGroup.append("text")
                .attr("text-anchor", "start")
                .attr("dx", "6")  // Vertical alignment within background
                // .attr("dy", "17")
                .attr("font-size", "9px")
                .attr("font-weight", "bold")
                .attr("fill", "#000")
                .text("zeroday");

            // Add "0D" text above the triangle
            // group.append("text")
            //     .attr("dy", -radius)  // Position above the node
            //     .attr("text-anchor", "start")
            //     .attr("font-size", "8px")
            //     .attr("font-weight", "bold")
            //     .attr("fill", "#ffffff")  // White text
            //     .attr("stroke", "#000000")  // Black outline
            //     .attr("stroke-width", "0.5px")
            //     .attr("paint-order", "stroke")
            //     .text("0D");
        } else {
            // Circle for other nodes
            const circle = group.append("circle")
                .attr("class", "base")
                .attr("r", radius)
                .attr("fill", d => {
                    if (!isExactMatch || (!isVisibleAfterVulSearch && d.depth !== 0)) return "#d4d4d4";
                    let color = getStrokeColor(d.data.severity);
                    return color || fill;
                });
            // Add border for traceable packages
            // if (isTraceable) {
            //     circle
            //         .style("stroke", "#ffffff")
            //         .style("stroke-width", 2)
            //     // .style("stroke-dasharray", "4,2");
            // }
        }
    });

    // Add pulse animation for critical nodes
    hoverGroup.filter(d => {
        const isVisibleAfterVulSearch = d.data.packageDetails?.cves?.some(v => v.showCve);
        const isExactMatch = isNodeExactMatch(d, selectedSeverity);
        return d.data.severity === 'CRITICAL' && isExactMatch && isVisibleAfterVulSearch;
    }).append('circle')
        .attr('class', 'pulse')
        .attr('r', getNodeRadius)
        .attr("stroke", d => {
            let color = getStrokeColor(d.data.severity);
            return color || fill;
        })
        .style('fill', 'none')
        .style('stroke-opacity', 0.5)
        .style('animation', 'pulse 1.5s infinite');

    // Update the count text style
    hoverGroup.append("text")
        .attr("class", "count")
        .attr("dy", "0.35em")
        .attr("text-anchor", "middle")
        .attr("paint-order", "stroke")
        .attr("fill", d => {
            const isExactMatch = isNodeExactMatch(d, selectedSeverity);
            return !isExactMatch && d.depth !== 0 ? "#666666" : "#000000";
        })
        .attr("font-size", d => {
            if (d.depth === 0) {
                const count = d.data.rootVulCount || 0
                return count >= 1000 ? 12 : 14;
            }
            let count = d.data.nodeCount || 0;
            return count >= 1000 ? "9px" : count >= 100 ? "10px" : "11px";
        })
        .attr("stroke", "#212529")
        // .attr("stroke-width", d => {
        //     // Reduce stroke width for intermediate nodes
        //     return !selectedSeverity.includes(d.data.severity) && d.depth !== 0 ? 0.5 : 1;
        // })
        // .attr("stroke-opacity", 0.7)
        .text(d => {
            // Always show count for root node
            if (d.depth === 0) {
                const count = d.data.rootVulCount || 0;
                return count > 999 ? `${Math.floor(count / 1000)}k` : (count > 0 ? count : '');
            }

            // Check if the node matches current filter exactly
            const isExactMatch = isNodeExactMatch(d, selectedSeverity);

            // Only show count for exact matches, empty string for intermediate nodes
            if (isExactMatch) {
                const count = d.data.nodeCount || 0;
                return count > 999 ? `${Math.floor(count / 1000)}k` : (count > 0 ? count : '');
            }

            return ''; // Hide count for intermediate nodes
        });

    // // Add base circle inside hover group
    // hoverGroup.append("circle")
    //     .attr("class", "base")


    // Add labels with hover behavior
    if (L) {
        const textGroup = hoverGroup.append("g")
            .attr("text-anchor", "start")
            .style("display", d => {
                if (!showIntermediateNodes) {
                    const counts = getSeverityCounts(d.data.packageDetails);
                    if (!counts && d.data.level !== 0) return "none";
                }
                return "block";
            });

        textGroup.each(function (d, i) {
            const group = d3.select(this);
            const name = L[i].includes("@") ? L[i].split("@").shift() : L[i];
            const hasKev = d.data.packageDetails?.cves?.some(v => v.kev);
            const hasZeroDay = d.data.packageDetails?.cves?.some(v => v.zeroDay);
            const isExactMatch = isNodeExactMatch(d, selectedSeverity);

            // Add to the createSVG function where the root node severity circles are created
            if (d.depth === 0) {
                // Root node content
                const contentGroup = group.append("g")
                    .attr("class", "root-content");

                const nodeRadius = getNodeRadius(d);
                // Add the root node text
                const text = contentGroup.append("text")
                    .attr("text-anchor", "middle")
                    .attr("dy", nodeRadius * 1.7)
                    .attr("paint-order", "stroke")
                    .attr('fill', '#a59b9b')
                    .attr("stroke", '#212529')
                    .attr("stroke-width", 5)
                    .attr("stroke-opacity", 0.7)
                    .text(name.split("/").pop());

                const circleSize = 20;
                const circleSpacing = 25;
                const severities = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'];
                const totalWidth = (severities.length * circleSize) + ((severities.length - 1) * 5);

                d.textWidth = text.node().getComputedTextLength();

                // Create severity circles below text
                const circleGroup = contentGroup.append("g")
                    .attr("transform", `translate(0, ${nodeRadius * 3})`);

                // Add a group for both icon and text
                const combinedGroup = contentGroup.append("g")
                    .style("cursor", "pointer")
                    // .attr("fill", '#a59b9b')
                    // .attr("stroke", '#a59b9b')
                    .on("click", (event) => {
                        console.log("click", onAutoFixClick);
                        event.stopPropagation();
                        onAutoFixClick();
                    });

                const grp = combinedGroup.append('rect').attr('fill', '#17bc14')
                    .attr("x", -30) // Position next to the root node
                    .attr("y", 33) // Center vertically
                    .attr("width", 75)
                    .attr("height", 20)
                    .attr("rx", 5)
                    .attr("ry", 5)

                // Add the icon using foreignObject
                const iconGroup = combinedGroup.append("foreignObject")
                    .attr("width", 24)
                    .attr("height", 24)
                    .attr("x", -30) // Position next to the root node
                    .attr("y", 31) // Center vertically
                    .style("overflow", "visible");

                // Create a container div for the FontAwesome icon
                const iconContainer = iconGroup.append("xhtml:div")
                    .style("width", "100%")
                    .style("height", "100%")
                    .style("display", "flex")
                    .style("align-items", "center")
                    .style("justify-content", "center")
                    .style("color", "#ffffff") // Primary blue color
                    .style("transition", "color 0.3s") // Smooth color transition
                    .on("mouseover", function () {
                        d3.select(this).style("color", "#ffffff"); // Lighter blue on hover
                        // Also change text color on hover
                        textElement.style("fill", "#ffffff");
                    })
                    .on("mouseout", function () {
                        d3.select(this).style("color", "#ffffff"); // Back to original color
                        // Reset text color
                        textElement.style("fill", "#ffffff");
                    });

                // Create the React element for FontAwesome icon
                const iconElement = document.createElement('div');
                ReactDOM.render(
                    <FontAwesomeIcon
                        icon={faWandMagicSparkles}
                        style={{ fontSize: '10px', color: "#ffffff" }}
                    />,
                    iconElement
                );

                // Append the icon element to the container
                iconContainer.node().appendChild(iconElement);

                // Add text below the icon
                const textElement = combinedGroup.append("text")
                    .attr("x", 15) // Center text below icon
                    .attr("y", 47) // Position below the icon
                    .attr("text-anchor", "middle") // Center the text horizontally
                    .style("font-size", "12px")
                    .style("fill", "#ffffff")
                    .style("transition", "fill 0.3s")
                    .text("Auto Fix");
            } else {
                // Create a container group for the text and potential rectangle
                const labelGroup = group.append("g")
                    .attr("transform", d => {
                        return hasKev || hasZeroDay ? `translate(-30, ${getNodeRadius(d) * 2.7})` : `translate(-30, ${getNodeRadius(d) * 2.2})`
                    });

                // Add background rectangle for traceable packages
                if (!_.isEmpty(d.data.packageDetails) && !_.isEmpty(d.data.packageDetails.traceabilityDetails)) {
                    // First, create temporary text to measure dimensions
                    const tempText = labelGroup.append("text")
                        .text(name)
                        .style("opacity", 0);

                    const textWidth = tempText.node().getComputedTextLength();
                    const textHeight = 16; // Approximate text height
                    tempText.remove();

                    const padding = 6;
                    const stepSize = 4;
                    const cornerRadius = 2;
                    // Create a stepped path for the border
                    const createSteppedPath = (width, height, stepSize, cornerRadius) => {
                        const steps = Math.floor(height / (2 * stepSize));
                        let path = [];

                        // Top left corner
                        path.push(`M${cornerRadius},0`);
                        path.push(`L${width - cornerRadius},0`);
                        path.push(`Q${width},0 ${width},${cornerRadius}`);

                        // Right edge with steps
                        for (let i = 0; i < steps; i++) {
                            const y = cornerRadius + (i * 2 * stepSize);
                            path.push(`V${y + stepSize}`);
                            path.push(`H${width + stepSize}`);
                            path.push(`V${y + 2 * stepSize}`);
                            path.push(`H${width}`);
                        }

                        // Bottom right corner
                        path.push(`V${height - cornerRadius}`);
                        path.push(`Q${width},${height} ${width - cornerRadius},${height}`);

                        // Bottom edge
                        path.push(`H${cornerRadius}`);

                        // Bottom left corner
                        path.push(`Q0,${height} 0,${height - cornerRadius}`);

                        // Left edge with steps
                        for (let i = steps - 1; i >= 0; i--) {
                            const y = height - cornerRadius - (i * 2 * stepSize);
                            path.push(`V${y}`);
                            path.push(`H${-stepSize}`);
                            path.push(`V${y - stepSize}`);
                            path.push(`H0`);
                        }

                        // Close path
                        path.push(`V${cornerRadius}`);
                        path.push(`Q0,0 ${cornerRadius},0`);
                        path.push('Z');

                        return path.join(' ');
                    };
                }

                const text = labelGroup.append("text")
                    .attr("paint-order", "stroke")
                    .attr('fill', "#a59b9b")
                    .attr("stroke", '#212529')
                    .attr("stroke-width", 5)
                    .attr("stroke-opacity", 0.7)
                    .text(() => {
                        // Always show text for root node
                        if (d.depth === 0) {
                            return name;
                        }

                        // For non-root nodes, only show text if it exactly matches the filter
                        const isExactMatch = isNodeExactMatch(d, selectedSeverity);
                        if (isExactMatch) {
                            return name;
                        }

                        // Hide text for intermediate nodes
                        return '';
                    });


                d.textWidth = text.node().getComputedTextLength();
            }
        });
    }

}

export default function TidyTreeChart({ data, options, showComponentDetails, handleAutoFix, selectedSeverity, showAllComponents }) {
    const [hoveredNode, setHoveredNode] = useState(null);
    // const [showFullPath, setShowFullPath] = useState(false);
    const [isHoveringPopover, setIsHoveringPopover] = useState(false);
    const hoverTimeoutRef = useRef(null);
    const containerRef = useRef(null);

    const ref = useD3((svg) => {
        createSVG(
            data,
            options,
            svg,
            handleNodeHover,
            handleNodeLeave,
            showAllComponents,
            selectedSeverity,
            onPackageClick,
            handleAutoFixClick
        );
    }, [data, showAllComponents, selectedSeverity]);

    const handleNodeHover = useCallback(({ data, position, counts, nodeWidth }) => {
        if (hoverTimeoutRef.current) {
            clearTimeout(hoverTimeoutRef.current);
        }
        setHoveredNode({
            data,
            position,
            nodeWidth,
            visible: true
        });
    }, []);

    const handleNodeLeave = useCallback((node) => {
        if (hoverTimeoutRef.current) {
            clearTimeout(hoverTimeoutRef.current);
        }

        hoverTimeoutRef.current = setTimeout(() => {
            // Only hide if not hovering over popover
            if (!isHoveringPopover) {
                setHoveredNode(prev => ({
                    ...prev,
                    visible: false
                }));
            }
        }, 100);
    }, [isHoveringPopover]);

    const handlePopoverMouseEnter = () => {
        setIsHoveringPopover(true);
        if (hoverTimeoutRef.current) {
            clearTimeout(hoverTimeoutRef.current);
        }
    };

    const handlePopoverMouseLeave = () => {
        setIsHoveringPopover(false);
        setHoveredNode(prev => ({
            ...prev,
            visible: false
        }));
    };

    const handleViewComponentDetails = () => {
        showComponentDetails(hoveredNode.data)
    }

    const onPackageClick = (packageDetails) => {
        showComponentDetails(packageDetails)
    }

    const handleAutoFixClick = () => {
        handleAutoFix()
    }

    return (
        <div ref={containerRef} style={{ position: 'relative' }}>
            {data ? (
                <>

                    <svg
                        ref={ref}
                        style={{
                            height: 500,
                            width: "100%",
                            marginRight: "0px",
                            marginLeft: "0px",
                        }}
                    />
                    {!_.isEmpty(hoveredNode) && (
                        <div
                            style={{
                                position: 'absolute',
                                left: `${hoveredNode.position?.x}px`,
                                top: `${hoveredNode.position?.y}px`,
                                transform: 'translateY(-50%)', // Center vertically only
                                visibility: hoveredNode.visible ? 'visible' : 'hidden',
                                zIndex: 1000,
                                pointerEvents: 'auto'
                            }}
                            onMouseEnter={handlePopoverMouseEnter}
                            onMouseLeave={handlePopoverMouseLeave}
                        >
                            <Popover
                                open={hoveredNode.visible}
                                title={<span>{hoveredNode?.data?.packageName}</span>}
                                content={!_.isEmpty(hoveredNode)
                                    ? <VulnerabilityContent 
                                    componentVulData={hoveredNode}
                                    handleViewComponentDetails={handleViewComponentDetails} 
                                    /> 
                                    : ""}
                                    overlayClassName='vul-popover'
                                trigger="hover"
                                placement="right" // Changed to right placement
                                destroyTooltipOnHide={true}
                                overlayInnerStyle={{
                                    maxWidth: '400px'
                                }}
                                align={{
                                    offset: [0, 0] // Reset offset since we're handling position manually
                                }}
                            >
                                <div style={{
                                    width: 1,
                                    height: 1,
                                    pointerEvents: 'none'
                                }} />
                            </Popover>
                        </div>
                    )}
                </>
            ) : ''}
        </div>
    );
}