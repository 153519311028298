import React from 'react';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import timeZoneList from '../apiSecurityDashboard/timeZone.json';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';
import { getGMTOffset } from '../../shared/helper/genHelper';

const Scheduler = ({ schedulerList, setSchedulerList }) => {

    const findZoneByGMT = (timeZoneList, targetGMT) => {
        return _.get(_.find(timeZoneList, { gmt: targetGMT }), 'zone', null);
    };

    const handleAddSchedulerClick = () => {
        const newScheduler = {
            id: Date.now(),
            time: dayjs('00:00:00', 'HH:mm:ss'),
            timezone: findZoneByGMT(timeZoneList, `(${getGMTOffset()})`) ? findZoneByGMT(timeZoneList, "(GMT+05:30)") : timeZoneList[0].zone
        };

        // Check for duplicate time and timezone combination
        // const isDuplicate = schedulerList.some(
        //     item =>
        //         item.time.format('HH:mm') === newScheduler.time.format('HH:mm') &&
        //         item.timezone === newScheduler.timezone
        // );

        // if (isDuplicate) {
        //     alert('Scheduler already exists with same config!');
        //     return;
        // }

        setSchedulerList([...schedulerList, newScheduler]);
    };

    const handleRemoveScheduler = (id) => {
        setSchedulerList(schedulerList.filter(item => item.id !== id));
    };

    const handleTimeChange = (time, id) => {
        const updatedList = schedulerList.map(item => {
            if (item.id === id) {
                // Check for duplicate before updating
                const wouldBeDuplicate = schedulerList.some(
                    existingItem =>
                        existingItem.id !== id &&
                        existingItem.time.format('HH:mm') === time.format('HH:mm') &&
                        existingItem.timezone === item.timezone
                );

                if (wouldBeDuplicate) {
                    alert('Scheduler already exists with same config!');
                    return item;
                }

                return { ...item, time };
            }
            return item;
        });

        setSchedulerList(updatedList);
    };

    const handleTimezoneChange = (timezone, id) => {
        const updatedList = schedulerList.map(item => {
            if (item.id === id) {
                // Check for duplicate before updating
                const wouldBeDuplicate = schedulerList.some(
                    existingItem =>
                        existingItem.id !== id &&
                        existingItem.time.format('HH:mm') === item.time.format('HH:mm') &&
                        existingItem.timezone === timezone
                );

                if (wouldBeDuplicate) {
                    alert('Scheduler already exists with same config!');
                    return item;
                }

                return { ...item, timezone };
            }
            return item;
        });

        setSchedulerList(updatedList);
    };

    return (
        <div className="">
            {!_.isEmpty(schedulerList) && schedulerList.map((scheduler) => (
                <div key={scheduler.id} className="mb-3">
                    <div className="row align-items-center">
                        <div className="col-sm-3 pe-0">
                            <TimePicker
                                format="h:mm A"
                                use12Hours
                                minuteStep={15}
                                className="form-control"
                                value={scheduler.time}
                                onChange={(time) => handleTimeChange(time, scheduler.id)}
                            />
                        </div>
                        <div className="col-sm-8 pe-0">
                            <select
                                className="form-select"
                                value={scheduler.timezone}
                                onChange={(e) => handleTimezoneChange(e.target.value, scheduler.id)}
                            >
                                {timeZoneList.map((el, index) => {
                                    return <option key={`zonelist-${index}`} value={`${el.zone}`}>{el.gmt} - {el.zone}</option>;
                                })}
                            </select>
                        </div>
                        <div className="col-sm-1 text-end">
                            <CiCircleMinus
                                className="text-danger"
                                style={{ fontSize: 22, cursor: 'pointer' }}
                                onClick={() => handleRemoveScheduler(scheduler.id)}
                            />
                        </div>
                    </div>
                </div>
            ))}

            <div className="mb-3">
                <button
                    type='button'
                    onClick={handleAddSchedulerClick}
                    className="p-2 text-center d-flex mb-0 align-items-center justify-content-center w-100"
                    style={{ border: '1px dashed rgb(73 80 87)', cursor: 'pointer', borderRadius: 8 }}
                >
                    <CiCirclePlus style={{ fontSize: '22px' }} className='me-2' /> Add scheduler
                </button>
            </div>
        </div>
    );
};

export default Scheduler;