import { faDocker, faGithub } from "@fortawesome/free-brands-svg-icons";
import {
  faXmark,
  faRegistered,
  faFileCode,
  faFileZipper,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Input, message, Modal, Tooltip, Upload } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPendingAssetScans,
  setIScanModalState,
  setAssetsThreatScanRecords,
  setOverallRiskState,
} from "../../shared/redux/reducers/threatVisionReducer";
import _ from "lodash";
import axios from "axios";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { setAllUserRepoList } from "../../shared/redux/reducers/threatVisionReducer";
import SelectRepository from "./selectRepository";

function ScanModal() {
  const [activeTab, setActiveTab] = useState(false);
  const [loadingRepos, setLoadingRepos] = useState(false);
  const [selectedRepo, setSelectedRepo] = useState([]);
  const [unscannedRepoState, setUnscannedRepoState] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);

  const iScanModalState = useSelector(
    (state) => state.threatVisionReducer.iScanModalState
  );
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (_.isEmpty(reduxState.threatVisionReducer.allUserRepoList)) {
      getRepositories();
    }
  }, []);

  const handleCancelCLick = () => {
    dispatch(setIScanModalState(false));
  };

  const getRepositories = () => {
    setLoadingRepos(true);
    const reqBody = {
      urlType: reduxState.authReducer.loggedInFrom,
      owner: reduxState.authReducer.userDetails.email,
      page: reduxState.threatVisionReducer.userListPageNumber,
      recordsPerPage: GlobalConst.GIT_REPO_LIST_PAGE_COUNT,
    };
    axios
      .post(`${GlobalConst.API_URL}/auth/foss/get-repo-list`, reqBody)
      .then((op) => {
        setLoadingRepos(false);
        if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.data)) {
          dispatch(setAllUserRepoList(op.data.data));
        } else {
          dispatch(setAllUserRepoList([]));
        }
      })
      .catch((e) => {
        console.log("Exception: ", e.response);
        setLoadingRepos(false);
        dispatch(setAllUserRepoList([]));
      });
  };

  const handleInputChange = (e) => {
    setSearchVal(e.target.value);
    setUploadedFile(null);
  };

  const scanApplicationFromText = (event) => {
    event.preventDefault();
    let isDemoUser = localStorage.getItem('isDemoUser');
    if (isDemoUser && isDemoUser === 'true') {
      alert("Scan has been disabled for this account");
    }
    else {

      dispatch(setIScanModalState(false));

      if (!_.isEmpty(selectedRepo)) {
        let requestList = [];
        let presentRepos = [];
        selectedRepo.forEach((el) => {
          triggerScan(el.url)
        });
        return;
      }

      if (!_.isEmpty(searchVal)) {
        let name = "";
        if (isValidHttpUrl(searchVal)) {
          var pathname = new URL(searchVal).pathname;
          pathname = pathname.substring(1);
          name = pathname;
        } else {
          name = searchVal;
        }
        // let requestBody = {
        //   repoType: "github",
        //   repoURL: searchVal,
        // };
        let requestBody = searchVal;
        triggerScan(requestBody)
        return;
      }

      if (!_.isEmpty(uploadedFile)) {
        let data = new FormData();
        data.append("file", uploadedFile.file.originFileObj);

        let config = {
          method: "post",
          url: `${GlobalConst.API_URL}/auth/sca-threat-api/sbom/predict`,
          headers: { "content-type": "multipart/form-data" },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            if (
              !_.isEmpty(response) &&
              !_.isEmpty(response.data)
            )
              getDashboardSummary()
          })
          .catch((e) => {
            console.log("Exception", e);
            // setIsScanningFromDrag(false);
            message.error(`${uploadedFile.file.name} is not a valid file.`);
          });
      }
    }
  };

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const getDashboardSummary = () => {
    axios
      .get(`${GlobalConst.API_URL}/auth/sca-threat-api/dashboard/summary`)
      .then((op) => {
        if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.documents) && !_.isEmpty(op.data.overallRiskCategory)) {
          dispatch(setOverallRiskState(op.data.overallRiskCategory));
          dispatch(setAssetsThreatScanRecords(op.data.documents));
          const scanningOrInitiated = _.chain(op.data.documents)
            .filter(
              (record) =>
                record.status.toLowerCase() === "scanning" ||
                record.status.toLowerCase() === "initiated"
            )
            .map("reportId")
            .value();
          if (_.isEmpty(scanningOrInitiated)) {
            dispatch(setPendingAssetScans([]))
          } else {
            dispatch(setPendingAssetScans(scanningOrInitiated))
          }
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const triggerScan = (url) => {
    let requestBody = {
      repoType: "github",
      repoURL: url,
    };

    axios
      .post(
        `${GlobalConst.API_URL}/auth/sca-threat-api/repo/predict`,
        requestBody
      )
      .then((response) => {
        if (
          !_.isEmpty(response) &&
          !_.isEmpty(response.data)
        )
          getDashboardSummary()
      })
      .catch((e) => {
        console.log("Exception", e);
      });
  }

  const { Dragger } = Upload;
  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    className: "custom-dragger-upload",
    customRequest: (p1, p2, p3) => {
      // console.log("I am fired here", p1, p2, p3)
    },
    onChange(info) {
      setSearchVal("");
      setUploadedFile(info);
      message.info(
        `${info.file.name} file uploaded successfully, scanning in progress.`
      );
      let data = new FormData();
      // console.log("I am the file obj", info.file.originFileObj);
      data.append("file", info.file.originFileObj);

      let config = {
        method: "post",
        // maxBodyLength: Infinity,
        url: `${GlobalConst.API_URL}/auth/sca-threat-api/sbom/predict`,
        headers: { "content-type": "multipart/form-data" },
        data: data,
      };
      dispatch(setIScanModalState(false));
      axios
        .request(config)
        .then((response) => {
          if (
            !_.isEmpty(response) &&
            !_.isEmpty(response.data))
            getDashboardSummary()
        })
        .catch((e) => {
          console.log("Exception", e);
          // setIsScanningFromDrag(false);
          message.error(`${info.file.name} is not a valid file.`);
        });
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
  };
  return (
    <Modal
      title=""
      open={iScanModalState}
      footer={null}
      closeIcon={<FontAwesomeIcon className="fs-5 text-white" icon={faXmark} />}
      width={850}
      className="scan-modal"
      onCancel={handleCancelCLick}
    >
      <section className="m-1">
        <div className="">
          <div className="text-white">
            <div className="mb-3">
              <h5 className="mb-0 text-white" style={{ fontWeight: 600 }}>
                AI Scan{" "}
                <small
                  style={{ fontSize: "65%" }}
                  className="mb-0 ms-2 text-muted"
                >
                  AI Powered Scans{" "}
                  <FontAwesomeIcon
                    icon={faRegistered}
                    style={{ fontSize: 10 }}
                  />
                </small>
              </h5>
              <div className="d-flex align-items-center justify-content-start"></div>
            </div>
            <div className="my-3 mb-1">
              <div className="me-3 mb-2">Select asset to scan</div>
              <div className="d-flex align-items-center justify-content-start">
                <Tooltip title="Scan Github Repo">
                  <div
                    style={{ cursor: "pointer", marginRight: 12, opacity: 0.7 }}
                    className={`p-2 rounded ${activeTab === "1" ? "active-other-link" : "border"
                      } other-links`}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("1");
                    }}
                  >
                    <p className="mb-0">
                      <FontAwesomeIcon
                        icon={faGithub}
                        className="icon"
                        style={{ fontSize: 20 }}
                      />
                    </p>
                    <p className="mb-0">Github</p>
                  </div>
                </Tooltip>
                <Tooltip title="Scan Docker Image/Container">
                  <div
                    className={`pe-none ${activeTab === "2" ? "active-other-link" : "border"
                      } p-2 rounded other-links`}
                    style={{ cursor: "pointer", marginRight: 12, opacity: 0.7 }}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("2");
                    }}
                  >
                    <p className="mb-0">
                      <FontAwesomeIcon
                        icon={faDocker}
                        style={{ fontSize: 20 }}
                      />
                    </p>
                    <p className="mb-0">Docker</p>
                  </div>
                </Tooltip>
                <Tooltip title="Scan by uploading file">
                  <div
                    className={`${activeTab === "3" ? "active-other-link" : "border"
                      } p-2 rounded other-links`}
                    style={{ cursor: "pointer", marginRight: 12, opacity: 0.7 }}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("3");
                    }}
                  >
                    <p className="mb-0">
                      <FontAwesomeIcon
                        icon={faFileCode}
                        style={{ fontSize: 20 }}
                      />
                    </p>
                    <p className="mb-0">SBOM</p>
                  </div>
                </Tooltip>
                <Tooltip title="Scan a binary package">
                  <div
                    className={`pe-none ${activeTab === "4" ? "active-other-link" : "border"
                      } p-2 rounded other-links`}
                    style={{ cursor: "pointer", marginRight: 12, opacity: 0.7 }}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("4");
                    }}
                  >
                    <p className="mb-0">
                      <FontAwesomeIcon
                        icon={faFileZipper}
                        style={{ fontSize: 20 }}
                      />
                    </p>
                    <p className="mb-0">Binary</p>
                  </div>
                </Tooltip>
                <Tooltip title="Scan packages">
                  <div
                    className={`pe-none ${activeTab === "4" ? "active-other-link" : "border"
                      } p-2 rounded other-links`}
                    style={{ cursor: "pointer", marginRight: 12, opacity: 0.7 }}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("5");
                    }}
                  >
                    <p className="mb-0">
                      <FontAwesomeIcon
                        icon={faFileZipper}
                        style={{ fontSize: 20 }}
                      />
                    </p>
                    <p className="mb-0">Package</p>
                  </div>
                </Tooltip>
              </div>
            </div>
            <div>
              {activeTab === "1" && (
                <div className="mt-3">
                  <SelectRepository
                    loadingRepos={loadingRepos}
                    selectedRepo={selectedRepo}
                    setSelectedRepo={setSelectedRepo}
                  />
                </div>
              )}
              {activeTab === "2" && (
                <div>
                  <h2 style={{ color: "#adb5bd" }}>Docker Tab</h2>
                </div>
              )}
              {activeTab === "3" && (
                <div>
                  <div className="my-3">
                    <Dragger {...props} className="p-0 uploader-custom">
                      <p
                        className="ant-upload-text mb-0  "
                        style={{ color: "#adb5bd", fontSize: 14 }}
                      >
                        Upload a SBOM file
                      </p>
                    </Dragger>
                  </div>
                </div>
              )}
              {activeTab === "4" && (
                <div>
                  <h2 style={{ color: "#adb5bd" }}>Binary Tab</h2>
                </div>
              )}
              {activeTab === "5" && (
                <div>
                  <h2 style={{ color: "#adb5bd" }}>Package Tab</h2>
                </div>
              )}
              {activeTab !== "3" && <Divider className="divider-custom my-1" orientation="center">
                <small>or</small>
              </Divider>}
              <div className="sotcox-osvs-form sotcox-input-field">
                <form action="#" onSubmit={scanApplicationFromText}>
                  {activeTab !== "3" && <div className="">
                    <div>
                      <Input
                        placeholder="enter an artifact URL to scan for e.g. https://github.com/owner/repo"
                        allowClear
                        size="large"
                        className="iscan-search"
                        value={searchVal}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>}
                  <div className="text-center ">
                    <button className={`sotcox-btn mt-3`} type="submit">
                      AI Scan{" "}
                      {!_.isEmpty(selectedRepo) &&
                        `${selectedRepo.length} repo${selectedRepo.length > 1 ? "s" : ""
                        }`}
                      <span></span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
}

export default ScanModal;
