import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox } from 'antd';
import _ from 'lodash';

export default function VulnerabilityTab({ selectedVulnerability, showTitle }) {
    return (
        <div className='my-1'>
            {/* <h4 className='mb-3'>{selectedVulnerability.title}</h4> */}
            {showTitle && <div className='d-flex'><h6 className='mb-3'><strong>{selectedVulnerability.title}</strong></h6>
            </div>}
            <p className='mb-1'><strong>Line Number: </strong>{selectedVulnerability.line_number}</p>
            <p className='mb-1'><strong>Severity: </strong>{selectedVulnerability.severity}</p>
            {/* <p className='mb-1'><strong>File Name: </strong>{selectedVulnerability.filename}</p> */}
            <p className='mb-1'><strong>CWE: </strong>{selectedVulnerability.cwe_ids.join(',')}</p>
            <p className='mb-1'><strong>Documentation URL: </strong><a href={selectedVulnerability.documentation_url} target='_blank'>{selectedVulnerability.documentation_url}</a></p>
            {(!_.isEmpty(selectedVulnerability.category_groups)) && <p className='mb-1'><strong>Category Group: </strong>{selectedVulnerability.category_groups.join(',')}</p>}
            <p><strong>Description: </strong>{selectedVulnerability.description}</p>
        </div>
    );
}