import _ from 'lodash';
import moment from "moment";
import { Tooltip, Tag, Table } from "antd";
import { getFontColor, getStrokeColor } from "../../shared/helper/genHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAssetName } from "../../shared/helper/genHelper";
import { useSelector } from "react-redux";
import Loader from "../welcomeScreen/loader";
import { setSastScanTriggeredState, addUpdateSastDashboardTableData } from "../../shared/redux/reducers/sastReducer";
import { useDispatch } from "react-redux";
import axios from "axios";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { getAssetIcon } from "../../shared/helper/getAssetImagesHelper";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { RiQrScan2Line } from "react-icons/ri";
import { AiOutlineDashboard } from "react-icons/ai";

export default function SastStatisticsTable({ }) {

  const dispatch = useDispatch();
  const sastReducerState = useSelector(state => state.sastReducer);

  const handleReScanClick = (e, toScanRow) => {
    e.preventDefault();
    triggerRescan(toScanRow);
  }


  const triggerRescan = (toScanRow) => {

    let isDemoUser = localStorage.getItem('isDemoUser');
    if (isDemoUser && isDemoUser === 'true') {
      alert("Scan has been disabled for this account");
    }
    else {
      if (!_.isEmpty(toScanRow)) {
        dispatch(setSastScanTriggeredState(true));
        const postBody = { "urlType": toScanRow.assetType, "scanRequestList": [{ "urlType": toScanRow.assetType, "location": toScanRow.assetUrl, "branchName": toScanRow.branch }] };
        axios
          .post(`${GlobalConst.API_URL}/auth/foss/sast/ascan`, postBody)
          .then((op) => {
            if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
              dispatch(addUpdateSastDashboardTableData(op.data));
            }
          })
          .catch((e) => { });
      }
      else {
        // show a alert message to tell that invalid row selected to scan
      }
    }
  }


  const isScanning = (recordId) => {
    const isPresent = sastReducerState.sastTriggeredScans.find(opRecord => opRecord === recordId);
    if (isPresent) {
      return true;
    }
    else {
      return false;
    }
  }

  const openChildPage = (e, reportId) => {
    e.preventDefault();
    window.open(`/sast-advance-dashboard/${reportId}`, '_blank');
  }

  const columns = [
    {
      title: 'Sno',
      dataIndex: 'sno',
      key: 'sno',
      width: 70,
    },
    {
      title: 'Asset Name',
      dataIndex: 'assetName',
      key: 'assetName',
      width: 290,
      render: (text, record) => (
        <>
          <Tooltip title={<span className="d-flex align-items-start jsutify-content-center flex-column"><strong>GitHub repository</strong><span>{record.assetUrl}</span></span>}>
            <a href={record.assetUrl} target="_blank" rel="noreferrer" className="text-secondary">
              {getAssetIcon(record.assetType)}
            </a>
          </Tooltip>
          <a onClick={(e) => openChildPage(e, record.reportId)} href="#" className="me-3 details-hover-animate">
            {getAssetName(record.assetUrl)}
            {/* <small><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></small> */}
          </a>
          {record.scanStatus.toLowerCase() === 'failed' &&
            <Tooltip title={record.errorMessage}>
              <FontAwesomeIcon className="text-danger" icon={faTimesCircle} />
            </Tooltip>
          }
        </>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'center',
      render: (text, record) => (
        (record.scanStatus.toLowerCase() === 'scanning' || record.scanStatus.toLowerCase() === 'initiated')
          ? <div className="d-flex align-items-center justify-content-center"><Loader /> </div>
          : text
      ),
    },
    {
      title: 'Critical',
      dataIndex: 'critical',
      key: 'critical',
      align: 'center',
      render: (text, record) => (
        (record.scanStatus.toLowerCase() === 'scanning' || record.scanStatus.toLowerCase() === 'initiated')
          ? <div className="d-flex align-items-center justify-content-center"><Loader /> </div>
          : <Tag
            style={{
              color: getFontColor("CRITICAL"),
              borderRadius: 50,
              width: 40,
              fontSize: 14,
            }}
            color={getStrokeColor("CRITICAL")}
            className={"mx-auto text-center"}
          >
            {text}
          </Tag>
      ),
    },
    {
      title: 'High',
      dataIndex: 'high',
      key: 'high',
      align: 'center',
      render: (text, record) => (
        (record.scanStatus.toLowerCase() === 'scanning' || record.scanStatus.toLowerCase() === 'initiated')
          ? <div className="d-flex align-items-center justify-content-center"><Loader /> </div>
          : <Tag
            style={{
              color: getFontColor("HIGH"),
              borderRadius: 50,
              width: 40,
              fontSize: 14,
            }}
            color={getStrokeColor("HIGH")}
            className={"mx-auto text-center"}
          >
            {text}
          </Tag>
      ),
    },
    {
      title: 'Medium',
      dataIndex: 'medium',
      key: 'medium',
      align: 'center',
      render: (text, record) => (
        (record.scanStatus.toLowerCase() === 'scanning' || record.scanStatus.toLowerCase() === 'initiated')
          ? <Loader />
          : <Tag
            style={{
              color: getFontColor("MEDIUM"),
              borderRadius: 50,
              width: 40,
              fontSize: 14,
            }}
            color={getStrokeColor("MEDIUM")}
            className={"mx-auto text-center"}
          >
            {text}
          </Tag>
      ),
    },
    {
      title: 'Low',
      dataIndex: 'low',
      key: 'low',
      align: 'center',
      render: (text, record) => (
        (record.scanStatus.toLowerCase() === 'scanning' || record.scanStatus.toLowerCase() === 'initiated')
          ? <div className="d-flex align-items-center justify-content-center"><Loader /> </div>
          : <Tag
            style={{
              color: getFontColor("LOW"),
              borderRadius: 50,
              width: 40,
              fontSize: 14,
            }}
            color={getStrokeColor("LOW")}
            className={"mx-auto text-center"}
          >
            {text}
          </Tag>
      ),
    },
    {
      title: 'Last Scan',
      dataIndex: 'scanDateTime',
      key: 'scanDateTime',
      render: (text, record) => (
        (record.scanStatus.toLowerCase() === 'scanning' || record.scanStatus.toLowerCase() === 'initiated')
          ? <div className="d-flex align-items-center justify-content-center"><Loader /> </div>
          : moment.utc(text).local().fromNow()
      ),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 120,
      render: (text, record) => (
        <>
          <a
            onClick={(e) => handleReScanClick(e, record)}
            className={`me-2 details-hover-animate ${record.scanStatus.toLowerCase() === 'initiated' ||
              record.scanStatus.toLowerCase() === 'scanning' || record.assetType === 'file-cli' || record.assetType === 'file'
              ? "disabled"
              : ""
              }`}
          >
            <Tooltip title={"Rescan"}>
              <RiQrScan2Line style={{ fontSize: 20 }} />
            </Tooltip>
          </a>
          <a onClick={(e) => openChildPage(e, record.reportId)} href={`#`} className={`me-2 details-hover-animate ${record.scanStatus.toLowerCase() === 'initiated' ||
            record.scanStatus.toLowerCase() === 'scanning'
            ? "disabled"
            : ""
            }`}>
            <Tooltip title="Adv Dashboard">
              <AiOutlineDashboard style={{ fontSize: 20 }} />
            </Tooltip>
          </a>
        </>
      ),
    },
  ];

  const data = sastReducerState.sastDashTableData.map((row, index) => ({
    key: `sast-table-${index}`,
    sno: index + 1,
    ...row,
  }));

  return (
    <Table
      size='small'
      columns={columns}
      dataSource={data}
      pagination={false}
      className="custom-table mb-0 fixedScrollCustomTable"
      locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
      scroll={{
        x: 1300
      }}
    />
  );
}