import axios from "axios";
import { useEffect, useState } from "react";
import _ from 'lodash';
import { Tag, Steps, Tooltip } from "antd";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faCheckCircle, faCaretRight, faArrowUp, faArrowDown, faCircleExclamation, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { formatScanKey, getFontColor, getStrokeColor } from '../../../shared/helper/genHelper';
import { TiWarning } from "react-icons/ti";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import Loader from "../../../shared/sharedComponents/loader";
import { GlobalConst } from "../../../shared/appConfig/globalConst";
import { useParams } from "react-router-dom";

export default function HistoryTab() {

  const [loading, setLoading] = useState(false);
  const [scanHistoryItems, setScanHistoryItems] = useState([]);
  const { id, integrationId } = useParams()

  useEffect(() => {
    getHistoryData();
  }, []);

  const getHistoryData = () => {
    const postBody = {
      "integrationId": integrationId
    };
    setLoading(true);
    axios
      .post(`${GlobalConst.API_URL}/auth/cloud-api/k8s/asset/history`, postBody)
      .then(op => {
        setLoading(false);
        updateScanHistoryItems(op.data);
      })
      .catch(e => { setLoading(false); })
  }

  const handleShowReportClick = (reportId) => {
    window.location.href = `/k8s-advance-dashboard/${reportId}/${integrationId}`;
  }

  const updateScanHistoryItems = (historyData) => {
    if (!_.isEmpty(historyData)) {
      const dataArray = _.cloneDeep(historyData);
      const lastToFirstScansArray = _.orderBy(dataArray, ['updatedAt'], ['asc']);

      let lastSuccessTotalCVE = 0;
      const newDataArray = lastToFirstScansArray.map((el, key) => {
        const total = el.totalCve;
        let delta = null;
        let deltaDiff = 0;
        if (_.isNumber(total)) {
          if (key > 0) {
            deltaDiff = total - lastSuccessTotalCVE;
            delta = deltaDiff > 0 ? 'up' : deltaDiff < 0 ? 'down' : null;
          }
          if (_.has(el, "overallScanStatus") && (el.overallScanStatus.toLowerCase() != 'failed')) {
            lastSuccessTotalCVE = total;
          }
        }

        return {
          ...el,
          delta,
          deltaDiff: Math.abs(deltaDiff),
        };
      }).reverse();

      const items = newDataArray.map((value) => ({
        title: (
          <div style={{ fontSize: 14 }} className={`d-flex align-items-center justify-content-center flex-row px-3 py-1 card card-custom timelineCard ${(!_.isEmpty(id) && id === value.reportId) ? 'active' : ''} ${(!_.isEmpty(value, "overallScanStatus") && value.overallScanStatus.toLowerCase() === 'failed') ? 'disabled' : ''}`}>
            <div style={{ minWidth: 230 }} className="d-flex align-items-center justify-content-between">
              <div>
                {(!_.isEmpty(id) && id === value.reportId) && <FontAwesomeIcon className="me-2 text-primary-emphasis" icon={faCaretRight} />}
                {!_.isEmpty(value.updatedAt) && moment.utc(value.updatedAt).local().fromNow()}
                {(!_.isEmpty(value.branch)) && <span className="ms-2 text-secondary"><i>&lt;{value.branch}&gt;</i></span>}

                {(!_.isEmpty(value.overallScanStatus) && (value.overallScanStatus.toLowerCase() === 'failed') || value.overallScanStatus.toLowerCase() === 'unknown' || value.overallScanStatus.toLowerCase() === 'partial') &&
                  <span>
                    {getScanStatus(value.overallScanStatus, value.scanStatuses)}
                  </span>
                }
                {(!_.isEmpty(value.overallScanStatus) && value.overallScanStatus.toLowerCase() === 'completed') &&
                  <Tooltip title="Scan Success">
                    <FontAwesomeIcon className="ms-2 text-success" icon={faCheckCircle} style={{ fontSize: 12 }} />
                  </Tooltip>
                }
                {/* {_.has(value, "overallScanStatus") ? (value.overallScanStatus.toLowerCase() === 'failed' ? (
                  <Tooltip title={value.errorMessage}>
                    <FontAwesomeIcon className="ms-2 text-danger" icon={faCircleXmark} style={{ fontSize: 12 }} />
                  </Tooltip>
                ) : value.overallScanStatus.toLowerCase() === 'completed' ? (
                  <Tooltip title="Scan Success">
                    <FontAwesomeIcon className="ms-2 text-success" icon={faCheckCircle} style={{ fontSize: 12 }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Scan Partial">
                    <FontAwesomeIcon className="ms-2 text-warning" icon={faCircleExclamation} style={{ fontSize: 12 }} />
                  </Tooltip>
                )) : null} */}
              </div>
              <div>
                {value.delta === 'up' && <Tag><FontAwesomeIcon icon={faArrowUp} className="text-danger me-1" /> {value.deltaDiff}</Tag>}
                {value.delta === 'down' && <Tag><FontAwesomeIcon icon={faArrowDown} className="text-success me-1" /> {value.deltaDiff}</Tag>}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <div style={{ minWidth: 460 }}>
                <div className="d-flex gap-2" >
                  <Tag className="mx-0">{value.totalCve  ? value.totalCve : "-"} Total vulnerabilities</Tag>
                  <Tag className="mx-0" style={{ color: getFontColor('CRITICAL'), fontWeight: 700 }} color={getStrokeColor('CRITICAL')}>{value.critical ? value.critical : "-"} Critical</Tag>
                  <Tag className="mx-0" style={{ color: getFontColor('HIGH'), fontWeight: 700 }} color={getStrokeColor('HIGH')}>{value.high ? value.high : "-"} High</Tag>
                  <Tag className="mx-0" style={{ color: getFontColor('MEDIUM'), fontWeight: 700 }} color={getStrokeColor('MEDIUM')}>{value.medium ? value.medium : "-"} Medium</Tag>
                  <Tag className="mx-0" style={{ color: getFontColor('LOW'), fontWeight: 700 }} color={getStrokeColor('LOW')}>{value.low ? value.low : "-"} Low</Tag>
                  <Tag className="mx-0" style={{ color: getFontColor('NA'), fontWeight: 700 }} color={getStrokeColor('NA')}>{value.na ? value.na : "-"} NA</Tag>
                </div>
              </div>
              <div className="text-secondary-emphasis ms-2" style={{ fontSize: 12 }}><small><i><b>Run by</b> - {value.userId}</i></small></div>
              {_.has(value, "overallScanStatus") && (value.overallScanStatus.toLowerCase() === 'completed' || value.overallScanStatus.toLowerCase() === 'partial') && (
                <a className="ms-5 btn-link" onClick={(e) => { e.preventDefault(); handleShowReportClick(value.reportId); }} href="#">
                  View Report
                </a>
              )}
            </div>
          </div >
        ),
        description: <></>,
      }));

      setScanHistoryItems(items);
    }
  };

  const getScanStatus = (status, statuses) => {
    if (!_.isEmpty(status)) {
      if (status.toLowerCase() === 'failed') {
        return <Tooltip title={scanStatusList(statuses)}>
          <span className="ms-2 "><FontAwesomeIcon className="text-danger" icon={faTimesCircle} /></span>
        </Tooltip>;
      }
      else if (status.toLowerCase() === 'unknown') {
        return '';
      }
      else if (status.toLowerCase() === 'partial') {
        return <Tooltip title={scanStatusList(statuses)} trigger={'click'}>
          <span className="ms-2 "><TiWarning className="text-warning" /></span>
        </Tooltip>;
      }
    }
    else {
      return '';
    }
  }

  const scanStatusList = (scanStatuses) => {
    if (!scanStatuses || Object.keys(scanStatuses).length === 0) {
      return null;
    }

    return (
      <ul className="list-group list-group-flush">
        {Object.entries(scanStatuses).filter(([key]) => !"k8s_scan".includes(key)).map(([key, value]) => (
          <li key={key} className="list-group-item bg-transparent p-2">
            <div className='d-flex align-items-center justify-content-start'>
              {value.status.toLowerCase() === 'completed' ? <div className='me-1'><IoCheckmarkDoneSharp className='text-success' /></div> : <div className='me-1'><IoIosCloseCircleOutline className='text-danger' /></div>}
              <div><strong>{formatScanKey(key)}</strong></div>
            </div>
            {value.errorMessage && (
              <div>
                Error: {value.errorMessage}
              </div>
            )}
          </li>
        ))}
      </ul>
    );
  };

  if (loading) {
    return <Loader />
  }
  else {
    return (
      <section className="my-2">
        <h3 className="text-white">Scan History</h3>
        <div className="mt-3">
          <Steps
            progressDot
            current={0}
            direction="vertical"
            className="text-white scan-history-timeline"
            items={scanHistoryItems}
          />
        </div>
      </section>
    )
  }
}