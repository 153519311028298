import React, { useState, useEffect, useCallback } from "react";
import { Radio, Button } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import DashboardSeverityIndicator from "../../shared/sharedComponents/dashboardSeverityIndicator";
import TrendGraph from "./trendGraph";
import axios from 'axios';
import _ from 'lodash';
import { useParams } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import { RiQrScan2Line } from "react-icons/ri";
import Tabs from 'react-bootstrap/Tabs';
import { TbBinaryTree } from "react-icons/tb";
import Loader from '../../shared/sharedComponents/loader';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import WelcomeLoader from "../welcomeScreen/loader";
import AssetViewTab from "./Tabs/assetViewTab";
import SecurityPostureTab from "./Tabs/securityPostureTab";
import WorkloadProtectionTab from "./Tabs/workloadProtectionTab";
import HistoryTab from "./Tabs/historyTab";
import ResourceDetailsModal from "./resourceDetailsModal";
import CloudTreeModal from './cloudTreeModal';

import RulesTab from "./Tabs/rulesTab";
import { getCloudIcon, getCloudSecurityScanStatus } from "../../shared/helper/genHelper";
import PieChart from "../../shared/sharedComponents/echartsGraphs/pieChart";
import moment from "moment";

export default function CloudSecurityAdvanceDashboard() {

    const { id } = useParams();
    const [trendValue, setTrendValue] = useState('7');
    const [loadingRows, setLoadingRows] = useState(false);
    const [activeTabsKey, setActiveTabsKey] = useState('securityPosture');
    const [showCloudTreeModal, setShowCloudTreeModal] = useState(false);
    const [showResourceDetailsModal, setShowResourceDetailsModal] = useState(false);
    const [trendData, setTrendData] = useState({});
    const [loadingTrendData, setLoadingTrendData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [advDashboardData, setAdvDashboardData] = useState({});
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [issuesDistribution, setIssuesDistribution] = useState([]);
    const [scanStatus, setScanStatus] = useState({});

    useEffect(() => {
        setLoading(true);
        const postBody = {
            "reportId": id
        };
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/summaryreport/category`, postBody)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setAdvDashboardData(op.data);
                }
            })
            .catch(e => { })
            .finally(o => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        if (!_.isEmpty(trendValue) && !_.isEmpty(advDashboardData) && !_.isEmpty(advDashboardData.integrationId)) {
            if (!_.isEmpty(advDashboardData.resourceSummary)) {
                let filteredData = _.chain(advDashboardData.resourceSummary)
                    .map(item => {
                        const sum = _.sum([item.critical, item.high, item.medium, item.low]);
                        return {
                            name: item.displayName,
                            value: sum
                        };
                    })
                    .filter(item => item.value > 0)
                    .value();
                setIssuesDistribution(filteredData);
            } else {
                setIssuesDistribution([]);
            }
            let postData = { days: trendValue ? trendValue : "7", integrationId: advDashboardData.integrationId };
            setLoadingTrendData(true);
            setTrendData({});
            axios
                .post(`${GlobalConst.API_URL}/auth/cloud-api/dashboard/trend`, postData)
                .then(op => {
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        setTrendData(op.data);
                    }
                })
                .catch(e => {
                    console.log("Exception: ", e);
                })
                .finally(e => {
                    setLoadingTrendData(false);
                });
        }
    }, [trendValue, advDashboardData]);

    const handleRescanClick = (e) => {
        e.preventDefault();
        let isDemoUser = localStorage.getItem('isDemoUser');
        if (isDemoUser && isDemoUser === 'true') {
            alert("Scan has been disabled for this account");
        }
        else {
            if (!_.isEmpty(advDashboardData)) {
                setLoadingRows(true);
                const postBody = {
                    "integrationId": advDashboardData.integrationId,
                    "targetSystem": advDashboardData.targetSystem
                };

                axios
                    .post(`${GlobalConst.API_URL}/auth/cloud-api/ascan`, postBody)
                    .then((op) => {
                        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                            handleStatusCheck(op.data);
                        }
                    })
                    .catch((e) => {
                        setLoadingRows(false);
                    });
            }
        }
    }

    const handleStatusCheck = (scannedRecordData) => {
        if (!_.isEmpty(scannedRecordData) && !_.isEmpty(scannedRecordData[0])) {
            const postBody = { reportId: [scannedRecordData[0].reportId] };
            const poll = () => {
                axios
                    .post(`${GlobalConst.API_URL}/auth/cloud-api/report/status`, postBody)
                    .then(op => {
                        if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data[0])) {
                            if (op.data[0].status.toLowerCase() === 'scanning' || op.data[0].status.toLowerCase() === 'initiated') {
                                setScanStatus(op.data[0]);
                                setTimeout(poll, GlobalConst.POLLING_LIMIT);
                            } else {
                                setLoadingRows(false);
                                setScanStatus({});
                                // window.location.reload();
                                window.location.href = `/cloud-security-advance-dashboard/${op.data[0].reportId}`;
                            }
                        }
                    })
                    .catch(e => { });
            };
            poll();
        }
    };

    const handleTrendFilterChange = (e) => {
        setTrendValue(e.target.value);
    };

    const goBack = (e) => {
        if (window.opener && !window.opener.closed) {
            window.opener.focus();
            window.close();
        } else {
            window.location.href = '/cloud-security-dashboard';
        }
    }

    const getTotalCount = (advDashboardData) => {
        return advDashboardData.critical + advDashboardData.high + advDashboardData.medium + advDashboardData.low;
    }

    const handleShowCloudTreeClick = (e) => {
        e.preventDefault();
        setShowCloudTreeModal(true);
    }

    const handleExpand = useCallback((expanded, record) => {
        setExpandedRowKeys(keys => {
            if (expanded) {
                return [...keys, record.key];
            } else {
                return keys.filter(key => key !== record.key);
            }
        });
    }, []);

    const setShowResourceDetailsModalCallback = useCallback((value) => {
        setShowResourceDetailsModal(value);
    }, []);

    const memoizedSecurityPostureTab = React.useMemo(() => (
        <SecurityPostureTab
            data={advDashboardData}
            setShowResourceDetailsModal={setShowResourceDetailsModalCallback}
            expandedRowKeys={expandedRowKeys}
            onExpand={handleExpand}
        />
    ), [advDashboardData, expandedRowKeys, handleExpand, setShowResourceDetailsModalCallback]);


    return (
        <section className="mx-3 my-2">
            <section className="mb-1 d-flex align-items-center justify-content-start">
                <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-0 text-white d-flex align-items-center justify-content-start me-2">
                    <a className='me-2' onClick={goBack} href='#' style={{ fontSize: 22 }}><small ><FontAwesomeIcon icon={faArrowLeft} /></small></a>
                    <span className='ps-2' style={{ borderLeft: '1px solid rgba(108, 117, 125, 15%' }}>{advDashboardData && <img src={getCloudIcon('', advDashboardData.targetSystem)} className="me-2" style={{ width: 35 }} />} Cloud Security Scan Report</span>
                </h2>
                <Button
                    onClick={handleRescanClick}
                    className="custom-button btn-sm ms-3"
                    loading={loadingRows}
                >
                    {/* <FontAwesomeIcon icon={faArrowsRotate} className="me-2" /> */}
                    <RiQrScan2Line style={{ fontSize: 20 }} className="me-1" />
                    Re-Scan
                </Button>
                <Button
                    className="custom-button btn-sm ms-3"
                    onClick={handleShowCloudTreeClick}
                >
                    <TbBinaryTree className="me-1" />
                    Visualize Cloud
                </Button>

                {loadingRows && (
                    <span className="d-flex align-items-center justify-content-start ms-3">
                        <WelcomeLoader />{" "}
                        {(!_.isEmpty(scanStatus) && !_.isEmpty(scanStatus.scanStatuses)) && <div className='' style={{ color: '#00e0e9' }}>
                            <small className="ms-1" style={{ fontSize: 14, fontWeight: 200 }}>{getCloudSecurityScanStatus(scanStatus.scanStatuses)}</small>
                            <div className='loader-moving-line'></div>
                        </div>}
                    </span>
                )}
            </section>

            <div className="mb-3">
                <div>
                    {!_.isEmpty(advDashboardData) && (
                        <>
                            <span
                                className="d-flex align-items-center justify-content-start"
                            >
                                <span className="text-truncate me-1">{advDashboardData.projectId}</span>
                                <span>
                                    {!_.isEmpty(advDashboardData.updatedAt) && <small className="d-block text-secondary" style={{}}><i>(<strong>Last Scan:</strong> {moment.utc(advDashboardData.updatedAt).local().fromNow()})</i></small>}
                                </span>
                            </span>
                        </>
                    )}

                </div>
            </div>

            {!_.isEmpty(advDashboardData) && !loading ?
                <>
                    <section className="mb-2">
                        <div className="row">
                            <div className="col-lg-5 custom-border-right">
                                <div className="p-3 ps-0 d-flex align-items-center w-100 h-100">
                                    <div className="card-body">
                                        <div className="d-flex flex-row align-items-start justify-content-between">
                                            <div className="me-3">
                                                <p className="mb-0 text-secondary" style={{ fontSize: 12 }}>Total Vulnerabilities</p>
                                                <h1 className="head-tag" style={{ lineHeight: 1.1 }}>{getTotalCount(advDashboardData)}</h1>
                                            </div>
                                            <div className="d-flex flex-wrap flex-row align-items-start justify-content-center">
                                                <div className="me-3 mb-2">
                                                    <DashboardSeverityIndicator severity={'Critical'} loadingRows={loadingRows} dashboardData={advDashboardData.critical} />
                                                </div>
                                                <div className="me-3 mb-2">
                                                    <DashboardSeverityIndicator severity={'High'} loadingRows={loadingRows} dashboardData={advDashboardData.high} />
                                                </div>
                                                <div className="me-3 mb-2">
                                                    <DashboardSeverityIndicator severity={'Medium'} loadingRows={loadingRows} dashboardData={advDashboardData.medium} />
                                                </div>
                                                <div className="me-3 mb-2">
                                                    <DashboardSeverityIndicator severity={'Low'} loadingRows={loadingRows} dashboardData={advDashboardData.low} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">

                                <div className="row">
                                    <div className="col-lg-6 custom-border-right">
                                        <div className="p-2">
                                            <div className="card-body">
                                                <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                                                    <h5 className="fw-medium">Issues Distribution</h5>
                                                </div>
                                                <div style={{ height: 300 }}>
                                                    <PieChart data={issuesDistribution} legendHeading={'Issues Distribution'} hideLegend={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="p-2">
                                            <div className="card-body">
                                                <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                                                    <h5 className="fw-medium">Trend</h5>
                                                    <div>
                                                        <Radio.Group value={trendValue} size="small" buttonStyle="solid" onChange={(e) => handleTrendFilterChange(e)}>
                                                            <Radio.Button value="7" style={{ fontSize: 12 }}>1 Week</Radio.Button>
                                                            <Radio.Button value="14" style={{ fontSize: 12 }}>2 Weeks</Radio.Button>
                                                            <Radio.Button value="30" style={{ fontSize: 12 }}>1 Month</Radio.Button>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                                <div style={{ height: 300 }}>
                                                    <TrendGraph hasCloudData={(!_.isEmpty(advDashboardData) && !_.isEmpty(advDashboardData.integrationId)) ? true : false} loadingData={loadingTrendData} trendData={trendData} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <div className="p-2">
                                            <div className="card-body">
                                                <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                                                    <h5 className="fw-medium">Trend</h5>
                                                    <div>
                                                        <Radio.Group value={trendValue} size="small" buttonStyle="solid" onChange={(e) => handleTrendFilterChange(e)}>
                                                            <Radio.Button value="7" style={{ fontSize: 12 }}>1 Week</Radio.Button>
                                                            <Radio.Button value="14" style={{ fontSize: 12 }}>2 Weeks</Radio.Button>
                                                            <Radio.Button value="30" style={{ fontSize: 12 }}>1 Month</Radio.Button>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                                <div style={{ height: 300 }}>
                                                    <TrendGraph hasCloudData={(!_.isEmpty(advDashboardData) && !_.isEmpty(advDashboardData.integrationId)) ? true : false} loadingData={loadingTrendData} trendData={trendData} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </section>

                    <section className="mb-5">
                        <Tabs
                            className="mb-3 report-tabs"
                            activeKey={activeTabsKey}
                            onSelect={(k) => setActiveTabsKey(k)}
                            mountOnEnter={true}
                            unmountOnExit={true}
                        >
                            <Tab eventKey="securityPosture" title="Security Posture">
                                <section className="mb-5">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                {memoizedSecurityPostureTab}
                                                {/* <SecurityPostureTab data={advDashboardData} setShowResourceDetailsModal={setShowResourceDetailsModal} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Tab>
                            <Tab eventKey="assetView" title="Asset View">
                                <section className="mb-5">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <AssetViewTab id={id} setShowResourceDetailsModal={setShowResourceDetailsModal} />
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </Tab>
                            <Tab eventKey="rules" title="Rules">
                                <section className="mb-5">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <RulesTab id={id} setShowResourceDetailsModal={setShowResourceDetailsModal} dashboardData={advDashboardData} />
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </Tab>
                            <Tab eventKey="workloadProtection" title="Workload Protection">
                                <WorkloadProtectionTab />
                            </Tab>
                            {/* <Tab eventKey="assetTree" title="Asset Tree">
                                <AssetTreeTab />
                            </Tab> */}
                            <Tab eventKey="history" title="History">
                                <HistoryTab id={id} data={advDashboardData} />
                            </Tab>
                        </Tabs>
                    </section>
                </>
                : <></>}
            {showResourceDetailsModal && <ResourceDetailsModal showResourceDetailsModal={showResourceDetailsModal} setShowResourceDetailsModal={setShowResourceDetailsModal} setLoading={setLoading} targetSystem={advDashboardData.targetSystem} advDashboardData={advDashboardData} />}
            {showCloudTreeModal && <CloudTreeModal showCloudTreeModal={showCloudTreeModal} setShowCloudTreeModal={setShowCloudTreeModal} setLoading={setLoading} setShowResourceDetailsModal={setShowResourceDetailsModal} advDashboardData={advDashboardData} />}
            {loading && <Loader />}

        </section>
    );
}