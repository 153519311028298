import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from 'react-bootstrap/Modal';
import { faRegistered } from "@fortawesome/free-regular-svg-icons";
import { setIScanModalState } from "../../shared/redux/reducers/apiSecurityReducer";
import ScanPublicWebsite from "./scanPublicWebsite";

export default function IScanModal({ }) {

    const dispatch = useDispatch();
    const iscanReducer = useSelector(state => state.apiSecurityReducer);

    const closeIScanModal = () => {
        dispatch(setIScanModalState(false))
    }

    return (
        <Modal
            show={iscanReducer.iScanModalState}
            onHide={closeIScanModal}
            size="lg"
            className="scan-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5" className="mb-0" style={{ fontWeight: 600 }}>
                    iScan
                    <small style={{ fontSize: '65%' }} className="mb-0 ms-2 text-muted">AI Powered Scans for API Security <FontAwesomeIcon icon={faRegistered} style={{ fontSize: 10 }} /></small>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ScanPublicWebsite fromModal={true} />
            </Modal.Body>
        </Modal >
    );
}