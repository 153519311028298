import './style.css';
import FileExplorerSidebar from './fileExplorerSidebar';
import noFileSelected from '../../../assets/img/no-file-selected.png';

import _ from 'lodash';

// import ReactDiffViewer from 'react-diff-viewer';
// import Prism from 'prismjs';
import axios from 'axios';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBug, faCircle, faExclamationCircle, faLock } from '@fortawesome/free-solid-svg-icons';

// import 'prismjs/themes/prism.css';
import { useEffect, useState } from 'react';
import Loader from '../../../shared/sharedComponents/loader';
// import { Affix } from 'antd';



// import { useEffect, useMemo, useRef } from 'react';
// import { useCodeMirror } from '@uiw/react-codemirror';
// import DiffView from './differenceViewer';
import CodeMirrorComp from './codeMirror';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { shortenPath } from '../../../shared/helper/genHelper';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { GlobalConst } from '../../../shared/appConfig/globalConst';
// import { javascript } from '@codemirror/lang-javascript';

// const extensions = [javascript()];


export default function FileExplorer({ reportDetails, selectedData, userPrefrences }) {

    const [oldFile, setOldFile] = useState('');
    const [newFile, setNewFile] = useState('');
    const [selectedFileName, setSelectedFileName] = useState('');
    const [loadingFile, setLoadingFile] = useState(false);
    const [fileData, setFileData] = useState({});
    const [remediation, setRemediation] = useState({});
    const [selectedFileId, setSelectedFileId] = useState('');
    const MySwal = withReactContent(Swal)

    const [vulDetailsModalOpen, setVulDetailsModalOpen] = useState(false);
    const [remediationDetailsModalOpen, setRemediationDetailsModalOpen] = useState(false);
    // const [container, setContainer] = useState(null);

    // const editor = useRef();
    // const { setContainer } = useCodeMirror({
    //     container: editor.current,   
    //     extensions,
    //     value: oldFile,
    // });

    // useEffect(() => {
    //     if (editor.current) {
    //         setContainer(editor.current);
    //     }
    // }, [editor.current]);

    // useEffect(() => {
    //     console.log("selectedFileId", selectedFileId);
    // }, [selectedFileId])


    const handleRemediationDisabledState = () => {
        // console.log("User prefrences", userPrefrences)
        if (!_.isEmpty(userPrefrences) && userPrefrences['enable.ai.remediation'] === 'true') {
            return false;
        }
        else {
            return true;
        }



    }

    const handleLineClick = (lineClick) => {
        // console.log("Line Number Click", lineClick);
    }

    const handleFileClick = (fileId) => {
        //console.log("here in download handle file click : ", fileId);
        setSelectedFileId(fileId);
        setVulDetailsModalOpen(false);
        setRemediationDetailsModalOpen(false);
        const postObject = {
            "fileId": fileId,
            "reportId": reportDetails.reportId
        };

        setNewFile('');
        setRemediation({});
        getFileData(fileId)
    }

    const renderLeftContent = (line, lineNumber) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <pre style={{ display: 'inline' }}>{line}</pre>
        </div>
    );

    const renderRightContent = () => null;

    const handleRemediationBtnClick = () => {
        const postObject = {
            "fileId": selectedFileId,
            "reportId": reportDetails.reportId
        };
        setLoadingFile(true);

        const pollRemediation = () => {
            return new Promise((resolve, reject) => {
                const poll = () => {
                    axios.post(`${GlobalConst.API_URL}/auth/sast/asset/file/remediation`, postObject)
                        .then(response => {
                            const status = response.data.remediationStatus;
                            if (status === "COMPLETED" || status === "") {
                                resolve(response.data);
                            } else if (status === "INITIATED" || status === "SCANNING") {
                                setTimeout(poll, 5000); // Poll every 5 seconds
                            } else {
                                resolve(response.data);
                            }
                        })
                        .catch(error => reject(error));
                };
                poll();
            });
        };

        try {
            axios.post(`${GlobalConst.API_URL}/auth/sast/asset/file/remediation`, postObject)
                .then(initialResponse => {
                    if (!_.isEmpty(initialResponse) && !_.isEmpty(initialResponse.data) && !_.isEmpty(initialResponse.data.remediationStatus)) {
                        if (initialResponse.data.remediationStatus === 'COMPLETED') {
                            return initialResponse.data;
                        }
                        else if (initialResponse.data.remediationStatus === 'FAILED') {
                            throw new Error('Remediation failed');
                        }
                        else if (initialResponse.data.remediationStatus === '') {
                            return initialResponse.data;
                        }
                        else {
                            return pollRemediation();
                        }
                    }
                    else {
                        return pollRemediation();
                    }
                })
                .then(finalResponse => {
                    if (!_.isEmpty(finalResponse) && !_.isEmpty(finalResponse.fileContent)) {
                        const decodedString = atob(finalResponse.fileContent);
                        setNewFile(decodedString);
                        setRemediation(finalResponse);
                        setLoadingFile(false);
                    } else {
                        throw new Error('No file content in the response');
                    }
                })
                .catch(error => {
                    setLoadingFile(false);
                    if (error.message === 'Remediation failed') {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Error getting remediation',
                            text: 'There was an error while getting remediation please contact our support team at support@sec1.io'
                        });
                    } else if (error.message === 'No file content in the response') {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Error getting remediation',
                            text: 'There was an error while getting remediation please contact our support team at support@sec1.io'
                        });
                    }
                    else {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Error getting remediation',
                            text: 'There was an error while getting remediation please contact our support team at support@sec1.io'
                        });
                    }
                });
        }
        catch (e) {
            MySwal.fire({
                icon: 'error',
                title: 'Undefined Error',
                text: 'There was an undefined error while getting remediation please contact our support team at support@sec1.io'
            });
        }
    };

    const onUpdateFileVulnerability = (selectedVul) => {
        // Update file vulnerability
        const request = {
            "fileId": selectedFileId,
            "reportId": reportDetails.reportId,
            "fingerprints": [selectedVul.fingerprint]
        }
        setLoadingFile(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/sast/asset/file/false-positive`, request)
            .then(op => {
                // console.log(op)
                setLoadingFile(false);
                getFileData(selectedFileId)
            })
            .catch(e => {
                console.log(e)
                setLoadingFile(false);
            })
    }

    const getFileData = (fileId) => {
        setLoadingFile(true);
        const postObject = {
            "fileId": fileId,
            "reportId": reportDetails.reportId
        };
        axios
            .post(`${GlobalConst.API_URL}/auth/sast/asset/file/download`, postObject)
            .then(op => {
                const decodedString = atob(op.data.fileContent);
                // console.log("OOutput ::", op.data.filename);
                setFileData(op.data);
                setOldFile(decodedString);
                setSelectedFileName(op.data.filename);
                setLoadingFile(false);
            })
            .catch(e => {

                setLoadingFile(false);
            })
    }

    // console.log("you reached here now : ", !_.isEmpty(reportDetails), selectedData.fileId);
    // handleFileClick(selectedData.fileId);
    return (

        <>
            {/* <div className="border-end" id="sidebar-wrapper"> */}
            {/* <div className="sidebar-heading border-bottom px-1 py-2 d-flex align-items-center justify-content-start">
                    <span className='toggle-sidebar p-2 d-flex align-items-center justify-content-center me-2' style={{ borderRadius: 5 }}><img src={hideSidebar} style={{ width: 15 }} /></span>
                    Files</div> */}
            {/* {!_.isEmpty(reportDetails) && <FileExplorerSidebar reportDetails={reportDetails} handleFileClick={handleFileClick} selectedData={selectedData} />} */}
            {/* </div> */}
            <div className="app-container">
                {!_.isEmpty(reportDetails) && <FileExplorerSidebar reportDetails={reportDetails} handleFileClick={handleFileClick} selectedData={selectedData} />}

                <div className="app-frame" style={{ height: 'calc(100vh - 105px)' }}>
                    {/* <div className='h-100'> */}
                    <div className="container-fluid h-100 pe-0">
                        {!_.isEmpty(oldFile) ?
                            <>
                                <div className='d-flex align-items-end justify-content-between mb-1'>
                                    <div className='d-flex align-items-center justify-content-start'>
                                        <h4 className='me-3'>{shortenPath(selectedFileName)}</h4>
                                        {(selectedFileId && !_.isEmpty(userPrefrences)) && <>
                                            <button className='btn btn-primary btn-sm' onClick={handleRemediationBtnClick} disabled={handleRemediationDisabledState()}>
                                                <FontAwesomeIcon icon={faLock} className='me-2' />Get Remediation
                                            </button>
                                        </>}
                                    </div>
                                    {(selectedFileId && !_.isEmpty(userPrefrences) && !_.isEmpty(newFile)) &&
                                        <div className='d-flex align-items-end justify-content-end'><small className='ms-2 text-secondary d-flex align-items-center justify-content-center' style={{ fontSize: 10 }}><i>Powered by OpenAI </i></small></div>
                                    }
                                </div>

                                <div style={{ borderRadius: 8, overflow: 'hidden' }}>
                                    <CodeMirrorComp
                                        oldFile={oldFile}
                                        newFile={newFile}
                                        fileData={fileData}
                                        remediation={remediation}
                                        vulDetailsModalOpen={vulDetailsModalOpen}
                                        setVulDetailsModalOpen={setVulDetailsModalOpen}
                                        remediationDetailsModalOpen={remediationDetailsModalOpen}
                                        setRemediationDetailsModalOpen={setRemediationDetailsModalOpen}
                                        onUpdateFileVulnerability={onUpdateFileVulnerability}
                                        selectedData={selectedData} />
                                </div>
                            </>
                            :
                            <div className='h-100 w-100 d-flex align-items-center justify-content-center'>
                                <div className="text-center" role="alert">
                                    <img src={noFileSelected} className='mb-4' style={{ width: 100 }} />
                                    <h4>Select file to load</h4>
                                    <p>Please select a file from file explorer.</p>
                                </div>
                            </div>
                        }
                    </div>
                    {loadingFile && <Loader />}
                    {/* </div> */}
                </div>
            </div>

        </>
    );
}