import { Progress, Tooltip } from "antd";
import React from "react";


export default function ProgressGraph({ title, vulCount, totalOutOff, index, innerText }) {

    const halfCircleColor = ["#785add", "#2db46b", "#3f80e2"];


    return (
        <div className="p-3">
            <Tooltip title={title}>
                <div className="d-flex align-items-start justify-content-start flex-column">
                    <h6
                        style={{
                            fontWeight: 400,
                            fontSize: 12,
                            minHeight: 35
                        }}
                        className="text-white mb-2"
                    >
                        <i>{title}</i>
                    </h6>
                    <div className="d-flex align-items-center">
                        <Progress
                            type="dashboard"
                            percent={
                                (vulCount * 100) /
                                totalOutOff
                            }
                            strokeColor={
                                halfCircleColor[index]
                            }
                            trailColor="#2b313e"
                            strokeWidth={12}
                            size={125}
                            format={(percent) => (
                                <span className="text-white">
                                    {parseInt(vulCount)}
                                    <br />{" "}
                                    <span
                                        style={{
                                            fontSize: 12,
                                            color:
                                                "rgb(154, 154, 154)",
                                        }}
                                    >
                                        {innerText}
                                    </span>
                                </span>
                            )}
                        />
                    </div>
                </div>
            </Tooltip>
        </div>
    );
}