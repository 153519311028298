import { Input, Tree } from 'antd';

import React, { useEffect, useMemo, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import _ from 'lodash';
import { SHA256 } from 'crypto-js';


const DependencyTree = ({ treeDataSet }) => {

  const { Search } = Input;
  const { TreeNode } = Tree;

  const [initialTreeData, setInitialTreeData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [treeData, setTreeData] = useState([]); // Store the original tree data
  const [filteredTreeData, setFilteredTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]); // Store the keys of expanded nodes
  const [dataList, setDataList] = useState([]);
  const [expandParent, setExpandParent] = useState(false);

  // Sample tree data (replace this with your actual tree data)
  // const initialTreeData = [
  //   // Your initial tree data here
  // ];

  // useEffect(() => {
  //   console.log("Expanded Keys:", expandedKeys);
  // }, [expandedKeys])

  useEffect(() => {

    // console.log("Tree Dataset::", treeDataSet);

    if (!_.isEmpty(treeDataSet) && !_.isEmpty(treeDataSet.title)) {


      let newData = [];
      newData.push(addKeyToTreeNodes(treeDataSet, 'key', ''));



      // Set the original tree data when the component mounts
      setTreeData(newData);
      setFilteredTreeData(newData); // Also set filtered tree data to show the entire tree initially
      setExpandedKeys(newData.map((node) => node.key)); // Expand all the first nodes initially.


      // console.log("Initial tree data::", initialTreeData);
      const dataListNew = [];
      const generateList = (data) => {
        for (let i = 0; i < data.length; i++) {
          const node = data[i];
          const { key } = node;
          dataListNew.push({
            ...node
            // key,
            // title: key,
          });
          if (node.children) {
            generateList(node.children);
          }
        }
      }
      generateList(newData);
      setDataList([...dataListNew]);
    }
  }, []);

  const generateHash = (inputString) => {
    return SHA256(inputString).toString();
  };



  const addKeyToTreeNodes = (tree, keyName, defaultValue) => {
    if (typeof tree !== 'object' || tree === null) {
      return; // Invalid tree input
    }

    // Recursively traverse the tree
    function traverse(node) {
      if (typeof node === 'object' && node !== null) {
        // Add the new key to the current node
        // console.log("I am node:", node);
        if (!_.isEmpty(node)) {
          // console.log(generateHash(`${node.level}-${node.title}`));
          node[keyName] = generateHash(`${node.level}-${node.title}`);
        }


        // Traverse child nodes
        for (var key in node) {
          if (node.hasOwnProperty(key)) {
            traverse(node[key]);
          }
        }
      }
    }

    traverse(tree); // Start traversing from the root

    return tree; // Return the modified tree
  }





  const filterTree = (data, query) => {
    if (!query) {
      return data;
    }

    const filteredNodes = data.filter((node) => {
      const titleMatches = node.title.toLowerCase().includes(query.toLowerCase());
      const hasMatchingChildren = node.children
        ? filterTree(node.children, query).length > 0
        : false;

      return titleMatches || hasMatchingChildren;
    });

    return filteredNodes.map((node) => {
      if (node.children) {
        return {
          ...node,
          children: filterTree(node.children, query),
        };
      }
      return node;
    });
  };

  const getParentKey = (key, tree) => {
    // console.log("key:", key)
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        // console.log("got some children", node)
        if (node.children.some((item) => item.key === key)) {
          // console.log("Inside if block")
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          // console.log("Returning from here");
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    // console.log("ParentKey: ", parentKey)
    return parentKey;
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filteredData = filterTree(treeData, query);
    setFilteredTreeData(filteredData);

    if (!_.isEmpty(query)) {


      // Find the keys of all matching nodes and their parent nodes to expand them
      // const expandedParentKeys = findExpandedParentKeys(filteredData, treeData);
      // setExpandedKeys(expandedParentKeys);

      const newExpandedKeys = dataList
        .map((item) => {
          const titleLowerCase = item.title.toLowerCase();
          const queryLowerCase = query.toLowerCase();
          if (titleLowerCase.includes(queryLowerCase)) {
            return getParentKey(item.key, treeData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);

      // console.log("Parent keys::", newExpandedKeys);
      setExpandedKeys(newExpandedKeys);
      setExpandParent(true);
    }
    else {
      setExpandedKeys(treeData.map((node) => node.key));
    }

  };

  const renderHighlightedText = (title, query) => {
    // if (!_.isEmpty(title) && !_.isEmpty(query)) {


      const titleLowerCase = title.toLowerCase();
      const queryLowerCase = query.toLowerCase();

      // If no query or no match, return the original title
      if (!query || !titleLowerCase.includes(queryLowerCase)) {
        return title;
      }
      return <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[query]}
        autoEscape
        textToHighlight={title ? title.toString() : ''}
      />
    // }

  };

  const renderTreeNodes = (data) => {
    // if (!_.isEmpty(data) && data.length > 0) {
      return data.map((node) => {
        const { key, title, children } = node;
        const titleWithHighlight = renderHighlightedText(title, searchQuery);

        if (children && children.length > 0) {
          return (
            <TreeNode key={key} title={<span>{titleWithHighlight}</span>}>
              {renderTreeNodes(children)}
            </TreeNode>
          );
        }

        return <TreeNode key={key} title={<span>{titleWithHighlight}</span>} />;
      });
    // }
  };


  if (_.isEmpty(dataList)) {
    return (<div className='p-2 px-3 text-left mb-2 alert alert-warning'>No dependencies found</div>);
  }
  else {

    return (
      <div>
        {/* {console.log("dataList", dataList)} */}
        <Input
          placeholder="Search tree..."
          onChange={handleSearchChange}
          value={searchQuery}
          allowClear
          prefix={<SearchOutlined />}
          // enterButton={false}
          className='search-text-dependency-tree'
        />
        {/* {console.log("Tree data::", filteredTreeData)} */}
        <div className='card p-2 mt-3 scroll-style' style={{ overflow: 'hidden', overflowY: 'scroll', height: 'calc(100vh - 350px)' }}>
          <Tree
            showLine={true}
            expandedKeys={expandedKeys}
            onExpand={(keys) => { setExpandParent(false); setExpandedKeys(keys); }}
            autoExpandParent={expandParent}
            defaultExpandAll={true}
            virtual={true}
          >
            {/* {filteredTreeData.map((node) => (
            <TreeNode key={node.key} title={renderHighlightedText(node.title, searchQuery)}>
              {node.children && node.children.length > 0
                ? node.children.map((childNode) => (
                  <TreeNode
                    key={childNode.key}
                    title={renderHighlightedText(childNode.title, searchQuery)}
                  />
                ))
                : null}
            </TreeNode>
          ))} */}
            {renderTreeNodes(filteredTreeData)}
          </Tree>
        </div>
      </div>
    );
  }

};
export default DependencyTree;