import React from 'react';
import { Table, Empty } from 'antd';
import NoDataFound from '../../shared/sharedComponents/noDataFound';

export default function TopOwaspFailures({ data }) {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            width: '70%',
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            width: '30%',
        },
    ];

    // If data is null or empty, show Empty component
    if (!data || data.length === 0) {
        return <Empty description="No data available" />;
    }

    // Add key to each data item if not present
    const dataSource = data.map((item, index) => ({
        key: item.key || `${index}`,
        ...item,
    }));

    return (
        <Table
            size='small'
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            //   pagination={{
            //     pageSize: 10,
            //     showSizeChanger: true,
            //     showQuickJumper: true,
            //   }}
            //   scroll={{ x: true, y: 400 }}
            className={"table custom-table mb-0"}
            locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
        />
    );
}