import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Modal, message, Tag, Tooltip, Popconfirm } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { faCheck, faGears, faInfoCircle, faPeopleGroup, faCircleExclamation, faCheckCircle, faUserPlus, faTimes, faMinus, faRightFromBracket, faTrash, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import moment from 'moment';
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { setLoginModalViewStatus } from "../../shared/redux/reducers/authReducer";
import addTeamIcon from '../../assets/img/team-add-icon.svg';
import Loader from "../../shared/sharedComponents/loader";

export default function TeamPage() {

    const reduxState = useSelector(state => state);

    const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false);
    const [newTeamName, setNewTeamName] = useState('');
    const [creatingTeam, setCreatingTeam] = useState(false);

    const [addNewMemberModalOpen, setAddNewMemberModalOpen] = useState(false);
    const [memberEmail, setMemberEmail] = useState('');
    const [addingMember, setAddingMember] = useState(false);
    const [teamId, setTeamId] = useState(null);

    const [teamList, setTeamList] = useState([]);

    const [loadingTeamMembers, setLoadingTeamMembers] = useState(false);

    const [pendingInvites, setPendingInvites] = useState(null);

    const [showLoader, setShowLoader] = useState(false);
    const [isEmptyTeamList, setIsEmptyTeamList] = useState(false);



    useEffect(() => {
        if (!_.isEmpty(reduxState.authReducer.userDetails.email)) {
            loadPendingList();
        }
    }, [reduxState]);

    const loadPendingList = () => {
        const postBody = { "email": reduxState.authReducer.userDetails.email };
        setShowLoader(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/team/pending-invitations`, postBody)
            .then(op => {
                // console.log("Output ::", op);
                setShowLoader(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setPendingInvites(op.data);
                    // console.log("I populated pending invite")
                }
                if (!_.isEmpty(op) && _.isEmpty(op.data)) {
                    loadTeamList();
                    setPendingInvites([]);
                }
            })
            .catch(e => {
                setShowLoader(false);
                message.error({
                    content: (
                        <div className="d-flex align-items-center">
                            Undefined error occurred
                        </div>
                    ),
                    icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                    onClose: () => message.destroy(),
                });
            })
    }


    const loadTeamList = () => {

        setLoadingTeamMembers(true);
        setShowLoader(true);
        const postBody = { "email": reduxState.authReducer.userDetails.email };
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/team/owned-teams`, postBody)
            .then(op => {
                setLoadingTeamMembers(false);
                setShowLoader(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setTeamList(op.data);
                    setIsEmptyTeamList(false);
                }
                if (!_.isEmpty(op) && _.isEmpty(op.data)) {
                    setTeamList([]);
                    setIsEmptyTeamList(true);
                }
            })
            .catch(e => {
                setLoadingTeamMembers(false);
                setShowLoader(false);
                message.error({
                    content: (
                        <div className="d-flex align-items-center">
                            Undefined error occurred
                        </div>
                    ),
                    icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                    onClose: () => message.destroy(),
                });
            });
    }


    const showCreateTeamModal = () => {
        setCreateTeamModalOpen(true);
    };

    const handleCancelCreateTeamModal = () => {
        setCreateTeamModalOpen(false);
    };

    const handleOkCreateTeamModal = () => {

        if (_.isEmpty(newTeamName)) {
            message.error('Please enter team name.');
            return;
        }

        const postData = { "email": reduxState.authReducer.userDetails.email, "teamName": newTeamName };
        setCreatingTeam(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/team/create`, postData)
            .then(op => {
                setCreatingTeam(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && _.has(op.data, 'errorMessage') && !_.isEmpty(op.data.errorMessage)) {
                    message.error({
                        content: (
                            <div className="d-flex align-items-center">
                                {op.data.errorMessage}
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                        onClose: () => message.destroy(),
                    });
                }

                if (!_.isEmpty(op) && !_.isEmpty(op.data) && _.has(op.data, 'isSuccess') && op.data.isSuccess === true) {
                    message.success({
                        content: (
                            <div className="d-flex align-items-center">
                                Team "{newTeamName}" created successfully.
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-success" icon={faCheckCircle} />,
                        onClose: () => message.destroy(),
                    });
                }
                handleCancelCreateTeamModal();
                loadTeamList();
            })
            .catch(e => {
                console.log("Exception:", e);
                setCreatingTeam(false);
                message.error({
                    content: (
                        <div className="d-flex align-items-center">
                            Undefined error occurred
                        </div>
                    ),
                    icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                    onClose: () => message.destroy(),
                });
            })
    };

    const handleAddNewMemberClick = (e, teamId) => {

        setAddNewMemberModalOpen(true);
        setMemberEmail('');
        setTeamId(teamId);
    }

    const handleCancelAddNewMember = () => {
        setAddNewMemberModalOpen(false);
    }

    const handleOkAddNewMember = () => {
        // setAddNewMemberModalOpen(true);

        if (_.isEmpty(memberEmail)) {
            message.error('Please enter member email address.');
            return;
        }

        // const postData = { "email": reduxState.authReducer.userDetails.email, "teamName": newTeamName };
        const postData = { "email": reduxState.authReducer.userDetails.email, "memberEmail": memberEmail, "teamId": teamId };
        setAddingMember(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/team/add-member`, postData)
            .then(op => {
                setAddingMember(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && _.has(op.data, 'errorMessage') && !_.isEmpty(op.data.errorMessage)) {
                    message.error({
                        content: (
                            <div className="d-flex align-items-center">
                                {op.data.errorMessage}
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                        onClose: () => message.destroy(),
                    });
                }

                if (!_.isEmpty(op) && !_.isEmpty(op.data) && _.has(op.data, 'isSuccess') && op.data.isSuccess === true) {
                    message.success({
                        content: (
                            <div className="d-flex align-items-center">
                                {/* Member "{memberEmail}" requested to join, once the user accepts the invitition he will be automatically added to the team. */}
                                Once the user "{memberEmail}" accepts the invitation, he will be automatically added to the team.
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-success" icon={faCheckCircle} />,
                        onClose: () => message.destroy(),
                    });
                }
                handleCancelAddNewMember();
                loadTeamList();

            })
            .catch(e => {
                console.log("Exception:", e);
                setAddingMember(false);
                message.error({
                    content: (
                        <div className="d-flex align-items-center">
                            Undefined error occurred
                        </div>
                    ),
                    icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                    onClose: () => message.destroy(),
                });
            })
    }


    const handleUpdateInviteClick = (e, teamId, id, status) => {

        const postBody = {
            "email": reduxState.authReducer.userDetails.email,
            "teamId": teamId,
            "inviteId": id,
            "invitationStatus": status
        };

        setShowLoader(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/team/update-invitation`, postBody)
            .then(op => {
                setShowLoader(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && _.has(op.data, 'isSuccess') && op.data.isSuccess === true) {
                    message.success({
                        content: (
                            <div className="d-flex align-items-center text-capitalize">
                                Team invite {status} successfully.
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-success" icon={faCheckCircle} />,
                        onClose: () => message.destroy(),
                    });
                    loadPendingList();
                }
            })
            .catch(ex => {
                console.log("Exception: ", ex);
                setShowLoader(false);
                message.error({
                    content: (
                        <div className="d-flex align-items-center">
                            Undefined error occurred
                        </div>
                    ),
                    icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                    onClose: () => message.destroy(),
                });
            })
    }

    const handleRemoveClick = (e, teamId, memberEmail) => {
        const postBody = {
            "email": reduxState.authReducer.userDetails.email,
            "memberEmail": memberEmail,
            "teamId": teamId
        };
        setShowLoader(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/team/remove-member`, postBody)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && _.has(op.data, 'isSuccess') && op.data.isSuccess === true) {
                    message.success({
                        content: (
                            <div className="d-flex align-items-center">
                                Member removed.
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-success" icon={faCheckCircle} />,
                        onClose: () => message.destroy(),
                    });
                }
                loadTeamList();
                setShowLoader(false);
            })
            .catch(ex => {
                console.log("Exception:", e);
                setShowLoader(false);
                message.error({
                    content: (
                        <div className="d-flex align-items-center">
                            Undefined error occurred
                        </div>
                    ),
                    icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                    onClose: () => message.destroy(),
                });
            })
    }

    const handleLeaveTeamClick = (e, teamId) => {

        const postBody = {
            "email": reduxState.authReducer.userDetails.email,
            "teamId": teamId
        }
        setShowLoader(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/team/leave-team`, postBody)
            .then(op => {
                setShowLoader(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && _.has(op.data, 'isSuccess') && op.data.isSuccess === true) {
                    message.success({
                        content: (
                            <div className="d-flex align-items-center">
                                You've successfully exited the team.
                            </div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-success" icon={faCheckCircle} />,
                        onClose: () => message.destroy(),
                    });
                }
                loadTeamList();
            })
            .catch(ex => {
                console.log("Exception:", e);
                setShowLoader(false);
                message.error({
                    content: (
                        <div className="d-flex align-items-center">
                            Undefined error occurred
                        </div>
                    ),
                    icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                    onClose: () => message.destroy(),
                });
            })
    }

    const handleResendClick = (e, teamId, memberEmail) => {
        const postBody = {
            "email": reduxState.authReducer.userDetails.email,
            "memberEmail": memberEmail,
            "teamId": teamId
        };
        setShowLoader(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/team/resend-invite`, postBody)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && _.has(op.data, 'isSuccess') && op.data.isSuccess === true) {
                    message.success({
                        content: (
                            <div className="d-flex align-items-center">The invitation has been successfully resent.</div>
                        ),
                        icon: <FontAwesomeIcon className="me-2 text-success" icon={faCheckCircle} />,
                        onClose: () => message.destroy(),
                    });
                }
                loadTeamList();
                setShowLoader(false);
            })
            .catch(ex => {
                console.log("Exception:", e);
                setShowLoader(false);
                message.error({
                    content: (
                        <div className="d-flex align-items-center">
                            Undefined error occurred
                        </div>
                    ),
                    icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                    onClose: () => message.destroy(),
                });
            })

    }



    return (
        <section className="mx-3">
            <section>
                <div className="mb-20">
                    <div className="">
                        <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-0 text-white d-flex align-items-center justify-content-start">
                            <FontAwesomeIcon icon={faPeopleGroup} className="me-2" />
                            Team
                        </h2>
                    </div>
                </div>

                {
                    !_.isEmpty(pendingInvites) && <>
                        <div className="alert alert-warning" role="alert">
                            <h4 className="alert-heading">Pending Invite From Other Teams</h4>
                            <p>You have pending invite from below teams</p>
                            <div className="list-group col-sm-2 border-warning">
                                {
                                    pendingInvites.map((el, index) => {
                                        return (
                                            <div className="list-group-item list-group-item-action bg-transparent border-warning" aria-current="true">
                                                <div className="">
                                                    <h5 className="mb-1">{el.team.name}</h5>
                                                    {/* <small>{el.invitationStatus}</small> */}
                                                </div>
                                                <p className="mb-3">Role: {el.role}</p>
                                                <div>
                                                    <button className="btn btn-success btn-sm me-2" onClick={(e) => handleUpdateInviteClick(e, el.team.id, el.id, "ACCEPTED")}><FontAwesomeIcon className="me-1" icon={faCheck} />Accept</button>
                                                    <button className="btn btn-danger btn-sm" onClick={(e) => handleUpdateInviteClick(e, el.team.id, el.id, "REJECTED")}><FontAwesomeIcon className="me-1" icon={faTimes} />Reject</button>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </>
                }

                {!_.isEmpty(teamList) ? <>
                    <div className="row">
                        {teamList.map((team, index) => {
                            return (
                                <div className="col-sm-4" key={`team-list-${index}`}>
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center justify-content-between pb-2 mb-4" style={{ borderBottom: '1px solid #2b313e' }}>
                                                <h5 className="title text-white fw-normal">{team.name}</h5>
                                                {(team.role === 'admin') &&
                                                    <div>
                                                        <Tooltip title={'Add new member'}><Button onClick={(e) => handleAddNewMemberClick(e, team.id)} ><FontAwesomeIcon icon={faUserPlus} /></Button></Tooltip>
                                                    </div>
                                                }
                                                {(team.role === 'member') &&
                                                    <div>
                                                        <Popconfirm
                                                            title="Leave Team?"
                                                            description={`Are you sure you want to leave team ${team.name}?`}
                                                            onConfirm={(e) => handleLeaveTeamClick(e, team.id)}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button danger ><FontAwesomeIcon icon={faRightFromBracket} className="me-2" />Leave Team</Button>
                                                        </Popconfirm>
                                                    </div>
                                                }
                                            </div>


                                            {team.descrpition && <p>{team.descrpition}</p>}
                                            <p className="mb-1">Team members</p>
                                            {!_.isEmpty(team.members) && <div className="list-group">
                                                {
                                                    team.members.map((member, key) => {
                                                        return (
                                                            <div key={`team-member-${key}`} href="#" className="list-group-item list-group-item-action" aria-current="true">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div>
                                                                        <div className="d-flex w-100">
                                                                            <div className="mb-0 me-2"><strong>{member.email}</strong></div>
                                                                            {(member.invitationStatus === 'accepted') && <Tag color="green">{member.invitationStatus}</Tag>}
                                                                            {(member.invitationStatus === 'pending') && <Tag color="blue">{member.invitationStatus}</Tag>}
                                                                        </div>
                                                                        <p className="mb-1 text-secondary">- {member.role}</p>
                                                                    </div>
                                                                    {(member.role === 'member' && team.role === 'admin') &&
                                                                        <div>
                                                                            {(member.invitationStatus === "pending") && <Popconfirm
                                                                                title="Resend Invite Link"
                                                                                description={`Would you like to resend the invitation to ${member.email}?`}
                                                                                onConfirm={(e) => handleResendClick(e, team.id, member.email)}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                            >
                                                                                <button className="btn btn-outline-info btn-sm me-2">Resend</button>
                                                                            </Popconfirm>}

                                                                            <Popconfirm
                                                                                title="Remove Member"
                                                                                description={`Are you sure you want to remove ${member.email}?`}
                                                                                onConfirm={(e) => handleRemoveClick(e, team.id, member.email)}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                            >
                                                                                <button className="btn btn-outline-danger btn-sm"><FontAwesomeIcon icon={faTrashCan} /></button>
                                                                            </Popconfirm>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }

                                            </div>}
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                </>
                    :
                    <>
                        {
                            isEmptyTeamList && <div className={'mb-5'}>
                                <div className="card custom-card rounded-3 mx-n4 mt-n4 border-0">
                                    <div className="card-body px-4 py-5">
                                        <div className="row justify-content-center">
                                            <div className="col-xxl-5 align-self-center">
                                                <div className="text-center py-4">
                                                    <div><FontAwesomeIcon icon={faPeopleGroup} className="fa-4x" /></div>
                                                    <h2 className="mb-3">Create Your Team</h2>
                                                    <p className="text-muted fs-15 mb-4">You haven't created a team yet. Take the first step by creating one now!</p>
                                                    <div><Button onClick={showCreateTeamModal} type="primary"><img src={addTeamIcon} className="me-2" style={{ width: 20 }} />Create New Team</Button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>

                }

            </section>

            {createTeamModalOpen && <Modal
                title="Create New Team" open={createTeamModalOpen} onOk={handleOkCreateTeamModal} onCancel={handleCancelCreateTeamModal} okText={'Create Team'}
                footer={[
                    <Button key="back" onClick={handleCancelCreateTeamModal}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={creatingTeam} onClick={handleOkCreateTeamModal}>
                        Create
                    </Button>
                ]}
            >
                <div className="mb-3">
                    <input type="text" className="form-control" id="txtTeamName" placeholder="Enter Team Name" value={newTeamName} onChange={(e) => { setNewTeamName(e.target.value) }} />
                </div>
            </Modal>}

            {addNewMemberModalOpen && <Modal
                title="Add New Member To Team" open={addNewMemberModalOpen} onOk={handleOkAddNewMember} onCancel={handleCancelAddNewMember} okText={'Request member to join'}
                footer={[
                    <Button key="back" onClick={handleCancelAddNewMember}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={addingMember} onClick={handleOkAddNewMember}>
                        Request member to join
                    </Button>
                ]}
            >
                <div className="mb-3">
                    <input type="email" className="form-control" id="txtMemberEmail" placeholder="Enter Member Email" value={memberEmail} onChange={(e) => { setMemberEmail(e.target.value) }} />
                </div>
            </Modal>}

            {showLoader && <Loader />}

        </section>
    );
}