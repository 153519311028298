import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck, faExclamationCircle, faInfoCircle, faTrowelBricks } from "@fortawesome/free-solid-svg-icons";
import bitbucket from '../../assets/img/bitbucket.svg';
import { faFile, faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import { Button, Tag } from "antd";
import _ from 'lodash';
import axios from "axios";
import { useHistory } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { Breadcrumb } from 'antd';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAllIntegratedSystems } from "../../shared/redux/reducers/integrationReducer";
import { getIntegratedState } from "../../shared/helper/genHelper";
import Loader from "../../shared/sharedComponents/loader";
import { Alert } from 'antd';
import Modal from 'react-bootstrap/Modal';
import CustomResultView from "../../shared/sharedComponents/customResultView";
import { GlobalConst } from "../../shared/appConfig/globalConst";

export default function IntegrateSCM({ scmType }){
    const reduxState = useSelector(state => state);
    const dispatch = useDispatch();
    const history = useHistory();
    const [apiFired, setApiFired] = useState(false);
    const [error, setError] = useState({});
    const [isIntegrated, setIsIntegrated] = useState(false);
    const [integratedSystems, setIntegratedSystems] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [confirmOpenDeleteModal, setConfirmOpenDeleteModal] = useState(false);
    const [credentials, setCredentials] = useState({
        // username: '',
        workspace: "",
        appPassword: '',
      });
      const [result, setResult] = useState({});

    const scmConfig = {
        bitbucket: {
          name: 'Bitbucket',
          logo: bitbucket,
          docsLink: 'https://docs.sec1.io/user-docs/2-integration-with-sec1/6-ci-cd-integrations/2-github-actions-integration',
          tokenCreationLink: 'https://confluence.atlassian.com/bitbucketserver/personal-access-tokens-939515499.html',
          authMethod: 'usernamePassword',
        },
        // Add configurations for other SCM providers here
      };

    useEffect(() => {
        // check for the status of the userdetails email.
        if (!_.isEmpty(reduxState) && !_.isEmpty(reduxState.authReducer) && !_.isEmpty(reduxState.authReducer.userDetails) && !_.isEmpty(reduxState.authReducer.userDetails.email)) {

            getUserIntegrations();
        }
    }, [reduxState]);

    const getUserIntegrations = () => {

        setShowLoader(true);
        axios
            .get(`${GlobalConst.API_URL}/auth/integrate/user-integrations`)
            .then(op => {
                setShowLoader(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setIntegratedSystems(op.data);
                    if (_.has(op.data, scmType)  && !_.isEmpty(op.data[scmType].targetorg)) {
                        getUserPat(op.data[scmType].targetorg);
                    }
                }

            })
            .catch(e => { setShowLoader(false); console.log("Exception:", e); })
    }

    const handleRemoveRepoClick = () => {

        setShowLoader(true);
        let data = {
            "userEmail": reduxState.authReducer.userDetails.email,
            "targetSystem": scmType,
            "targetOrg": credentials.workspace
        }
        axios
            .delete(`${GlobalConst.API_URL}/auth/integrate/user-pat`, { data })
            .then(op => {
                setShowLoader(false);
                history.push('/integrations-list');
                setResult({
                    show: true,
                    isAlert: false,
                    message: 'Integration Deleted Successfully',
                    description: `The ${scmConfig[scmType].name} Repositories integration has been successfully deleted.`,
                  });
            })
            .catch(e => {
                setShowLoader(false);
                console.log("Exception:", e);
                setResult({
                    show: true,
                    isAlert: true,
                    message: 'Undefined Error',
                    description: "An undefined error occurred while attempting to save the data.",
                });
            })
    }

    const getUserPat = (targetOrg) => {

        setShowLoader(true);
        let data = {
            "userEmail": reduxState.authReducer.userDetails.email,
            "targetSystem": scmType,
            "targetOrg": targetOrg
        }
        axios
            .post(`${GlobalConst.API_URL}/auth/integrate/get-user-pat`, data)
            .then(op => {
                // console.log("Output:", op);
                setShowLoader(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.data)) {
                    if(op.data.data.targetSystem === "bitbucket"){
                        setCredentials({
                            // username: op.data.data.targetUsername,
                            workspace: op.data.data.org,
                            appPassword: op.data.data.accessToken,
                        });
                    }
                    
                    verifyData(op.data.data.org, op.data.data.accessToken);

                }

            })
            .catch(e => { setShowLoader(false); console.log("Exception:", e); })
    }

    const verifyData = (org, accessT) => {
        setShowLoader(true);

        const postBody = {
            urlType: scmType,
            accessToken: accessT,
            owner: org,

        };

        axios
            .post(`${GlobalConst.API_URL}/auth/foss/verify`, postBody)
            .then(op => {
                setShowLoader(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && op.data.isValid) {
                    setError(false);
                }
                else {
                    setError(true);
                }
            })
            .catch(e => {
                setShowLoader(false);
                setError(e.response.data);
            })
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        // console.log("Handle Test Click here");
        setApiFired(true);

        handleFormSave();
    }

    const handleFormSave = () => {

        let postData = {
            accessToken: credentials.appPassword,
            "targetOrg": credentials.workspace,
            // targetUsername: credentials.username,
            "targetSystem": scmType,
            "userEmail": reduxState.authReducer.userDetails.email
        };

        if(getIntegratedState(integratedSystems, scmType)){
            postData = {
                ...postData,
                integrationId: integratedSystems[scmType].integrationId
            }
        }
        let data = new FormData();
        data.append("request", JSON.stringify(postData));

        let config = {
            method: "post",
            url: `${GlobalConst.API_URL}/auth/integrate/user-pat`,
            headers: { "content-type": "multipart/form-data" },
            data: data,
        };

        axios
            .request(config)
            .then(op => {
                setApiFired(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && op.data.data.connected === true && op.data.data.saved === true) {
                    setError(false);
                    setResult({
                        show: true,
                        isAlert: false,
                        message: 'Successfully Verified and Saved',
                        description: `Your ${scmConfig[scmType].name} repository credentials have been verified and saved successfully.`,
                    });
                    getUserIntegrations();
                }
                else if (!_.isEmpty(op) && !_.isEmpty(op.data) && op.data.data.connected === false && op.data.data.saved === false) {
                    setError(true);
                    setResult({
                        show: true,
                        isAlert: true,
                        message: 'Invalid Credentials',
                        description: "The provided credentials are invalid. Please verify and try again. Your credentials were not saved.",
                    });
                }
                else {
                    setError(true);
                    setResult({
                        show: true,
                        isAlert: true,
                        message: 'Undefined Error',
                        description: `An error occurred while attempting to save ${scmConfig[scmType].name} repository credentials. Please try again later.`,
                    });
                }
            })
            .catch(e => {
                setApiFired(false);
                setError(e.response.data);
                // console.log("Exception:", e);
                if (!_.isEmpty(e.response) && !_.isEmpty(e.response.data) && e.response.data.data.connected === false && e.response.data.data.saved === false) {
                    setResult({
                        show: true,
                        isAlert: true,
                        message: 'Invalid Credentials',
                        description: "The provided credentials are invalid. Please verify and try again. Your credentials were not saved.",
                    });
                }
                else {
                    setResult({
                        show: true,
                        isAlert: true,
                        message: 'Undefined Error',
                        description: "An undefined error occurred while attempting to save the data.",
                    });
                }

            })
    }


    const handleConfirmModalClose = () => {
        setConfirmOpenDeleteModal(false);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prev => ({ ...prev, [name]: value }));
    };

    const renderAuthFields = () => {
        switch (scmConfig[scmType].authMethod) {
          case 'usernamePassword':
            return (
              <>
                <div className="mb-3">
                  <label htmlFor="workspace" className="form-label mb-1 fw-semibold">Workspace Name</label>
                  <input
                    type="text"
                    name="workspace"
                    value={credentials.workspace}
                    onChange={handleInputChange}
                    className="form-control"
                    id="workspace"
                    placeholder="Enter workspace name"
                    disabled={apiFired}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="appPassword" className="form-label mb-1 fw-semibold">Personal access token</label>
                  <input
                    type="password"
                    name="appPassword"
                    value={credentials.appPassword}
                    onChange={handleInputChange}
                    className="form-control"
                    id="appPassword"
                    placeholder="e.g. yy6uc5uoyhwjbjvuydfg7juk25e8e5wbobhddgxpee2ehp4ec66q"
                    disabled={apiFired}
                  />
                </div>
              </>
            );
          default:
            return null;
        }
      };

    return (

        <section className="mx-3">
            <section>
                <div className="mb-4">
                    <div className="">
                        <div className="mb-3">
                            <Link to="/integrations-list"><FontAwesomeIcon className="me-2" icon={faArrowLeft} />Back to integrations</Link>
                        </div>
                        <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-2 text-white d-flex align-items-center justify-content-start">
                            <img src={scmConfig[scmType].logo} className="me-2" style={{ width: 30 }} alt={`${scmConfig[scmType].name}  Repositories`} />
                            <span className="pe-2">{scmConfig[scmType].name}</span> Repositories
                        </h2>
                        <p className="text-secondary">Streamline your integration with <span>{scmConfig[scmType].name}</span> Repositories and effortlessly scan container images. Get started with your personal access tokens.</p>
                    </div>
                </div>



                <div className={'mb-5'}>
                    <div className="card custom-card">
                        <div className="card-body">
                            <h3 className="title pb-2 mb-4 text-white fw-normal d-flex align-items-center justify-content-start" style={{ borderBottom: '1px solid #cfdaed' }}>
                            <span className="pe-2">{scmConfig[scmType].name}</span> Repos Settings
                                {error !== false ? <Tag className="ms-3" color="error"><FontAwesomeIcon className="me-2" icon={faExclamationCircle} />Not Connected</Tag> : <>{getIntegratedState(integratedSystems, scmType) ? <Tag className="ms-3" color="rgb(25,135,84)"><FontAwesomeIcon className="me-2" icon={faCheck} />Connected</Tag> : <Tag className="ms-3"><FontAwesomeIcon className="me-2" icon={faInfoCircle} />Not Connected</Tag>}</>}
                            </h3>
                            <p className="mb-3">Provide your account credentials below to establish a connection between Sec1 and your <span>{scmConfig[scmType].name}</span> Repositories account.</p>
                            {!_.isEmpty(result) && result.show && (
                                <div className="col-sm-5">
                                <CustomResultView
                                    show={result.show}
                                    isAlert={result.isAlert}
                                    resultPrimaryText={result.message}
                                    // resultSecondaryText={result.description}
                                />
                                </div>
                            )}
                            <section className="row">
                                <div className="col-sm-5">
                                    <form onSubmit={handleFormSubmit}>
                                    {renderAuthFields()}
                                        <div className="d-flex align-items-center justify-content-start mb-3">
                                            <Button type="primary" htmlType="submit" disabled={apiFired || !(credentials.appPassword)} loading={apiFired}>
                                                <FontAwesomeIcon icon={faFloppyDisk} className="me-2" /> Save
                                            </Button>

                                        </div>

                                    </form>

                                </div>
                                <div className="col-sm-4">
                                    <div className="alert alert-light">
                                        <h4 className="mb-3">Creating a personal access token</h4>
                                        <p>To create a personal access token, follow the below link:</p>
                                        <p><a className="btn btn-outline-info btn-sm" target="_blank" href={scmConfig[scmType].tokenCreationLink}>Create a personal access token</a></p>
                                    </div>
                                </div>
                            </section>

                            <hr />
                            <p className="text-secondary"><small>Refer to <a href={scmConfig[scmType].docsLink} target="_blank">our documentation</a> for further details regarding our integration with <span>{scmConfig[scmType].name}</span> Repos. </small></p>
                        </div>
                    </div>
                </div>
                {getIntegratedState(integratedSystems, scmType) && <div className="mb-5">
                    <div className="card custom-card border-danger-subtle">
                        <div className="card-body">
                            <h3 className="title pb-2 mb-4 text-white fw-normal d-flex align-items-center justify-content-start" style={{ borderBottom: '1px solid #cfdaed' }}>
                                Danger Zone
                            </h3>
                            <div className="row">
                                <div className="col-sm-5">
                                    <h6>Remove <span>{scmConfig[scmType].name}</span> Repositories Integration</h6>
                                    <p className="mb-0">Removing this integration will revoke your credentials and deactivate all <span>{scmConfig[scmType].name}</span> Repositories projects associated with your account from Sec1.</p>
                                </div>
                                <div className="col-sm-4">
                                    <button className="btn btn-outline-danger" onClick={(e) => { e.preventDefault(); setConfirmOpenDeleteModal(true); }}>Remove</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </section>
            {showLoader && <Loader />}
            {confirmOpenDeleteModal && <Modal show={confirmOpenDeleteModal} onHide={handleConfirmModalClose} size="lg" backdrop={'static'} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title>Confirm Deletion of <span>{scmConfig[scmType].name}</span> Repositories Integration</Modal.Title>
                </Modal.Header>
                <Modal.Body>Once you delete the <span>{scmConfig[scmType].name}</span> Repositories integration, <span className="text-danger-emphasis">the action cannot be undone</span>. Please proceed with caution and ensure your decision is final.</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-dark" onClick={handleConfirmModalClose}>
                        Close
                    </button>
                    <button className="btn btn-danger" onClick={handleRemoveRepoClick}>
                        Remove
                    </button>
                </Modal.Footer>
            </Modal>}
        </section>
    );
}