import React, { useEffect, useState, useRef } from "react";

import { Affix, Pagination, Result, Collapse, Tag, Dropdown, Space, Menu, Typography, Alert, Table, Switch, message } from 'antd';
import { Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import Highlighter from 'react-highlight-words';
import { DownOutlined, SmileOutlined, CaretDownOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen, faQuestionCircle, faTimes, faWrench } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { getStrokeColor, getFontColor } from '../../shared/helper/genHelper';
import DataTable from "./dataTable";
// import CVERow from './cveRow';
import _ from 'lodash';
import DependencyTree from "./dependencyTree";
import axios from "axios";
import Loader from "../welcomeScreen/loader";
import { getArtifactImage } from "../../shared/helper/getArtifactImageHelper";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import { GlobalConst } from "../../shared/appConfig/globalConst";



const expandedRowRender = (props, selectedFileRecord, selectedFilter, handleSeverityFilterChange, selectedFile, onChange, index) => {
    return <DataTable dataSet={props.rowData} filter={selectedFilter} handleSeverityFilterChange={handleSeverityFilterChange} selectedFile={props.value} />;
};




export default function ModuleView({ data, searchResults, selectedFileRecord, selectedFilter, handleSeverityFilterChange, selectedFile, onChange }) {

    // const [dataSet, setDataSet] = useEffect([]);
    const [dataSet, setDataSet] = useState([]);
    const [showDepTreeModal, setShowDepTreeModal] = useState(false);
    const [treeData, setTreeData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showAllComponents, setShowAllComponents] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [filterDropdownVisible, setFilterDropdownVisible] = useState({});

    const [selectedRow, setSelectedRow] = useState({});


    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');


    const searchInput = useRef(null);

    useEffect(() => {
        // console.log("Data::", data, searchResults);


        // let newData = [...data, ...selectedFileRecord]
        // console.log("Data", data);
        // console.log("SearchResults:", searchResults);

        let newOptions = [];
        let newData = [];
        if (!_.isEmpty(searchResults)) {



            let options = [];
            if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.scanResult)) {
                // console.log("I am the search results", searchResults.scanResult);
                searchResults.scanResult.forEach((elem, index) => {
                    if (getCountOnFilter(selectedFilter, elem.scanResult) > 0) {
                        // console.log("I am here");
                        options.push({
                            key: `${index}-row`,
                            value: index,
                            label: (_.has(searchResults, 'displayAssetName') && !_.isEmpty(searchResults.displayAssetName)) ? searchResults.displayAssetName : getFileLocation(elem.scanFileLocation),
                            displayAssetName: searchResults.displayAssetName,
                            count: getCountOnFilter(selectedFilter, elem.scanResult),
                            critical: (!_.isEmpty(elem.cveCountDetails) && _.has(elem.cveCountDetails, 'CRITICAL')) ? elem.cveCountDetails.CRITICAL : 0,
                            high: (!_.isEmpty(elem.cveCountDetails) && _.has(elem.cveCountDetails, 'HIGH')) ? elem.cveCountDetails.HIGH : 0,
                            medium: (!_.isEmpty(elem.cveCountDetails) && _.has(elem.cveCountDetails, 'MEDIUM')) ? elem.cveCountDetails.MEDIUM : 0,
                            low: (!_.isEmpty(elem.cveCountDetails) && _.has(elem.cveCountDetails, 'LOW')) ? elem.cveCountDetails.LOW : 0,
                            na: (!_.isEmpty(elem.cveCountDetails) && _.has(elem.cveCountDetails, 'NA')) ? elem.cveCountDetails.NA : 0,
                            total: (!_.isEmpty(elem.totalCve)) ? elem.totalCve : 0,
                            rowData: [elem],
                            scanFileLocation: elem.scanFileLocation,
                            scanType: elem.scanType,
                            colSpan: 0,
                            errorMessage: elem.errorMessage ? elem.errorMessage : ''
                        });
                    }
                    else {
                        // console.log("here", options)

                        if (showAllComponents) {
                            // console.log("Data without cve count: ", elem);
                            options.push({
                                key: `${index}-row`,
                                value: index,
                                label: (_.has(searchResults, 'displayAssetName') && !_.isEmpty(searchResults.displayAssetName)) ? searchResults.displayAssetName : getFileLocation(elem.scanFileLocation),
                                displayAssetName: searchResults.displayAssetName,
                                count: 0,
                                critical: 0,
                                high: 0,
                                medium: 0,
                                low: 0,
                                total: 0,
                                na: 0,
                                rowData: [elem],
                                scanFileLocation: elem.scanFileLocation,
                                scanType: elem.scanType,
                                colSpan: 6,
                                errorMessage: elem.errorMessage ? elem.errorMessage : ''
                            });
                        }
                    }
                })
            }
            newData = [...options];
            // console.log("New options", options);





            newOptions = _.orderBy(newData, ['critical', 'high', 'medium', 'low', 'na'], ['desc', 'desc', 'desc', 'desc', 'desc']);
            // console.log("New options:", newOptions);
        }
        setDataSet(newOptions);

        


    }, [searchResults, showAllComponents]);


    const getFileLocation = (file) => {
        if (!_.isEmpty(file)) {
            let fileSplit = file.split('/');
            return `${fileSplit[fileSplit.length - 2]}/${fileSplit.pop()}`
        }

    }

    const getCount = (rowData) => {
        let count = { CRITICAL: 0, HIGH: 0, MEDIUM: 0, LOW: 0, NA: 0 };
        if (!_.isEmpty(rowData) && !_.isEmpty(rowData.cves)) {
            rowData.cves.forEach((elem) => {
                if (!_.isEmpty(elem)) {
                    if (!_.isEmpty(elem.cvssV3BaseSeverity)) {
                        count[elem.cvssV3BaseSeverity.toUpperCase()] = count[elem.cvssV3BaseSeverity.toUpperCase()] + 1;
                    }
                    else {
                        if (!_.isEmpty(elem.cvssV2BaseSeverity)) {
                            count[elem.cvssV2BaseSeverity.toUpperCase()] = count[elem.cvssV2BaseSeverity.toUpperCase()] + 1;
                        }
                        else {
                            count["NA"] = count["NA"] + 1;
                        }
                    }
                }
            });
        }
        return count;
    }

    const getCountOnFilter = (filter, rowData) => {
        // console.log("I am the selected FIlter, row data", rowData, filter);
        let count = 0;
        if (!_.isEmpty(rowData)) {
            // console.log("Row data is not empty");
            _.forIn(rowData, (value, key) => {
                // console.log("Value", value)
                if (!_.isEmpty(value) && !_.isEmpty(value.cves)) {
                    let ct = getCount(value);
                    if (!_.isEmpty(filter)) {
                        filter.forEach((el) => {
                            // console.log("Filter", el)
                            count = count + ct[el];
                        })
                    }
                    else {
                        // console.log("Returning the resilt")
                        count = count + (parseInt(ct.CRITICAL) + parseInt(ct.HIGH) + parseInt(ct.MEDIUM) + parseInt(ct.LOW));
                    }
                    // console.log("I am the ct here;;;", ct)
                }
            });
        }
        // console.log("Count", count);
        return count;
    }

    const onChangeShowAllComponents = (checked) => {
        setShowAllComponents(checked);
        if (checked) {
            infoComponentsAdded();
        }
        else {
            infoComponentsRemoved();
        }
    }

    const infoComponentsAdded = () => {
        messageApi.info('All modules without vulnerabilities added to the table');
    };

    const infoComponentsRemoved = () => {
        messageApi.info('Modules without vulnerabilities removed from the table');
    };


    const showModal = (row) => {
        // setTreeData(row.rowData[0]);
        // Show the loader and get the date fire the api
        setShowDepTreeModal(true);
        setTreeData([]);

        if (!_.isEmpty(searchResults) && !_.isEmpty(row)) {
            // console.log("I am the search result here:", searchResults);
            // return;

            let reqBody = {
                location: searchResults.assetUrl,
                scanPath: row.scanFileLocation,
                reportId: searchResults.reportId,
                branch: searchResults.branch
            };

            setSelectedRow(reqBody);

            // console.log("Request body::", reqBody);
            setIsLoading(true);
            axios
                .post(`${GlobalConst.API_URL}/auth/foss/dependency-tree`, reqBody)
                .then((op) => {
                    // console.log("Output ::", op);
                    setIsLoading(false);
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        setTreeData(op.data);
                        // console.log("treeData: ", op.data);
                    }


                })
                .catch(e => { console.log("Exception: ", e); setIsLoading(false); setTreeData({ title: null }) });
        }

    }

    const handleOk = () => {
        setShowDepTreeModal(false);
    };
    const handleCancel = () => {
        setShowDepTreeModal(false);
    };




    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const getColumnSearchProps = (dataIndex, columnName) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${columnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        className="d-flex align-items-center justify-content-center"
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            if (clearFilters) {

                                clearFilters();
                                setSearchText('');
                            }
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchedColumn(dataIndex);
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        filterDropdownVisible: filterDropdownVisible[dataIndex],
        onFilterDropdownVisibleChange: (visible) => handleSearchClick(dataIndex, visible),
        render: (text, row) => (searchedColumn === dataIndex && !_.isEmpty(searchText)) ? highlightText(searchedColumn, text, row) : getColumnText(dataIndex, text, row)
    });


    const handleSearchClick = (selectedColumn, visible) => {
        let allVisible = _.mapValues(filterDropdownVisible, function (o) { return false; });
        setFilterDropdownVisible({ ...allVisible, [selectedColumn]: visible });
    };

    const getColumnText = (searchedColumn, text, row) => {
        if (searchedColumn === 'label') {
            // console.log("I AM THE ROW::", row)
            let href = '', assetType = '';
            if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.assetUrl)) {

                if (!_.isEmpty(searchResults.assetType) && searchResults.assetType === 'file') {
                    assetType = 'file';
                }


                let hrefPart = row.scanFileLocation.split(/\//g);
                if (hrefPart.length >= 3) {
                    href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(2).join('/');
                }
                else {
                    href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(1).join('/');
                }
            }

            let artifact = row.scanType;
            let imgTitle = '';
            if (artifact === 'maven') {
                imgTitle = 'java';
            }
            else {
                imgTitle = artifact;
            }

            // console.log("I am the display name::", row.displayAssetName);

            if (!_.isEmpty(row.displayAssetName)) {
                return ((!_.isEmpty(row) && !_.isEmpty(row.displayAssetName)) && <span title={row.displayAssetName}>{row.displayAssetName}</span>);
            }
            else {
                if (assetType === 'file') {
                    // console.log("File::")
                    return ((!_.isEmpty(row) && !_.isEmpty(row.scanFileLocation)) && <span title={row.scanFileLocation}>{row.scanFileLocation}</span>);
                }
                else {
                    return <a href={href} target="_blank"><img src={getArtifactImage(artifact)} style={{ width: 25 }} className="me-1" title={imgTitle} /> <span title={row.scanFileLocation}>{text}</span></a>;
                }
            }


        }
        else if (searchedColumn === 'scanFileLocation') {
            return <span title={row.fileLocation}>{text}</span>;
        }
        else {
            return text;
        }


    }

    const highlightText = (searchedColumn, text, row) => {
        if (searchedColumn === 'label') {

            let href = '';
            // if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.assetUrl)) {
            //     let [project, filePath] = row.scanFileLocation.split(/\/(.*)/s);
            //     href = searchResults.assetUrl + '/tree/' + searchResults.branch + '/' + filePath;
            // }
            if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.assetUrl)) {
                let hrefPart = row.scanFileLocation.split(/\//g);
                if (hrefPart.length >= 3) {
                    href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(2).join('/');
                }
                else {
                    href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(1).join('/');
                }
            }


            let artifact = row.scanType;
            let imgTitle = '';
            if (artifact === 'maven') {
                imgTitle = 'java';
            }
            else {
                imgTitle = artifact;
            }

            // console.log("I am the display name::", row.displayAssetName);
            if (!_.isEmpty(row.displayAssetName)) {
                return <span title={row.displayAssetName}>{getHighlighted(row.displayAssetName)}</span>;
                // return <span ><span title={text}>{getHighlighted(text)}</span></span>;
            }
            else {
                return <a href={href} target="_blank"><img src={getArtifactImage(artifact)} style={{ width: 25 }} className="me-1" title={imgTitle} /> <span title={row.scanFileLocation}>{getHighlighted(text)}</span></a>;
                // return <span ><span title={text}>{getHighlighted(text)}</span></span>;
            }



        }
        else if (searchedColumn === 'scanFileLocation') {
            return <span title={row.fileLocation}>{getHighlighted(text)}</span>;
        }
        else {
            return getHighlighted(text);
        }

    }

    const getHighlighted = (text) => {
        return <Highlighter
            highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
        />;
    }









    const columns = [
        {
            title: 'Module',
            dataIndex: 'label',
            // width: '25%',
            // render: (index, row) => { return ((index) && <span title={row.scanFileLocation}>{index}</span>) },
            ellipsis: true,
            ...getColumnSearchProps('label', 'module name'),
            sorter: (a, b) => a.label.localeCompare(b.label),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Total',
            dataIndex: 'count',
            width: 75,
            sorter: (a, b) => a.count - b.count,
            align: (val, row) => { return row.colSpan ? 'left' : 'center' },
            sortDirections: ['descend'],
            render: (val, row) => {

                if (row.colSpan === 6) {
                    if (row.errorMessage) {
                        return <span className="text-danger">{row.errorMessage}</span>;
                    }
                    else {
                        return 'This module has no known vulnerabilities';
                    }

                }
                return (val ? <Tag style={{ color: '#adb5bd', borderRadius: 50, width: 40, border: 'none', boxShadow: 'none', background: 'transparent', fontSize: 14 }} color={getStrokeColor('TOTAL')} className={'me-1 text-center'}>{val}</Tag> : '');
            },
            onCell: (row, index) => {
                // console.log("Row is:", _); 
                let colSpan = 6;
                if (!_.isEmpty(row.errorMessage)) {
                    colSpan = 7;
                }
                return ({ colSpan: (row.colSpan === 6) ? colSpan : '' })
            }
        },
        {
            title: 'Critical',
            dataIndex: 'critical',
            width: 75,
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.critical - b.critical,
            sortDirections: ['descend'],
            align: 'center',
            render: (val, row) => val ? <Tag style={{ color: getFontColor('CRITICAL'), borderRadius: 50, width: 40 }} color={getStrokeColor('CRITICAL')} className={'me-1 text-center'}>{val}</Tag> : '',
            onCell: (_, row) => { return ({ colSpan: (_.colSpan === 6) ? 0 : '' }) }
        },
        {
            title: 'High',
            dataIndex: 'high',
            width: 65,
            sorter: (a, b) => a.high - b.high,
            sortDirections: ['descend'],
            align: 'center',
            render: (val, row) => val ? <Tag style={{ color: getFontColor('HIGH'), borderRadius: 50, width: 40 }} color={getStrokeColor('HIGH')} className={'me-1 text-center'}>{val}</Tag> : '',
            onCell: (_, row) => { return ({ colSpan: (_.colSpan === 6) ? 0 : '' }) }
        },
        {
            title: 'Medium',
            dataIndex: 'medium',
            width: 85,
            sorter: (a, b) => a.medium - b.medium,
            sortDirections: ['descend'],
            align: 'center',
            render: (val, row) => val ? <Tag style={{ color: getFontColor('MEDIUM'), borderRadius: 50, width: 40 }} color={getStrokeColor('MEDIUM')} className={'me-1 text-center'}>{val}</Tag> : '',
            onCell: (_, row) => { return ({ colSpan: (_.colSpan === 6) ? 0 : '' }) }
        },
        {
            title: 'Low',
            dataIndex: 'low',
            width: 65,
            sorter: (a, b) => a.low - b.low,
            sortDirections: ['descend'],
            align: 'center',
            render: (val, row) => val ? <Tag style={{ color: getFontColor('LOW'), borderRadius: 50, width: 40 }} color={getStrokeColor('LOW')} className={'me-1 text-center'}>{val}</Tag> : '',
            onCell: (_, row) => { return ({ colSpan: (_.colSpan === 6) ? 0 : '' }) }
        },
        {
            title: 'NA',
            dataIndex: 'na',
            width: 65,
            sorter: (a, b) => a.na - b.na,
            sortDirections: ['descend'],
            align: 'center',
            render: (val, row) => val ? <Tag style={{ color: getFontColor('NA'), borderRadius: 50, width: 40 }} color={getStrokeColor('NA')} className={'me-1 text-center'}>{val}</Tag> : '',
            onCell: (_, row) => { return ({ colSpan: (_.colSpan === 6) ? 0 : '' }) }
        },
        {
            title: 'Action',
            dataIndex: '',
            width: '15%',
            render: (key, row) => {
                // console.log("Module data:", row)
                return (<a href={'#'} onClick={(e) => { e.preventDefault(); showModal(row); }}>View dependency tree</a>);
            },
            onCell: (row, index) => {
                let colSpan = '';
                if (!_.isEmpty(row.errorMessage)) {
                    colSpan = 0;
                }
                return ({ colSpan: (row.colSpan === 6) ? colSpan : '' })
            }
        },
    ];



    const expandIcon = ({ expanded, onExpand, record }) => {
        if (record.colSpan) {
            return false;
        }
        return expanded ? <button type="button" onClick={(e) => onExpand(record, e)} className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded" aria-label="Collapse row" aria-expanded="true"></button> : <button onClick={(e) => onExpand(record, e)} type="button" className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed" aria-label="Expand row" aria-expanded="false"></button>
    };


    const getComponentName = () => {
        if (selectedRow) {
            let value = selectedRow.scanPath;
            return getFileLocation(value);
        }
        else {
            return 'anonymous';
        }
    }


    return (<div>
        {contextHolder}
        <div className='d-flex align-items-center justify-content-end mb-4 me-3 mt-2'>
            <Switch onChange={onChangeShowAllComponents} checked={showAllComponents} className='me-2' />Show all modules
        </div>
        <hr />


        <Table
            // className='component-view-table'
            className='component-view-table table table-striped custom-table mb-0'
            columns={columns}
            dataSource={dataSet}
            // sticky={{ offsetHeader: 0 }}
            // expandIconColumnIndex={0}
            // size="small"
            // expandable={{
            //     expandedRowRender,
            //     // expandIcon: ({ expanded, onExpand, record }) => <FontAwesomeIcon icon={(expanded) ? faSquareMinus : faSquarePlus} style={{ cursor: 'pointer' }} onClick={e => onExpand(record, e)} />
            // }}
            expandable={{
                expandedRowRender,
                expandIcon: expandIcon
                // expandIcon: ({ expanded, onExpand, record }) => <FontAwesomeIcon icon={(expanded) ? faSquareMinus : faSquarePlus} style={{ cursor: 'pointer' }} onClick={e => onExpand(record, e)} />
            }}
            pagination={{
                showTotal: (total, range) => {
                    return <span className="text-white">{range[0]}-{range[1]} of {total && total.format()} modules</span>;
                },
                defaultPageSize: 20,
                showSizeChanger: true,
                position: ["bottom", "left"],
                className: "searchPagePagination",
                pageSizeOptions: [10, 20, 25, 50, 100, 200]
            }}
            locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
        />






        {/* <table className="table table-sm">
            <thead>
                <tr>
                    <th style={{ width: '20%' }}>Module</th>
                    <th style={{ width: '5%' }}>Vulnerabilities</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    (!_.isEmpty(data)) &&
                    data.map((el, index) => <><tr>
                        <td style={{ verticalAlign: 'top', fontSize: 14, width: '20%' }}>
                            <span title={el.label} className="d-flex align-items-center justify-content-start">
                                <span style={{ minWidth: 100 }} className="text-truncate d-inline-block me-1">
                                    {el.label.split('/').pop()}
                                </span> 
                            </span>
                        </td>
                        <td style={{ width: '5%', fontSize: 14 }}>{(!_.isEmpty(el) && el.count) && el.count}</td>
                        <td style={{ verticalAlign: 'top' }}><DataTable dataSet={selectedFileRecord} filter={selectedFilter} handleSeverityFilterChange={handleSeverityFilterChange} selectedFile={index} /></td>

                    </tr> */}
        {/* <tr>
                    <td></td>
                    <td></td>
                    <td ><DataTable dataSet={selectedFileRecord} filter={selectedFilter} handleSeverityFilterChange={handleSeverityFilterChange} selectedFile={index} /></td>
                </tr> */}

        {/* </>) */}
        {/* } */}
        {/* <h6 className="mb-3">
            Module
            <span className="ms-1" style={{}}><small className="text-muted" style={{ fontWeight: 200 }}><i>(no of vulnerabilities)</i></small></span>
        </h6> */}
        {/* <Tabs
            defaultActiveKey="0"
            type="card"
            onChange={(e) => { onChange(e) }}
            tabPosition={'left'}
            className="module-tab"
            style={{ height: '59vmin', overflowY: 'scroll', border: '1px solid #d9d9d9', borderRadius: 8}}
            items={data.map((el, i) => {
                const id = String(i);
                return {
                    label: <span>{el.label} <span className="text-muted"><i>(<b>{(!_.isEmpty(el) && el.count) && el.count}</b>)</i></span></span>,
                    key: id,
                    disabled: i === 28,
                    children: <DataTable dataSet={selectedFileRecord} filter={selectedFilter} handleSeverityFilterChange={handleSeverityFilterChange} selectedFile={selectedFile} />,
                };
            })}
        /> */}
        {/* </tbody>
        </table> */}
        <Modal
            title={<span>Dependency tree</span>}
            open={showDepTreeModal}
            onOk={handleOk}
            onCancel={handleCancel}
            width={750}
            closeIcon={<FontAwesomeIcon icon={faTimes} />}
        >
            <p className="mb-2"><small><b>Module:</b> <i>{getComponentName()}</i></small></p>
            {isLoading && <span className="d-flex align-items-center justify-content-start my-3"><Loader /> <span className="ms-2">Loading dependency tree...</span></span>}
            {!_.isEmpty(treeData) && <DependencyTree treeDataSet={treeData} />}
        </Modal>
    </div>);
}