import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import _ from 'lodash';
import GraphChart from './graphChart';
import { GlobalConst } from '../../../shared/appConfig/globalConst';
import axios from 'axios';
import { getCloudIcon } from '../../../shared/helper/genHelper';
import kubernetes from "../../../assets/img/kubernetes.svg";
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default function K8TreeModal({ showK8sTreeModal, setShowK8sTreeModal, setLoading, setShowResourceDetailsModal }) {
    const [isReady, setIsReady] = useState(false);
    const [modalDimensions, setModalDimensions] = useState({ width: 0, height: 0 });
    const [apidata, setApiData] = useState({});
    const { id, integrationId } = useParams();

    const hideResourceDetailsModal = () => {
        setShowK8sTreeModal(false);
    }

    const handleModalEntered = useCallback(() => {
        setIsReady(true);
    }, []);

    const updateModalDimensions = useCallback(_.debounce(() => {
        const modalBody = document.querySelector('.modal-body');
        if (modalBody) {
            setModalDimensions({
                width: modalBody.clientWidth,
                height: modalBody.clientHeight - 70
            });
        }
    }, 200), []);

    useEffect(() => {
        if (showK8sTreeModal) {
            window.addEventListener('resize', updateModalDimensions);
            return () => {
                window.removeEventListener('resize', updateModalDimensions);
            };
        }
    }, [showK8sTreeModal, updateModalDimensions]);

    useEffect(() => {
        if (isReady) {
            updateModalDimensions();
        }
    }, [isReady, updateModalDimensions]);

    useEffect(() => {
        getHierarchyData();
    }, []);

    const getHierarchyData = () => {
        setLoading(true);

        let postData = {
            "reportId": id,
            "integrationId": integrationId,
            "targetSystem": "k8s",
        }

        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/hierarchy`, postData)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setApiData(op.data);
                }
            })
            .catch(e => { setApiData({}); })
            .finally(o => {
                setLoading(false);
            })
    }


    return (
        <Modal
            show={showK8sTreeModal}
            fullscreen={true}
            onHide={hideResourceDetailsModal}
            onEntered={handleModalEntered}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className='d-flex align-items-start justify-content-start flex-row'>
                        <a className='me-3 mt-1' onClick={hideResourceDetailsModal} href='#' style={{ fontSize: 22 }}><small ><FontAwesomeIcon icon={faArrowLeft} /></small></a>
                        <div className='me-3'>{!_.isEmpty(apidata) ? <><img className='me-2' src={kubernetes} style={{ width: 30 }} />{apidata.name}</> : 'Cluster Tree'}</div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='mb-3'>
                    <section className="mb-5">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div style={{ height: `${modalDimensions.height}px`, width: '100%' }} className='d-flex align-items-center justify-content-center'>
                                    {!_.isEmpty(apidata) && <GraphChart apidata={apidata} hideResourceDetailsModal={hideResourceDetailsModal} setShowResourceDetailsModal={setShowResourceDetailsModal} />}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Modal.Body>
        </Modal>
    );
}