import axios from "axios";
import _ from 'lodash';
import { GlobalConst } from "../appConfig/globalConst";

export const handleDastFormSubmit = (values, opType, schedulerList) => {
    const formData = new FormData();
    let scanType = values.scanType;
    let scanAuthType = values.scanAuthType;

    formData.append('scanType', scanType);
    formData.append('authType', scanAuthType);
    formData.append('url', values.url);

    if (scanType === 'api') {
        // console.log("Schema file", values.schemaFile);
        if (values.schemaFile) {
            formData.append('apiCollection', values.schemaFile);
        }
        else {
            if (values.fileId) {
                formData.append('fileId', values.fileId);
            }
            if (values.fileName) {
                formData.append('fileName', values.fileName);
            }
        }
    }

    if (scanAuthType === 'OAUTH2') {
        let oAuth2Configuration = { "authUrl": values.authUrl, "realmName": values.realmName, "clientId": values.clientId, "clientSecret": values.clientSecret, "userId": values.userId, "password": values.password };
        // if (values.configId && opType === 'config') {
        //     oAuth2Configuration.configId = values.configId;
        // }
        formData.append('oauth2', JSON.stringify(oAuth2Configuration));
    }

    if (scanAuthType === 'USERNAME-PASSWORD') {
        let usernamePassConfiguration = { "authenticatorName": values.authenticatorName, "userId": values.userIdUname, "password": values.passwordUname };
        // if (values.configId && opType === 'config') {
        //     usernamePassConfiguration.configId = values.configId;
        // }
        formData.append('username-password', JSON.stringify(usernamePassConfiguration));
    }

    if (scanAuthType === 'HEADERS' && !_.isEmpty(values.headers)) {
        let headersJson = {};
        values.headers.forEach((header, index) => {
            headersJson[header.name] = header.value;
        });
        // if (values.configId && opType === 'config') {
        //     formData.append("configId", values.configId);
        // }
        formData.append('headers', JSON.stringify(headersJson));
    }

    if (!_.isEmpty(schedulerList)) {
        let newList = [];
        schedulerList.map((el) => {
            newList.push({
                hour: _.toNumber(el.time.format('h')),
                minute: _.toNumber(el.time.format('mm')),
                period: el.time.format('A'),
                timezone: el.timezone
            });
        });
        // console.log("newList", newList);
        formData.append("schedulers", JSON.stringify(newList));
    }

    if (values.configId && opType === 'config') {
        formData.append("configId", values.configId);
    }

    formData.append("triggerNow", values.triggerNow);
    // return;

    // const dummyHeaders = getDummyHeader();
    // dummyHeaders["Content-Type"] = "multipart/form-data";
    let response = null;
    let url = opType && opType === 'config' ? `${GlobalConst.API_URL}/auth/dast/scan/config` : `${GlobalConst.API_URL}/auth/dast/ascan`;

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    axios
        .post(url, formData, config)
        .then((op) => {
            if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                response = op.data;
                window.location.reload();
            }
        })
        .catch((e) => { response = e; });
    return response;
}