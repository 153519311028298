import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function PageLoader() {

    const loaderState = useSelector((state) => state.scannerReducer);
    const [loader, setLoader] = useState(false);

    useEffect(()=>{
        setLoader(loaderState.pageLoaderState);
    },[loaderState])

    return (
        <div id="sotcox-page-loading" className={`sotcox-loader ${loader ? 'd-block' : 'd-none'}`}>
            <div className="sotcox-spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        </div>
    );
}