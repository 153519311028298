import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';

const apiSecurityReducer = createSlice({
    name: 'apiSecurityReducer',
    initialState: {
        iScanModalState: false,
        apiScanTriggered: false,
        apiDashTableData: [],
        apiTriggeredScans: []
    },
    reducers: {
        setIScanModalState: (state, action) => {
            state.iScanModalState = action.payload;
        },
        setAPIScanTriggeredState: (state, action) => {
            state.apiScanTriggered = action.payload;
        },
        setAPIDashboardTableData: (state, action) => {
            state.apiDashTableData = action.payload;
        },
        updateAPITriggeredScans: (state, action) => {
            state.apiTriggeredScans = action.payload;
        },
        addUpdateAPIDashboardTableData: (state, action) => {

            // This function is added only to populate data in the table when a new scan is initiated and nothing else.

            let clonedAPITableData = _.cloneDeep(state.apiDashTableData);

            const presentList = _.filter(action.payload, (item) => {
                return _.some(clonedAPITableData, { assetUrl: item.location });
            });

            // console.log("I am the present list", JSON.stringify(presentList));

            const nonPresentList = _.filter(action.payload, (item) => {
                return !_.some(clonedAPITableData, { assetUrl: item.location });
            });

            // console.log("nonPresentList", JSON.stringify(nonPresentList));

            // Incoming record URL
            // {
            //     "critical": 1,
            //     "high": 0,
            //     "medium": 1,
            //     "low": 8,
            //     "scanStatus": "COMPLETED",
            //     "reportId": "65910f15-76c8-428b-9ff1-4212b671b30d",
            //     "scanDateTime": "2024-07-05T12:52:21.272Z",
            //     "assetUrl": "https://github.com/ScaleSec/vulnado",
            //     "total": 10,
            //     "assetType": "github",
            //     "source": ""
            // }

            if (!_.isEmpty(presentList)) {
                // These are the present records that need to be modified and add a loader to it so that we can show loading state.
                clonedAPITableData = clonedAPITableData.map((record) => {
                    const presentRecord = _.find(presentList, { location: record.assetUrl });
                    if (presentRecord) {
                        // console.log("Present", presentRecord)
                        // Modify the record as needed, for example, adding a loading state
                        return {
                            ...record,
                            scanStatus: "INITIATED",
                            reportId: presentRecord.uuid,
                            scanProgressReport: presentRecord.message,
                            scanProgress: 0
                        };
                    }
                    return record;
                });
            }

            // console.log("clonedAPITableData", JSON.stringify(clonedAPITableData));
            let newRecordsData = [];
            if (!_.isEmpty(nonPresentList)) {
                // These are the records which are newly added into the system show it on top of all the records.
                newRecordsData = nonPresentList.map((record) => {
                    return {
                        critical: 0,
                        high: 0,
                        medium: 0,
                        low: 0,
                        scanStatus: "INITIATED",
                        scanDateTime: "-",
                        assetUrl: record.location,
                        total: 0,
                        assetType: record.assetType,
                        scanProgressReport: "Mapping your digital landscape",
                        source: "",
                        reportId: record.uuid,
                        scanProgress: 0
                    };
                });
            }

            // console.log("newRecordsData", JSON.stringify(newRecordsData));

            // Filter records with status 'INITIATED' or 'SCANNING'
            const initiatedOrScanning = _.filter(clonedAPITableData, record => record.scanStatus.toLowerCase() === 'initiated' || record.scanStatus.toLowerCase() === 'scanning');

            // console.log("initiatedOrScanning", JSON.stringify(initiatedOrScanning));

            // Filter records with other statuses
            const otherStatuses = _.filter(clonedAPITableData, record => record.scanStatus.toLowerCase() !== 'initiated' && record.scanStatus.toLowerCase() !== 'scanning');

            // console.log("otherStatuses", JSON.stringify(otherStatuses));

            // Concatenate the filtered arrays to place 'INITIATED' or 'SCANNING' records first
            const sortedRecords = [...initiatedOrScanning, ...otherStatuses];
            const dashboardRecords = [...newRecordsData, ...sortedRecords];

            state.apiDashTableData = dashboardRecords;

            // const apiScans = sortedRecords.map((el)=>{() el.reportId});
            const apiScans = _.chain(dashboardRecords)
                .filter(record => record.scanStatus.toLowerCase() === 'scanning' || record.scanStatus.toLowerCase() === 'initiated')
                .map('reportId')
                .value();


            // console.log("api Scans", apiScans);

            state.apiTriggeredScans = apiScans;


            // dispatch



            // console.log("I am the api dashboard table data", JSON.stringify(state.apiDashTableData));
        }
    }
})

export const { setIScanModalState, setAPIScanTriggeredState, setAPIDashboardTableData, addUpdateAPIDashboardTableData, updateAPITriggeredScans } = apiSecurityReducer.actions;

export default apiSecurityReducer.reducer;