import noData from '../../assets/img/no-data.svg';

export default function NoDataFound({ title, desc }) {

    return (
        <div className='d-flex align-items-center justify-content-center flex-column my-4'>
            <img className='mb-3' src={noData} alt={'No Data Image'} style={{ width: 80 }} />
            {title && <h4>{title}</h4>}
            {desc && <p>{desc}</p>}
        </div>
    )
}