import React, { useState, useMemo, useRef } from 'react';
import { Table, Tag, Switch, Popover, Input, Space, Button } from 'antd';
import _ from 'lodash';
import NoDataFound from '../../../shared/sharedComponents/noDataFound';
import { getCloudIcon, getFontColor, getStrokeColor } from '../../../shared/helper/genHelper';
import { WarningFilled, CheckCircleFilled, SearchOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { updateSelectedK8sRecord } from '../../../shared/redux/reducers/kubernetesSecurityReducer';
import { FaCircleInfo } from "react-icons/fa6";
import { useParams } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const MessagePopover = ({ record }) => {
    const [visible, setVisible] = useState(false);

    const handleOpenChange = (newVisible) => {
        setVisible(newVisible);
    };

    const hide = () => {
        setVisible(false);
    };

    return (<Popover
        overlayClassName="message-popover"
        open={visible}
        content={<div style={{ width: 400, maxHeight: 400, overflow: 'auto' }}>
            <p className='mb-3'><strong>Description:</strong> {record.description}</p>
            {record.impact && <p className='mb-3'><strong>Impact:</strong> {record.impact}</p>}
            <div className='mb-1'>
                <strong>References</strong>
                <ul>
                    {!_.isEmpty(record.references) && record.references.map((el, index) => (
                        <li key={`ref-${record.name}-${index}`}>
                            <a target='_blank' href={el} rel="noopener noreferrer">{el}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>}
        title={<div className='d-flex justify-content-between'><p style={{ fontSize: 16 }} className='mb-1'><strong>Details</strong></p><span className='ms-auto'><FontAwesomeIcon icon={faClose} onClick={hide} /></span></div>}
        trigger="click"
        placement='rightTop'
        onOpenChange={handleOpenChange}
    >
        <FaCircleInfo className='me-2 cursor-pointer' />
    </Popover>)
}

const SecurityPostureTab = ({ data, setShowResourceDetailsModal }) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const dispatch = useDispatch();
    const [showAllComponents, setShowAllComponents] = useState(false);
    const [filterDropdownVisible, setFilterDropdownVisible] = useState({});
    const { id, integrationId } = useParams();
    const searchInput = useRef(null);

    const expandedRowRender = (record) => {

        const handleNameClick = (e, row) => {
            e.preventDefault();
            const activeRecordData = {
                "targetSystem": "k8s",
                "integrationId": integrationId,
                "reportId": id,
                "docType": row.docType,
                "name": row.name,
                "namespace": row.namespace
            };
            dispatch(updateSelectedK8sRecord(activeRecordData));
            setShowResourceDetailsModal(true);
        }

        const columns = [
            // {
            //     title: "Sr No", key: "srNo", width: 60,
            //     render: (val, record, index) => index + 1
            // },
            {
                title: 'Asset Name', dataIndex: 'name', key: 'name', ellipsis: true, width: 250,
                render: (text, record) => {
                    return (
                        <>
                            <img src={getCloudIcon(record.docType, "k8s")} className='me-2' style={{ width: 18 }} />
                            <a href='#' onClick={(e) => handleNameClick(e, record)}>{text}</a>
                        </>
                    )
                }
            },
            // { title: 'Name', dataIndex: 'name', key: 'name', ellipsis: true, width: 250, render: (text, row) => { return (<a href='#' onClick={(e) => handleNameClick(e, row)}>{text}</a>) } },
            {
                title: 'Message', dataIndex: 'message', key: 'message', ellipsis: true, render: (text, record) => {
                    return <>
                        <MessagePopover record={record} />
                        <span>{text}</span></>
                }
            },
            { title: 'Framework', dataIndex: 'frameworkName', key: 'frameworkName', ellipsis: true, width: 110 },
            { title: 'Rule No', dataIndex: 'ruleNo', key: 'ruleNo', ellipsis: true, width: 190, align: 'left' },

            {
                title: 'Status',
                key: 'status',
                width: 100,
                render: (_, record) => (
                    <span>
                        {record.violated === 'Pass' ? (
                            <CheckCircleFilled style={{ color: 'green' }} />
                        ) : (
                            <WarningFilled style={{ color: getStrokeColor('Critical') }} />
                        )}
                        {' '}
                        {record.violated}
                    </span>
                ),
            },
            {
                title: 'Severity',
                dataIndex: 'severity',
                key: 'severity',
                width: 120,
                render: (text, record) => (
                    <Tag
                        style={{
                            color: getFontColor(text),
                            borderRadius: 50,
                            fontSize: 12,
                            fontWeight: 600
                        }}
                        color={getStrokeColor(text)}
                        className={"mx-auto text-center text-uppercase"}
                    >
                        {text}
                    </Tag>
                ),
            },
        ];

        const myUpdatedData = (_.isEmpty(record) && _.isEmpty(record.policies)) ? [] : record.policies.map((row, index) => ({
            ...row,
            key: `k8s-adv-dashboard-expanded-${index}`
        }));

        const filteredData = showAllComponents ? myUpdatedData : myUpdatedData.filter(item => !_.isEmpty(item.violated) && item.violated.toLowerCase() === 'fail');

        // Sort the expanded data based on severity
        const sortedData = _.orderBy(filteredData,
            [item => {
                switch (item.severity.toLowerCase()) {
                    case 'critical': return 1;
                    case 'high': return 2;
                    case 'medium': return 3;
                    case 'low': return 4;
                    default: return 5;
                }
            }],
            ['asc']
        );

        const thirdLevelExpandedRowRender = (record) => {
            return (
                <div className="card card-custom p-3">
                    <p className='mb-1'><strong>Description:</strong> {record.description}</p>
                    {record.impact && <p className='mb-1'><strong>Impact:</strong> {record.impact}</p>}
                    <div className='mb-1'>
                        <strong>References</strong>
                        <ul>
                            {record.references.map((el, index) => (
                                <li key={`ref-${record.name}-${index}`}>
                                    <a target='_blank' href={el} rel="noopener noreferrer">{el}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            );
        };

        const sData = sortedData.map((row, index) => ({
            ...row,
            sno: index + 1,
        }));
        return (
            <Table
                size='small'
                columns={columns}
                dataSource={sData}
                // expandable={{
                //     expandedRowRender: thirdLevelExpandedRowRender,
                //     rowExpandable: (record) => true,
                // }}
                pagination={(!_.isEmpty(sData) && sData.length > 10) ? {
                    showTotal: (total, range) => {
                        return (
                            <span className="text-white">
                                {`${range[0]}-${range[1]} of ${total && total.format()} issues`}
                            </span>
                        );
                    },
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    position: ["bottom", "left"],
                    className: "searchPagePagination px-0",
                    pageSizeOptions: [10, 20, 25],
                } : false}
            />
        );
    };

    const getColumnSearchProps = (dataIndex, columnName) => ({
        // filterDropdownOpen: openState,
        // filterDropdownVisible: searchStates.column1,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${columnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        className="d-flex align-items-center justify-content-center"
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            if (clearFilters) {

                                clearFilters();
                                setSearchText('');
                            }
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchedColumn(dataIndex);
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        // filterDropdownVisible: filterDropdownVisible.column1,
        filterDropdownVisible: filterDropdownVisible[dataIndex],
        onFilterDropdownVisibleChange: (visible) => handleSearchClick(dataIndex, visible),
        render: (text, record) => (searchedColumn === dataIndex && !_.isEmpty(searchText)
            ? getHighlighted(text)
            : <><img src={getCloudIcon(record.docType, "k8s")} className='me-2' style={{ width: 18 }} />{text}</>)
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        // visible = true;
        // console.log("Visible", visible);
        setTimeout(() => searchInput.current?.select(), 100);
        // setOpenState(true);
    };

    const handleSearchClick = (selectedColumn, visible) => {
        let allVisible = _.mapValues(filterDropdownVisible, function (o) { return false; });
        setFilterDropdownVisible({ ...allVisible, [selectedColumn]: visible });
    };

    const getHighlighted = (text) => {
        return (
            <Highlighter
                highlightStyle={{
                    backgroundColor: "#ffc069",
                    padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
            />
        );
    };

    const dataTableColumns = [
        {
            title: 'Name',
            dataIndex: 'displayName',
            key: 'displayName',
            width: 180,
            ...getColumnSearchProps('displayName', 'Name'),
        },
        {
            title: 'Total',
            key: 'total',
            align: 'center',
            width: 95,
            sorter: (a, b) => {
                const atotal = a.total || 0;
                const btotal = b.total || 0;
                return atotal - btotal
            },
            sortDirections: ["descend"],
            render: (text, record) => {
                const total = record.critical + record.high + record.medium + record.low;
                return (<>{total}</>);
            }
        },
        {
            title: 'Critical',
            dataIndex: 'critical',
            key: 'critical',
            align: 'center',
            width: 105,
            sorter: (a, b) => {
                const acritical = a.critical || 0;
                const bcritical = b.critical || 0;
                return acritical - bcritical
            },
            sortDirections: ["descend"],
            render: (text, record) => (
                <Tag
                    style={{
                        color: getFontColor("CRITICAL"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14
                    }}
                    color={getStrokeColor("CRITICAL")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        },
        {
            title: 'High',
            dataIndex: 'high',
            key: 'high',
            align: 'center',
            width: 85,
            sorter: (a, b) => {
                const ahigh = a.high || 0;
                const bhigh = b.high || 0;
                return ahigh - bhigh
            },
            sortDirections: ["descend"],
            render: (text, record) => (
                <Tag
                    style={{
                        color: getFontColor("HIGH"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14
                    }}
                    color={getStrokeColor("HIGH")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        },
        {
            title: 'Medium',
            dataIndex: 'medium',
            key: 'medium',
            align: 'center',
            width: 105,
            sorter: (a, b) => {
                const amedium = a.medium || 0;
                const bmedium = b.medium || 0;
                return amedium - bmedium
            },
            sortDirections: ["descend"],
            render: (text, record) => (
                <Tag
                    style={{
                        color: getFontColor("MEDIUM"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14
                    }}
                    color={getStrokeColor("MEDIUM")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        },
        {
            title: 'Low',
            dataIndex: 'low',
            key: 'low',
            align: 'center',
            width: 95,
            sorter: (a, b) => {
                const alow = a.low || 0;
                const blow = b.low || 0;
                return alow - blow
            },
            sortDirections: ["descend"],
            render: (text, record) => (
                <Tag
                    style={{
                        color: getFontColor("LOW"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14
                    }}
                    color={getStrokeColor("LOW")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        }
    ];

    const onChangeShowAllComponents = (checked) => {
        setShowAllComponents(checked);
    }

    const myUpdatedData = data.resourceSummary.map((row, index) => ({
        ...row,
        sno: index + 1,
        key: `cloud-adv-dashboard-${index}`
    }));

    const filteredAndSortedData = useMemo(() => {
        let filtered = showAllComponents ? myUpdatedData : myUpdatedData.filter(item =>
            item.critical > 0 || item.high > 0 || item.medium > 0 || item.low > 0
        );

        // Sort the data based on severity counts
        return _.orderBy(filtered,
            ['critical', 'high', 'medium', 'low'],
            ['desc', 'desc', 'desc', 'desc']
        );
    }, [myUpdatedData, showAllComponents]);

    return (
        <div>
            <div className='d-flex align-items-center justify-content-end mb-4 me-3 mt-2' style={{ position: 'absolute', right: 0, top: '-56px' }}>
                <Switch onChange={onChangeShowAllComponents} checked={showAllComponents} className='me-2' />Show All
            </div>
            <Table
                size='small'
                className='custom-table mb-0 fixedScrollCustomTable table-hover component-view-table'
                dataSource={filteredAndSortedData}
                columns={dataTableColumns}
                locale={{
                    emptyText: <NoDataFound title={'No Data'} />
                }}
                expandable={{
                    expandedRowRender,
                    rowExpandable: (record) => record.policies && record.policies.length > 0,
                }}
                pagination={(!_.isEmpty(filteredAndSortedData) && filteredAndSortedData.length > 10) ? {
                    showTotal: (total, range) => {
                        return (
                            <span className="text-white">
                                {`${range[0]}-${range[1]} of ${total && total.format()} issues`}
                            </span>
                        );
                    },
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    position: ["bottom", "left"],
                    className: "searchPagePagination px-0",
                    pageSizeOptions: [10, 20, 25],
                } : false}
            />
        </div>
    );
};

export default SecurityPostureTab;