import { Button, Switch, Tooltip } from "antd";
import _ from "lodash";
import ThreatGraph from "./threatGraph";
import { useEffect, useRef, useState } from "react";
import ShowPackageDetails from "./showPackageDetails";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../../shared/sharedComponents/loader";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { useDispatch, useSelector } from "react-redux";
import {
  setAssetScanDependencyReport,
  setAssetsThreatScanRecords,
  setOverallRiskState,
  setPendingAssetScans,
} from "../../shared/redux/reducers/threatVisionReducer";
import { getAIAssetScanStrokeColor } from "../../shared/helper/genHelper";

function Dependency() {
  const [selectedPackage, setSelectedPackage] = useState({});
  const [loading, setLoading] = useState(false);
  const [scanning, setScanning] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const threatVisionReducer = useSelector((state) => state.threatVisionReducer);
  const [showAllComponents, setShowAllComponents] = useState(false)
  const [dependencyGraph, setDependencyGraph] = useState(null)
  const [isDependencyTreeModalOpen, setDependencyTreeModalOpen] = useState(false);

  const intervalId = useRef(null);

  useEffect(() => {
    if (!_.isEmpty(id)) {
      getDependencyData(id);
    }
  }, [id]);

  useEffect(() => {
    if (
      threatVisionReducer &&
      !_.isEmpty(threatVisionReducer.pendingAssetScans)
    ) {
      // Clear previous interval if it exists
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }

      // Set a new interval for polling
      intervalId.current = setInterval(() => {
        poll(threatVisionReducer.pendingAssetScans);
      }, 5000);
    }
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [threatVisionReducer.pendingAssetScans]);



  const onSelectedPackage = (item) => {
    setDependencyTreeModalOpen(true)
    setSelectedPackage(item);
  };

  const getSeverityCategory = (value) => {
    if (!value)
      return "NA"
    if (value > 0 && value <= 0.29) {
      return "LOW";
    } else if (value >= 0.3 && value <= 0.49) {
      return "MEDIUM";
    } else if (value >= 0.5 && value <= 0.69) {
      return "HIGH";
    } else if (value >= 0.7 && value <= 1) {
      return "CRITICAL";
    }
  };

  const rescan = (scanDependency) => {
    setScanning(true);
    const { assetType, assetUrl } = scanDependency;
    let requestBody = {
      repoType: assetType,
      repoURL: assetUrl,
    };
    axios
      .post(`${GlobalConst.API_URL}/auth/sca-threat-api/repo/predict`, requestBody)
      .then((response) => {
        if (!_.isEmpty(response) && !_.isEmpty(response.data))
          getDashboardSummary();
      })
      .catch((e) => {
        setScanning(false);
        console.log("Exception", e);
      });
  };

  const getDashboardSummary = () => {
    axios
      .get(`${GlobalConst.API_URL}/auth/sca-threat-api/dashboard/summary`)
      .then((op) => {
        if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.documents) && !_.isEmpty(op.data.overallRiskCategory)) {
          dispatch(setOverallRiskState(op.data.overallRiskCategory));
          dispatch(setAssetsThreatScanRecords(op.data.documents));
          const scanningOrInitiated = _.chain(op.data.documents)
            .filter(
              (record) =>
                record.status.toLowerCase() === "scanning" ||
                record.status.toLowerCase() === "initiated"
            )
            .map("reportId")
            .value();
          if (_.isEmpty(scanningOrInitiated)) {
            dispatch(setPendingAssetScans([]));
          } else {
            dispatch(setPendingAssetScans(scanningOrInitiated));
          }
        }
      })
      .catch((e) => {
        setScanning(false);
        console.log("error", e);
      });
  };

  const getDependencyData = (id) => {
    setLoading(true);
    axios
      .post(`${GlobalConst.API_URL}/auth/sca-threat-api/report/fetch`, {
        reportId: id,
      })
      .then((op) => {
        setLoading(false);
        if (
          !_.isEmpty(op) &&
          !_.isEmpty(op.data)
        ) {
          setScanning(false);
          let packages = []
          if (!_.isEmpty(op.data.probabilityReport))
            packages = op.data.probabilityReport.map(
              ({ Name, Probability }) => ({
                name: Name,
                severityScore: Probability,
                severityCategory: getSeverityCategory(Probability),
              })
            );

          // Create a map of package names to their severity categories
          const severityMap = new Map(packages.map(item => [item.name, item.severityCategory]));

          // Function to filter the dependency graph
          const filterGraph = (node, isRoot = false) => {
            const currentNodeSeverity = severityMap.get(getSubstringBeforeLastAt(node.bomRef));
            const isHighSeverity = currentNodeSeverity === "CRITICAL" || currentNodeSeverity === "HIGH" || currentNodeSeverity === "MEDIUM" || currentNodeSeverity === "LOW";

            const filteredChildren = node.children
              ? node.children
                .map(child => filterGraph(child, false))
                .filter(Boolean)
              : undefined;

            if (isRoot || isHighSeverity || (filteredChildren && filteredChildren.length > 0)) {
              return {
                ...node,
                children: filteredChildren && filteredChildren.length > 0 ? filteredChildren : undefined
              };
            }

            return null;
          };

          // Apply the filter to the root of the graph, ensuring the root is always included
          let filteredDependencyGraph = filterGraph(op.data.dependencyGraph, true);

          // console.log("Filtered dependency graph:", filteredDependencyGraph);

          // Apply styles to the filtered graph
          let styledDependencyGraph = addItemStyleToNodes(filteredDependencyGraph, packages);

          // console.log("Styled dependency graph:", styledDependencyGraph);

          setDependencyGraph(styledDependencyGraph);
          dispatch(
            setAssetScanDependencyReport({
              ...op.data,
              probabilityReport: packages,
              dependencyGraph: op.data.dependencyGraph, // Update with the filtered and styled graph
            })
          );
          setSelectedPackage(""); // Clear selected package when new scan results arrive
        }
      })
      .catch((e) => {
        setLoading(false);
        setScanning(false);
        console.log("error", e);
      });
  };

  const poll = (reportIds) => {
    // console.log("Inside poll stats", reportIds);
    axios
      .post(`${GlobalConst.API_URL}/auth/sca-threat-api/reports/status`, {
        reportId: reportIds,
      })
      .then((response) => {
        if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
          const updatedAssetsSummaries =
            threatVisionReducer.assetsThreatScanRecords.map((record) => {
              const isPresent = response.data.find((statusRecord) => {
                return record.reportId === statusRecord.reportId;
              });
              return isPresent ? isPresent : record;
            });
          dispatch(setAssetsThreatScanRecords(updatedAssetsSummaries));
          const scanningOrInitiated = _.chain(updatedAssetsSummaries)
            .filter(
              (record) =>
                record.status.toLowerCase() === "scanning" ||
                record.status.toLowerCase() === "initiated"
            )
            .map("reportId")
            .value();
          console.log(scanningOrInitiated);
          if (_.isEmpty(scanningOrInitiated)) {
            setScanning(false);
            getDependencyData(id);
            dispatch(setPendingAssetScans([]));
          } else {
            dispatch(setPendingAssetScans(scanningOrInitiated));
          }
        }
      })
      .catch((error) => {
        setScanning(false);
        console.error("API Request Failed:", error);
      });
  };

  const findProbabilityItem = (name, probabilityReport) => {
    return probabilityReport.find(item => item.name === name);
  }

  const addItemStyleToNodes = (node, probabilityReport, isShowAllComponents) => {
    const probabilityItem = findProbabilityItem(getSubstringBeforeLastAt(node.bomRef), probabilityReport);

    let newNode = { ...node };


    if (!_.isEmpty(probabilityItem)) {
      newNode = {
        ...newNode,
        severityCategory: probabilityItem.severityScore === 0 ? null : probabilityItem.severityCategory
      };
    } else {
      newNode = {
        ...newNode,
        severityCategory: null
      };
    }

    // Recursively process children if they exist
    if (newNode.children && Array.isArray(newNode.children)) {
      newNode.children = newNode.children
        .map(child => addItemStyleToNodes(child, probabilityReport, isShowAllComponents))
        .filter(child => child !== null);
    }

    return newNode;
  }

  function getSubstringBeforeLastAt(str) {
    if (!str.includes('@')) return str;  // Return full string if no @ exists
    const regex = /(.+)(?=@[^@]*$)/;
    const match = str.match(regex);
    return match ? match[1] : str;
  }


  const getUpdatedGraph = (dependencyGraph, probabilityReport, showAll) => {
    if (showAll) {
      // If showing all components, return the full graph with styles applied
      return dependencyGraph;
    }

    // Create a map of package names to their severity categories
    const severityMap = new Map(probabilityReport.map(item => [item.name, item.severityCategory]));

    // Function to filter the dependency graph
    const filterGraph = (node) => {
      if (!node) return null;

      const currentNodeSeverity = severityMap.get(getSubstringBeforeLastAt(node.bomRef));
      const isHighSeverity = currentNodeSeverity === "CRITICAL" || currentNodeSeverity === "HIGH" || currentNodeSeverity === "MEDIUM" || currentNodeSeverity === "LOW";

      const filteredChildren = node.children
        ? node.children
          .map(filterGraph)
          .filter(Boolean)
        : undefined;

      if (isHighSeverity || (filteredChildren && filteredChildren.length > 0)) {
        return {
          ...node,
          children: filteredChildren && filteredChildren.length > 0 ? filteredChildren : undefined
        };
      }

      return null;
    };

    // Always include the root node, then filter its children
    const filteredDependencyGraph = {
      ...dependencyGraph,
      children: dependencyGraph.children
        ? dependencyGraph.children.map(filterGraph).filter(Boolean)
        : undefined
    };

    return filteredDependencyGraph;
  };

  const handleShowAllComponentChange = (checked) => {
    if (!_.isEmpty(threatVisionReducer) && !_.isEmpty(threatVisionReducer.assetScanDependencyReport)) {
      const { probabilityReport, dependencyGraph, ...data } = threatVisionReducer.assetScanDependencyReport;
      let clonedDependencyGraph = _.cloneDeep(dependencyGraph);

      // Update the graph based on the checked state
      clonedDependencyGraph = getUpdatedGraph(clonedDependencyGraph, probabilityReport, checked);

      // Apply styles to the updated graph
      let styledDependencyGraph = addItemStyleToNodes(clonedDependencyGraph, probabilityReport);

      // console.log("Updated and styled dependency graph:", styledDependencyGraph);

      setDependencyGraph(styledDependencyGraph);

      // Uncomment if you want to update the store
      // dispatch(
      //   setAssetScanDependencyReport({
      //     ...data,
      //     probabilityReport,
      //     dependencyGraph: styledDependencyGraph,
      //   })
      // );
    }
    setShowAllComponents(checked);
  };

  const onCloseTreeModal = () => {
    setDependencyTreeModalOpen(false)
    setSelectedPackage(""); // Clear selected package when new scan results arrive
  };

  return (
    <>
      <section className="mx-3">
        <ThreatGraph scanning={scanning} onSelectedPackage={onSelectedPackage} rescan={rescan} showAllComponents={showAllComponents} setShowAllComponents={handleShowAllComponentChange} dependencyGraph={dependencyGraph} loading={loading} />
        {isDependencyTreeModalOpen && !_.isEmpty(selectedPackage) && (
          <ShowPackageDetails selectedPackage={selectedPackage} onCloseTreeModal={onCloseTreeModal} isDependencyTreeModalOpen={isDependencyTreeModalOpen} />
        )}
      </section>
      {loading && <Loader />}
    </>
  );
}

export default Dependency;
