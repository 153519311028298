import axios from "axios";
import { GlobalConst } from "../../../shared/appConfig/globalConst";

import React, { useState, useMemo, useEffect } from 'react';
import { Table, Tag, Switch, Tooltip } from 'antd';
import _ from 'lodash';
import NoDataFound from '../../../shared/sharedComponents/noDataFound';
import { getCloudIcon, getFontColor, getStrokeColor } from '../../../shared/helper/genHelper';
import { WarningFilled, CheckCircleFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { updateActiveSelectedRecord } from '../../../shared/redux/reducers/cloudSecurityReducer';
import TabLoader from "../../../shared/sharedComponents/tabLoader";
import TableFilterDropdown from "../../../shared/sharedComponents/antdTableComponents/tableFilterDropDown";
import TableColumnSearch from "../../../shared/sharedComponents/antdTableComponents/tableColumnSearch";

export default function RulesTab({ id, setShowResourceDetailsModal, dashboardData }) {

    const [showAllComponents, setShowAllComponents] = useState(false);
    const [data, setData] = useState({});
    const [loadingData, setLoadingData] = useState(false);
    const dispatch = useDispatch();
    const { getColumnSearchProps } = TableColumnSearch();

    useEffect(() => {
        setLoadingData(true);
        const postData = { "reportId": id };
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/summaryreport/rules`, postData)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setData(op.data);
                }
                else {
                    setData({});
                }
            })
            .catch(e => { setData({}); })
            .finally(o => {
                setLoadingData(false);
            })
    }, []);


    const expandedRowRender = (record) => {

        const handleNameClick = (e, row) => {
            e.preventDefault();
            const activeRecordData = {
                "targetSystem": dashboardData.targetSystem,
                "integrationId": dashboardData.integrationId,
                "reportId": dashboardData.reportId,
                "docType": row.docType,
                "name": row.name
            };
            dispatch(updateActiveSelectedRecord(activeRecordData));
            setShowResourceDetailsModal(true);
        }


        const customAssetNameRender = (text, record, { highlightComponent }) => (
            <>
                {/* <img src={getCloudIcon(record.docType, data.targetSystem)} className='me-2' style={{ width: 18 }} /> */}
                {/* <a href='#' onClick={(e) => handleNameClick(e, record)}>{highlightComponent || text}</a> */}
                <span><Tooltip title={record.docType}><img src={getCloudIcon(record.docType, dashboardData.targetSystem)} className='me-2' style={{ width: 18 }} /></Tooltip></span>
                <a href='#' onClick={(e) => handleNameClick(e, record)}>{highlightComponent || text}</a>
            </>
        );

        const columns = [
            {
                title: 'Name', dataIndex: 'name', key: 'name', ellipsis: true,
                ...getColumnSearchProps('name', customAssetNameRender, 'Name')
                // render: (text, row) => { return (<a href='#' onClick={(e) => handleNameClick(e, row)}><img src={getCloudIcon(row.docType, dashboardData.targetSystem)} className='me-2' style={{ width: 18 }} />{text}</a>) } 
            },
            {
                title: 'Status',
                key: 'status',
                width: 100,
                filters: [
                    { text: 'Pass', value: 'Pass' },
                    { text: 'Fail', value: 'Fail' },
                    { text: 'N/A', value: 'NA' }
                ],
                onFilter: (value, record) => record.violated.toLowerCase() === value.toLowerCase(),
                filterDropdown: (props) => <TableFilterDropdown {...props} />,
                render: (_, record) => (
                    <span>
                        {record.violated === 'Pass' ? (
                            <CheckCircleFilled style={{ color: 'green' }} />
                        ) : (
                            <WarningFilled style={{ color: getStrokeColor('Critical') }} />
                        )}
                        {' '}
                        {record.violated}
                    </span>
                ),
            },
            {}
            // {
            //     title: 'Severity',
            //     dataIndex: 'severity',
            //     key: 'severity',
            //     render: (text, record) => (
            //         <Tag
            //             style={{
            //                 color: getFontColor(text),
            //                 borderRadius: 50,
            //                 fontSize: 12,
            //                 fontWeight: 600
            //             }}
            //             color={getStrokeColor(text)}
            //             className={"mx-auto text-center text-uppercase"}
            //         >
            //             {text}
            //         </Tag>
            //     ),
            // },
        ];

        const myUpdatedData = (_.isEmpty(record) && _.isEmpty(record.policies)) ? [] : record.policies.map((row, index) => ({
            ...row,
            key: `cloud-adv-dashboard-expanded-${index}`
        }));

        const filteredData = showAllComponents ? myUpdatedData : myUpdatedData.filter(item => item.violated.toLowerCase() === 'fail');

        const sortedData = _.orderBy(filteredData,
            [item => {
                switch (item.severity.toLowerCase()) {
                    case 'critical': return 1;
                    case 'high': return 2;
                    case 'medium': return 3;
                    case 'low': return 4;
                    default: return 5;
                }
            }],
            ['asc']
        );

        return (
            <>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="card custom-card">
                            <div className="card-body">
                                <h5 className="mb-2">Resource Details</h5>
                                <Table
                                    size='small'
                                    columns={columns}
                                    dataSource={sortedData}
                                    pagination={{
                                        showTotal: (total, range) => {
                                            return (
                                                <span className="text-white">
                                                    {`${range[0]}-${range[1]} of ${total && total.format()} issues`}
                                                </span>
                                            );
                                        },
                                        defaultPageSize: 10,
                                        showSizeChanger: true,
                                        position: ["bottom", "left"],
                                        className: "searchPagePagination px-0",
                                        pageSizeOptions: [10, 20, 25],
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card custom-card">
                            <div className="card-body">
                                <h5 className="mb-3">Additional Details</h5>
                                <p><strong>Additional description: </strong>{record.description}</p>
                                <p><strong>Impact: </strong>{record.impact}</p>
                                <p className="mb-0"><strong>References: </strong></p>
                                {(_.has(record, 'references') && !_.isEmpty(record.references)) && <ul>
                                    {record.references.map((el, index) => (
                                        <li key={`ref-${record.name}-${index}`}>
                                            <a target='_blank' href={el} rel="noopener noreferrer">{el}</a>
                                        </li>
                                    ))}
                                </ul>}
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    };


    const customRender = (text, record, { highlightComponent }) => (
        <>
            {highlightComponent || text}
        </>
    );


    const dataTableColumns = [
        { title: 'Sno', dataIndex: 'sno', key: 'sno', ellipsis: true, width: 60 },
        {
            title: 'Rule Description',
            dataIndex: 'message',
            key: 'message',
            ellipsis: true,
            render: (text, record) => (<>{text}</>)
        },
        {
            title: 'Framework',
            dataIndex: 'frameworkName',
            key: 'frameworkName',
            width: 110,
            ...getColumnSearchProps('frameworkName', customRender, 'Framework'),
            // render: (text, record) => (<>{record.ID.frameworkName}</>)
        },
        { title: 'Rule No', dataIndex: 'ruleNo', key: 'ruleNo', ellipsis: true, align: 'left', width: 90, ...getColumnSearchProps('ruleNo', customRender, 'Rule Number') },
        {
            title: 'Severity',
            dataIndex: 'severity',
            align: 'center',
            width: 110,
            filters: [
                { text: 'CRITICAL', value: 'CRITICAL' },
                { text: 'HIGH', value: 'HIGH' },
                { text: 'MEDIUM', value: 'MEDIUM' },
                { text: 'LOW', value: 'LOW' }
            ],
            onFilter: (value, record) => record.severity.toLowerCase() === value.toLowerCase(),
            filterDropdown: (props) => <TableFilterDropdown {...props} />,
            render: (text, record) => {
                return (<Tag
                    style={{
                        color: getFontColor(text),
                        borderRadius: 50,
                        fontSize: 12,
                        fontWeight: 600
                    }}
                    color={getStrokeColor(text)}
                    className={"mx-auto text-center text-uppercase"}
                >
                    {text}
                </Tag>)
            }
        },
        {
            title: 'Fail Count',
            dataIndex: 'failCount',
            align: 'center',
            sortDirections: ["ascend", "descend"],
            sorter: (a, b) => a.failCount - b.failCount,
            width: 110,
            render: (text, record) => {
                return (<>{text}</>);
            }
        }
    ];

    const onChangeShowAllComponents = (checked) => {
        setShowAllComponents(checked);
    }

    let myUpdatedData = [];
    if (!_.isEmpty(data)) {
        myUpdatedData = data.map((row, index) => ({
            ...row,
            key: `cloud-adv-dashboard-${index}`,
            ruleNo: row.ID.ruleNo,
            frameworkName: row.ID.frameworkName
        }));
    }

    const filteredAndSortedData = useMemo(() => {

        if (!_.isEmpty(myUpdatedData)) {
            const sortedData = _.orderBy(myUpdatedData,
                [item => {
                    switch (item.severity.toLowerCase()) {
                        case 'critical': return 1;
                        case 'high': return 2;
                        case 'medium': return 3;
                        case 'low': return 4;
                        default: return 5;
                    }
                }, 'failCount'],
                ['asc', 'desc']
            );

            const finalData = sortedData.map((row, index) => ({
                ...row,
                sno: index + 1,
            }));

            return finalData;
        }

    }, [myUpdatedData, showAllComponents]);


    if (loadingData) {
        return (<div style={{ height: 200 }}><TabLoader /></div>);
    }
    else {
        return (
            <div>
                <div className='d-flex align-items-center justify-content-end mb-4 me-3 mt-2' style={{ position: 'absolute', right: 0, top: '-56px' }}>
                    <Switch onChange={onChangeShowAllComponents} checked={showAllComponents} className='me-2' />Show all
                </div>
                <Table
                    size='small'
                    className='custom-table mb-0 fixedScrollCustomTable table-hover component-view-table'
                    dataSource={filteredAndSortedData}
                    columns={dataTableColumns}
                    locale={{
                        emptyText: <NoDataFound title={'No Data'} />
                    }}
                    expandable={{
                        expandedRowRender,
                        rowExpandable: (record) => record.policies && record.policies.length > 0,
                    }}
                    loading={loadingData}
                    pagination={{
                        showTotal: (total, range) => {
                            return (
                                <span className="text-white">
                                    {`${range[0]}-${range[1]} of ${total && total.format()} issues`}
                                </span>
                            );
                        },
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        position: ["bottom", "left"],
                        className: "searchPagePagination px-0",
                        pageSizeOptions: [10, 20, 25],
                    }}
                />
            </div>
        );
    }

}