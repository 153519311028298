import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import { Button, Alert } from "antd";
import axios from "axios";
import { notification } from 'antd';
import { GlobalConst } from "../../shared/appConfig/globalConst";
import Loader from "../../shared/sharedComponents/loader";
import _ from 'lodash';

export default () => {

    const { id } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Suppress ResizeObserver error
        if (!_.isEmpty(id)) {
            getAWSConfiguration(id);
        }

        const resizeObserverError = console.error;
        console.error = (...args) => {
            if (
                args.length > 1 &&
                typeof args[0] === 'string' &&
                args[0].startsWith('ResizeObserver loop')
            ) {
                // Do nothing, suppressing the ResizeObserver error
            } else {
                resizeObserverError.apply(console, args);
            }
        };

        return () => {
            console.error = resizeObserverError;
        };
    }, [id]);


    const formik = useFormik({
        initialValues: {
            accountId: '',
            region: '',
            aws_access_key_id: '',
            aws_secret_access_key: '',
        },
        validationSchema: Yup.object({
            accountId: Yup.string()
                .required('Project ID is required'),
            region: Yup.string()
                .required('Region is required'),
            aws_access_key_id: Yup.string()
                .required('Access key is required'),
            aws_secret_access_key: Yup.string()
                .required('Secret Access key is required'),

        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const postBody = {
                "integration": "saas",
                "aws": {
                    "accountId": values.accountId,
                    "region": values.region,
                    "awsCredentials": {
                        "aws_access_key_id": values.aws_access_key_id,
                        "aws_secret_access_key": values.aws_secret_access_key
                    }
                }
            };

            let data = new FormData();
            data.append("config", JSON.stringify(postBody));
            if (id) {
                data.append("integrationId", id);
            }

            let config = {
                method: "post",
                url: `${GlobalConst.API_URL}/auth/cloud-api/integration/aws/save`,
                headers: { "content-type": "multipart/form-data" },
                data: data,
            };

            axios
                .request(config)
                .then(response => {
                    if (response.data && response.data.connected === true) {
                        let message = { title: '', desc: '' };
                        if (id) {
                            message = { title: 'Integration Updated', desc: "AWS integration updated successfully!" };
                        }
                        else {
                            message = { title: 'Integration Successfull', desc: "AWS integration successfully configured!" };
                        }
                        notification.success({
                            message: message.title,
                            description: message.desc,
                            duration: 5,
                        });
                        history.push(`/integrations-list/aws/${response.data.integrationId}`);
                    } else {
                        notification.error({
                            message: 'Undefined Error',
                            description: "Integration was not successful. Please check your configuration.",
                            duration: 0,
                        });
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.data && error.response.data.message) {
                            notification.error({
                                message: 'Undefined Error',
                                description: error.response.data.message,
                                duration: 0,
                            });
                        }
                        else if (error.response.data && error.response.data.error) {
                            notification.error({
                                message: 'Undefined Error',
                                description: error.response.data.error,
                                duration: 0,
                            });
                        }
                        else {
                            notification.error({
                                message: 'Undefined Error',
                                description: "An error occurred while processing your request. Please try again.",
                                duration: 0,
                            });
                        }
                    } else if (error.request) {
                        notification.error({
                            message: 'Undefined Error',
                            description: "No response received from the server. Please try again later.",
                            duration: 0,
                        });
                    } else {
                        notification.error({
                            message: 'Undefined Error',
                            description: "An error occurred while sending the request. Please try again.",
                            duration: 0,
                        });
                    }
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });

    const getAWSConfiguration = (awsIntegrationId) => {
        setLoading(true);
        const postData = {
            "integrationId": awsIntegrationId,
            "targetSystem": "aws"
        };
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/integration/cloud/fetch`, postData)
            .then(op => {
                if (op && op.data && !_.isEmpty(op.data.config) && !_.isEmpty(op.data.config.aws)) {
                    formik.setValues({
                        accountId: op.data.config.aws.accountId || '',
                        region: op.data.config.aws.region || '',
                        aws_access_key_id: op.data.config.aws.awsCredentials.aws_access_key_id || '',
                        aws_secret_access_key: op.data.config.aws.awsCredentials.aws_secret_access_key || '',
                    });
                }
            })
            .catch(e => {
                notification.error({
                    message: 'Undefined Error',
                    description: "Failed to load AWS configuration. Please try again.",
                    duration: 0,
                });
            })
            .finally(op => {
                setLoading(false);
            });
    }


    return (
        <div className="col-sm-5">
            <form onSubmit={formik.handleSubmit}>
                <div className="flex-fill me-3">


                    <div className="mb-3">
                        <label htmlFor="accountId" className="form-label mb-1 fw-semibold">Account ID</label>
                        <input
                            type="text"
                            className={`form-control ${formik.touched.accountId && formik.errors.accountId ? 'is-invalid' : ''}`}
                            id="accountId"
                            {...formik.getFieldProps('accountId')}
                            placeholder="Enter Account ID"
                        />
                        {formik.touched.accountId && formik.errors.accountId ? (
                            <div className="invalid-feedback">{formik.errors.accountId}</div>
                        ) : null}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="region" className="form-label mb-1 fw-semibold">Region</label>
                        <input
                            type="text"
                            className={`form-control ${formik.touched.region && formik.errors.region ? 'is-invalid' : ''}`}
                            id="region"
                            {...formik.getFieldProps('region')}
                            placeholder="e.g. us-east-1"
                        />
                        {formik.touched.region && formik.errors.region ? (
                            <div className="invalid-feedback">{formik.errors.region}</div>
                        ) : null}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="aws_access_key_id" className="form-label mb-1 fw-semibold">Access Key</label>
                        <input
                            type="text"
                            className={`form-control ${formik.touched.aws_access_key_id && formik.errors.aws_access_key_id ? 'is-invalid' : ''}`}
                            id="aws_access_key_id"
                            {...formik.getFieldProps('aws_access_key_id')}
                            placeholder="e.g. AKIAIOSFODNN7EXAMPLE"
                        />
                        {formik.touched.aws_access_key_id && formik.errors.aws_access_key_id ? (
                            <div className="invalid-feedback">{formik.errors.aws_access_key_id}</div>
                        ) : null}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="aws_secret_access_key" className="form-label mb-1 fw-semibold">Secret Access Key</label>
                        <input
                            type="password"
                            className={`form-control ${formik.touched.aws_secret_access_key && formik.errors.aws_secret_access_key ? 'is-invalid' : ''}`}
                            id="aws_secret_access_key"
                            {...formik.getFieldProps('aws_secret_access_key')}
                            placeholder="e.g. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"
                        />
                        {formik.touched.aws_secret_access_key && formik.errors.aws_secret_access_key ? (
                            <div className="invalid-feedback">{formik.errors.aws_secret_access_key}</div>
                        ) : null}
                    </div>



                </div>
                <div className="d-flex align-items-center justify-content-start">
                    <Button type="primary" htmlType="submit" loading={formik.isSubmitting} disabled={formik.isSubmitting}>
                        <FontAwesomeIcon icon={faFloppyDisk} className="me-1" />
                        {formik.isSubmitting ? 'Validating & Saving...' : 'Save'}
                    </Button>
                </div>
            </form>
            {formik.isSubmitting && <Loader />}
            {loading && <Loader />}
        </div>
    );
};