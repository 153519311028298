import { faDocker } from "@fortawesome/free-brands-svg-icons";
import {
  faSquare,
  faSquareCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Divider,
  Input,
  message,
  Modal,
  Radio,
  Space,
  Table,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  setConScanPage,
  setContainerScanModalState,
  setScanGuidResponse,
  setScannedContainers,
} from "../../shared/redux/reducers/containerSecurityReducer";
import axios from "axios";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import googleCloud from "../../assets/img/scopy-tech-icons/gcp.svg";
import awsCi from "../../assets/img/scopy-tech-icons/aws.svg";
import ibm from "../../assets/img/scopy-tech-icons/ibm.svg";
import azureRepos from "../../assets/img/azure-repos.svg";
import {
  getIntegratedState,
  removeGitSuffix,
} from "../../shared/helper/genHelper";
import Loader from "../welcomeScreen/loader";
import jfrog from "../../assets/img/scopy-tech-icons/jfrog.svg";
import oracle from "../../assets/img/techIcons/oracle.png";

export default function ScanModal() {
  const [selectedFilter, setSelectedFilter] = useState("unscanned");
  const [scannedCount, setScannedCount] = useState(10);
  const [unScannedCount, setUnScannedCount] = useState(50);
  const [allRegistryImages, setAllRegistryImages] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [activeTab, setActiveTab] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);
  const [imageRegistry, setImageRegistry] = useState("");

  const iScanModalState = useSelector(
    (state) => state.contSecurityReducer.iScanModalState
  );
  const scannedContainers = useSelector(
    (state) => state.contSecurityReducer.scannedContainers
  );

  const allIntegratedSystems = useSelector(
    (state) => state.integrationReducer.allIntegratedSystems
  );

  const dispatch = useDispatch();

  const getListByScanStatus = (selectedFilter) => {
    if (!_.isEmpty(allRegistryImages) && allRegistryImages.length > 0) {
      switch (selectedFilter) {
        case "scanned":
          let scannedList = [];
          allRegistryImages.forEach((repo) => {
            const isPresent = _.find(scannedContainers, function (o) {
              const url = repo.image
              return o.url === url;
            });
            if (!_.isEmpty(isPresent)) {
              scannedList.push({ ...repo });
            }
          });
          return scannedList;
        case "unscanned":
          let unscannedList = [];
          allRegistryImages.forEach((repo) => {
            const isPresent = _.find(scannedContainers, function (o) {
              const url = repo.image
              return o.url === url;
            });
            if (_.isEmpty(isPresent)) {
              unscannedList.push({ ...repo });
            }
          });
          return unscannedList;
        default:
          let allScannedList = [];
          allRegistryImages.forEach((repo) => {
            const isPresent = _.find(scannedContainers, function (o) {
              const url = repo.image
              return o.url === url;
            });
            if (!_.isEmpty(isPresent)) {
              allScannedList.push({ ...repo });
            }
          });
          return allScannedList;
      }
    }
  };

  const getImages = (registryType, activeTab) => {
    setLoadingImages(true);
    let reqBody = {
      registryType,
    };
    axios
      .post(`${GlobalConst.API_URL}/auth/foss/get-images-list`, reqBody)
      .then((op) => {
        setLoadingImages(false);
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          if (!_.isEmpty(op.data.results)) {
            let sCount = 0,
              uSCount = 0;
            op.data.results.forEach((repo) => {
              const isPresent = _.find(scannedContainers, function (o) {
                const url = repo.image
                return o.url === url;
              });
              if (!_.isEmpty(isPresent)) {
                sCount = sCount + 1;
              } else {
                uSCount = uSCount + 1;
              }
            });
            setSelectedFilter("unscanned");
            setScannedCount(sCount);
            setUnScannedCount(uSCount);
            setAllRegistryImages(op.data.results);
          } else {
            setAllRegistryImages([]);
          }
        } else {
          setAllRegistryImages([]);
        }
      })
      .catch((e) => {
        message.error("Failed to load container images");
        setAllRegistryImages(null);
        setLoadingImages(false);
        console.log("Exception: ", e);
      });
  };

  const handleContainerRegistryClick =
    (selectedRegistryTab, registryType) => () => {
      if (getIntegratedState(allIntegratedSystems, registryType)) {
        getImages(registryType, selectedRegistryTab);
      }
      setActiveTab(selectedRegistryTab);
      setImageRegistry(registryType);
    };

  const scanApplicationFromText = (e) => {
    e.preventDefault();
    let isDemoUser = localStorage.getItem('isDemoUser');
    if (isDemoUser && isDemoUser === 'true') {
      alert("Scan has been disabled for this account");

    }
    else {
      dispatch(setContainerScanModalState(false));
      const excludeVersion = (imageUrl) => imageUrl.split(':').slice(0, -1).join(':');
      if (!_.isEmpty(selectedImages)) {
        let requestList = [];
        let presentRepos = [];

        selectedImages.forEach((el) => {
          requestList.push({
            location: el.url,
            urlType: imageRegistry,
          });
          // check if the record is present in the scan list. If the record is present then
          const presentRecord = _.find(scannedContainers, function (o) {
            return o.url === excludeVersion(el.url);
          });
          if (!_.isEmpty(presentRecord)) {
            presentRepos.push(presentRecord);
          }
        });

        if (!_.isEmpty(presentRepos)) {
          dispatch(setConScanPage(1));
          // There are present repos so we need to shift things in scanned records
          let clonedPresentRecords = [];
          presentRepos.forEach((presentRecord) => {
            clonedPresentRecords.push({
              ...presentRecord,
              rowStatus: "INITIATED",
            });
          });
          // console.log("Cloned Present records with status changed", clonedPresentRecords);

          var clonedRecords = _.cloneDeep(scannedContainers);

          const comparator = (record1, record2) => record1.key === record2.key;

          // Remove matching records from the 'records' array
          _.pullAllWith(clonedRecords, clonedPresentRecords, comparator);

          // now that we have removed the existing records from the array join the array with new records.
          let newMergedRecords = [...clonedPresentRecords, ...clonedRecords];
          // now change their indexes

          let newData = [];

          newMergedRecords.forEach((el, ind) => {
            newData.push({
              ...el,
              key: `dataRow-${ind}`,
              serialNumber: ind + 1,
            });
          });
          dispatch(setScannedContainers(newData));
          triggerScan(requestList);
        } else {
          // when there are no present repos in the scanned list so we will directly add them in the scanned list and trigger the scans.
          let newRecords = [];
          selectedImages.forEach((el) => {
            let name = "";
            if (isValidHttpUrl(el.url)) {
              var pathname = new URL(el.url).pathname;
              pathname = pathname.substring(1);
              name = pathname;
            } else {
              name = el.url;
            }
            newRecords.push({
              key: `dataRow-new`,
              serialNumber: 1,
              name: decodeURIComponent(name),
              owner: "",
              org: "",
              total: 0,
              critical: 0,
              high: 0,
              medium: 0,
              low: 0,
              na: 0,
              type: "dockerhub",
              url: el.url,
              branch: "",
              assetType: "scm",
              subAssetType: "dockerhub",
              reportId: "",
              scanDate: "",
              scanningRow: false,
              ecosystem: [],
              rowStatus: "INITIATED",
              source: null,
            });
          });

          let updatedScanRecords = [...newRecords, ...scannedContainers];
          let newData = [];
          if (!_.isEmpty(updatedScanRecords)) {
            updatedScanRecords.forEach((el, ind) => {
              newData.push({
                ...el,
                key: `dataRow-${ind}`,
                serialNumber: ind + 1,
              });
            });
          }
          dispatch(setScannedContainers(newData)); // Update the redux state array to load the first record on top.
          triggerScan(requestList);
        }
      } else if (!_.isEmpty(searchVal)) {
        let name = "";
        if (isValidHttpUrl(searchVal)) {
          var pathname = new URL(searchVal).pathname;
          pathname = pathname.substring(1);
          name = pathname;
        } else {
          name = searchVal;
        }
        // check if the record is present in the scan list. If the record is present then
        let presentRecord = _.find(scannedContainers, function (o) {
          return o.url === excludeVersion(searchVal);
        });

        if (!_.isEmpty(presentRecord)) {
          // when there is a record found so we need to move the record to the first place and trigger the scan
          dispatch(setConScanPage(1));
          var clonedRecords = _.cloneDeep(scannedContainers);
          // get the record on top of the array...

          presentRecord = {
            ...presentRecord,
            rowStatus: "INITIATED",
          };
          clonedRecords = _.remove(
            clonedRecords,
            (o) => o.key !== presentRecord.key
          );
          clonedRecords.unshift(presentRecord); // Add the object to the beginning of the array
          // reset the key of the array by modifying the keys.
          let newData = [];

          if (!_.isEmpty(clonedRecords)) {
            clonedRecords.forEach((el, ind) => {
              newData.push({
                ...el,
                key: `dataRow-${ind}`,
                serialNumber: ind + 1,
              });
            });
          }
          dispatch(setScannedContainers(newData));
          let requestList = [];
          requestList.push({
            location: searchVal,
            urlType: "dockerhub",
          });
          triggerScan(requestList);
        } else {
          // When there is no record found we need to add the new record to the scan records and then trigger the scan.
          let newRecord = {
            key: `dataRow-new`,
            serialNumber: 1,
            name: decodeURIComponent(name),
            owner: "",
            org: "",
            total: 0,
            critical: 0,
            high: 0,
            medium: 0,
            low: 0,
            na: 0,
            type: "dockerhub",
            branch: "",
            url: searchVal,
            assetType: "scm",
            subAssetType: "dockerhub",
            reportId: "",
            scanDate: "",
            scanningRow: false,
            ecosystem: [],
            rowStatus: "INITIATED",
            source: null,
          };

          let updatedScanRecords = [{ ...newRecord }, ...scannedContainers];
          let newData = [];

          if (!_.isEmpty(updatedScanRecords)) {
            updatedScanRecords.forEach((el, ind) => {
              newData.push({
                ...el,
                key: `dataRow-${ind}`,
                serialNumber: ind + 1,
              });
            });
          }
          dispatch(setScannedContainers(newData));
          let requestList = [];
          requestList.push({
            location: searchVal,
            urlType: "dockerhub",
          });
          triggerScan(requestList);
        }
      }
    }
  };

  const isValidHttpUrl = (string) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };

  const triggerScan = (requestList) => {
    let requestBody = {
      urlType: imageRegistry,
      scanRequestList: requestList,
    };
    axios
      .post(`${GlobalConst.API_URL}/auth/foss/ascan?enableSbom=true`, requestBody)
      .then((op) => {
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          dispatch(setScanGuidResponse(op.data));
        }
      })
      .catch((e) => {
        message.error("Failed to initiate container image scan");
        console.log("Exception: ", e);
      });
  };

  const handleRepoItemClick = (e, element) => {
    e.preventDefault();
    // check if the current element is not present in the existing list if present then remove it else add it
    if (!_.isEmpty(selectedImages)) {
      let isPresent = false;
      selectedImages.forEach((el, ind) => {
        const url = getImageUrl(element);
        if (el.url === url) {
          isPresent = true;
        }
      });
      if (isPresent) {
        let filter = [];
        filter = _.filter(selectedImages, (o) => {
          const url = getImageUrl(element);
          return o.url !== url;
        });
        setSelectedImages([...filter]);
      } else {
        const url = getImageUrl(element);
        setSelectedImages([...selectedImages, { url }]);
      }
    } else {
      const url = getImageUrl(element);
      setSelectedImages([{ url }]);
    }
  };

  const isImageChecked = (element) => {
    if (!_.isEmpty(selectedImages)) {
      let isPresent = false;
      selectedImages.forEach((el) => {
        const url = getImageUrl(element);

        if (el.url === url) {
          isPresent = true;
        }
      });
      return isPresent;
    } else {
      return false;
    }
  };

  const showScanOptions = ({ title, tabId, icon, name, key }) => {
    return (
      <Tooltip key={title} title={title}>
        <div
          style={{ cursor: "pointer", marginRight: 12, opacity: 0.7 }}
          className={`p-2 rounded ${activeTab === tabId ? "active-other-link" : "border"
            } other-links`}
          onClick={handleContainerRegistryClick(tabId, key)}
        >
          <p className="mb-0">
            {key !== "dockerhub" ? (
              <img
                src={icon}
                alt={title}
                className="img-fluid"
                style={{ width: 20, height: 20 }}
              />
            ) : (
              <FontAwesomeIcon
                icon={icon}
                className="source-control-image-container"
                style={{ fontSize: 20, width: 20, height: 20 }}
              />
            )}
          </p>
          <p className="mb-0 text-center" style={{ width: 50 }}>
            {name}
          </p>
        </div>
      </Tooltip>
    );
  };

  const handleInputChange = (e) => {
    setSearchVal(e.target.value);
  };

  const handleRadioChange = (el) => {
    setSelectedImages([]);
    setSelectedFilter(el);
  };

  const showContainerImages = () => {
    const filteredImages = getListByScanStatus(selectedFilter);

    return (
      <div
        style={{
          maxHeight: 450,
          overflow: "hidden",
          overflowY: "auto",
        }}
      >
        <Table
          size={"small"}
          columns={columns}
          dataSource={filteredImages}
          className="table table-striped custom-table mb-0 table-no-select-all sticky-custom-table-header"
          pagination={false}
          locale={{ emptyText: <NoDataFound title={"No Data"} /> }}
        />
      </div>
    );
  };

  const getImageUrl = (element) => {
    return element.imageUrl
  };

  const getConfigurationLink = () => {
    switch (imageRegistry) {
      case "awsecr":
        return "aws-registry";
      case "ibmcr":
        return "ibm-registry";
      case "gcr":
        return "google-registry";
      case "jfrog":
        return "jfrog-registry";
      case "azurecr":
        return "azure-registry";
      default:
        return "docker-registry";
    }
  };

  const options = [
    {
      title: "Scan Docker Image",
      tabId: "1",
      icon: faDocker,
      name: "Docker",
      key: "dockerhub",
    },
    {
      title: "Scan IBM Container Registry Image",
      tabId: "2",
      icon: ibm,
      name: "IBM",
      key: "ibmcr",
    },
    {
      title: "Scan Google Artifact Registry Image",
      tabId: "3",
      icon: googleCloud,
      name: "Google",
      key: "gcr",
    },
    {
      title: "Scan AWS Image",
      tabId: "4",
      icon: awsCi,
      name: "AWS",
      key: "awsecr",
    },
    {
      title: "Scan Azure Image",
      tabId: "5",
      icon: azureRepos,
      name: "Azure",
      key: "azurecr",
    },
    {
      title: "Scan JFrog Image",
      tabId: "6",
      icon: jfrog,
      name: "JFrog",
      key: "jfrog",
    },
    {
      title: "Scan Oracle Image",
      tabId: "7",
      icon: oracle,
      name: "Oracle",
      key: "ocir",
    },
  ];
  const isRepoSelected = true;
  const columns = [
    {
      title: () => <strong>Sno</strong>,
      key: "sno",
      render: (_, __, index) => index + 1,
      width: 60
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "name",
      ellipsis: true,
      render: (text, element) => element.name,
    },
    {
      title: "Version",
      dataIndex: "latestVersion",
      width: 80
    },
    {
      title: "Select to scan",
      align: "center",
      dataIndex: "dataKey",
      render: (key, row) => {
        return (
          <a
            className="list-group-item border-0 white-link"
            href={"#"}
            onClick={(e) => handleRepoItemClick(e, row)}
          >
            <span className="d-flex align-items-center justify-content-center">
              <span>
                <span className="me-2">
                  {isImageChecked(row) ? (
                    <span className="me-2">
                      <FontAwesomeIcon icon={faSquareCheck} />
                    </span>
                  ) : (
                    <span className="me-2">
                      <FontAwesomeIcon icon={faSquare} />
                    </span>
                  )}
                </span>
              </span>
            </span>
          </a>
        );
      },
      width: "25%",
    },
  ];

  return (
    <Modal
      title=""
      open={iScanModalState}
      footer={null}
      onCancel={() => dispatch(setContainerScanModalState(false))}
      closeIcon={<FontAwesomeIcon className="fs-5 text-white" icon={faXmark} />}
      width={850}
      maskClosable={false}
      className="scan-modal"
    >
      <section className="m-1">
        <div className="">
          <div className="text-white">
            <div className="mb-3">
              <h5 className="mb-0 text-white" style={{ fontWeight: 600 }}>
                iScan{" "}
              </h5>
            </div>
            <div className="my-3 mb-1">
              <div className="me-3 mb-2">Select container registry to scan images</div>
              <div className="d-flex align-items-center justify-content-start">
                {options.map((option) => showScanOptions(option))}
              </div>
              <div>
                <div className="mt-3">
                  <div className="py-2 rounded">
                    <div className="row">
                      <div className="col-sm-12 scroll-style">
                        <div className="row">
                          <div className="col-sm-12 containerImageList">
                            {!_.isEmpty(allIntegratedSystems) &&
                              !_.isEmpty(imageRegistry) && (
                                <>
                                  {getIntegratedState(
                                    allIntegratedSystems,
                                    imageRegistry
                                  ) ? (
                                    loadingImages ? (
                                      <div
                                        className={
                                          "card custom-card p-2 px-3 text-left mb-2 mt-3"
                                        }
                                        style={{
                                          background: "#1e464d",
                                          border: "1px solid #2c6a74",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="d-flex align-items-center justify-content-start">
                                          <Loader />{" "}
                                          <span className="ms-2">
                                            Loading selected container
                                            images...
                                          </span>
                                        </div>
                                      </div>
                                    ) : !_.isEmpty(allRegistryImages) ? (
                                      <>
                                        {/* <div className="d-flex align-items-center justify-content-start mb-2">
                                      <div
                                        style={{
                                          width: `${
                                            (scannedCount * 100) /
                                            (scannedCount + unScannedCount)
                                          }%`,
                                          height: 25,
                                          borderRadius: "7px 0 0 7px",
                                          color: "#212121",
                                          fontWeight: 600,
                                          cursor: "pointer",
                                        }}
                                        className={`bg-success d-flex align-items-center justify-content-center ${
                                          !scannedCount ? "d-none" : ""
                                        }`}
                                        onClick={() =>
                                          handleRadioChange("scanned")
                                        }
                                      >
                                        {scannedCount}
                                      </div>
                                      <div
                                        style={{
                                          width: `${
                                            (unScannedCount * 100) /
                                            (scannedCount + unScannedCount)
                                          }%`,
                                          height: 25,
                                          borderRadius: "0 7px 7px 0",
                                          color: "#212121",
                                          fontWeight: 600,
                                          cursor: "pointer",
                                        }}
                                        className={`bg-danger d-flex align-items-center justify-content-center ${
                                          !unScannedCount ? "d-none" : ""
                                        }`}
                                        onClick={() =>
                                          handleRadioChange("unscanned")
                                        }
                                      >
                                        {unScannedCount}
                                      </div>
                                    </div> */}
                                        <div className="d-flex align-items-center justify-content-start px-2">
                                          <Tooltip
                                            title="show only unscanned images"
                                            color={"#444"}
                                          >
                                            <Radio
                                              name="filter"
                                              className="me-4 text-danger radio-danger"
                                              onChange={() =>
                                                handleRadioChange(
                                                  "unscanned"
                                                )
                                              }
                                              checked={
                                                selectedFilter ===
                                                "unscanned"
                                              }
                                              style={{ color: "#adb5bd" }}
                                            >
                                              unscanned images
                                              <span className="ps-1">{`(${unScannedCount})`}</span>
                                            </Radio>
                                          </Tooltip>
                                          <Tooltip
                                            title="show only scanned images"
                                            color={"#444"}
                                          >
                                            <Radio
                                              name="filter"
                                              className="me-4 text-success radio-success"
                                              onChange={() =>
                                                handleRadioChange("scanned")
                                              }
                                              checked={
                                                selectedFilter === "scanned"
                                              }
                                              style={{ color: "#adb5bd" }}
                                            >
                                              scanned images
                                              <span className="ps-1">{`(${scannedCount})`}</span>
                                            </Radio>
                                          </Tooltip>
                                        </div>
                                        {showContainerImages(
                                          selectedFilter
                                        )}
                                      </>
                                    ) : allRegistryImages !== null ? (
                                      <NoDataFound
                                        title={"No Images found"}
                                      />
                                    ) : null
                                  ) : (
                                    <NoDataFound
                                      title={`We could not found integrations for the container registry`}
                                      desc={
                                        <a
                                          href={`/integrations-list/${getConfigurationLink(
                                            imageRegistry
                                          )}`}
                                          target="_blank"
                                        >
                                          Please click here to configure
                                        </a>
                                      }
                                      customInlineStyle={{
                                        image: {
                                          width: 54,
                                        },
                                        title: {
                                          fontSize: 14,
                                        },
                                        description: {
                                          fontSize: 12,
                                        },
                                      }}
                                    />
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sotcox-osvs-form sotcox-input-field">
                <form action="#" onSubmit={scanApplicationFromText}>
                  {activeTab && (
                    <>
                      <Divider
                        className="divider-custom my-1"
                        orientation="center"
                      >
                        <small>or</small>
                      </Divider>
                      <div className="">
                        <div>
                          <Input
                            placeholder="enter an image URL to scan for e.g. https://docker.com/image"
                            allowClear
                            size="large"
                            value={searchVal}
                            className="iscan-search"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="text-center ">
                    <button
                      className={`sotcox-btn mt-4`}
                      type="submit"
                      disabled={!_.isEmpty(selectedImages) ? false : searchVal ? false : true}
                    >
                      Scan <span></span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
}
