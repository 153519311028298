import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function EnterprisePlanList({ planType }) {


    const handleChangePlanClick = (e) => {
        e.preventDefault();
    }

    return (
        <div className="card pricing-box shadow-lg border-0 border-top border-2 border-info" style={{ borderRadius: 8 }}>
            <div className="card-body m-2 p-4">
                <div className="mb-0">
                    <div className="">
                        <h3 className="mb-0 fw-semibold">Enterprise</h3>
                    </div>
                    <p className="text-muted">Please contact sales for pricing</p>
                </div>
                <hr />
                <h5 className="mb-2 fw-semibold">Products</h5>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item border-bottom-0"><input className="form-check-input me-1" type="checkbox" value="" id="scopyEnterprise" /> <label className="form-check-label" htmlFor="scopyEnterprise">Scopy - SCA</label></li>
                    <li className="list-group-item border-bottom-0"><input className="form-check-input me-1" type="checkbox" value="" id="xilEnterprise" /> <label className="form-check-label" htmlFor="xilEnterprise">XIL - Automated Fixes</label></li>
                    <li className="list-group-item border-bottom-0"><input className="form-check-input me-1" type="checkbox" value="" id="contekEnterprise" /> <label className="form-check-label" htmlFor="contekEnterprise">Contek - Container Scanning</label></li>
                    <li className="list-group-item border-bottom-0"><input className="form-check-input me-1" type="checkbox" value="" id="cisoEnterprise" /> <label className="form-check-label" htmlFor="cisoEnterprise">CISO Console</label></li>
                    <li className="list-group-item border-bottom-0"><input className="form-check-input me-1" type="checkbox" value="" id="sbomEnterprise" /> <label className="form-check-label" htmlFor="sbomEnterprise">SBOM Scanner</label></li>
                    <li className="list-group-item border-bottom-0"><input className="form-check-input me-1" type="checkbox" value="" id="cliEnterprise" /> <label className="form-check-label" htmlFor="cliEnterprise">CLI</label></li>
                    <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Galaxy Guard - Vulnerability Database</li>
                </ul>

                <hr />
                <div style={{ height: 350 }}>
                    <h5 className="mb-2 fw-semibold">Other Features</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Unlimited Scans</li>
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Support for SCM - GitHub, Azure, GitLab</li>
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Integration with CI tools - Jenkins, Azure DevOps, GitHub actions, GitLab CI/CD</li>
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Unlimited Team members</li>
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Team Dashboard</li>
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Dedicated Support</li>
                    </ul>
                </div>
                <hr />
                <div className="ms-auto text-center  d-flex align-items-center justify-content-end flex-column" style={{ height: 120 }}>
                    <h2 className="mb-0">Contact Sales</h2>
                    {/* <small className="fs-13 text-muted">per user/{planType === 'monthly' ? 'Month' : 'Year'}</small> */}
                </div>

                <div className="mt-3 pt-2">
                    <a href="#" onClick={handleChangePlanClick} className="btn btn-info w-100">Change Plan</a>
                </div>
            </div>
        </div>
    );
}