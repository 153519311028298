import axios from "axios";
import React, { useEffect, useState } from "react";
import _ from 'lodash';
import Loader from "../../shared/sharedComponents/loader";
import { Button, Steps, Tag, Tooltip } from 'antd';
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircle, faCircleXmark, faCheckCircle, faCaretRight, faArrowUp, faArrowDown, faArrowTrendUp, faArrowTrendDown } from "@fortawesome/free-solid-svg-icons";
import { getSeverityInitial, getStrokeColor, getFontColor } from "../../shared/helper/genHelper";
import { GlobalConst } from "../../shared/appConfig/globalConst";

export default function HistoryTabView({ searchResults, id, reportDetails }) {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [scanHistoryItems, setScanHistoryItems] = useState([]);
    // const [currentSelected, setCurrentSelected] = useState(null);

    useEffect(() => {
        if (!_.isEmpty(searchResults) && !_.isEmpty(reportDetails)) {
            // console.log("Search Results::", reportDetails);
            let postData = {
                "userId": searchResults.userId,
                "assetUrl": reportDetails.location
            }

            setLoading(true);
            axios
                .post(`${GlobalConst.API_URL}/auth/foss/user/asset-scan-history`, postData)
                .then(op => {
                    setLoading(false);
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        // console.log("op", op.data);
                        updateScanHistoryItems(op.data);
                    }


                })
                .catch(e => {
                    setLoading(false);
                    console.log("Exception::", e);
                })
        }

    }, []);

    const handleShowReportClick = (reportId) => {
        window.location.href = `/container-security/${reportId}`;
        // history.push(`/dashboard-scan-details/${reportId}`)

    }

    const updateScanHistoryItems = (historyData) => {

        // console.log("HistoryData::", historyData);
        let data = [];
        if (!_.isEmpty(historyData) && !_.isEmpty(historyData.content)) {


            // const dataArray = Object.entries(historyData.content).map(([date, value]) => ({ date, ...value }));
            const dataArray = _.map(historyData.content, (value, date) => ({ date, ...value }));
            // Sort the array by date
            dataArray.sort((a, b) => a.date - b.date);


            let lastSuccessTotalCVE = 0;
            let newDataArray = [];
            // dataArray.forEach((el, key) => {
            //     if (el.totalCve > lastSuccessTotalCVE) {
            //         newDataArray.push({ ...el, delta: key > 0 ? 'up' : null });
            //     }
            //     else if (el.totalCve < lastSuccessTotalCVE) {
            //         newDataArray.push({ ...el, delta: key > 0 ? 'down' : null });
            //     }
            //     else {
            //         newDataArray.push({ ...el, delta: null });
            //     }


            //     if (el.status.toLowerCase() === 'completed') {
            //         lastSuccessTotalCVE = el.totalCve;
            //     }
            // })
            dataArray.forEach((el, key) => {
                let delta = null;
                if (el.totalCve > lastSuccessTotalCVE) {
                    delta = key > 0 ? 'up' : null;
                } else if (el.totalCve < lastSuccessTotalCVE) {
                    delta = key > 0 ? 'down' : null;
                }

                newDataArray.push({ ...el, delta, deltaDiff: Math.abs(lastSuccessTotalCVE - el.totalCve) });

                if (el.status.toLowerCase() === 'completed') {
                    lastSuccessTotalCVE = el.totalCve;
                }
            });




            newDataArray.reverse();

            // return;

            // let delta = false;
            _.forEach(newDataArray, (value, key) => {
                // delta = false;
                if (!_.isEmpty(value)) {
                    // if ((key + 1) < dataArray.length) {
                    //     delta = (value.totalCve > dataArray[key + 1].totalCve) ? true : false;
                    // }
                    data.push({
                        title: <div style={{ fontSize: 14 }} className={`d-flex align-items-center justify-content-center flex-row px-3 py-1 card card-custom timelineCard ${(!_.isEmpty(id) && id === value.reportId) ? 'active' : ''} ${(value.status.toLowerCase() === 'failed') ? 'disabled' : ''}`} >
                            <div style={{ minWidth: 230 }} className="d-flex align-items-center justify-content-between">
                                <div>
                                    {(!_.isEmpty(id) && id === value.reportId) && <FontAwesomeIcon className="me-2 text-primary-emphasis" icon={faCaretRight} />} {moment.utc(value.scanDate).local().fromNow()}
                                    {(!_.isEmpty(value.imageVersion)) && <span className="ms-2 text-secondary"><i>&lt;{value.imageVersion}&gt;</i></span>}
                                    {(value.status.toLowerCase() === 'failed') && <Tooltip title={value.errorMessage}><FontAwesomeIcon className="ms-2 text-danger" icon={faCircleXmark} style={{ fontSize: 12 }} /></Tooltip>}
                                    {(value.status.toLowerCase() === 'completed') && <Tooltip title={"Scan Success"}><FontAwesomeIcon className="ms-2 text-success" icon={faCheckCircle} style={{ fontSize: 12 }} /></Tooltip>}
                                </div>
                                <div>
                                    {value.delta === 'up' && <Tag><FontAwesomeIcon icon={faArrowUp} className="text-danger me-1" /> {value.deltaDiff}</Tag>}
                                    {value.delta === 'down' && <Tag><FontAwesomeIcon icon={faArrowDown} className="text-success me-1" /> {value.deltaDiff}</Tag>}
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <div style={{ minWidth: 550 }}>
                                    {/* Total: {value.totalCve} */}
                                    <Tag className="ms-2">{value.totalCve} Total vulnerabilities</Tag>
                                    <Tag className="ms-2" style={{ color: getFontColor('CRITICAL'), fontWeight: 700 }} color={getStrokeColor('CRITICAL')}>{value.CRITICAL} Critical</Tag>
                                    {/* Critical: {value.CRITICAL} | */}
                                    <Tag style={{ color: getFontColor('HIGH'), fontWeight: 700 }} color={getStrokeColor('HIGH')}>{value.HIGH} High</Tag>
                                    {/* High: {value.HIGH} | */}
                                    <Tag style={{ color: getFontColor('MEDIUM'), fontWeight: 700 }} color={getStrokeColor('MEDIUM')}>{value.MEDIUM} Medium</Tag>
                                    {/* Medium: {value.MEDIUM} | */}
                                    <Tag style={{ color: getFontColor('LOW'), fontWeight: 700 }} color={getStrokeColor('LOW')}>{value.LOW} Low</Tag>
                                    <Tag style={{ color: getFontColor('NA'), fontWeight: 700 }} color={getStrokeColor('NA')}>{value.NA} NA</Tag>
                                    {/* Low: {value.LOW} */}
                                </div>

                                <div className="text-secondary-emphasis" style={{ fontSize: 12 }}><small><i><b>Run by</b> - {value.scanByEmail}</i></small></div>
                                {/* {value.delta === 'down' && <FontAwesomeIcon icon={faArrowDown} className="ms-2 text-success" />} */}

                                {(value.status.toLowerCase() === 'completed') && <a className="ms-5 btn-link" onClick={(e) => { e.preventDefault(); handleShowReportClick(value.reportId) }} href="#">View Report</a>}
                            </div>
                        </div>,
                        description: <></>,
                    });
                }
            });
            setScanHistoryItems(data);
        }
    }




    if (loading) {
        return (
            <Loader />
        );
    }
    else {

        return (
            <section>
                <h3 className="text-white">Scan History</h3>
                <div className="mt-3">
                    <Steps
                        progressDot
                        current={0}
                        direction="vertical"
                        className="text-white scan-history-timeline"
                        items={scanHistoryItems}
                    />
                </div>
            </section>
        );
    }

}