import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import _ from 'lodash';
import { useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import NoDataFound from "../../shared/sharedComponents/noDataFound";

export default function ViewTraceabilityTable({ traceabilityDetails }) {
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterDropdownVisible, setFilterDropdownVisible] = useState({});

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setTimeout(() => searchInput.current?.select(), 100);
  };

  const handleSearchClick = (selectedColumn, visible) => {
    let allVisible = _.mapValues(filterDropdownVisible, function (o) { return false; });
    setFilterDropdownVisible({ ...allVisible, [selectedColumn]: visible });
  };

  const getHighlighted = (text) => {
    return (
      <Highlighter
        highlightStyle={{
          backgroundColor: "#ffc069",
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    );
  }

  const highlightText = (text) => {
    return getHighlighted(text);
  }


  const getColumnSearchProps = (dataIndex, columnName) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${columnName}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            className="d-flex align-items-center justify-content-center"
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                clearFilters();
                setSearchText("");
              }
              confirm({
                closeDropdown: false,
              });
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    filterDropdownVisible: filterDropdownVisible[dataIndex],
    onFilterDropdownVisibleChange: (visible) =>
      handleSearchClick(dataIndex, visible),
    render: (text) =>
      searchedColumn === dataIndex && !_.isEmpty(searchText)
        ? highlightText(text)
        : text,
  });

  const columns = [
    {
      title: "Line No",
      dataIndex: "lineNumber",
      key: "lineNumber",
      sorter: (a, b) => a.lineNumber - b.lineNumber,
      sortDirections: ['ascend','descend'],
      width: 85
    },
    {
      title: "File Path",
      dataIndex: "filePath",
      key: "filePath",
      ellipsis: true,
      sorter: (a, b) => a.filePath.localeCompare(b.filePath),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps("filePath","File Path"),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      ellipsis: true,
      sorter: (a, b) => a.code.localeCompare(b.code),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps("code","Code"),
    },
    // {
    //   title: "Column Number",
    //   dataIndex: "columnNumber",
    //   key: "columnNumber",
    //   align: "center",
    // },
    // {
    //   title: "Tags",
    //   dataIndex: "tags",
    //   key: "tags",
    //   align: "left",
    //   ellipsis: true,
    // },
  ];

  return (
    <Table
    size="small"
      columns={columns}
      className="custom-table mb-0"
      dataSource={traceabilityDetails}
      pagination={{
        showTotal: (total, range) => {
          return (
            <span className="text-white">
              {range[0]}-{range[1]} of {total && total.format()} resources
            </span>
          );
        },
        defaultPageSize: 5,
        showSizeChanger: true,
        position: ["bottom", "left"],
        className: "searchPagePagination px-0",
        pageSizeOptions: [5,10,50,100],
      }}
      locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
    />
  );
}
