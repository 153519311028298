import React, { useRef, useEffect, useState, useCallback } from 'react';
import * as d3 from 'd3';
import { useD3 } from '../../shared/hooks/useD3';
import { getAIAssetScanStrokeColor } from '../../shared/helper/genHelper';
import _ from 'lodash';
import { GlobalConst } from '../../shared/appConfig/globalConst';

function createSVG(data, {
    label,
    title
},containerWidth, containerHeight, svg,
triggerNodeClick) {
    svg.selectAll("*").remove(); // Clear previous content

    const margin = { top: 40, right: 120, bottom: 40, left: 120 };
    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    const root = d3.hierarchy(data);
    const nodeSize = { width: 180, height: 40 };
    const regularNodeSize = { width: 140, height: 30 };
const criticalNodeSize = { width: 140, height: 30 };
    
     // Calculate tree layout with fixed horizontal spacing
     const treeLayout = d3.tree()
     .nodeSize([criticalNodeSize.height * 2.5, Math.max(regularNodeSize.width, criticalNodeSize.width) + 5])
     .separation(() => 1); //

     treeLayout(root);


    // Normalize for fixed-depth and ensure 5px horizontal spacing
    root.descendants().forEach((d, i) => {
        d.y = d.depth * (Math.max(regularNodeSize.width, criticalNodeSize.width) + 25);
        d.x = d.x * 0.5; // Reduce vertical spacing by multiplying by 0.8 instead of 1.2
    });

    // Create a group for the entire chart
    const g = svg.append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

   // Add links
const link = g.selectAll(".link")
.data(root.links())
.enter().append("path")
.attr("class", "link")
.attr("d", d => {
    const sourceX = d.source.x;
    const sourceY = d.source.y;
    const targetX = d.target.x;
    const targetY = d.target.y;
    
    // Calculate the midpoint between source and target
    const midY = (sourceY + targetY) / 2;
    
    // Create a step-like path
    return `
        M ${sourceY},${sourceX}
        H ${midY}
        V ${targetX}
        H ${targetY}
    `;
})
.attr("fill", "none")
.attr("stroke", d => {
    if (!_.isEmpty(d.target.data.severityCategory)) {
        if (d.target.data.severityCategory.toUpperCase() === 'CRITICAL') {
            return 'url(#animate-gradient)';
        }
    }
    return 'url(#simple-gradient)';
})
.attr("stroke-width", 2)
.attr("stroke-opacity", 1);

    // Add nodes
    const node = g.selectAll(".node")
        .data(root.descendants())
        .enter().append("g")
        .attr("class", "node")
        .attr("transform", d => `translate(${d.y},${d.x})`)
        .on("click", (event, d) => {
            event.preventDefault();
            if(d.depth !== 0){
                triggerNodeClick(d.data); // Assuming the name is stored in d.data.name
            }
        })
        

        const defs = svg.append('defs');

        GlobalConst.AI_CHART_COLORS.forEach((mapColor, index) => {
           
            const linearGradient = defs.append('linearGradient')
                .attr('id', `gradient${index}`)
                // .attr('gradientUnits', 'userSpaceOnUse')
                .attr('inkscapeCollect', 'always')
                .attr('y2', '100%')
                .attr('x2', '0')
                .attr('y1', '0')
                .attr('x1', '0');
            linearGradient.append('stop')
                .attr('style', `stop-color:${mapColor}`)
                .attr('offset', '30%');
            linearGradient.append('stop')
                .attr('style', `stop-color:${GlobalConst.AI_CHART_GRADIENT_COLORS[index]}`)
                .attr('offset', '100%');
        });

// Add semi-transparent colored rectangles on top
node.append("rect")
.attr("width", d => d.data.severityCategory && d.data.severityCategory.toUpperCase() === 'CRITICAL' ? criticalNodeSize.width  - 6 : regularNodeSize.width  - 6)
.attr("height", d => d.data.severityCategory && d.data.severityCategory.toUpperCase() === 'CRITICAL' ? criticalNodeSize.height  - 6 : regularNodeSize.height  - 6)
.attr("x", d => -(d.data.severityCategory && d.data.severityCategory.toUpperCase() === 'CRITICAL' ? criticalNodeSize.width : regularNodeSize.width) / 2 + 3)
.attr("y", d => -(d.data.severityCategory && d.data.severityCategory.toUpperCase() === 'CRITICAL' ? criticalNodeSize.height : regularNodeSize.height) / 2 + 3)
.attr("rx", 3)
.attr("ry", 3)
.attr("fill", d => {
    if (!_.isEmpty(d.data.severityCategory)) {
        const sev = d.data.severityCategory.toUpperCase()
        switch (sev) {
            case 'CRITICAL':
                return 'url(#gradient0)';
            case 'HIGH':
                return 'url(#gradient1)';
            case 'MEDIUM':
                return 'url(#gradient2)';
            default:
                return 'url(#gradient3)';
        }
    }
    return "#555";  // Default color
})
.style("cursor", "pointer")  // Add this line to change cursor on hover

const getNodeSize = d => d.data.severityCategory && d.data.severityCategory.toUpperCase() === 'CRITICAL' ? criticalNodeSize : regularNodeSize;

  // Add separate dashed border rectangles
  node.append("rect")
.attr("width", d => d.data.severityCategory && d.data.severityCategory.toUpperCase() === 'CRITICAL' ? criticalNodeSize.width : regularNodeSize.width)
.attr("height", d => d.data.severityCategory && d.data.severityCategory.toUpperCase() === 'CRITICAL' ? criticalNodeSize.height : regularNodeSize.height)
.attr("x", d => -(d.data.severityCategory && d.data.severityCategory.toUpperCase() === 'CRITICAL' ? criticalNodeSize.width : regularNodeSize.width) / 2)
.attr("y", d => -(d.data.severityCategory && d.data.severityCategory.toUpperCase() === 'CRITICAL' ? criticalNodeSize.height : regularNodeSize.height) / 2)
.attr("rx", 5)
.attr("ry", 5)
.attr("fill", "none")
.attr("stroke", d => {
  if (!_.isEmpty(d.data.severityCategory)) {
    const sev = d.data.severityCategory.toUpperCase()
    switch (sev) {
      case 'CRITICAL':
        return 'url(#gradient0)';
      case 'HIGH':
        return 'url(#gradient1)';
      case 'MEDIUM':
        return 'url(#gradient2)';
      default:
        return 'url(#gradient3)';
    }
  }
  return "#555";  // Default color
})
.attr("stroke-width", d => d.data.severityCategory && d.data.severityCategory.toUpperCase() === 'CRITICAL' ? 1.5 : 0)
.attr("stroke-dasharray", d => d.data.severityCategory && d.data.severityCategory.toUpperCase() === 'CRITICAL' ? "3,3" : "0")
.each(function(d) {
    const rect = d3.select(this);
    const totalLength = (getNodeSize(d).width + getNodeSize(d).height) * 2;
    
    rect.append("animate")
        .attr("attributeName", "stroke-dashoffset")
        .attr("values", `${totalLength};0`)
        .attr("dur", "20s")
        .attr("repeatCount", "indefinite");
})

    node.append("title")
            .text(d => d.data.name);

    // Add text to nodes
    node.append("text")
        .attr("dy", ".35em")
        .attr("text-anchor", "middle")
        .text(d => d.data.name)
        .attr("fill", "white")
        .style("font-size", d => d.data.severityCategory ? "12px" : "10px")
        .style("font-weight", d => d.data.severityCategory ? "bold" : "normal")
        .style("line-weight", d => d.data.severityCategory && d.data.severityCategory.toUpperCase() === 'CRITICAL' ? "16px" : "16px")
        .each(function(d) {
            const textWidth = this.getComputedTextLength();
            const boxWidth = (d.data.severityCategory && d.data.severityCategory.toUpperCase() === 'CRITICAL' ? criticalNodeSize.width : regularNodeSize.width) - 20;            
            if (textWidth > boxWidth) {
                d3.select(this).text(function(d) {
                    return d.data.name.slice(0, Math.floor(d.data.name.length * boxWidth / textWidth)) + '...';
                });
            }
        })
        .attr("opacity", d => (d.data.severityCategory ? 1 : 0.6))
        .style("cursor", "pointer")  // Add this line to change cursor on hover


    // Calculate the bounds of the tree
    let left = Infinity;
    let right = -Infinity;
    let top = Infinity;
    let bottom = -Infinity;
    root.each(d => {
        if (d.x < top) top = d.x;
        if (d.x > bottom) bottom = d.x;
        if (d.y < left) left = d.y;
        if (d.y > right) right = d.y;
    });

    // Set the viewBox to fit the tree with additional padding
    const viewBoxWidth = right - left + nodeSize.width + margin.left + margin.right + 100; // Added 100 for extra padding
    const viewBoxHeight = bottom - top + nodeSize.height + margin.top + margin.bottom + 100; // Added 100 for extra padding
    svg.attr("viewBox", `${left - margin.left - 50} ${top - margin.top - 50} ${viewBoxWidth} ${viewBoxHeight}`)
    .attr("font-size", 10);

    // Simple gradient
    const simpleGradient = defs.append("linearGradient")
        .attr("id", "simple-gradient")
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "100%")
        .attr("y2", "0")
        .attr("spreadMethod", "reflect");

    const simpleColors = ["#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874"];

    simpleGradient.selectAll("stop")
        .data(simpleColors)
        .enter().append("stop")
        .attr("offset", (d, i) => i / (simpleColors.length - 1))
        .attr("stop-color", d => d);

    // Animated gradient
    const animatedGradient = defs.append("linearGradient")
        .attr("id", "animate-gradient")
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "100%")
        .attr("y2", "0")
        .attr("spreadMethod", "reflect");

    const animatedColors = ["#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#ed3608", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874", "#005874"];

    animatedGradient.selectAll("stop")
        .data(animatedColors)
        .enter().append("stop")
        .attr("offset", (d, i) => i / (animatedColors.length - 1))
        .attr("stop-color", d => d);

    animatedGradient.append("animate")
        .attr("attributeName", "x1")
        .attr("values", "0%;200%")
        .attr("dur", "12s")
        .attr("repeatCount", "indefinite");

    animatedGradient.append("animate")
        .attr("attributeName", "x2")
        .attr("values", "100%;300%")
        .attr("dur", "12s")
        .attr("repeatCount", "indefinite");

    return svg.node();
}

export default function TidyTreeChart({ data ,options, triggerNodeClick}) {
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const resizeTimeoutRef = useRef(null);

    const handleResize = useCallback(() => {
        if (containerRef.current) {
            const { width, height } = containerRef.current.getBoundingClientRect();
            setDimensions({ width, height });
        }
    }, []);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            if (entries[0]) {
                // Clear the previous timeout
                if (resizeTimeoutRef.current) {
                    clearTimeout(resizeTimeoutRef.current);
                }
                // Set a new timeout
                resizeTimeoutRef.current = setTimeout(() => {
                    handleResize();
                }, 250); // Debounce for 250ms
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Initial size calculation
        handleResize();

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
            if (resizeTimeoutRef.current) {
                clearTimeout(resizeTimeoutRef.current);
            }
        };
    }, [handleResize]);

    const ref = useD3(
        (svg) => {
            if (dimensions.width > 0 && dimensions.height > 0) {
                createSVG(data,options, dimensions.width, dimensions.height, svg, triggerNodeClick);
            }
        },
        [data, dimensions]
    );

    return (
        <div ref={containerRef} style={{ width: '100%', height: '100%', minHeight: '600px' }}>
            {data && dimensions.width > 0 && dimensions.height > 0 ? (
                <svg
                    ref={ref}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                ></svg>
            ) : (
                <div>Loading...</div> // Add a loading indicator
            )}
        </div>
    );
}