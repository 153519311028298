import axios from "axios";
import { useEffect, useState } from "react";
import _ from 'lodash';
import { Tag, Steps, Tooltip } from "antd";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faCheckCircle, faCaretRight, faArrowUp, faArrowDown, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { formatScanKey, getFontColor, getStrokeColor } from '../../../shared/helper/genHelper';
import Loader from "../../../shared/sharedComponents/loader";
import { GlobalConst } from "../../../shared/appConfig/globalConst";
import { TiWarning } from "react-icons/ti";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";

export default function HistoryTab({ data, id }) {

    const [loading, setLoading] = useState(false);
    const [scanHistoryItems, setScanHistoryItems] = useState([]);

    useEffect(() => {
        if (!_.isEmpty(data)) {
            getHistoryData();
        }
    }, [data]);

    const getHistoryData = () => {
        const postBody = {
            "integrationId": data.integrationId
        };
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/asset/history`, postBody)
            .then(op => {
                setLoading(false);
                updateScanHistoryItems(op.data);
            })
            .catch(e => { setLoading(false); })
    }

    const handleShowReportClick = (reportId) => {
        window.location.href = `/cloud-security-advance-dashboard/${reportId}`;
    }

    const scanStatusList = (scanStatuses) => {
        if (!scanStatuses || Object.keys(scanStatuses).length === 0) {
            return null;
        }

        return (
            <ul className="list-group list-group-flush">
                {Object.entries(scanStatuses).map(([key, value]) => (
                    <li key={key} className="list-group-item bg-transparent p-2">
                        <div className='d-flex align-items-center justify-content-start'>
                            {value.status.toLowerCase() === 'completed' ? <div className='me-1'><IoCheckmarkDoneSharp className='text-success' /></div> : <div className='me-1'><IoIosCloseCircleOutline className='text-danger' /></div>}
                            <div><strong>{formatScanKey(key)}</strong></div>
                        </div>
                        <div>
                            (Last Scan: {moment.utc(value.scanTime).local().fromNow()})
                        </div>
                        {value.errorMessage && (
                            <div className="text-danger">
                                Error: {value.errorMessage}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        );
    };

    const getScanStatus = (status, statuses) => {
        if (!_.isEmpty(status)) {
            if (status.toLowerCase() === 'failed') {
                return <Tooltip title={scanStatusList(statuses)}>
                    <span><FontAwesomeIcon className="text-danger" icon={faTimesCircle} /></span>
                </Tooltip>;
            }
            else if (status.toLowerCase() === 'unknown') {
                return '';
            }
            else if (status.toLowerCase() === 'partial') {
                return <Tooltip title={scanStatusList(statuses)}>
                    <span><TiWarning className="text-warning" /></span>
                </Tooltip>;
            }
        }
        else {
            return '';
        }
    }

    const updateScanHistoryItems = (historyData) => {
        // console.log("History Data", historyData);
        let data = [];
        if (!_.isEmpty(historyData)) {
            // console.log("Inside the if block", historyData);
            // const dataArray = _.map(historyData, (value, date) => ({ date, ...value }));
            const dataArray = _.cloneDeep(historyData);

            // Sort the array by date
            const descendingArray = _.orderBy(dataArray, ['updatedAt'], ['asc']);
            // console.log("descendingArray", descendingArray);

            let lastSuccessTotalCVE = 0;
            let newDataArray = [];
            // newDataArray = newDataArray.reverse();
            descendingArray.forEach((el, key) => {
                let delta = null;
                const total = el.critical + el.high + el.medium + el.low;
                if (total > lastSuccessTotalCVE) {
                    delta = key > 0 ? 'up' : null;
                } else if (total < lastSuccessTotalCVE) {
                    delta = key > 0 ? 'down' : null;
                }
                // console.log("I am failing here");
                newDataArray.push({ ...el, delta, deltaDiff: Math.abs(lastSuccessTotalCVE - total) });
                // console.log("I am failed here");
                if (_.has(el, "status") && (el.status.toLowerCase() != 'failed')) {
                    lastSuccessTotalCVE = total;
                }
            });
            // console.log("newDataArray - 1", newDataArray);
            newDataArray = newDataArray.reverse();
            // console.log("newDataArray", newDataArray);
            _.forEach(newDataArray, (value, key) => {
                if (!_.isEmpty(value)) {
                    // console.log("value", value);
                    data.push({
                        title: <div style={{ fontSize: 14 }} className={`d-flex align-items-center justify-content-center flex-row px-3 py-1 card card-custom timelineCard ${(!_.isEmpty(id) && id === value.reportId) ? 'active' : ''} ${(value.status.toLowerCase() === 'failed') ? 'disabled' : ''}`} >
                            <div style={{ minWidth: 230 }} className="d-flex align-items-center justify-content-between">
                                <div>
                                    {(!_.isEmpty(id) && id === value.reportId) && <FontAwesomeIcon className="me-2 text-primary-emphasis" icon={faCaretRight} />}  {moment.utc(value.updatedAt).local().fromNow()}
                                    {(!_.isEmpty(value.branch)) && <span className="ms-2 text-secondary"><i>&lt;{value.branch}&gt;</i></span>}
                                    {/* {(value.status.toLowerCase() === 'failed') && <Tooltip title={value.errorMessage}><FontAwesomeIcon className="ms-2 text-danger" icon={faCircleXmark} style={{ fontSize: 12 }} /></Tooltip>} */}
                                    {(!_.isEmpty(value.status) && (value.status.toLowerCase() === 'failed') || value.status.toLowerCase() === 'unknown' || value.status.toLowerCase() === 'partial') &&
                                        <span className="ms-2">
                                            {getScanStatus(value.status, value.scanStatuses)}
                                        </span>
                                    }



                                    {(value.status.toLowerCase() === 'completed') && <Tooltip title={"Scan Success"}><FontAwesomeIcon className="ms-2 text-success" icon={faCheckCircle} style={{ fontSize: 12 }} /></Tooltip>}
                                </div>
                                <div>
                                    {value.delta === 'up' && <Tag><FontAwesomeIcon icon={faArrowUp} className="text-danger me-1" /> {value.deltaDiff}</Tag>}
                                    {value.delta === 'down' && <Tag><FontAwesomeIcon icon={faArrowDown} className="text-success me-1" /> {value.deltaDiff}</Tag>}
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <div style={{ minWidth: 460 }}>
                                    <Tag className="ms-2">{value.critical + value.high + value.medium + value.low} Total vulnerabilities</Tag>
                                    <Tag className="ms-2" style={{ color: getFontColor('CRITICAL'), fontWeight: 700 }} color={getStrokeColor('CRITICAL')}>{value.critical} Critical</Tag>
                                    <Tag style={{ color: getFontColor('HIGH'), fontWeight: 700 }} color={getStrokeColor('HIGH')}>{value.high} High</Tag>
                                    <Tag style={{ color: getFontColor('MEDIUM'), fontWeight: 700 }} color={getStrokeColor('MEDIUM')}>{value.medium} Medium</Tag>
                                    <Tag style={{ color: getFontColor('LOW'), fontWeight: 700 }} color={getStrokeColor('LOW')}>{value.low} Low</Tag>
                                </div>
                                <div className="text-secondary-emphasis" style={{ fontSize: 12 }}><small><i><b>Run by</b> - {value.userId}</i></small></div>
                                {(value.status.toLowerCase() === 'completed') && <a className="ms-5 btn-link" onClick={(e) => { e.preventDefault(); handleShowReportClick(value.reportId) }} href="#">View Report</a>}
                            </div>
                        </div>,
                        description: <></>,
                    });
                }
            });
            // console.log("I am the final data here ", data);
            setScanHistoryItems(data);
        }
    }

    if (loading) {
        return <Loader />
    }
    else {
        return (
            <section className="my-2">
                <h3 className="text-white">Scan History</h3>
                <div className="mt-3">
                    {/* {console.log("scanHistoryItems", scanHistoryItems)} */}
                    <Steps
                        progressDot
                        current={0}
                        direction="vertical"
                        className="text-white scan-history-timeline"
                        items={scanHistoryItems}
                    />
                </div>
            </section>
        )
    }
}