import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTrowelBricks, faCheck, faInfoCircle, faEdit, faPlusCircle, faGear, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import kubernetes from "../../assets/img/kubernetes.svg";
import _ from 'lodash';
import { Link } from "react-router-dom";
import { Button, Popconfirm, Select, Space, Tag, Tooltip } from "antd";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import OnPremise from "./onPremise";
import Loader from "../../shared/sharedComponents/loader";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { notification } from 'antd';
import axios from "axios";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import * as Yup from "yup";
import { useFormik } from "formik";
import oracle from "../../assets/img/techIcons/oracle.png";
import awsCi from "../../assets/img/scopy-tech-icons/aws.svg";
import googleCloud from "../../assets/img/scopy-tech-icons/gcp.svg";
import azure from "../../assets/img/scopy-tech-icons/azure.svg";
import { useHistory } from "react-router-dom";

export default function IntegrateKubernetes() {
    const [configs, setConfigs] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory()

    useEffect(() => {
        fetchConfigurations();
    }, []);


    const fetchConfigurations = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${GlobalConst.API_URL}/auth/integrate/user-integrations?v2=true`);
            setConfigs(response.data.k8s);
        } catch (error) {
            setConfigs(null)
            notification.error({
                message: 'Error',
                description: "Failed to fetch configurations.",
                duration: 5,
            });
        }
        setLoading(false);
    };

    const handleEditConfig = (config) => {
        history.push(`/integrations-list/k8s-config/${config.integrationId}`)
    };

    const handleAddNewConfig = () => {
        history.push(`/integrations-list/k8s-config`)
    };

    const renderConfigList = () => {
        return configs.map((config, index) => (
            <div className="col-sm-2" key={index}>
                <div
                    className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card"
                    onClick={() => handleEditConfig(config)}
                >
                    {!_.isEmpty(config) &&
                        config.isIntegrated && (
                            <button
                                onClick={() => handleEditConfig(config)}
                                type="button"
                                className="btn btn-dark btn-sm"
                                style={{ position: "absolute", top: 2, right: 2 }}
                            >
                                <FontAwesomeIcon icon={faGear} />
                            </button>
                        )}
                    <div className="source-control-image-container">
                        <img
                            src={kubernetes}
                            alt="Kubernetes"
                            className="img-fluid"
                        />
                    </div>
                    <h5 className="mb-2 pt-3">{config.clusterName}</h5>
                </div>
            </div>
        ))
    }

    return (
        <section className="mx-3">
            <section>
                <div className="mb-4">
                    <div className="">
                        <div className="mb-3">
                            <Link to="/integrations-list"><FontAwesomeIcon className="me-2" icon={faArrowLeft} />Back to integrations</Link>
                        </div>
                        <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-2 text-white d-flex align-items-center justify-content-start">
                            <FontAwesomeIcon icon={faTrowelBricks} className="me-2" />
                            Integrations
                        </h2>
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/integrations-list">Integrations</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Kubernetes</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className={'mb-5'}>
                    <div className="row">
                        {!_.isEmpty(configs) && renderConfigList()}
                        <div className="col-sm-2">
                            <div
                                className="card custom-card p-2 pt-4 text-center d-flex align-items-center justify-content-center source-control-card"
                                style={{ background: "#326CE5" }}
                                onClick={handleAddNewConfig}
                            >
                                <div className="source-control-image-container" style={{ background: "transparent" }}>
                                    <FontAwesomeIcon icon={faPlusSquare} />
                                </div>
                                <h5 className="mb-2 pt-3">Add Cluster</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {loading && <Loader />}
        </section >
    );
}