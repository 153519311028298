import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Space, Menu, Typography } from 'antd';
import _ from "lodash"
import { useState } from 'react';

const GetRecFixRow = ({ val, row }) => {

  // console.log("Row is :", row, val);

  const [selectedVersion, setSelectedVersion] = useState((!_.isEmpty(val) && !_.isEmpty(val[0])) ? val[0] : '');

  const getDropdownMenuItems = (stableVersionList) => {
    let menuItems = [];
    if (!_.isEmpty(stableVersionList)) {
      stableVersionList.forEach((elem, index) => {
        menuItems.push({
          key: `${elem}`,
          label: `${elem}`
        })
      })
    }
    return menuItems;
  }

  const getCountStatement = (count) => {
    if (!_.isEmpty(count)) {
      let c = getIntroduceCount();
      return (<small className='text-muted d-block'>
        (this will fix {count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW} vulnerabilit{(count.CRITICAL + count.HIGH + count.MEDIUM + count.LOW) > 1 ? 'ies' : 'y'}
        {(c) ? ` and will introduce ${c} vulnerabilit${c > 1 ? 'ies' : 'y'}` : ''})
      </small>);
    }
  }

  const getIntroduceCount = () => {
    if (!_.isEmpty(row) && !_.isEmpty(row.introducedCveDetails)) {
      let objectVal = row.introducedCveDetails[selectedVersion];
      if (!_.isEmpty(objectVal) && !_.isEmpty(objectVal.cveCountDetails)) {
        let c = 0;
        _.forIn(objectVal.cveCountDetails, (val, key) => c = c + val)
        return c;
      }
      else {
        return 0;
      }
    }
    else {
      return 0;
    }
  }

  const handleDropdownMenuClick = ({ key }) => {
    if (!_.isEmpty(key) && selectedVersion !== key) {
      setSelectedVersion(key);
    }
  };

  const getCount = (rowData) => {
    // console.log("I am new data:", rowData);
    let count = { CRITICAL: 0, HIGH: 0, MEDIUM: 0, LOW: 0, NA: 0 };
    if (!_.isEmpty(rowData) && !_.isEmpty(rowData.cves)) {
      rowData.cves.forEach((elem) => {
        if (!_.isEmpty(elem)) {
          if (!_.isEmpty(elem.cvssV3BaseSeverity)) {
            count[elem.cvssV3BaseSeverity] = count[elem.cvssV3BaseSeverity] + 1;
          }
          else {
            if (!_.isEmpty(elem.cvssV2BaseSeverity)) {
              count[elem.cvssV2BaseSeverity] = count[elem.cvssV2BaseSeverity] + 1;
            }
            else {
              count['NA'] = count["NA"] + 1;
            }
          }
        }
      });
    }
    return count;
  }

  let count = getCount(row);


  if (_.isEmpty(val)) {
    return (<><span className='text-danger'>No fix available</span></>);
  }

  if (!_.isEmpty(val) && val.length > 1) {
    return (<>
      <Dropdown overlay={<Menu selectable defaultSelectedKeys={[selectedVersion]} onClick={handleDropdownMenuClick} items={getDropdownMenuItems(val)} className={'versionMenuList scroll-style'} />} trigger={['click']}>
        <Typography.Link>
          <Space>
            <span style={{ display: 'flex', maxWidth: '12rem', color: '#adb5bd', alignItems: 'center' }}>
              <span className="text-truncate" style={{ color: '#adb5bd', fontSize: 14, fontFamily: 'Open Sans' }}>{selectedVersion}</span>
              <CaretDownOutlined className="ms-1" />
            </span>
          </Space>
        </Typography.Link>
      </Dropdown>
      {/* {!_.isEmpty(val) && getCountStatement(count)} */}
      </>);
  }
  else {
    return <span>{val[0]} 
    {/* {!_.isEmpty(val) && getCountStatement(count)} */}
    </span>;
  }
}

export default GetRecFixRow