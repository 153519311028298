import Modal from 'react-bootstrap/Modal';
import OAuth2Fields from './oAuth2Fields';
import NoAuthFields from './noAuth';
import UsernamePasswordFields from './usernamePasswordFields';

export default function EditConfig({ showEditConfig, handleEditConfigClose, reportData }) {

    const getAuthField = () => {
        if (reportData.authType === 'NO-AUTH') {
            return (<NoAuthFields reportData={reportData} handleEditConfigClose={handleEditConfigClose} />);
        }
        else if (reportData.authType === 'OAUTH2') {
            return (<OAuth2Fields handleEditConfigClose={handleEditConfigClose} reportData={reportData} />);
        }
        else {
            return (<UsernamePasswordFields handleEditConfigClose={handleEditConfigClose} reportData={reportData} />)
        }
    }

    return (
        <Modal show={showEditConfig} onHide={handleEditConfigClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title as={'h5'}>Config for - {reportData.url}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {getAuthField()}
            </Modal.Body>
        </Modal>
    );
}