import { useEffect, useState } from "react";
import _ from 'lodash';
import axios from "axios";
import { TbTextScan2 } from "react-icons/tb";
import { GlobalConst } from "../../../shared/appConfig/globalConst";
import NoDataFound from "../../../shared/sharedComponents/noDataFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Tag } from "antd";
import { useDispatch } from "react-redux";
import { addUpdateCloudSecurityDashboardTableData, setIScanModalState } from "../../../shared/redux/reducers/cloudSecurityReducer";

export default function GCPTab({ integration, loading, setLoading }) {

    const dispatch = useDispatch();
    const [gcpDetails, setGcpDetails] = useState({});

    useEffect(() => {
        if (!_.isEmpty(integration) && !_.isEmpty(integration.integrationId)) {
            getIntegrationDetails(integration.integrationId);
        }
    }, []);

    const getIntegrationDetails = (integrationId) => {

        const postData = {
            "integrationId": integrationId,
            "targetSystem": integration.targetSystem
        }
        setGcpDetails({});
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/integration/cloud/fetch`, postData)
            .then(op => {

                if (!_.isEmpty(op.data) && !_.isEmpty(op.data.config) && !_.isEmpty(op.data.config.gcp)) {
                    setGcpDetails({ 'gcp': op.data.config.gcp });
                }
                else {
                    setGcpDetails({});
                }
            })
            .catch(e => {
                console.log("Exception: ", e);
                setGcpDetails({});
            })
            .finally(o => {
                setLoading(false);
            })
    }

    const handleScanClick = (e, projectId) => {

        let isDemoUser = localStorage.getItem('isDemoUser');
        if (isDemoUser && isDemoUser === 'true') {
            alert("Scan has been disabled for this account");
        }
        else {
            setLoading(true);

            const postData = {
                "integrationId": integration.integrationId,
                "targetSystem": integration.targetSystem
            };

            axios
                .post(`${GlobalConst.API_URL}/auth/cloud-api/ascan`, postData)
                .then(op => {
                    // console.log("Output ", op);
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        let newData = {
                            ...op.data,
                            "integrationType": integration.targetSystem,
                            "projectId": projectId,
                        }
                        // console.log("I am the scan data", newData);

                        // return ;
                        dispatch(addUpdateCloudSecurityDashboardTableData(newData));
                        dispatch(setIScanModalState(false));
                        // return ;
                        window.location.reload();
                    }
                })
                .catch(e => {
                    // console.log("Exception: ", e);
                })
                .finally(o => {
                    setLoading(false);
                });
        }
    }

    if (_.isEmpty(gcpDetails)) {
        if (loading) {
            return ('Loading GCP Project Details .... ');
        }
        else {
            return (<NoDataFound title={"No Data"} />);
        }
    }
    else {
        return (
            <>
                <h6 className="mb-3">List of all configured GCP Projects</h6>
                <div className="card custom-card">
                    <div className="card-body">
                        <table className="table table-hover table-sm mb-0">
                            <thead>
                                <tr>
                                    <th className="bg-transparent">Sno</th>
                                    <th className="bg-transparent">Project</th>
                                    <th className="bg-transparent">Status</th>
                                    <th className="bg-transparent">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.entries(gcpDetails).map(([key, value], index) =>
                                        <tr key={`cloud-sec-${key}`}>
                                            <td className="bg-transparent">{index + 1}</td>
                                            <td className="bg-transparent">{value.projectId}</td>
                                            <td className="bg-transparent"><Tag color="rgb(25,135,84)"><FontAwesomeIcon className="me-2" icon={faCheck} />Connected</Tag></td>
                                            <td className="bg-transparent"><button className="btn btn-primary btn-sm" onClick={(e) => handleScanClick(e, value.projectId)}><TbTextScan2 className={'me-1'} />Scan</button></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}