import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import _ from 'lodash';
import NoDataFound from "../../shared/sharedComponents/noDataFound";


export default function PieChart({ data, graphTitle, legendHeading, hideLegend, onPieClick }) {

    const [options, setOptions] = useState({
        title: {
            text: graphTitle,
            textStyle: {
                color: '#ffffff',
            }
        },
        tooltip: {
            trigger: 'item'
        },
        legend: (hideLegend) ? false : {
            // top: 'bottom',
            // textStyle: {
            //     color: '#ffffff',
            // }
            top: 'bottom',
            bottom: 20, // Add padding from the bottom
            padding: [0, 0, 0, 0], // Add padding around the legend [top, right, bottom, left]
            itemGap: 20, // Increase space between legend items
            textStyle: {
                color: '#ffffff',
                padding: 8  // Add padding around legend text
            },
        },
        series: [],
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top: '12%',
            containLabel: true
        }
    });

    useEffect(() => {
        if (!_.isEmpty(data)) {
            // console.log("I am the data here", data);
            let series = [
                {
                    name: legendHeading,
                    type: 'pie',
                    radius: ['40%', '70%'],
                    center: ['50%', '50%'],  // Moved chart up more
                    avoidLabelOverlap: false,
                    padAngle: 2,
                    itemStyle: {
                        borderRadius: 8
                    },
                    label: {
                        textStyle: {
                            color: '#ffffff'
                        }
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 18,
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: true
                    },
                    data: data.map((item, index) => ({
                        ...item,
                        value: item.value,
                        name: item.name,
                        itemStyle: {
                            color: getColorByIndex(index)
                        }
                    }))
                }
            ];
            setOptions({ ...options, series: series });
        }
    }, [data]);

    const getColorByIndex = (index) => {
        const colors = ['#2b75e6', '#2dcb72', '#49b0c1', '#ff6c6c', '#4caf50'];
        return colors[index % colors.length];
    };


    // Event handlers
    const onEvents = {
        'click': (params) => {
            if (onPieClick) {
                onPieClick(params.data);
            }
        }
    };

    if (_.isEmpty(data)) {
        return (<NoDataFound title={'No Data'} />);
    }
    else {
        return (
            <ReactEcharts option={options} onEvents={onEvents}
            />
        );
    }
}