import React, { useState } from "react";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FreePlanList({ planType }) {

    const handleChangePlanClick = (e) => {
        e.preventDefault();
    }

    
    return (
        <div className="card pricing-box card-animate shadow-lg border-0 border-top border-2 border-success" style={{ borderRadius: 8 }}>
            <div className="card-body m-2 p-4">
                <div className="mb-0">
                    <div className="">
                        <h3 className="mb-0 fw-semibold">Free</h3>
                    </div>
                    <p className="text-muted">For individual developers</p>
                </div>
                <hr />
                <h5 className="mb-2 fw-semibold">Products</h5>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Scopy - SCA</li>
                    <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> XIL - Automated Fixes</li>
                    <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Contek - Container Scanning</li>
                    <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> CISO Console</li>
                    <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> SBOM Scanner</li>
                    <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> CLI</li>
                    <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Galaxy Guard - Vulnerability Database</li>
                </ul>
                <hr />
                <div style={{ height: 350 }}>
                    <h5 className="mb-2 fw-semibold">Other Features</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Limited Scans (50 per {planType==='monthly' ? 'month' : 'year'})</li>
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Support for SCM - GitHub, Azure, GitLab</li>
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faCheck} className="me-2 text-success" /> Integration with CI tools - Jenkins, Azure DevOps, GitHub actions, GitLab CI/CD</li>
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faTimes} className="me-2 text-danger" /> Manage Team</li>
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faTimes} className="me-2 text-danger" /> Team Dashboard</li>
                        <li className="list-group-item border-bottom-0"><FontAwesomeIcon icon={faTimes} className="me-2 text-danger" /> Support</li>
                    </ul>
                </div>
                <hr />
                <div className="ms-auto text-center d-flex align-items-center justify-content-end flex-column" style={{height: 120}}>
                    <h2 className="mb-0">$0 </h2>
                    <small className="fs-13 text-muted">per user/{planType==='monthly' ? 'Month' : 'Year'}</small>
                </div>

                <div className="mt-3 pt-2">
                    <a href="#" onClick={handleChangePlanClick} className="btn btn-danger disabled w-100">Your Current Plan</a>
                </div>
            </div>
        </div>
    );
}