import React, { useEffect, useState } from "react";
import _ from "lodash";
import ThreatCircleGraph from "./circleGraph";
import Dropdown from "react-bootstrap/Dropdown";
import {
  faCircle,
  faCircleExclamation,
  faExclamation,
  faFilter,
  faTriangleExclamation,
  faRotate,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { faCircle as faEmptyCircle } from "@fortawesome/free-regular-svg-icons";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Switch, Button, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ComponentSearchList from "./componentSearchList";
import WelcomeLoader from "../welcomeScreen/loader";
import ComponentFlowTree from "./componentTreeView";
import TidyTreeView from "./tidyTree";
import { useHistory } from "react-router-dom";

export default function ThreatGraph({ scanning, onSelectedPackage, rescan, setShowAllComponents, showAllComponents, dependencyGraph, loading }) {
  const [hideLabel, setHideLabel] = useState(false);
  const scannedPackages = useSelector(
    (state) => state.threatVisionReducer.assetScanDependencyReport
  );
  const history = useHistory();

  const onChange = (checked) => {
    setShowAllComponents(checked);
  };

  const getReportName = (location) => {
    if (isValidHttpUrl(location)) {
      var pathname = new URL(location).pathname;
      pathname = pathname.substring(1);
      if (scannedPackages.assetSubtype === "azure-scm") {
        location = `${location}?version=GB${scannedPackages.branch}`;
      }
      return (
        <Tooltip title={location}>
          <a href={location} target="_blank" rel="noreferrer noopener">
            {!_.isEmpty(pathname) ? decodeURIComponent(pathname) : location}
          </a>
        </Tooltip>
      );
    } else {
      return <Tooltip title={location}>{location}</Tooltip>;
    }
  };

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (e) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const goBack = (e) => {
    history.goBack();
  }

  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-start mb-2">
          <h2 style={{ lineHeight: 1 }}  className="sotcox-title mb-0 text-white d-flex align-items-center justify-content-start me-2" style={{ minWidth: 200 }}>
            <a className='me-2' onClick={goBack} href='#' style={{ fontSize: 22 }}>
                            <small ><FontAwesomeIcon icon={faArrowLeft} /></small>
                        </a>
              <span className='ps-2' style={{ borderLeft: '1px solid rgba(108, 117, 125, 15%' }}>Scan Report</span>
          </h2>
          <Button
            className="custom-button btn-sm ms-3"
            onClick={() => rescan(scannedPackages)}
            loading={scanning}
          >
            <FontAwesomeIcon icon={faRotate} className="me-2" />
            Re-Scan
          </Button>
          <div className=" ps-2 gap-2 ms-auto">
            <Switch
              className="ms-auto me-1"
              checked={showAllComponents}
              onChange={onChange}
            />{" "}
            Show all components
          </div>
          {scanning && (
            <span className="d-flex align-items-center justify-content-start ms-3">
              <WelcomeLoader />{" "}
              <small className="ms-1" style={{ fontSize: 14, fontWeight: 200 }}>
                Scanning...
              </small>
            </span>
          )}
        </div>
        <div className=" mb-2">
          <div>
            {!_.isEmpty(scannedPackages) && (
              <span className="d-flex align-items-center justify-content-start">
                <span className="text-truncate me-2">
                  {getReportName(scannedPackages.assetUrl)}
                </span>
                <span>
                  <small className="d-block text-secondary" style={{}}>
                    <i>
                      (<strong>Last Scan:</strong>{" "}
                      {moment
                        .utc(scannedPackages.scanDateTime)
                        .local()
                        .fromNow()}
                      )
                    </i>
                  </small>
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="row">
          <div
            className="col-sm-12"
          >
            <div className="card custom-card shadow rounded mb-0">
              {/* <div
                className="d-flex align-items-center justify-content-end"
                style={{ position: "relative" }}
              >
                <div
                  className="text-white"
                  style={{ position: "absolute", top: 0, right: 0 }}
                >
                  <p className="mt-3 me-3 mb-0">Vulnerability Probability</p>
                  <ul className="list-group bg-transparent ">
                    <li className="list-group-item bg-transparent text-white p-2 legend-linkGraph border-0">
                      <span className="d-flex align-items-center justify-content-start">
                        <span className="d-flex align-items-center justify-content-center me-2 position-relative">
                          <FontAwesomeIcon
                            icon={faCircle}
                            style={{
                              fontSize: 20,
                              color: GlobalConst.AI_CHART_COLORS[0],
                              zIndex: 0,
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              fontSize: "12", // Adjust size as needed
                            }}
                          />
                        </span>{" "}
                        Very High{" "}
                      </span>
                    </li>
                    <li className="list-group-item bg-transparent text-white p-2 legend-linkGraph border-0">
                      <span className="d-flex align-items-center justify-content-start">
                        <span className="d-flex align-items-center justify-content-center  me-2 position-relative">
                          <FontAwesomeIcon
                            icon={faCircle}
                            style={{
                              fontSize: 20,
                              color: GlobalConst.AI_CHART_COLORS[1],
                              zIndex: 0,
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              fontSize: "12", // Adjust size as needed
                            }}
                          />
                        </span>{" "}
                        High{" "}
                      </span>
                    </li>
                    <li className="list-group-item bg-transparent text-white p-2 legend-linkGraph border-0">
                      <span className="d-flex align-items-center justify-content-start">
                        <span className="d-flex align-items-center justify-content-center  me-2 position-relative">
                          <FontAwesomeIcon
                            icon={faCircle}
                            style={{
                              fontSize: 20,
                              color: GlobalConst.AI_CHART_COLORS[2],
                              zIndex: 0,
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faExclamation}
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              fontSize: "12", // Adjust size as needed
                            }}
                          />
                        </span>{" "}
                        Medium{" "}
                      </span>
                    </li>
                    <li className="list-group-item bg-transparent text-white p-2 legend-linkGraph border-0">
                      <span className="d-flex align-items-center justify-content-start">
                        <span className="d-flex align-items-center justify-content-center  me-2 position-relative">
                          <FontAwesomeIcon
                            icon={faCircle}
                            style={{
                              color: GlobalConst.AI_CHART_COLORS[3],
                              zIndex: 0,
                              fontSize: 20,
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faEmptyCircle}
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              fontSize: "12", // Adjust size as needed
                            }}
                          />
                        </span>{" "}
                        Low{" "}
                      </span>
                    </li>
                  </ul>
                </div>
              </div> */}
              {!_.isEmpty(dependencyGraph) && !_.isEmpty(dependencyGraph.name)? (
                <>
                <TidyTreeView dataSet={dependencyGraph} onSelectedPackage={onSelectedPackage} />
                        {/* <ComponentFlowTree treeData={dependencyGraph} probabilityReport={scannedPackages.probabilityReport}/> */}
                </>
              ) : !loading ? (
                <div className="text-center mx-4 mt-4">
            <>
              <p className="text-white mb-4">
                <>
                  No dependency tree available
                </>
              </p>
            </>
          </div>
              ) : null}
            </div>
          </div>
          {/* <div className="col-sm-5">
            <ComponentSearchList
              showAllComponents={showAllComponents}
              onSelectedPackage={onSelectedPackage}
            />
          </div> */}
        </div>
      </div>
    </>
  );
}
