import React from "react";
import AppLayout from "./shared/layout/appLayout";

import { HelmetProvider } from "react-helmet-async";
import { ConfigProvider, theme } from 'antd';
// import { AuthProvider } from "./shared/hooks/AuthContext";
// import './shared/helper/axiosInterceptor';
// import { useSelector, useDispatch } from "react-redux";

// import { updateToken } from "./shared/redux/reducers/authReducer";
// import _ from 'lodash';


import "bootstrap/dist/css/bootstrap.min.css";
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import './assets/css/animate.min.css';
import './assets/css/main.css';
// import './assets/css/customIcons.css';
import './assets/css/mediaQuery.css';

import './shared/helper/javaScriptHelpers';

function App() {

  // useEffect(()=>{
  //   console.log("App...");
  // }, [])

  // const reduxState = useSelector(state => state);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   async function getToken() {
  //     let token = await localStorage.getItem("__t");
  //     return token;
  //   }
  //   let token = getToken();

  //   if (_.isEmpty(reduxState.authReducer.token) && !_.isEmpty(token)) {
  //     dispatch(updateToken(token));
  //   }
  // }, [])

  return (
    // <AuthProvider>
      <HelmetProvider>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#438eff',
              fontFamily: 'Arimo'
            },
            algorithm: [theme.darkAlgorithm]
          }}
        >
          <AppLayout />
        </ConfigProvider>
      </HelmetProvider>
    // </AuthProvider>
  );
}

export default App;