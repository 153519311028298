import _ from "lodash";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import VersionList from "./versionList";
import { getArtifactImage } from "../../shared/helper/getArtifactImageHelper";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Loader from "../../shared/sharedComponents/loader";
import VulnerabilityTimeline from "./scanThreatReport/componentVulTimeline";
import ComponentVulnTimeline from "./scanThreatReport/componentVulTimeline";
import { GlobalConst } from "../../shared/appConfig/globalConst";

const ShowPackageDetails = ({ selectedPackage, onCloseTreeModal, isDependencyTreeModalOpen }) => {
  const [productPage, setProductPage] = useState(1);
  const [productPageSize, setProductPageSize] = useState(25);
  const [packageDetails, setPackageDetails] = useState(null);
  const [loadingPackageDetails, setLoadingPackageDetails] = useState(null);
  const [componentCves, setComponentCves] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(selectedPackage)) {
      console.log(selectedPackage)
      getPackageAPI(getSubstringBeforeLastAt(selectedPackage.packageUrl));
    }
  }, [selectedPackage]);

  const getSubstringBeforeLastAt = (str) => {
    if (!str.includes('@')) return str;  // Return full string if no @ exists
    const regex = /(.+)(?=@[^@]*$)/;
    const match = str.match(regex);
    return match ? match[1] : str;
  }

  const getPackageAPI = (value) => {
    setLoadingPackageDetails(true)
    axios
      .get(`${GlobalConst.API_URL}/foss/cve/v1/search?s=${value.trim()}&em=true`)
      .then((response) => {
        setLoadingPackageDetails(false)
        if (response.data && !_.isEmpty(response.data.responseData)) {
          setComponentCves(response.data.responseData)
          // if (response.data.searchType === "component") {
            // let processedPackages = [];
            // response.data.responseData.forEach((cveInfo, index) => {
            //   cveInfo.impactedPackages.forEach((packageInfo) => {
            //     let existingPackage = _.find(processedPackages, {
            //       packageName: packageInfo.packageurl,
            //     });
            //     // If impacted package already existing
            //     if (existingPackage) {
            //       const packageVersions = !_.isEmpty(
            //         packageInfo.impactedVersions
            //       )
            //         ? [...packageInfo.impactedVersions]
            //         : !_.isEmpty(packageInfo.affectedRanges)
            //           ? [...packageInfo.affectedRanges]
            //           : [];
            //       // Find the new versions of an existing package
            //       const newVersions = _.difference(
            //         packageVersions,
            //         existingPackage.versionList
            //       );
            //       // Find the common impacted versions for existing package
            //       const alreadyExistingVersions = _.intersection(
            //         packageVersions,
            //         existingPackage.versionList
            //       );
            //       if (!_.isEmpty(alreadyExistingVersions)) {
            //         alreadyExistingVersions.forEach((existingVersion) => {
            //           existingPackage.versions = existingPackage.versions.map(
            //             (info) =>
            //               info.version === existingVersion
            //                 ? {
            //                   ...info,
            //                   vulnerabilities: [
            //                     ...info.vulnerabilities,
            //                     cveInfo,
            //                   ],
            //                 }
            //                 : info
            //           );
            //         });
            //       }
            //       newVersions.forEach((version) => {
            //         existingPackage.versions = [
            //           ...existingPackage.versions,
            //           {
            //             key: `${packageInfo.packageurl}@${version}`,
            //             version,
            //             vulnerabilities: [cveInfo],
            //           },
            //         ];
            //       });
            //       processedPackages = processedPackages.map((packageInfo) =>
            //         packageInfo.packageName === existingPackage.packageName
            //           ? {
            //             ...packageInfo,
            //             versions: [...existingPackage.versions],
            //             versionList: [
            //               ...existingPackage.versionList,
            //               ...newVersions,
            //             ],
            //           }
            //           : packageInfo
            //       );
            //     } else {
            //       let processedPackage = {
            //         key: `${packageInfo.packageurl}`,
            //         packageName: packageInfo.packageurl,
            //         versions: [],
            //         versionList: [],
            //       };

            //       if (!_.isEmpty(packageInfo.impactedVersions))
            //         processedPackage.versionList = [
            //           ...processedPackage.versionList,
            //           ...packageInfo.impactedVersions,
            //         ];
            //       else if (!_.isEmpty(packageInfo.affectedRanges))
            //         processedPackage.versionList = [
            //           ...processedPackage.versionList,
            //           ...packageInfo.affectedRanges,
            //         ];

            //       if (!_.isEmpty(processedPackage.versionList)) {
            //         processedPackage.versionList.forEach((version) => {
            //           processedPackage.versions = [
            //             ...processedPackage.versions,
            //             {
            //               key: `${packageInfo.packageurl}@${version}`,
            //               version,
            //               vulnerabilities: [cveInfo],
            //             },
            //           ];
            //         });
            //       }

            //       processedPackages = [...processedPackages, processedPackage];
            //     }
            //   });
            // });
            // setPackageDetails(processedPackages);
          // }
        }
      });
  };

  const getColumnText = (searchedColumn, text, row) => {
    if (searchedColumn === "packageName") {
      let [artifact, componentRaw] = text.split(/\/(.*)/s);
      let component = "";
      let imageTitle = "";
      if (artifact === "maven") {
        component = text.split("/").pop();
        // component = _.replace(componentRaw, "/", ":");
        imageTitle = "java";
      } else {
        component = text.split("/").pop();
        imageTitle = artifact;
      }
      return (
        <span className="d-flex align-items-center">
          <img
            src={getArtifactImage(artifact)}
            style={{ width: 25 }}
            className="me-1"
            title={imageTitle}
            alt={artifact}
          />{" "}
          <span className="d-flex align-items-center" title={component}>{component}<span className="ps-2" style={{ fontSize: 14 }}>({artifact})</span></span>
        </span>
      );
    } else {
      return text;
    }
  };


  const getPackageDetails = (val, type, artifact) => {
    if (val) {
      let splitedPkg = val.split(":");
      switch (type) {
        case "artifact":
          let sText = splitedPkg[1].split("/");
          return sText[2];
        case "group":
          let splitedText = splitedPkg[1].split("/");
          return splitedText[1];
        case "getEcosystem":
          let ecosystem = splitedPkg[1].split("/");
          return ecosystem[0];
        case "packageName":
          // if (artifact === "maven") {
          //   let strSplit = splitedPkg[1].split('/');
          //   return `${strSplit[0]}/${strSplit[1]}:${strSplit[2]}`;
          // }
          // else {
          //   // return splitedPkg[1];
          //   // console.log(splitedPkg[1].split(/\/(.*)/s));
          //   let splitTxt = splitedPkg[1].split(/\/(.*)/s)
          //   return splitTxt[1];
          // }
          return splitedPkg[1];
        case "getVersion":
          let version = splitedPkg[1].split("@");
          return version[1];
        default:
          return splitedPkg[2];
      }
    } else {
      return "";
    }
  };

  const splitPackageUrl = !_.isEmpty(selectedPackage.packageUrl) ? selectedPackage.packageUrl.split(":") : ""
  let [artifact, componentRaw] =  splitPackageUrl[1].split(/\/(.*)/s);
  let component = '';
  let imageTitle = '';
  if (artifact === 'maven') {
    if(!_.isEmpty(componentRaw)){
      component = _.replace(getSubstringBeforeLastAt(componentRaw), '/', ':');
    }
    imageTitle = 'java';
  }
  else {
    if(!_.isEmpty(componentRaw)){
      component = getSubstringBeforeLastAt(componentRaw);
    }
    imageTitle = artifact;
  }


  return <>
    <Modal show={isDependencyTreeModalOpen} onHide={onCloseTreeModal} size="lg" backdrop={'static'}>
      <Modal.Header closeButton>
        <Modal.Title><span><img src={getArtifactImage(artifact)} style={{ width: 25 }} className="me-1" title={imageTitle} alt={artifact} /> <span title={component}>{component}</span></span></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: 400, overflowY: "auto" }}>
          {!_.isEmpty(componentCves) ?
            <VulnerabilityTimeline cveList={componentCves} selectedPackage={selectedPackage}/>
            : !loadingPackageDetails ? <p className="text-center">No Data found</p> : null}
        </div>
      </Modal.Body>
    </Modal>
    {loadingPackageDetails && <Loader />}
  </>

};

export default ShowPackageDetails;
