import { TbTextScan2 } from "react-icons/tb";
import { InboxOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Button, message, Upload, Select, Input } from 'antd';
import { Dropdown } from 'antd';
import { useEffect, useState } from "react";
import _ from 'lodash';
import axios from "axios";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { useSelector } from "react-redux";
import Loader from "../../shared/sharedComponents/loader";

export default function VisaeroScanner() {

    const { Dragger } = Upload;
    const history = useHistory();
    const reduxState = useSelector(state => state);

    const [scannerText, setScannerText] = useState('SCA');
    const [applicationName, setApplicationName] = useState('');
    const [fileState, setFileState] = useState(null);

    const [loading, setLoading] = useState(false);
    const [loadingDashData, setLoadingDashData] = useState(false);

    const [items, setItems] = useState([]);

    const props = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        accept: '.json',
        onChange(info) {
            const { file, fileList } = info;

            if (fileList.length > 0) {
                const latestFile = fileList[fileList.length - 1];
                setFileState(latestFile.originFileObj);
            } else {
                setFileState(null);
            }
        },
        beforeUpload: (file) => {
            // Cancel the default upload behavior
            const isJSON = file.type === 'application/json' || file.name.endsWith('.json');
            if (!isJSON) {
                message.error('Please upload only JSON files');
            }
            return false;
        }
    };

    useEffect(() => {
        getSCAScanStats();
        // message.error('Please upload only JSON files');

    }, []);

    const getSCAScanStats = () => {
        setLoadingDashData(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/user/scan-stats`, {
                userId: reduxState.authReducer.userDetails.email,
                showOnlyTracingData: false
            })
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.assetList)) {
                    let ItemData = [];
                    _.map(op.data.assetList, (elem, name) => {
                        if (elem.subAssetType === 'sbom') {
                            ItemData.push({
                                label: name,
                                onClick: () => handleMenuClick({ label: name })
                            });
                        }
                    })
                    setItems(ItemData);
                }
                else {
                    setItems([]);
                }
            })
            .catch(e => { console.log("Execption: ", e) })
            .finally(() => { setLoadingDashData(false); })
    }

    const getSASTScanStats = () => {
        setLoadingDashData(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/sast/dashboard/summary`, {})
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.assetSummaries)) {
                    let ItemData = [];
                    _.map(op.data.assetSummaries, (elem, name) => {
                        // console.log("eklen", elem);
                        if (elem.assetType === 'file-cli') {
                            ItemData.push({
                                label: elem.assetUrl,
                                onClick: () => handleMenuClick({ label: elem.assetUrl })
                            });
                        }
                    })
                    setItems(ItemData);
                }
                else {
                    setItems([]);
                }
            })
            .catch(e => { console.log("Execption: ", e) })
            .finally(() => { setLoadingDashData(false); })
    }

    const handleMenuClick = ({ key, label }) => {
        // Extract text content from the menu item if it's a link
        setApplicationName(label);
    };

    const handleScannerSelectChange = (e) => {
        setScannerText(e);
        // console.log("Executed here");
        if (e === 'SCA') {
            getSCAScanStats();
        }
        else {
            getSASTScanStats();
        }
    }

    const handleApplicationNameChange = (e) => {
        setApplicationName(e.target.value);
    }

    const isBtnDisabled = () => {
        if (!applicationName || !fileState) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleSubmit = async () => {
        if (!applicationName) {
            message.warning('Enter or select application name');
            return;
        }
        if (!fileState) {
            message.warning('Please upload a file');
            return;
        }
        try {
            // Create FormData instance
            const formData = new FormData();
            formData.append('file', fileState);
            formData.append('request', `{"appName":"${applicationName}"}`);
            setLoading(true);

            let url = `${GlobalConst.API_URL}/auth/foss/scan/file`;
            if (scannerText === 'SAST') {
                url = `${GlobalConst.API_URL}/auth/foss/sast/upload/file`;
            }
            axios
                .post(url, formData)
                .then(e => {
                    // message.success('File successfully submitted to server');
                    if (scannerText === 'SAST') {
                        history.push('/sast-dashboard');
                        return;
                    }
                    history.push('/sca-dashboard');
                })
                .catch(e => { message.error('An error occured while scanning.');})
                .finally(() => { setLoading(false) })
        } catch (error) {
            // console.error('Error uploading file:', error);
            message.error('There was an error while scanning');
        } finally {
            // setLoading(false);
        }
    };

    return (
        <section className="mx-3">
            <section className="mb-3">
                <div className="d-flex align-items-start justify-content-between">
                    <div className="d-flex">
                        <h2 className="sotcox-title mb-0 text-white d-flex align-items-center justify-content-start me-2">
                            <TbTextScan2 className="me-2" />Manual Scanner
                        </h2>
                    </div>
                </div>
            </section>

            <section>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card custom-card">
                            <div className="card-body">

                                <div className="row mb-3">
                                    <div className="col-sm-6">
                                        <div className="mb-0">
                                            <label htmlFor="selScanner" className="form-label">Scanner</label>
                                            <div>
                                                <Select
                                                    id={'selScanner'}
                                                    value={scannerText}
                                                    className="w-100"
                                                    onChange={handleScannerSelectChange}
                                                    options={[
                                                        {
                                                            value: 'SCA',
                                                            label: 'SCA',
                                                        },
                                                        {
                                                            value: 'SAST',
                                                            label: 'SAST',
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-0">
                                            <label className="form-label">Application name</label>
                                            <Dropdown
                                                menu={{
                                                    items,
                                                }}
                                                trigger={['click']}
                                            >
                                                <Input type="text" value={applicationName} placeholder="Select/enter application name" onChange={handleApplicationNameChange} />
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Upload File</label>
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">
                                            Upload a SBOM file to scan.
                                        </p>
                                    </Dragger>
                                </div>

                                <div className="mb-3">
                                    <Button type="primary" onClick={handleSubmit} disabled={isBtnDisabled()} loading={loading}>Submit</Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {loadingDashData && <Loader />}
        </section>
    );
}