import { Progress } from "antd";
import { getAssetName } from "../../shared/helper/genHelper";
import { useEffect } from "react";


export default function VulnerableRepoGraph({ assetUrl, vulnerabilityCount, totalCve, strokeColor, data }) {

    const openAdvDashboard = (e, reportId) => {
        e.preventDefault();
        let url = `/sast-advance-dashboard/${reportId}`;
        // const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        // if (newWindow) newWindow.opener = null;
        window.open(url, '_blank');
    }

    return (
        <div className="d-flex align-items-center justify-content-center flex-column text-center">
            <div className="d-flex align-items-center mb-1" style={{ cursor: 'pointer' }} onClick={(e) => openAdvDashboard(e, data.reportId)}>
                <Progress
                    type="dashboard"
                    percent={(vulnerabilityCount * 100) / totalCve}
                    strokeColor={strokeColor}
                    trailColor="#2b313e"
                    strokeWidth={12}
                    size={110}
                    format={(percent) => (
                        <span className="d-flex align-items-center justify-content-center flex-column">
                            <span className="mb-2">{vulnerabilityCount}</span>
                            <span style={{ fontSize: 10, color: "rgb(154, 154, 154)" }}>
                                Vulnerabilities
                            </span>
                        </span>
                    )}
                />
            </div>
            <p style={{ fontWeight: 400, fontSize: 12, cursor: 'pointer' }} className="text-white mb-0">
                <a href={`#`} onClick={(e) => openAdvDashboard(e, data.reportId)}>{getAssetName(assetUrl)}</a>
            </p>
        </div>
    );
}