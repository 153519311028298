import React, { useEffect, useState, useRef } from "react";
import { Tag, Dropdown, Space, Menu, Typography, Alert, Table, Popover, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import Highlighter from 'react-highlight-words';
import { CaretDownOutlined } from '@ant-design/icons';
import axios from "axios";
import _ from 'lodash';
import { useSelector } from "react-redux";
import { getStrokeColor, getFontColor } from '../../shared/helper/genHelper';
import { getArtifactImage } from '../../shared/helper/getArtifactImageHelper';
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { LuHardDriveDownload } from "react-icons/lu";
import { exportToCSV } from "../../shared/helper/exportCSV";

const GetRecFixRow = ({ val, row }) => {

  const [selectedVersion, setSelectedVersion] = useState((!_.isEmpty(val) && !_.isEmpty(val[0])) ? val[0] : '');
  const [email, setEmail] = useState('');
  const [isInvalidValidEmail, setIsInvalidValidEmail] = useState(false);
  const [openNotify, setOpenNotify] = useState(false);
  const [loadingNotify, setLoadingNotify] = useState(false);

  const reduxState = useSelector(state => state);

  useEffect(() => {

    if (!_.isEmpty(reduxState) && !_.isEmpty(reduxState.authReducer) && !_.isEmpty(reduxState.authReducer.userDetails) && !_.isEmpty(reduxState.authReducer.userDetails.email)) {
      setEmail(reduxState.authReducer.userDetails.email);
    }

  }, [reduxState])

  const getDropdownMenuItems = (stableVersionList) => {
    let menuItems = [];
    if (!_.isEmpty(stableVersionList)) {
      stableVersionList.forEach((elem, index) => {
        menuItems.push({
          key: `${elem}`,
          label: `${elem}`
        })
      })
    }
    return menuItems;
  }

  const handleDropdownMenuClick = ({ key }) => {
    if (!_.isEmpty(key) && selectedVersion !== key) {
      setSelectedVersion(key);
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const getEcosystem = (val) => {

    if (_.isEmpty(val)) {
      return '';
    }
    const extractedText = _.chain(val)
      .split('/')
      .head()
      .split(':')
      .last()
      .value();

    return extractedText;
  }

  const getPackageSlug = (val) => {
    if (_.isEmpty(val)) {
      return '';
    }
    const extractedText = _.chain(val)
      .split('@')
      .head()
      .value();

    return extractedText;
  }


  const notify = () => {

    if (!_.isEmpty(email) && validateEmail(email)) {
      const data = {
        "email": email,
        "packageSlug": getPackageSlug(row.pkg),
        "cveId": row.cveId,
        "ecosystem": getEcosystem(row.pkg),
        "distroVersion": 'N/A',
        "subscriptionType": 'r'
      };
      setIsInvalidValidEmail(false);
      setLoadingNotify(true);
      axios
        .post(`${GlobalConst.API_URL}/notify/notification/save`, data)
        .then((op) => {
          setOpenNotify(false);
          setLoadingNotify(false);
          if (!_.isEmpty(op) && op.data && op.data.data && op.data.data.saved === true) {
            message.success(`Successfully subscribed to notification.`);
          }
          else {
            message.error(op.data.data.message);
          }
        })
        .catch(e => { setLoadingNotify(false); setOpenNotify(false); message.error(`Undefined error occured, we are sorry for the inconveniance caused. Please try after sometime.`); })
    }
    else {
      setIsInvalidValidEmail(true);
    }
  }

  if (_.isEmpty(val)) {
    return (<span className="d-flex align-items-center justify-content-start">
      <span className='text-danger'>No fix available</span>
      <Popover
        trigger="click"
        content={
          <>
            <div className="text-center mb-0" style={{ fontWeight: 400 }}>
              {_.isEmpty(email) ? <p className="text-danger">No Email found. <br />Please login to get notifications.</p> : <p className="mb-1">We'll notify you when fix is available on <br /><strong>{email}</strong></p>}
            </div>
            <div className="d-flex align-items-center justify-content-end mt-3">
              <Button className="me-2" size={'small'} onClick={() => { notify() }} type="primary" disabled={_.isEmpty(email)} loading={loadingNotify}>OK</Button>
              <Button size={'small'} onClick={() => { setOpenNotify(false) }} >Cancel</Button>
            </div>
          </>
        }
        visible={openNotify}
        destroyTooltipOnHide={true}
      >
        <Button
          size="small"
          onClick={() => {
            setOpenNotify(true);
            // setEmail('');
            setIsInvalidValidEmail(false);
          }}
          className="ms-2 d-flex align-items-center justify-items-center"
          style={{ fontSize: 12, height: 18, padding: '0 5px', borderRadius: 5 }}>Notify me</Button>
      </Popover>
    </span>);
  }

  if (!_.isEmpty(val) && val.length > 1) {
    return (<>
      <Dropdown overlay={<Menu selectable defaultSelectedKeys={[selectedVersion]} onClick={handleDropdownMenuClick} items={getDropdownMenuItems(val)} className={'versionMenuList scroll-style'} />} trigger={['click']}>
        <Typography.Link>
          <Space>
            <span style={{ display: 'flex', maxWidth: '12rem', color: '#adb5bd', alignItems: 'center' }}>
              <span className="text-truncate" style={{ color: '#adb5bd', fontSize: 14, fontFamily: 'Open Sans' }}>{selectedVersion}</span>
              <CaretDownOutlined className="ms-1" />
            </span>
          </Space>
        </Typography.Link>
      </Dropdown>
    </>);
  }
  else {
    return <span>{val[0]}</span>;
  }
}

export default function VulDataTable({ dataSet, searchResults, filter, selectedFile }) {

  const [data, setData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterDropdownVisible, setFilterDropdownVisible] = useState({});
  const searchInput = useRef(null);

  const [uniqValues, setUniqValues] = useState({ 'CRITICAL': 0, 'HIGH': 0, 'MEDIUM': 0, 'LOW': 0, 'NA': 0 });

  const getFileLocation = (file) => {
    let fileSplit = file.split('/');
    return `${fileSplit[fileSplit.length - 2]}/${fileSplit.pop()}`
  }

  useEffect(() => {

    if (!_.isEmpty(dataSet)) {
      let newDataSet = [];
      dataSet.forEach((superRow, ind) => {
        if (!_.isEmpty(superRow) && !_.isEmpty(superRow.scanResult)) {
          _.forIn(superRow.scanResult, (value, key) => {
            if (!_.isEmpty(value) && !_.isEmpty(value.cves)) {
              value.cves.forEach((el, index) => {
                newDataSet.push({
                  key: `${ind}-${key}-${index}-vultable-${el.cveId}`,
                  vulnerability: (!_.isEmpty(el) && !_.isEmpty(el.cveTypes)) ? el.cveTypes[0].cveType : 'Misc',
                  // count: count,
                  scanType: superRow.scanType,
                  pkg: key,
                  module: getFileLocation(superRow.scanFileLocation),
                  fileLocation: (superRow.scanFileLocation) ? superRow.scanFileLocation : '',
                  cve: el,
                  cveId: el.cveId,
                  severity: (el.cvssV3BaseSeverity) ? el.cvssV3BaseSeverity : (el.cvssV2BaseSeverity) ? el.cvssV2BaseSeverity : 'NA',
                  severityScore: (el.cvssV3BaseScore) ? el.cvssV3BaseScore : (el.cvssV2BaseScore) ? el.cvssV2BaseScore : 0,
                  cveCountDetails: value.cveCountDetails,
                  recommendedVersionList: value.recommendedVersionList,
                  stableRecommendedVersionList: value.stableRecommendedVersionList,
                  vulnerableComponent: getPackageDetails(key, 'packageName')
                });
              })
            }
          })
        }
      });

      let groupedItems = { 'CRITICAL': [], 'HIGH': [], 'MEDIUM': [], 'LOW': [], 'NA': [] };
      groupedItems = _(newDataSet)
        .orderBy('severityScore', ['desc'])
        .groupBy('severity')
        .mapValues((items, severity) => [severity, items])
        .sortBy(([severity]) => {
          switch (severity) {
            case 'CRITICAL':
              return 0;
            case 'HIGH':
              return 1;
            case 'MEDIUM':
              return 2;
            case 'LOW':
              return 3;
            default:
              return 4; // Handle any other cases
          }
        })
        .fromPairs()
        .value();
      let sortedNewData = [];
      if (!_.isEmpty(groupedItems)) {
        _.forIn(groupedItems, (val, key) => {
          if (!_.isEmpty(val)) {
            sortedNewData = [...sortedNewData, ...val]
          }

        })
      }
      if (!_.isEmpty(sortedNewData)) {
        sortedNewData = sortedNewData.map((el, index) => ({ ...el, "count": index + 1 }))
      }
      let uniqData = [];
      uniqData = _.uniqBy(sortedNewData, (obj) => obj.cveId);
      let nd = {};
      if (!_.isEmpty(uniqData)) {
        nd.CRITICAL = _.filter(uniqData, (o) => o.severity === 'CRITICAL').length;
        nd.HIGH = _.filter(uniqData, (o) => o.severity === 'HIGH').length;
        nd.MEDIUM = _.filter(uniqData, (o) => o.severity === 'MEDIUM').length;
        nd.LOW = _.filter(uniqData, (o) => o.severity === 'LOW').length;
        nd.NA = _.filter(uniqData, (o) => o.severity === 'NA').length;
      }
      setUniqValues({ ...uniqValues, ...nd });
      setData([...sortedNewData]);
    }
  }, [dataSet, filter, selectedFile]);



  const getPackageDetails = (val, type, artifact) => {
    if (val) {
      let splitedPkg = val.split(":");
      switch (type) {
        case "artifact":
          let sText = splitedPkg[1].split('/');
          return sText[2];
        case "group":
          let splitedText = splitedPkg[1].split('/');
          return splitedText[1];
        case 'getEcosystem':
          let ecosystem = splitedPkg[1].split('/');
          return ecosystem[0];
        case 'packageName':
          return splitedPkg[1];
        case 'getVersion':
          let version = splitedPkg[1].split('@')
          return version[1];
        default:
          return splitedPkg[2];
      }
    }
    else {
      return ('');
    }
  }


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setTimeout(() => searchInput.current?.select(), 100);
  };

  const getColumnSearchProps = (dataIndex, columnName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${columnName}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            className="d-flex align-items-center justify-content-center"
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {

                clearFilters();
                setSearchText('');
              }
              confirm({
                closeDropdown: false,
              });
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    filterDropdownVisible: filterDropdownVisible[dataIndex],
    onFilterDropdownVisibleChange: (visible) => handleSearchClick(dataIndex, visible),
    render: (text, row) =>
      (searchedColumn === dataIndex && !_.isEmpty(searchText)) ? (
        highlightText(searchedColumn, text, row)
      ) : (
        getColumnText(dataIndex, text, row)
      ),
  });

  const handleSearchClick = (selectedColumn, visible) => {
    let allVisible = _.mapValues(filterDropdownVisible, function (o) { return false; });
    setFilterDropdownVisible({ ...allVisible, [selectedColumn]: visible });
  };

  const getColumnText = (searchedColumn, text, row) => {
    if (searchedColumn === 'cveId') {
      return <a href={`https://galaxyguard.sec1.io/cve/${text}/?s=${text}`} target={'_blank'} rel="noreferrer">{text}</a>
    }
    else if (searchedColumn === 'module') {
      let href = '';
      if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.assetUrl)) {

        if (!_.isEmpty(searchResults.assetType) && searchResults.assetType === 'file') {
          return ((text) && <span title={row.fileLocation}>{row.fileLocation}</span>);
        }
        else {
          let hrefPart = row.fileLocation.split(/\//g);
          if (hrefPart.length >= 3) {
            href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(2).join('/');
          }
          else {
            href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(1).join('/');
          }
          return ((text) && <a href={href} target="_blank"><span title={row.fileLocation}>{text}</span></a>);
        }
      }
    }
    else {
      return text;
    }
  }

  const highlightText = (searchedColumn, text, row) => {
    if (searchedColumn === 'cveId') {
      return <a href={`https://galaxyguard.sec1.io/cve/${text}/?s=${text}`} target={'_blank'} rel="noreferrer">{getHighlighted(text)}</a>;
    }
    else if (searchedColumn === 'module') {
      const highlightText = (text) ? text : '';
      let href = '';
      if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.assetUrl)) {

        if (!_.isEmpty(searchResults.assetType) && searchResults.assetType === 'file') {
          return ((text) && <span title={row.fileLocation}>{row.fileLocation}</span>);
        }
        else {
          let hrefPart = row.fileLocation.split(/\//g);
          if (hrefPart.length >= 3) {
            href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(2).join('/');
          }
          else {
            href = searchResults.assetUrl + '/blob/' + searchResults.branch + '/' + hrefPart.slice(1).join('/');
          }
          return ((highlightText) ? <a href={href} target="_blank"><span title={row.fileLocation}>{getHighlighted(highlightText)}</span></a> : '');
        }
      }
    }
    else {
      return getHighlighted(text);
    }
  }

  const getHighlighted = (text) => {
    return <Highlighter
      highlightStyle={{
        backgroundColor: '#ffc069',
        padding: 0,
      }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
    />;
  }


  const compareCVE = (a, b) => {
    const regex = /CVE-(\d{4})-(\d+)/;
    const aMatch = a.match(regex);
    const bMatch = b.match(regex);
    const aYear = parseInt(aMatch[1]);
    const aNumber = parseInt(aMatch[2]);
    const bYear = parseInt(bMatch[1]);
    const bNumber = parseInt(bMatch[2]);

    if (aYear !== bYear) {
      return aYear - bYear;
    }

    return aNumber - bNumber;
  }

  const columns = [
    {
      title: 'Vulnerability',
      dataIndex: 'vulnerability',
      ...getColumnSearchProps('vulnerability', 'vulnerability'),
      ellipsis: true,
      sorter: (a, b) => a.vulnerability.localeCompare(b.vulnerability),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Severity',
      filters: [
        {
          text: 'critical',
          value: 'CRITICAL',
        },
        {
          text: 'high',
          value: 'HIGH',
        },
        {
          text: 'medium',
          value: 'MEDIUM',
        },
        {
          text: 'low',
          value: 'LOW',
        },
        {
          text: 'na',
          value: 'NA',
        },
      ],
      onFilter: (value, row) => {
        let returnStatus = false;
        if (value) {
          if (!_.isEmpty(row.severity)) {
            returnStatus = _.includes(row.severity, value);
          }
          else {
            returnStatus = false;
          }
        }
        return returnStatus;
      },
      render: (index, row) => {
        if (!_.isEmpty(row) && !_.isEmpty(row.cve) && !_.isEmpty(row.severity)) {
          return (
            <Tag style={{ color: getFontColor(row.severity.toUpperCase()), fontWeight: 600 }} color={getStrokeColor(row.severity.toUpperCase())}>
              {row.severity.toUpperCase()} {row.severity !== 'NA' && row.severityScore}
            </Tag>
          )
        }
        else {
          return '-';
        }
      },
      width: '10%',
      sorter: (a, b) => a.severityScore - b.severityScore,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Vulnerable component',
      dataIndex: 'vulnerableComponent',
      ...getColumnSearchProps('vulnerableComponent', 'vulnerable component'),
      ellipsis: true,
      render: (text, row) => {
        let [artifact, componentRaw] = text.split(/\/(.*)/s);
        let component = '';
        let imgTitle = '';
        if (artifact === 'maven') {
          component = _.replace(componentRaw, '/', ':');
          imgTitle = 'java';
        }
        else {
          component = componentRaw;
          imgTitle = artifact;
        }
        return <span ><img src={getArtifactImage(artifact)} style={{ width: 25 }} className="me-1" title={imgTitle} /> <span title={component}>{component}</span></span>;
      }
    },
    {
      title: 'Recommended fix',
      render: (index, row) => {
        let returnInd = (!_.isEmpty(row.stableRecommendedVersionList)) ? row.stableRecommendedVersionList : row.recommendedVersionList;
        return <GetRecFixRow val={returnInd} row={row} />;
      },
      width: '13%'
    },
    {
      title: 'Module name',
      dataIndex: 'module',
      ...getColumnSearchProps('module', 'module name'),
      ellipsis: true,
      sorter: (a, b) => a.module.localeCompare(b.module),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'CVE ID',
      dataIndex: 'cveId',
      ...getColumnSearchProps('cveId', 'cve id'),
      sorter: (a, b) => { return compareCVE(a.cveId, b.cveId) },
      sortDirections: ['ascend', 'descend'],
      width: '14%'
    }
  ];

  const handleReportDownloadClick = () => {

    let exportData = [];
    data.forEach((el) => {
      let returnInd = (!_.isEmpty(el.stableRecommendedVersionList)) ? el.stableRecommendedVersionList : el.recommendedVersionList;
      // console.log("el.cve", el.cve);
      exportData.push({
        "Vulnerability": el.vulnerability,
        'Severity': `${el.severity} ${el.severityScore}`,
        "Vulnerable Component": el.vulnerableComponent,
        "Recommended Fix": `${!_.isEmpty(returnInd) ? returnInd[0] : 'No fix available'}`,
        "Module Name": el.module,
        "CVE ID": el.cveId,
        "Is KEV": (!_.isEmpty(el.cve) && el.cve.kev) ? "YES" : "NO",
        "Zero-day": (!_.isEmpty(el.cve) && el.cve.zeroDay) ? "YES" : "NO",
      });
    });
    // exportTableToCSV(exportData, {}, 'vultable-report.csv');

    // console.log("Search Results", searchResults);
    let downloadFileName = 'SCA-vulnerability-report.csv';
    if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.appName)) {
      downloadFileName = `${searchResults.appName}-SCA.csv`;
    }

    const metadata = [
      { key: "Application Name", value: searchResults?.appName || 'N/A' },
      { key: "Report URL", value: searchResults?.reportUrl || 'N/A' },
      { key: "Scan Date", value: new Date().toISOString() },
      { key: 'Overall Rag Status', value: searchResults?.overallRagStatus || 'N/A' },
      { key: 'Total Components', value: searchResults?.totalComponents || 'N/A' },
      {
        key: "Total Vulnerabilities",
        value: (!_.isEmpty(searchResults?.cveCountDetails)) ? _.sum(_.values(searchResults.cveCountDetails)) : 'N/A'
      },
      { key: 'User Id', value: searchResults?.userId || 'N/A' },
      
    ];

    // return;
    exportToCSV(
      exportData,
      ['Vulnerability', 'Severity', 'Vulnerable Component', 'Recommended Fix', 'Module Name', "CVE ID", "Is KEV", "Zero-day"],
      null,
      downloadFileName,
      metadata  // Pass the metadata array as the fifth parameter
    );
  }


  return (
    <div>
      {!_.isEmpty(data) ? (
        <>
          <span style={{ position: 'absolute', top: 10, right: 0 }}>
            <button className="btn btn-primary btn-sm" onClick={() => handleReportDownloadClick('vulnerability')}><LuHardDriveDownload className="me-2" />Download</button>
          </span>
          <div style={{ overflow: "hidden", borderRadius: 8 }}>
            <Table
              columns={columns}
              dataSource={data}
              sticky={{ offsetHeader: 0 }}
              className="table table-striped custom-table mb-0"
              pagination={{
                showTotal: (total, range) => {
                  return <span className="text-white">{range[0]}-{range[1]} of {total && total.format()} vulnerabilities</span>;
                },
                defaultPageSize: 20,
                showSizeChanger: true,
                position: ["bottom", "left"],
                className: "searchPagePagination",
                pageSizeOptions: [10, 20, 25, 50, 100, 200, 1000]
              }}
              locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
            />
          </div>
        </>
      ) : (
        <Alert
          message="Application ready to deploy"
          description="This application contains no vulnerabilities and it is ready to deploy on prod."
          type="info"
          showIcon
          className="mt-2"
        />
      )}
    </div>
  );
}