import { TbAlertSquareRounded } from "react-icons/tb";
import { TbSquareRoundedCheck } from "react-icons/tb";

import _ from "lodash";

export default function CustomResultView({
  resultPrimaryText,
  resultSecondaryText,
  linkUrl,
  linkText,
  isAlert,
}) {
  return (
    <div
      className={`card custom-card rounded p-0 ${!isAlert ? 'border-success' : 'border-danger'}`}
    >
      <div className="card-body d-flex align-items-center justify-content-start gap-2 py-2">
        {!isAlert ? <TbSquareRoundedCheck style={{fontSize: 20, color: !isAlert ? "rgb(25,135,84)" : "rgba(218,74,71, 1)"}}/> : <TbAlertSquareRounded style={{fontSize: 20, color: !isAlert ? "rgb(25,135,84)" : "rgba(218,74,71, 1)"}}/>
      }
        <div className="d-flex flex-column  justify-content-start">
          <p className="mb-0"       style={{ color: !isAlert ? "rgb(25,135,84)" : "rgba(218,74,71, 1)" }}
          >{resultPrimaryText}</p>
          {!_.isEmpty(resultSecondaryText) && (
            <span>
              {resultSecondaryText}
              {!_.isEmpty(linkText) && <a href={linkUrl}>{linkText}</a>}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
