import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { GlobalConst } from '../../../shared/appConfig/globalConst';
import { Tag, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareMinus, faSquarePlus } from '@fortawesome/free-regular-svg-icons';

const MAX_VALUE_LENGTH = 50;

const StatsGraphMetaData = ({ metadata }) => {
    const [expandedKeys, setExpandedKeys] = useState({});

    const formatDate = (dateString) => {
        return moment.utc(dateString).local().format('MMMM D, YYYY HH:mm:ss');
    };

    const isValidUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    const toggleExpand = (key) => {
        setExpandedKeys(prev => ({
            ...prev,
            [key]: !prev[key]
        }));
    };

    const renderValue = (key, value) => {
        if (_.isBoolean(value)) {
            return value ? 'True' : 'False';
        }
        if (_.includes(GlobalConst.DATE_KEYS_METADATA, key.split('.').pop())) {
            return formatDate(value);
        }
        if (isValidUrl(value)) {
            return (
                <a
                    href={value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                >
                    {value}
                </a>
            );
        }
        const stringValue = _.toString(value);
        if (stringValue.length > MAX_VALUE_LENGTH) {
            return (
                <Tooltip title={stringValue}>
                    {`${stringValue.substring(0, MAX_VALUE_LENGTH)}...`}
                </Tooltip>
            );
        }
        if(key.toLowerCase()==='state'){
            
            if(_.includes(GlobalConst.CLOUD_SECURITY_STATE_GREEN_VALUES, value.toLowerCase())){
                return (<Tag color='#87d068' className='text-uppercase'>{stringValue}</Tag>);
            }
            else if(_.includes(GlobalConst.CLOUD_SECURITY_STATE_RED_VALUES, value.toLowerCase())){
                return (<Tag color='#cd201f' className='text-uppercase'>{stringValue}</Tag>);
            }
            else{
                return (<Tag>{stringValue}</Tag>);
            }
        }
        return stringValue === '' ? '-' : stringValue;
    };

    const renderObject = (key, value, level = 0) => {
        return (
            <div className={level === 0 ? "" : "mt-2"}>
                <div
                    className="d-flex align-items-center cursor-pointer mb-0"
                    onClick={() => toggleExpand(key)}
                >
                    <strong>{_.startCase(key.split('.').pop())}</strong>
                    {(expandedKeys[key]) ? (
                        // <span style={{padding: 1, borderRadius: 2, border: '1px solid #ddd'}}><FaMinus className="ms-1" size={8} /></span>
                        // <MinusSquareOutlined className="ms-1" style={{borderRadius: 6}}/>
                        <a href='#' className='ms-1'><FontAwesomeIcon icon={faSquareMinus} /></a>
                    ) : (
                        // <PlusSquareOutlined className="ms-1 ant-table-row-expand-icon ant-table-row-expand-icon-collapsed" style={{borderRadius: 6}}/>
                        <a href='#' className='ms-1'><FontAwesomeIcon icon={faSquarePlus} /></a>
                        // <FaPlus className="ms-1" size={8} />
                    )}

                </div>
                {(expandedKeys[key]) && (
                    <div className="ps-3 mt-2">
                        {Object.entries(value).map(([subKey, subValue]) => (
                            <div key={subKey} className="mb-2">
                                {_.isObject(subValue) && !_.isArray(subValue)
                                    ? renderObject(`${key}.${subKey}`, subValue, level + 1)
                                    : (
                                        <>
                                            <strong>{_.startCase(subKey)}: </strong>
                                            {renderValue(subKey, subValue)}
                                        </>
                                    )
                                }
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    const shouldExcludeKey = (key) => {
        return _.some(GlobalConst.EXCLUDE_METADATA_KEYS_INCLUDING_KEYWORD, keyword => _.includes(key.toLowerCase(), keyword.toLowerCase()));
    };

    return (
        <div className="scroll--simple" style={{ height: 268, overflow: 'hidden', overflowY: 'scroll' }}>
            {Object.entries(metadata).map(([key, value]) => {
                if (shouldExcludeKey(key)) return null;
                return (
                    <div key={key} className="mb-2">
                        {_.isObject(value) && !_.isArray(value)
                            ? renderObject(key, value)
                            : (
                                <>
                                    <strong>{_.startCase(key)}: </strong>
                                    {renderValue(key, value)}
                                </>
                            )
                        }
                    </div>
                );
            })}
        </div>
    );
};

export default StatsGraphMetaData;