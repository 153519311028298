import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import GridView from './grid';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function GridAssetsView() {
    const [data, setData] = useState([]);
    const history = useHistory();
    const threatVisionReducer = useSelector((state) => state.threatVisionReducer);

    useEffect(() => {
        if (!_.isEmpty(threatVisionReducer.assetsThreatScanRecords)) {
            let newData = [];
            _.forEach(threatVisionReducer.assetsThreatScanRecords, ({ reportId,assetUrl, riskScore, riskCategory, rowStatus }, index) => {
                if(rowStatus.toLowerCase() !== 'failed')
                newData.push({
                    id: assetUrl,
                    key: assetUrl,
                    value: riskScore ? riskScore : 0,
                    severity:riskCategory,
                    status:rowStatus,
                    reportId: reportId
                });
                
            });
            setData(newData);
        }
    }, [threatVisionReducer.assetsThreatScanRecords]);

    const getSeverityCategory = (value) => {
        if (value >= 0 && value <= 30) {
            return "LOW";
        } else if (value > 30 && value <= 50) {
            return "MEDIUM";
        } else if (value > 50 && value <= 70) {
            return "HIGH";
        } else if (value > 70 && value <= 100) {
            return "CRITICAL";
        }
    };

    const getData = (value) => {
        if (value) {
            let str = value.split('/').pop().split('@')[0];
            if (_.includes(str, '-')) {
                const words = str.split('-').slice(0, -1).map(word => word + '-');
                words.push(str.split('-').pop());
                return words;
            }
            else if (_.includes(str, ' ')) {
                return str.split(' ');
            }
            else {
                return str.split(/(?=[A-Z][a-z])/g);
            }
        }
        else {
            return ['Misc'];
        }
    };

    const handleAssetClick = (assetReportId) => {
        history.push(`/ai-threat-vision/${assetReportId}`);
    };

    return (
        <div className="d-flex align-items-center justify-content-start">
            <div>
                {!_.isEmpty(data) ? (
                    <GridView  data={data}
                    // width={800}
                    // height={400}
                    options={{
                        label: d => 
                            // getData(d.key),
                            [...getData(d.key), (!_.isEmpty(d) && !_.isEmpty(d.value)) ? d.value.toLocaleString("en") : ''].join("\n"),
                            // [...getData(d.key)],
                        value: d => d.value,
                        // value: d => (d.radiusVal) ? d.radiusVal : d.value,
                        group: d => d.id,
                        title: d => `${(d.id) ? d.id.toLocaleString("en") : 'Misc'}`,
                        // radiusVal: 
                        // link: d => ``,
                        // width: 400,
                        fill: d => d.fill,
                        severity: d => d.severity,
                        status: d => (d.status.toLowerCase() === "scanning" ||
                        d.status.toLowerCase() === "initiated") ? 0 : 1, 
                        width: 800,
                        height: 400
                    }}
                    onGridClick={handleAssetClick}/>
                ) : ''}
            </div>
        </div>
    );
}