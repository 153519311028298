import noData from "../../assets/img/no-data.svg";

export default function NoDataFound({
  title,
  desc,
  isShowImage = true,
  customInlineStyle = {},
}) {
  let defaultInlineStyle = {
    image: {
      width: 80,
      opacity: 0.7,
    },
    title: {
      fontWeight: 200,
    },
    description: {
      fontSize: 14,
    },
  };

  const mergedInlineStyles = {
    image: { ...defaultInlineStyle.image, ...customInlineStyle.image },
    title: { ...defaultInlineStyle.title, ...customInlineStyle.title },
    description: {
      ...defaultInlineStyle.description,
      ...customInlineStyle.description,
    },
  };
  return (
    <div className="d-flex align-items-center justify-content-center flex-column my-4 text-center">
      {isShowImage && (
        <img
          className="mb-3"
          src={noData}
          alt={"No Data Image"}
          style={mergedInlineStyles.image}
        />
      )}
      {title && (
        <h6 className="text-muted" style={mergedInlineStyles.title}>
          {title}
        </h6>
      )}
      {desc && (
        <p className="text-muted" style={mergedInlineStyles.description}>
          {desc}
        </p>
      )}
    </div>
  );
}
