import React, { useEffect, useState } from 'react';
// import TidyTreeChart from './tidyTreeChart';
import TidyTreeChart from './tidyTreeChartv2';

// import { getStrokeColor, getFontColor } from '../../shared/helper/genHelper';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faFilter } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { Checkbox } from 'antd';
// import flare from './flare.json';
import VulnerabilityLegend from './legend';



export default function TidyTreeView({ dataSet, showComponentDetails, handleAutoFixClick, selectedSeverity, showAllComponents}) {
    const cutOutIndex = 5;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState([]);

    const getModuleName = (name) => {
        if (!_.isEmpty(name)) {
            return name.split('/').pop();
        }
    }

    const getPackageDetails = (val, type, artifact) => {
        if (val) {
            let splitedPkg = val.split(":");
            switch (type) {
                case "artifact":
                    let sText = splitedPkg[1].split('/');
                    return sText[2];
                case "group":
                    let splitedText = splitedPkg[1].split('/');
                    return splitedText[1];
                case 'getEcosystem':
                    let ecosystem = splitedPkg[1].split('/');
                    return ecosystem[0];
                case 'packageName':
                    if (artifact === "maven") {
                        let strSplit = splitedPkg[1].split('/');
                        return `${strSplit[0]}/${strSplit[1]}:${strSplit[2]}`;
                    }
                    else {
                        return splitedPkg[1].split('/').pop();
                    }
                case 'getVersion':
                    let version = splitedPkg[1].split('@')
                    return version[1];
                default:
                    return splitedPkg[2];
            }
        }
        else {
            return ('');
        }
    }


    const onFilterChange = (e) => {
        // console.log(`checked = ${e.target.checked}`, e.target.value);
        if (e.target.checked) {
            setFilter(prev => [...prev, e.target.value]);
        }
        else {
            // console.log("I am here::")
            setFilter(prev => _.filter(prev, (el) => el !== e.target.value));
        }
    };


    return (
        <div>
            {/* <Dropdown autoClose={'outside'}>
                <Dropdown.Toggle variant="outline-secondary" className='text-white' size='sm'>
                    <FontAwesomeIcon className='me-2' icon={faFilter} />Filter
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <ul className="list-group m-0">
                        <li className="list-group-item p-2 legend-linkGraph border-0"><Checkbox className='me-2' checked={_.includes(filter, 'CRITICAL')} onClick={onFilterChange} value={'CRITICAL'}>Critical</Checkbox></li>
                        <li className="list-group-item p-2 legend-linkGraph border-0"><Checkbox className='me-2' checked={_.includes(filter, 'HIGH')} onClick={onFilterChange} value={'HIGH'}>High</Checkbox></li>
                        <li className="list-group-item p-2 legend-linkGraph border-0"><Checkbox className='me-2' checked={_.includes(filter, 'MEDIUM')} onClick={onFilterChange} value={'MEDIUM'}>Medium</Checkbox></li>
                        <li className="list-group-item p-2 legend-linkGraph border-0"><Checkbox className='me-2' checked={_.includes(filter, 'LOW')} onClick={onFilterChange} value={'LOW'}>Low</Checkbox></li>
                    </ul>
                </Dropdown.Menu>
            </Dropdown> */}
            <div className='d-flex align-items-center justify-content-center'>
                {!_.isEmpty(dataSet) ? <> <TidyTreeChart
                    data={dataSet}
                    options={{
                        label: d => d.packageName,
                        title: (d) => d.packageName,
                        // link: (d, n) => `https://github.com/prefuse/Flare/${n.children ? "tree" : "blob"}/master/flare/src/${n.ancestors().reverse().map(d => d.data.name).join("/")}${n.children ? "" : ".as"}`,
                        width: 1152
                    }}
                    showComponentDetails={showComponentDetails}
                    handleAutoFix={handleAutoFixClick}
                    selectedSeverity={selectedSeverity}
                    showAllComponents={showAllComponents}/> </> : ''}
            </div>
        </div>
    );
}
