import React from 'react';
import ReactECharts from 'echarts-for-react';

export default function ComplianceVerticalGraph({ data }) {
    const colors = ["#8561f9", "#2dcb73", "#438eff"]; // Purple, Green, Blue from the image

    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        xAxis: {
            type: 'category',
            data: data.map(item => item.name),
            axisLabel: {
                interval: 0,
                rotate: 0,
                color: '#ffffff' // White text for dark background
            },
            axisLine: {
                lineStyle: {
                    color: '#ffffff' // White axis line for dark background
                }
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                color: '#ffffff' // White text for dark background
            },
            axisLine: {
                lineStyle: {
                    color: '#ffffff' // White axis line for dark background
                }
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(255, 255, 255, 0.1)' // Faint white grid lines
                }
            }
        },
        series: [
            {
                name: 'Findings',
                type: 'bar',
                barWidth: '25%', // Adjust this value to make bars smaller or larger
                data: data.map((item, index) => ({
                    value: item.count,
                    itemStyle: {
                        color: colors[index % colors.length]
                    }
                })),
                label: {
                    show: true,
                    position: 'right',
                    color: '#ffffff' // White text for dark background
                }
            }
        ],
        grid: {
            containLabel: true,
            left: '3%',
            right: '5%',
            bottom: '3%',
            top: '3%'
        },
        backgroundColor: 'transparent'
    };

    return (
        <ReactECharts
            option={option}
            style={{ height: '200px', width: '100%' }}
        />
    );
}