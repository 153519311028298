import Modal from 'react-bootstrap/Modal';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileExplorer from './fileExplorer';
import _ from 'lodash';


export default function FileDirectory({ showFileExplorerModal, setShowFileExplorerModal, reportDetails, selectedData, userPrefrences }) {

    // console.log("reportDetails : ", reportDetails, selectedData);
    return (
        <Modal show={showFileExplorerModal} fullscreen={true} onHide={() => setShowFileExplorerModal(false)}>
            <Modal.Header closeButton={false}>
                <Modal.Title className='d-flex align-items-center justify-content-start'>
                    <a style={{ fontSize: '1rem', borderRight: '1px solid #ddd' }} href='#' onClick={() => { setShowFileExplorerModal(false) }} className='me-3 pe-2'><small><FontAwesomeIcon icon={faArrowLeft} className='me-2' />Back</small></a>
                    File Directory
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {!_.isEmpty(reportDetails) && <FileExplorer reportDetails={reportDetails} selectedData={selectedData} userPrefrences={userPrefrences} />}

            </Modal.Body>
        </Modal>
    );
}