export const exportToCSV = (
  data,
  columns = [],
  subTableConfig = null,
  filename = 'export.csv',
  metadata = null // new optional parameter for metadata
) => {
  let csvContent = [];

  // Add metadata table if provided
  if (metadata && Array.isArray(metadata) && metadata.length > 0) {
    // Metadata headers
    csvContent.push(['Key', 'Value'].join(','));

    // Metadata rows
    metadata.forEach(item => {
      const key = typeof item.key === 'string' && (item.key.includes(',') || item.key.includes('"'))
        ? `"${item.key.replace(/"/g, '""')}"`
        : item.key;
      const value = typeof item.value === 'string' && (item.value.includes(',') || item.value.includes('"'))
        ? `"${item.value.replace(/"/g, '""')}"`
        : item.value;
      csvContent.push([key, value].join(','));
    });

    // Add empty row after metadata
    csvContent.push('');
  }

  // Prepare headers for main table
  const headers = [...columns];
  if (subTableConfig) {
    subTableConfig.columns.forEach(column => {
      if (!headers.includes(column)) {
        headers.push(column);
      }
    });
  }
  csvContent.push(headers.join(','));

  // Prepare rows for main table
  data.forEach(mainItem => {
    // Add main row
    const mainRow = {};
    headers.forEach(header => {
      mainRow[header] = mainItem[header] || '';
    });
    csvContent.push(
      headers.map(header => {
        const value = mainRow[header] || '';
        return typeof value === 'string' && (value.includes(',') || value.includes('"'))
          ? `"${value.replace(/"/g, '""')}"`
          : value;
      }).join(',')
    );

    // Add subtable rows if config exists and item has subtable data
    if (subTableConfig && mainItem[subTableConfig.key]) {
      const subtableData = mainItem[subTableConfig.key];
      if (Array.isArray(subtableData) && subtableData.length > 0) {
        subtableData.forEach(subItem => {
          const subRow = {};
          // Empty values for main columns
          columns.forEach(column => {
            subRow[column] = '';
          });
          // Add subtable values
          subTableConfig.columns.forEach(column => {
            subRow[column] = subItem[column] || '';
          });
          csvContent.push(
            headers.map(header => {
              const value = subRow[header] || '';
              return typeof value === 'string' && (value.includes(',') || value.includes('"'))
                ? `"${value.replace(/"/g, '""')}"`
                : value;
            }).join(',')
          );
        });
      }
    }
  });

  // Join all rows with newlines
  const finalCsvContent = csvContent.join('\n');

  // Download file
  const blob = new Blob([finalCsvContent], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};