import React, { useState } from "react";
import _ from 'lodash';

import { MdDashboard } from "react-icons/md";
import { RiArrowRightLine } from "react-icons/ri";
import { GoFileCode } from "react-icons/go";
import { VscDebugConsole } from "react-icons/vsc";
import { IoIosCloudOutline } from "react-icons/io";
import { FaDocker } from "react-icons/fa6";
import { SiKubernetes } from "react-icons/si";
// import { AiOutlineKubernetes } from "react-icons/ai";

import Loader from "../../shared/sharedComponents/loader";
import SCASASTCombinedBar from './graphs/scaSastCombinedBar';
import TotalRepoNumberGraph from "./graphs/totalRepoNumberGraph";
import DASTTrendGraph from "./graphs/dastTrendGraph";
import { Link } from "react-router-dom";
// import { Progress, Tooltip } from "antd";
import ProgressGraph from "./graphs/progressGraph";
import { getCloudIcon, getFontColor, getStrokeColor } from "../../shared/helper/genHelper";
import { AiOutlineDashboard } from "react-icons/ai";
import { SiWebauthn } from "react-icons/si";
import { RiArrowRightUpLine, RiArrowRightDownLine } from "react-icons/ri";
import RiskGaugeChart from "./graphs/riskGraph";
import { Tag, Tooltip } from "antd";
import RadialGraph from "./graphs/radialGraph";
import AssetVulnerabilityChart from "./graphs/assetVulnerabilityChart";
// import GaugeChart from "./graphs/gaugeChart";



export default function UnifiedDashboard() {

    const [loading, setLoading] = useState(false);

    // const halfCircleColor = ["#785add", "#2db46b", "#3f80e2"];

    return (
        <>
            <section className="mx-3 my-2">
                <section className="mb-4">
                    <div className="d-flex align-items-start justify-content-between">
                        <div className="d-flex align-items-center">
                            <h2 style={{ lineHeight: 1 }} className="mb-2 text-white d-flex align-items-center justify-content-start">
                                <MdDashboard className="me-2" />
                                Dashboard
                            </h2>
                        </div>
                    </div>
                </section>

                <div className="row mb-3">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-2 mb-2">
                                <div className="" style={{ borderRight: '1px solid #2b313e' }}>
                                    <div className="card-body">
                                        <p className="text-uppercase fw-medium text-muted text-truncate fs-13 mb-1">Risk Meter</p>
                                        <RiskGaugeChart />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2 mb-2">
                                <div className="" style={{ borderRight: '1px solid #2b313e', minHeight: 80 }}>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div className="flex-grow-1">
                                                <p className="text-uppercase fw-medium text-muted text-truncate fs-13 mb-1">Code Security</p>
                                                <h4 className="fs-22 fw-semibold mb-1"><span className="counter-value" data-target="745.35">745</span></h4>
                                                {/* <div className="d-flex align-items-center justify-content-start mb-2">
                                                    <ul className="list-group list-group-horizontal">
                                                        <li className="list-group-item px-2 py-1 fs-10" style={{ color: getFontColor('CRITICAL'), background: getStrokeColor('CRITICAL') }}>12</li>
                                                        <li className="list-group-item px-2 py-1 fs-10" style={{ color: getFontColor('HIGH'), background: getStrokeColor('HIGH') }}>23</li>
                                                        <li className="list-group-item px-2 py-1 fs-10" style={{ color: getFontColor('MEDIUM'), background: getStrokeColor('MEDIUM') }}>534</li>
                                                        <li className="list-group-item px-2 py-1 fs-10" style={{ color: getFontColor('LOW'), background: getStrokeColor('LOW') }}>1</li>
                                                    </ul>

                                                </div> */}
                                                <div className="d-flex align-items-center justify-content-start mb-2">
                                                    <Tooltip title={'425 Critical'}><Tag color={getStrokeColor('CRITICAL')} style={{ color: getFontColor('CRITICAL'), cursor: 'default' }} className="fs-10 round overloap zInd-4">156</Tag></Tooltip>
                                                    <Tooltip title={'23 High'}><Tag color={getStrokeColor('HIGH')} style={{ color: getFontColor('HIGH'), cursor: 'default' }} className="fs-10 round overloap zInd-3">204</Tag></Tooltip>
                                                    <Tooltip title={'1 Medium'}><Tag color={getStrokeColor('MEDIUM')} style={{ color: getFontColor('MEDIUM'), cursor: 'default' }} className="fs-10 round overloap zInd-2">175</Tag></Tooltip>
                                                    <Tooltip title={'0 Low'}><Tag color={getStrokeColor('LOW')} style={{ color: getFontColor('LOW'), cursor: 'default' }} className="fs-10 round overloap zInd-1">210</Tag></Tooltip>
                                                </div>

                                                <div className="d-flex align-items-center gap-2">
                                                    <h5 className="text-danger fs-12 mb-0">
                                                        <RiArrowRightUpLine className={'fs-13 align-middle'} /> +2 %
                                                    </h5>
                                                    <p className="text-muted fs-12 mb-0">than last week</p>
                                                </div>
                                            </div>
                                            {/* <div className="avatar-sm flex-shrink-0">
                                                <span className="avatar-title bg-success-subtle rounded fs-3">
                                                    <GoFileCode className="text-success" />
                                                </span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2 mb-2">
                                <div className="" style={{ borderRight: '1px solid #2b313e', minHeight: 80 }}>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div className="flex-grow-1">
                                                <p className="text-uppercase fw-medium text-muted text-truncate fs-13 mb-1">Runtime Application Security</p>
                                                <h4 className="fs-22 fw-semibold mb-0"><span className="counter-value" data-target="745.35">325</span></h4>
                                                <div className="d-flex align-items-center justify-content-start mb-2">
                                                    <Tooltip title={'425 Critical'}><Tag color={getStrokeColor('CRITICAL')} style={{ color: getFontColor('CRITICAL'), cursor: 'default' }} className="fs-10 round overloap zInd-4">70</Tag></Tooltip>
                                                    <Tooltip title={'23 High'}><Tag color={getStrokeColor('HIGH')} style={{ color: getFontColor('HIGH'), cursor: 'default' }} className="fs-10 round overloap zInd-3">85</Tag></Tooltip>
                                                    <Tooltip title={'1 Medium'}><Tag color={getStrokeColor('MEDIUM')} style={{ color: getFontColor('MEDIUM'), cursor: 'default' }} className="fs-10 round overloap zInd-2">90</Tag></Tooltip>
                                                    <Tooltip title={'0 Low'}><Tag color={getStrokeColor('LOW')} style={{ color: getFontColor('LOW'), cursor: 'default' }} className="fs-10 round overloap zInd-1">80</Tag></Tooltip>
                                                </div>
                                                <div className="d-flex align-items-center gap-2">
                                                    <h5 className="text-danger fs-12 mb-0">
                                                        <RiArrowRightUpLine className={'fs-13 align-middle'} /> +12 %
                                                    </h5>
                                                    <p className="text-muted fs-12 mb-0">than last week</p>
                                                </div>
                                            </div>
                                            {/* <div className="avatar-sm flex-shrink-0">
                                                <span className="avatar-title bg-success-subtle rounded fs-3">
                                                    <VscDebugConsole className="text-success" />
                                                </span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2 mb-2">
                                <div className="" style={{ borderRight: '1px solid #2b313e', minHeight: 80 }}>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div className="flex-grow-1">
                                                <p className="text-uppercase fw-medium text-muted text-truncate fs-13 mb-1">Cloud Security</p>
                                                <h4 className="fs-22 fw-semibold mb-0"><span className="counter-value" data-target="745.35">182</span></h4>
                                                <div className="d-flex align-items-center justify-content-start mb-2">
                                                    <Tooltip title={'425 Critical'}><Tag color={getStrokeColor('CRITICAL')} style={{ color: getFontColor('CRITICAL'), cursor: 'default' }} className="fs-10 round overloap zInd-4">42</Tag></Tooltip>
                                                    <Tooltip title={'23 High'}><Tag color={getStrokeColor('HIGH')} style={{ color: getFontColor('HIGH'), cursor: 'default' }} className="fs-10 round overloap zInd-3">50</Tag></Tooltip>
                                                    <Tooltip title={'1 Medium'}><Tag color={getStrokeColor('MEDIUM')} style={{ color: getFontColor('MEDIUM'), cursor: 'default' }} className="fs-10 round overloap zInd-2">35</Tag></Tooltip>
                                                    <Tooltip title={'0 Low'}><Tag color={getStrokeColor('LOW')} style={{ color: getFontColor('LOW'), cursor: 'default' }} className="fs-10 round overloap zInd-1">55</Tag></Tooltip>
                                                </div>
                                                <div className="d-flex align-items-center gap-2">
                                                    <h5 className="text-danger fs-12 mb-0">
                                                        <RiArrowRightUpLine className={'fs-13 align-middle'} /> +0.9 %
                                                    </h5>
                                                    <p className="text-muted fs-12 mb-0">than last week</p>
                                                </div>
                                            </div>
                                            {/* <div className="avatar-sm flex-shrink-0">
                                                <span className="avatar-title bg-success-subtle rounded fs-3">
                                                    <IoIosCloudOutline className={'text-success'} />
                                                </span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-2 mb-2">
                                <div className="" style={{ borderRight: '1px solid #2b313e', minHeight: 80 }}>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div className="flex-grow-1">
                                                <p className="text-uppercase fw-medium text-muted text-truncate fs-13 mb-1">Container Security</p>
                                                <h4 className="fs-22 fw-semibold mb-0"><span className="counter-value" data-target="745.35">213</span></h4>
                                                <div className="d-flex align-items-center justify-content-start mb-2">
                                                    <Tooltip title={'425 Critical'}><Tag color={getStrokeColor('CRITICAL')} style={{ color: getFontColor('CRITICAL'), cursor: 'default' }} className="fs-10 round overloap zInd-4">48</Tag></Tooltip>
                                                    <Tooltip title={'23 High'}><Tag color={getStrokeColor('HIGH')} style={{ color: getFontColor('HIGH'), cursor: 'default' }} className="fs-10 round overloap zInd-3">60</Tag></Tooltip>
                                                    <Tooltip title={'1 Medium'}><Tag color={getStrokeColor('MEDIUM')} style={{ color: getFontColor('MEDIUM'), cursor: 'default' }} className="fs-10 round overloap zInd-2">55</Tag></Tooltip>
                                                    <Tooltip title={'0 Low'}><Tag color={getStrokeColor('LOW')} style={{ color: getFontColor('LOW'), cursor: 'default' }} className="fs-10 round overloap zInd-1">50</Tag></Tooltip>
                                                </div>
                                                <div className="d-flex align-items-center gap-2">
                                                    {/* <h5 className="text-danger fs-12 mb-0">
                                                        <RiArrowRightUpLine className={'fs-13 align-middle'} /> +6 %
                                                    </h5> */}
                                                    <h5 className="text-success fs-12 mb-0">
                                                        <RiArrowRightDownLine className={'fs-13 align-middle'} /> -5 %
                                                    </h5>
                                                    <p className="text-muted fs-12 mb-0">than last week</p>
                                                </div>
                                            </div>
                                            {/* <div className="avatar-sm flex-shrink-0">
                                                <span className="avatar-title bg-success-subtle rounded fs-3">
                                                    <FaDocker className={'text-success'} />
                                                </span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2 mb-2">
                                <div className="">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div className="flex-grow-1">
                                                <p className="text-uppercase fw-medium text-muted text-truncate fs-13 mb-1">Kubernetes Security</p>
                                                <h4 className="fs-22 fw-semibold mb-0"><span className="counter-value" data-target="745.35">188</span></h4>
                                                {/* <div className="d-flex align-items-center justify-content-start mb-2">
                                                    <Tooltip title={'425 Critical'}><Tag color={'transparent'} style={{ color: getStrokeColor('CRITICAL'), border: `1px solid ${getStrokeColor('CRITICAL')}` }} className="fs-10 round overloap zInd-4">425</Tag></Tooltip>
                                                    <Tooltip title={'23 High'}><Tag color={'transparent'} style={{ color: getStrokeColor('HIGH'), border: `1px solid ${getStrokeColor('HIGH')}` }} className="fs-10 round overloap zInd-3">253</Tag></Tooltip>
                                                    <Tooltip title={'1 Medium'}><Tag color={'transparent'} style={{ color: getStrokeColor('MEDIUM'), border: `1px solid ${getStrokeColor('MEDIUM')}` }} className="fs-10 round overloap zInd-2">187</Tag></Tooltip>
                                                    <Tooltip title={'0 Low'}><Tag color={'transparent'} style={{ color: getStrokeColor('LOW'), border: `1px solid ${getStrokeColor('LOW')}` }} className="fs-10 round overloap zInd-1">066</Tag></Tooltip>
                                                </div> */}
                                                <div className="d-flex align-items-center justify-content-start mb-2">
                                                    <Tooltip title={'425 Critical'}><Tag color={getStrokeColor('CRITICAL')} style={{ color: getFontColor('CRITICAL'), cursor: 'default' }} className="fs-10 round overloap zInd-4">40</Tag></Tooltip>
                                                    <Tooltip title={'23 High'}><Tag color={getStrokeColor('HIGH')} style={{ color: getFontColor('HIGH'), cursor: 'default' }} className="fs-10 round overloap zInd-3">47</Tag></Tooltip>
                                                    <Tooltip title={'1 Medium'}><Tag color={getStrokeColor('MEDIUM')} style={{ color: getFontColor('MEDIUM'), cursor: 'default' }} className="fs-10 round overloap zInd-2">56</Tag></Tooltip>
                                                    <Tooltip title={'0 Low'}><Tag color={getStrokeColor('LOW')} style={{ color: getFontColor('LOW'), cursor: 'default' }} className="fs-10 round overloap zInd-1">45</Tag></Tooltip>
                                                </div>
                                                <div className="d-flex align-items-center gap-2">
                                                    <h5 className="text-danger fs-12 mb-0">
                                                        <RiArrowRightUpLine className={'fs-13 align-middle'} /> +25 %
                                                    </h5>
                                                    <p className="text-muted fs-12 mb-0">than last week</p>
                                                </div>
                                            </div>
                                            {/* <div className="avatar-sm flex-shrink-0">
                                                <span className="avatar-title bg-success-subtle rounded fs-3">
                                                    <SiKubernetes className={'text-success'} />
                                                </span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>



                <section className="mb-5">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card custom-card">

                                <div className="card-body">
                                    <div className="card-header d-flex p-0 pb-1 mb-2">
                                        <h5 className="card-title flex-grow-1 mb-0 d-flex align-items-center justify-content-start"><GoFileCode className="me-1" /> Code Security</h5>
                                        <Link to="/sca-dashboard" style={{ fontSize: 12 }} className="d-flex align-items-center justify-content-center flex-shrink-0">Detailed Dashboard <RiArrowRightLine className="ri-arrow-right-line ms-1" /></Link>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-3 pe-0">
                                                    <div className="card card-animate">
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="flex-grow-1">
                                                                    <p className="fw-medium text-muted text-truncate fs-13 mb-1">Number of Repositories</p>
                                                                    <h4 className="fs-22 fw-semibold mb-2"><span className="counter-value" data-target="745.35">31</span></h4>
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <h5 className="text-success fs-12 mb-0">
                                                                            &nbsp;
                                                                        </h5>
                                                                        <p className="text-muted fs-12 mb-0">&nbsp;</p>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="avatar-sm flex-shrink-0">
                                                                    <span className="avatar-title bg-success-subtle rounded fs-3">
                                                                        <i className="bx bx-dollar-circle text-success"></i>
                                                                    </span>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 pe-0">
                                                    <div className="card card-animate">
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="flex-grow-1">
                                                                    <p className="fw-medium text-muted text-truncate fs-13 mb-1">Vulnerabilities</p>
                                                                    <h4 className="fs-22 fw-semibold mb-2"><span className="counter-value" data-target="745.35">1157</span></h4>
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <h5 className="text-danger fs-12 mb-0">
                                                                            <RiArrowRightUpLine className={'fs-13 align-middle'} /> +18 %
                                                                        </h5>
                                                                        <p className="text-muted mb-0 fs-12">than last week</p>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="avatar-sm flex-shrink-0">
                                                                    <span className="avatar-title bg-success-subtle rounded fs-3">
                                                                        <i className="bx bx-dollar-circle text-success"></i>
                                                                    </span>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 pe-0">
                                                    <div className="card card-animate">
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="flex-grow-1">
                                                                    <p className="fw-medium text-muted text-truncate fs-13 mb-1">Code Issues</p>
                                                                    <h4 className="fs-22 fw-semibold mb-2"><span className="counter-value" data-target="745.35">560</span></h4>
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <h5 className="text-danger fs-12 mb-0">
                                                                            <RiArrowRightUpLine className={'fs-13 align-middle'} /> +0.2 %
                                                                        </h5>
                                                                        <p className="text-muted mb-0 fs-12">than last week</p>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="avatar-sm flex-shrink-0">
                                                                    <span className="avatar-title bg-success-subtle rounded fs-3">
                                                                        <i className="bx bx-dollar-circle text-success"></i>
                                                                    </span>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-3">
                                                    <div className="card card-animate">
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="flex-grow-1">
                                                                    <p className="fw-medium text-muted text-truncate fs-13 mb-1">Auto-remediation</p>
                                                                    <h4 className="fs-22 fw-semibold mb-2"><span className="counter-value" data-target="745.35">320</span></h4>
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <h5 className="text-danger fs-12 mb-0">
                                                                            <RiArrowRightUpLine className={'fs-13 align-middle'} /> +2 %
                                                                        </h5>
                                                                        <p className="text-muted mb-0 fs-12">than last week</p>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="avatar-sm flex-shrink-0">
                                                                    <span className="avatar-title bg-success-subtle rounded fs-3">
                                                                        <i className="bx bx-dollar-circle text-success"></i>
                                                                    </span>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 mt-2">
                                                    <div className="card card-animate">
                                                        <div className="card-body">

                                                            <div className="d-flex justify-content-between">
                                                                <div className="flex-grow-1">
                                                                    <p className="fw-medium text-muted text-truncate fs-13 mb-1 mb-0">Vulnerability Trend</p>
                                                                    <SCASASTCombinedBar />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>







                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div className="card-header d-flex p-0 pb-1  mb-2">
                                        <h5 className="card-title flex-grow-1 mb-0 d-flex align-items-center justify-content-start"><VscDebugConsole className={'me-1'} /> DAST</h5>
                                        <Link to="/web-security-dashboard" style={{ fontSize: 12 }} className="d-flex align-items-center justify-content-center flex-shrink-0">Detailed Dashboard <RiArrowRightLine className="ri-arrow-right-line ms-1" /></Link>
                                    </div>

                                    <div className="row">



                                        <div className="col-sm-6" style={{ borderRight: '1px solid rgb(43, 49, 62)' }}>

                                            <div className="mb-2">
                                                <div className="card-body px-2">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="flex-grow-1">
                                                            <p className="fw-medium text-muted text-truncate fs-13 mb-1">Web Application Vulnerabilities</p>
                                                            <h4 className="fs-22 fw-semibold mb-0"><span className="counter-value" data-target="745.35">12</span></h4>
                                                            {/* <div className="d-flex align-items-center gap-2">
                                                                <h5 className="text-success fs-12 mb-0">
                                                                    &nbsp;
                                                                </h5>
                                                                <p className="text-muted fs-12 mb-0">&nbsp;</p>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="card-body">

                                                    <div className="d-flex justify-content-between">
                                                        <div className="flex-grow-1">
                                                            {/* <p className="fw-medium text-muted text-truncate fs-13 mb-1 mb-0">Vulnerability Distribution</p> */}
                                                            <AssetVulnerabilityChart />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-2">
                                                <div className="card-body px-2">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="flex-grow-1">
                                                            <p className="fw-medium text-muted text-truncate fs-13 mb-1">API Vulnerabilities</p>
                                                            <h4 className="fs-22 fw-semibold mb-0"><span className="counter-value" data-target="745.35">6</span></h4>
                                                            {/* <div className="d-flex align-items-center gap-2">
                                                                <h5 className="text-success fs-12 mb-0">
                                                                    &nbsp;
                                                                </h5>
                                                                <p className="text-muted fs-12 mb-0">&nbsp;</p>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="card-body">

                                                    <div className="d-flex justify-content-between">
                                                        <div className="flex-grow-1">
                                                            {/* <p className="fw-medium text-muted text-truncate fs-13 mb-1 mb-0">Vulnerability Distribution</p> */}
                                                            <AssetVulnerabilityChart />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>



                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card custom-card">

                                <div className="card-body">
                                    <div className="card-header d-flex p-0 pb-1  mb-2">
                                        <h5 className="card-title flex-grow-1 mb-0  d-flex align-items-center justify-content-start"><FaDocker className={'me-1'} /> Container Security</h5>
                                        <Link to="/container-security" style={{ fontSize: 12 }} className="d-flex align-items-center justify-content-center flex-shrink-0">Detailed Dashboard <RiArrowRightLine className="ri-arrow-right-line ms-1" /></Link>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 pe-0">
                                            <div className="">
                                                <div className="card-body px-2">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="flex-grow-1">
                                                            <p className="fw-medium text-muted text-truncate fs-13 mb-1">Total Vulnerabilities</p>
                                                            <h4 className="fs-22 fw-semibold mb-1"><span className="counter-value" data-target="745.35">213</span></h4>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <h5 className="text-success fs-12 mb-0">
                                                                    <RiArrowRightDownLine className={'fs-13 align-middle'} /> -5 %
                                                                </h5>
                                                                <p className="text-muted mb-0 fs-12">than last week</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-sm-6">
                                            <div className="card card-animate">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="flex-grow-1">
                                                            <p className="text-uppercase fw-medium text-muted text-truncate fs-13 mb-1">Total Vulnerabilities</p>
                                                            <h4 className="fs-22 fw-semibold mb-2"><span className="counter-value" data-target="745.35">745.35</span></h4>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <h5 className="text-success fs-12 mb-0">
                                                                    <i className="ri-arrow-right-up-line fs-13 align-middle"></i> +18.30 %
                                                                </h5>
                                                                <p className="text-muted mb-0 fs-12">than last week</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-sm-12 mt-2">
                                            <div className="card card-animate">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="flex-grow-1">
                                                            <p className="fw-medium text-muted text-truncate fs-13 mb-1">Top 3 Vulnerable Images</p>
                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    <ProgressGraph title={'shiftleft-java-demo'} vulCount={75} totalOutOff={152} index={0} innerText={'Vulnerabilities'} />
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <ProgressGraph title={'docker-gs'} vulCount={26} totalOutOff={152} index={1} innerText={'Vulnerabilities'} />
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <ProgressGraph title={'spring-boot-test'} vulCount={136} totalOutOff={152} index={2} innerText={'Vulnerabilities'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card custom-card">

                                <div className="card-body">
                                    <div className="card-header d-flex p-0 pb-1  mb-2">
                                        <h5 className="card-title flex-grow-1 mb-0  d-flex align-items-center justify-content-start"><IoIosCloudOutline className={'me-1'} /> Cloud Security</h5>
                                        <Link to="/cloud-security-dashboard" style={{ fontSize: 12 }} className="d-flex align-items-center justify-content-center flex-shrink-0">Detailed Dashboard <RiArrowRightLine className="ri-arrow-right-line ms-1" /></Link>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 pe-0 ">
                                            <div className="py-4">
                                                <div className="">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="flex-grow-1">
                                                            {/* <p className="fw-medium text-muted text-truncate fs-13">Misconfigurations</p> */}
                                                            {/* <GaugeChart value={57} index={0} /> */}
                                                            <div className="d-flex align-items-center justify-content-center flex-column">
                                                                {/* <div className="d-flex align-items-center justify-content-center" style={{ background: '#2b313e', borderRadius: '50%', height: 110, width: 110 }}> */}
                                                                <h2 className="mb-0" style={{ fontSize: 38 }}>57</h2>
                                                                <span style={{ fontSize: 10 }}>Misconfigurations</span>
                                                                {/* </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="py-4">
                                                <div className="">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="flex-grow-1">
                                                            {/* <p className="fw-medium text-muted text-truncate fs-13">Misconfigurations</p> */}
                                                            {/* <GaugeChart value={57} index={0} /> */}
                                                            <div className="d-flex align-items-center justify-content-center flex-column">
                                                                {/* <div className="d-flex align-items-center justify-content-center" style={{ background: '#2b313e', borderRadius: '50%', height: 110, width: 110 }}> */}
                                                                <h2 className="mb-0" style={{ fontSize: 38 }}>125</h2>
                                                                <span style={{ fontSize: 10 }}>Threat Management</span>
                                                                {/* </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="card card-animate">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="flex-grow-1">
                                                            <p className="text-uppercase fw-medium text-muted text-truncate fs-13 mb-1">Threat Management</p>
                                                            <div className="d-flex align-items-center justify-content-center py-3">
                                                                <div className="d-flex align-items-center justify-content-center" style={{ background: '#3f80e2', borderRadius: '50%', height: 110, width: 110 }}>
                                                                    <h2 className="mb-0">57</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>

                                        <div className="col-sm-12 mt-4">
                                            <div className="card card-animate">
                                                <div className="card-body">

                                                    <div className="d-flex justify-content-between">
                                                        <div className="flex-grow-1">
                                                            <table className="table table-hover table-sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Cloud</th>
                                                                        <th className="text-center">Total</th>
                                                                        <th className="text-center">Critical</th>
                                                                        <th>Last Scan</th>
                                                                        <th className="text-center">Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><img src={getCloudIcon('', 'gcp')} className="me-2" style={{ width: 18 }} />cve-buster</td>
                                                                        <td className="text-center">257</td>
                                                                        <td className="text-center">
                                                                            <Tag
                                                                                style={{
                                                                                    color: getFontColor("CRITICAL"),
                                                                                    borderRadius: 50,
                                                                                    width: 40,
                                                                                    fontSize: 14,
                                                                                }}
                                                                                color={getStrokeColor("CRITICAL")}
                                                                                className={"mx-auto text-center"}
                                                                            >
                                                                                17
                                                                            </Tag>
                                                                        </td>
                                                                        <td>5 days ago</td>
                                                                        <td className="text-center">
                                                                            <a onClick={(e) => { e.preventDefault(); window.open('/cloud-security-advance-dashboard/46ae5150-c4b1-42bb-8040-7e5949132d2d') }} href={`#`} className="details-hover-animate">
                                                                                <span><AiOutlineDashboard style={{ fontSize: 20 }} /></span>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><img src={getCloudIcon('', 'aws')} className="me-2" style={{ width: 18 }} />294101271641</td>
                                                                        <td className="text-center">30</td>
                                                                        <td className="text-center">
                                                                            <Tag
                                                                                style={{
                                                                                    color: getFontColor("CRITICAL"),
                                                                                    borderRadius: 50,
                                                                                    width: 40,
                                                                                    fontSize: 14,
                                                                                }}
                                                                                color={getStrokeColor("CRITICAL")}
                                                                                className={"mx-auto text-center"}
                                                                            >
                                                                                8
                                                                            </Tag>
                                                                        </td>
                                                                        <td>5 days ago</td>
                                                                        <td className="text-center">
                                                                            <a onClick={(e) => { e.preventDefault(); window.open('/cloud-security-advance-dashboard/efc1ae1f-f75e-4829-9b15-a41cbeb8594f') }} href={`#`} className="details-hover-animate">
                                                                                <span><AiOutlineDashboard style={{ fontSize: 20 }} /></span>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card custom-card">

                                <div className="card-body">
                                    <div className="card-header d-flex p-0 pb-1  mb-2">
                                        <h5 className="card-title flex-grow-1 mb-0  d-flex align-items-center justify-content-start"><SiKubernetes className={'me-1'} /> Kubernetes Security</h5>
                                        <Link to="/k8s-dashboard" style={{ fontSize: 12 }} className="d-flex align-items-center justify-content-center flex-shrink-0">Detailed Dashboard <RiArrowRightLine className="ri-arrow-right-line ms-1" /></Link>
                                    </div>
                                    <div className="">
                                        <div className="">

                                            <div className="d-flex justify-content-between">
                                                <div className="flex-grow-1">
                                                    {/* <p className="fw-medium text-muted text-truncate fs-13 mb-1 mb-0">Vulnerability Trend</p> */}
                                                    <TotalRepoNumberGraph />
                                                    <div className="card card-animate">
                                                        <div className="card-body">
                                                            <table className="table table-hover table-sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Cluster Name</th>
                                                                        <th className="text-center">Vulnerabilities</th>
                                                                        <th className="text-center">Misconfigurations</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><img src={getCloudIcon('', 'gcp')} className="me-2" style={{ width: 18 }} />cve-buster</td>
                                                                        <td className="text-center">124</td>
                                                                        <td className="text-center">675</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><img src={getCloudIcon('', 'aws')} className="me-2" style={{ width: 18 }} />294101271641</td>
                                                                        <td className="text-center">545</td>
                                                                        <td className="text-center">234</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>

            </section>

            {loading && <Loader />}

        </>
    );
}