import _ from 'lodash';
import { RiArrowRightUpLine, RiArrowRightDownLine } from "react-icons/ri";

export default function GetCount({ dataPoint }) {
    const columnStyles = {
        applications: {
            wrapper: "col-sm-4",
            container: ""
        },
        vulnerabilities: {
            wrapper: "col-sm-4 align-items-start justify-content-center d-flex  p-0",
            container: ""
        },
        than_last_week: {
            wrapper: "col-sm-4 align-items-end justify-content-end d-flex  p-0",
            container: ""
        }
    };

    const formatComparisonNumber = (value) => {
        const num = parseFloat(value);
        if (isNaN(num)) return { value: value, trend: 'neutral' };
        if (num === 0) return { value: '0', trend: 'neutral' };
        const prefix = num > 0 ? '+' : '';
        return {
            value: `${prefix}${num.toLocaleString()}`,
            trend: num > 0 ? 'positive' : 'negative'
        };
    };

    const getValueStyle = (key, value) => {
        if (key === 'than_last_week') {
            const { value: formattedValue, trend } = formatComparisonNumber(value);
            const colors = {
                positive: '#ef4444',  // red for positive
                negative: '#10b981',  // green for negative
                neutral: '#94a3b8'    // gray for zero
            };
            
            return {
                value: formattedValue,
                trend,
                style: { 
                    lineHeight: 1.2,
                    color: colors[trend]
                },
                className: "text-sm d-flex align-items-center gap-1"
            };
        }

        return {
            value: value,
            style: { lineHeight: 1.2 },
            className: "text-sm text-white"
        };
    };

    const renderTrendingValue = (valueProps) => {
        // Only show trend icons for non-zero values
        const TrendIcon = valueProps.trend === 'positive' ? RiArrowRightUpLine : 
                         valueProps.trend === 'negative' ? RiArrowRightDownLine : 
                         null;

        return (
            <div className={valueProps.className} style={valueProps.style}>
                {TrendIcon && <small style={{fontSize: 12}}><TrendIcon size={16} /></small>}
                <span><small style={{fontSize: 12}}>{valueProps.value}%</small></span>
            </div>
        );
    };

    const renderColumn = (key, value) => {
        const valueProps = getValueStyle(key, value);
        
        return (
            <div key={key} className={columnStyles[key].wrapper}>
                <div className={columnStyles[key].container}>
                    {key === 'than_last_week' ? (
                        renderTrendingValue(valueProps)
                    ) : (
                        <h5 className={valueProps.className} style={valueProps.style}>
                            {valueProps.value}
                        </h5>
                    )}
                    <div className="text-capitalize" style={{ color: '#94a3b8', fontSize: 10 }}>
                        {key.split('_').join(' ').toLowerCase()}
                    </div>
                </div>
            </div>
        );
    };

    if (_.isEmpty(dataPoint.list)) {
        return <></>;
    }

    return (
        <div className='row w-100'>
            {Object.entries(dataPoint.list).map(([key, value]) => 
                renderColumn(key, value)
            )}
        </div>
    );
}