// import React from 'react';
// import { Route, Redirect } from 'react-router-dom';
// import { GlobalConst } from '../appConfig/globalConst';

// const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       isAuthenticated ? <Component {...props} /> : <Redirect to={`${GlobalConst.AUTH_APP_URL}/login?redirect_url=${GlobalConst.BASE_URL}`} />
//     }
//   />
// );

// export default ProtectedRoute;

import React from 'react';
import { Route } from 'react-router-dom';
import { GlobalConst } from '../appConfig/globalConst';

const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthenticated) {
        return <Component {...props} />;
      } else {
        // console.log("I am the base url here :", GlobalConst)
        const redirectUrl = encodeURIComponent(GlobalConst.APP_URL);
        // console.log("I am the redirect url : ", redirectUrl);
        const loginUrl = `${GlobalConst.AUTH_APP_URL}/login?redirect_url=${redirectUrl}`;
        window.location.href = loginUrl;
        return null;
      }
    }}
  />
);

export default ProtectedRoute;