import React from 'react';
import ReactECharts from 'echarts-for-react';
import _ from "lodash"

const TrendAxis = ({ cveList: vulnerabilities, selectedPackage }) => {
    // Previous data processing code remains the same
    const monthlyData = vulnerabilities?.reduce((acc, vuln) => {
        const date = new Date(vuln.publishedDate);
        const month = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;

        if (!acc[month]) {
            acc[month] = {
                month,
                count: 0,
                vulnerabilities: [],
                severityCounts: {
                    CRITICAL: 0,
                    HIGH: 0,
                    MEDIUM: 0,
                    LOW: 0,
                    NA: 0
                }
            };
        }

        const severity = vuln.cvssV3BaseSeverity || vuln.cvssV2BaseSeverity || "NA";
        acc[month].count += 1;
        acc[month].vulnerabilities.push({
            id: vuln.cveId,
            severity,
            severityScore: vuln.cvssV3BaseScore || vuln.cvssV2BaseScore || 0,
        });
        acc[month].severityCounts[severity.toUpperCase()]++;

        return acc;
    }, {});

    // Rest of the data processing functions remain the same
    const processedData = Object.values(monthlyData)
        .sort((a, b) => a.month.localeCompare(b.month));

    const generateTrendLine = () => {
        const counts = processedData.map(item => item.count);
        const maxCount = Math.max(...counts);
        return processedData.map((item, index) => {
            const normalizedCount = ((item.count / maxCount) - 0.5);
            return [index, normalizedCount];
        });
    };

    const calculateAverageSeverity = () => {
        const severityMap = { CRITICAL: 4, HIGH: 3, MEDIUM: 2, LOW: 1, NA: 0 };
        let totalScore = 0;
        let totalVulns = 0;

        processedData.forEach(month => {
            Object.entries(month.severityCounts).forEach(([severity, count]) => {
                totalScore += severityMap[severity] * count;
                totalVulns += count;
            });
        });

        const avgScore = totalScore / totalVulns;
        if (avgScore >= 3) return 'High';
        if (avgScore >= 2) return 'Medium';
        if (avgScore >= 1) return 'Low';
        return 'NA';
    };

    const getPackageName = (pkg) => {
        if (!pkg) return '';
        const parts = pkg.split(':');
        return parts[1]?.split('/').pop() || '';
    };

    // Calculate min and max counts for better scaling
    const getScaledSymbolSize = (count, data) => {
        // Find the maximum count in the dataset
        const maxCount = Math.max(...data.map(item => item.count));
        const minCount = Math.min(...data.map(item => item.count));

        // Define size bounds
        const minSize = 15;  // Minimum circle size
        const maxSize = 40;  // Maximum circle size

        // If there's only one unique value, return a fixed size
        if (maxCount === minCount) {
            return (maxSize + minSize) / 2;
        }

        // Linear scaling with logarithmic adjustment for better distribution
        const scale = Math.log(count + 1) / Math.log(maxCount + 1);
        return minSize + (maxSize - minSize) * scale;
    };

    // Calculate date range
    const getDateRange = () => {
        if (!vulnerabilities?.length) return { firstDate: null, lastDate: null };

        const dates = vulnerabilities.map(vuln => new Date(vuln.publishedDate));
        const firstDate = new Date(Math.min(...dates));
        const lastDate = new Date(Math.max(...dates));

        return {
            firstDate,
            lastDate,
            formatDate: (date) => {
                if (!date) return '';
                const month = date.toLocaleString('default', { month: 'short' });
                const year = date.getFullYear();
                return `${month} ${year}`;
            }
        };
    };

    const { firstDate, lastDate, formatDate } = getDateRange();
    const dateRangeText = firstDate && lastDate
        ? `${formatDate(firstDate)} - ${formatDate(lastDate)}`
        : 'No data available';

    const option = {
        backgroundColor: 'transparent',
        // title: {
        //     text: !_.isEmpty(selectedPackage) ? selectedPackage.name : "",
        //     left: 20,
        //     top: 20,
        //     textStyle: {
        //         color: '#e2e8f0',
        //         fontSize: 24,
        //         fontWeight: 'bold',
        //         fontFamily: 'Inter, sans-serif'
        //     }
        // },
        tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(15, 23, 42, 0.9)',
            borderColor: '#0ea5e9',
            borderWidth: 1,
            textStyle: {
                color: '#e2e8f0'
            },
            formatter: function (params) {
                const scatterData = params.find(p => p.seriesType === 'scatter');
                if (scatterData) {
                    const monthData = processedData[scatterData.dataIndex];
                    return `
                        <div style="padding: 8px;">
                            <div style="font-weight: bold; margin-bottom: 4px; color: #e2e8f0;">${scatterData.name}</div>
                            <div style="color: #e2e8f0;">Vulnerabilities: ${monthData.count}</div>
                            <div style="font-size: 0.9em; color: #94a3b8;">
                                Critical: ${monthData.severityCounts.CRITICAL}<br/>
                                High: ${monthData.severityCounts.HIGH}<br/>
                                Medium: ${monthData.severityCounts.MEDIUM}<br/>
                                Low: ${monthData.severityCounts.LOW}
                            </div>
                        </div>
                    `;
                }
                return '';
            }
        },
        grid: {
            left: '5%',
            right: '5%',
            bottom: '25%',
            top: '20%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: processedData.map(item => {
                const date = new Date(item.month);
                return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
            }),
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#475569',
                    width: 2
                }
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                color: '#94a3b8',
                fontSize: 12,
                fontFamily: 'Inter, sans-serif',
                margin: -40,
                rotate: 45  // Added rotation for better readability
            },
            splitLine: {
                show: false
            },
            z: 10,
        },
        yAxis: {
            type: 'value',
            show: false,
            min: -1,
            max: 1
        },
        series: [
            // Gradient background area
            {
                type: 'line',
                smooth: true,
                showSymbol: false,
                data: generateTrendLine(),
                areaStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0,
                            color: 'rgba(56, 189, 248, 0.3)'
                        }, {
                            offset: 1,
                            color: 'rgba(56, 189, 248, 0)'
                        }]
                    }
                },
                lineStyle: {
                    width: 0
                },
                z: 1
            },
            // Trend line
            {
                name: 'Trend',
                type: 'line',
                smooth: true,
                showSymbol: false,
                data: generateTrendLine(),
                lineStyle: {
                    color: '#0ea5e9',
                    width: 3,
                    shadowBlur: 4,
                    shadowColor: '#0ea5e9'
                },
                z: 2
            },
            // Vulnerability points with labels
            {
                type: 'scatter',
                data: processedData.map((item, index) => ({
                    value: [index, 0],
                    itemStyle: {
                        color: new Function('params', `
                            const gradient = new echarts.graphic.RadialGradient(0.5, 0.5, 1, [{
                                offset: 0,
                                color: '#38bdf8'
                            }, {
                                offset: 1,
                                color: '#0284c7'
                            }]);
                            return gradient;
                        `),
                        shadowBlur: 5,
                        shadowColor: '#0ea5e9'
                    },
                    // Use the new scaling function
                    symbolSize: getScaledSymbolSize(item.count, processedData),
                    label: {
                        show: true,
                        position: 'top',
                        distance: 10,
                        formatter: () => item.count,
                        color: '#38bdf8',
                        fontSize: 14,
                        fontWeight: 'bold',
                        fontFamily: 'Inter, sans-serif',
                        textShadow: '0 0 3px #0ea5e9'
                    }
                })),
                z: 3
            }
        ],
        graphic: [
            // Risk Level Indicator
            // {
            //     type: 'rect',
            //     left: 20,
            //     top: 20,
            //     shape: { width: 150, height: 36, r: 18 },
            //     style: {
            //         fill: getAIAssetScanStrokeColor(selectedPackage.severityCategory.toUpperCase()),
            //         // 'rgba(56, 189, 248, 0.1)',
            //         stroke: getAIAssetScanStrokeColor(selectedPackage.severityCategory.toUpperCase()),
            //         // '#0ea5e9',
            //         lineWidth: 2,
            //         shadowBlur: 10,
            //         shadowColor: getAIAssetScanStrokeColor(selectedPackage.severityCategory.toUpperCase())
            //         // shadowColor: '#0ea5e9'
            //     }
            // },
            // {
            //     type: 'text',
            //     left: 50,
            //     top: 29,
            //     style: {
            //         text: `${selectedPackage.severityCategory.toUpperCase()} Risk`,
            //         fill: '#FFFFFF',
            //         // fill: getFontColor(selectedPackage.severityCategory.toUpperCase()) ,
            //         // fill: '#38bdf8',
            //         fontSize: 14,
            //         fontFamily: 'Inter, sans-serif',
            //         fontWeight: 'bold',
            //         // textShadow: '0 0 3px #0ea5e9',
            //     }
            // },
            // Legend
            // {
            //     type: 'rect',
            //     left: 20,
            //     top: 20,
            //     shape: { width: 280, height: 60, r: 8 },
            //     style: {
            //         fill: 'rgba(56, 189, 248, 0.1)',
            //         stroke: '#0ea5e9',
            //         lineWidth: 2,
            //         shadowBlur: 10,
            //         shadowColor: '#0ea5e9'
            //     }
            // },
            // Updated legend circle with simple gradient colors
            // {
            //     type: 'circle',
            //     left: 30,
            //     top: 30,
            //     shape: { r: 12 },
            //     style: {
            //         fill: {
            //             type: 'radial',
            //             x: 0.5,
            //             y: 0.5,
            //             r: 1,
            //             colorStops: [{
            //                 offset: 0,
            //                 color: '#38bdf8'  // Lighter blue
            //             }, {
            //                 offset: 1,
            //                 color: '#0284c7'  // Darker blue
            //             }]
            //         },
            //         shadowBlur: 15,
            //         shadowColor: '#0ea5e9'
            //     }
            // },
            // {
            //     type: 'text',
            //     left: 60,
            //     top: 30,
            //     style: {
            //         text: 'Vulnerability Detected',
            //         fill: '#e2e8f0',
            //         fontSize: 13,
            //         fontFamily: 'Inter, sans-serif',
            //         fontWeight: 'bold'
            //     }
            // },
            // {
            //     type: 'text',
            //     left: 60,
            //     top: 50,
            //     style: {
            //         text: 'Circle size indicates count of vulnerabilities',
            //         fill: '#94a3b8',
            //         fontSize: 11,
            //         fontFamily: 'Inter, sans-serif'
            //     }
            // },
            // Stats
            {
                type: 'rect',
                left: 20,
                top: 20,
                shape: { width: 200, height: 60, r: 8 },
                style: {
                    fill: 'rgba(56, 189, 248, 0.1)',
                    stroke: '#0ea5e9',
                    lineWidth: 2,
                    shadowBlur: 5,
                    shadowColor: '#0ea5e9'
                }
            },
            {
                type: 'text',
                left: 50,
                top: 40,
                style: {
                    text: `Total Occurrences: ${vulnerabilities?.length || 0}`,
                    fill: '#e2e8f0',
                    fontSize: 13,
                    lineHeight: 20,
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 'bold',
                    align: 'center',
                    textAlign: 'center',
                    textVerticalAlign: 'middle'
                },
                textConfig: {
                    position: 'center'
                }
            },
            {
                type: 'text',
                left: 50,
                top: 60,
                style: {
                    text: dateRangeText,
                    fill: '#94a3b8',
                    fontSize: 12,
                    fontFamily: 'Inter, sans-serif',
                }
            }
            // {
            //     type: 'text',
            //     right: 50,
            //     bottom: 30,
            //     style: {
            //         text: `Avg. Severity: ${calculateAverageSeverity()}`,
            //         fill: '#e2e8f0',
            //         fontSize: 13,
            //         fontFamily: 'Inter, sans-serif',
            //         fontWeight: 'bold'
            //     }
            // }
        ],
        dataZoom: [
            {
                type: 'slider',
                show: processedData.length > 10,
                xAxisIndex: 0,
                bottom: 10,
                height: 20,
                borderColor: '#475569',
                textStyle: {
                    color: '#94a3b8'
                },
                brushStyle: {
                    color: 'rgba(56, 189, 248, 0.2)'
                },
                handleStyle: {
                    color: '#0ea5e9',
                    borderColor: '#0ea5e9'
                },
                moveHandleStyle: {
                    color: '#0ea5e9'
                },
                selectedDataBackground: {
                    lineStyle: {
                        color: '#0ea5e9'
                    },
                    areaStyle: {
                        color: '#0ea5e9'
                    }
                },
                emphasis: {
                    handleStyle: {
                        borderColor: '#38bdf8'
                    }
                }
            },
            {
                type: 'inside',
                xAxisIndex: 0,
                zoomOnMouseWheel: true,
                moveOnMouseMove: true
            }
        ],
    };

    return (
        <div>
            <ReactECharts
                option={option}
                style={{
                    height: '400px',
                    width: '100%'
                }}
            />
        </div>
    );
};

export default TrendAxis;