import _ from "lodash";
import { useEffect, useState } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Loader from "../../../shared/sharedComponents/loader";
import TrendGraph from "./trendAxis";
import { getArtifactImage } from "../../../shared/helper/getArtifactImageHelper";
import { GlobalConst } from "../../../shared/appConfig/globalConst";

const TrendView = ({ selectedPackage, onCloseTrendView, isTrendViewOpen }) => {
  const [loadingPackageDetails, setLoadingPackageDetails] = useState(null);
  const [componentCves, setComponentCves] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(selectedPackage)) {
      getPackageAPI(getSubstringBeforeLastAt(selectedPackage));
    }
  }, [selectedPackage]);

  const getSubstringBeforeLastAt = (str) => {
    if (!str.includes('@')) return str;  // Return full string if no @ exists
    const regex = /(.+)(?=@[^@]*$)/;
    const match = str.match(regex);
    return match ? match[1] : str;
  }

  const getPackageAPI = (value) => {
    setLoadingPackageDetails(true)
    axios
      .get(`${GlobalConst.API_URL}/foss/cve/v1/search?s=${value.trim()}&em=true`)
      .then((response) => {
        setLoadingPackageDetails(false)
        if (response.data && !_.isEmpty(response.data.responseData)) {
          setComponentCves(response.data.responseData)
        }
      });
  };

  const splitPackageUrl = selectedPackage.split(":")
  let [artifact, componentRaw] =  splitPackageUrl[1].split(/\/(.*)/s);
  let component = '';
  let imageTitle = '';
  if (artifact === 'maven') {
    component = _.replace(getSubstringBeforeLastAt(componentRaw), '/', ':');
    console.log(component,"raw",componentRaw)
    imageTitle = 'java';
  }
  else {
    component = getSubstringBeforeLastAt(componentRaw);
    imageTitle = artifact;
  }

  return <>
    <Modal show={isTrendViewOpen} onHide={onCloseTrendView} size="lg" backdrop={'static'}>
      <Modal.Header closeButton>
        <Modal.Title><span><img src={getArtifactImage(artifact)} style={{ width: 25 }} className="me-1" title={imageTitle} alt={artifact} /> <span title={component}>{component}</span></span></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: 400, overflowY: "auto" }}>
          {!_.isEmpty(componentCves) ?
            <TrendGraph cveList={componentCves} selectedPackage={selectedPackage}/>
            : !loadingPackageDetails ? <p className="text-center">No Data found</p> : null}
        </div>
      </Modal.Body>
    </Modal>
    {loadingPackageDetails && <Loader />}
  </>

};

export default TrendView;
