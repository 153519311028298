
import { Tag, Space, Table, Popconfirm } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import FileDirectory from './fileDirectory';
import { getFontColor, getStrokeColor } from '../../shared/helper/genHelper';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
// import NoDataFound from './noDataFound';
import axios from 'axios';
import Loader from '../../shared/sharedComponents/loader';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import NoDataFound from '../../shared/sharedComponents/noDataFound';


const expandedRowRender = (props, setShowFileExplorerModal, setSelectedData, handleRevokeFalsePositiveClick) => {

    let subData = [];

    const confirm = (e, row) => {
        setShowFileExplorerModal(false);
        handleRevokeFalsePositiveClick(row);
    };

    const columns = [
        {
            title: 'Line Number',
            dataIndex: 'lineNumber',
            key: 'lineNumber',
            width: '12%',
        },
        {
            title: 'CWE ID',
            dataIndex: 'cweId',
            key: 'cweId',
            width: '10%',
        },
        {
            title: 'Vulnerability Title',
            key: 'vulTitle',
            dataIndex: 'vulTitle',
            width: '30%',
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
            align: 'center',
            width: '12%',
            render: (index, row) => {
                if (!_.isEmpty(row) && !_.isEmpty(row.severity)) {
                    return (
                        <Tag style={{ color: getFontColor(row.severity.toUpperCase()), fontWeight: 600, textTransform: 'capitalize' }} color={getStrokeColor(row.severity.toUpperCase())}>
                            {row.severity}
                        </Tag>);
                }
                else {
                    return '-';
                }
            },
        },
        {
            title: 'Action',
            key: 'operation',
            width: '30%',
            render: (col, row) => {
                return (
                    <Space size="middle">
                        <a href='#' className='me-2'
                            onClick={(e) => {
                                e.preventDefault();
                                setShowFileExplorerModal(true);
                                setSelectedData({ fileId: row.fileId, lineNumber: row.lineNumber });
                            }}
                        >View Detail</a>
                        <Popconfirm
                            title="Revoke False Positive"
                            description="Are you sure you want to revoke this false positive? This action cannot be undone."
                            onConfirm={(e) => confirm(e, row)}
                            okText="Confirm"
                            cancelText="Cancel"
                        >
                            <button
                                className='btn btn-sm btn-warning py-0'
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >Revoke</button>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    if (!_.isEmpty(props) && !_.isEmpty(props.vulnerabilities)) {

        let newData = [];
        props.vulnerabilities.forEach((row, i) => {
            newData.push({
                key: i.toString(),
                lineNumber: row.line_number,
                cweId: `CWE-${row.cwe_ids.join(',')}`,
                vulTitle: row.title,
                severity: row.severity,
                operation: '',
                fileId: props.fileId,
                fingerPrint: row.fingerprint
            })
        })
        subData = newData;
    }
    else {
        subData = [];
    }
    return <Table columns={columns} dataSource={subData} pagination={false} locale={{ emptyText: <NoDataFound title={'No Data'} /> }} />;
};

export default function SastFalsePositiveTabView({ id, userPrefrences }) {

    const MySwal = withReactContent(Swal)
    const [showFileExplorerModal, setShowFileExplorerModal] = useState(false);
    const [data, setData] = useState([]);

    const [selectedData, setSelectedData] = useState({});
    const [loading, setLoading] = useState(false);
    const [reportDetails, setReportDetails] = useState({});

    const columns = [
        {
            title: <><span className='me-3'>File Name</span> </>,
            dataIndex: 'fileName',
            key: 'fileName',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'center'
        },
        {
            title: 'Critical',
            dataIndex: 'critical',
            key: 'critical',
            align: 'center',
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("CRITICAL"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("CRITICAL")}
                        className={"mx-auto text-center"}
                    >
                        {row.critical}
                    </Tag>
                </div>);
            }
        },
        {
            title: 'High',
            dataIndex: 'high',
            key: 'high',
            align: 'center',
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("HIGH"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("HIGH")}
                        className={"mx-auto text-center"}
                    >
                        {row.high}
                    </Tag>
                </div>);
            }
        },
        {
            title: 'Medium',
            dataIndex: 'medium',
            key: 'medium',
            align: 'center',
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("MEDIUM"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("MEDIUM")}
                        className={"mx-auto text-center"}
                    >
                        {row.medium}
                    </Tag>
                </div>);
            }
        },
        {
            title: 'Low',
            dataIndex: 'low',
            key: 'low',
            align: 'center',
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("LOW"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("LOW")}
                        className={"mx-auto text-center"}
                    >
                        {row.low}
                    </Tag>
                </div>);
            }
        }
    ];

    useEffect(() => {
        getFalsePositiveViewData();
    }, [])

    const getTotal = (vulCount) => {
        return (vulCount.critical + vulCount.high + vulCount.medium + vulCount.low);
    }

    const getFalsePositiveViewData = () => {

        const postBody = {
            "reportId": id
        };
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/sast/asset/report/false-positive`, postBody)
            .then(op => {
                setLoading(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setReportDetails(op.data);
                    if (!_.isEmpty(op.data) && !_.isEmpty(op.data.scanReport.vulnerabilities_by_file)) {
                        let newData = [];
                        op.data.scanReport.vulnerabilities_by_file.forEach((row, i) => {
                            newData.push({
                                key: i.toString(),
                                fileName: row.fileName,
                                total: getTotal(row.vulnerabilityCounts),
                                critical: row.vulnerabilityCounts.critical,
                                high: row.vulnerabilityCounts.high,
                                medium: row.vulnerabilityCounts.medium,
                                low: row.vulnerabilityCounts.low,
                                vulnerabilities: row.vulnerabilities,
                                fileId: row.fileId
                            });
                        });
                        setData(newData);
                    }
                    else {
                        setData([]);
                    }
                }
                else {
                    setData([]);
                }
            })
            .catch(e => { setLoading(false); })
    }

    const handleRevokeFalsePositiveClick = (row) => {
        const postBody = {
            "reportId": id,
            "fileId": row.fileId,
            "fingerprints": [row.fingerPrint]
        };
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/sast/asset/file/false-positive/rollback`, postBody)
            .then(op => {
                setLoading(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    MySwal.fire({
                        icon: 'success',
                        text: 'The false positive has been successfully revoked.',
                        showCancelButton: false,
                        buttonsStyling: true,
                        confirmButtonText: "OK",
                        customClass: {
                            confirmButton: "btn fw-bold btn-primary hover-elevate-up",
                            htmlContainer: "text-white",
                        },
                        background: "#1f242e"
                    }).then(function (result) {
                        if (result.value) {
                            getFalsePositiveViewData();
                        }
                    });
                }
            })
            .catch(e => {
                setLoading(false);
                MySwal.fire({
                    icon: 'error',
                    title: 'Error revoking false positive',
                    text: 'An error occurred while revoking the false positive. Please try again or contact our support team at support@sec1.io',
                    showCancelButton: false,
                    buttonsStyling: true,
                    confirmButtonText: "OK",
                    customClass: {
                        confirmButton: "btn fw-bold btn-primary hover-elevate-up",
                        htmlContainer: "text-white",
                    },
                    background: "#1f242e"
                });
            })
    }

    if (loading) {
        return (<Loader />)
    }
    if (_.isEmpty(data)) {
        return (<NoDataFound title={"No false positive data"} desc={""} isShowImage={true} />);
    }
    else {
        return (
            <section className="my-2">
                <div>
                    <div className='d-flex align-items-center justify-content-start mb-0'>
                    </div>
                    <Table
                        className='custom-table'
                        columns={columns}
                        // expandable={{ expandedRowRender }}
                        expandable={{
                            expandedRowRender: (record) => expandedRowRender(record, setShowFileExplorerModal, setSelectedData, handleRevokeFalsePositiveClick),
                        }}
                        dataSource={data}
                        size="small"
                        locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
                    />
                </div>
                <FileDirectory setShowFileExplorerModal={setShowFileExplorerModal} showFileExplorerModal={showFileExplorerModal} reportDetails={reportDetails} selectedData={selectedData} userPrefrences={userPrefrences} />
            </section>
        )
    }
}