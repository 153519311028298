import React, { useEffect, useState, useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import _ from 'lodash';
import ConvertResourceGraphData from './convertResourceGraphData';
import { getCloudIcon, getStrokeColor } from "../../../shared/helper/genHelper";

export default function ResourceGraph({ apidata }) {
    const [graphData, setGraphData] = useState({ nodes: [], links: [], categories: [] });

    useEffect(() => {
        const convertedData = ConvertResourceGraphData(apidata);
        setGraphData(convertedData);
    }, [apidata]);

    const options = useMemo(() => ({
        series: [
            {
                type: 'graph',
                layout: 'force',
                animation: false,
                label: {
                    show: true,
                    position: 'right',
                    formatter: (params) => {
                        const vulnerabilities = params.data.vulnerability;
                        if (vulnerabilities) {
                            const total = _.sum(Object.values(vulnerabilities));
                            const severityOrder = ['critical', 'high', 'medium', 'low'];
                            const highestSeverity = severityOrder.find(sev => vulnerabilities[sev] > 0) || 'low';
                            return [
                                `{name|${params.name}}`,
                                `{${highestSeverity}|${total}}`
                            ].join('\n');
                        }
                        return `{name|${params.name}}`;
                    },
                    rich: {
                        name: {
                            color: '#ffffff',
                            fontSize: 12,
                        },
                        critical: {
                            backgroundColor: getStrokeColor('CRITICAL'),
                            color: '#212121',
                            borderRadius: 10,
                            padding: [2, 4],
                            fontSize: 10,
                        },
                        high: {
                            backgroundColor: getStrokeColor('HIGH'),
                            color: '#212121',
                            borderRadius: 10,
                            padding: [2, 4],
                            fontSize: 10,
                        },
                        medium: {
                            backgroundColor: getStrokeColor('MEDIUM'),
                            color: '#212121',
                            borderRadius: 10,
                            padding: [2, 4],
                            fontSize: 10,
                        },
                        low: {
                            backgroundColor: getStrokeColor('LOW'),
                            color: '#212121',
                            borderRadius: 10,
                            padding: [2, 4],
                            fontSize: 10,
                        },
                    },
                },
                data: graphData.nodes.map(node => ({
                    ...node,
                    symbolSize: node.vulnerability ? 20 : 15,
                    symbol: `image://${getCloudIcon(node.category, "k8s") || getCloudIcon('')}`,
                })),
                categories: graphData.categories,
                force: {
                    repulsion: 50,
                    edgeLength: 25,
                    layoutAnimation: false,
                },
                edges: graphData.links,
                roam: true,
                lineStyle: {
                    color: 'source',
                    curveness: 0.3,
                },
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 1,
                    shadowBlur: 10,
                    shadowColor: 'rgba(0, 0, 0, 0.3)',
                },
                emphasis: {
                    focus: 'adjacency',
                    lineStyle: {
                        width: 2,
                    },
                },
                focusNodeAdjacency: false,
                zoom: 4,
                center: ['50%', '50%'],
            }
        ]
    }), [graphData]);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <ReactEcharts
                option={options}
                style={{ height: '100%', width: '100%' }}
                opts={{ renderer: 'canvas' }}
            />
        </div>
    );
}