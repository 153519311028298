import React from 'react';
import { Field } from 'formik';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';

// Define the functional component
export const HeadersOptionsHelper = ({ headers, setHeaders, values }) => {

    // console.log("headers : ", headers);
    const addHeader = () => {
        if (headers.length < 5) {
            const newHeaders = [...headers, { name: '', value: '' }];
            setHeaders(newHeaders);
            // updateHeaders(newHeaders);
        }
    };

    const removeHeader = (index) => {
        if (headers.length > 1) { // Ensure at least one header remains
            const newHeaders = headers.filter((_, i) => i !== index);
            setHeaders(newHeaders);
            // updateHeaders(newHeaders);
        }
    };

    const handleHeaderChange = (index, field, value) => {
        const newHeaders = [...headers];
        newHeaders[index] = { ...newHeaders[index], [field]: value };
        setHeaders(newHeaders);
        // updateHeaders(newHeaders);
    };

    return (
        <>
            <div className="mb-3">
                <div className='row text-left mb-1'>
                    <div className='col-sm-5'>Key</div>
                    <div className='col-sm-5'>Value</div>
                    <div className='col-sm-1'></div>
                </div>
                {headers.map((header, index) => (
                    <div key={index} className="row mb-2 align-items-center">
                        <div className="col-sm-5">
                            <div className="input-group">
                                <Field
                                    name={`headers[${index}].name`}
                                    type="text"
                                    className="form-control"
                                    placeholder={`Key`}
                                    value={header.name}
                                    onChange={(e) => handleHeaderChange(index, 'name', e.target.value)}
                                    style={{ padding: '10px 20px' }}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6 p-0">
                            <div className="input-group">
                                <Field
                                    name={`headers[${index}].value`}
                                    type="text"
                                    className="form-control"
                                    placeholder={`Value`}
                                    value={header.value}
                                    onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
                                    style={{ padding: '10px 20px' }}
                                />
                            </div>
                        </div>
                        <div className="col-sm-1 d-flex justify-content-end">
                            {/* {headers.length == 5 && (
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>
                            )}
                            {headers.length < 5 && (
                                <span className="input-group-text" onClick={addHeader}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>
                            )} */}

                            {index == 0 && (
                                <span className="">
                                    <CiCircleMinus
                                        className="text-danger"
                                        style={{ fontSize: 22, cursor: 'pointer' }}
                                        onClick={() => removeHeader(index)}
                                    />
                                </span>
                            )}
                            {index > 0 && (
                                <span className="">
                                    <CiCircleMinus
                                        className="text-danger"
                                        style={{ fontSize: 22, cursor: 'pointer' }}
                                        onClick={() => removeHeader(index)}
                                    />
                                </span>
                            )}

                        </div>
                    </div>
                ))}

                <div className="mb-3">
                    <button
                        type='button'
                        onClick={addHeader}
                        disabled={headers.length == 5}
                        className="p-2 text-center d-flex mb-0 align-items-center justify-content-center w-100"
                        style={{ border: '1px dashed rgb(73 80 87)', cursor: 'pointer', borderRadius: 8 }}
                    >
                        <CiCirclePlus style={{ fontSize: '22px' }} className='me-2' /> Add header
                    </button>
                </div>
                {/* <div className='p-3'>

                </div> */}
            </div>
        </>
    );
};