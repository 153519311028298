import { createSlice } from "@reduxjs/toolkit";

const scannerReducer = createSlice({
    name: 'scannerReducer',
    initialState: {
        pageLoaderState: false,
        scanModalViewStatus: false,
        scanInProgress: false,

        allUserRepoList: [], // list of all the github repos fetched from the user account of github portal.
        userListPageNumber: 0,

        scannedRecords: [], // are the list of all the scanned records triggered by the user.
        scanGuidResponse: [], // contains all the list of current scans which are triggered.
        noScannedRecords: false,
        dashboardData: false,
        currentDashboardPageNumber: 1,
        firstTrigger: false,
        scanningFromDragStatus: false,
    },
    reducers: {
        loaderState: (state, action) => {
            state.pageLoaderState = action.payload;
        },
        // setLoadingRows: (state, action) => {
        //     state.loadingRows = action.payload;
        // },
        getDashboardDataState: (state, action) => {
            state.dashboardData = action.payload;
        },
        setScanGuidResponse: (state, action) => {
            // console.log("Acr", action.payload)
            state.scanGuidResponse = action.payload;
        },
        setScanModalViewState: (state, action) => {
            state.scanModalViewStatus = action.payload;
        },
        setScanInProgress: (state, action) => {
            state.scanInProgress = action.payload;
        },
        // setScannedData: (state, action) => {
        //     state.scannedData = action.payload;
        // },
        setScannedRecords: (state, action) => {
            state.scannedRecords = action.payload;
        },
        setFirstTrigger: (state, action) => {
            state.firstTrigger = action.payload;
        },
        setAllUserRepoList: (state, action) => {
            state.allUserRepoList = action.payload;
        },
        setUserListPageNumber: (state, action) => {
            state.userListPageNumber = action.payload;
        },
        setNoScannedRecords: (state, action) => {
            state.noScannedRecords = action.payload;
        },
        updateScannedRecord: (state, action) => {
            const itemIndex = state.scannedRecords.findIndex(item => action.payload.reportId === item.reportId);
            if (itemIndex !== -1) {
                state.scannedRecords[itemIndex] = { ...state.scannedRecords[itemIndex], ...action.payload };
            }
        },
        addGuid: (state, action) => {
            state.scanGuidResponse = [...state.scanGuidResponse, ...action.payload];
        },
        setCurrentDashboardPageNumber: (state, action) => {
            state.currentDashboardPageNumber = action.payload;
        },
        setSanningFromDrag: (state, action) => {
            state.scanningFromDragStatus = action.payload;
        }
    }
})

export const { loaderState, setScanModalViewState, setScanInProgress, setScanGuidResponse, getDashboardDataState, setFirstTrigger, setScannedRecords, setAllUserRepoList, setNoScannedRecords, updateScannedRecord, addGuid, setCurrentDashboardPageNumber, setSanningFromDrag, setUserListPageNumber } = scannerReducer.actions;

export default scannerReducer.reducer;