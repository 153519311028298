import React, { useEffect, useState } from 'react';
import { faCogs, faArrowRight, faExclamationTriangle, faInfoCircle, faInfo } from '@fortawesome/free-solid-svg-icons';
// import {  } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScanOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import chip from '../../assets/img/chip.png';
import {getUserDisplayName} from '../../shared/helper/genHelper';

import { useSelector, useDispatch } from 'react-redux';

import RepoListScannedDataTable from '../scaDashboard/RepoListScannedDataTable';
import _ from 'lodash';
import Loader from './loader';
import axios from 'axios';
import { GlobalConst } from '../../shared/appConfig/globalConst';


export default function WelcomeScreen() {

    const reduxState = useSelector(state => state);

    const [data, setData] = useState(false);
    // const [] = useState();

    useEffect(() => {
        // console.log("On loaded::", reduxState);
        if (reduxState.authReducer.userDetails.preferred_username) {
            getRepoList();
        }

    }, [reduxState]);

    const getRepoList = () => {
        // console.log("Fired here")
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/get-repo-list`, { "urlType": "github" })
            .then(op => {
                // console.log("output is ::", op);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    processData(op.data.data);
                }

            })
            .catch((e) => { console.log("Exception: ", e); })
    }

    const processData = (rows) => {
        if (!_.isEmpty(rows)) {
            let newData = [];
            rows.forEach((el, ind) => {
                newData.push({
                    serialNumber: ind + 1,
                    name: el.name,
                    owner: el.owner,
                    totalVulnerabilities: 0,
                    org: el.group,
                    total: 0,
                    critical: 0,
                    high: 0,
                    medium: 0,
                    low: 0,
                    type: 'github',
                    vulnerabilityCount: {
                        critical: 1,
                        high: 2,
                        medium: 5,
                        low: 0
                    }
                });
            });
            setData(newData);
        }
    }


    // const data = [{
    //     serialNumber: 1,
    //     name: 'resillient-foss-client',
    //     owner: 'dottribe',
    //     totalVulnerabilities: 8,
    //     org: 'dottribe',
    //     total: 8,
    //     critical: 1,
    //     high: 2,
    //     medium: 5,
    //     low: 0,
    //     type: 'github',
    //     vulnerabilityCount: {
    //         critical: 1,
    //         high: 2,
    //         medium: 5,
    //         low: 0
    //     }
    // },
    // {
    //     serialNumber: 2,
    //     name: '	test-scan',
    //     owner: 'vinayakpadwalkar',
    //     org: 'vinayakpadwalkar',
    //     totalVulnerabilities: 405,
    //     total: 405,
    //     critical: 12,
    //     high: 85,
    //     medium: 20,
    //     low: 88,
    //     type: 'github',
    //     vulnerabilityCount: {
    //         critical: 12,
    //         high: 85,
    //         medium: 20,
    //         low: 88
    //     }
    // }
    //     , {
    //     serialNumber: 3,
    //     name: 'scan-repo',
    //     owner: 'security',
    //     org: 'vinayakpadwalkar',
    //     totalVulnerabilities: 2,
    //     total: 10,
    //     critical: 12,
    //     high: 85,
    //     medium: 20,
    //     low: 88,
    //     type: 'gitlab',
    //     vulnerabilityCount: {
    //         critical: 12,
    //         high: 85,
    //         medium: 20,
    //         low: 88
    //     }
    // }, {
    //     serialNumber: 4,
    //     name: 'crafsmen',
    //     owner: 'crafsmen',
    //     org: 'vinayakpadwalkar',
    //     totalVulnerabilities: 23,
    //     total: 23,
    //     critical: 12,
    //     high: 85,
    //     medium: 20,
    //     low: 88,
    //     type: 'bitbucket',
    //     vulnerabilityCount: {
    //         critical: 12,
    //         high: 85,
    //         medium: 20,
    //         low: 88
    //     }
    // }

    // ];


    return (
        <section className="mx-3">
            <section className='card custom-card p-3 text-left mb-4' style={{ background: '#1e464d', border: '1px solid #2c6a74' }}>
                {/* <FontAwesomeIcon className='mb-3' icon={faGithub} fontSize={60} /> */}
                {/* <h1 className="text-white mb-1">First time setup</h1> */}
                <p className='mb-1'><strong> Hi {getUserDisplayName(reduxState.authReducer.userDetails)}! </strong>  You have logged in for first time. To change the default settings, please <a href={'/settings'}>click here</a></p>
                <p className='mb-0'><b style={{ fontSize: 16 }}>{(!_.isEmpty(data)) ? data.length : 0}</b> repositories found, automatic scan triggered for all. <Tooltip title="Found 17 branches with 44 vulnerabilities"><img src={chip} className='ms-2' style={{ width: 18 }} /></Tooltip></p>
                {/* <p></p> */}
                {/* <p><button className='btn btn-primary btn-lg'><FontAwesomeIcon icon={faCogs} className='me-2' />Let's configure</button></p> */}

            </section>

            <section>
                {/* <div className="alert alert-primary-custom mb-4" role="alert"> */}
                {/* if less then 5 then all your repositories */}

                {/* </div> */}

                <div className='card custom-card'>
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1"></h4>
                        {/* <a href={'#'}>View all <FontAwesomeIcon className="ms-2" icon={faArrowRight} /></a> */}
                    </div>
                    {/* <div className='bg-light-subtle'>
                        <div className='d-flex align-items-center justify-content-start text-center w-100'>
                            <div className='row w-100' style={{ marginLeft: 360 }}>
                                <div className='col-1'>
                                    <div className='p-3'>
                                        <p className="mb-1" style={{ color: '#eff2f7' }}>Total</p>
                                        <h5 className='mb-0' style={{ fontWeight: 600 }}><span className="counter-value text-white" data-target="106">0</span></h5>

                                    </div>
                                </div>
                                <div className='col-1'>
                                    <div className='p-3'>
                                        <p className="mb-1" style={{ color: '#eff2f7' }}>Critical</p>
                                        <h5 className='mb-0' style={{ fontWeight: 600, color: '#b22222' }}><span className="counter-value" data-target="106">0</span></h5>

                                    </div>
                                </div>
                                <div className='col-1'>
                                    <div className='p-3'>
                                        <p className="mb-1" style={{ color: '#eff2f7' }}>High</p>
                                        <h5 className='mb-0' style={{ fontWeight: 600, color: '#f26d24' }}><span className="counter-value" data-target="106">0</span></h5>

                                    </div>
                                </div>
                                <div className='col-1'>
                                    <div className='py-3 px-2'>
                                        <p className="mb-1" style={{ color: '#eff2f7' }}>Medium</p>
                                        <h5 className='mb-0' style={{ fontWeight: 600, color: '#f8a814' }}><span className="counter-value" data-target="106">0</span></h5>

                                    </div>
                                </div>
                                <div className='col-1'>
                                    <div className='p-3'>
                                        <p className="mb-1" style={{ color: '#eff2f7' }}>Low</p>
                                        <h5 className='mb-0' style={{ fontWeight: 600, color: '#919191' }}><span className="counter-value" data-target="106">0</span></h5>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className='card-body py-0'>



                        <RepoListScannedDataTable dataSet={data} />




                        {/* <table className='custom-table table table-dark'>
                            <tbody>

                                <tr>
                                    <td>
                                        <div>
                                            <h6 className="fw-normal mb-0"><a href="#">crafsmen-frontend</a></h6>
                                            <p className='mb-0 fade-text'><small>main</small></p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <Loader /> <span className='ms-2 blink_me'>Scanning...</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center justify-content-start'>
                                            <div className='me-2'>Vulnerabilities: </div>
                                            <Tooltip title="Critical">
                                                <div className='me-3 ' style={{ color: '#b22222' }}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                                    <span className='ms-1 size-row-count'>5</span>
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="High">
                                                <div className='me-3' style={{ color: '#f26d24' }}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                                    <span className='ms-1 size-row-count'>34</span>
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="Medium">
                                                <div className='me-3' style={{ color: '#f8a814' }}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                                    <span className='ms-1 size-row-count'>67</span>
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="Low">
                                                <div style={{ color: '#919191' }}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                                    <span className='ms-1 size-row-count'>0</span>
                                                </div>
                                            </Tooltip>

                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <h6 className="fw-normal mb-0">prosourcingHubBackend</h6>
                                            <p className='mb-0 fade-text'><small>master</small></p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <Loader /> <span className='ms-2 blink_me'>Scanning...</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center justify-content-start'>
                                            <div className='me-2'>Vulnerabilities: </div>
                                            <Tooltip title="Critical">
                                                <div className='me-3' style={{ color: '#919191' }}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                                    <span className='ms-1 size-row-count'>0</span>
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="High">
                                                <div className='me-3' style={{ color: '#919191' }}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                                    <span className='ms-1 size-row-count'>0</span>
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="Medium">
                                                <div className='me-3' style={{ color: '#919191' }}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                                    <span className='ms-1 size-row-count'>0</span>
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="Low">
                                                <div style={{ color: '#919191' }}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                                    <span className='ms-1 size-row-count'>0</span>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <h6 className="fw-normal mb-0">digicom-expo-app</h6>
                                            <p className='mb-0 fade-text'><small>main</small></p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <Loader /> <span className='ms-2 blink_me'>Scanning...</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center justify-content-start'>
                                            <div className='me-2'>Vulnerabilities: </div>
                                            <Tooltip title="Critical" >
                                                <div className='me-3' style={{ color: '#919191' }}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                                    <span className='ms-1 size-row-count'>0</span>
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="High">
                                                <div className='me-3' style={{ color: '#919191' }}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                                    <span className='ms-1 size-row-count'>0</span>
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="Medium">
                                                <div className='me-3' style={{ color: '#919191' }}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                                    <span className='ms-1 size-row-count'>0</span>
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="Low">
                                                <div style={{ color: '#919191' }}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                                    <span className='ms-1 size-row-count'>0</span>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table> */}
                    </div>
                </div>

            </section>

            <section className='p-4 px-0'>
                <h2 className='text-white fw-normal other-header pb-2 mb-4'>Other options</h2>
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className='card custom-card p-4'>
                            <h4 className='text-white fw-normal mb-2'>Scan a public repo</h4>
                            <p className='mb-4' style={{ color: 'rgb(97,97,97)', minHeight: 110 }}>Quickly assess the security of any publicly available Git repository. Our feature enables you to analyze open-source projects for potential vulnerabilities, ensuring your codebase remains secure.</p>
                            <div>
                                <a href='#'><ScanOutlined className={'me-2'} />Scan</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-3'>
                        <div className='card custom-card p-4'>
                            <h4 className='text-white fw-normal mb-2'>Scan docker container</h4>
                            <p className='mb-4' style={{ color: 'rgb(97,97,97)', minHeight: 110 }}>Ensure the security of your private Docker containers effortlessly. Detect and resolve vulnerabilities within your containerized applications with ease. </p>
                            <div>
                                <a href='#'><FontAwesomeIcon icon={faCogs} className={'me-2'} />Setup</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-3'>

                    </div>
                    <div className='col-sm-3'></div>
                </div>
            </section>
        </section>
    );
}