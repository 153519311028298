import _ from 'lodash';
import Alert from 'react-bootstrap/Alert';

export default function RemediationTab({ remediation, selectedRemediation }) {

    if (_.isEmpty(remediation)) {
        return (
            <Alert variant={'info'}>
                Click on the "Get Remediation" button above to load remediation for this file.
            </Alert>
        );
    }
    else {
        return (
            <>
                {!_.isEmpty(selectedRemediation) ? <div className='my-1'>
                    <h6 className='mb-3'><strong>{selectedRemediation.title}</strong></h6>
                    <p className='mb-3'>{selectedRemediation.comment}</p>
                    <div className='error-code-div-highlight p-2 mb-2'><h6>Old Code </h6><div>{selectedRemediation.originalCode}</div></div>
                    <div className='success-code-div-highlight p-2 mb-2'><h6 >New Code </h6><div>{selectedRemediation.fixedCode}</div></div>
                </div> : <Alert variant='info'>No  Remediation available for this code</Alert>}
            </>
        );
    }

}