import React, { useEffect, useState, useMemo, useRef } from "react";
import ReactEcharts from "echarts-for-react";
import _ from 'lodash';
import convertData from './convertData';
import { useDispatch } from "react-redux";
import { updateActiveSelectedRecord } from "../../../shared/redux/reducers/cloudSecurityReducer";
import { GlobalConst } from "../../../shared/appConfig/globalConst";
import { getCloudIcon, getStrokeColor } from "../../../shared/helper/genHelper";
import './style.css';

export default function GraphChart({ apidata, setShowResourceDetailsModal, advDashboardData }) {
  const dispatch = useDispatch();
  const chartRef = useRef(null);

  const initialData = useMemo(() => convertData(apidata, false, []), [apidata]);
  const [graphData, setGraphData] = useState(initialData);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [graphCenter, setGraphCenter] = useState(['50%', '50%']);
  const [clickedNodeId, setClickedNodeId] = useState(null);

  useEffect(() => {
    setGraphData(convertData(apidata, false, expandedNodes));
  }, [expandedNodes, apidata]);

  useEffect(() => {
    if (clickedNodeId !== null) {
      setTimeout(() => {
        centerViewOnNode(clickedNodeId);
      }, 100);
    }
  }, [clickedNodeId]);

  const getNodePosition = (dataIndex) => {
    if (chartRef.current) {
      const echartsInstance = chartRef.current.getEchartsInstance();
      const series = echartsInstance.getModel().getSeries()[0];
      const layout = series.preservedPoints || series.graph.data;

      if (layout && layout[dataIndex]) {
        return {
          x: layout[dataIndex][0],
          y: layout[dataIndex][1]
        };
      }
    }
    return null;
  };

  const centerViewOnNode = (nodeId) => {
    if (chartRef.current) {
      const echartsInstance = chartRef.current.getEchartsInstance();
      const nodePosition = getNodePosition(nodeId);

      if (nodePosition) {
        const { x, y } = nodePosition;
        const zoom = echartsInstance.getOption().series[0].zoom;
        echartsInstance.setOption({
          series: [{
            center: [x, y],
            zoom: zoom
          }]
        });
      }
    }
  };

  const handleNodeClick = (params) => {
    if (params.dataType === 'node') {
      const clickedNode = params.data;
      let newExpandedNodes = _.cloneDeep(expandedNodes);
      if (_.find(newExpandedNodes, (node) => node === clickedNode.uniqId)) {
        newExpandedNodes = _.filter(newExpandedNodes, (node) => node != clickedNode.uniqId);
      }
      else {
        newExpandedNodes.push(clickedNode.uniqId);
      }
      setExpandedNodes(newExpandedNodes);
      if (clickedNode.hasChildren === false) {
        if (_.includes(GlobalConst.EXCLUDED_GRAPH_CLICK_KEYS, clickedNode.docType.toLowerCase())) { return false; }
        const activeRecordData = {
          "targetSystem": advDashboardData.targetSystem,
          "integrationId": advDashboardData.integrationId,
          "reportId": advDashboardData.reportId,
          "docType": clickedNode.docType,
          "name": clickedNode.name
        };
        dispatch(updateActiveSelectedRecord(activeRecordData));
        setShowResourceDetailsModal(true);
      }
      setTimeout(() => {
        setClickedNodeId(params.dataIndex);

      }, 100);
    }
  };

  const options = useMemo(() => ({
    title: false,
    legend: false,
    series: [
      {
        type: 'graph',
        layout: 'force',
        animation: false,
        label: {
          show: true,
          position: 'right',
          formatter: '{b}',
          fontSize: 12,
          fontWeight: 'normal',
          color: '#ffffff',
          textBorderColor: 'transparent',
          textBorderWidth: 0,
          textShadowColor: 'transparent',
          textShadowBlur: 0,
          textShadowOffsetX: 0,
          textShadowOffsetY: 0,
          formatter: (params) => {
            const severityCounts = {
              ...params.data.vulnerability,
            };
            const sortedSeverity = _.fromPairs(
              _.sortBy(
                _.toPairs(severityCounts),
                ([key]) => ['critical', 'high', 'medium', 'low'].indexOf(key)
              )
            );
            let total = 0;
            _.map(sortedSeverity, (count, severity) => {
              if (count > 0) {
                total = total + count;
                // return `{${severity}|${count}}`;
              }
            }).join(' ');

            // let circleText = '';
            // if (severityCircles) {
            //   circleText = severityCircles;
            // }
            const severityOrder = ['critical', 'high', 'medium', 'low'];
            const firstNonZeroSeverity = severityOrder.find(severity => sortedSeverity[severity] > 0);


            // console.log("I am the non zero one ::", firstNonZeroSeverity);

            // console.log("I am the total here ", total);
            let totalText = total ? `{${firstNonZeroSeverity}|${(total < 10) ? ' ' + total + ' ' : total}}` : ``;
            const arrow = params.data.hasChildren && !_.includes(expandedNodes, params.data.uniqId) ? ` {expander|▶}` : '';
            return [
              `{name|${params.name}} ${arrow}`,
              `{lineBreak|}`,
              totalText,
              // `{dashed| }`
            ].join('\n');
          },
          rich: {
            name: {
              color: '#ffffff',
              fontSize: 12,
              fontWeight: 'normal',
            },
            lineBreak: {
              height: 15,
            },
            critical: {
              // backgroundColor: getStrokeColor('CRITICAL'),
              // color: '#212121',
              // className: 'scanning-animation',
              color: getStrokeColor('CRITICAL'),
              borderRadius: 10,
              padding: [5, 5, 5, 5],
              fontSize: 14,
              lineHeight: 11,
              borderColor: getStrokeColor('CRITICAL'),
              borderWidth: 1,
              borderType: 'dashed',
              borderDashOffset: 0,
            },
            high: {
              // backgroundColor: getStrokeColor('HIGH'),
              color: getStrokeColor('HIGH'),
              borderRadius: 10,
              padding: [5, 5, 5, 5],
              fontSize: 14,
              lineHeight: 11,
              borderColor: getStrokeColor('HIGH'),
              borderWidth: 1,
              borderType: 'dashed',
              borderDashOffset: 0,
            },
            medium: {
              // backgroundColor: getStrokeColor('MEDIUM'),
              color: getStrokeColor('MEDIUM'),
              borderRadius: 10,
              padding: [5, 5, 5, 5],
              fontSize: 14,
              lineHeight: 11,
              borderColor: getStrokeColor('MEDIUM'),
              borderWidth: 1,
              borderType: 'dashed',
              borderDashOffset: 0,
            },
            low: {
              // backgroundColor: getStrokeColor('LOW'),
              color: getStrokeColor('LOW'),
              borderRadius: 10,
              padding: [5, 5, 5, 5],
              fontSize: 14,
              lineHeight: 11,
              borderColor: getStrokeColor('LOW'),
              borderWidth: 1,
              borderType: 'dashed',
              borderDashOffset: 0,
            },
            expander: {
              color: '#0e6ecf'
            }
          }
        },
        draggable: true,
        data: graphData.nodes.filter(node => !node.hidden).map(function (node, idx) {
          return {
            ...node,
            id: idx,
            symbol: 'image',
            symbolSize: [15, 15],
            symbolOffset: [0, 0],
            itemStyle: {
              color: node.collapsed ? '#999' : undefined,
              cursor: 'pointer',
            },
            symbol: `image://${getCloudIcon(node.docType, advDashboardData.targetSystem) || getCloudIcon('')}`, // Use a placeholder if no image is provided
            emphasis: {
              itemStyle: {
                cursor: 'pointer'
              }
            }
          };
        }),
        categories: graphData.categories,
        labelLayout: {
          hideOverlap: true
        },
        force: {
          repulsion: 90,
          edgeLength: 30,
          layoutAnimation: false,
          initLayout: 'none'
        },
        // edges: graphData.links.filter(link => !link.hidden),
        edges: graphData.links.filter(link => !link.hidden).map(link => {
          if (!_.isEmpty(link) && !_.isEmpty(link.vulnerability) && link.vulnerability.toLowerCase() === 'critical') {
            return {
              ...link,
              lineStyle: {
                color: 'rgb(218, 74, 71)',
                width: 2,
                type: 'solid'
              }
            };
          }
          return {
            ...link,
            lineStyle: {
              color: '#5470C6',
              width: 2,
              type: 'solid'
            }
          };
        }),
        roam: true,
        lineStyle: {
          color: 'source',
          curveness: 0.3
        },
        focusNodeAdjacency: false,
        zoom: 5,
        center: graphCenter,
      }
    ]
  }), [graphData, graphCenter, advDashboardData.targetSystem, expandedNodes]);

  const onEvents = {
    'click': handleNodeClick,
  };

  return (
    <ReactEcharts
      ref={chartRef}
      option={options}
      style={{ height: '100%', width: '100%', cursor: 'pointer' }}
      opts={{ renderer: 'svg' }}
      onEvents={onEvents}
      className="echarts-graph-container"
    />
  );
}