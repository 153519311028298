
import { Tag, Space, Table, Button } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import FileDirectory from './fileDirectory';
import { getFontColor, getStrokeColor } from '../../shared/helper/genHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
// import NoDataFound from './noDataFound';
import axios from 'axios';
import Loader from '../../shared/sharedComponents/loader';
import NoDataFound from '../../shared/sharedComponents/noDataFound';
import { GlobalConst } from '../../shared/appConfig/globalConst';


const expandedRowRender = (props, setShowFileExplorerModal, setSelectedData) => {

    let subData = [];
    // console.log("Props", props);
    const columns = [
        {
            title: 'Line Number',
            dataIndex: 'lineNumber',
            key: 'lineNumber',
            width: '12%',
        },
        {
            title: 'CWE ID',
            dataIndex: 'cweId',
            key: 'cweId',
            width: '10%',
        },
        {
            title: 'Vulnerability Title',
            key: 'vulTitle',
            dataIndex: 'vulTitle',
            width: '30%',
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
            align: 'center',
            width: '12%',
            render: (index, row) => {
                if (!_.isEmpty(row) && !_.isEmpty(row.severity)) {
                    return (
                        <Tag style={{ color: getFontColor(row.severity.toUpperCase()), fontWeight: 600, textTransform: 'capitalize' }} color={getStrokeColor(row.severity.toUpperCase())}>
                            {row.severity}
                        </Tag>);
                }
                else {
                    return '-';
                }
            },
        },
        {
            title: 'Action',
            key: 'operation',
            width: '30%',
            render: (col, row) => {
                // console.log("Row", row);
                return (
                    <Space size="middle">
                        <a href='#'
                            onClick={(e) => {
                                e.preventDefault();
                                setShowFileExplorerModal(true);
                                // console.log("I am the row", row);
                                setSelectedData({ fileId: row.fileId, lineNumber: row.lineNumber });
                            }}
                        >View Detail</a>
                    </Space>
                );
            },
        },
    ];

    if (!_.isEmpty(props) && !_.isEmpty(props.vulnerabilities)) {

        let newData = [];
        props.vulnerabilities.forEach((row, i) => {
            newData.push({
                key: i.toString(),
                lineNumber: row.line_number,
                cweId: `CWE-${row.cwe_ids.join(',')}`,
                vulTitle: row.title,
                severity: row.severity,
                operation: '',
                fileId: props.fileId
            })
        })
        subData = newData;
    }
    else {
        subData = [];
    }
    return <Table columns={columns} dataSource={subData} pagination={false} locale={{ emptyText: <NoDataFound title={'No Data'} /> }}/>;
};

export default function SastFileTabView({ userPrefrences, id }) {

    const [showFileExplorerModal, setShowFileExplorerModal] = useState(false);
    const [data, setData] = useState([]);

    const [selectedData, setSelectedData] = useState({});
    const [reportDetails, setReportDetails] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAdvSastDashboardData()
    },[])

    const columns = [
        {
            title: <><span className='me-3'>File Name</span> <a href={'#'} className="mb-2" onClick={(e) => { e.preventDefault(); setShowFileExplorerModal(true); setSelectedData({}) }}>
                <small><FontAwesomeIcon icon={faFolderOpen} className='me-0' /> File Explorer</small>
            </a></>,
            dataIndex: 'fileName',
            key: 'fileName',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'center'
        },
        {
            title: 'Critical',
            dataIndex: 'critical',
            key: 'critical',
            align: 'center',
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("CRITICAL"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("CRITICAL")}
                        className={"mx-auto text-center"}
                    >
                        {row.critical}
                    </Tag>
                </div>);
            }
        },
        {
            title: 'High',
            dataIndex: 'high',
            key: 'high',
            align: 'center',
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("HIGH"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("HIGH")}
                        className={"mx-auto text-center"}
                    >
                        {row.high}
                    </Tag>
                </div>);
            }
        },
        {
            title: 'Medium',
            dataIndex: 'medium',
            key: 'medium',
            align: 'center',
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("MEDIUM"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("MEDIUM")}
                        className={"mx-auto text-center"}
                    >
                        {row.medium}
                    </Tag>
                </div>);
            }
        },
        {
            title: 'Low',
            dataIndex: 'low',
            key: 'low',
            align: 'center',
            render: (val, row) => {
                return (<div className="d-flex align-items-center justify-content-center flex-column">
                    <Tag
                        style={{
                            color: getFontColor("LOW"),
                            borderRadius: 50,
                            width: 40,
                            fontSize: 14,
                        }}
                        color={getStrokeColor("LOW")}
                        className={"mx-auto text-center"}
                    >
                        {row.low}
                    </Tag>
                </div>);
            }
        }
    ];

    const getTotal = (vulCount) => {
        return (vulCount.critical + vulCount.high + vulCount.medium + vulCount.low);
    }

    const getAdvSastDashboardData = () => {
        setLoading(true)
        const postBody = { "reportId": id };
        axios
            .post(`${GlobalConst.API_URL}/auth/sast/asset/report/file-view`, postBody)
            .then((op) => {
                setLoading(false)
                // console.log("Output is ::", op);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setReportDetails(op.data);
                    if (!_.isEmpty(op.data.scanReport.vulnerabilities_by_file)) {
                        let newData = [];
                        op.data.scanReport.vulnerabilities_by_file.forEach((row, i) => {
                            newData.push({
                                key: i.toString(),
                                fileName: row.fileName,
                                total: getTotal(row.vulnerabilityCounts),
                                critical: row.vulnerabilityCounts.critical,
                                high: row.vulnerabilityCounts.high,
                                medium: row.vulnerabilityCounts.medium,
                                low: row.vulnerabilityCounts.low,
                                vulnerabilities: row.vulnerabilities,
                                fileId: row.fileId
                            });
                        });
                        // console.log("newData", newData);
                        setData(newData);

                    }
                    else {
                        // console.log("I am blank here");
                        setData([]);
                    }
                }
            })
            .catch((e) => {
                setLoading(false)
                setReportDetails({});
                console.log("Exception: ", e);
            });
    }

    if (loading) {
        return (<Loader/>)
    }
    if (_.isEmpty(data)) {
        return (<NoDataFound title={"No Files Available"} desc={'There are currently no files to display. Please initiate a scan by uploading files in repository.'} />);
    }
    else {

        return (
            <section className="my-2">
                <div>
                    <div className='d-flex align-items-center justify-content-start mb-0'>
                        {/* <a href={'#'} className="me-2 mb-2" onClick={(e) => {e.preventDefault(); setShowFileExplorerModal(true);setSelectedData({})}}>
                            <FontAwesomeIcon icon={faFolderOpen} className='me-2' /> View All Files
                        </a> */}
                        {/* <Button className="me-2 mb-0" onClick={(e) => {e.preventDefault(); setShowFileExplorerModal(true);setSelectedData({})}}>
                            <FontAwesomeIcon icon={faFolderOpen} className='me-2' /> All Files
                        </Button> */}
                    </div>
                    <Table
                        className='custom-table'
                        columns={columns}
                        // expandable={{ expandedRowRender }}
                        expandable={{
                            expandedRowRender: (record) => expandedRowRender(record, setShowFileExplorerModal, setSelectedData),
                        }}
                        dataSource={data}
                        size="small"
                        locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
                    />

                </div>
                <FileDirectory setShowFileExplorerModal={setShowFileExplorerModal} showFileExplorerModal={showFileExplorerModal} reportDetails={reportDetails} selectedData={selectedData} userPrefrences={userPrefrences} />
            </section>
        )
    }

}