import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Tooltip, Radio, Button } from 'antd';
import axios from "axios";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import _ from 'lodash';
import InlineLoader from '../../shared/sharedComponents/inlineLoader';
import { useSelector, useDispatch } from "react-redux";
import { removeGitSuffix } from '../../shared/helper/genHelper';
import SelectRepositoryDataTable from "./selectRepositoryDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";


export default function SelectRepository({ 
    selectedRepo, 
    setSelectedRepo, 
    urlType, 
    repositories, 
    loadingMoreRepos, 
    handleLoadMoreClick,
    disableLoadMore
 }) {

    const dispatch = useDispatch();
    const [selectedFilter, setSelectedFilter] = useState('unscanned');
    const [data, setData] = useState([]);

    const sastReducerState = useSelector(state => state.sastReducer);
    const reduxState = useSelector(state => state);

    const handleRepoItemClick = (e, element) => {
        e.preventDefault();

        // check if the current element is not present in the existing list if present then remove it else add it
        if (!_.isEmpty(selectedRepo)) {

            let isPresent = false;
            selectedRepo.forEach((el, ind) => {
                if (el.url === element.url) {
                    isPresent = true;
                }
            })
            if (isPresent) {
                // since the element is present now remove the element from list
                let filter = [];
                filter = _.filter(selectedRepo, (o) => { return o.url !== element.url });
                setSelectedRepo([...filter]);
            }
            else {
                setSelectedRepo([...selectedRepo, element]);
            }
        }
        else {
            setSelectedRepo([element]);
        }
    }

    const getRepoCheckStatus = (repo) => {

        if (!_.isEmpty(selectedRepo)) {
            let isPresent = false;
            selectedRepo.forEach((el) => {
                if (el.url === repo.url) {
                    isPresent = true;
                }
            })
            return isPresent;
        }
        else {
            return false;
        }

    }

   

    const handleRadioChange = (el) => {
        setSelectedFilter(el);
    }

    // Helper function to check if a repo is scanned
    const isRepoScanned = useCallback((repo, scannedRecords) => {
        return _.find(scannedRecords, (o) => 
        urlType !== 'github'
            ? o.assetUrl === repo.url
            : removeGitSuffix(o.assetUrl) === removeGitSuffix(repo.url)
        );
    }, [urlType]);
  
    // Calculate counts on demand
    const { scannedCount, unScannedCount } = useMemo(() => {
        const { sastDashTableData } = sastReducerState;
        return repositories.reduce(
        (acc, repo) => {
            isRepoScanned(repo, sastDashTableData) ? acc.scannedCount++ : acc.unScannedCount++;
            return acc;
        },
        { scannedCount: 0, unScannedCount: 0 }
        );
    }, [repositories, sastReducerState.sastDashTableData, isRepoScanned]);
  
    // Filter repositories based on selected filter
    const filteredData = useMemo(() => {
        const { sastDashTableData } = sastReducerState;
            
        switch (selectedFilter) {
        case 'scanned':
            return repositories.reduce((acc, repo) => {
            const scannedRepo = isRepoScanned(repo, sastDashTableData);
            if (scannedRepo) acc.push({ ...repo, scanDate: scannedRepo.scanDateTime });
            return acc;
            }, []);
        case 'unscanned':
            return repositories.filter(repo => !isRepoScanned(repo, sastDashTableData))
            .map(repo => ({ ...repo, scanDate: '' }));
        default:
            return repositories.map(repo => {
            const scannedRepo = isRepoScanned(repo, sastDashTableData);
            return { ...repo, scanDate: scannedRepo ? scannedRepo.scanDateTime : '' };
            });
        }
    }, [selectedFilter, sastReducerState.repositories, sastReducerState.sastDashTableData, isRepoScanned]);
    
    // Single useEffect to update data
    useEffect(() => {
        setData(filteredData);
        setSelectedRepo([]);
    }, [filteredData]);

        return (

            <div className="py-2 rounded" >
                <div className="d-flex align-items-end justify-content-between mb-2">
                    <div>
                        {/* <div className="d-flex align-items-center justify-content-start mb-2">
                            <div style={{ width: `${(scannedCount * 100) / (scannedCount + unScannedCount)}%`, height: 25, borderRadius: '7px 0 0 7px', color: "#212121", fontWeight: 600, cursor: 'pointer' }} onClick={() => handleRadioChange('scanned')} className={`bg-success d-flex align-items-center justify-content-center  ${!scannedCount ? "d-none" : ""}`}>{scannedCount}</div>
                            <div style={{ width: `${(unScannedCount * 100) / (scannedCount + unScannedCount)}%`, height: 25, borderRadius: '0 7px 7px 0', color: "#212121", fontWeight: 600, cursor: 'pointer' }} onClick={() => handleRadioChange('unscanned')} className={`bg-danger d-flex align-items-center justify-content-center   ${!unScannedCount ? "d-none" : ""}`}>{unScannedCount}</div>
                        </div> */}

                        <div className="d-flex align-items-center justify-content-start px-2">
                            <Tooltip title="show only scanned repos" color={'#444'}><Radio name="filter" className="me-4 text-success radio-success" onChange={() => handleRadioChange('scanned')} checked={selectedFilter === 'scanned'} style={{ color: '#adb5bd' }}>scanned repos 
                            <span className="ps-1">{`(${scannedCount})`}</span>
                            </Radio></Tooltip >
                            <Tooltip title="show only unscanned repos" color={'#444'}><Radio name="filter" className="me-4 text-danger radio-danger" onChange={() => handleRadioChange('unscanned')} checked={selectedFilter === 'unscanned'} style={{ color: '#adb5bd' }}>unscanned repos                             
                                <span className="ps-1">{`(${unScannedCount})`}</span>
                            </Radio></Tooltip >
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 scroll-style">
                        <div className="row">
                            <div className="col-sm-8">
                                <div style={{ maxHeight: 450, overflow: "hidden", overflowY: 'scroll' }}>
                                    <SelectRepositoryDataTable
                                        dataSet={data}
                                        selectedFilter={selectedFilter}
                                        handleRepoItemClick={handleRepoItemClick}
                                        selectedRepo={selectedRepo}
                                        getRepoCheckStatus={getRepoCheckStatus}
                                    />
                                    <div className="w-100 text-center my-3">
                                        <Button type="primary" disabled={disableLoadMore} loading={loadingMoreRepos} onClick={() => handleLoadMoreClick()}>Load More <FontAwesomeIcon className="ms-2" icon={faArrowDown} /></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}