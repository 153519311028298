import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodeBranch, faLink, faTriangleExclamation, faXmark } from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash';
import axios from 'axios';
import { Modal, Tooltip, Button, Divider, Alert } from 'antd';
import { faRegistered } from "@fortawesome/free-regular-svg-icons";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { faBitbucket, faGithub, faGitlab } from "@fortawesome/free-brands-svg-icons";
import { setIScanModalState, setSastScanTriggeredState, addUpdateSastDashboardTableData } from "../../shared/redux/reducers/sastReducer";
import azureScm from '../../assets/img/scopy-tech-icons/azure-scm.svg';
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { getIntegratedState } from "../../shared/helper/genHelper";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import SelectRepository from "./selectRepository";
import InLineLoader from "../../shared/sharedComponents/inlineLoader";

export default function IScanModal({ }) {

    const dispatch = useDispatch();
    const iscanReducer = useSelector(state => state.sastReducer);
    const reduxState = useSelector(state => state);
    const formikRef = useRef(null);

    const [activeTab, setActiveTab] = useState('1');
    const [integratedSystems, setIntegratedSystems] = useState({});
    const [loadingRepos, setLoadingRepos] = useState(false);
    const [selectedRepo, setSelectedRepo] = useState([]);
    const [unscannedRepoState, setUnscannedRepoState] = useState(true);
    const [repos, setRepos] = useState([])
    const [currentRepoPage, setCurrentRepoPage] = useState(0)
    const [loadingMoreRepos, setLoadingMoreRepos] = useState(false)
    const [disableLoadMore, setDisableLoadMore] = useState(false);

    const azurePlaceholderUrl = 'https://dev.azure.com/orgName/projectName/_git/repoName';
    const githubPlaceholderUrl = 'https://github.com/owner/repo';

    useEffect(() => {
        getUserIntegrations()
    }, []);

    useEffect(() => {
        const initialRepoCount = repos.length;
        setDisableLoadMore(initialRepoCount % 20 !== 0);
    }, [repos]);

    const closeIScanModal = () => {
        dispatch(setIScanModalState(false))
    }

    const handleFormSubmit = (values, setSubmitting) => {
        let isDemoUser = localStorage.getItem('isDemoUser');
        if (isDemoUser && isDemoUser === 'true') {
            alert("Scan has been disabled for this account");
            setSubmitting(false);
        }
        else {
            setSubmitting(true);
            let requestList = [];
            if (!_.isEmpty(selectedRepo)) {
                selectedRepo.forEach((el) => {
                    requestList.push({
                        location: el.url,
                        urlType: "bitbucket"
                    });
                });
            }
            let assetType = activeTab !== '3' ? (activeTab === '1' ? 'github' : 'azure-scm') : 'bitbucket';
            dispatch(setSastScanTriggeredState(true));
            const postBody = { "urlType": assetType, "scanRequestList": !_.isEmpty(selectedRepo) ? requestList : [{ "urlType": assetType, "location": values.artifactURL, "branchName": values.branch }] };
            axios
                .post(`${GlobalConst.API_URL}/auth/foss/sast/ascan`, postBody)
                .then((op) => {
                    // console.log("Output ", JSON.stringify(op.data))
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        dispatch(addUpdateSastDashboardTableData(op.data));
                        dispatch(setIScanModalState(false));
                        window.location.reload();
                    }
                })
                .catch((e) => { });
        }
    }

    const handleBitbucketClick = (e) => {
        e.preventDefault();
        if (!_.isEmpty(integratedSystems) && !_.isEmpty(integratedSystems['bitbucket']))
            getRepositories('bitbucket', integratedSystems['bitbucket'].targetorg)
        setActiveTab('3')
    }

    const getRepositories = (urlType, targetOrg) => {
        setCurrentRepoPage(0)
        setLoadingRepos(true);
        let reqBody = {
            "urlType": urlType,
            "page": currentRepoPage,
            "recordsPerPage": GlobalConst.GIT_REPO_LIST_PAGE_COUNT
        }
        if (!_.isEmpty(targetOrg)) {
            reqBody = {
                ...reqBody,
                "owner": targetOrg,
            }
        }
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/get-repo-list`, reqBody)
            .then(op => {
                setLoadingRepos(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.data)) {
                    // setRepositoryList(op.data.data);
                    // setRepositoryListCopy(op.data.data);
                    setRepos(op.data.data)
                }
                else {
                    setRepos([])
                }
            })
            .catch(e => {
                console.log("Exception: ", e.response);
                setLoadingRepos(false);
                setRepos([])
            });
    }

    const getUserIntegrations = () => {
        axios
            .get(`${GlobalConst.API_URL}/auth/integrate/user-integrations`)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setIntegratedSystems(op.data);
                }

            })
            .catch(e => {
                console.log("Exception:", e);
            })
    }

    const handleLoadMoreClick = () => {
        const page = currentRepoPage + 1
        setCurrentRepoPage(page)

        setLoadingMoreRepos(true);
        const urlType = activeTab !== '3' ? 'github' : 'bitbucket'
        let reqBody = {
            urlType,
            "owner": reduxState.authReducer.userDetails.email,
            "page": page,
            "recordsPerPage": GlobalConst.GIT_REPO_LIST_PAGE_COUNT
        }

        if (urlType === 'bitbucket') {
            if (!_.isEmpty(integratedSystems) && !_.isEmpty(integratedSystems['bitbucket']))
                reqBody = {
                    ...reqBody,
                    "owner": integratedSystems['bitbucket'].targetorg,
                }
        }
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/get-repo-list`, reqBody)
            .then(op => {
                setLoadingMoreRepos(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.data)) {
                    // setRepositoryList(op.data.data);
                    // setRepositoryListCopy(op.data.data);
                    setRepos([...op.data.data, ...repos])
                    if (op.data.data.length < 20) {
                        setDisableLoadMore(true);
                    }
                }
                else {
                    // show user the error message that there is no repo list.
                    // setRepositoryList([]);
                    // setRepositoryListCopy([]);
                    setDisableLoadMore(true);
                }
            })
            .catch(e => {
                console.log("Exception: ", e.response);
                setLoadingMoreRepos(false);
                // setRepositoryList([]);
                // setRepositoryListCopy([]);
            });



    }

    const validationSchema = Yup.object().shape({
        artifactURL: Yup.string()
            // .required('Artifact URL is required')
            // .url('Please enter a valid URL')
            .test(
                'is-https',
                'Please enter a valid HTTPS URL or select repositories',
                function (value) {
                    if (!_.isEmpty(selectedRepo)) {
                        return true;
                    }
                    return value && value.startsWith('https://') && Yup.string().url().isValidSync(value);
                },
            ),

    });

    return (

        <Modal
            title=""
            open={iscanReducer.iScanModalState}
            footer={null}
            onCancel={closeIScanModal}
            closeIcon={<FontAwesomeIcon className="fs-5 text-white" icon={faXmark} />}
            width={850}
            maskClosable={false}
            className="scan-modal"
        >
            <section className="m-1">
                <div className="">
                    <div className="text-white">
                        <div className="mb-3">
                            <h5 className="mb-0 text-white" style={{ fontWeight: 600 }}>iScan SAST <small style={{ fontSize: '65%' }} className="mb-0 ms-2 text-muted">AI Powered Scans for SAST <FontAwesomeIcon icon={faRegistered} style={{ fontSize: 10 }} /></small></h5>
                            <div className="d-flex align-items-center justify-content-start"></div>
                        </div>
                        <div className="my-3 mb-1">
                            <div className="me-3 mb-2">Select asset to scan</div>
                            <div className="d-flex align-items-center justify-content-start mb-3">
                                <Tooltip title="Scan Github Repo">
                                    <div style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7, minWidth: 95 }} className={`p-2 rounded ${activeTab === '1' ? 'active-other-link' : 'border'} other-links`} onClick={(e) => { e.preventDefault(); setActiveTab('1') }}>
                                        <p className="mb-0"><FontAwesomeIcon icon={faGithub} style={{ fontSize: 20, color: '#adb5bd' }} /></p>
                                        <p className="mb-0">Github</p>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Scan Azure SCM">
                                    <div className={`${activeTab === '2' ? 'active-other-link' : 'border'} p-2 rounded other-links`} style={{ cursor: 'pointer', opacity: 0.7, minWidth: 95, marginRight: 12 }} onClick={(e) => { e.preventDefault(); setActiveTab('2') }}>
                                        <p className="mb-0"><img src={azureScm} alt="azure-scm" style={{ width: 20 }} /></p>
                                        <p className="mb-0">Azure SCM</p>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Scan Bitbucket SCM">
                                    <div className={`${activeTab === '3' ? 'active-other-link' : 'border'} p-2 rounded other-links`} style={{ cursor: 'pointer', opacity: 0.7, minWidth: 95, marginRight: 12 }} onClick={handleBitbucketClick}>
                                        <p className="mb-0"><FontAwesomeIcon icon={faBitbucket} style={{ fontSize: 20, color: '#adb5bd' }} /></p>
                                        <p className="mb-0">Bitbucket</p>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Scan GitLab Repo">
                                    <div style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7, minWidth: 95 }} className={`p-2 rounded ${activeTab === '4' ? 'active-other-link' : 'border'} other-links pe-none`} onClick={(e) => { e.preventDefault(); setActiveTab('4') }}>
                                        <p className="mb-0"><FontAwesomeIcon icon={faGitlab} style={{ fontSize: 20, color: '#adb5bd' }} /></p>
                                        <p className="mb-0">GitLab</p>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        {activeTab === '3' && <div className="mb-3">
                            {getIntegratedState(
                                integratedSystems,
                                activeTab !== '3' ? 'github' : 'bitbucket'
                            ) ?
                                (loadingRepos ? (
                                    <div className='card custom-card p-2 px-3 text-left mb-2' style={{ background: '#1e464d', border: '1px solid #2c6a74' }}>
                                        <div className="d-flex align-items-start justify-content-start text-white"><InLineLoader /> <span className="ms-2">Loading repositories...</span></div>
                                    </div>
                                )
                                    : (_.isEmpty(repos) ? (
                                        <Alert message="No Repositories found in your account" type="info" showIcon />
                                    ) : <SelectRepository
                                        loadingMoreRepos={loadingMoreRepos}
                                        repositories={repos}
                                        selectedRepo={selectedRepo}
                                        setSelectedRepo={setSelectedRepo}
                                        unscannedRepoState={unscannedRepoState}
                                        setUnscannedRepoState={setUnscannedRepoState}
                                        disableLoadMore={disableLoadMore}
                                        urlType={activeTab !== '3' ? 'github' : 'bitbucket'}
                                        handleLoadMoreClick={handleLoadMoreClick}
                                    />))
                                : <NoDataFound
                                    title={`We could not found integrations for the scm`}
                                    desc={
                                        <a
                                            href={`/integrations-list/${activeTab !== '3' ? 'github' : 'bitbucket'}`}
                                            target="_blank"
                                        >
                                            Please click here to configure
                                        </a>
                                    }
                                    customInlineStyle={{
                                        image: {
                                            width: 54,
                                        },
                                        title: {
                                            fontSize: 14,
                                        },
                                        description: {
                                            fontSize: 12,
                                        },
                                    }}
                                />
                            }
                            <Divider className="divider-custom my-1" orientation="center"><small>or</small></Divider>
                        </div>}
                        <div>
                            <div className="sotcox-input-field">
                                <Formik
                                    initialValues={{ artifactURL: '', branch: '' }}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        handleFormSubmit(values, setSubmitting);
                                    }}
                                    innerRef={formikRef}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <div className={`mb-3 field is-horizontal`}>
                                                <div className="field-body">
                                                    <div className="field">
                                                        <div className="control is-expanded">
                                                            <div className="row">
                                                                <div className="col-sm-8">
                                                                    <div className="input-group me-3">
                                                                        <span className="input-group-text"><FontAwesomeIcon icon={faLink} /></span>
                                                                        <Field
                                                                            name="artifactURL"
                                                                            type="text"
                                                                            className="input input-rounded form-control"
                                                                            aria-describedby="Artifact URL"
                                                                            placeholder={`Enter an artifact URL to trigger SAST scan for e.g. ${activeTab === '1' ? githubPlaceholderUrl : azurePlaceholderUrl}`}
                                                                            style={{ padding: '10px 20px' }}
                                                                        />
                                                                    </div>

                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div className="input-group">
                                                                        <span className="input-group-text"><FontAwesomeIcon icon={faCodeBranch} /></span>
                                                                        <Field
                                                                            name="branch"
                                                                            type="text"
                                                                            className="input input-rounded form-control"
                                                                            aria-describedby="Branch"
                                                                            placeholder={`Enter Branch (optional)`}
                                                                            style={{ padding: '10px 20px' }}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="artifactURL">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center mt-3">
                                                <Button htmlType="submit" type="primary" size={'large'} loading={isSubmitting} disabled={isSubmitting}>Scan</Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Modal>
    );
}